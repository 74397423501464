import {usePagination} from "UI/App/Components/Pagination/Pagination";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import Table from "UI/App/Components/Table/Table";
import {formatDate} from "App/Util/format";
import {stringify} from "qs";
import Button from "UI/App/Components/Button/Button";
import Container from "UI/App/Components/Container/Container";

export default function Logs() {
    const {authAxios} = useContext(FetchContext);

    // create a state for the table data
    const [tableData, setTableData] = useState([]);

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'logging',
        searchSettings: {
            enabled: true,
            strapiFields: [
                '[user][username]',
                '[user][email]',
                'action',
                'status',
                'payload'
            ]
        },
        filters: [
            {
                label: 'Payload',
                name: 'payload',
                type: 'input',
                enabled: true,
                strapiFilterFields: ['payload'],
                strapiFilter: '$containsi',
            },
            {
                label: 'User',
                name: 'user',
                type: 'input',
                enabled: true,
                strapiFilterFields: ['[user][username]', '[user][email]'],
                strapiFilter: '$containsi',
            },
            {
                label: 'Action',
                name: 'action',
                type: 'input',
                enabled: true,
                strapiFilterFields: ['action'],
                strapiFilter: '$containsi',
            }
        ]
    });

    // Run in dom update
    useEffect(() => {
        if (!paginationStateLoaded) return;

        const params = stringify({
            populate: {
                user: {
                    fields: [
                        'username',
                        'email'
                    ]
                }
            },
            fields: [
                'id',
                'status',
                'createdAt',
                'action',
                'apiToken',
            ],
            sort: ['id:desc'],
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            }
        });

        const dateFrom = new Date(new Date().setDate((new Date()).getDate() - 30));
        authAxios.get(`/admin/logs?${params}&${filterQuery}&filters[createdAt][$gte]=${dateFrom.toISOString()}`).then(({data: {data, meta}}) => {
            setTotalPages(meta.pagination.pageCount)
            setTotalResults(meta.pagination.total)

            let tempTableData = [];
            data.forEach((record) => {
                const user = record.user;
                tempTableData.push([
                    record.status,
                    formatDate(record.createdAt),
                    (user ? `${user.username} (${user.email})` : (record.apiToken ? 'API Token: ' + record.apiToken : <i style={{color: 'gray'}}>Geen (Unauthenticated)</i>)),
                    record.action,
                    <Button to={`/admin/logs/${record.id}`}>Bekijken</Button>
                ]);
            });

            setTableData(tempTableData);
        });
    }, [authAxios, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery, paginationStateLoaded])

    return (
        <>
            {filtering}
            <Container>
                <Table
                    headers={['Status', 'Date', 'user', 'Action', 'Bekijk log item']}
                    data={tableData}
                    structure={['100px', '200px', '50%', '50%', '250px']}
                    border='row'
                    hover
                    overflowText
                />
            </Container>
            {paging}
        </>
    )
}
