import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import qs from "qs";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import NotFound from "UI/App/Partials/App/NotFound";

export default function CalculationOwnerSwitch({
    param,
    hjmgComponent,
    pretoreComponent,
}) {
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    const [calculationType, setCalculationType] = useState(null);

    useEffect(() => {
        if (!params[param]) return;

        const query = qs.stringify({
            fields: [
                'id',
                'owner'
            ]
        });

        authAxios.get(`/calculations/${params[param]}?${query}`).then(({ data: { data } }) => {
            setCalculationType(data.owner.toLowerCase())
        }).catch((err) => {
            setCalculationType(false);
        })
    }, [params?.[param]]);

    if (calculationType === 'hjmg') {
        return hjmgComponent
    } else if (calculationType === 'pretore') {
        return pretoreComponent
    } else if (calculationType === false) {
        return <NotFound />
    }

    return (
        <div style={{
            display: 'block',
            height: '100%',
            width: '100%'
        }}>
            <SpinnerOverlay visible={true} />
        </div>
    )
}