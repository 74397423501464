import {useContext} from "react";
import {CalculationProviderContext} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import ChecklistSelect from "UI/App/Partials/Content/Calculations/Calculations/steps/ChecklistSelect";

export default function ChecklistTab() {
    const {
        activeTab,
        checklistData,
        setChecklistData,
        language
    } = useContext(CalculationProviderContext);

    return (
        <div className={"hjmg__calculation__tab"} data-active={activeTab === 'checklist'}>
            <ChecklistSelect
                chosenLanguage={language}
                checklistData={checklistData}
                setChecklistData={setChecklistData}
            />
        </div>
    )
}