import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { ConfigContext } from 'App/Strapi/ConfigContext';
import Block from 'UI/App/Components/Block/Block';
import Icon from 'UI/App/Components/Icon/Icon';
import { openPopup } from 'UI/App/Components/Popup/Popup';
import PopupPostAndUpdateTask from 'UI/App/Components/Popup/Popups/PostAndUpdateTask';
import { SpinnerCenter } from 'UI/App/Components/Spinner';
import NotesTask from './NotesTask';
import IF from 'UI/App/Components/Conditional/IF';
import './notes.scss';

export default function Notes({ ...props }) {
    const { appConfig } = useContext(ConfigContext);
    const { msGraph } = useContext(AuthContext);

    // state for loading icon
    const [isLoading, setLoading] = useState(true);

    // get the tasks
    const [tasks, setTasks] = useState({ value: [], listID: '' });
    const [expandedTasks, setExpandedTasks] = useState([]);
    const [importanceSort] = useState({ high: 1, normal: 0, low: -1 });

    useEffect(() => {
        // only run when appConfig has been fetched from strapi
        if (appConfig && msGraph?.isReady()) {
            // get id of our to do list
            msGraph
                .get(`me/todo/lists`, {
                    $filter: `displayName eq '${appConfig?.organisation ?? 'Emily'}'`
                })
                .then(({ value }) => {
                    if (!isEmpty(value)) {
                        return value[0];
                    } else {
                        // reject (quit chain) with no message (not really an error -> no toast)
                        return Promise.reject({ message: false });
                    }
                })
                .catch(() => {
                    // create list on promise reject
                    return msGraph.post(`me/todo/lists`, {
                        displayName: appConfig?.organisation ?? 'Emily'
                    });
                })
                .then(async ({ id }) => {
                    // get tasks (order by `isReminderOn` is not allowed)
                    const getTasks = await msGraph.get(`me/todo/lists/${id}/tasks`, {
                        $orderby: `reminderDateTime/dateTime asc, importance desc`
                    });
                    return { value: getTasks.value, listID: id };
                })
                .then((data) => {
                    data.value = data.value
                        .map((task) => {
                            if (typeof task?.reminderDateTime?.dateTime === 'string') {
                                task.reminderDateTime.dateTime = task.reminderDateTime.dateTime + 'Z';
                            }

                            return { ...task };
                        })
                        .sort((a, b) => {
                            // Infinity gives best results

                            let aDate, aTime, bDate, bTime;

                            aDate = aTime = bDate = bTime = Infinity;

                            if (typeof a?.reminderDateTime?.dateTime === 'string') {
                                aDate = new Date(a.reminderDateTime.dateTime.replace(/T.*?$/, ''));
                                aTime = new Date(a.reminderDateTime.dateTime);
                            }
                            if (typeof b?.reminderDateTime?.dateTime === 'string') {
                                bDate = new Date(b.reminderDateTime.dateTime.replace(/T.*?$/, ''));
                                bTime = new Date(b.reminderDateTime.dateTime);
                            }

                            return (
                                aDate - bDate ||
                                importanceSort[b?.importance] - importanceSort[a?.importance] ||
                                aTime - bTime
                            );
                        });

                    setTasks(data);
                })
                .catch((error) => {
                    // show toast if error.message is truthy
                    error.message && toast.error(error.message);

                    error.message && console.error(error);
                })
                .finally(() => {
                    // stop loading when this chain is executed
                    setLoading(false);
                });
        }
    }, [appConfig, importanceSort, msGraph]);

    // set the initial task popup form data
    const initialTaskFormData = {
        key: '',
        title: '',
        content: '',
        priority: {
            label: 'normaal',
            value: 'normal'
        },
        isReminder: false,
        date: '',
        status: 'notStarted'
    };
    const [taskFormData, setTaskFormData] = useState({
        ...initialTaskFormData,
        priority: { ...initialTaskFormData.priority }
    });

    // add state for which taskID to update with the popup form
    const [updateTaskID, setUpdateTaskID] = useState({});

    /**
     * set the popup states and call openPopup
     */
    function openAddAndUpdateTaskPopup() {
        // reset input values
        setTaskFormData((prev) => ({
            ...initialTaskFormData,
            priority: { ...initialTaskFormData.priority },
            status: prev.status
        }));

        // empty the task to update state
        setUpdateTaskID({});

        openPopup('add-and-update-task');
    }

    const blockHeaderRightSideChildren = (
        <>
            <select
                defaultValue={taskFormData.status}
                onChange={(e) => {
                    setTaskFormData((prev) => ({ ...prev, status: e.target.value }));
                }}
                disabled={localStorage.getItem("isTerminalUser") === "true"}
            >
                <option value='notStarted'>Niet afgevinkt</option>
                <option value='completed'>Afgevinkt</option>
            </select>
            <button disabled={localStorage.getItem("isTerminalUser") === "true"} onClick={openAddAndUpdateTaskPopup} className='btn btn--black btn--round btn--small'>
                <Icon name='plus' />
            </button>
        </>
    );

    return (
        <>
            <Block {...props} name='notes' title='Notities' headerRightSideChildren={blockHeaderRightSideChildren}>

                <IF condition={localStorage.getItem("isTerminalUser") === "true"}>
                    Wilt u notities zien?
                    Log dan in via microsoft!
                </IF>
                <IF condition={localStorage.getItem("isTerminalUser") === "false"}>
                    {isLoading && <SpinnerCenter />}

                    {!isLoading && tasks.value.length > 0 && (
                        <div data-filter={taskFormData.status ?? 'notStarted'}>
                            {tasks.value.map(
                                (task, key) =>
                                    task?.status === (taskFormData.status ?? 'notStarted') && (
                                        <NotesTask
                                            key={key}
                                            taskKey={key}
                                            task={task}
                                            listID={tasks?.listID}
                                            setUpdateTaskID={setUpdateTaskID}
                                            setTaskFormData={setTaskFormData}
                                            setTasks={setTasks}
                                            expandedTasks={expandedTasks}
                                            setExpandedTasks={setExpandedTasks}
                                        />
                                    )
                            )}
                        </div>
                    )}

                    {!isLoading &&
                        tasks.value.filter((task) => task.status === (taskFormData.status ?? 'notStarted')).length ===
                        0 && <div data-filter={taskFormData.status ?? 'notStarted'}>U heeft nog geen notities.</div>}
                </IF>
                <PopupPostAndUpdateTask
                    setTasks={setTasks}
                    taskFormData={taskFormData}
                    setTaskFormData={setTaskFormData}
                    listID={tasks.listID}
                    updateTaskID={updateTaskID}
                />
            </Block>
        </>
    );
}
