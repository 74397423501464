import React, { useState } from "react";
import Block from "../../../../../Components/Block/Block";
import ConfirmationPdfEditor from "../ConfirmationPdfEditor";
import Icon from "UI/App/Components/Icon/Icon";

import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_page_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";



import ConfirmationPDFGenerator from "../ConfirmationPDFGenerator";
import HasRole from "UI/App/Components/Auth/HasRole";

export default function ConfirmOrder({ revisionedCalculations, activeStep, language, shippingCost, setShippingCost, shippingAdres, setShippingAdres, shipment, setShipment, deliveryTime, setDeliveryTime, price, setPrice, date, setDate, chosenAmount, chosenCalculationDataId, company, contactPerson, formattedRows, setFormattedRows, rowData, setRowData, saveConfirmation, editorRef, chosenPDFTemplateId, json, quoteData, quoteId, signature, owner = 'pretore', placeOfOrigin, setPlaceOfOrigin, introLine, setIntroLine, footerData, setFooterData, footerData2, setFooterData2, footerText, setFooterText }) {
    const [editorValue, setEditorValue] = useState('');
    const [backgroundOne, setBackgroundOne] = useState(null)
    const [backgroundTwo, setBackgroundTwo] = useState(null)

    async function getPDF() {
        await new ConfirmationPDFGenerator({ page1: backgroundOne, page2: backgroundTwo }, formattedRows, quoteData, company, owner, contactPerson, shippingCost, shippingAdres, shipment, deliveryTime, price, true, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText, chosenAmount).getPDF();
    }

    return (
        <Block title={'Bevestiging'} headerStyle={{ backgroundColor: 'white' }} name={'confirmationSettings'} headerRightSideChildren={<>
            <HasRole roles={['admin']}>
                <button className={'btn btn--icon-right'} onClick={() => getPDF()}>Bekijk PDF <Icon name={'eye'} /></button>
            </HasRole>
            <button className={'btn btn--icon-right btn--black'} onClick={(e) => saveConfirmation(e)}>Opslaan <Icon name={'save'} /></button>
        </>}>
            <ConfirmationPdfEditor
                revisionedCalculations={revisionedCalculations}
                activeStep={activeStep}
                language={language}
                shippingCost={shippingCost}
                setShippingCost={setShippingCost}
                shippingAdres={shippingAdres}
                setShippingAdres={setShippingAdres}
                shipment={shipment}
                setShipment={setShipment}
                deliveryTime={deliveryTime}
                setDeliveryTime={setDeliveryTime}
                price={price}
                setPrice={setPrice}
                date={date}
                setDate={setDate}
                chosenCalculationId={chosenCalculationDataId}
                company={company}
                contactPerson={contactPerson}
                formattedRows={formattedRows}
                setFormattedRows={setFormattedRows}
                rowData={rowData}
                setRowData={setRowData}
                chosenAmount={chosenAmount}
                quote={quoteData}
                chosenPDFTemplateId={chosenPDFTemplateId}
                calculationData={json}
                setEditorValue={setEditorValue}
                editorValue={editorValue}
                editorRef={editorRef}
                signature={signature}
                owner={owner}
                placeOfOrigin={placeOfOrigin}
                setPlaceOfOrigin={setPlaceOfOrigin}
                introLine={introLine}
                setIntroLine={setIntroLine}
                footerData={footerData}
                setFooterData={setFooterData}
                footerData2={footerData2}
                setFooterData2={setFooterData2}
                footerText={footerText}
                setFooterText={setFooterText}
                backgroundOne={backgroundOne}
                setBackgroundOne={setBackgroundOne}
                backgroundTwo={backgroundTwo}
                setBackgroundTwo={setBackgroundTwo}
            />
        </Block>
    )
}
