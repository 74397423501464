import { isEmpty } from "lodash";

export function getDateFromUTC(date) {
    const returnValue = new Date(date);
    returnValue.setHours(0, 0, 0, 0);
    return returnValue;
}

export function finalizeOrderChanges(items, dif, draggingItem) {
    if (dif > 0) {
        // Get the dependents as multidimensional array
        const dependents = getDependentsToMove(dif, items, draggingItem, true);
        // Update the dates of the dragging item and its dependents
        items.machines = updateDates([...items.machines], dependents, draggingItem, dif);
    }

    if (dif < 0) {
        // Convert the dependencies to an array of uuids
        draggingItem.dependsOn = draggingItem.dependsOn.map(d => typeof d === 'string' ? d : d.id);
        // Get the dependencies as multidimensional array
        const dependencies = getDependenciesToMove(dif, items, draggingItem, true);
        // Update the dates of the dragging item and its dependencies
        items.machines = updateDates([...items.machines], dependencies, draggingItem, dif);
    }

    return items;
}

function getDependentsToMove(dif, items, parentItem, isDependentOnDraggingItem = false, existingDependentUUIDs = [], depth = 0) {
    const dependents = [];

    if (depth > 100) {
        return dependents;
    }

    if(parentItem.dependents && !isEmpty(parentItem.dependents)) {
        for (const dependent of parentItem.dependents) {
            if (!existingDependentUUIDs.includes(dependent.uuid)) {
                // Get the target dependent
                const targetItem = getItemByUuid(items.machines, dependent.uuid);

                if(targetItem) {
                    // Make sure the item should move with the dragging item
                    if (!shouldItemMove(dif, parentItem, targetItem, isDependentOnDraggingItem)) {
                        continue;
                    }

                    targetItem.dependents = getDependents(targetItem, items.machines);

                    existingDependentUUIDs.push(targetItem.uuid);

                    // Push the dependent to the dependents array and get the dependents of the dependent
                    dependents.push(targetItem, ...getDependentsToMove(dif, items, targetItem, false, existingDependentUUIDs, depth + 1));
                }
            }
        }
    }

    return dependents;
}

function getDependenciesToMove(dif, items, parentItem, isDependencyOfDraggingItem = false, existingDependencyUUIDs = [], depth = 0) {
    const dependencies = [];

    if (depth > 100) {
        return dependencies;
    }

    for (const dependency of parentItem.dependsOn) {
        if (!existingDependencyUUIDs.includes(dependency)) {
            // Get the target dependent
            const targetItem = getItemByUuid(items.machines, dependency);

            if (!targetItem) {
                continue;
            }

            // Make sure the item should move with the dragging item
            if (!shouldItemMove(dif, parentItem, targetItem, isDependencyOfDraggingItem)) {
                continue;
            }

            existingDependencyUUIDs.push(targetItem.uuid);

            // Push the dependent to the dependents array and get the dependents of the dependent
            dependencies.push(targetItem, ...getDependenciesToMove(dif, items, targetItem, false, existingDependencyUUIDs, depth + 1));
        }
    }

    return dependencies;
}

function getItemByUuid(machines, uuid) {
    for (const machine of machines) {
        for (const planningItem of machine.planning) {
            if (planningItem.uuid === uuid) {
                return planningItem;
            }
        }
    }
}

/**
 * Determines whether the given item should move with the dragging item
 * @param dif
 * @param parentItem
 * @param targetItem
 * @param isDirectlyConnectedToDraggingItem
 * @returns {boolean}
 */
function shouldItemMove(dif, parentItem, targetItem, isDirectlyConnectedToDraggingItem = false) {
    // The date the parent item is on before the drag
    const dateBeforeDrag = new Date(parentItem.date);

    // The date the parent item is on after the drag
    const draggedToDate = new Date(parentItem.date);
    draggedToDate.setUTCDate(draggedToDate.getUTCDate() + dif);

    // The date the target item is on
    const targetItemDate = new Date(targetItem.date);

    // calculate the number of days between the parent item and the target item
    const dateDiff = getDateDiffWithoutSunday(targetItemDate, dateBeforeDrag);

    // If both items belong to the same parent, return true if the date difference is 1 day or none
    if(targetItem.parentId === parentItem.parentId) {
        // If dateDiff is 1 day and the difference is in the direction the item is located
        if((dif > 0 && dateDiff === 1) || (dif < 0 && dateDiff === -1)) return true;
        // The moved item is comming from underneath or above the target item
        if((!(dateDiff > 6) && !(dateDiff < -6)) && (dif === 7 || dif === -7)) return true;
        // other cases
        return false;
    }
    // If this item is a direct dependent or dependency of the dragging item, it should only move with the dragging item if the date difference is 0
    else if (isDirectlyConnectedToDraggingItem) {
        // If difference is more than i day but the repositioning is vertically
        if((dif > 1 || dif < -1) && (dateDiff < 6 && dateDiff > -6)) {
            return true;
        }
        // If the date difference is 0, return true to move the item
        return dateDiff === 0;
    }
    // If the item is not directly connected, check if the item is the next (for dif > 1) or previous (for dif < 1) item, or if the item is on the same date
    else if ((dif > 0 && dateDiff === 1) || (dif < 0 && dateDiff === -1) || dateDiff === 0) {
        return true;
    }
    // If none of the above checks returned true, return false
    return false;
}

/**
 * Update the dates of the given item and its dependents
 * @param machines
 * @param dependents
 * @param draggingItem
 * @param dif
 */
function updateDates(machines, dependents, draggingItem, dif) {

    const draggingItemDate = new Date(draggingItem.date);
    draggingItemDate.setUTCDate(draggingItemDate.getUTCDate() + dif);
    draggingItem.date = draggingItemDate.toISOString().replace(/T[0-9:.]+Z/, '');

    for (const machine of machines) {
        for (const planningItem of machine.planning) {
            if (dependents.map((d) => d.uuid).includes(planningItem.uuid) || planningItem.uuid === draggingItem.uuid) {
                const oldDate = new Date(planningItem.date);
                oldDate.setUTCDate(oldDate.getUTCDate() + dif);

                // Skip sundays
                if (oldDate.getUTCDay() === 0) {
                    oldDate.setUTCDate(oldDate.getUTCDate() + (dif > 0 ? 1 : -1));
                }

                planningItem.date = oldDate.toISOString().replace(/T[0-9:.]+Z/, '');
            }
        }
    }

    return machines;
}

/**
 * Calculates the difference between the two given dates.
 * Don't count sundays
 * @param date1 The target date
 * @param date2 The original date
 * @returns {number}
 */
function getDateDiffWithoutSunday(date1, date2) {
    // Convert both params to Date objects
    date1 = new Date(date1);
    date2 = new Date(date2);

    if (date1 < date2) {
        return -getDateDiffWithoutSunday(date2, date1);
    }

    // Reset the hours, minutes, seconds and milliseconds
    date1.setUTCHours(0, 0, 0, 0);
    date2.setUTCHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds
    const diff = date1.getTime() - date2.getTime();

    // Calculate the difference in days
    const numDaysDiff = diff / (1000 * 60 * 60 * 24);

    // Calculate the number of sundays in the difference
    const numSundays = Math.floor((Math.abs(numDaysDiff) + Math.abs(date1.getUTCDay() - date2.getUTCDay())) / 7);

    // Convert the difference to days without sundays
    return numDaysDiff - numSundays;
}

// Get the dependents based on a given UUID
export function getDependents(item, allPlannedItems, startDate = null, endDate = null) {
    return allPlannedItems
        .filter(plannedItem => plannedItem?.planning)
        .map(plannedItem => plannedItem.planning)
        .flat()
        .filter(planningItem => planningItem?.dependsOn?.map(dependency => typeof dependency === 'string' ? dependency : dependency?.id)?.includes(item.uuid))
        .map((dependent) => {
            const itemsInThisWeek = allPlannedItems.filter((plannedItem) => {
                if (startDate !== null && plannedItem.planning.every(planningDay => new Date(planningDay.date) < new Date(startDate))) {
                    return false;
                }

                if (endDate !== null && plannedItem.planning.every(planningDay => new Date(planningDay.date) > new Date(endDate))) {
                    return false;
                }

                return true;
            });

            const itemIndex = itemsInThisWeek.findIndex((plannedItem) => plannedItem.planning.find((planningItem) => planningItem.uuid === item.uuid));
            const dependencyIndex = itemsInThisWeek.findIndex((plannedItem) => plannedItem.planning.find((planningItem) => planningItem.uuid === dependent.uuid));
            const xOffset = getXOffset(item.date, dependent.date);
            const yOffset = itemIndex - dependencyIndex;

            return {
                uuid: dependent.uuid,
                yOffset,
                xOffset,
            }
        });
}

export function getXOffset(currentDay, dependencyDay) {
    const currentDayDate = getDateFromUTC(currentDay);
    const dependencyDayDate = getDateFromUTC(dependencyDay);
    return (dependencyDayDate - currentDayDate) / (1000 * 60 * 60 * 24);
}

export function formatTime(time, useBrackets = true) {
    const hours = Math.floor(time);
    const minutes = Math.round((time % 1) * 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    const formattedString = `${formattedHours}:${formattedMinutes}`;
    return useBrackets ? `(${formattedString})` : formattedString;
}
