import MaterialChoiceWidget from "./MaterialChoiceWidget";
import {TrenderType} from "./WidgetTypes"
import SelectWidget from "./SelectWidget";
import ReturnWidget from "./ReturnWidget";
import InputWidget from "./InputWidget";
import Subtraction from "./Subtraction";
import IntWidget from "./IntWidget";
import Addition from "./Addition";
import Division from "./Division";
import IWidget from "./IWidget";
import Times from "./Times";
import CeilWidget from "./CeilWidget";
import FloorWidget from "./FloorWidget";
import HourWidget from "./HourWidget";
import IfWidget from "UI/App/Partials/Content/Calculations/Widgets/Widgets/IfWidgetV2";

export default class WidgetCollection {
    public renderType: TrenderType
    public debug: boolean
    public showVisibilityCheckbox:boolean;
    private widgetArray: { [key: any]: IWidget }
    private optionalWidgetData?: object

    constructor(renderType: TrenderType, callback: () => any | null, saveWidget: () => any | null, optionalData: any | undefined, debug: boolean = false, prefix: string | undefined,showVisibilityCheckbox:boolean,allowHidden:boolean = true) {
        this.renderType = renderType;
        this.optionalWidgetData = optionalData;
        this.debug = debug;
        this.showVisibilityCheckbox = showVisibilityCheckbox === undefined ? false : showVisibilityCheckbox;

        /**
         * Widget array to store widgets
         */
        this.widgetArray = {
            'Int': new IntWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'Addition': new Addition(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'Subtraction': new Subtraction(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'Times': new Times(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'Division': new Division(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'Input': new InputWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug,this.showVisibilityCheckbox,allowHidden),
            'MaterialChoice': new MaterialChoiceWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug,this.showVisibilityCheckbox,allowHidden),
            'SelectWidget': new SelectWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug,this.showVisibilityCheckbox,allowHidden),
            'ReturnWidget': new ReturnWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'CeilWidget': new CeilWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'FloorWidget': new FloorWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            'HourWidget': new HourWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            // 'StartDataWidget': new StartDataWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            // 'WidgetCollectionWidget': new WidgetCollectionWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug),
            // 'ParenthesesWidget': new ParenthesesWidget(this.renderType, callback, saveWidget,prefix, this.optionalWidgetData, this.debug),
            'IfWidget': new IfWidget(this.renderType, callback, saveWidget, prefix, this.optionalWidgetData, this.debug)
        }
    }

    /**
     * @returns array
     */
    getWidgets() {
        let widgetArray = [];

        for (const widget in this.widgetArray) {
            widgetArray.push(this.widgetArray[widget].getHTML(this.widgetArray[widget]))
        }

        return widgetArray;
    }

    /**
     * Get specific widget and return html of said widget
     * @param widgetType
     */
    getWidget(widgetType: string, editData: object | null = null) {
        return this.widgetArray[widgetType].getHTML(this.widgetArray[widgetType], editData);
    }

    /**
     * Get specific widget and return html of said widget
     * @param widgetType
     */
    async getWidgetData(widgetType: string,element:string) {
        return await this.widgetArray[widgetType].provideData(element);
    }

    /**
     * Runs calculation functionality
     * @param widgetType
     */
    runCalculation(widgetType: string) {
        return this.widgetArray[widgetType].calculationFunctionality();
    }
}
