import {TFile} from "App/Strapi/@types/File";
import {TRole} from "App/Strapi/@types/Role";
import {TUser} from "App/Strapi/@types/User";
import {tryParse} from "App/Util/format";
import isEmpty from "lodash/isEmpty";

export default class User implements TUser {
    blocked: boolean;
    confirmed: boolean;
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    msUserId: string;
    password: string;
    profileImage: TFile;
    provider: string;
    role: TRole;
    roles: Array<TRole>;
    salesManager: boolean;
    signature: TFile;
    updatedAt: string;
    username: string;

    constructor(blocked: boolean, confirmed: boolean, createdAt: string, email: string, firstName: string, id: number, lastName: string, msUserId: string, password: string, profileImage: TFile, provider: string, role: TRole, roles: Array<TRole>, salesManager: boolean, signature: TFile, updatedAt: string, username: string) {
        this.blocked = blocked;
        this.confirmed = confirmed;
        this.createdAt = createdAt;
        this.email = email;
        this.firstName = firstName;
        this.id = id;
        this.lastName = lastName;
        this.msUserId = msUserId;
        this.password = password;
        this.profileImage = profileImage;
        this.provider = provider;
        this.role = role;
        this.roles = roles;
        this.salesManager = salesManager;
        this.signature = signature;
        this.updatedAt = updatedAt;
        this.username = username;
    }

    static get(): User|false {
        const user: TUser = tryParse(localStorage.getItem('userInfo'));

        if (isEmpty(user)) return false;

        return new User(user.blocked, user.confirmed, user.createdAt, user.email, user.firstName, user.id, user.lastName, user.msUserId, user.password, user.profileImage, user.provider, user.role, user.roles, user.salesManager, user.signature, user.updatedAt, user.username);
    }

    static hasRole(roles: string[]): boolean {
        const user: TUser = tryParse(localStorage.getItem('userInfo'));

        if (isEmpty(user)) return false;

        const self = new User(user.blocked, user.confirmed, user.createdAt, user.email, user.firstName, user.id, user.lastName, user.msUserId, user.password, user.profileImage, user.provider, user.role, user.roles, user.salesManager, user.signature, user.updatedAt, user.username);

        return roles.some(role => self.role.name.toLowerCase() === role.toLowerCase() || self.roles.some(r => r.name.toLowerCase() === role.toLowerCase()));
    }

    static isOwner(id: number): boolean {
        const user: TUser = tryParse(localStorage.getItem('userInfo'));

        const self = new User(user.blocked, user.confirmed, user.createdAt, user.email, user.firstName, user.id, user.lastName, user.msUserId, user.password, user.profileImage, user.provider, user.role, user.roles, user.salesManager, user.signature, user.updatedAt, user.username);

        return self.id === id;
    }
}
