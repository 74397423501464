import Select from "react-select";
import IF from "UI/App/Components/Conditional/IF";
import {Fragment, useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import {formatCurrency} from "App/Util/format";
import Grid from "UI/App/Components/Grid/Grid";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import qs from "qs";
import isEmpty from 'lodash/isEmpty';
import Block from "UI/App/Components/Block/Block";
import {CalculationProviderContext} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";

export default function PrintTab({ printData, setPrintData, customerId }) {
    const { authAxios } = useContext(FetchContext);
    const { activeTab } = useContext(CalculationProviderContext);

    const [calculations, setCalculations] = useState([]);
    const [isFetchingCalculation, setIsFetchingCalculation] = useState(false);

    useEffect(() => {
        setIsFetchingCalculation(true);
        const query = qs.stringify({
            fields: [
                'name',
                'id',
                'customerReference',
                'number',
            ],
            sort: 'id:desc',
            filters: {
                owner: 'pretore',
            }
        })
        authAxios.get(`/calculations/all?${query}`).then(({ data }) => {
            let optionsArray = data.filter(calc => (calc.customerReference || calc?.name) && calc?.number).map(calc => ({ label: `[${calc?.number}] ${(calc?.customerReference ?? calc?.name)}`, value: calc?.id }))

            optionsArray.unshift({ label: "Geen calculatie", value: undefined })

            setCalculations(optionsArray)
        }).catch((err) => {
            console.error(err);
            toast.error("Er is iets fout gegaan bij het ophalen van de calculaties!");
        }).then(() => setIsFetchingCalculation(false));
    }, [authAxios, customerId]);

    function getCalculation(id) {
        if (id === undefined) {
            const newPrintData = {
                id: 0,
                placeHolder: true,
            }
            setPrintData(newPrintData);
            return;
        }
        setIsFetchingCalculation(true);
        authAxios.get(`/calculations/${id}?populate=*`).then(({ data: {data} }) => {
            const newPrintData = {
                id: id,
                customerReference: data.customerReference,
                number: data.number,
                amounts: data?.data?.amounts,
                format: {
                    width: data?.data?.width,
                    length: data?.data?.length
                },
                summary: data?.data?.summary
            }
            setPrintData(newPrintData);
        }).catch((err) => {
            console.error(err);
            toast.error("Er is iets fout gegaan bij het ophalen van de calculatie!");
        }).then(() => setIsFetchingCalculation(false));
    }

    return (
        <div className={"hjmg__calculation__tab"} data-active={activeTab === 'print'}>
            <label>
                Calculatie:
                <Select
                    options={calculations}
                    value={calculations.find(calc => calc.value === printData?.id)}
                    onChange={newValue => getCalculation(newValue.value)}
                    isDisabled={isFetchingCalculation}
                    isLoading={isFetchingCalculation}
                    noOptionsMessage={() => "De gekozen klant heeft nog geen bestaande calculaties!"}
                />
            </label>

            <IF condition={!isEmpty(printData) && printData?.placeHolder !== true}>
                <SpinnerOverlay visible={isFetchingCalculation}>
                    <Block name={'Print'} title={'Calculatie details'} contentStyle={{backgroundColor: 'var(--light-grey)'}} style={{marginBottom: 0}}>
                        <Grid columns={2} gap={'2px'} customColTemplate={"200px 1fr"}>
                            <b>Klant referentie:</b> <span>{printData?.customerReference}</span>
                            <b>Nummer:</b> <span>{printData?.number}</span>
                            <b>Aantallen:</b> <span>{printData?.amounts?.join(', ')}</span>
                            <b>Formaat:</b> <span>{printData?.format?.width} x {printData?.format?.length} mm.</span>
                            <span><br/></span><span><br/></span>
                            <b>Aantal:</b><b>Totaalbedrag:</b>
                            {printData?.amounts?.map((amount, index) => <Fragment key={index}><span>{amount}:</span><span>{formatCurrency(printData?.summary[amount]?.total)}</span></Fragment>)}
                        </Grid>
                    </Block>
                </SpinnerOverlay>
            </IF>
        </div>
    )
}
