import {Link, useNavigate, useParams} from "react-router-dom";
import Button from "../../../../Components/Button/Button";
import Block from "../../../../Components/Block/Block";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import Grid from "../../../../Components/Grid/Grid";
import {toast} from "react-toastify";
import Icon from "UI/App/Components/Icon/Icon";
import capitalize from "lodash/capitalize";
import Container from "UI/App/Components/Container/Container";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import IF from "UI/App/Components/Conditional/IF";
import Table from "UI/App/Components/Table/Table";
import HasRole from "UI/App/Components/Auth/HasRole";
import Popup, {closePopup, openPopup} from "../../../../Components/Popup/Popup";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function Quote() {
    const [quoteConfirmation, setQuoteConfirmation] = useState(undefined);
    const [contactPerson, setContactPerson] = useState(null);
    const [calculations, setCalculations] = useState(null);
    const [copiedFrom, setCopiedFrom] = useState(null);
    const [status, setStatus] = useState('concept');
    const [quotePdf, setQuotePdf] = useState(null);
    const fetchContext = useContext(FetchContext);
    const [company, setCompany] = useState(null);
    const [base64, setBase64] = useState(null);
    const [copies, setCopies] = useState([]);
    const [quote, setQuote] = useState(null)
    const navigate = useNavigate();
    const params = useParams();
    const { width } = useWindowDimensions();
    const statusses = [
        'concept',
        'order',
        'uitgebracht',
        'herzien',
        'geaccepteerd',
        'afgewezen prijs',
        'afgewezen planning',
        'afgewezen klant',
        'afgewezen overig',
        'verlopen',
    ];

    // get the table data using the useEffect hook
    useEffect(() => {

        fetchContext.authAxios.get(`/quotes/${params.quoteId}?populate[0]=company.address&populate[1]=contactPerson&populate[2]=calculations&populate[3]=copiedFrom&populate[4]=copies`).then(({data}) => {
            setContactPerson(data.data.contactPerson)
            setCopiedFrom(data.data.copiedFrom)
            setBase64(data.data.pdfBase64);
            setCompany(data.data.company)
            setCopies(data.data.copies)
            setQuote(data.data);

            let tableData = [];

            for (const calculation of data.data.calculations) {
                tableData.push({
                    attributes: {
                        onClick: () => {
                            navigate(`/calculations/${calculation.id}/view`)
                        }
                    },
                    data: [
                        <div className='owner-indicator'>
                            <IF condition={typeof calculation?.owner === 'string'}>
                                <span className={`circle circle-${calculation?.owner?.toLowerCase()}`}></span>
                            </IF>
                        </div>,
                        calculation.number,
                        calculation.description,
                        calculation.customerReference
                    ]
                })
            }

            setCalculations(tableData);
            setStatus(data.data.status)
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });

        fetchContext.authAxios.get(`/quotes/confirmations?filters[quote][id]=${params.quoteId}`).then(({data}) => {
            setQuoteConfirmation(data.data[0])
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });


    }, [params.quoteId]);

    const updateStatus = (e, quoteId) => {
        setStatus(e.target.value);
        fetchContext.authAxios.put(`/quotes/${quoteId}`, {
            "data": {
                "status": e.target.value
            }
        }).then(({data}) => {
            toast.success(`Offerte: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });
    }
    const [copyTableData, setCopyTableData] = useState([]);

    useEffect(() => {
        let tableData = [];

        for (const copy of copies) {
            console.log(copy);
            tableData.push({
                attributes: {
                    onClick: () => {
                        navigate(`/quotes/${copy.id}`)
                    }
                },
                data: [
                    copy?.number,
                    copy?.description
                ]
            });
        }

        setCopyTableData(tableData);
    }, [copies])

    return (
        <>
            <Popup popupID='CopyOrRevision' title={'Kopie of revisie'}>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/quotes/${params.quoteId}/copy`)
                }}>
                    Kopie
                </Button>
                <Button onClick={() => {
                    closePopup('CopyOrRevision')
                    navigate(`/quotes/${params.quoteId}/revision`)
                }}>
                    Revisie
                </Button>
            </Popup>
            <Container>
                <PageHeader title={`Offerte: ${quote?.number} ${quote?.description ? '- ' + quote?.description : ''}`}>
                    <HasRole roles={['admin']}>
                        <Button data-title={'Offerte kopiëren'} className={'btn btn--black btn--round'} onClick={() => {
                            openPopup("CopyOrRevision")
                        }}><Icon name={'copy'}/></Button>
                        {/*<Button data-title={'Kopiëren'} className={'btn btn--black btn--round'} to={`/quotes/${quote?.id}/copy`}><Icon name={'copy'} /></Button>*/}
                    </HasRole>
                    {/*<IF condition={quote?.calculations.every(calc => calc.owner === 'pretore')}>*/}
                        <Button data-title={'Bevestigen'} className={'btn btn--black btn--round'} to={`/orders/confirmations/${quote?.id}`}><Icon name={'file-check'}/></Button>
                    {/*</IF>*/}
                </PageHeader>
                <Grid columns={2} customColTemplate={width > 900 ? '2fr 5fr' : '1fr'}>
                    <Grid
                        columns={width > 900 || width <= 650 ? '1' : '2'}
                        rows={'1'}
                    >
                        <Block style={{gridRow: '1', gridColumn: width > 900 || width <= 650 ? '1' : '1 / 3'}} name={'quoteInfo'} title={"Informatie"}>
                            <Grid columns={1} gap={'10px'}>
                                <span>
                                    <b>Status:</b><br/>
                                    <select
                                        value={status}
                                        onChange={(e, id) => {
                                            updateStatus(e, quote?.id)
                                        }}>
                                        {statusses.map((status, id) =>
                                            <option key={id} value={status}>{capitalize(status)}</option>
                                        )}
                                    </select>
                                </span>
                                <span>
                                    <b>Bedrijf:</b><br/>
                                    {company !== null ? <Link to={`/crm/companies/${company.id}`}>{company?.name}</Link> : ''}
                                </span>
                                <span>
                                    <b>Contactpersoon:</b><br/>
                                    {contactPerson !== null ? <Link to={`/crm/companies/${company.id}/contactpersons/${contactPerson.id}`}>{`${contactPerson?.firstName} ${(contactPerson?.prefixToSurname ?? '')} ${contactPerson?.surname}`.replace('  ', ' ')}</Link> : ''}
                                </span>
                                <IF condition={company && company?.address}>
                                    <span>
                                        <b>Adres:</b><br/>
                                        {company?.address.addressLine1}<br/>
                                        <IF condition={company?.address.addressLine2}>
                                            {company?.address.addressLine2}<br/>
                                        </IF>
                                        <IF condition={company?.address.addressLine3}>
                                            {company?.address.addressLine3}<br/>
                                        </IF>
                                        {company?.address.postalCode} {company?.address.city}<br/>
                                        <IF condition={company?.address.province}>
                                            {company?.address.province}&nbsp;
                                        </IF>
                                        {company?.address.country}<br/>
                                    </span>
                                </IF>
                                <IF condition={copiedFrom !== null && copiedFrom !== undefined}>
                                    <span>
                                        <strong>Revisie van:</strong><br/>
                                        <Link to={`/quotes/${copiedFrom?.id}`}>{copiedFrom?.number}</Link>
                                    </span>
                                </IF>
                            </Grid>
                        </Block>

                        {copyTableData.length !== 0 ? <>
                            <Block style={{gridRow: '2', gridColumn: '1'}} name={'Revisies'} title={'Revisies'} useSmallerPadding={true}>
                                <Table
                                    structure={['145px', '100%']}
                                    data={copyTableData}
                                    overflowText={true}
                                    border='row'
                                    hover
                                />
                            </Block>
                        </> : <></>}
                        <Block style={{gridRow: (width > 900 || width <= 650) && copyTableData.length !== 0 ? '3' : '2', gridColumn: copyTableData.length !== 0 ? (width > 900 || width <= 650) ? '1' : '2' : (width > 900 || width <= 650) ? '1' : '1 / 3'}} name={'Calculaties'} title={'Calculaties'} useSmallerPadding={true}>
                            <Table
                                structure={['40px', '150px', '100%']}
                                data={calculations}
                                overflowText={true}
                                border='row'
                                hover
                            />
                        </Block>
                    </Grid>
                    <Block
                        title={'PDF'}
                        name={'quotePDF'}>
                        <Grid columns={1}>
                            <embed
                                style={{
                                    height: '800px', width: '100%'
                                }}
                                src={base64 !== null ? `data:application/pdf;base64,${base64}` : ''}/>
                        </Grid>
                    </Block>
                </Grid>
            </Container>
        </>
    )
}
