import CalculationResult from "UI/App/Partials/Content/Calculations/HJMG/steps/CalculationResultTab/CalculationResult";
import {memo, useEffect} from "react";
import Block from "UI/App/Components/Block/Block";
import IF from "UI/App/Components/Conditional/IF";

function CalculationResultTab({
    activeTab,
    results,
    setResults,
    calculationData,
    printData,
    setPrintData,
    saveCalculation,
    calculationNumber,
    checklistData,
    language,
}) {

    useEffect(() => {
        setTimeout(() => {
            console.log(activeTab === 'result');
            activeTab === 'result' && saveCalculation();
        }, 300)
    }, [activeTab]);

    return (
        <div className={"hjmg__calculation__tab"} data-active={activeTab === 'result'}>
            <IF condition={checklistData && checklistData?.length !== 0 && checklistData.some(row => row.value !== '')}>
                <Block name={'checklist'} title={'Checklist'}>
                    <ul className={'checklist'} style={{listStyle: 'none'}}>
                        {checklistData.filter(row => row.value && row.name).map(row => (
                            <li>
                                <span className={'name'}>{(language === 'nl' ? row.dutchName : row.englishName) ?? row.name}</span>
                                :
                                <span className={'value'}>{row.value}</span>
                            </li>
                        ))}
                    </ul>
                </Block>
            </IF>

            <CalculationResult
                calculationNumber={calculationNumber}
                results={results}
                setResults={setResults}
                calculationData={calculationData}
                printData={printData}
                setPrintData={setPrintData}
                checklistData={checklistData}
                saveCalculation={saveCalculation}
                activeTab={activeTab}
            />
        </div>
    )
}

export default memo(CalculationResultTab);
