import {useEffect, useState} from "react";
import PDF from "App/Util/print";
import './calculationPrint.scss'
import Grid from "../../../../../Components/Grid/Grid";
import Table from "../../../../../Components/Table/Table";
import Block from "../../../../../Components/Block/Block";
import {formatCurrency, formatDate, formatNumberValue, formatSmallCurrency} from "App/Util/format";
import autoTable from "jspdf-autotable";
import './calculationResult.scss';
import {useNavigate} from "react-router-dom";
import isObject from "lodash/isObject";
import IF from "UI/App/Components/Conditional/IF";
import InformationBlock from "UI/App/Partials/Content/Calculations/Calculations/result/InformationBlock";
import SimpleCalculationResult from "UI/App/Partials/Content/Calculations/Calculations/result/SimpleCalculationResult";

export default function CalculationResult({copies, copiedFrom, storeCalculation, company, setData, renderInfo = false, checklistContent, setTotalResultJson, data, calculationNumber = '', loading = false, calculationStatus, setCalculationStatus, saveButtonEnabled = true, language,viewTypeOverwrite = null}) {
    const [calculationDimensions, setCalculationDimensions] = useState(null);
    const [manualLaborTableData, setManualLaborTableData] = useState(null);
    const [thirdPartyTableData, setThirdPartyTableData] = useState(null);
    const [machineScheduleData, setMachineScheduleData] = useState([]);
    const [materialCostTable, setMaterialCostTable] = useState([]);
    const [thirdPartyQuotes, setThirdPartyQuotes] = useState(null);
    const [machineCostsData, setMachineCostsData] = useState([]);
    const [checklistTable, setChecklistTable] = useState(<></>);
    const [informationData, setInformationData] = useState([]);
    const [formattedResult, setFormattedResult] = useState([]);
    const [resultValues, setResultValues] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [laborData, setLaborData] = useState([]);
    const [amounts, setAmounts] = useState(null);

    // The view type is set to 'simple' by default
    const [viewType, setViewType] = useState('simple');

    const toggleViewType = () => {
        setViewType((viewType) => viewType === 'simple' ? 'advanced' : 'simple');
    };

    // Used for overwriting viewtyp
    useEffect(() => {
        if(viewTypeOverwrite !== null){
            setViewType(viewTypeOverwrite);
        }
    }, [viewTypeOverwrite]);

    const getTotalMaterialCost = (summary) => {
        for (const amount of data.amounts) {
            const materialResults = Object.values(data.rawResult)
                // Get the total material costs
                .map(tab => tab['Totaal.Totale materiaal kosten'])
                // Filter out undefined values which is the case for the result tab for instance
                .filter(values => values !== undefined)
                // Get the value for the current amount
                .map(values => values[amount])
                // Should always exist, but just to be sure
                .filter(value => value !== undefined)
                // Convert to number to avoid adding strings
                .map(value => Number(value))
                // Filter out NaN values to prevent adding them
                .filter(value => !isNaN(value))
                // Add all values together
                .reduce((acc, value) => acc + value, 0);

            summary[amount] = {
                ...summary[amount],
                materials: materialResults
            }
        }

        return summary;
    }

    const navigate = useNavigate();

    useEffect(() => {
        // Load table data
        load();

        if (checklistContent !== undefined) {
            setChecklistTable('');
            for (const listItem of checklistContent) {
                if (listItem.value === '' || listItem.value === null || listItem.value === undefined) continue;
                setChecklistTable(prevState => [...prevState, <li key={listItem?.id}><span className={'name'}>{(language === 'nl' ? listItem.dutchName : listItem.englishName) ?? listItem.name}</span>: <span className={'value'}>{listItem.value}</span></li>])
            }
        }

        if (data === null || data === undefined) {
            console.warn('Data is null')
            return;
        }

        setAmounts(data.amounts);
        setCalculationDimensions({length: data.length, width: data.width});

        if (data.summary) {
            const resultValues = getTotalMaterialCost({...data.summary});
            setResultValues(resultValues);
        } else {
            const result = {};
            for (const amount of data.amounts) {
                const subTotal = data.calculationData['Totaal.Subtotaal']?.[amount] ?? 0;
                const materialTotal = data.calculationData['Totaal.Totale materiaal kosten']?.[amount] ?? 0;
                result[amount] = {
                    amount: amount,
                    materials: materialTotal,
                    subTotal: subTotal,
                    margin: 50,
                    workflow: 0,
                    total: subTotal * 1.5,
                    quote: subTotal * 1.5,
                };
            }

            setResultValues(result);
        }

        if (data?.templateData?.data === undefined) {
            console.warn('Template data is undefined!');
            return;
        }

        /**
         * Check if necessary values are set
         */
        let results = {};
        if (data.values !== null && data.templateData !== null && data.amounts !== null) {
            setTableHeaders(['Naam'])
            for (const amount of data.amounts) {
                setTableHeaders(prevState => [...prevState, amount])
            }

            /**
             * Loop trough machines
             */
            for (const [machineKey, machine] of Object.entries(data.templateData.data).sort(([_, a], [__, b]) => a.position - b.position)) {
                if (data.values[machineKey] === undefined) {
                    continue;
                }

                /**
                 * If machine key us machine
                 */
                if (machineKey !== 'result' || machineKey !== 'amounts' || machineKey !== 'calculation_result') {
                    /**
                     * Loop amounts
                     */
                    for (const amount of data.amounts) {
                        if (machine.widgets !== undefined) {
                            for (const widget of machine.widgets) {
                                if (widget.data.type !== 'ReturnWidget') {
                                    continue;
                                }

                                /**
                                 * Get name for return
                                 */
                                let name = '';
                                let subName = null;
                                let returnType = null
                                const tabName = data.templateData.data?.[widget.parentId]?.name || data.templateData.data?.[widget.parentId]?.constants.find(constant => constant.id?.endsWith('const-name'))?.value + ` (${widget.prefix.replace('-', '')})` || `Naamloos (${widget.prefix.replace('-', '')})`;
                                if (widget.data.title.includes(".")) {

                                    returnType = widget.data.title.split(".")[0];
                                    if (widget.data.title.includes('widget')) {
                                        const resultName = getNameFromWidget(widget, data.values, amount, machine.constants)

                                        if (resultName !== undefined) {
                                            name = widget.data.title.split(".")[2];
                                            subName = `${tabName} (${resultName})`;
                                        }
                                    } else {
                                        name = `${tabName} (${widget.data.title.split('.')[1]})`
                                    }
                                } else {
                                    name = widget.data.title;
                                }

                                if (name === null) {
                                    console.warn('Name for widget not found widget not added to result')
                                    continue;
                                }

                                let value = getDataFromWidget(widget, data.values, amount, false);

                                if (results[returnType] === undefined) {
                                    results[returnType] = [];
                                }

                                if (isExcluded(widget)) {
                                    continue;
                                }

                                let sumValue = null
                                if (name.includes('Opstart kosten totaal')) {
                                    for (const widgetForFixedValue of machine.widgets) {
                                        if (widgetForFixedValue.data.title === 'Machinekosten.Opstart kosten per keer') {
                                            sumValue = `${getDataFromWidget(widgetForFixedValue, data.values, amount,)}`;
                                        }
                                    }

                                    for (const widgetForFixedValue of machine.widgets) {
                                        if (widgetForFixedValue.data.title === 'Machinekosten.Opstart keren') {
                                            value = `${getDataFromWidget(widgetForFixedValue, data.values, amount)} x ${sumValue} = ${value}`;
                                        }
                                    }
                                }

                                if (name.includes('Productie kosten per uur')) {
                                    for (const widgetForFixedValue of machine.widgets) {
                                        if (widgetForFixedValue.data.title === 'Machinekosten.Productie uren') {
                                            sumValue = `${getDataFromWidget(widgetForFixedValue, data.values, amount)}`;
                                        }
                                    }

                                    let tmp = '';
                                    for (const widgetForFixedValue of machine.widgets) {
                                        if (widgetForFixedValue.data.title === 'Machinekosten.Productie kosten per uur') {
                                            tmp = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                        }
                                    }

                                    for (const widgetForFixedValue of machine.widgets) {
                                        if (widgetForFixedValue.data.title === 'Machinekosten.Totale productie kosten zonder opstart') {
                                            value = `${sumValue} x ${tmp} = ${getDataFromWidget(widgetForFixedValue, data.values, amount)}`;
                                        }
                                    }
                                }

                                /**
                                 * Material
                                 */
                                if (returnType === 'Materiaal') {
                                    if (subName !== 'Inkt' && subName !== 'Usercharge' && subName !== 'Colorgrip') {
                                        if (name.includes('Totaalprijs')) {
                                            const totalPrice = getDataFromWidget(widget, data.values, amount);
                                            let amountPrice = 0;
                                            let widgetAmountValue = 0;
                                            const targetWidgetId = `${widget.data.title}`.replace('Materiaal.', '').replace('.Totaalprijs', '');
                                            for (const widgetForFixedValue of machine.widgets) {
                                                if (widgetForFixedValue.id === targetWidgetId) {
                                                    const widgetReturnData = getDataFromWidget(widgetForFixedValue, data.values, amount)
                                                    if (widgetReturnData !== null) {
                                                        if (widgetReturnData.data.value.result !== undefined) {
                                                            amountPrice = getDataFromWidget(widgetForFixedValue, data.values, amount).data.value.result.salePrice
                                                        }
                                                    }
                                                }

                                                if (widgetForFixedValue.data.type === "ReturnWidget") {
                                                    if (targetWidgetId === `${widgetForFixedValue.data.title}`.replace('Materiaal.', '').replace('.Aantal', '')) {
                                                        widgetAmountValue = getDataFromWidget(widgetForFixedValue, data.values, amount, false);
                                                    }
                                                }
                                            }


                                            value = `${widgetAmountValue} x ${formatSmallCurrency(amountPrice, 6)} = ${totalPrice}`
                                        }
                                    } else {
                                        if (name.includes('Totaalprijs')) {

                                            let totalPrice = 0;
                                            let amountPrice = 0;
                                            let widgetAmountValue = 0;

                                            for (const widgetForFixedValue of machine.widgets) {
                                                if (widgetForFixedValue.data.title.includes(`Materiaal.${subName}`)) {
                                                    if (widgetForFixedValue.data.title.includes(`Verkoopprijs`)) {
                                                        amountPrice = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                    }

                                                    if (widgetForFixedValue.data.title.includes(`Totaalprijs`)) {
                                                        totalPrice = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                    }

                                                    if (widgetForFixedValue.data.title.includes(`Aantal`)) {
                                                        widgetAmountValue = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                    }
                                                }
                                            }

                                            value = `${widgetAmountValue} x ${amountPrice} = ${totalPrice}`
                                        }
                                    }
                                }

                                /**
                                 * Arbeid
                                 */
                                if (returnType === 'Machinekosten') {
                                    // {machine naam} (Productie) e.g. Canon IX1200 (Productie)
                                    if (name.includes('Productie uren')) {
                                        name = name.replace('Productie uren', 'Productie');
                                        returnType = 'Arbeid'
                                        if (results[returnType] === undefined) {
                                            results[returnType] = [];
                                        }

                                        let totalAmount = getDataFromWidget(widget, data.values, amount);
                                        let labourEffort = 0;
                                        let widgetAmountValue = 0;

                                        for (const widgetForFixedValue of machine.widgets) {
                                            if (widgetForFixedValue.data.title.includes(`Arbeid`) && widgetForFixedValue.data.type === 'ReturnWidget') {
                                                if (widgetForFixedValue.data.title.includes(`Arbeidsuren`)) {
                                                    widgetAmountValue = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                }

                                                if (widgetForFixedValue.data.title.includes(`Arbeidsinspanning`)) {
                                                    labourEffort = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                }
                                            }
                                        }

                                        value = `${totalAmount} x ${labourEffort} = ${widgetAmountValue}`
                                    }

                                    // {machine naam} ({Opstart}) e.g. Canon IX1200 (Opstart)
                                    if (name.includes('Opstart keren')) {
                                        name = name.replace('Opstart keren', 'Opstart');
                                        returnType = 'Arbeid'
                                        if (results[returnType] === undefined) {
                                            results[returnType] = [];
                                        }

                                        let startUpTimes = getDataFromWidget(widget, data.values, amount);
                                        let individualStartupTime = 0;
                                        let totalStartupTime = 0;

                                        for (const widgetForFixedValue of machine.widgets) {
                                            if (widgetForFixedValue.data.title.includes(`Arbeid`) && widgetForFixedValue.data.type === 'ReturnWidget') {
                                                if (widgetForFixedValue.data.title.includes(`Opstart minuten totaal`)) {
                                                    totalStartupTime = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                }

                                                // if (widgetForFixedValue.data.title.includes(`Totaalprijs`)) {
                                                //     totalAmount = getDataFromWidget(widgetForFixedValue, data.values, amount, true);
                                                // }

                                                if (widgetForFixedValue.data.title.includes(`Opstart minuten per keer`)) {
                                                    individualStartupTime = getDataFromWidget(widgetForFixedValue, data.values, amount);
                                                }
                                            }
                                        }

                                        value = `${startUpTimes} x ${individualStartupTime} = ${totalStartupTime}`;
                                    }
                                }

                                /**
                                 * If material widget
                                 */
                                if (widget.data.title.includes(".")) {
                                    /**
                                     * Store value for rendering
                                     */
                                    if (returnType !== null) {
                                        if (results[returnType][amount] === undefined) {
                                            results[returnType][amount] = [];
                                        }

                                        if (subName !== null) {
                                            if (results[returnType][amount][subName] === undefined) {
                                                results[returnType][amount][subName] = [];
                                            }
                                            if (results[returnType][amount][subName][name] === undefined) {
                                                results[returnType][amount][subName][name] = [];
                                            }

                                            results[returnType][amount][subName][name] = value;
                                        } else {
                                            if (results[returnType][amount][name] === undefined) {
                                                results[returnType][amount][name] = [];
                                            }
                                            results[returnType][amount][name] = value;
                                        }
                                    }
                                } else {
                                    /**
                                     * Store value for rendering
                                     */
                                    if (returnType !== null) {
                                        if (results[returnType][name] === undefined) {
                                            results[returnType][name] = [];
                                        }

                                        if (results[returnType][name][amount] === undefined) {
                                            results[returnType][name][amount] = value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        fillResultTables(results, data)
    }, [data, checklistContent])

    const load = () => {
        let tableData = [];

        if (!data) {
            return
        }

        for (const thirdPartyData of (data?.thirdParty ?? [])) {

            if (thirdPartyData.id !== undefined) {
                tableData.push({
                    attributes: {
                        onClick: () => {
                            navigate(`/purchase/quotes/${thirdPartyData.id}`)
                        }
                    },
                    data: [
                        thirdPartyData.description,
                        thirdPartyData.number
                    ]
                })
            }
        }

        setThirdPartyQuotes(tableData);
    }

    const isExcluded = (widget) => {
        const excludedReturns = [
            'Machinekosten.Opstart kosten per keer',
            'Machinekosten.Totale productie kosten zonder opstart',
            'Aantal',
            'Verkoopprijs',
            'Arbeid.Arbeidsuren',
            'Arbeid.Arbeidsinspanning',
            'Arbeid.Opstart minuten per keer',
            'Arbeid.Opstart minuten totaal'
        ]


        for (const exclusion of excludedReturns) {
            if (widget.data.title.includes(exclusion)) {
                return true;
            }
        }

        return false;
    }

    const fillResultTables = (results, data) => {
        let formattedResult = {};
        for (const [resultKey, resultValues] of Object.entries(results)) {
            for (const [amount, machineValues] of Object.entries(resultValues)) {
                for (const [machineKey, machineValue] of Object.entries(machineValues)) {
                    const splitMachineNames = machineKey.split(/(\(.*?\)(?!\)))/);

                    if (splitMachineNames.at(-1) === '') {
                        splitMachineNames.pop();
                    }

                    splitMachineNames.pop();
                    const cleanName = splitMachineNames.join('');

                    // const machineName = machineKey
                    if (machineKey === '' || machineKey === ')') {
                        continue;
                    }

                    if (formattedResult[resultKey] === undefined) {
                        formattedResult[resultKey] = {};
                    }

                    if (formattedResult[resultKey][cleanName] === undefined) {
                        formattedResult[resultKey][cleanName] = {};
                    }

                    if (formattedResult[resultKey][cleanName][machineKey] === undefined) {
                        formattedResult[resultKey][cleanName][machineKey] = {};
                    }

                    if (formattedResult[resultKey][cleanName][machineKey] === undefined) {
                        formattedResult[resultKey][cleanName][machineKey] = {};
                    }

                    if (formattedResult[resultKey][cleanName][machineKey][amount] === undefined) {
                        formattedResult[resultKey][cleanName][machineKey][amount] = [];
                    }

                    formattedResult[resultKey][cleanName][machineKey][amount] = machineValue;
                }
            }
        }

        let printResult = {};

        printResult.machineScheduleStorage = fillMachineSchedule(formattedResult);
        printResult.material = fillMaterialOptions(formattedResult);
        printResult.labor = fillLaborOptions(formattedResult);
        printResult.machineCosts = fillMachineCosts(formattedResult);
        printResult.information = fillInformation(formattedResult);
        printResult.thirdparty = fillThirdParty();
        printResult.manualLabor = fillManualLabor();

        // loadTotals(printResult, data);

        setFormattedResult(printResult);
        setTotalResultJson(printResult);
    }

    const fillManualLabor = () => {
        if (data.manualLabor === undefined) {
            return;
        }

        if (!Array.isArray(data.manualLabor)) {
            return;
        }

        let tableData = [];

        for (const value of data.manualLabor) {
            const row = [];

            row.push(value.description);

            const hourlyWage = formatCurrency(parseFloat(value.hourlyWage));

            for (const quantity of data.amounts) {
                const qData = value.quantities[quantity];
                if (!qData) continue;
                row.push(`${qData.minutes ?? 0} ${(qData.minutes == 1 ? 'minuut' : 'minuten')} x ${hourlyWage} p/u = ${qData.price}`);
            }

            tableData.push(row);
        }

        if (tableData.length === 0) {
            setManualLaborTableData(null);
        } else {
            setManualLaborTableData(tableData);
        }

        return tableData;
    }

    const fillThirdParty = () => {
        if (data.thirdParty === undefined) {
            return;
        }

        if (!isObject(data.thirdParty) && !Array.isArray(Object.entries(data.thirdParty))) {
            return;
        }

        const thirdParty = Array.isArray(data.thirdParty) ? data.thirdParty : Object.values(data.thirdParty);

        let tableData = [];
        for (const value of thirdParty) {

            const row = [];

            row.push(value.description);

            for (const quantity of data.amounts) {
                const qData = value.quantities[quantity];
                if (!qData) continue;
                row.push(`${formatCurrency(qData.cost)} + ${qData.surtax}% = ${qData?.price ?? formatCurrency(qData.cost * (1 + qData.surtax / 100))} voor ${qData.quantity} stuks`);
            }

            tableData.push(row);
        }

        if (tableData.length === 0) {
            setThirdPartyTableData(null);
        } else {
            setThirdPartyTableData(tableData);
        }

        return tableData;
    }

    const fillMachineCosts = (results) => {
        setMachineCostsData([]);

        let columnCount = 2;
        let tableData = [];
        for (const [machine, machineCostData] of Object.entries(results['Machinekosten'] ?? {})) {
            if (tableData.length > 0) {
                tableData.push(Array(columnCount).fill(''));
            }

            for (const [machineSpecification, machineValues] of Object.entries(machineCostData)) {
                let tmp = [];
                columnCount = Object.values(machineValues).length + 1;
                tmp.push(`${machineSpecification}`);

                for (const amount of data.amounts) {
                    if (machineValues[amount] !== undefined) {
                        tmp.push(`${machineValues[amount]}`);
                    }
                }
                tableData.push(tmp);
            }
        }

        setMachineCostsData(tableData);
        return tableData;
    }

    const fillInformation = (results) => {
        setInformationData([]);

        let columnCount = 2;
        let tableData = [];
        for (const [machine, machineCostData] of Object.entries(results['Informatie'] ?? {})) {
            if (tableData.length > 0) {
                tableData.push(Array(columnCount).fill(''));
            }

            for (const [machineSpecification, machineValues] of Object.entries(machineCostData)) {
                let tmp = [];
                columnCount = Object.values(machineValues).length + 1;
                tmp.push(`${machineSpecification}`);

                for (const amount of data.amounts) {
                    if (machineValues[amount] !== undefined) {
                        tmp.push(`${machineValues[amount]}`);
                    }
                }

                tableData.push(tmp);
            }
        }

        setInformationData(tableData);
        return tableData;
    }

    const fillMachineSchedule = (results) => {
        setMachineScheduleData([]);

        let columnCount = 2;
        let tableData = [];

        for (const [machine, machineScheduleData] of Object.entries(results['Machine planning'] ?? {})) {
            if (tableData.length > 0) {
                tableData.push(Array(columnCount).fill(''));
            }

            for (const [machineSpecification, machineValues] of Object.entries(machineScheduleData)) {
                let tmp = [];
                columnCount = Object.values(machineValues).length + 1;
                tmp.push(`${machineSpecification}`);

                for (const amount of data.amounts) {
                    if (machineValues[amount] !== undefined) {
                        tmp.push(`${machineValues[amount]}`);
                    }
                }

                tableData.push(tmp);
            }
        }
        let tmp = [];

        tableData.push(tmp);

        setMachineScheduleData(tableData);
        return tableData;
    }

    const fillLaborOptions = (results) => {
        setLaborData([]);

        let columnCount = 2;
        let tableData = [];
        for (const [machine, machineCostData] of Object.entries(results['Arbeid'] ?? {})) {
            if (tableData.length > 0) {
                tableData.push(Array(columnCount).fill(''));
            }
            for (const [machineSpecification, machineValues] of Object.entries(machineCostData)) {
                let tmp = [];
                columnCount = Object.values(machineValues).length + 1;
                tmp.push(`${machineSpecification}`);

                for (const amount of data.amounts) {
                    if (machineValues[amount] !== undefined) {
                        tmp.push(`${machineValues[amount]}`);
                    }
                }

                tableData.push(tmp);
            }
        }


        setLaborData(tableData);
        return tableData;
    }

    const fillMaterialOptions = (results) => {
        setMaterialCostTable([]);

        let columnCount = 2;
        let tableData = [];
        for (const [machine, machineCostData] of Object.entries(results['Materiaal'] ?? {})) {
            if (tableData.length > 0) {
                tableData.push(Array(columnCount).fill(''));
            }

            for (const [machineSpecification, machineValues] of Object.entries(machineCostData)) {
                let tmp = [];
                tmp.push(`${machineSpecification}`);
                columnCount = Object.values(machineValues).length + 1;

                for (const amount of data.amounts) {
                    const value = machineValues[amount];
                    if (value !== undefined) {
                        if (value !== null) {
                            if (value.Totaalprijs === undefined) {
                                tmp.push(`${value}`);
                            } else {
                                tmp.push(`${value.Totaalprijs}`);
                            }
                        }
                    }
                }

                tableData.push(tmp);
            }
        }

        setMaterialCostTable(tableData);
        return tableData;
    }

    async function printCalculation() {
        // create pdf document
        const pdf = new PDF(undefined, undefined, undefined, undefined, {
            orientation: 'landscape'
        });

        const dateStr = formatDate(null, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
        pdf.setFontSize(10);
        pdf.text(dateStr, (calculationNumber === '' ? pdf.getPageWidth() - 15 : pdf.getPageWidth() - 45), 10, {
            align: 'right',
        });

        // add calculation name
        pdf.setFontSize(10);
        if (calculationNumber) {
            pdf.text(calculationNumber ?? '', pdf.getPageWidth() - 15, 10, {
                align: 'right',
            });
        }

        let amountHeaders = [''];
        for (const amount of amounts) {
            amountHeaders.push(`${amount}`);
        }

        pdf.setFontSize(20);
        pdf.text('Checklist', 14, 10);
        pdf.setFontSize(10);

        let checklistTableData = [];
        if (checklistContent !== undefined) {
            for (const checklistItem of checklistContent) {
                // check if we have something to display, if not continue with next entry
                if (checklistItem?.value === undefined || checklistItem.value === '') continue;
                checklistTableData.push([checklistItem.name, checklistItem.value])
            }
        }

        autoTable(pdf, {
            head: [['Naam', 'Waarde']],
            body: checklistTableData,
            startY: 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        pdf.setFontSize(20);
        pdf.text('Machinekosten incl. arbeid', 14, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(10);

        let machineCostTableData = [];
        for (const [key, value] of Object.entries(formattedResult.machineCosts)) {
            let tmp = [];

            for (const [amountKey, amount] of Object.entries(value)) {
                tmp.push(amount);
            }

            machineCostTableData.push(tmp)
        }

        autoTable(pdf, {
            head: [amountHeaders],
            body: machineCostTableData,
            startY: pdf.autoTable.previous.finalY + 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        pdf.setFontSize(20);
        pdf.text('Materiaal', 14, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(10);

        let materialCostData = [];
        for (const [key, material] of Object.entries(formattedResult.material)) {
            let tmp = [];

            for (const value of material) {
                tmp.push(value)
            }

            materialCostData.push(tmp)
        }

        autoTable(pdf, {
            head: [amountHeaders],
            body: materialCostData,
            startY: pdf.autoTable.previous.finalY + 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        pdf.setFontSize(20);
        pdf.text('Arbeid', 14, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(10);

        let laborCostData = [];
        for (const [key, labor] of Object.entries(formattedResult.labor)) {
            let tmp = [];

            for (const [amountKey, amount] of Object.entries(labor)) {
                tmp.push(amount);
            }

            laborCostData.push(tmp)
        }

        autoTable(pdf, {
            head: [amountHeaders],
            body: laborCostData,
            startY: pdf.autoTable.previous.finalY + 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        pdf.setFontSize(20);
        pdf.text('Machine planning', 14, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(10);

        let machineScheduleData = [];
        for (const [key, machineSchedule] of Object.entries(formattedResult.machineScheduleStorage)) {
            let tmp = [];

            for (const [amountKey, amount] of Object.entries(machineSchedule)) {
                tmp.push(amount);
            }

            machineScheduleData.push(tmp)
        }

        autoTable(pdf, {
            head: [amountHeaders],
            body: machineScheduleData,
            startY: pdf.autoTable.previous.finalY + 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        if (formattedResult.manualLabor !== undefined) {
            if (formattedResult.manualLabor.length !== 0) {

                pdf.setFontSize(20);
                pdf.text('Handwerk', 14, pdf.autoTable.previous.finalY + 10);
                pdf.setFontSize(10);

                let manualLaborData = [];
                for (const [name, value] of Object.entries(formattedResult.manualLabor)) {
                    let tmp = [];

                    for (const [amountKey, amount] of Object.entries(value)) {
                        tmp.push(amount);
                    }

                    manualLaborData.push(tmp);
                }

                autoTable(pdf, {
                    head: [amountHeaders],
                    body: manualLaborData,
                    startY: pdf.autoTable.previous.finalY + 15,
                    rowPageBreak: 'avoid',
                    bodyStyles: {valign: 'top'},
                })
            }
        }

        if (formattedResult.thirdparty !== undefined) {
            if (formattedResult.thirdparty.length !== 0) {

                pdf.setFontSize(20);
                pdf.text('Werkderden', 14, pdf.autoTable.previous.finalY + 10);
                pdf.setFontSize(10);

                let thirdPartyData = [];
                for (const [name, value] of Object.entries(formattedResult.thirdparty)) {
                    let tmp = [];

                    for (const [amountKey, amount] of Object.entries(value)) {
                        tmp.push(amount);
                    }

                    thirdPartyData.push(tmp);
                }

                autoTable(pdf, {
                    head: [amountHeaders],
                    body: thirdPartyData,
                    startY: pdf.autoTable.previous.finalY + 15,
                    rowPageBreak: 'avoid',
                    bodyStyles: {valign: 'top'},
                })
            }
        }

        pdf.setFontSize(20);
        pdf.text('Totaal', 14, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(10);

        let totalTableData = [];
        const summary = JSON.parse(document.getElementById('calculationSummary')?.getAttribute('summaryjsondata'));

        const summaryValues = Object.values(summary);
        const subTotal = summaryValues.map(amount => formatNumberValue(amount.subTotal, 'euro'));
        const margin = summaryValues.map(amount => formatNumberValue(amount.margin, 'procent'));
        const workflow = summaryValues.map(amount => formatNumberValue(amount.workflow, 'procent'));
        const total = summaryValues.map(amount => formatNumberValue(amount.total, 'euro'));
        const quote = summaryValues.map(amount => formatNumberValue(amount.quote, 'euro'));

        totalTableData.push(['Subtotaal:', ...subTotal]);
        totalTableData.push(['Marge:', ...margin]);
        totalTableData.push(['Workflow:', ...workflow]);
        totalTableData.push(['Totaal:', ...total]);
        totalTableData.push(['Offerte:', ...quote]);

        autoTable(pdf, {
            head: [amountHeaders],
            body: totalTableData,
            startY: pdf.autoTable.previous.finalY + 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        // calculationNumber.replaceAll(' ', '-') + '.pdf'
        pdf.openPDF();
    }

    const printSimpleCalculation = async () => {
        // create pdf document
        const pdf = new PDF(undefined, undefined, undefined, undefined, {
            orientation: 'landscape'
        });

        // Set the date for the PDF
        const dateStr = formatDate(null, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
        pdf.setFontSize(10);
        pdf.text(dateStr, (calculationNumber === '' ? pdf.getPageWidth() - 15 : pdf.getPageWidth() - 45), 10, {
            align: 'right',
        });

        // Add calculation number
        pdf.setFontSize(10);
        if (calculationNumber) {
            pdf.text(calculationNumber ?? '', pdf.getPageWidth() - 15, 10, {
                align: 'right',
            });
        }

        pdf.setFontSize(20);
        pdf.text('Checklist', 14, 10);
        pdf.setFontSize(10);

        let checklistTableData = [];
        for (const checklistItem of checklistContent) {
            // check if we have something to display, if not continue with next entry
            if (checklistItem?.value === undefined || checklistItem.value === '') continue;
            checklistTableData.push([checklistItem.name, checklistItem.value])
        }

        autoTable(pdf, {
            head: [['Naam', 'Waarde']],
            body: checklistTableData,
            startY: 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        const resultData = document.querySelectorAll('.simple-calculation-result');

        for (const simpleResultElement of resultData) {
            const title = simpleResultElement.previousSibling.textContent;
            const headers = [...simpleResultElement.querySelectorAll('th')]
                .map(header => header.textContent);

            const rowHeaders = [...simpleResultElement.children[0].querySelectorAll('tr')]
                .map(row =>
                    [...row.querySelectorAll('td')]
                        .map(cell => cell.textContent)
                )
                .filter(row => row.length > 0);

            const content = [...simpleResultElement.children[1].querySelectorAll('tr')]
                .map(row =>
                    [...row.querySelectorAll('td')]
                        .map(cell => cell.textContent)
                )
                .filter(row => row.length > 0);

            // Merge row headers with content
            content.forEach((row, index) => {
                row.unshift(...rowHeaders[index]);
            });


            pdf.setFontSize(20);
            pdf.text(title, 14, pdf.autoTable.previous.finalY + 10);
            pdf.setFontSize(10);

            autoTable(pdf, {
                head: [headers],
                body: content,
                startY: pdf.autoTable.previous.finalY + 15,
                rowPageBreak: 'avoid',
                pageBreak: 'auto',
                bodyStyles: {valign: 'top'},
            })
        }

        pdf.setFontSize(20);
        pdf.text('Totaal', 14, pdf.autoTable.previous.finalY + 10);
        pdf.setFontSize(10);

        let totalTableData = [];
        const summary = JSON.parse(document.getElementById('calculationSummary')?.getAttribute('summaryjsondata'));

        const summaryValues = Object.values(summary);
        const subTotal = summaryValues.map(amount => formatNumberValue(amount.subTotal, 'euro'));
        const margin = summaryValues.map(amount => formatNumberValue(amount.margin, 'procent'));
        const workflow = summaryValues.map(amount => formatNumberValue(amount.workflow, 'procent'));
        const total = summaryValues.map(amount => formatNumberValue(amount.total, 'euro'));
        const quote = summaryValues.map(amount => formatNumberValue(amount.quote, 'euro'));

        totalTableData.push(['Subtotaal:', ...subTotal]);
        totalTableData.push(['Marge:', ...margin]);
        totalTableData.push(['Workflow:', ...workflow]);
        totalTableData.push(['Totaal:', ...total]);
        totalTableData.push(['Offerte:', ...quote]);

        let amountHeaders = [''];
        for (const amount of amounts) {
            amountHeaders.push(`${amount}`);
        }

        autoTable(pdf, {
            head: [amountHeaders],
            body: totalTableData,
            startY: pdf.autoTable.previous.finalY + 15,
            rowPageBreak: 'avoid',
            bodyStyles: {valign: 'top'},
        })

        pdf.openPDF();
    }

    const getDataFromWidget = (widget, values, amount, rawValue = false) => {
        const value = getFormattedValueFromWidget(widget.id, widget.parentId, values, amount, rawValue);

        if (widget.data.type === 'MaterialChoice') {
            return JSON.parse(value);
        } else {
            return value
        }
    }

    const getNameFromWidget = (widget, values, amount, constants) => {
        if (widget.data.title.includes('|')) {
            return null;
        }

        let returnData = false;
        if (widget.data.title.includes('.')) {
            for (const widgetFromValues of values[widget.parentId][amount]) {
                if (widgetFromValues.id === widget.data.title.split('.')[1]) {
                    const JSONDataFromWidget = JSON.parse(widgetFromValues.value);
                    if (JSONDataFromWidget !== null) {
                        if (JSONDataFromWidget.data.value.result !== undefined) {
                            returnData = JSONDataFromWidget.data.value.result.calculationDescription;
                        }
                    }
                }
            }
        } else if (data.templateData.data.attributes.data[widget.parentId].name !== undefined) {
            returnData = data.templateData.data.attributes.data[widget.parentId].name;
        }

        /**
         * If the name is not in other widget return machine name from constants
         */
        if (!returnData) {
            for (const constant of constants) {
                if (constant.id.includes('name')) {
                    returnData = constant.value;
                    break;
                }
            }
        }

        return returnData;
    }

    /**
     * Returns widget value
     * @param widgetId
     * @param machineId
     * @param values
     * @param amount
     * @param getRaw
     * @return {*}
     */
    const getFormattedValueFromWidget = (widgetId, machineId, values, amount, getRaw) => {
        if (values[machineId][amount] === undefined) {
            console.warn(`${machineId} is missing values`);
            return ''
        }

        // An array of widget to search in
        const widgets = values[machineId][amount];
        // Get the widget from the widgets array
        const widget = widgets?.find(widget => widget.id === widgetId);

        if (!widget) {
            return false;
        }

        // Return the raw value if getRaw is true
        if (getRaw) {
            return widget.value;
        }

        // If the widget is a number but a string, convert it to a number
        if (/^[\d.]+$/.test(widget.value)) {
            widget.value = parseFloat(widget.value);
        }

        // If the widget is a number return the formatted number
        if (typeof widget.value === 'string') {
            return widget.unit ? `${widget.value} ${widget.unit}` : widget.value;
        }

        // If the widget is a not a number return the value
        if (typeof widget.value !== 'number') {
            return widget.value;
        }

        // Format number
        return formatNumberValue(widget.value, widget.unit);
    }

    function updateReturnValue(amount, name, value) {
        setResultValues(resultValues => {
            resultValues[amount][name] = parseFloat(value);

            const calculatedTotal = resultValues[amount].subTotal * ((resultValues[amount].margin / 100) + 1) * ((resultValues[amount].workflow / 100) + 1);
            resultValues[amount] = {
                ...resultValues[amount],
                total: calculatedTotal,
                quote: name === 'quote' ? parseFloat(value) : calculatedTotal,
            }

            if (setData !== undefined) {
                data.summary[amount] = {
                    ...resultValues[amount]
                }
            }


            return {...resultValues};
        });
    }

    function mapCalculationCol(col) {
        return {
            attributes: {
                style: {
                    borderRight: col[0] ? "1px dashed lightgray" : ''
                }
            },
            data: [col[0]],
        }
    }

    const machineCostsFields = machineCostsData?.map(mapCalculationCol);
    const machineCostsValues = machineCostsData?.map(col => col.slice(1));

    const materialCostsFields = materialCostTable?.map(mapCalculationCol);
    const materialCostsValues = materialCostTable?.map(col => col.slice(1));

    const laborCostsFields = laborData?.map(mapCalculationCol);
    const laborCostsValues = laborData?.map(col => col.slice(1));

    const scheduleDataFields = machineScheduleData?.map(mapCalculationCol);
    const scheduleDataValues = machineScheduleData?.map(col => col.slice(1));

    const informationFields = informationData?.map(mapCalculationCol);
    const informationValues = informationData?.map(col => col.slice(1));

    const thirdPartyCostsFields = thirdPartyTableData?.map(mapCalculationCol);
    const thirdPartyCostsValues = thirdPartyTableData?.map(col => col.slice(1));

    const manualLaborFields = manualLaborTableData?.map(mapCalculationCol);
    const manualLaborValues = manualLaborTableData?.map(col => col.slice(1));

    const hasChecklist = checklistContent !== undefined ? checklistContent.length !== 0 && checklistContent.some(row => row.value !== '') : null;
    const [copyTableData, setCopyTableData] = useState([]);

    useEffect(() => {
        let tableData = [];

        if (copies !== undefined) {
            for (const copy of copies) {
                tableData.push({
                    attributes: {
                        onClick: () => {
                            navigate(`/calculations/${copy.id}/view`)
                        }
                    },
                    data: [
                        copy?.number,
                        copy?.projectDescription
                    ]
                });
            }
        }

        setCopyTableData(tableData);
    }, [copies])


    return (
        <div className="result__container" key={calculationNumber}>
            {renderInfo ?
                <InformationBlock
                    hasChecklist={hasChecklist}
                    printCalculation={(viewType === 'simple' ? printSimpleCalculation : printCalculation)}
                    company={company}
                    calculationNumber={calculationNumber}
                    calculationDimensions={calculationDimensions}
                    copiedFrom={copiedFrom}
                    amounts={amounts}
                    setCalculationStatus={setCalculationStatus}
                    calculationStatus={calculationStatus}
                    thirdPartyQuotes={thirdPartyQuotes}
                    copyTableData={copyTableData}
                    checklistTable={checklistTable}
                />: ''
            }
            <br/>
            <Block className="block__result__container" name={'result'} title={'Resultaat'} loading={loading} headerRightSideChildren={
                <>
                    <IF condition={storeCalculation !== undefined && saveButtonEnabled}>
                        <button onClick={() => storeCalculation()}>Opslaan</button>
                    </IF>
                    <button onClick={toggleViewType}>{viewType === 'simple' ? 'Expert' : 'Eenvoudige'} weergave</button>
                </>
            }>
                {viewType === 'simple' ?
                    <SimpleCalculationResult
                        copies={copies}
                        copiedFrom={copiedFrom}
                        storeCalculation={storeCalculation}
                        company={company}
                        setData={setData}
                        renderInfo={renderInfo}
                        checklistContent={checklistContent}
                        setTotalResultJson={setTotalResultJson}
                        data={data}
                        calculationNumber={calculationNumber}
                        loading={loading}
                        calculationStatus={calculationStatus}
                        setCalculationStatus={setCalculationStatus}
                        saveButtonEnabled={saveButtonEnabled}
                        language={language}
                        toggleViewType={() => setViewType('advanced')}
                    />
                    :
                    <>
                        <h3 className="result__table__header">Machinekosten incl. arbeid</h3>
                        {/* <Grid className="result__item__grid" columns={1 + amounts?.length} customColTemplate={"500px 1fr"} gap={'0px'}> */}
                        <div className="table__row__container">
                            <div className="result__table__container">
                                <Table
                                    headers={['Naam']}
                                    structure={['100%']}
                                    data={machineCostsFields}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                            <div className="result__table__container">
                                <Table
                                    headers={amounts ?? ['\u00A0']}
                                    structure={Array(amounts?.length).fill('400px')}
                                    data={machineCostsValues}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                        </div>
                        {/* </Grid> */}

                        <h3 className="result__table__header">Materiaalkosten</h3>
                        <div className="table__row__container">
                            <div className="result__table__container">
                                <Table
                                    headers={['Naam']}
                                    structure={['100%']}
                                    data={materialCostsFields}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                            <div className="result__table__container">
                                <Table
                                    headers={amounts ?? ['\u00A0']}
                                    structure={Array(amounts?.length).fill('400px')}
                                    data={materialCostsValues}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                        </div>

                        <h3 className="result__table__header">Arbeid</h3>
                        <div className="table__row__container">
                            <div className="result__table__container">
                                <Table
                                    headers={['Naam']}
                                    structure={['100%']}
                                    data={laborCostsFields}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                            <div className="result__table__container">
                                <Table
                                    headers={amounts ?? ['\u00A0']}
                                    structure={Array(amounts?.length).fill('400px')}
                                    data={laborCostsValues}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                        </div>

                        <h3 className="result__table__header">Machine planning</h3>
                        <div className="table__row__container">
                            <div className="result__table__container">
                                <Table
                                    headers={['Naam']}
                                    structure={['100%']}
                                    data={scheduleDataFields}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                            <div className="result__table__container">
                                <Table
                                    headers={amounts ?? ['\u00A0']}
                                    structure={Array(amounts?.length).fill('400px')}
                                    data={scheduleDataValues}
                                    stickyHeader={true}
                                    hover={false}
                                />
                            </div>
                        </div>

                        {thirdPartyTableData !== null ?
                            <>
                                <h3 className="result__table__header">Werkderden</h3>
                                <div className="table__row__container">
                                    <div className="result__table__container">
                                        <Table
                                            headers={['Naam']}
                                            structure={['100%']}
                                            data={thirdPartyCostsFields}
                                            stickyHeader={true}
                                            hover={false}
                                        />
                                    </div>
                                    <div className="result__table__container">
                                        <Table
                                            headers={amounts ?? ['\u00A0']}
                                            structure={Array(amounts?.length).fill('400px')}
                                            data={thirdPartyCostsValues}
                                            stickyHeader={true}
                                            hover={false}
                                        />
                                    </div>
                                </div>
                            </> : ''}

                        {manualLaborTableData !== null ?
                            <>
                                <h3 className="result__table__header">Handwerk</h3>
                                <div className="table__row__container">
                                    <div className="result__table__container">
                                        <Table
                                            headers={['Naam']}
                                            structure={['100%']}
                                            data={manualLaborFields}
                                            stickyHeader={true}
                                            hover={false}
                                        />
                                    </div>
                                    <div className="result__table__container">
                                        <Table
                                            headers={amounts ?? ['\u00A0']}
                                            structure={Array(amounts?.length).fill('400px')}
                                            data={manualLaborValues}
                                            stickyHeader={true}
                                            hover={false}
                                        />
                                    </div>
                                </div>
                            </> : ''}

                        {informationData?.length > 0 &&
                            <>
                                <h3 className="result__table__header">Informatie</h3>
                                <div className="table__row__container">
                                    <div className="result__table__container">
                                        <Table
                                            headers={['Naam']}
                                            structure={['100%']}
                                            data={informationFields}
                                            stickyHeader={true}
                                            hover={false}
                                        />
                                    </div>
                                    <div className="result__table__container">
                                        <Table
                                            headers={amounts ?? ['\u00A0']}
                                            structure={Array(amounts?.length).fill('400px')}
                                            data={informationValues}
                                            stickyHeader={true}
                                            hover={false}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                   </>
                }

                <div
                    id={'calculationSummary'}
                    summaryjsondata={JSON.stringify(resultValues)}
                >
                    <Grid
                        columns={data?.amounts?.length + 1}
                        gap={'0px'}
                        customColTemplate={{
                            1199: ("minmax(300px,300px) " + "minmax(400px,1fr) ".repeat(Math.abs(tableHeaders.length - 1))).trim(),
                            1700: ("minmax(300px,300px) " + "minmax(400px,1fr) ".repeat(Math.abs(tableHeaders.length - 1))).trim(),
                            default: ("minmax(300px,300px) " + "minmax(400px,1fr) ".repeat(Math.abs(tableHeaders.length - 1))).trim()
                        }}
                        style={{
                            overflowX: 'auto'
                        }}
                    >
                        <div className='calculation-result--column recap__container'>
                            <h3 className="recap__title">
                                Samenvatting:
                            </h3>
                            <div className="recap__label">
                                Materiaal:
                            </div>
                            <div className="recap__label">
                                Subtotaal:
                            </div>
                            <div className="recap__label">
                                Marge (%):
                            </div>
                            <div className="recap__label">
                                Workflow (%):
                            </div>
                            <div className="recap__label">
                                Totaal:
                            </div>
                            <div className="recap__label">
                                Offerte Bedrag:
                            </div>
                        </div>

                        {
                            (data?.amounts ?? [])
                                .map(amount => [amount, (resultValues?.[amount] ?? false)])
                                .filter(([, values]) => values !== false)
                                .map(([amount, values], index) => (
                                    <div
                                        className={'calculation-result--column recap__container'}
                                        key={index}
                                    >
                                        <h3 className="result__table__header">
                                            {amount}
                                        </h3>
                                        <div className={'input-group input-group--text margin-0'}>
                                            <span className={'input-group-text'}>
                                                €
                                            </span>
                                            <input
                                                type={"text"}
                                                disabled={true}
                                                value={formatCurrency(values.materials ?? 0).replace('€', '').trim()}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className={'input-group input-group--text margin-0'}>
                                            <span className={'input-group-text'}>
                                                €
                                            </span>
                                            <input
                                                type={"text"}
                                                disabled={true}
                                                value={formatCurrency(values.subTotal).replace('€', '').trim()}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className={'input-group input-group--text margin-0'}>
                                            <input
                                                type={"number"}
                                                step={0.1}
                                                value={values.margin}
                                                onChange={e => updateReturnValue(amount, 'margin', e.target.value)}
                                            />
                                            <span className={'input-group-text'}>
                                                %
                                            </span>
                                        </div>
                                        <div className={'input-group input-group--text margin-0'}>
                                            <input
                                                type={"number"}
                                                step={0.1}
                                                value={values.workflow}
                                                onChange={e => updateReturnValue(amount, 'workflow', e.target.value)}
                                            />
                                            <span className={'input-group-text'}>
                                                %
                                            </span>
                                        </div>
                                        <div className={'input-group input-group--text margin-0'}>
                                            <span className={'input-group-text'}>
                                                €
                                            </span>
                                            <input
                                                type={"text"}
                                                disabled={true}
                                                value={formatCurrency(values.total).replace('€', '').trim()}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className={'input-group input-group--text margin-0'}>
                                            <span className={'input-group-text'}>
                                                €
                                            </span>
                                            <input
                                                type={"number"}
                                                step={0.01}
                                                min={isNaN(values.subTotal) ? 0 : values.subTotal}
                                                value={+values.quote?.toFixed(2) ?? '0.00'}
                                                onChange={e => updateReturnValue(amount, 'quote', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </Grid>
                </div>
            </Block>
        </div>
    )
}
