import {AxiosInstance} from "axios";

export default class TimerRepository {

    /**
     * Axios instance to make requests to the API
     */
    api: AxiosInstance;

    constructor(authAxios: AxiosInstance) {
        this.api = authAxios;
    }

    /**
     * Get the current running timer
     * Returns null if no timer is running
     */
    public async current(): Promise<any> {
        return await this.api.get('/employees/timer/current');
    }

    /**
     * Get the schedule for today
     */
    public async getScheduleForToday(): Promise<any> {
        return await this.api.get('/employees/schedule/today');
    }

    /**
     * Clock in the current employee
     */
    public async clockIn(): Promise<any> {
        return await this.api.post('/employees/timer/clock-in', {
            start: new Date().toISOString()
        });
    }

    /**
     * Clock out the current employee
     */
    public async clockOut(endDate: Date = new Date()): Promise<any> {
        return await this.api.post('/employees/timer/clock-out', {
            end: endDate.toISOString()
        });
    }

    /**
     * Start a break for the current employee
     */
    public async startBreak(): Promise<any> {
        return await this.api.post('/employees/timer/start-break', {
            start: new Date().toISOString()
        });
    }

    /**
     * End a break for the current employee
     */
    public async endBreak(): Promise<any> {
        return await this.api.post('/employees/timer/end-break', {
            end: new Date().toISOString()
        });
    }
}