import {useEffect, useState} from "react";
import PDF from "App/Util/print";
import isEmpty from "lodash/isEmpty";
import autoTable from "jspdf-autotable";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import IF from "UI/App/Components/Conditional/IF";
import Block from "UI/App/Components/Block/Block";
import PurchaseResultBlock from "./PurchaseResultBlock";
import ActivityResultBlock from "./ActivityResultBlock";
import {formatCurrency, formatDate, formatNumberValue} from "App/Util/format";
import {planningSuffix} from "UI/App/Partials/Content/Calculations/HJMG/steps/util";

export default function CalculationResult({
          results,
          setResults,
          calculationData,
          purchaseData,
          printData,
          setPrintData,
          calculationNumber,
          checklistData,
          saveCalculation,
          activeTab,
          calculation
      }) {

    const [dataEntries, setDataEntries] = useState(null);
    // const [calculationDataEntries,setCalculationDataEntries] = useEffect(null);

    useEffect(() => {
        if(calculationData !== null){
            if(!isEmpty(calculationData)){
                setDataEntries(calculationData);
            }
        }
    },[calculationData])

    useEffect(() => {
        setResults(prev => {
            if (!prev.subTotal || !prev.margin) {
                return prev;
            }

            return {
                ...prev,
                total: Number((prev.subTotal * (1 + (prev.margin / 100))).toFixed(2)),
                quote: Number((prev.subTotal * (1 + (prev.margin / 100))).toFixed(2))
            }
        })
    }, [results.subTotal]);

    function registerResult(activity, result) {
        setResults(prev => {
            const updatedResults = {
                ...prev,
                [activity]: result,
            }

            return {
                ...updatedResults,
                subTotal: Object.values(updatedResults).filter(result => typeof result.cost === 'number').reduce((total, result) => total + Number(result.cost), 0),
            }
        });
    }

    function updateRecurringResult(activity, key, newResult) {
        setResults(prev => {
            const updatedResults = {
                ...prev,
            }

            updatedResults[activity][key] = {
                ...updatedResults[activity][key],
                ...newResult
            };

            return updatedResults;
        });
    }

    function printCalculation() {
        // create pdf document
        const pdf = new PDF(undefined);

        let pageOffset = 15;

        const dateStr = formatDate(null, {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
        pdf.setFontSize(10);
        pdf.text(dateStr, (calculationNumber === '' ? pdf.getPageWidth() - 15 : pdf.getPageWidth() - 45), 10, {
            align: 'right',
        });

        // add calculation name
        pdf.setFontSize(10);
        if (calculationNumber) {
            pdf.text(calculationNumber ?? '', pdf.getPageWidth() - 15, 10, {
                align: 'right',
            });
        }

        if (checklistData) {
            pdf.setFontSize(13);
            pdf.text("Checklist", 15, pageOffset);
            autoTable(pdf, {
                head: [['Omschrijving', 'Waarde']],
                body: Object.values(checklistData)
                    .filter(item => item.value)
                    .map(({name, value}) => [
                        {content: name + ':', styles: {fontStyle: 'bold'}},
                        value
                    ]),
                startY: pageOffset + 5,
                rowPageBreak: 'avoid',
                bodyStyles: {valign: 'top'},
            });

            pageOffset = pdf.autoTable.previous.finalY + 10;
        }

        const resultBlocks = Array.from(document.querySelectorAll('.hjmg__calculation__result-block'));

        for (const resultBlock of resultBlocks) {
            const title = resultBlock.querySelector('h3').innerText;
            const headers = Array.from(resultBlock.querySelectorAll('tr > th')).map(header => header.innerText);
            const data = Array.from(resultBlock.querySelectorAll('tbody tr'))
                .map(row =>
                    Array.from(row.querySelectorAll('td'))
                        .map(cell =>
                            ['B', 'STRONG'].includes(cell?.children[0]?.tagName) ? {content: cell.innerText, styles: {fontStyle: 'bold'}} : cell.innerText
                        )
                );

            pdf.setFontSize(13);
            pdf.text(title, 15, pageOffset);

            autoTable(pdf, {
                head: [headers],
                body: data,
                startY: pageOffset + 5,
                rowPageBreak: 'avoid',
                bodyStyles: {valign: 'top'},
            })

            pageOffset = pdf.autoTable.previous.finalY + 10;

            pageOffset = pdf.addNewPageIfOverflown(pageOffset, 100);
        }


        if (['subTotal', 'margin', 'total', 'quote'].every(key => Object.keys(results).includes(key))) {
            // add summary
            pdf.setFontSize(13);
            pdf.text("Samenvatting werkzaamheden", 15, pageOffset);

            autoTable(pdf, {
                head: [[
                    'Omschrijving',
                    'Kosten'
                ]],
                body: [
                    [{content: 'Subtotaal', styles: {fontStyle: 'bold'}}, formatCurrency(results?.subTotal ?? 0)],
                    [{content: 'Marge', styles: {fontStyle: 'bold'}}, formatNumberValue((results?.margin ?? 20), 'procent')],
                    [{content: 'Totaal', styles: {fontStyle: 'bold'}}, formatCurrency(results?.total ?? 0)],
                    [{content: 'Offertebedrag', styles: {fontStyle: 'bold'}}, formatCurrency(results?.quote ?? 0)],
                ],
                startY: pageOffset + 5,
                rowPageBreak: 'avoid',
                bodyStyles: {valign: 'top'},
            });

            pageOffset = pdf.autoTable.previous.finalY + 10;

            pageOffset = pdf.addNewPageIfOverflown(pageOffset, 50);
        }

        if (results?.Service && !isEmpty(results?.Service)) {
            pdf.text("Samenvatting service", 15, pageOffset);

            autoTable(pdf, {
                head: [[
                    'Omschrijving',
                    'Subtotaal',
                    'Marge',
                    'Totaal',
                    'Offertebedrag'
                ]],
                body: Object.values(results.Service)
                    .map(result => [
                        {content: result.description, styles: {fontStyle: 'bold'}},
                        formatCurrency(result?.subTotal ?? 0),
                        formatNumberValue((result?.margin ?? 20), 'procent'),
                        formatCurrency(result?.total ?? 0),
                        formatCurrency(result?.quote ?? 0) + planningSuffix(result?.billingPeriod),
                    ]),
                startY: pageOffset + 5,
                rowPageBreak: 'avoid',
                bodyStyles: {valign: 'top'},
            });

            pageOffset = pdf.autoTable.previous.finalY + 10;
        }

        pageOffset = pdf.addNewPageIfOverflown(pageOffset, 50);

        if (printData && !isEmpty(printData) && !printData?.placeHolder) {
            pdf.text("Samenvatting print", 15, pageOffset);

            autoTable(pdf, {
                head: [[
                    '',
                    ...printData.amounts
                ]],
                body: [
                    [
                        {content: 'Totaal (incl. marge en workflow)', styles: {fontStyle: 'bold'}},
                        ...printData.amounts.map(amount => formatCurrency(printData.summary[amount].total))
                    ],
                    [
                        {content: 'Offertebedrag', styles: {fontStyle: 'bold'}},
                        ...printData.amounts.map(amount => formatCurrency(printData.summary[amount].quote))
                    ]
                ],
                startY: pageOffset + 5,
                rowPageBreak: 'avoid',
                bodyStyles: {valign: 'top'},
            });
        }

        pdf.openPDF();
    }

    return (
        <Block
            name={'calc-result'}
            title={'Resultaat'}
            headerStyle={{overflow: 'visible'}}
            headerRightSideChildren={<>
                <button data-title={'Printen'} className={'btn btn--round btn--black'} onClick={printCalculation}>
                    <Icon name={'print'} />
                </button>
                
                {saveCalculation && (
                    <button data-title={'Opslaan'} className={'btn btn--round'} onClick={() => saveCalculation(true)}>
                        <Icon name={'save'} />
                    </button>
                )}
            </>}
        >
            <div className={'hjmg__calculation__result'}>
                {dataEntries !== null ? Object.keys(dataEntries).map(function(key, index) {
                    return <ActivityResultBlock key={index} activity={key} data={dataEntries[key]} registerResult={registerResult} results={results}/>
                }): <></>}

                <PurchaseResultBlock
                    purchaseData={purchaseData}
                    activeTab={activeTab}
                />

                <IF condition={['subTotal', 'margin', 'total'].every(key => Object.keys(results).includes(key))}>
                    <br/>
                    <h3>Samenvatting werkzaamheden</h3>
                    <div className={'hjmg__calculation__summary'}>
                        <span>
                            <b>Subtotaal:</b>
                            <input
                                type={'text'}
                                value={formatCurrency(results?.subTotal ?? 0)}
                                disabled
                            />
                        </span>
                        <span>
                            Marge (%):
                            <input
                                type={'number'}
                                placeholder={'Marge...'}
                                min={20}
                                max={40}
                                onChange={e => setResults(prev => ({
                                    ...prev,
                                    margin: Number(e.target.value) ? Number(e.target.value) : '',
                                    total: Number((prev.subTotal * (1 + (e.target.value / 100))).toFixed(2)),
                                    quote: Number((prev.subTotal * (1 + (e.target.value / 100))).toFixed(2))
                                }))}
                                onBlur={e => {
                                    const margin = Number(e.target.value) < 20 ? 20 : Number(e.target.value) > 40 ? 40 : Number(e.target.value);
                                    setResults(prev => ({
                                        ...prev,
                                        margin,
                                        total: Number((prev.subTotal * (1 + (margin / 100))).toFixed(2)),
                                        quote: Number((prev.subTotal * (1 + (margin / 100))).toFixed(2))
                                    }))
                                }}
                                value={results?.margin ?? 20}
                            />
                        </span>
                        <span>
                            <b>Totaal:</b>
                            <input
                                type={'text'}
                                disabled
                                value={formatCurrency(results?.total ?? 0)}
                            />
                        </span>
                        <span>
                            Offertebedrag:
                            <input
                                type={'number'}
                                step={0.01}
                                min={(results?.total ?? 0) * 0.95}
                                value={(results?.quote ? results.quote : '')}
                                onChange={e => setResults(prev => ({
                                    ...prev,
                                    quote: Number(e.target.value)
                                }))}
                                // Limit to minimum 95% of total including margin
                                onBlur={e => {
                                    setResults(prev => ({
                                        ...prev,
                                        quote: Number(e.target.value) < prev.total * 0.95 ? Number(prev.total.toFixed(2)) : Number(e.target.value)
                                    }));
                                }}
                            />
                        </span>
                    </div>
                </IF>

                <IF condition={(results?.Service !== undefined && results?.Service?.length !== 0)}>
                    <h3>Samenvatting service</h3>
                    <div className={'hjmg__calculation__summary'}>
                        <div className="hjmg__calculation__summary-row">
                            <span></span>
                            <b>Subtotaal:</b>
                            <span>Marge (%):</span>
                            <b>Totaal:</b>
                            <span>Offertebedrag:</span>
                        </div>

                        {
                            Object.entries(results)
                                .filter(([activity, result]) => Array.isArray(result))
                                .map(([activity, entries], index) =>
                                    entries.map((result, index) =>
                                        <div className="hjmg__calculation__summary-row" key={activity + index}>
                                            {/* Omschrijving */}
                                            <span>
                                                {result.description}
                                            </span>
                                            {/* Subtotaal (inclusief projectmanagement) */}
                                            <input
                                                type={'text'}
                                                value={formatCurrency(result.subTotal) + planningSuffix(result.billingPeriod)}
                                                disabled
                                            />
                                            {/* Marge */}
                                            <input
                                                type={'number'}
                                                min={20}
                                                max={40}
                                                value={result.margin}
                                                onChange={e => updateRecurringResult(activity, index, {
                                                    margin: e.target.value
                                                })}
                                                onBlur={e => {
                                                    if (Number(e.target.value) === result.margin) return;

                                                    const margin = Number(e.target.value) < 20 ? 20 : Number(e.target.value) > 40 ? 40 : Number(e.target.value);
                                                    updateRecurringResult(activity, index, {
                                                        margin,
                                                        total: Number((result.subTotal * (1 + (margin / 100))).toFixed(2)),
                                                        quote: Number((result.subTotal * (1 + (margin / 100))).toFixed(2))
                                                    })
                                                }}
                                            />
                                            {/* Totaal (inclusief marge */}
                                            <input
                                                type={'text'}
                                                value={formatCurrency(result.total) + planningSuffix(result.billingPeriod)}
                                                disabled
                                            />
                                            {/* Offerte bedrag */}
                                            <input
                                                type={'number'}
                                                value={(typeof result?.quote === 'number' ? result?.quote.toFixed(2) : result.quote)}
                                                step={0.01}
                                                onChange={e => updateRecurringResult(activity, index, {
                                                    quote: e.target.value
                                                })}
                                                onBlur={e => updateRecurringResult(activity, index, {
                                                    quote: (Number(e.target.value) < result.total * 0.95 ? Number(result.total.toFixed(2)) : Number(e.target.value))
                                                })}
                                            />
                                        </div>
                                    ))
                        }
                    </div>
                </IF>

                <IF condition={!isEmpty(printData) && !printData?.placeHolder}>
                    <h3>Samenvatting print</h3>
                    <Grid columns={(printData.amounts?.length ?? 0) + 1} customColTemplate={`300px repeat(${(printData.amounts?.length ?? 0)},1fr)`}>
                        <div className={'hjmg__calculation__summary-col'}>
                            <b></b>
                            <span>
                                Totaal&nbsp;<small>(Incl. marge en workflow)</small>:
                            </span>
                            <span>Offertebedrag:</span>
                        </div>

                        {Object.entries(printData?.summary ?? {}).map(([amount, result]) => (
                            <div key={amount} className={'hjmg__calculation__summary-col'}>
                                <b>{amount}</b>
                                <input
                                    type={'text'}
                                    value={formatCurrency(result.total)}
                                    disabled
                                />
                                <input
                                    type={'number'}
                                    value={(result?.quote ?? result.total ?? 0).toFixed(2)}
                                    onChange={e => {
                                        setPrintData(prev => ({
                                            ...prev,
                                            summary: {
                                                ...prev.summary,
                                                [amount]: {
                                                    ...prev.summary[amount],
                                                    quote: Number(e.target.value)
                                                }
                                            }
                                        }));
                                    }}
                                />
                            </div>
                        ))}
                    </Grid>
                </IF>
            </div>
        </Block>
    )
}
