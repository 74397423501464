import {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom"
import {toast} from "react-toastify";
import isEmpty from 'lodash/isEmpty';
import {FetchContext} from "App/Strapi/FetchContext";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import Button from "UI/App/Components/Button/Button";
import CompanyDetails from "./Blocks/CompanyDetails/CompanyDetails";
import CompanyInfo from "./Blocks/CompanyMessages/CompanyMessages";
import CompanyPersonsList from "./Blocks/CompanyPeople/CompanyPersonsList";
import './company.scss';
import Block from "UI/App/Components/Block/Block";
import Table from "UI/App/Components/Table/Table";
import {formatDate, formatDateWithoutTime} from "App/Util/format";
import QueryString from "qs";
import {b64toBlob, sanitizeData} from "App/Util/transform";
import IF from "UI/App/Components/Conditional/IF";
import CompanyCalculations from "UI/App/Partials/Content/CRM/Companies/Company/Blocks/CompanyCalculations/CompanyCalculations";
import Container from "UI/App/Components/Container/Container";
import useWindowDimensions from "App/Util/useWindowDimensions";
import CreateOrder from "../../Orders/CreateOrder";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import Popup, { closePopup, openPopup } from "UI/App/Components/Popup/Popup";
import Select from "react-select";
import qs from "qs"
import strapiToast from "App/Util/errorHandle";
import {clone} from "lodash";
import HasRole from "UI/App/Components/Auth/HasRole";
import User from "App/Strapi/User";

export default function Company() {
    // create a new fetch context for fetching the company
    const { authAxios } = useContext(FetchContext);

    const { width } = useWindowDimensions();

    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    // get the company id from the url params
    const params = useParams();
    const companyId = params.companyId;
    const navigate = useNavigate();

    // create a state for the company data
    const [company, setCompany] = useState({});
    const [managers, setManagers] = useState([]);
    const [checklists, setChecklists] = useState([]);
    // state for branches (locations)
    const [branches, setBranches] = useState([]);

    // store the 'raw' data to reuse later
    const [quoteData, setQuoteData] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [createQuoteData, setCreateQuoteData] = useState({
        disabled: true,
        value: undefined,
    })

    const [quoteTableData, setQuoteTableData] = useState(null);
    const [orderTableData, setOrderTableData] = useState(null);
    const [packingSlipTableData, setPackingSlipTableData] = useState(null);
    const [templateTableData, setTemplateTableData] = useState(null);
    const [packingSlipOrder, setPackingSlipOrder] = useState(undefined);
    const [orderSelectData, setOrderSelectData] = useState([])

    const [webshopTemplates,setWebshopTemplates] = useState([]);
    const [chosenWebshopTemplate,setChosenWebshopTemplate] = useState([]);

    const [supplierMaterialData, setSupplierMaterialData] = useState([]);

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color;

            return {
                ...styles,
                borderLeft: `8px ${color} solid`
            };
        }
    };

    const [calcCreateFunction, setCalcCreateFunction] = useState(undefined);
    const companyParams = QueryString.stringify({
        populate: {
            address: true,
            materials: true,
            telephone: true,
            contactPersons: true,
            accountManagers: true,
            salesManager: true,
            managingCompany: true,
            notes: true,
            templates: {
                fields:[
                    'name'
                ]
            },
            calculations: {
                fields: [
                    'id',
                    'name',
                    'projectDescription',
                    'status',
                    'number',
                    "owner",
                    "createdAt"
                ],
            },
            checklists: {
                fields: [
                    'id',
                    'name',
                    "createdAt",
                ]
            },
            branches: {
                populate: {
                    address: true,
                },
            },
            logo: true
        }
    })

    useEffect(() => {
        // Stop if templates is not defined
        if(company?.templates === undefined) return

        // Create template table
        let templateOptions = [];
        for(const template of company.templates){
            templateOptions.push({
                attributes: {
                    onClick: (e) => {
                        if(User.hasRole(['admin','management'])) {
                            // navigate(`/calculations/templates/${template.id}/edit/`)
                        }
                    }
                },
                data: [
                    template.name,
                    <button onClick={(e) => removeTemplateFromCompany(e,template.id)}>
                        <Icon name='times' />
                    </button>
                ],
            })
        }

        setTemplateTableData(templateOptions)
    }, [company]);

    // fetch the company based on the id
    useEffect(() => {
        const getCompanyPath = !isSupplier ? `/crm/companies/${companyId}` : `/crm/suppliers/${companyId}`
        // create query string out of object


        authAxios.get(getCompanyPath + "?" + companyParams)
            .then(({ data: { data } }) => {
                data = sanitizeData(data);

                if (data.materials !== undefined && data.materials.length !== 0) {
                    let materialTableData = [];

                    for (const material of data.materials) {
                        materialTableData.push({
                            attributes: {
                                onClick: () => {
                                    navigate(`/calculations/catalog/materials/${material.id}`)
                                }
                            },
                            data: [
                                material.calculationDescription
                            ]
                        })

                    }

                    setSupplierMaterialData(materialTableData)
                }

                // sort notes in right order
                data?.notes?.sort((a, b) => {
                    if (a.id < b.id) {
                        return 1;
                    }
                    if (a.id > b.id) {
                        return -1;
                    }
                    return 0;
                });

                setBranches(data.branches?.map((branch) => {
                    const address = branch?.address;
                    return {
                        attributes: {
                            onClick: () => {
                                navigate(`branch/${branch.id}`)
                            }
                        },
                        data: [
                            branch.name,
                            `${address.city}, ${address.country}`,
                            <>
                                <div className="company__list-actions">
                                    {
                                        <Button to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${companyId}/branch/${branch?.id}/update`}
                                            onClick={(e) => e.stopPropagation()}
                                            className='btn btn--round'><Icon name='pencil' /></Button>
                                    }
                                    {
                                        <Button onClick={(e) => deleteBranch(e, branch?.id)}
                                            className='btn btn--round btn--red'><Icon name='trash-can' /></Button>
                                    }
                                </div>
                            </>,
                        ]
                    }
                }))

                // set the company
                setCompany(data);

                if (Array.isArray(data.checklists)) {
                    /**
                     * Set checklists
                     */
                    setChecklists(data.checklists.map((checklist, i) => {
                        const now = new Date(checklist.createdAt);
                        let datestring = now.getDate() + "-" + (now.getMonth() + 1) + "-" + now.getFullYear() + " " +
                            (now.getHours() < 10 ? '0' + now.getHours() : now.getHours()) + ":" + now.getMinutes();

                        return {
                            attributes: {
                                onClick: () => {
                                    navigate(`/calculations/checklists/${checklist?.id}/update`)
                                }
                            },
                            data: [
                                formatDate(checklist.createdAt),
                                checklist.name,
                            ]
                        }
                    }));
                }
            })
            .catch((exception) => {
                toast.error("Kon bedrijf niet ophalen");

                process.env.NODE_ENV === 'development' && console.error(exception);
            });

        getQuoteToOrder();
        loadWebshopTemplates();

        authAxios.get(`crm/company/${companyId}/managers`).then((data) => {
            setManagers(data);
        }).catch((exception) => {
            toast.error('Kon de verantwoordelijke(n) niet ophalen');
            process.env.NODE_ENV === 'development' && console.error(exception);
        });
    }, [authAxios, companyId, isSupplier]);

    useEffect(() => {
        widthChangeQuoteToOrder();
    }, [width])

    useEffect(() => {
        if (company?.managingCompany === undefined) return
        if (company?.managingCompany?.HJMG && company?.managingCompany?.Pretore) {

            setCalcCreateFunction(() => createUnknownCalc)

        } else if (company?.managingCompany?.HJMG) {

            setCalcCreateFunction(() => createHjmgCalculation)
        } else if (company?.managingCompany?.Pretore) {

            setCalcCreateFunction(() => createCalculation)
        }
    }, [company])

    // Loads webshop template select options
    async function loadWebshopTemplates(){
        let webshopTemplates = [];

        // Get active webshop templates
        await authAxios.get(`calculations/templates/all?fields[0]=name&filters[isWebshopTemplate][$eq]=true`).then((data) => {

            // Loop templates to create options
            for(const webshopTemplate of data.data){

                // Push new option to option array
                webshopTemplates.push({
                    label:webshopTemplate.name,
                    value:webshopTemplate.id
                })
            }

        }).catch((exception) => {
            toast.error('Kon webshop templates niet ophalen');
            process.env.NODE_ENV === 'development' && console.error(exception);
        });

        // Add new options
        setWebshopTemplates(webshopTemplates)
    }

    async function getQuoteToOrder() {
        await authAxios.get(`/quotes?populate=company&filters[company][id][$eq]=${companyId}&sort[createdAt]=desc`)
            .then(({ data: { data } }) => {
                // Set data
                const quoteData = [];

                // loop through all the records
                for (const quote of data) {
                    quoteData.push({
                        data: [
                            quote.number,
                            quote?.description,
                            quote.status,
                            <>
                                <button className={'btn btn--round btn--primary'} style={{ marginRight: "5px" }} onClick={(e) => {
                                    e.stopPropagation();
                                    getQuotePDF(quote.id)
                                }}>
                                    <Icon name='download' />
                                </button>
                                <button className={'btn btn--round btn--primary'}  style={{ marginRight: "5px" }} onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/quotes/${quote.id}`)
                                }}>
                                    <Icon name='eye' />
                                </button>
                            </>
                        ]
                    })
                }

                setQuoteTableData(quoteData);
                setQuoteData(data);
                widthChangeQuoteToOrder()
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })

        const populateQuery = qs.stringify({
            populate: {
                packingSlips: true,
            }
        })

        await authAxios.get(`/orders?filters[company][id][$eq]=${companyId}&sort[id]=desc&${populateQuery}`)
            .then(({ data: { data } }) => {
                // Set data
                const orderData = [];
                const packingSlipData = [];

                // loop through all the records
                for (const order of data) {
                    orderData.push({
                        attributes: {
                            onClick: () => {
                                navigate(`/orders/${order.id}`)
                            }
                        },
                        data: [
                            order.number,
                            order?.description,
                            order?.status,
                            formatDateWithoutTime(order?.deliveryDate),
                        ]
                    })
                    if (order?.packingSlips !== undefined) {
                        for (const slip of order?.packingSlips) {
                            packingSlipData.push({
                                attributes: {
                                    onClick: () => {
                                        navigate(`/orders/${order.id}/packing-slips/${slip.id}`)
                                    }
                                },
                                data: [
                                    slip.number,
                                    order.number,
                                    slip?.pdfDataV2?.checklist?.find((el) => el.name === "description")?.value ?? ""
                                ]
                            })
                        }

                    }


                }

                setOrderTableData(orderData);
                setPackingSlipTableData(packingSlipData)

                setOrderData(data);

                const tableData = data?.map((el) => {
                    const description = el?.orderPdfData?.checklist?.find((entry) => entry.name === "description")?.value
                    return {
                        label: `${el.number} ${description ? ` | ${description}` : ""}`, value: el.id
                    }
                })
                setOrderSelectData(tableData ?? []);
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })
    }

    function widthChangeQuoteToOrder() {
        // skip over this if we dont have any valid data to work with
        if (quoteData === undefined || quoteData === null || quoteData.length === 0) {
            return;
        }

        const newQuoteData = [];

        // loop through all the records
        for (const quote of quoteData) {
            const dateArray = formatDateWithoutTime(quote.createdAt).split('-')
            newQuoteData.push({
                attributes: {
                    onClick: () => {
                        navigate(`/quotes/${quote.id}`)
                    }
                },
                data: [
                    quote.number,
                    quote?.description,
                    quote.status,
                    <>
                        <button className={'btn btn--round btn--primary'}  style={{ marginRight: "5px" }} onClick={(e) => {
                            e.stopPropagation();
                            getQuotePDF(quote.id)
                        }}>
                            <Icon name='download' />
                        </button>
                        <button className={'btn btn--round btn--primary'}  style={{ marginRight: "5px" }} onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/quotes/${quote.id}`)
                        }}>
                            <Icon name='eye' />
                        </button>
                    </>
                ]
            })
        }

        setQuoteTableData(newQuoteData);
    }

    function getQuotePDF(quoteId) {
        authAxios.get(`/quotes/${quoteId}?populate=*`).then(({ data: { data, meta } }) => {
            if (data.pdfBase64 !== null) {
                const blob = b64toBlob(data.pdfBase64, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
            } else {
                toast.error("Er is iets fout gegaan met het genereren van een PDF!")
            }
        }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }

    const deleteContact = (e, id) => {
        // prevent redirect
        e.stopPropagation();

        if (window.confirm("Weet u zeker dat u deze informatie wilt verwijderen?")) {
            authAxios.delete(`/crm/${isSupplier ? 'companies' : 'suppliers'}/persons/${id}`).then(() => {
                // remove from list
                e.target.closest('tr').remove();

                toast.success('Contactpersoon is verwijderd!');

            }).catch((exception) => {
                toast.error('Contactpersoon kon niet verwijderd worden!');

                console.error(exception);
            });
        }
    };

    function deleteBranch(e, branchId) {
        // prevent redirect
        e.stopPropagation();

        if (window.confirm('Weet u zeker dat u deze vestiging wilt verwijderen?')) {
            authAxios.delete(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/branches/${branchId}`).then(({ data }) => {

                // remove from list
                e.target.closest('tr').remove();

                toast.success('Vestiging verwijderd.');

            }).catch((reason) => {
                toast.error('Het verwijderen van de vestiging is mislukt!');
                console.error(reason);
            });
        }
    }

    async function removeTemplateFromCompany(e,templateId){
        e.preventDefault();

        // Remove from templates
        for(const templateKey in company.templates){
            const template = company.templates[templateKey];

            if(template.id === templateId){
                company.templates.splice(templateKey, 1);
                break;
            }
        }

        // Update company
        await authAxios.put(`/crm/companies/${company.id}?${companyParams}`, {
            data:company
        }).then(({data}) => {
            // Add updated company data to company state
            setCompany(data.data);
            toast.success('Template toegevoegd.');
        }).catch((e) => {
            strapiToast(e)
            console.error(e);
        });
    }

    // Adds webshop template to company and fixes UUID stuff
    async function addWebshopTemplateToCompany(templateId){

        // First check if company has UUID else add and update
        await validateCompanyUUID();

        // Add template to company
        let companyCopy  = clone(company);

        companyCopy.templates.push(templateId);

        await authAxios.put(`/crm/companies/${company.id}?${companyParams}`, {
            data:company
        }).then(({data}) => {
            // Add updated company data to company state
            setCompany(data.data);
            toast.success('Template toegevoegd.');
        }).catch((e) => {
            strapiToast(e)
            console.error(e);
        });
    }

    async function validateCompanyUUID(){
        // If UUID is null or undefined generate a UUID and update company
        if(company.UUID === null || company.UUID === undefined){
            company.UUID = crypto.randomUUID();

            await authAxios.put(`/crm/companies/${company.id}?${companyParams}`, {
                data:company
            }).then(({data}) => {
                // Add updated company data to company state
                setCompany(data.data);
                toast.success('UUID toegevoegd.');
            }).catch((e) => {
                strapiToast(e)
                console.error(e);
            });
        }
    }

    function addQuoteToCompany(e) {
        navigate(`/quotes/create/${e.target.value}`)
    }

    function createUnknownCalc(e) {
        openPopup("calcConformation")
    }

    function createCalculation(e) {
        navigate("/calculations/create", { state: { companyId: company.id } })
    }

    function createHjmgCalculation(e) {
        navigate("/calculations/hjmg/create", { state: { companyId: company.id } })
    }
    // get the company people
    const [contactPersons, responsiblePersons] = getPeople(company, managers, isSupplier, deleteContact, navigate);

    if (isEmpty(company)) {
        // to-do: insert html and text for company page when company isn't found.
        return (<div className='no-company'></div>);
    }

    return (
        <Container>
            <Grid columns={{
                1300: 1,
                default: 2
            }}>
                <Popup
                    overflow={true}
                    popupID='addOrder'
                    title={'Order toevoegen'}
                >
                    <CreateOrder companyId={company?.id} />
                </Popup>
                <Popup
                    popupID='addCalculationTemplate'
                    title='Webshop template toevoegen'
                >
                    <Select
                        id='addCalculationTemplate'
                        options={webshopTemplates}
                        value={chosenWebshopTemplate}
                        onChange={(e) => {
                            setChosenWebshopTemplate(e);
                        }}
                    />

                    <Button
                        disabled={chosenWebshopTemplate?.value === undefined}
                        onClick={() => {
                            addWebshopTemplateToCompany(chosenWebshopTemplate?.value)
                            closePopup();
                        }}
                    >
                        Aanmaken
                    </Button>

                </Popup>
                <Popup
                    popupID='addPackingSlip'
                    title={'Pakbon toevoegen'}
                >
                    <div className='input-group' >
                        <label className={"required"} htmlFor={"orderSelect"}> Kies Order </label>
                        <Select id={"orderSelect"} options={orderSelectData}
                            value={packingSlipOrder}
                            onChange={(order) => setPackingSlipOrder(orderSelectData.find((el) => el.value === order.value))}
                        />
                    </div>
                    <Button disabled={packingSlipOrder?.value === undefined} onClick={() => { closePopup(); navigate(`/orders/${packingSlipOrder?.value}/packing-slips/create`) }} >Aanmaken</Button>
                </Popup>

                <CompanyDetails company={company} />
                <CompanyInfo company={company} setCompany={setCompany} companyID={company?.id} companyName={company?.name} />

                <CompanyPersonsList data={contactPersons} title='Contactpersonen' name='contactPeople' icon='plus' path={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${companyId}/contactpersons/create`} structure={{ 350: ['100%', 0, 0], 500: ['100%', 0, '110px'], default: ['50%', '50%', '110px'] }} />

                <IF condition={!isSupplier}>
                    <CompanyPersonsList data={responsiblePersons} title='verantwoordelijke(n)' name='managementPeople' icon='pencil' path={`/crm/companies/${companyId}/responsible-people`} structure={{ 350: ['100%', 0, 0], 500: ['100%', 0, '80px'], default: ['50%', '50%', '80px'] }} />
                </IF>

                <Block style={{ maxHeight: 500 }} title={'Vestigingen'} name={'vestiging'} headerRightSideChildren={<Button to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${companyId}/branch/create`} className='btn btn--round btn--black'><Icon name='plus' /></Button>}>
                    <Table
                        data={branches ?? []}
                        structure={{ 350: ['100%', 0, 0], 500: ['100%', 0, '110px'], default: ['50%', '50%', '110px'] }}
                        hover
                        scroll
                        border='row'
                    />
                </Block>

                <IF condition={!isSupplier}>
                    <CompanyCalculations calculations={company?.calculations ?? []} icon='plus' clickFunction={() => {
                        calcCreateFunction()
                    }} />
                </IF>

                <IF condition={!isSupplier}>
                    <Block title={'Offertes'} name={'offertes'} headerRightSideChildren={<Button onClick={() => openPopup("createQuote")} className='btn btn--round btn--black'><Icon name='plus' /></Button>}>
                        <Table
                            headers={[
                                {
                                    value: 'Nummer',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'Omschrijving',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'status',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: ' ',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                            ]}
                            data={quoteTableData ?? []}
                            structure={{
                                1450: ['115px', '100%', '90px', '110px'],
                                1700: ['120px', '100%', '100px', '110px'],
                                2000: ['150px', '100%', '120px', '110px'],
                                default: ['150px', '100%', '175px', '110px']
                            }}
                            border='row'
                            scroll
                            hover
                        />
                    </Block>

                    <Block title={'Orders'} name={'orders'} headerRightSideChildren={<Button onClick={() => openPopup("addOrder")} className='btn btn--round btn--black'><Icon name='plus' /></Button>}>
                        <Table
                            headers={[
                                {
                                    value: 'Nummer',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'Omschrijving',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'status',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'datum',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: ' ',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                            ]}
                            data={orderTableData ?? []}
                            structure={{
                                1300: ['130px', '100%', '135px', '115px'],
                                1500: ['120px', '100%', '110px', '115px'],
                                1700: ['130px', '100%', '135px', '115px'],
                                1900: ['150px', '100%', '150px', '130px'],
                                default: ['150px', '100%', '175px', '130px']
                            }}
                            border='row'
                            scroll
                            hover
                        />
                    </Block>

                    <Block title={'Pakbonnen'} name={'pakbonnen'} headerRightSideChildren={<Button onClick={() => openPopup("addPackingSlip")} className='btn btn--round btn--black'><Icon name='plus' /></Button>}>
                        <Table
                            headers={[
                                {
                                    value: 'Nummer',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'Order Nummer',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                },
                                {
                                    value: 'Omschrijving',
                                    attributes: {
                                        style: {
                                            background: "white",
                                            color: "black",
                                            margin: '0px',
                                        }
                                    }
                                }

                            ]}
                            data={packingSlipTableData ?? []}
                            structure={{
                                1700: ['115px', '130px', '100%'],
                                default: ['150px', '150px', '100%',]
                            }}
                            border='row'
                            scroll
                            hover
                        />
                    </Block>
                    <HasRole roles={['admin']} >
                        <Block title={'Templates'} name={'templates'} headerRightSideChildren={<Button onClick={() => openPopup("addCalculationTemplate")} className='btn btn--round btn--black'><Icon name='plus' /></Button>}>
                            <Table
                                headers={[
                                    {
                                        value: 'Naam',
                                        attributes: {
                                            style: {
                                                background: "white",
                                                color: "black",
                                                margin: '0px',
                                            }
                                        }
                                    }
                                ]}
                                data={templateTableData ?? []}
                                structure={{
                                    default: ['100%', '70px']
                                }}
                                border='row'
                                scroll
                                hover
                            />
                        </Block>
                    </HasRole>
                </IF>

                <IF condition={isSupplier}>
                    <Block title={'Materialen'} name={'materials'}>
                        <Table
                            data={supplierMaterialData ?? []}
                            border='row'
                            scroll
                            hover
                        />
                    </Block>
                </IF>
            </Grid>

            <ConfirmationPopup
                popupID={"calcConformation"}
                title={"Calculatie aanmaken"}
                description={"Voor welk bedrijf wil je deze calculatie maken?"}
                confirmText={{ style: { backgroundColor: "#e6a530" }, value: "Pretore" }}
                declineText={{ style: { backgroundColor: "#76b83a" }, value: "HJMG" }}
                functionOnConfirm={(e) => createCalculation(e, company?.id)}
                functionOnDecline={(e) => createHjmgCalculation(e, company?.id)}
            />

            <ConfirmationPopup
                popupID={"createQuote"}
                title={"Offerte aanmaken"}
                confirmText={{ style: {}, value: "Offerte aanmaken" }}
                contentArray={{
                    style: { height: "200px" },
                    value: [
                        <Grid
                            key='createQuote-1'
                            columns={1}
                            style={{ height: "200px", alignContent: 'space-between' }}
                        >
                            <div>
                                <label>Kies een calculatie:</label>
                                <Select
                                    id="calculationSelect"
                                    name={"Calculaties"}
                                    maxMenuHeight={130}
                                    style={{ height: "180px" }}
                                    styles={colourStyles}
                                    options={company?.calculations?.map((el) => {

                                        let color = "white";
                                        if (el?.owner?.toLowerCase() === "pretore") {
                                            color = "#e6a530"
                                        }
                                        if (el?.owner?.toLowerCase() === "hjmg") {
                                            color = "#76b83a"
                                        }

                                        return { label: `${el?.number} ${el?.projectDescription !== undefined && " - " + el?.projectDescription}`, value: el?.id, color: color }
                                    }
                                    ) ?? []}
                                    onChange={(e) => setCreateQuoteData({ disabled: false, value: e.value })}
                                />
                            </div>
                            <span hidden={!createQuoteData?.disabled} style={{ color: "red", display: "flex", justifyContent: "center" }}>
                                Kies eerst een calculatie om door te gaan!
                            </span>
                            <br />
                        </Grid>
                    ]
                }}
                functionOnConfirm={(e) => addQuoteToCompany(e)}
                confirmButtonProps={{ disabled: createQuoteData.disabled, value: createQuoteData.value }}
                singularButton={true}
            />
        </Container>
    )
}

/**
 * Gets the contactpeople and responsible people for this company
 * @param {object} company
 * @param {boolean} isSupplier
 * @returns {[[], []]}
 */
function getPeople(company, managers, isSupplier, deleteContact, navigate) {

    // build an array for the contactpeople
    const contactPersons = [];
    for (const person of company?.contactPersons ?? []) {
        if (!isEmpty(person)) {
            contactPersons.push({
                attributes: {
                    onClick: () => {
                        navigate(`contactpersons/${person.id}`)
                    }
                },
                data: [
                    `${person?.firstName ?? ''} ${person?.prefixToSurname ?? ''} ${person?.surname ?? ''}`.replace(/ {2,}/, ' '),
                    /^\W+$/.test(person?.function) ? <i style={{color: 'grey'}}>Geen functie</i> : person?.function,
                    <div className="company__list-actions">
                        <>
                            {person?.telephone?.dialingCode !== undefined && person?.telephone?.telephoneNumber !== undefined &&
                                <a href={`tel:+${person?.telephone?.dialingCode} ${person?.telephone?.telephoneNumber}`}
                                    onClick={(e) => e.stopPropagation()}
                                    className='btn btn--round'><Icon name='phone' /></a>
                            }
                            {person?.email !== undefined &&
                                <a href={`https://outlook.office.com/?path=/mail/action/compose&to=${person?.email}`}
                                    onClick={(e) => e.stopPropagation()}
                                    className='btn btn--round'
                                    target='_blank' rel="noreferrer"><Icon name='mail' /></a>
                            }
                            {<Button onClick={(e) => deleteContact(e, person.id)}
                                className='btn btn--round'
                                target='_top' rel="noreferrer"><Icon name='trash-can' /></Button>
                            }
                        </>
                    </div>
                ]
            });
        }
    }

    // build an array for the management people
    const responsiblePersons = [];
    if (!isSupplier && company?.id !== undefined) {
        for (const managerType of (managers?.data ?? [])) {
            for (const record of managerType.managerRecords) {
                const user = record?.user;

                responsiblePersons.push([
                    user.username,
                    managerType.name,
                    <>
                        {user?.telephone?.dialingCode && user?.telephone?.telephoneNumber &&
                            <a href={`tel:+${user?.telephone?.dialingCode} ${user?.telephone?.telephoneNumber}`}
                                onClick={(e) => e.stopPropagation()}
                                className='btn btn--round'><Icon name='phone' /></a>
                        }
                        {user?.email &&
                            <a href={`https://outlook.office.com/?path=/mail/action/compose&to=${user?.email}`}
                                onClick={(e) => e.stopPropagation()}
                                className='btn btn--round'
                                target='_blank' rel="noreferrer"><Icon name='mail' /></a>
                        }
                    </>
                ])
            }

        }

    }

    return [contactPersons, responsiblePersons];
}
