import React, {useContext, useState} from "react";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import {BooleanControlled} from "UI/App/Components/Form/Boolean";
import Select from "react-select";

export default function CreateWareHouse({
                                            closePopup = () => {
                                            }
                                        }) {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');

    const sizeOptions = [
        {
            label: 'Europallet (80x120cm.)',
            value: 'Europallet (80x120cm.)'
        },
        {
            label: 'Pallet (80x60cm.)',
            value: 'Pallet (80x60cm.)'
        },
        {
            label: 'Mini pallet (40x60cm.)',
            value: 'Mini pallet (40x60cm.)'
        },
        {
            label: 'A4-doos (30x22cm.)',
            value: 'A4-doos (30x22cm.)'
        },
        {
            label: 'Anders',
            value: 'Anders'
        }
    ]
    const [chosenSize, setChosenSize] = useState(null);

    const categoryOptions = [
        {
            label: 'Normale voorraad',
            value: 'Normale voorraad'
        },
        {
            label: 'Snelle voorraad',
            value: 'Snelle voorraad'
        },
        {
            label: 'Bulk voorraad',
            value: 'Bulk voorraad'
        }
    ]
    const [chosenCategory, setChosenCategory] = useState({
        label: 'Normale voorraad',
        value: 'Normale voorraad'
    });

    const [isWarehouse, setIsWarehouse] = useState(true);
    const {authAxios} = useContext(FetchContext);

    const createWarehouse = () => {
        authAxios.post(`/logistics/warehouses`, {
            data: {
                name: name,
                location: location,
            }
        }).then(({data}) => {
            toast.success(`Magazijn aangemaakt!`);
            closePopup();
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van het magazijn!`);
        });
    }

    return (
        <>
            <div className='input-group'>
                <label>
                    Naam
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                />
                <label>
                    Locatie
                </label>
                <input
                    type="text"
                    value={location}
                    onChange={(e) => {
                        setLocation(e.target.value)
                    }}
                />
                {isWarehouse ? <>
                    <label>
                        Maat
                    </label>
                    <Select
                        options={sizeOptions}
                        value={chosenSize}
                        onChange={(e) => {
                            setChosenSize(e)
                        }}
                    />
                    <label>
                        Categorie
                    </label>
                    <Select
                        options={categoryOptions}
                        value={chosenCategory}
                        onChange={(e) => {
                            setChosenCategory(e)
                        }}
                    />
                </> : <></>}
                <button
                    onClick={createWarehouse}
                    disabled={name === '' || location === '' || (isWarehouse ? chosenSize === null : false)}
                >
                    Opslaan
                </button>
            </div>
        </>
    );
}
