import { useContext, useEffect, useState } from 'react';
import { FetchContext } from 'App/Strapi/FetchContext';
import Popup, { closePopup } from 'UI/App/Components/Popup/Popup';
import { toast } from 'react-toastify';
import Select from 'react-select'

export default function ImportCatalog() {
    const { authAxios } = useContext(FetchContext);

    const [csvFile, setCsvFile] = useState();
    const [validCsv, setValidCsv] = useState(false);
    const [importSetting, setImportSetting] = useState(1);
    const [csvHeader, setCsvHeader] = useState([]);
    const [csvArray, setCsvArray] = useState([]);

    const fileReader = new FileReader();

    // csv can have different types of separators, these are the ones most commonly used
    const csvSeparators = [
        ",",
        ";",
        "\t",
        "|"
    ];
    const options = [
        { value: 0, label: 'Pas prijzen aan' },
        { value: 1, label: 'Lijst importeren' },
    ];
    // requirements to check for BEFORE we try to update/create entries
    // this gets used by the verify csv function to detect if we have all the parts
    const requirementsPerOption = [
        {
            value: 0,
            required: [
                "Id",
                "Inkoopprijs",
                "Actuele inkoop",
                "Verkoopprijs"
            ],
            // the field strapi wil update on
            strapiUpdateOn: "id",
            noneEmptyFields: [
                "Id",
                "Inkoopprijs",
                "Actuele inkoop",
                "Verkoopprijs",
            ],
        },
        {
            value: 1, // value of the select
            required: [
                "Categorie",
                "Workflow / artikelnummers",
                "Artikelnummer leverancier",
                "Calculatie omschrijving",
                "Offerte omschrijving NL",
                "Offerte omschrijving UK",
                "Lengte in meters",
                "Breedte in meters",
                "Hoogte in meters",
                "Dikte in millimeters",
                "Gewicht in grammen",
                "Min. aantal per bestelling",
                "Looprichting",
                "Inkoopprijs",
                "Actuele inkoop",
                "Verkoopprijs",
                "aantal per eenheid",
                "eenheid",
                "Coated",
                "Datum laatste prijs",
                "Actief",
                "Order eenheid"
            ],
            // the field strapi wil update on
            strapiUpdateOn: "id",
            // fields that cant be empty
            noneEmptyFields: [
                "aantal per eenheid",
                "Verkoopprijs",
                "Categorie",
                "Offerte omschrijving NL",
                "Offerte omschrijving UK",
            ],
            // fields that are only allowed to have a value from a pre defined list
            enumFields: {
                'eenheid': [
                    "kilogram",
                    "meter",
                    "strekkende meter",
                    "vierkante meter",
                    "enveloppen",
                    "vel",
                    "stuks",
                    "dozen",
                    "liter",
                    "rol"
                ],
                'Order eenheid': [
                    "rol",
                    "pallet",
                    "doos",
                    "dozen",
                    "meter",
                    "liter",
                    "vel"
                ]
            }
        }
    ];

    useEffect(() => {
        setValidCsv(false);
        if (csvFile !== undefined) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvToArray(text);
            };
            fileReader.readAsText(csvFile);
        }

    }, [csvFile]);

    useEffect(() => {
        if (csvHeader.length > 0) {
            validateImportForType();
        }
    }, [csvHeader, importSetting]);

    async function ImportCsv(e) {
        e.preventDefault();
        let toastId = toast.loading("Materiaallijst importeren...");

        let translatedArr = translateFields(csvArray);


        if (importSetting === 0) {
            // filter out the fields we need for the update
            let requiredFieldNames = ['id', 'purchasePrice', 'regularPrice', 'salePrice'];

            translatedArr = translatedArr.map(function (material) {
                return Object.keys(material)
                    .filter(fieldName => requiredFieldNames.includes(fieldName))
                    .reduce((updatedMaterial, fieldName) => {
                        updatedMaterial[fieldName] = material[fieldName];
                        return updatedMaterial;
                    }, {});
            })
            setValidCsv(false);

            authAxios.put('/logistics/materials/update', {
                data: translatedArr,
            }).then(({ data: { data } }) => {
                toast.update(toastId, { render: "Succesvol geïmporteerd", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true });
                closePopup();
                window.location.reload();
                setValidCsv(true);
            }).catch((exception) => {
                toast.update(toastId, { render: "Importeren mislukt", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true });
                setValidCsv(true);
                
                console.error(exception);
            });
        } else if (importSetting === 1) {
            setValidCsv(false);

            if (!window.confirm("LET OP: Alle materialen en bijbehorende voorraad wordt verwijderd!")) {
                toast.update(toastId, { render: "Importeren geannuleerd.", type: "info", isLoading: false, autoClose: 3000, closeOnClick: true });
                setValidCsv(true);
                return;
            }

            authAxios.put('/logistics/materials/createOrUpdate', {
                data: translatedArr,
            }).then(({ data: { data } }) => {
                toast.update(toastId, { render: "Succesvol geïmporteerd", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true });
                closePopup();
                setValidCsv(true);
                //toast.success('Succesvol geïmporteerd');
            }).catch((exception) => {
                toast.update(toastId, { render: "Importeren mislukt", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true });
                setValidCsv(true);
                
                console.error(exception);
            });

        }
    };

    function translateFields(array) {

        let curDate = new Date();
        let stringDate = curDate.getFullYear() + "-" + ("0" + curDate.getMonth()).slice(-2) + "-" + ("0" + curDate.getDate()).slice(-2);

        function zeroIfEmpty(value) {
            return isNaN(Number(value?.replaceAll(",", '.'))) ? 0 : Number(value?.replaceAll(",", '.'));
        }

        function nullIfEmpty(value) {
            return value === "" ? null : value;
        }

        function parseDate(date) {
            if (!/[0-9]{1,2}[/\\-][0-9]{1,2}[/\\-][0-9]{4}/.test(date)) {
                return stringDate
            }
            const [day, month, year] = date.split(/[/\\-]/);
            return `${year}-${month}-${day}`;
        }

        return array.map((item) => ({
            id: nullIfEmpty(item["Id"]),
            category: item["Categorie"],
            internalSKU: item["Workflow / artikelnummers"],
            supplierSKU: nullIfEmpty(item["Artikelnummer leverancier"]),
            calculationDescription: item["Calculatie omschrijving"],
            quotationNameNL: item["Offerte omschrijving NL"],
            quotationNameEN: item["Offerte omschrijving UK"],
            lengthInMeters: zeroIfEmpty(item["Lengte in meters"]),
            widthInMeters: zeroIfEmpty(item["Breedte in meters"]),
            heightInMeters: zeroIfEmpty(item["Hoogte in meters"]),
            thicknessInMM: zeroIfEmpty(item["Dikte in millimeters"]),
            weightInGram: zeroIfEmpty(item["Gewicht in grammen"]),
            unitsPerOrder: zeroIfEmpty(item["Min. aantal per bestelling"]),
            unitsPerOrderName: nullIfEmpty(item["Order eenheid"]),
            runningDirection: nullIfEmpty(item["Looprichting"]),
            purchasePrice: zeroIfEmpty(item["Inkoopprijs"]),
            regularPrice: zeroIfEmpty(item["Actuele inkoop"]),
            salePrice: zeroIfEmpty(item["Verkoopprijs"]),
            countPerUnit: zeroIfEmpty(item["aantal per eenheid"]),
            unit: nullIfEmpty(item["eenheid"]),
            coated: item["Coated"]?.toLowerCase() === "ja",
            lastPriceUpdate: parseDate(item["Datum laatste prijs"]),
            active: item["Actief"]?.toLowerCase() === "ja",
        }));
    }

    function OnChange(e) {
        setCsvFile(e.target.files[0]);
    }

    function typeChange(e) {
        setImportSetting(e.value);
    }

    function csvToArray(input) {
        let identifier = input.slice(0, input.indexOf("\n")).replace(/[\r\n]/gm, '');
        let splittingCharacter = null;

        // try to separate on know separators and find id.
        for (let separator of csvSeparators) {
            if (identifier.split(separator).includes("Id")) {
                splittingCharacter = separator;
            }

        }
        // no notify the user that something is wrong with the given csv
        if (splittingCharacter === null) {
            toast.error("De csv is in een onbekend indeling, of heeft geen Id veld")
        }

        // split on working separator
        identifier = identifier.split(splittingCharacter)
        let rows = input.slice(input.indexOf("\n")).replace(/\n/gm, '').split("\r");

        // prepare rows with header names
        rows = rows.map(function (row) {

            if (row === "" || row === undefined) {
                return;
            };
            let values = row.replace(/"/gm, '').split(splittingCharacter);

            return identifier.reduce((obj, header, index) => {
                obj[header] = values[index];
                return obj
            }, {});

        })
        // filter out empty entries
        rows = rows.filter(function (row) {
            if (row !== undefined) {
                return row;
            }
        });

        // set header, and rows
        setCsvHeader(identifier);
        setCsvArray(rows);

    }

    function validateImportForType() {

        // get all wanted headers from imported csv
        let wantedHeaders = csvHeader.filter(function (part) {

            if (requirementsPerOption[importSetting].required.includes(part)) {
                return part
            }
        })

        // mutiple needed fields present
        if (wantedHeaders.length > requirementsPerOption[importSetting].required.length) {
            toast.error("Het gegeven bestand heeft meerdere benodigde velden")
            setValidCsv(false);
            return;
        }

        // some of all of needed fields not present
        if (wantedHeaders.length <= requirementsPerOption[importSetting].required.length) {
            let unAccounted = requirementsPerOption[importSetting].required.filter(x => !wantedHeaders.includes(x));


            if (unAccounted.length > 0) {
                toast.error((unAccounted.length === 1 ? "veld: " : "velden: ") + unAccounted.join(", ") + (unAccounted.length === 1 ? " mist." : " missen."), { autoClose: 2500 + 1250 * unAccounted.length + 1 })
                setValidCsv(false);
                return;
            }
        }

        // check if the data contains only uniq identifiers
        if (requirementsPerOption[importSetting]?.uniqueFields !== undefined) {
            let totalDupes = []
            let foundDupes = false;
            for (let field of requirementsPerOption[importSetting].uniqueFields) {

                let knowContent = csvArray;
                let uniq = []
                let dupes = []
                let counter = 1;
                for (const item of knowContent) {
                    counter++;
                    const isDuplicate = uniq.find((obj) => obj[field] === item[field]);
                    if (!isDuplicate) {
                        uniq.push(item);
                    } else {
                        //dupes.push(item)
                        dupes.push({ data: item, index: counter });
                    }
                }

                totalDupes.push({ [field]: dupes });
                if (dupes.length > 0) {

                    foundDupes = true;
                }

            }

            if (foundDupes) {
                let delay = 5000;
                let errorMsg = [];

                errorMsg.push(<><b>Import mislukt omdat somige uniqe velden NIET uniq zijn</b><br /><br /></>)
                for (const dupe of totalDupes) {
                    // get all items belonging to this 'field'
                    const items = dupe[Object.keys(dupe)[0]];
                    // check if we have mutiple for some nice text
                    let mutiple = items.length > 1 ? "De volgende velden hebben" : "Het volgende veld heeft";


                    // send the 'field' in which a non uniq has been detected
                    errorMsg.push(<>
                        <div>
                            <b><small>{mutiple} een fout in '{Object.keys(dupe)[0]}';</small></b><br />
                        </div>
                    </>);

                    // prepare a counter for visual index
                    let visualIndex = 1;

                    for (const object of items) {
                        delay += 2500;
                        let item = object.data;
                        let idText = <>Bij het veld met id: <b>{item.Id}</b></>
                        if (item.id === undefined) {
                            idText = <>Bij het veld met op de regel: <b>{object.index}</b></>
                        }
                        errorMsg.push(<><div >

                            <small ><b>{items.length > 1 ? visualIndex + "." : ''}</b> {idText}<br /> Is <b>'{item[Object.keys(dupe)[0]]}'</b> niet uniq!</small>
                        </div>
                        </>);
                        visualIndex++;
                    }


                }

                toast.error(<div style={{ width: "270px", overflowWrap: "break-word" }}>{errorMsg}</div>, { autoClose: delay });
                setValidCsv(false);
                return;
            }


        }

        // check if the data has a value
        if (requirementsPerOption[importSetting]?.noneEmptyFields !== undefined) {
            let foundEmptyFields = false;
            let totalEmpties = []
            for (let field of requirementsPerOption[importSetting].noneEmptyFields) {
                let knowContent = csvArray;
                let empty = [];
                let counter = 1;

                for (const item of knowContent) {
                    counter++;
                    if (item[field] === undefined || item[field]?.trim() === "") {

                        foundEmptyFields = true;
                        empty.push({ data: item, index: counter });
                    }
                }
                if (empty.length > 0) {
                    totalEmpties.push({ [field]: empty });
                }

            }
            if (foundEmptyFields) {
                let delay = 5000;
                let errorMsg = [];

                errorMsg.push(<><b>Importeren mislukt! Een aantal unieke waarden zijn niet ingevuld!</b><br /><br /></>)
                for (const empty of totalEmpties) {
                    // get all items belonging to this 'field'
                    const items = empty[Object.keys(empty)[0]];
                    // check if we have mutiple for some nice text
                    let mutiple = items.length > 1 ? "De volgende velden hebben" : "Het volgende veld heeft";
                    // send the 'field' in which a non uniq has been detected
                    errorMsg.push(<>
                        <div>
                            <b><small>{mutiple} een fout in '{Object.keys(empty)[0]}';</small></b><br />
                        </div>
                    </>);

                    // prepare a counter for visual index
                    let visualIndex = 1;

                    for (let item of items) {
                        delay += 2500;

                        let idText = <>Bij het veld met id <b>{item.data.Id}</b></>

                        if (item.data.Id === undefined || item.data.Id === "") {
                            idText = <>Bij het veld  op regel {item.index}</>
                        }

                        errorMsg.push(
                            <div>
                                <small ><b>{items.length > 1 ? visualIndex + "." : ''}</b> {idText} <br /> Is <b>'{Object.keys(empty)[0]}'</b> niet gevuld!</small>
                            </div>
                        );
                        visualIndex++;
                    }
                }
                toast.error(<div style={{ width: "270px", overflowWrap: "break-word" }}>{errorMsg}</div>, { autoClose: delay });
                setValidCsv(false);
                return;
            }
        }
        // check if the data has an allowed value
        if (requirementsPerOption[importSetting]?.enumFields !== undefined) {
            let foundNoneAllowedFields = false;
            let totalInvalids = []
            for (let [key, field] of Object.entries(requirementsPerOption[importSetting].enumFields)) {
                let knowContent = csvArray;
                let noneValidOptions = []
                let counter = 1;
                for (const item of knowContent) {
                    counter++;
                    if (!field.includes(item[key])) {
                        foundNoneAllowedFields = true;
                        noneValidOptions.push({ data: item, index: counter });
                    }

                }
                if (noneValidOptions.length > 0) {
                    totalInvalids.push({ [key]: noneValidOptions });
                }
            }
            if (foundNoneAllowedFields) {
                let delay = 5000;
                let errorMsg = [];

                errorMsg.push(<><b>Importeren mislukt! Een aantal unieke waarden zijn niet ingevuld!</b><br /><br /></>)
                for (const invalid of totalInvalids) {

                    // get all items belonging to this 'field'
                    const items = invalid[Object.keys(invalid)[0]];
                    // check if we have mutiple for some nice text
                    let mutiple = items.length > 1 ? "De volgende velden hebben" : "Het volgende veld heeft";
                    // send the 'field' in which a non uniq has been detected
                    errorMsg.push(<>
                        <div>
                            <b ><small >{mutiple} een fout in '{Object.keys(invalid)[0]}';</small></b >
                        </div>
                    </>);

                    // prepare a counter for visual index
                    let visualIndex = 1;

                    for (const object of items) {

                        delay += 2500;
                        let item = object.data;
                        let idText = <>Bij het veld met id: <b>{item.Id}</b></>
                        if (item.id === undefined) {
                            idText = <>Bij het veld met op de regel: <b>{object.index}</b></>
                        }
                        errorMsg.push(<><div >

                            <small ><b>{items.length > 1 ? visualIndex + "." : ''}</b> {idText} <br /> Is <b>'{Object.keys(invalid)[0]}'</b> niet correct!</small>
                        </div>
                        </>);
                        visualIndex++;
                    }
                }
                toast.error(<div style={{ width: "270px", overflowWrap: "break-word" }}>{errorMsg}</div>, { autoClose: delay });
                setValidCsv(false);
                return;
            }
        }
        setValidCsv(true);
    }
    // return popup
    return (
        <Popup title='Import een csv' popupID='import-catalog'>
            <form onSubmit={ImportCsv} >
                <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={OnChange}
                />
                <Select options={options} onChange={typeChange} defaultValue={{ value: 1, label: 'Lijst importeren' }} />

                <button disabled={!validCsv} >
                    IMPORT CSV
                </button>
                <div><small>Let op: Afhankelijk van het aantal materialen kan het importeren even duren! Dit scherm sluit automatisch zodra het importeren klaar is.</small></div>
            </form>
        </Popup>
    )
}
