import './HandwerkTab.scss';
import {formatCurrency} from "App/Util/format";
import React, {useEffect, useState} from "react";
import Icon from "UI/App/Components/Icon/Icon";

declare type THandwerkEntry = {
    description: string,
    hourlyWage: number,
    quantities: {
        [key: number]: {
            minutes: number,
            price: string
        }
    },
}

export default function HandwerkTab({id, quantities, data = []}: { id:string, quantities: (string|number)[][], data?: Array<THandwerkEntry>|false }) {
    const [handwerkData, setHandwerkData]: [Array<THandwerkEntry>, any] = useState([]);

    useEffect(() => {
        if (data !== false && quantities?.length !== 0) {
            setHandwerkDataByQuantity(data);
        }
    }, [data, quantities]);

    function setHandwerkDataByQuantity(handwerkData: Array<THandwerkEntry>) {
        if (quantities?.length === 0) return;

        setHandwerkData(() => {
            // If the quantity lengths are the same, some quantity was probably updated
            // Soo we find the quantity in this entry, that doesn't exist in the calculation quantities
            // That quantity was updated, to the quantity in the calculation quantities that doesn't exist in this entry
            // We then update the entry with the new quantity
            handwerkData.map((handwerkEntry) => {
                if (quantities.length === Object.values(handwerkEntry.quantities).length) {
                    const quantityMissingInEntry = quantities.find(([quantity, misses]) => {
                        return !Object.keys(handwerkEntry.quantities).includes(String(quantity));
                    });

                    const quantityMissingInQuantities = Object.keys(handwerkEntry.quantities).find(quantity => {
                        return !quantities.map(q => q[0]).includes(parseInt(quantity));
                    });

                    // If we don't have a quantity that's missing in the entry, or a quantity that's missing in the quantities
                    // We don't need to update the entry
                    if (!quantityMissingInEntry || !quantityMissingInQuantities) return handwerkEntry;

                    // Add the updated quantity as a new quantity in the entry
                    handwerkEntry.quantities = {
                        ...handwerkEntry.quantities,
                        [quantityMissingInEntry[0]]: handwerkEntry.quantities[Number(quantityMissingInQuantities)]
                    }

                    // Remove the old quantity from the entry
                    delete handwerkEntry.quantities[Number(quantityMissingInQuantities)];

                    return {...handwerkEntry};
                }
                // If there are more quantities in the calculation than in the entry, we add the missing quantities to the entry
                else if (quantities.length > Object.values(handwerkEntry.quantities).length) {
                    const quantityMissingInEntry = quantities.find(([quantity, misses]) => {
                        return !Object.keys(handwerkEntry.quantities).includes(String(quantity));
                    });

                    // If we don't have a quantity that's missing in the entry, we don't need to update the entry
                    // The quantity was probably a duplicate
                    if (!quantityMissingInEntry) return handwerkEntry;

                    // Add the updated quantity as a new quantity in the entry
                    handwerkEntry.quantities = {
                        ...handwerkEntry.quantities,
                        [quantityMissingInEntry[0]]: {
                            price: formatCurrency(0),
                            minutes: 0
                        }
                    }

                    return {...handwerkEntry};
                }
                // If there are more quantities in the entry than in the calculation, we remove the missing quantities from the entry
                else if (quantities.length < Object.values(handwerkEntry.quantities).length) {
                    const quantityMissingInQuantities = Object.keys(handwerkEntry.quantities).find(quantity => {
                        return !quantities.map(q => q[0]).includes(parseInt(quantity));
                    });

                    // If we don't have a quantity that's missing in the quantities, we don't need to update the entry
                    // The quantity was probably a duplicate
                    if (!quantityMissingInQuantities) return handwerkEntry;

                    // Remove the old quantity from the entry
                    delete handwerkEntry.quantities[Number(quantityMissingInQuantities)];

                    return {...handwerkEntry};
                }

                return {...handwerkEntry};
            });

            return handwerkData;
        })
    }


    const updateHandwerkEntryByKey = (entry: THandwerkEntry, key: number) => {
        setHandwerkData((prev: Array<THandwerkEntry>) => {
            prev[key] = entry;
            return prev;
        });
    }


    const addHandwerkEntry = () => {
        setHandwerkData((prev: Array<THandwerkEntry>) => {
            let quantitiesWithMinutes: THandwerkEntry["quantities"] = {};

            quantities.forEach(([quantity, misses]) => {
                if (typeof quantity === "string") {
                    quantity = parseInt(quantity);
                    quantity = isNaN(quantity) ? 0 : quantity;
                }
                quantitiesWithMinutes[quantity] = {
                    minutes: 0,
                    price: formatCurrency(0)
                };
            })

            return [...prev, {
                description: '',
                hourlyWage: 35,
                quantities: quantitiesWithMinutes
            }];
        });
    }

    const removeHandwerkEntry = (key: any) => {
        const newEntries = handwerkData;
        delete newEntries[key];
        setHandwerkData(newEntries.filter((elem) => elem));
    }

    return (
        <div className={"calculationTab"} id={id}>
            <button className={'handwerk--btn-add btn'} onClick={addHandwerkEntry}><Icon name={'plus'} /></button>
            {handwerkData.map((entry, key) => <HandwerkEntry entry={entry} entryKey={key} key={key} updateEntry={updateHandwerkEntryByKey} removeEntry={removeHandwerkEntry} />)}
        </div>
    )
}

function HandwerkEntry({entry, entryKey, updateEntry, removeEntry}: {entry: THandwerkEntry, entryKey: number, updateEntry: (entry: THandwerkEntry, key: number) => any, removeEntry: (key: any) => any}) {
    const [handwerkEntryData, setHandwerkEntryData] = useState(entry);

    useEffect(() => {
        updateEntry(handwerkEntryData, entryKey)
    }, [handwerkEntryData]);

    const updateThisEntry = (quantity: number, time: any) => {

        const minutes = parseInt(time);

        setHandwerkEntryData(prev => ({
            ...prev,
            quantities: {
                ...prev.quantities,
                [quantity]: {
                    minutes: minutes,
                    price: formatCurrency((minutes / 60) * handwerkEntryData.hourlyWage)
                }
            }
        }))
    }

    return (
        <div className={'handwerk-entry'} data-json={JSON.stringify(handwerkEntryData)}>
            <div>
                <div>
                    <label htmlFor={'handwerk--omschrijving'}>Omschrijving</label>
                    <input
                        id={'handwerk--omschrijving'}
                        type={'text'}
                        placeholder={'Omschrijving'}
                        value={handwerkEntryData.description}
                        onChange={(e) => setHandwerkEntryData((prev) => ({...prev, description: e.target.value}))}
                    />
                </div>
                <div>
                    <label htmlFor={'handwerk--uurloon'}>Uurloon</label>
                    <input
                        id={'handwerk--uurloon'}
                        type={'number'}
                        step={'0.01'}
                        placeholder={'Uurloon'}
                        value={isNaN(handwerkEntryData.hourlyWage) ? '' : handwerkEntryData.hourlyWage}
                        onChange={(e) => setHandwerkEntryData((prev) => ({...prev, hourlyWage: parseFloat(e.target.value)}))}
                    />
                </div>
            </div>
            <button className={'handwerk--btn-remove btn btn--transparent btn--icon-red'} onClick={() => removeEntry(entryKey)}><Icon name={'minus'}/></button>
            <div className={'handwerk--quantities'}>
                {Object.entries(handwerkEntryData.quantities).map(([quantity, value], key) => {
                    let price = (value.minutes / 60) * handwerkEntryData.hourlyWage;
                    price = isNaN(price) ? 0 : price
                    quantity = isNaN(parseInt(quantity)) ? '0' : quantity;

                    return (
                        <div key={key} className={`handwerk--quantity--time`}>
                            <div>
                                <label htmlFor={`handwerk--tijd--${quantity}`}>Handwerk tijd voor {quantity} in minuten</label>
                                <input
                                    id={`handwerk--tijd--${quantity}`}
                                    className={'handwerk-price-quantity'}
                                    type={'number'}
                                    placeholder={'Minuten'}
                                    value={value.minutes ?? ''}
                                    onChange={e => updateThisEntry(parseInt(quantity), e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor={`handwerk--prijs--${quantity}`}>Prijs</label>
                                <input
                                    id={`handwerk--prijs--${quantity}`}
                                    className={'handwerk-price-result'}
                                    type={'text'}
                                    disabled={true}
                                    value={formatCurrency(price)}
                                    data-quantity={quantity}
                                    data-price={price}
                                    data-time={value.minutes}
                                    onChange={() => {}}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
