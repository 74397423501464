import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { FetchContext } from 'App/Strapi/FetchContext';
import openFileInNewTab from 'App/Util/openFileInNewTab';
import { downloadFile } from 'App/Util/fetch';
import HasRole from 'UI/App/Components/Auth/HasRole';
import Block from 'UI/App/Components/Block/Block';
import Container from 'UI/App/Components/Container/Container';
import Grid from 'UI/App/Components/Grid/Grid';
import { getFormData } from 'UI/App/Components/Form/Inputs';
import Icon from 'UI/App/Components/Icon/Icon';
import { Spinner } from 'UI/App/Components/Spinner';
import './Account.scss';

export default function Account() {
    const { authState, setUserInfo } = useContext(AuthContext);
    const { authAxios } = useContext(FetchContext);
    const [submittable, setSubmittable] = useState(false);

    const signatureRef = useRef(null);

    const signatureDefaultValues = {
        id: null,
        blobURL: null,
        mime: null,
        newFile: null
    };

    const [profileImage, setProfileImage] = useState(null);
    const [signatureImage, setSignatureImage] = useState({ ...signatureDefaultValues });

    useEffect(() => {
        authAxios
            .get(`../users/${authState.user.id}`, {
                params: {
                    populate: ['signature', 'profileImage']
                }
            })
            .then(({ data }) => {
                if (data.signature?.id) {
                    downloadFile(authAxios, data.signature.id, data.signature.mime, 'medium')
                        .then((url) => {
                            setSignatureImage((signature) => {
                                return { ...signature, id: data.signature.id, blobURL: url, mime: data.signature.mime };
                            });
                        })
                        .catch((reason) => toast.error(reason));
                }

                if (data.profileImage?.id) {
                    downloadFile(authAxios, data.profileImage.id, data.profileImage.mime, 'medium')
                        .then((url) => {
                            setProfileImage(url);
                        })
                        .catch((reason) => toast.error(reason));
                }
            });
    }, [authAxios, authState.user.id]);

    // on emptying attachment blobURL through file input cancel or remove button
    useEffect(() => {
        if (validFile(signatureImage?.newFile) && signatureRef.current && !signatureImage?.newFile) {
            // file input value can only be programmatically set to empty string to clear the input's text
            signatureRef.current.value = '';
        }
    }, [signatureImage.newFile]);

    async function submitSignature(e) {
        e.preventDefault();

        const data = await getFormData(e, authAxios);

        authAxios
            .put(`../users/me/update`, data)
            .then(({ data: { updatedUser } }) => {
                setUserInfo(updatedUser);
                toast.success('Handtekening succesvol bijgewerkt');
            })
            .catch((e) => {
                toast.error('Er is een fout opgetreden bij het bijwerken van je handtekening!');
                console.error(e);
            });
    }

    function validFile(file) {
        let errors = [];

        // If file not set false
        if (file === null) return false;

        // Validate size max 5MB
        if (file.size > 5242880) {
            errors.push('Bestand mag niet groter dan 5MB zijn.');
        }

        // Validate file type
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
            errors.push('Bestand mag alleen van type JPG of PNG zijn.');
        }

        if (errors.length !== 0) {
            toast.error(errors.toString());
        }

        setSubmittable(errors.length === 0);
        return errors.length >= 1;
    }

    return (
        <Container>
            <Block name='account' title='Account gegevens'>
                <div className='account__input__wrapper'>
                    <Grid
                        columns={{
                            1000: 1,
                            default: 2
                        }}
                    >
                        <div>
                            <label htmlFor='firstname'>Voornaam</label>
                            <input
                                type='text'
                                id='firstname'
                                name='firstname'
                                value={authState.user.firstName}
                                readOnly
                                disabled
                            />
                        </div>
                        <div>
                            <label htmlFor='lastname'>Achternaam</label>
                            <input
                                type='text'
                                id='lastname'
                                name='lastname'
                                value={authState.user.lastName}
                                readOnly
                                disabled
                            />
                        </div>
                    </Grid>
                    <Grid
                        columns='1'
                        style={{
                            marginTop: 'var(--gap-l)'
                        }}
                    >
                        <div>
                            <label htmlFor='email'>E-mail</label>
                            <input
                                type='email'
                                id='email'
                                name='email'
                                value={authState.user.email}
                                readOnly
                                disabled
                            />
                        </div>
                    </Grid>
                </div>
            </Block>

            <Grid
                columns={{
                    1000: 1,
                    default: 2
                }}
                style={{ marginTop: '20px' }}
            >
                <Block name='profileImage' title='Profielfoto'>
                    {!profileImage && <Spinner />}
                    {profileImage && <img src={profileImage} alt='Profielfoto' />}
                </Block>

                <Block name='signature' title='handtekening'>
                    <form onSubmit={submitSignature}>
                        <div className='input-group'>
                            <label htmlFor='signature'>Handtekening uploaden</label>
                            <div className='file-upload'>
                                {signatureImage.blobURL && (
                                    <>
                                        <div className='file-upload__preview__container auto-size'>
                                            {/* show preview image */}
                                            <div
                                                className='file-upload__preview__wrapper'
                                                onClick={() => {
                                                    if (signatureImage.newFile) {
                                                        openFileInNewTab(signatureImage.blobURL);
                                                    } else {
                                                        openFileInNewTab(undefined, authAxios, signatureImage.id);
                                                    }
                                                }}
                                            >
                                                <div className='overflow-hidden--center'>
                                                    <img
                                                        src={signatureImage?.blobURL}
                                                        alt={'handtekening afbeelding'}
                                                        className='file-upload__preview-img'
                                                    />
                                                </div>
                                            </div>

                                            {/* show remove button */}
                                            <button
                                                type='button'
                                                className='file-upload__delete-btn btn btn--transparent btn--icon-red'
                                                onClick={() => setSignatureImage({ ...signatureDefaultValues })}
                                                title='verwijder bijlage'
                                                form='' // remove button from the <form>
                                            >
                                                <Icon name='close' width='30px' />
                                            </button>
                                        </div>
                                    </>
                                )}

                                <input
                                    type='file'
                                    id='signature'
                                    name='signature'
                                    ref={signatureRef}
                                    onChange={({ target }) => {
                                        if (target.files[0]) {
                                            setSignatureImage((signature) => {
                                                // destruct to prevent references overriding
                                                signature = { ...signatureDefaultValues };

                                                // If file is valid submit else return toast
                                                // if(validFile(signature)){
                                                signature.blobURL = window.URL.createObjectURL(target.files[0], {
                                                    type: target.files[0].type
                                                });
                                                signature.mime = target.files[0].type;
                                                signature.newFile = target.files[0];

                                                return { ...signature };
                                                // }else{
                                                //     toast.error('Er is een fout opgetreden bij het bijwerken van je handtekening!');
                                                // }
                                            });
                                        } else {
                                            setSignatureImage({ ...signatureDefaultValues });
                                        }
                                    }}
                                    accept='image/*'
                                    data-delete-image={signatureImage.id === null && signatureImage.newFile === null}
                                />
                            </div>
                        </div>

                        {/* submit */}
                        <div className='input-group'>
                            <button type='submit' disabled={!submittable}>
                                Handtekening wijzigen <Icon name='save' />
                            </button>
                        </div>
                    </form>
                </Block>
            </Grid>
        </Container>
    );
}
