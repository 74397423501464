import { useEffect, useState } from 'react';
import "./ToggleSwitch.scss";

export default function ToggleSwitch({ attributes, checked = false, value = 'checked', onChange }) {
	const [isChecked, setChecked] = useState(checked);

    useEffect(() => {
        setChecked(checked);
    }, [checked]);
    
	return (
		<input {...attributes} type='checkbox' key={`switch-${attributes?.id}`} className={`toggle-switch ${attributes?.className ?? ''}`} checked={isChecked} defaultValue={value} onChange={(e) => { setChecked(!isChecked); onChange(e, !isChecked); }} />
	);
}
