import {createContext, useContext} from 'react';
// eslint-disable-next-line no-unused-vars
import axios, {AxiosInstance} from 'axios';
import {AuthContext} from './AuthProvider';

/**
 * @type {React.Context<{authAxios: AxiosInstance}|null>}
 */
const FetchContext = createContext(null);
const { Provider } = FetchContext;

const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/v1'
});

function FetchProvider({ children }) {
    const authContext = useContext(AuthContext);

    // remove all old interceptors
    authAxios.interceptors.request.clear();

    // use an interceptor on the Axios instance to allow for changing the token and always using the up-to-date token
    authAxios.interceptors.request.use(
        (config) => {
            const { origin } = new URL(config.baseURL);
            const allowedOrigins = [process.env.REACT_APP_BACKEND_URL];

            if (allowedOrigins.includes(origin)) {
                config.headers.Authorization = `Bearer ${authContext.authState.token}`;
                config.headers['X-Graph-Token'] = authContext.authState.msToken;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return (
        <Provider
            value={{
                authAxios: authAxios
            }}
        >
            {children}
        </Provider>
    );
}

export { FetchContext, FetchProvider };
