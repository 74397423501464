import User from "App/Strapi/User";

export default function HasPermission({ roles, allowedIfOwner = false, ownerId, children }: { roles?: string[], allowedIfOwner: boolean, ownerId?: number, children: any}) {
    const hasPermission = roles && User.hasRole(roles);
    const isOwner = ownerId && User.isOwner(ownerId);

    if (hasPermission || (allowedIfOwner && isOwner)) {
        return children;
    } else {
        return null;
    }
}