export default class OrderTimer {

    constructor(orderId, axios) {
        this.axios = axios;
        this.orderId = orderId;
    }

    /**
     * Start a new timer for a machine
     * @returns {Promise<void>}
     * @throws Error
     */
    async start(timerFor) {
        return this.axios.post(`/orders/${this.orderId}/timers/start`, {
            for: timerFor,
            start: new Date().toISOString()
        })
            .then(response => {
                return response.data;
            })
            .catch(e => {
                console.error(e);

                if (e.response?.data?.error?.message) {
                    throw new Error(e.response.data.error.message);
                }

                throw e;
            })
    }

    /**
     * Stop a timer
     * @param timerId
     * @param end
     * @returns {Promise<void>}
     */
    async stop(timerId, end = null) {
        return this.axios.put(`/orders/timers/${timerId}/stop`, {
            end: new Date(end ?? new Date()).toISOString()
        })
            .then(response => {
                return response.data;
            })
            .catch(e => {
                console.error(e);

                if (e.response?.data?.error?.message) {
                    throw new Error(e.response.data.error.message);
                }

                throw e;
            })
    }
}