import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FetchContext } from 'App/Strapi/FetchContext'
import Title from 'UI/App/Components/Title/Title';
import isEmpty from 'lodash/isEmpty';
import PermissionGroup from './PermissionGroup';
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';

export default function RolesPermissions({ selectedRole }) {
    const { authAxios } = useContext(FetchContext);

    const [permissions, setPermissions] = useState({});
    useEffect(() => {
        if (selectedRole?.id) {
            authAxios.get(`../users-permissions/roles/${selectedRole.id}`).then(({ data }) => {
                if (isEmpty(data) || (data?.role?.name ?? '').toLowerCase() === 'admin') {
                    setPermissions({});
                    return;
                }

                setPermissions(data.role.permissions);
            }).catch((exception) => {
                toast.error(`De permissies voor "${selectedRole.name}" konden niet opgehaald worden`);

                console.error(exception);
            });
        }
        else {
            setPermissions({});
        }
    }, [authAxios, selectedRole]);

    return (
        <Grid columns='3'>
            {Object.keys(permissions).map((name, key) => {
                return <Block key={key} name={name} title={name}>
                    <PermissionGroup name={name} controllers={permissions[name].controllers} />
                </Block>
            })}
        </Grid>
    );
}
