import {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import VestigingDetails from "./Blocks/VestigingDetails/VestigingDetails";
import Container from "UI/App/Components/Container/Container";

export default function Branch() {
	const fetchContext = useContext(FetchContext);

	// get the params from the url
	const params = useParams();

	// check if on suppliers path
	const location = useLocation();
	const isSupplier = /\/suppliers\//.test(location.pathname);

	const [vestiging, setVestiging] = useState({});

	useEffect(() => {
		fetchContext.authAxios.get(`/crm/companies/branches/${params.branchId}`, {
			params: {
				populate: "*"
			}
		}).then(({ data: { data } }) => {
			setVestiging(data);
		}).catch((exception) => {
			console.error(exception);
		});
	}, [fetchContext.authAxios, params.branchId]);

	return (
		<Container>
			<VestigingDetails vestiging={vestiging} />
		</Container>
	);
}
