import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import capitalize from 'lodash/capitalize';
import { FetchContext } from 'App/Strapi/FetchContext';
import { b64toBlob } from 'App/Util/transform';
import Table from 'UI/App/Components/Table/Table';
import Icon from 'UI/App/Components/Icon/Icon';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
// set status options
const statusses = [
    'concept',
    'uitgebracht',
    'herzien',
    'geaccepteerd',
    'afgewezen prijs',
    'afgewezen planning',
    'afgewezen klant',
    'afgewezen overig',
    'verlopen'
];
const quoteStatusOptions = [];
for (const status of statusses) {
    quoteStatusOptions.push(
        <option key={status} value={status}>
            {capitalize(status)}
        </option>
    );
}
// set base quotes fetch query
const projectsQuery = {
    populate: {
        calculation: {
            fields: ['id', 'number']
        },
        order: {
            fields: ['id', 'number']
        },
        number: true,
        description: true,
        machinePlanItems: true,
    },
    sort: 'id:desc'
};
export default function Projects() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();
    // create a state for the table data
    const [tableData, setTableData] = useState([]);
    const [quotes, setQuotes] = useState([]);
    const [projects, setProjects] = useState([]);
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        searchSettings: {
            enabled: true,
            strapiFields: ['number', '[company][name]']
        },
        filters: [
            {
                name: 'status',
                type: 'select',
                options: [
                    { value: '*', name: 'Alle' },
                    { value: 'concept', name: 'Concept' },
                    { value: 'uitgebracht', name: 'Uitgebracht' },
                    { value: 'herzien', name: 'Herzien' },
                    { value: 'geaccepteerd', name: 'Geaccepteerd' },
                    { value: 'afgewezen prijs', name: 'Afgewezen prijs' },
                    { value: 'afgewezen planning', name: 'Afgewezen planning' },
                    { value: 'afgewezen klant', name: 'Afgewezen klant' },
                    { value: 'afgewezen overig', name: 'Afgewezen overig' },
                    { value: 'verlopen', name: 'Verlopen' }
                ]
            }
        ]
    });
    const updateStatus = useCallback(
        (value, quoteId, index) => {
            authAxios
                .put(`/quotes/${quoteId}`, {
                    data: {
                        status: value
                    }
                })
                .then(({ data }) => {
                    // update quotes state
                    setQuotes((prev) => {
                        prev[index].status = value;
                        return [...prev];
                    });
                    toast.success(`Offerte: ${data.data.number} status omgezet naar ${value}`);
                })
                .catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het bewerken van offerte.`);
                });
        },
        [authAxios]
    );
    const getTableData = useCallback(() => {
        // add our pagination value to the query
        projectsQuery.pagination = {
            pageSize: resultsPerPage,
            page: currentPage
        };
        // fetch the quotes
        authAxios
            .get(`/projects?${stringify(projectsQuery)}&${filterQuery}`)
            .then(({ data }) => {
                console.log(data);
                setProjects(data.data);
                // set the fetched quotes in the state
                // setQuotes(data.data);
                //
                // setTotalPages(data.meta.pagination.pageCount);
                // setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                toast.error(
                    `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    }`
                );
                console.error(exception);
            });
    }, [authAxios, currentPage, filterQuery, resultsPerPage, setTotalPages, setTotalResults]);
    useEffect(() => {
        if (projects.length > 0) {
            const tableData = [];
            for (const project of projects) {
                console.log(project);
                tableData.push({
                    attributes: {
                        onClick: () => {
                            navigate(`/projects/${project.id}`);
                        },
                        key: project.id // use unique id over array index to properly update the DOM
                    },
                    data: [
                        project.number,
                        project.description
                    ]
                });
            }
            setTableData(tableData);
        }
    }, [projects])
    /**
     * Sets the rows of the table when the fetched quotes state changes
     */
    const setTableRows = useCallback(() => {
        // Set table data:
        const tableData = [];
        // loop through all the records
        for (const index in quotes) {
            const quote = quotes[index];
            tableData.push({
                attributes: {
                    onClick: () => {
                        navigate(`/quotes/${quote.id}${quote.status !== 'Concept' ? '' : '/edit'}`);
                    },
                    key: quote.id // use unique id over array index to properly update the DOM
                },
                data: [
                    quote.number,
                    quote?.company?.name ? (
                        <Link
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            to={`/crm/companies/${quote?.company?.id}`}
                        >
                            {quote?.company?.name}
                        </Link>
                    ) : (
                        'Geen bedrijf..'
                    ),
                    quote?.description,
                    <select
                        value={quote?.status ?? ''}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        onChange={(e) => {
                            updateStatus(e.target.value, quote.id, index);
                        }}
                    >
                        {quoteStatusOptions}
                    </select>,
                    <div className='table__action-buttons'>
                        <Link
                            data-title={quote.status.toLowerCase() !== 'concept' ? 'Bekijken' : 'Bewerken'}
                            onClick={(e) => e.stopPropagation()}
                            className='btn btn--transparent'
                            to={`/quotes/${quote.id}${quote.status.toLowerCase() !== 'concept' ? '' : '/edit'}`}
                            key='0'
                        >
                            <Icon name={quote.status.toLowerCase() !== 'concept' ? 'eye' : 'pencil'} />
                        </Link>
                        <Link
                            data-title={'Kopiëren'}
                            onClick={(e) => e.stopPropagation()}
                            className='btn btn--transparent'
                            to={`/quotes/${quote.id}/copy`}
                            key='1'
                        >
                            <Icon name='copy' />
                        </Link>
                    </div>
                ]
            });
        }
        // setTableData(tableData);
    }, [quotes, navigate, updateStatus]);
    // get the table data using the useEffect hook
    useEffect(() => {
        getTableData();
    }, [getTableData]);
    // set the table rows on quotes state update
    useEffect(() => {
        setTableRows();
    }, [setTableRows]);
    return (
        <div>
            {filtering}
            <Table
                headers={['Project nummer', 'Omschrijving', 'Status']}
                structure={{
                    1600: ['170px', '150px', '100%'],
                    1700: ['170px', '150px', '100%'],
                    default: ['170px', '250px', '100%']
                }}
                data={tableData}
                hover
                border='row'
                padding='m'
                paddingBreakpoint='1700'
                buttonCollapseBreakpoint='1600'
            />
            {paging}
        </div>
    );
}