import Popup, { closePopup } from 'UI/App/Components/Popup/Popup';
import TimeInput from "UI/App/Partials/Content/TimeAccounting/Blocks/TimeAccountingCreateEdit/TimeAccountingInput";

export default function PopupEditTimer({ timer, setUpdateBlocks }) {

	function handleClose() {
		setUpdateBlocks();
		closePopup();
	}
	return (
		<Popup title='Bewerk timer' popupID='edit-timer'>

			<TimeInput aquireOngoing={false} editTimerWithid={timer.id} onTimer={handleClose} />
		</Popup>
	);
}