import { useEffect, useState, useContext } from "react"
import Block from "UI/App/Components/Block/Block";
import Grid from "UI/App/Components/Grid/Grid";
import { formatDate } from "App/Util/format";
import './_planningHistory.scss';
import IF from "UI/App/Components/Conditional/IF";
import { AuthContext } from "App/Strapi/AuthProvider";
export default function PlanningHistory({
    planningRecords
}) {
    const authContext = useContext(AuthContext);
    const [sortedRecords, setSortedRecords] = useState([])
    useEffect(() => {
        if (planningRecords === undefined || planningRecords?.length === 0) return;
        let dayPlannings = planningRecords?.map((record) => {
            record.dayPlanning.map((planRecord) => planRecord.name = record.name)

            return record.dayPlanning
        })


        setSortedRecords(
            dayPlannings
                .flat()
                .sort((a, b) => new Date(b.noteUpdatedAt).getTime() - new Date(a.noteUpdatedAt).getTime())
                .filter(planning => planning.note)
        )
    }, []);

    return (
        <Block name='planning-history' className='planning-history' title='Order Opmerkingen'>
            <Grid columns={1} style={{
                maxHeight: '380px',
                overflowY: 'auto',
                paddingRight: '10px',
                minHeight: '20px'
            }}>

                {sortedRecords?.map((planning) => {
                    let userName = "onbekend";
                    if (planning?.noteUpdatedBy?.username !== undefined) {
                        userName = planning?.noteUpdatedBy?.username === authContext?.authState?.user?.username ? "IK" : planning?.noteUpdatedBy?.username
                    }

                    return <Grid key={planning.id} className='stock-history-item' columns={3} customColTemplate={'20px 1fr 15px'} gap={'10px'}>
                        <div className='stock-history-item__timeline'></div>
                        <div className='stock-history-item__content'>
                            <span>
                                <small className='stock-history-item__user'>{userName} </small>
                                <small className='stock-history-item__datetime'>- {formatDate(planning.noteUpdatedAt)}</small>
                            </span>
                            <span>
                                <small className='stock-history-item__name'>{planning.name} </small>
                            </span>
                            <span>{planning.note}</span>
                        </div>

                    </Grid>
                })}
                <IF condition={sortedRecords?.length === 0}>
                    <p style={{
                        textAlign: 'center',
                        color: 'gray'
                    }}>Geen order opmerkingen gevonden.</p>
                </IF>

            </Grid>
        </Block>
    )
}