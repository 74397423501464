import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FetchContext} from 'App/Strapi/FetchContext';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import {toast} from "react-toastify";
import Table from "UI/App/Components/Table/Table"
import {stringify} from 'qs';

export default function ViewJobLogging() {
    const { authAxios } = useContext(FetchContext);
    const params = useParams();

    // create a state for the table data
    const [tableData, setTableData] = useState([]);

    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded,
    } = usePagination({
        searchSettings: {
            enabled: true,
            strapiFields: ['name', 'value']
        },
    });

    useEffect(() => {
        const query = stringify(
            {
                pagination: {
                    page: currentPage,
                    pageSize: resultsPerPage
                }
            }
        )
        authAxios
            .get(`/workflow/job-tickets/${params.jobId}/logs?${query}&${filterQuery}`)
            .then(({ data }) => {

                const tableData = [];
                // loop through all logs
                for (const log of data.data) {

                    tableData.push({
                        data: [
                            new Date(log.createdAt).toLocaleDateString('en-GB').replaceAll('/', '-'),
                            log?.name ?? "-",
                            log?.value ?? "-",
                        ]
                    })

                }
                // set table data
                setTableData(tableData)

                // Set pagination
                const paginationData = data.meta.pagination;
                setTotalPages(paginationData.pageCount);
                setTotalResults(paginationData.total);
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''} `);
                console.error(exception);
            })
    }, [
        currentPage,
        resultsPerPage,
        authAxios,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded,
    ])

    return (<>
        {filtering}
        <Table
            structure={{
                450: ['50%', '30%', '20%'],
                650: ['50%', '30%', '20%'],
                850: ['50%', '30%', '20%'],
                1400: ['50%', '30%', '20%'],
                default: ['50%', '30%', '20%'],
            }}
            headers={['Aanmaakdatum', 'Naam', 'Waarde']}
            data={tableData}
            padding={'s'}
            columnOverflowExceptions={[4]}
        />
        {paging}
    </>)


}