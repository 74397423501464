import React, {ReactElement} from "react";
import type {LoadingData} from "select2";
import WidgetInformation, {getWidgetInformationDisplay, WidgetInformationDisplay} from "UI/App/Partials/Content/Calculations/Widgets/Widgets/WidgetInformation";
import IWidget from "./IWidget";
import WidgetBase, {getWidgetValues} from "./WidgetBase";
import WidgetConditionalLogic from "./WidgetConditionalLogic";
import {TrenderType} from "./WidgetTypes";

export default class InputWidget extends WidgetBase implements IWidget {
    widgetData: String | null;
    optionalWidgetData: object | undefined;
    debug: boolean;
    prefix: string | undefined;
    allowHidden:boolean;

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false, showVisibilityCheckbox: boolean = false,allowHidden:boolean = true) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;
        this.visibilityCheckbox = showVisibilityCheckbox;
        this.allowHidden = allowHidden;
    }

    callback(object: Object): any {
    }

    saveWidget(object: Object): any {
    }

    /**
     * Returns react element with badge html
     */
    getBadgeHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return <div>intbadge html:</div>
    }

    /**
     * returns form react element
     */
    getFormHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {

        return <form onSubmit={(e) => this.handleSubmit(e, this)}>
            <h3>Invoerveld</h3>
            <div className='input-group'>
                Naam: <input type="text" name="title"/>
            </div>
            <div className='input-group'>
                Type: <select id="inputTypeSelector" name="type" onChange={this.changeTypeSelector}>
                <option value="number">getal</option>
                <option value="text">text</option>
            </select>
            </div>
            <div className='input-group' id="inputSettings">
                <input type="number" name="min" placeholder="min"/>
                <input type="number" name="max" placeholder="max"/>
                <input type="number" name="step" placeholder="step" step={'0.0000001'} defaultValue={'0.0000001'}/>
            </div>
            <div className='input-group'>
                <WidgetInformation/>
            </div>
            <div className='input-group'>
                <WidgetConditionalLogic/>
            </div>
            <button type="submit">Opslaan</button>
        </form>
    }

    changeTypeSelector(): void {
        if (document.getElementById('inputTypeSelector').value === 'number') {
            document.getElementById('inputSettings').classList.remove('hidden')
        } else {
            document.getElementById('inputSettings').classList.add('hidden')
        }
    }

    /**
     * Handles the form submit adds data to saveWidget function
     * @param event
     * @private
     */
    handleSubmit(event: any, parentClass: any): void {
        event.preventDefault();

        const data = {
            type: "Input",
            title: event.target.elements.title.value,
            value: {
                information: event.target.elements?.information?.value,
                type: event.target.elements.type.value,
                min: event.target.elements?.min?.value,
                max: event.target.elements?.max?.value,
                step: event.target.elements?.step?.value,
                conditional: {
                    number: event.target.elements?.conditionalValue.value,
                    type: event.target.elements?.conditionalType.value,
                    target: event.target.elements?.conditionalLogicWidget.value
                }
            }
        };

        parentClass.addWidgetToWidgetContainer("Invoer veld: " + data?.title, data);
    }

    /**
     * Returns react element for preview
     */
    getPreviewHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        const widgetData = {
            widgetType: 'Input',
        }

        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>Invoerveld</div>
    }

    /**
     * Returns form for calculation
     */
    public getCalculationForm(): void {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;

        const widgetContent = `
            <span class="visibleInputCheckbox">
                Verbergen: <input type="checkbox" class="visibleInputCheckbox" ${this.optionalWidgetData?.data?.hidden ? 'checked' : ''}>
            </span>
            <label htmlFor="${id}">
            <span class="widgetId hidden">[${id}]</span>
                ${this.optionalWidgetData.data.title}
                ${getWidgetInformationDisplay(this.optionalWidgetData)}
                <div onchange="{this.chooseInputValue}" class="chooseInputValue">
                    <select class="valueSetterSelect"></select>
                </div>
            </label>
            <input class="inputWidgetInput" step="${this.optionalWidgetData.data.value.step}" type="${this.optionalWidgetData.data.value.type}" placeholder="${this.optionalWidgetData.data.title}" id="${id}" disabled="true"/>`;

        this.addWidgetToCalculationForm(`${widgetContent}`, this.optionalWidgetData, this.optionalWidgetData.parentId)
    }

    /**
     * Returns html for edit
     */
    getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer("Invoer veld: " + this.optionalWidgetData?.data?.title, this.optionalWidgetData, undefined, true);
    }

    /**
     * Returns formula string
     */
    getFormulaString(): String {

        return "";
    }

    /**
     * Returns html for preview
     */
    getPreviewFormHTML(): ReactElement {
        return <div>
            <label htmlFor={this.optionalWidgetData.id}>{this.optionalWidgetData.title} <WidgetInformationDisplay widgetData={this.optionalWidgetData}/></label>
            <input type={this.optionalWidgetData.value.type} id={this.optionalWidgetData.id}/>
        </div>
    }

    /**
     * returns form react element
     */
    getEditWidgetForm(editData: { id: number, title: string, value: any }): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return <form onSubmit={this.handleEdit}>
            <input type="hidden" name={"id"} value={editData.id}/>
            <div className='input-group'>
                Naam: <input type="text" name="title" defaultValue={editData.title}/>
            </div>
            <div className='input-group'>
                Type: <select id="inputTypeSelector" defaultValue={editData.value.type} name="type" onChange={this.changeTypeSelector}>
                <option value="text">text</option>
                <option value="number">getal</option>
            </select>
            </div>
            <div className='input-group hidden' id="inputSettings">
                <input type="number" name="min" placeholder="min"/>
                <input type="number" name="max" placeholder="max"/>
                <input type="number" name="step" placeholder="step" defaultValue={1}/>
            </div>
            <div className='input-group'>
                <WidgetInformation content={editData.value?.information}/>
            </div>
            <div className='input-group'>
                <WidgetConditionalLogic/>
            </div>
            <button type="submit">Opslaan</button>
        </form>
    }

    /**
     * Handles the form submit adds data to saveWidget function
     * @param event
     * @private
     */
    handleEdit(event: any): void {
        event.preventDefault();

        const data = {
            type: "Input",
            title: event.target.elements.title.value,
            value: {
                type: event.target.elements.type.value,
                min: event.target.elements?.min?.value,
                max: event.target.elements?.max?.value,
                step: event.target.elements?.step?.value,
                conditional: {
                    number: event.target.elements?.conditionalValue.value,
                    type: event.target.elements?.conditionalType.value,
                    target: event.target.elements?.conditionalLogicWidget.value
                },
                information: event.target.elements?.information?.value
            }
        };

        (new WidgetBase()).updateWidget(event.target.elements.id.value, "Invoer veld: " + data?.title, data);
    }

    async provideData(selectId: string): Promise<[] | boolean> {
        // console.log(this.optionalWidgetData.data);
        // return Promise.resolve(options)
    }

    /**
     * Calculation logic
     */
    calculationFunctionality(): void {
        const widgetElement = this.getWidgetElement();
        if (!widgetElement) return;
        const widgetData = JSON.parse(widgetElement.getAttribute("widgetData"))

        // Hide aantal/lengte/hoogte
        if (widgetData.data.title.toLowerCase() === "aantal" || widgetData.data.title.toLowerCase() === "lengte product" || widgetData.data.title.toLowerCase() === "breedte product") {
            widgetElement.classList.add('hidden');

            if (!this.debug) {
                return;
            }
        }

        const inputWidgetInput = widgetElement.querySelector<HTMLInputElement>(".inputWidgetInput");

        /**
         * If default value set value
         */
        if (widgetData.data.defaultValue !== undefined) {
            if (inputWidgetInput && inputWidgetInput.value === '') {
                inputWidgetInput.value = widgetData.data.defaultValue;
            }
        }

        /**
         * If selected value is set get value and set
         */
        if (inputWidgetInput && widgetData.data.selectedValue !== undefined) {
            inputWidgetInput.value = this.getResultFromWidget(widgetData.data.selectedValue)
            if (!this.debug) {
                widgetElement.classList.add('hidden');
                return;
            }
        }

        /**
         * If has no conditional logic remove hidden and remove readonly
         */
        if (this.optionalWidgetData?.data.value.conditional.target === undefined || this.optionalWidgetData?.data.value.conditional.target === '') {

            /**
             * Set visible and usable
             */
            widgetElement.classList.remove('hidden');
            inputWidgetInput?.removeAttribute('readonly');
            inputWidgetInput?.removeAttribute('disabled');
        } else {
            /**
             * If condition is right set usable else disable and hide
             */
            if (this.handleConditional(this.optionalWidgetData.data.value.conditional)) {
                widgetElement.classList.remove('hidden');
                inputWidgetInput?.removeAttribute('readonly');
                inputWidgetInput?.removeAttribute('disabled');
            } else {
                if (!widgetElement.classList.contains('hidden')) {
                    widgetElement.classList.add('hidden');
                    inputWidgetInput?.setAttribute('readonly', "");
                    inputWidgetInput?.setAttribute('disabled', "");
                }
            }
        }

        // Handle visibility checkbox
        if (this.visibilityCheckbox) {
            widgetElement?.querySelector('.visibleInputCheckbox')?.classList.remove('hidden')
        } else {
            widgetElement?.querySelector('.visibleInputCheckbox')?.classList.add('hidden')
        }

        const label = widgetElement.querySelector('label');
        const chooseInputValue = label?.querySelector('.chooseInputValue');
        const valueSetterSelect = chooseInputValue?.querySelector<HTMLSelectElement>('.valueSetterSelect');

        // If debug handle debug
        if (this.debug) {

            if (chooseInputValue !== null) {
                chooseInputValue?.classList.remove('hidden')
                label?.querySelector('.widgetId')?.classList.remove('hidden')

                /**
                 * If not selected no options exist add options if selected handle change
                 */
                if (valueSetterSelect?.value === "") {
                    const types = this.getWidgetValues();
                    let options = "";
                    let oldTab = '';
                    options += `<option value="" selected>Kies waarde</option>`;
                    options += `<option value="0">Leeg (0)</option>`;
                    options += `<option value="1">Leeg (1)</option>`;

                    for (const type of types) {
                        for (const value of type.options) {
                            // Continue if blank label
                            if (value.label === 'label') continue

                            const tabNumber = value.label.match(/\[(\d+-\d+).*]/)?.[1];
                            const tabHandleId = tabNumber !== undefined ? `tablink_${tabNumber}` : undefined;
                            const calculationTabHandleId = tabNumber !== undefined ? `calculationTablink_${tabNumber}` : undefined;
                            const tabHandleName = tabHandleId !== undefined ? document.getElementById(tabHandleId)?.innerText : undefined;
                            const calculationTabHandleName = calculationTabHandleId !== undefined ? document.getElementById(calculationTabHandleId)?.innerText : undefined;
                            const tabCustomName = tabNumber !== undefined ? document.getElementById(tabNumber)?.querySelector('input.machineTitle')?.value : undefined;

                            if (oldTab === '' && tabHandleId !== undefined) {
                                options += `<optgroup label="${(tabCustomName ?? tabHandleName ?? calculationTabHandleName)}">`;
                                oldTab = tabHandleId;
                            }

                            if (tabHandleId !== oldTab && tabHandleId !== undefined) {
                                options += `</optgroup><optgroup label="${(tabCustomName ?? tabHandleName ?? calculationTabHandleName)}">`;
                                oldTab = tabHandleId;
                            }

                            let selected = '';

                            if (widgetData.data.selectedValue !== undefined) {
                                if (widgetData.data.selectedValue === value.value) {
                                    selected = 'selected'
                                }
                            }
                            options += `<option value="${value.value}" ${selected}>${(tabCustomName ?? tabHandleName ?? calculationTabHandleName)} ${value.label}</option>`
                        }
                    }

                    if (valueSetterSelect) {
                        valueSetterSelect.innerHTML = ""
                        valueSetterSelect.insertAdjacentHTML("afterbegin", options);
                    }
                } else {
                    const selectedIndex = valueSetterSelect?.options.selectedIndex;
                    const selectedValue = valueSetterSelect?.options[selectedIndex].value

                    if (inputWidgetInput && selectedValue !== "") {
                        inputWidgetInput.value = String(this.getResultFromWidget(selectedValue))
                        widgetData.data.selectedValue = selectedValue;

                    }
                }
            }

        } else {
            chooseInputValue?.classList.add('hidden')
            widgetElement.querySelector('label')?.querySelector('.widgetId')?.classList.add('hidden')
        }

        if (this.debug) {
            chooseInputValue?.classList.remove('hidden')
        } else {
            chooseInputValue?.classList.add('hidden')
        }

        // If not debug handle visibility
        if(this.allowHidden){
            if (!this.debug || !this.visibilityCheckbox) {
                // Check if input has value set else skip
                if (inputWidgetInput?.value !== '' || inputWidgetInput?.value !== null) {
                    if (widgetData.data.hidden !== undefined) {
                        if (widgetData.data.hidden) {
                            widgetElement?.classList.add('hidden')
                        }
                    }
                }
            }
        }

        /**
         * Update changed data
         */
        widgetData.data.defaultValue = inputWidgetInput?.value;
        widgetElement.setAttribute('widgetdata', JSON.stringify(widgetData));
    }
}

export function getWidgetValuesFromTemplate(selected: string | null = null) {
    const types = getWidgetValues();
    let options = [];
    let oldTab = '';
    options.push({
        value: '',
        label: 'Kies waarde'
    });

    const subOptions: {
        label: string,
        options: Array<any>
    } = {
        label: '',
        options: []
    };

    for (const type of types) {
        for (const value of type?.options ?? []) {
            const tabNumber = value.label.match(/\[(\d+-\d+).*]/)?.[1];
            const tabHandleId = tabNumber !== undefined ? `tablink_${tabNumber}` : undefined;
            const tabHandleName = tabHandleId !== undefined ? document.getElementById(tabHandleId)?.innerText : undefined;
            const tabCustomName = tabNumber !== undefined ? document.getElementById(tabNumber)?.querySelector('input.machineTitle')?.value : undefined;

            if (oldTab === '' && tabHandleId !== undefined) {
                subOptions.label = (tabCustomName ?? tabHandleName);
                oldTab = tabHandleId;
            }

            if (tabHandleId !== oldTab && tabHandleId !== undefined) {
                options.push({...subOptions});
                subOptions.label = (tabCustomName ?? tabHandleName)
                subOptions.options = [];
                oldTab = tabHandleId;
            }

            subOptions.options.push({
                value: value.value,
                label: value.label,
                selected: selected === value.value
            });
        }
    }

    return options;
}
