import {FetchContext} from "App/Strapi/FetchContext";
import {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form"
import {toast} from "react-toastify";
import Block from "UI/App/Components/Block/Block";
import spinner from "UI/Assets/Spinners/spinner-primary.svg";

export default function Config() {
	// get auth axios for fetching the configuration
	const {authAxios} = useContext(FetchContext);

	// create a state for the default company values
	const [formValues, setFormValues] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);

	// register the form hook
	const {register, handleSubmit, formState: {errors}} = useForm();
	const onSubmit = data => updateConfig(data)

	// get the company data
	useEffect(() => {
		authAxios.get('/config/crm')
			.then(({data}) => {
				const attributes = data.data;
				// set the form values
				setFormValues({
					companyTypes: attributes?.companyTypes,
					companyBranches: attributes?.companyBranches,
					// contactPersonTags: attributes?.contactPersonTags
				});
				// mark the form values as loaded
				setIsLoaded(true);
			}).catch((exception) => {
				toast.error(`CRM Configuratie kon niet worden opgehaald (${exception?.response?.status})`);

				console.error(exception);
			});
	}, [authAxios]);

	// resize textarea based on content
	const resizeOnInput = ({target}) => {
		target.style.height = 'inherit';
		target.style.height = `${target.scrollHeight + 2}px`;
	}

	const updateConfig = (data) => {
		authAxios.put('/config/crm', {
			data: data
		}).then(() => {
			toast.success('Bijgewerkt!');
		}).catch((exception) => {
			toast.error(`CRM Configuratie kon niet worden bijgewerkt (${exception?.response?.status})`);

			console.error(exception);
		})
	}

	return (
		<Block name='config__crm' title='CRM Configuratie'>
			{!isLoaded && <img className="spinner" src={spinner} alt='spinner' width="50" height="50" />}
			{isLoaded && <form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="input-group">
					<label className="required">Bedrijfs types</label>
					{errors.companyTypes?.type === 'required' && <span className="form__error" role="alert">Dit veld is verplicht!</span>}
					{errors.companyTypes?.type === 'pattern' && <span className="form__error" role="alert">Vul op elke regel 1 type in.</span>}
					<textarea onInput={resizeOnInput} className="textarea" {...register("companyTypes", {required: true, pattern: /^(?:[\w -]+\n)*[\w -]+$/i, value: formValues.companyTypes})} />
				</div>
				<div className="input-group">
					<label className="required">Bedrijfs branches</label>
					{errors.companyBranches?.type === 'required' && <span className="form__error" role="alert">Dit veld is verplicht!</span>}
					{errors.companyBranches?.type === 'pattern' && <span className="form__error" role="alert">Vul op elke regel 1 branche in.</span>}
					<textarea onInput={resizeOnInput} className="textarea" {...register("companyBranches", {required: true, pattern: /^(?:[\w -]+\n)*[\w -]+$/i, value: formValues.companyBranches})} />
				</div>
				{/* <div className="input-group">
					<label className="required">Contactpersoon tags</label>
					{errors.contactPersonTags?.type === 'required' && <span className="form__error" role="alert">Dit veld is verplicht!</span>}
					{errors.contactPersonTags?.type === 'pattern' && <span className="form__error" role="alert">Vul op elke regel 1 tag in.</span>}
					<textarea onInput={resizeOnInput} className="textarea" {...register("contactPersonTags", { required: true, pattern: /^(?:[\w -]+\n)*[\w -]+$/i, value: formValues.contactPersonTags })} />
				</div> */}
				<button type="submit" value='Opslaan'>Opslaan</button>
			</form>}
		</Block>
	);
}
