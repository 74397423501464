import {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Select from 'react-select';
import {toast} from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import Block from 'UI/App/Components/Block/Block';
import Button from 'UI/App/Components/Button/Button';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import OrderPackingSlipPdfEditor from './OrderPackingSlipPdfEditor';
import getSlipPdf from './orderPackingSlipPDFGenerator';
import Order_PRETORE from 'UI/Assets/Images/PdfBackgrounds/Order_PRETORE.jpg';
import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";
import Briefpapier_HJMG from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg';
import Briefpapier_HJMG_2 from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG_2.jpg';
import Container from 'UI/App/Components/Container/Container';
import PageHeader from 'UI/App/Components/PageHeader/PageHeader';
import CompanyBlock from 'UI/App/Partials/Content/CRM/Partials/CompanyBlock';
import Table from 'UI/App/Components/Table/Table';
import {SpinnerCenter} from 'UI/App/Components/Spinner';
import useWindowDimensions from 'App/Util/useWindowDimensions';
import IF from 'UI/App/Components/Conditional/IF';
import {openPopup} from 'UI/App/Components/Popup/Popup';
import {getSelectedValue} from 'App/Util/select2';
import {useFileUpload} from 'UI/App/Components/Form/FileUpload/FileUpload';
import {downloadFile} from "App/Util/fetch";

export default function CreateOrderPackingSlip() {
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();
    const params = useParams();
    const [calculation, setCalculation] = useState(null);

    /* STATES */
    // set pdf background options (options need `value` key to fix broken options colors)
    const [backgroundSelectOptions] = useState([
        {
            label: 'Geen',
            value: '',
            value2: '',
            template: 1
        },
        {
            label: 'Achtergrond briefpapier Pretore',
            value: Briefpapier_PRETORE,
            value2: Briefpapier_PRETORE_2,
            template: 2
        },
        {
            label: 'Achtergrond briefpapier HJMG',
            value: Briefpapier_HJMG,
            value2: Briefpapier_HJMG_2,
            template: 2
        }
    ]);
    const [languageSelectOptions] = useState([
        {
            label: 'NL',
            value: 'nl'
        },
        {
            label: 'EN',
            value: 'en'
        }
    ]);

    const [pdfData, setPdfData] = useState({});
    const [pdfTemplate, setPdfTemplate] = useState({
        backgroundImage: backgroundSelectOptions[0].value,
        backgroundImage2: backgroundSelectOptions[0].value2,
        template: backgroundSelectOptions[0].template,
        language: languageSelectOptions[0].value
    });

    const [order, setOrder] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [savedPackingSlips, setSavedPackingSlips] = useState([]);
    const [company, setCompany] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [canSaveOrView, setCanSaveOrView] = useState(false);

    const [blockTitle, setBlockTitle] = useState('Pakbon');
    const [pdfZoomLevel, setPdfZoomLevel] = useState(1);
    const {width} = useWindowDimensions();

    const {FileUpload, attachment, setAttachment} = useFileUpload(
        'image/*',
        'Getekende pakbon uploaden',
        'camera',
        'Getekende pakbon'
    );

    /* METHODS */
    /**
     * Get the order for creating a new packing-slip.
     *
     * @return {void}
     */
    const getOrder = useCallback(
        async (orderId) => {
            // disable saving or viewing during loading
            setCanSaveOrView(false);

            const query = stringify({
                populate: {
                    quoteConfirmation: {
                        fields: ['id', 'number']
                    },
                    packingSlips: {
                        fields: ['id', 'number'],
                        sort: ['id:desc']
                    },
                    calculation: {
                        fields: ['id', 'number', 'customerReference', 'data', 'owner']
                    },
                    quote: {
                        fields: ['id', 'number']
                    },
                    company: {
                        populate: ['address', 'telephone', 'managingCompany']
                    },
                    contactPerson: {
                        fields: ['id', 'firstName', 'prefixToSurname', 'surname']
                    },
                    project: {
                        fields: ['id', 'number']
                    }
                }
            });

            // get order data
            authAxios
                .get(`/orders/${orderId}?${query}`)
                .then(({data}) => {
                    // get managingCompany to auto-select the proper background
                    // pretore has prio over hjmg if they have both
                    const managingCompany = data.data?.company?.managingCompany?.Pretore
                        ? 'pretore'
                        : data.data?.company?.managingCompany?.HJMG
                            ? 'hjmg'
                            : null;

                    setCalculation(data)

                    // set company
                    setCompany(data.data?.company ?? null);

                    // set contactPerson
                    setContactPerson(data.data?.contactPerson ?? null);

                    // delete data.data?.company;
                    delete data.data?.contactPerson;

                    // set order
                    setOrder(data.data);

                    // set default pdf data
                    setPdfData({
                        locationInput: 'Dordrecht',
                        dateInput: new Date().toLocaleDateString('fr-ca')
                    });

                    // set language, template, and background image
                    let selectedTemplate = backgroundSelectOptions[0];
                    if (managingCompany === 'pretore') {
                        selectedTemplate = backgroundSelectOptions[1];
                    } else if (managingCompany === 'hjmg') {
                        selectedTemplate = backgroundSelectOptions[2];
                    }
                    setPdfTemplate({
                        backgroundImage: selectedTemplate.value,
                        backgroundImage2: selectedTemplate.value2,
                        template: selectedTemplate.template,
                        language: (data.data?.calculation?.language ?? 'nl').toLocaleLowerCase()
                    });

                    // update page header title
                    setBlockTitle(`Nieuwe pakbon voor order ${data.data.number}`);

                    // enable saving or viewing after loading
                    setCanSaveOrView(true);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Er ging iets mis met het ophalen van de order.');
                    setIsLoading(false);
                });
        },
        [authAxios, backgroundSelectOptions]
    );

    /**
     * Get a saved packing-slip for editing.
     *
     * @return {void}
     */
    const getSavedSlip = useCallback(
        async (slipId) => {
            // disable saving or viewing during loading
            setCanSaveOrView(false);

            const query = stringify({
                fields: ['id', 'number', 'pdfDataV2'],
                populate: {
                    order: {
                        populate: {
                            quoteConfirmation: {
                                fields: ['id', 'number']
                            },
                            packingSlips: {
                                fields: ['id', 'number'],
                                sort: ['id:desc']
                            },
                            calculation: {
                                fields: ['id', 'number', 'customerReference', 'data']
                            },
                            quote: {
                                fields: ['id', 'number']
                            },
                            company: {
                                populate: ['address', 'telephone', 'managingCompany']
                            },
                            contactPerson: {
                                fields: ['id', 'firstName', 'prefixToSurname', 'surname']
                            },
                            project: {
                                fields: ['id', 'number']
                            }
                        }
                    },
                    attachment: true
                }
            });

            // get saved order packing slip
            authAxios
                .get(`/orders/packing-slips/${slipId}?${query}`)
                .then(({data}) => {

                    // update page header title
                    setBlockTitle(
                        `[${data.data?.number}] ${data.data.order?.company?.name} - ${data.data.order.description}`
                    );

                    /* setting these states will not trigger the pdf useEffects due to `isEdit` prop on the component */
                    // set company
                    setCompany(data.data.order?.company ?? null);

                    // set contactPerson
                    setContactPerson(data.data.order?.contactPerson ?? null);

                    // delete them from order
                    delete data.data.order?.company;
                    delete data.data.order?.contactPerson;

                    // set order
                    setOrder(data.data.order);
                    /* */

                    const savedPdfData = data.data?.pdfDataV2 ?? {};

                    // set language, template, and background image (+ backwards compatibility)
                    const selectedTemplate = getSelectedValue(
                        savedPdfData?.backgroundImage ?? savedPdfData?.pdfBackgroundImage,
                        backgroundSelectOptions
                    );

                    if (selectedTemplate !== null) {
                        // setting this state will trigger the pdf useEffect for it, which is intended.
                        setPdfTemplate({
                            backgroundImage: selectedTemplate.value,
                            backgroundImage2: selectedTemplate.value2,
                            template: selectedTemplate.template,
                            language: (savedPdfData?.language ?? 'nl').toLocaleLowerCase()
                        });
                    }

                    // set pdf data to saved data
                    setPdfData({
                        ...savedPdfData,
                        slipNumber: data.data.number,
                        // backwards compatibility:
                        address: {
                            ...(savedPdfData?.address ?? {}),
                            addressLine1: savedPdfData?.address?.addressLine1 ?? '',
                            // set optional address lines to `null` if are empty here
                            addressLine2:
                                (savedPdfData?.address?.addressLine2 ?? '') === ''
                                    ? null
                                    : savedPdfData?.address?.addressLine2,
                            addressLine3:
                                (savedPdfData?.address?.addressLine3 ?? '') === ''
                                    ? null
                                    : savedPdfData?.address?.addressLine3
                        },
                        locationInput: savedPdfData?.locationInput ?? 'Dordrecht',
                        projectNumber:
                            savedPdfData?.projectNumber ??
                            data.data?.order?.project?.number ??
                            data.data?.order?.number ??
                            ''
                    });

                    if (data.data?.attachment) {
                        downloadFile(authAxios, data.data?.attachment?.id, data.data?.attachment?.mime).then(async (blobURL) => {
                            const blob = await fetch(blobURL).then(res => res.blob())

                            setAttachment({
                                name: data.data?.attachment?.name,
                                url: blobURL,
                                file: new File([blob], data.data?.attachment?.name, {type: blob.type})
                            })
                        })
                    }

                    // enable saving or viewing after loading
                    setCanSaveOrView(true);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Er ging iets mis met het ophalen van de order.');
                    setIsLoading(false);
                });
        },
        [authAxios, backgroundSelectOptions]
    );

    /**
     * Save the packing-slip to Strapi.
     *
     * @return {void}
     */
    const saveSlip = useCallback(async () => {
        if (!canSaveOrView) return;

        // disable button
        setCanSaveOrView(false);

        let pdfBase64 = null;
        try {
            pdfBase64 = getSlipPdf(pdfData, true);
        } catch (error) {
            console.error(error);
            toast.error(`Er ging iets mis met het opslaan van de pakbon`);

            // re-enable button after
            setCanSaveOrView(true);

            return;
        }

        const formData = new FormData();

        if (attachment?.file) {
            formData.append('files.attachment', attachment.file, attachment.file.name);
        }

        if (params?.slipId) {
            formData.append(
                'data',
                JSON.stringify({
                    pdfDataV2: pdfData,
                    pdfBase64: pdfBase64
                })
            );

            await authAxios
                .put(`/orders/packing-slips/${params?.slipId}`, formData)
                .then(() => {
                    toast.success(`Order pakbon bijgewerkt!`);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`Er ging iets mis met het opslaan van de pakbon`);
                });
        } else {
            formData.append(
                'data',
                JSON.stringify({
                    order: order?.id,
                    pdfDataV2: pdfData
                })
            );

            // create new slip entry
            await authAxios
                .post(`/orders/packing-slips`, formData)
                .then(async ({data}) => {
                    const pdfBase64 = getSlipPdf({...pdfData, slipNumber: data.data.number}, true);

                    await authAxios
                        .put(`/orders/packing-slips/${data.data.id}`, {
                            data: {
                                pdfBase64: pdfBase64
                            }
                        })
                        .then(() => {
                            toast.success(`Order pakbon opgeslagen!`);
                            navigate(`/orders/${order?.id}/packing-slips/${data.data.id}`);
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error(`Er ging iets mis met het opslaan van de pakbon`);
                        });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`Er ging iets mis met het opslaan van de pakbon`);
                });
        }

        // re-enable button after
        setCanSaveOrView(true);
    }, [attachment.file, authAxios, canSaveOrView, navigate, order?.id, params?.slipId, pdfData]);

    /* EFFECTS */
    // on page load determine what to do
    useEffect(() => {
        // first check the slipId in the url since orderId is also present in the url.
        if (params?.slipId) {
            getSavedSlip(params.slipId);
        } else if (params?.orderId) {
            getOrder(params.orderId);
        }
    }, [getOrder, getSavedSlip, params?.orderId, params?.slipId]);

    // set pdf zoom
    useEffect(() => {
        setPdfZoomLevel(() => {
            if (width > 1600) return 1;
            else if (width > 1500) return 0.9;
            else if (width > 1400) return 0.8;
            else if (width > 1300) return 0.7;
            else if (width > 1200) return 0.6;
            else if (width > 1100) return 1;
            else return width / 1000;
        });
    }, [width]);

    // set documents
    useEffect(() => {
        if (!order) return;

        // set documents
        const documentsArray = [];
        if (typeof order?.calculation?.number === 'string') {
            documentsArray.push([
                <strong>Calculatie</strong>,
                order.calculation.number,
                <Link to={`/calculations/${order.calculation?.id}/view`}>
                    <Icon name='eye'/>
                </Link>
            ]);
        }
        if (typeof order?.quote?.number === 'string') {
            documentsArray.push([
                <strong>Offerte</strong>,
                order.quote.number,
                <Link to={`/quotes/${order.quote?.id}`}>
                    <Icon name='eye'/>
                </Link>
            ]);
        }
        if (typeof order?.quoteConfirmation?.number === 'string') {
            documentsArray.push([
                <strong>Bevestiging</strong>,
                order.quoteConfirmation.number,
                <Link to={`/orders/confirmation/${order.quoteConfirmation?.id}`}>
                    <Icon name='eye'/>
                </Link>
            ]);
        }
        if (typeof order?.number === 'string') {
            documentsArray.push([
                <strong>Order</strong>,
                order.number,
                <Link to={`/orders/${order?.id}`}>
                    <Icon name='eye'/>
                </Link>
            ]);
        }
        setDocuments(documentsArray);

        // set saved packing slips on this order
        const packingSlipsArray = [];
        if (Array.isArray(order?.packingSlips)) {
            for (const slip of order.packingSlips) {
                const isCurrentSlip = slip?.id === parseInt(params?.slipId);

                packingSlipsArray.push({
                    attributes: {
                        style: {
                            backgroundColor: isCurrentSlip ? 'var(--primary-light)' : undefined,
                            fontWeight: isCurrentSlip ? 'var(--bold)' : undefined
                        }
                    },
                    data: [
                        slip?.number,
                        !isCurrentSlip ? (
                            <Link to={`/orders/${order?.id}/packing-slips/${slip?.id}`}>
                                <Icon name='eye'/>
                            </Link>
                        ) : null
                    ]
                });
            }
        }
        setSavedPackingSlips(packingSlipsArray);
    }, [order, params?.slipId]);

    if (isLoading) {
        return <SpinnerCenter/>;
    }

    return (
        <Container>
            <PageHeader title={blockTitle} style={{marginBottom: '1lh'}}>
                <Button
                    className='btn btn--round btn--black'
                    disabled={!canSaveOrView}
                    onClick={() => {
                        getSlipPdf(pdfData);
                    }}
                >
                    <Icon name='eye'/>
                </Button>
                <Button
                    className='btn btn--round btn--black'
                    onClick={() => {
                        saveSlip();
                    }}
                    disabled={!canSaveOrView}
                >
                    <Icon name='save'/>
                </Button>
            </PageHeader>

            <Grid
                customColTemplate={{
                    default: 'minmax(400px,33%) 66%',
                    1200: '100%'
                }}
            >
                <Grid columns='1' gap='1lh'>
                    <CompanyBlock calculation={calculation} company={company} contactPerson={contactPerson}/>

                    <Block title='Documenten' name='documents' contentStyle={{padding: 0}}>
                        <Table structure={['100%', '100%', '50px']} data={documents}/>
                    </Block>
                    <Block
                        title='Pakbonnen'
                        name='packing-slip'
                        contentStyle={{padding: 0}}
                        headerRightSideChildren={
                            params.slipId && (
                                <Link
                                    to={`/orders/${order?.id}/packing-slips/create`}
                                    className='btn btn--round'
                                    title='Nieuw pakbon aanmaken'
                                >
                                    <Icon name='plus'/>
                                </Link>
                            )
                        }
                    >
                        <Table structure={['100%', '50px']} data={savedPackingSlips}/>
                    </Block>

                    <Block name='settings' title='Settings'>
                        <div className='input-group'>
                            <label htmlFor='pdf-background-select'>Kies een achtergrond afbeelding:</label>
                            <Select
                                id='pdf-background-select'
                                name='pdf-background-select'
                                options={backgroundSelectOptions}
                                minMenuHeight={300}
                                defaultValue={backgroundSelectOptions[0]}
                                value={getSelectedValue(pdfTemplate.backgroundImage, backgroundSelectOptions)}
                                onChange={({value, value2, template}) => {
                                    setPdfTemplate((currentPdfTemplate) => ({
                                        ...currentPdfTemplate,
                                        backgroundImage: value,
                                        backgroundImage2: value2,
                                        template
                                    }));
                                }}
                                formatOptionLabel={({label, value}) => (
                                    <>
                                        {!isEmpty(value) ? (
                                            <div
                                                style={{
                                                    backgroundImage: `url(${value})`,
                                                    height: '150px',
                                                    width: '100%',
                                                    backgroundSize: 'cover',
                                                    border: '1px dashed black'
                                                }}
                                            />
                                        ) : (
                                            label
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className='input-group'>
                            <label htmlFor='pdf-language-select'>Kies een taal:</label>
                            <Select
                                id='pdf-language-select'
                                name='pdf-language-select'
                                options={languageSelectOptions}
                                defaultValue={languageSelectOptions[0]}
                                value={getSelectedValue(pdfTemplate.language, languageSelectOptions)}
                                onChange={({value}) => {
                                    setPdfTemplate((currentPdfTemplate) => ({
                                        ...currentPdfTemplate,
                                        language: value.toLocaleLowerCase()
                                    }));
                                }}
                            />
                        </div>
                    </Block>

                    <Block
                        title='Getekende pakbon'
                        name='bijlage'
                        headerRightSideChildren={
                            <IF condition={attachment?.url || attachment?.file}>
                                <button
                                    className='btn btn--round'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openPopup('bijlage-preview');
                                    }}
                                    data-title='Pakbon bekijken'
                                >
                                    <Icon name='eye'/>
                                </button>
                            </IF>
                        }
                    >
                        <FileUpload/>
                    </Block>
                </Grid>

                <div style={{zoom: pdfZoomLevel}}>
                    <OrderPackingSlipPdfEditor
                        pdfData={pdfData}
                        setPdfData={setPdfData}
                        template={pdfTemplate}
                        order={order}
                        company={company}
                        contactPerson={contactPerson}
                        isEdit={params?.slipId}
                    />
                </div>
            </Grid>
        </Container>
    );
}
