import {formatCurrency} from "App/Util/format";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import IF from "UI/App/Components/Conditional/IF";
import Select from "react-select";
import {getSelectedValue} from "App/Util/select2";
import {calculateTotalEntryCost} from "UI/App/Partials/Content/Calculations/HJMG/steps/util";

export default function Activity({
    activity,
    activityName,
    index,
    entry,
    updateEntry,
    removeEntry,
    options
}) {

    function confirmRemoveEntry() {
        if (window.confirm('Weet je zeker dat je deze activiteit wilt verwijderen?')) {
            removeEntry(activityName, index);
        }
    }

    function updateThisEntry(key, value) {
        if (key === 'time' && /\d+:\d+/.test(value)) {
            let [hours, minutes] = value.split(':');
            if (Number(minutes) > 59) {
                hours = Number(hours) + Math.floor(minutes / 60);
                minutes = minutes % 60;
                value = `${hours}:${String(minutes).padStart(2, '0')}`;
            }
        }

        updateEntry(activityName, index, calculateTotalEntryCost({
            ...entry,
            [key]: value
        }, activity));
    }

    function getColumnCount() {
        if (!entry?.work && activity?.isRecurring) {
            return 2;
        }

        if (activity?.isRecurring) {
            if (entry?.work?.isChargedHourly === false) {
                return 3;
            }
            return 6;
        } else {
            return 4;
        }
    }

    return (
        <div className={'hjmg__calculation__activity'}>
            <button className={'hjmg__calculation__remove-activity btn btn--transparent btn--round'} onClick={confirmRemoveEntry}><Icon name={'times'} /></button>

            <label>
                Omschrijving:
                <input type={'text'} placeholder={"Omschrijving..."} value={entry?.description} onChange={e => updateThisEntry('description', e.target.value)} />
            </label>

            <Grid columns={getColumnCount()}>
                <label>
                    Soort werk:
                    <IF condition={options}>
                        <Select options={options} value={getSelectedValue(entry?.work?.value, options, 'value')} onChange={option => updateThisEntry('work', option)} />
                    </IF>
                    <IF condition={!options}>
                        <input
                            type={'text'}
                            value={activityName}
                            disabled
                        />
                    </IF>
                </label>

                <IF condition={entry?.work?.isCustom || (entry?.work?.isChargedHourly ?? !activity?.isRecurring ?? true)}>
                    <label>
                        Uurloon:
                        <input
                            type={entry?.work?.isCustom ? 'number' : 'text'}
                            step={0.01}
                            placeholder={"Uurloon..."}
                            disabled={(!entry?.work?.isCustom)}
                            value={entry?.work?.isCustom ? (entry?.customHourlyRate ?? entry?.work?.cost) : formatCurrency(entry?.work?.cost ?? entry?.hourlyRate)}
                            onChange={e => updateThisEntry(entry?.work?.isCustom ? 'customHourlyRate' : 'hourlyRate', e.target.value)}
                        />
                    </label>

                    <label>
                        Uren:
                        <input
                            type={'text'}
                            placeholder={"Uren..."}
                            value={entry?.time}
                            onChange={e => updateThisEntry('time', e.target.value)}
                        />
                    </label>

                    <IF condition={activity?.isRecurring}>
                        <label>
                            Uren beschikbaarheid:
                            <select value={entry?.recurringTaskPlanning ?? ''} onChange={(e) => updateThisEntry('recurringTaskPlanning', e.target.value)}>
                                <option disabled value={''}>Kies...</option>
                                <option value={'weekly'}>{entry?.time} uur per week</option>
                                <option value={'monthly'}>{entry?.time} uur per maand</option>
                                <option value={'yearly'}>{entry?.time} uur per jaar</option>
                            </select>
                        </label>
                    </IF>
                </IF>

                <IF condition={activity?.isRecurring && entry?.work}>
                    <label>
                        Facturatie:
                        <select value={entry?.billingPeriod ?? ''} onChange={(e) => updateThisEntry('billingPeriod', e.target.value)}>
                            <option value={''} disabled>Kies...</option>
                            <option value={'monthly'}>Per maand</option>
                            <option value={'yearly'}>Per jaar</option>
                        </select>
                    </label>
                </IF>

                <label>
                    {(activity?.isRecurring && entry?.billingPeriod) ? `Totaal per ${entry?.billingPeriod === 'monthly' ? 'maand' : 'jaar'}:` : "Totaal:"}
                    <input type={'text'} placeholder={"0,00"} disabled value={formatCurrency(entry?.total ?? 0)} />
                </label>
            </Grid>

            {/*<pre>*/}
            {/*    {JSON.stringify(entry, null, 2)}*/}
            {/*</pre>*/}
        </div>
    )
}