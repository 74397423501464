import {AuthContext} from "App/Strapi/AuthProvider"
import {useContext, useEffect, useState} from "react"
import {useSearchParams} from "react-router-dom";

export default function Logout() {
	// get the auth context for logout
	const authContext = useContext(AuthContext);
	const [params] = useSearchParams();

	const [logout, setLogout] = useState(null);

	// logout the user on mount
	useEffect(() => {

		// prepare for logout.
		setLogout(authContext.logout(params.has('message') ? params.get('message') : false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return logout;
}