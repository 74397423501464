import Icon from "UI/App/Components/Icon/Icon";
import {useLocation, useNavigate} from "react-router-dom";

export default function PersonCompanyListItem({ company }) {
	const navigate = useNavigate();

	// check if on suppliers path
	const location = useLocation();
	const isSupplier = /\/suppliers\//.test(location.pathname);


	return (
		<span style={{
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			cursor: 'pointer'
		}} key={company.id} onClick={() => navigate(`/crm/${isSupplier ? 'suppliers' : 'companies'}/${company.id}`)}>
			<span key={company.name}>{company.name} {company.sector !== undefined ? `(${company.sector})` : ''}</span>
			<span key={company.email} className="company__list-actions">
				{
					company.telephone && <a
						href={`tel: +${company.telephone.dialingCode} ${company.telephone.telephoneNumber}`}
						className='btn btn--round'
						onClick={(e) => e.stopPropagation()}
					><Icon name='phone' /></a>
				}
				{
					company.generalEmail && <a
						href={`https://outlook.office.com/?path=/mail/action/compose&to=${company.generalEmail}`}
						className='btn btn--round'
						onClick={(e) => e.stopPropagation()}
					><Icon name='mail' /></a>
				}

			</span>
		</span>
	)
}
