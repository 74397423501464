import Popup, {closePopup} from 'UI/App/Components/Popup/Popup';
import Button from '../../Button/Button';
import {useContext} from "react";
import {ConfigContext} from "App/Strapi/ConfigContext";
import Alert from "UI/App/Components/Alert/Alert";
import Icon from "UI/App/Components/Icon/Icon";

export default function PopupReLogin() {
    const configContext = useContext(ConfigContext);

    function handleClick() {
        const popupWidth = 500;
        const popupHeight = 600;

        // Fixes dual-screen position                                       Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop

        const url = `${process.env.REACT_APP_API_URL}/connect/microsoft`;

        const myWindow = window.open(url, "Re-login", `popup=1,width=${popupWidth / systemZoom},height=${popupHeight / systemZoom},left=${left},top=${top}`);

        window.closePopup = function () {
            myWindow.close();
        }
    }
    return (
        <Popup closeable={false} title='Opnieuw inloggen' popupID='popup-login' style={{ width: '400px' }} contentStyle={{ minHeight: '300px' }} >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <img
                    src={configContext.appConfig.logo}
                    alt={`Logo ${configContext?.appConfig?.organisation}`}
                    style={{
                        margin: '20px auto 40px',
                        maxWidth: '300px'
                    }}
                />
                <Alert type='warning'>
                    Je sessie verloopt bijna. Log opnieuw in.
                </Alert>
                <Button style={{ marginTop: '20px' }} onClick={handleClick}><Icon name={'microsoft'} /> Opnieuw inloggen</Button><br />
            </div>
        </Popup>
    );
}