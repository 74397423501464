import React, {ReactElement} from "react"
import Select from 'react-select'
import IWidget from "./IWidget"
import WidgetBase from "./WidgetBase"
import WidgetConditionalLogic from "./WidgetConditionalLogic";
import {TrenderType} from "./WidgetTypes"

/**
 * Widget class
 * @extends WidgetBase @implements IWidget
 */
export default class FloorWidget extends WidgetBase implements IWidget {
	callback: ((object: Object) => any);
	widgetData: String | null
	saveWidget: ((object: Object) => any);
	optionalWidgetData: object | undefined;
	debug: boolean;
	prefix: string | undefined;

	constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
		super()
		this.renderType = renderType;
		this.callback = callback;
		this.widgetData = null;
		this.saveWidget = saveWidget;
		this.optionalWidgetData = optionalWidgetData;
		this.debug = debug;
		this.prefix = prefix;
	}

	/**
	 * @return ReactElement
	 */
	public getPreviewHTML(): JSX.Element {
		const widgetData = {
			widgetType: 'FloorWidget',
		}

		return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
		}}>Rond af naar beneden</div>
	}

	/**
	 * Returns from html
	 */
	public getFormHTML(): JSX.Element {
		const widgetValues = this.getWidgetValues();

		return (<form onSubmit={(e) => this.handleSubmit(e)}>
			<h3>Rond af naar beneden</h3>
			<div className="input-group">
				Naam: <input type="text" name="title" />
			</div>
			<div className="input-group">
				<Select
					name="val1"
					options={widgetValues}
				/>
			</div>

			<div className="input-group">
				<WidgetConditionalLogic />
			</div>
			<button type="submit">Opslaan</button>
		</form>)
	}

	public getEditWidgetForm(editData: { [key: string]: any }): JSX.Element {
		const widgetValues = this.getWidgetValues();
		const [selectedOne] = WidgetBase.getSelectedValues(widgetValues, editData.value.val);

		return (<form onSubmit={(e) => this.handleEdit(e)}>
			<div className="input-group">
				Naam: <input type="text" name="title" defaultValue={editData.title} />
			</div>
			<div className="input-group">
				<Select
					name="val1"
					options={widgetValues}
					defaultValue={selectedOne}
				/>
			</div>

			<div className="input-group">
				<WidgetConditionalLogic />
			</div>

			<input type="hidden" name={"id"} value={editData.id}/>
			<button type="submit">Opslaan</button>
		</form>)
	}

	public getBadgeHTML() {
		return <div>Badge html:</div>
	}

	/**
	 * Handles submit from getFormHTML
	 * @param event:any
	 */
	handleSubmit(event: any): void {
		event.preventDefault();

		const data = {
			type: "FloorWidget",
			title: event.target.elements.title.value,
			value: {
				val: event.target.elements.val1.value,
				conditional: {
					number: event.target.elements?.conditionalValue.value,
					type: event.target.elements?.conditionalType.value,
					target: event.target.elements?.conditionalLogicWidget.value
				}
			}
		};

		this.addWidgetToWidgetContainer("Rond af: " + data?.title + " < " + data.value.val, data);
	}

	handleEdit(event: any) {
		event.preventDefault();

		const data = {
			type: "FloorWidget",
			title: event.target.elements.title.value,
			value: {
				val: event.target.elements.val1.value,
				conditional: {
					number: event.target.elements?.conditionalValue.value,
					type: event.target.elements?.conditionalType.value,
					target: event.target.elements?.conditionalLogicWidget.value
				}
			}
		};

		this.updateWidget(event.target.elements.id.value, "Rond af: " + data?.title + " < " + data.value.val, data);
	}

	/**
	 * Renders calculation form
	 */
	public getCalculationForm() {

		this.addWidgetToCalculationForm(`Floor`, this.optionalWidgetData, this.optionalWidgetData.parentId)
		// return <div id={this.optionalWidgetData.id}>Floor</div>
	}

	/**
	 * Renders preview form
	 */
	public getPreviewFormHTML(): ReactElement {
		return <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.title} value={this?.optionalWidgetData?.value} />
	}

	/**
	 * Returns formula string of given widget
	 */
	public getFormulaString(): string {
		return `floor(${this?.optionalWidgetData?.value.val1})`;
	}

	/**
	 * Returns html for edit
	 */
	getEditHTML(): void {
		(new WidgetBase()).addWidgetToWidgetContainer("Rond af : " + this.optionalWidgetData?.data?.title, this.optionalWidgetData, undefined, true);
	}


	/**
	 * Calcualtion logic
	 */
	calculationFunctionality(): any {
		const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
		const valId = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.val : this.optionalWidgetData.data.value.val;

		/**
		 * If has no conditional logic remove hidden and remove readonly
		 */
		if (this.optionalWidgetData?.data.value.conditional.target === undefined || this.optionalWidgetData?.data.value.conditional.target === '') {
			/**
			 * Set widget data
			 */
			this.optionalWidgetData.data.value.result = Math.floor(this.getResultFromWidget(valId));
			/**
			 * Update widget data in storage
			 */
			this.updateWidgetData(id, this.optionalWidgetData)
		} else {
			/**
			 * If condition is right set usable else disable and hide
			 */
			if (this.handleConditional(this.optionalWidgetData.data.value.conditional)) {
				/**
				 * Set widget data
				 */
				this.optionalWidgetData.data.value.result = Math.floor(this.getResultFromWidget(valId));

				/**
				 * Update widget data in storage
				 */
				this.updateWidgetData(id, this.optionalWidgetData)
			} else {
				if (!document.getElementById(id).parentElement.classList.contains('hidden')) {
					/**
					 * Set widget data
					 */
					this.optionalWidgetData.data.value.result = 0;

					/**
					 * Update widget data in storage
					 */
					this.updateWidgetData(id, this.optionalWidgetData)
				}
			}
		}

		/**
		 * Handle debug
		 */
		if (this.debug) {
			document.getElementById(id).innerHTML = "";
			document.getElementById(id).append(`[${this.optionalWidgetData?.prefix}${this.optionalWidgetData?.id}] Rond af: ` + this.optionalWidgetData.data.title + ' < ' + this.optionalWidgetData.data.value.val)
			document.getElementById(id).classList.remove('hidden');
		} else {
			document.getElementById(id).innerHTML = "";
			document.getElementById(id).classList.add('hidden');
		}
	}
}
