import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Select from "react-select";
import {Controller} from "react-hook-form";

export default function JobTicketField({ groupName, orderNumber, customerReference, calculationId, field, register, control }) {
    return (
        <div className="input-group">
            <label>{field.label}</label>
            <InputField groupName={groupName} orderNumber={orderNumber} customerReference={customerReference} calculationId={calculationId} field={field} register={register} control={control} />
        </div>
    )
}

function InputField({ groupName, orderNumber, customerReference, calculationId, field, register, control }) {
    const getDefaultValueByName = (name) => {
        switch(name) {
            case 'ordernummer':
                return orderNumber;
            case 'klantreferentie':
                return customerReference;
            default:
                return '';
        }
    }

    const fieldProps = register(`${groupName}.${field.label.toLowerCase()}`, {
        value: getDefaultValueByName(field.label.toLowerCase()),
        required: false,
    })

    if (field.type === 'select') {
        return <SelectField field={field} fieldProps={fieldProps} control={control} />
    } else if (field.type === 'material') {
        return <MaterialsField field={field} fieldProps={fieldProps} calculationId={calculationId} control={control} />
    } else {
        return <SimpleField field={field} fieldProps={fieldProps} />
    }
}

function SimpleField({ field, fieldProps }) {
    return (
        <input {...fieldProps} type={field.type} />
    )
}

function SelectField({ field, fieldProps, control }) {
    return (
        <Controller
            control={control}
            name={fieldProps.name}
            render={( props ) => (
                <Select
                    {...props.field}
                    options={Object.entries(field?.options ?? {}).map(([label, value]) => ({ label, value }))}
                />
            )}
        />
    )
}

function MaterialsField({ field, fieldProps, calculationId, control }) {
    const [fieldState, setFieldState] = useState({
        ...field,
        options: []
    });
    const { authAxios } = useContext(FetchContext);

    useEffect(() => {
        authAxios
            .get(`/calculations/${calculationId}/materials`)
            .then(({data}) => {
                setFieldState(fieldState => ({
                    ...fieldState,
                    options: data
                        .filter(material => material.internalSKU)
                        .sort((a, b) => a.internalSKU > b.internalSKU)
                        .map(material => ({ label: material.internalSKU, value: material.id, material: material }))
                }));
            })
            .catch(error => {
                toast.error('Er is iets misgegaan bij het ophalen van de materialen.')
                console.error(error);
            })
    }, [authAxios, calculationId]);

    return (
        <Controller
            control={control}
            name={fieldProps.name}
            render={( props ) => (
                <Select
                    {...props.field}
                    options={fieldState.options}
                />
            )}
        />
    )
}