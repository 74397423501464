import {FetchContext} from "App/Strapi/FetchContext";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import qs from "qs";
import Block from "UI/App/Components/Block/Block";
import Grid from "UI/App/Components/Grid/Grid";
import IF from "UI/App/Components/Conditional/IF";
import {toast} from "react-toastify";
import {statusses} from "UI/App/Partials/Content/Calculations/Calculations/overview";
import Icon from "UI/App/Components/Icon/Icon";
import CalculationResult from "UI/App/Partials/Content/Calculations/HJMG/steps/CalculationResultTab/CalculationResult";
import {logError} from "App/Util/errorHandle";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import useWindowDimensions from "App/Util/useWindowDimensions";
import {openPopup} from "UI/App/Components/Popup/Popup";
import Button from "UI/App/Components/Button/Button";
import EditConfirmationPopup from "../Calculations/EditConfirmationPopup";

export default function ViewHJMGCalculation() {
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    const { width } = useWindowDimensions();

    const [results, setResults] = useState([]);
    const [calculationData, setCalculationData] = useState({});
    const [printData, setPrintData] = useState({});
    const [customer, setCustomer] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [calculation, setCalculation] = useState();
    const [checklistData, setChecklistData] = useState();
    const [purchaseData, setPurchaseData] = useState(null)


    useEffect(() => {
        const query = qs.stringify({
            populate: {
                company: {
                    fields: [
                        'name'
                    ]
                },
                contactPerson: {
                    fields: [
                        'firstName',
                        'prefixToSurname',
                        'surname'
                    ]
                }
            }
        });

        authAxios.get(`/calculations/${params.calculationId}?${query}`).then(({ data: { data } }) => {
            setCalculationData(data.data.calculationData);
            setPrintData(data.data.printData);
            setChecklistData(data.data.checklistData);
            setPurchaseData(data.data.purchaseData)
            setCustomer(data.company);
            setContactPerson(data.contactPerson);
            setCalculation(data);

            setTimeout(() => {
                setResults(data.data.results);
            }, 200);
        }).catch((err) => {
            console.log(err);
        });
    }, [params.calculationId]);

    function updateCalculation() {
        authAxios.put(`/calculations/${params.calculationId}`, {
            data: {
                data: {
                    results: results,
                    calculationData: calculationData,
                    printData: printData,
                    checklistData: checklistData
                }
            }
        }).then(() => {
            toast.success('Calculatie bijgewerkt!');
        }).catch((err) => {
            toast.error('Er ging iets mis bij het bijwerken van de calculatie!');
            logError(authAxios, err);
        })
    }

    function updateCalculationStatus(status) {
        authAxios.put(`/calculations/${params.calculationId}`, {
            data: {
                status: status
            }
        }).then(() => {
            toast.success('Calculatie status bijgewerkt!');
            setCalculation(prevState => ({
                ...prevState,
                status: status
            }));
        }).catch((err) => {
            toast.error('Er ging iets mis bij het bijwerken van de calculatie status!');
            logError(authAxios, err);
        })
    }

    return (
        <div>
            <PageHeader
                color={'primary'}
                sticky={true}
                title={`${calculation?.number} | ${calculation?.customerReference}`}
            >
                <Button data-title={'Bewerken'} onClick={() => {openPopup('editConfirmationMessage')}} className={'btn btn--round btn--black'}>{<Icon name={'pencil'} />}</Button>
                <Link data-title={'Kopiëren'} to={`/calculations/${calculation?.id}/copy`} className={'btn btn--round btn--black'}>{<Icon name={'copy'} />}</Link>
                <Link data-title={'Offerte maken'} className={'btn btn--round btn--black'} to={`/quotes/create/${calculation?.id}`}><Icon name={'offer-solid'} /></Link>
                {calculation?.status === 'concept' ? <button data-title={'Opslaan'} onClick={updateCalculation} className={'btn btn--round btn--black'}><Icon name={'save'} /></button> : null}
            </PageHeader>
            <br/>
            <EditConfirmationPopup calculationId={params.calculationId}/>
            <Grid columns={width < 750 ? 1 : checklistData?.length <= 1 ? 1 : 2}>
                <IF condition={customer && contactPerson && calculation}>
                    <Block name={'calculationInfo'} title={'Informatie'}>
                        <Grid gap={'10px'}>
                            <span>
                                <b>Klant:</b> <Link to={`/crm/companies/${customer?.id}`}>{customer?.name}</Link>
                            </span>
                            <span>
                                <b>Contactpersoon:</b> <Link to={`/crm/companies/${customer?.id}/contactpersons/${contactPerson?.id}`}>{(`${contactPerson?.firstName} ${contactPerson?.prefixToSurname ?? ''} ${contactPerson?.surname}`).replace('  ', ' ')}</Link>
                            </span>
                            <span>
                                <b>Nummer:</b> {calculation?.number}
                            </span>
                            <span style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px'
                            }}>
                                <b>Status:</b>
                                <select
                                    style={{
                                        margin: 0,
                                    }}
                                    onChange={(e) => updateCalculationStatus(e.target.value)}
                                    defaultValue={calculation?.status}
                                >
                                    {statusses.map(({label, value, disabled}) =>
                                        <option key={value} value={value} disabled={disabled}>{label}</option>
                                    )}
                                </select>
                            </span>
                        </Grid>
                    </Block>
                </IF>

                <IF condition={checklistData?.length > 0}>
                    <Block name={'checklist'} title={'Checklist'}>
                        <ul style={{listStyleType: 'none'}} className={'checklist'}>
                            {checklistData?.filter(item => item.value)?.map((listItem) =>
                                <li key={listItem?.id}><span className={'name'}>{listItem.name}</span>: <span className={'value'}>{listItem.value}</span></li>
                            )}
                        </ul>
                    </Block>
                </IF>
            </Grid>
            <br/>
            <CalculationResult
                results={results}
                setResults={setResults}
                calculationData={calculationData}
                purchaseData={purchaseData}
                printData={printData}
                setPrintData={setPrintData}
                checklistData={checklistData}
                calculation={calculation}
            />
        </div>
    )
}
