import IF from "UI/App/Components/Conditional/IF";
import spinnerPrimary from "../../../Assets/Spinners/spinner-primary.svg";
import spinnerWhite from "../../../Assets/Spinners/spinner.svg";

import "./spinner.scss";

export function Spinner({width = '50', height = '50', white = false, ...props}) {
    return <img className="spinner" src={white ? spinnerWhite : spinnerPrimary} alt='spinner' width={width} height={height} {...props} />
}

export function SpinnerCenter({width = '50', height = '50', white = false, message = null, containerStyle = {}, ...props}) {
    return (
        <div className="spinner-center" style={containerStyle}>
            <Spinner width={width} height={height} white={white} {...props} />
            <IF condition={message}>
                <div className="spinner-message">{message}</div>
            </IF>
        </div>
    )
}

export function SpinnerOverlay({children, visible, message = false, width = '50', height = '50', white = false}: Record<string, any>) {
    return (
        <div className="spinner-overlay" data-visible={visible}>
            <div className="spinner-container">
                <SpinnerCenter white={white} width={width} height={height} message={message} />
            </div>
            <div className={'spinner-backdrop'}></div>
            {children}
        </div>
    )
}

export {spinnerPrimary, spinnerWhite};
