import {formatTime} from "App/Util/format";

export function sanitizeData(data: any): any {
    // Sanitize data
    if (typeof data === 'string') {
        data = sanitizeString(data);
    } else if (Array.isArray(data) || typeof data === 'object') {
        data = sanitizeIterable(data);
    }

    // Return sanitized data
    return data;
}

export function sanitizeString(data: string): any {
    const dict: { [key: string]: string } = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#039;': "'",
        '&ouml;': 'ö',
        '&euml;': 'ë',
        '&uuml;': 'ü',
        '&rsquo;': '’',
        '&nbsp;': '',
        '&szlig;': 'ß'
    };

    for (const key in dict) {
        data = data.replaceAll(key, dict[key]);
    }

    return data;
}

export function sanitizeIterable(data: { [key: string]: any }): object {
    for (const key in data) {
        if (typeof data[key] === 'string') {
            data[key] = sanitizeString(data[key]);
        } else if (typeof data[key] === 'object' || Array.isArray(data[key])) {
            data[key] = sanitizeData(data[key]);
        }
    }
    return data;
}

export function b64toBlob(
    b64Data: string,
    contentType: string = '',
    sliceSize: number = 512
): Blob {
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export function getSecondsMinusBreaks(start: string | Date, end: null | string | Date, breaks: Array<{ start: string | Date, end: null | string | Date }>): number {
    start = new Date(start)
    end = end === null ? new Date() : new Date(end)
    const breakLength = breaks?.reduce((acc, cur) => {
        const start = new Date(cur.start);
        const end = cur.end === null ? new Date() : new Date(cur.end);
        return acc + (end.getTime() - start.getTime());
    }, 0) ?? 0;
    return end.getTime() - start.getTime() - breakLength;
}