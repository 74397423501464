import {toast} from "react-toastify";
import IF from "UI/App/Components/Conditional/IF";
import {tryParse} from "App/Util/format";

// function to handle errors from strapi reqeusts specifically
export default function strapiToast(error, returnElements = false, logToConsole = true) {
    // standard value for autoClose
    let autoClose = 5000;
    // get the reasons of the error
    let reasons = error?.response?.data?.error?.details?.errors === undefined ? [] : error.response.data.error.details.errors;

    // create array to store ellemnts in
    let formatedReason = [];
    if (reasons.length > 0) {
        let mutiple = reasons.length > 1 ? reasons.length + " reasons" : "reason"
        formatedReason.push(<>Failed because of the following {mutiple};<br /></>)
        Object.entries(reasons).forEach(function ([k, entry]) {
            autoClose += 1500;
            // get 'path', this wil help pinpoint the variable in question
            let extraInfo = entry.path.join('.');

            formatedReason.push(<><small><b>{reasons.length > 1 && ((Number(k) + 1) + ". ")} {extraInfo}<br /></b>{entry.message}<br /></small></>);
        });
    }
    if (logToConsole) {
        console.error(error);
    }

    // construct a message
    let errorMessage = (
        <>
            <IF condition={error?.response?.statusText}>
                <div>
                    <b>{error.response.statusText}</b>
                </div>
            </IF>
            {formatedReason}
        </>
    );

    if (returnElements) {
        return <div>{errorMessage}</div>
    } else {
        toast.error(<div>{errorMessage}</div>, {
            autoClose: autoClose
        });
    }

}

export function logError(axios, errorPacket, status = 500, targetAction = null) {
    const userId = tryParse(localStorage.getItem('userInfo'))?.id ?? null;

    // create the data payload
    const data = {
        user: userId,
        payload: errorPacket,
        status: status,
        action: "Error",
        apiToken: null,
        location: window.location.href,
        targetAction: targetAction
    }

    // log error
    axios.post("/admin/logs",
        { data },
    )
}
