import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";
import Briefpapier_HJMG from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg";
import Briefpapier_HJMG_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG_2.jpg";

import {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Button from "UI/App/Components/Button/Button";
import {FetchContext} from "App/Strapi/FetchContext";
import QuotePDFGenerator from "./QuotePDFGenerator";
import IF from "UI/App/Components/Conditional/IF";
import Block from "UI/App/Components/Block/Block";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import QuotePdfEditor from "UI/App/Partials/Content/CRM/Quotes/QuotePdfEditor";
import {toast} from "react-toastify";
import Select from "react-select";
import "./quote.scss";
import qs from "qs";
import {getCurrencyFromPriceString, getNumberFromPrice} from "App/Util/format";
import useWindowDimensions from "App/Util/useWindowDimensions";
import {downloadFile, downloadFileFromAPI} from 'App/Util/fetch';
import {AuthContext} from 'App/Strapi/AuthProvider';
import HasRole from "UI/App/Components/Auth/HasRole";
import {_PROJECT_MANAGEMENT_RATE} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import User from "App/Strapi/User";
import {SpinnerCenter} from "UI/App/Components/Spinner";

/**
 * The possible statuses for a quote
 * @type {[{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string}]}
 * @private
 */
export const _QUOTE_STATUSES = [
    { label: 'Concept', value: 'concept' },
    { label: 'Uitgebracht', value: 'uitgebracht' },
    { label: 'Herzien', value: 'herzien' },
    { label: 'Geaccepteerd', value: 'geaccepteerd' },
    { label: 'Afgewezen prijs', value: 'afgewezen prijs' },
    { label: 'Afgewezen planning', value: 'afgewezen planning' },
    { label: 'Afgewezen klant', value: 'afgewezen klant' },
    { label: 'Afgewezen overig', value: 'afgewezen overig' },
    { label: 'Verlopen', value: 'verlopen' },
];

const _PRICE_REGEX = /^[$€][\s ]?[\d.,]+$/;

const allowedSendersByRole = ["admin", "management", "sales", "productie manager"];

export default function CreateUpdateQuote({ revision = false, edit = false, copy = false }) {
    const params = useParams();
    const {authAxios} = useContext(FetchContext);
    const {width} = useWindowDimensions();
    const {authState} = useContext(AuthContext);

    const [calculations, setCalculations] = useState([]);
    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const [owner, setOwner] = useState('');
    const [company, setCompany] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [template, setTemplate] = useState(null);
    const [formattedRows, setFormattedRows] = useState([])
    const [price, setPrice] = useState([]);

    const [quote, setQuote] = useState({
        company: null,
        contactPerson: null,
        template: null,
        calculations: params.calculationId ? [parseInt(params.calculationId)] : [],
        status: "concept",
    });

    // pdf editor states
    const [selectedBackground, setSelectedBackground] = useState({page1: null, page2: null});
    const [chosenContactPerson, setChosenContactPerson] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [signature, setSignature] = useState(null);
    const [language, setLanguage] = useState(null);
    const [date, setDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [senders, setSenders] = useState([]);
    const [activeSender, setActiveSender] = useState({
        username: authState.user.username,
        value: authState.user.username,
        id: authState.user.id
    });

    const [placeOfOrigin, setPlaceOfOrigin] = useState('');
    const [introLine, setIntroLine] = useState('');

    const [footerData, setFooterData] = useState([]);
    const [footerText, setFooterText] = useState('');

    const [pages, setPages] = useState([]);

    async function reformatRowData(data) {
        const formattedData = await new QuotePDFGenerator(selectedBackground, formattedRows, company, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, activeSender?.value).formatForPdf(data);

        setFormattedRows(formattedData);
        return formattedData
    }

    useEffect(() => {
        // a copy might have a mutated footer, we dont update the footer if its a copy.
        if (!copy && !revision) {
            load();
        }

    }, [language])

    // On page load
    useEffect(() => {
        if (signature !== null) {
            if (params.calculationId) {
                getQuoteDataFromCalculation(params.quoteId);
            }

            if (params.quoteId) {
                getQuoteFromAPI(params.quoteId, copy, edit);
            }

            void updateCalculations();
        }
    }, [params, signature]);

    useEffect(() => {
        authAxios.get('../users/all/limited').then(async ({ data }) => {
            const currentUser = User.get();

            const relevantUsers = await Promise.all(
                data
                    .filter((user) => allowedSendersByRole.includes(user.roleName.toLowerCase()))
                    .filter((user) => !user.blocked)
                    .map(async (user) => {
                        if (user.username === currentUser.username) {
                            setActiveSender({
                                label: user.username,
                                value: user.username,
                                id: user.id
                            });
                        }
                        return {
                            label: user.username,
                            value: user.username,
                            id: user.id
                        }
                    })
            );
            setSenders(relevantUsers)
        }).catch((e) => {
            toast.error('Er is iets misgegaan bij het ophalen van de afzenders');
            console.error(e);
            setSenders([]);
        })
    }, [authAxios]);

    // get authstate user signature image
    useEffect(() => {
        if (!activeSender) return;

        authAxios.get(`../users/${activeSender.id}/signature`)
            .then((response) => {
                if (response.status === 204) {
                    setSignature(false);
                    return;
                }

                downloadFile(authAxios, response.data.id, 'base64')
                    .then((base64) => {
                        setSignature(`data:${response.data.mime};base64,${base64}`);
                    });
            }).catch((error) => {
                console.error(error);
                setSignature(false);
            });
    }, [authAxios, activeSender]);

    useEffect(() => {
        void updateCalculations();
    }, [company, contactPerson, quote.calculations]);

    useEffect(() => {
        if (!copy && !revision) updateTemplates();
    }, [quote.calculations]);

    useEffect(() => {
        loadChecklistData();
    }, [quote, signature])

    useEffect(() => {
        if (language === null) return;
        let endDate = new Date(date);
        if (language == "nl") {
            endDate.setDate(endDate.getDate() + 30)
        } else {
            endDate.setDate(endDate.getDate() + 14)
        }

        setEndDate(endDate.toLocaleDateString("fr-ca"))

    }, [date])

    async function load() {
        setPlaceOfOrigin('Dordrecht')
        setIntroLine(language === 'nl' ? 'Hartelijk dank voor je aanvraag. Wij bieden u graag het volgende aan:' : 'Thank you for your request. We’re pleased to send your offer.')

        // Load footer data
        let footerDataArray = [];

        // Add Payment info
        footerDataArray.push({
            label: language === 'nl' ? 'Betaling' : 'Payment',
            content: language === 'nl' ? '50% bij opdracht en 50% bij aflevering.' : '50% upon order and 50% upon delivery.'
        });

        // Add shipment Info
        footerDataArray.push({
            label: language === 'nl' ? 'Transport' : 'Shipment',
            content: language === 'nl' ? 'Af-fabriek' : 'Ex Works'
        })

        // Add General info row 1
        footerDataArray.push({
            label: language === 'nl' ? 'Algemeen' : 'Prices',
            content: language === 'nl' ? 'Bovengenoemde prijzen zijn exclusief BTW,' : `The above prices are exclusive of VAT, author\'s corrections statutory`
        });

        // Add General info row 2
        footerDataArray.push({
            label: language === 'nl' ? '' : '',
            content: language === 'nl' ? 'auteurscorrecties,wettelijke afvalbeheersbijdrage en' : `waste management contribution and packaging tax.`
        });

        if (language === 'nl') {
            // Add General info row 3
            footerDataArray.push({
                label: '',
                content: `verpakkingenbelasting.`
            });
        }

        // Add Conditions row 1
        footerDataArray.push({
            label: language === 'nl' ? 'Voorwaarden' : 'Conditions',
            content: language === 'nl' ? 'Al onze diensten en producten worden geleverd onder onze' : `All our services and products are provided under the general`
        });

        // Add Conditions row 2
        footerDataArray.push({
            label: language === 'nl' ? '' : '',
            content: language === 'nl' ? 'algemene voorwaarden. Klik hier om deze online te bekijken.' : `conditions of the graphic industry.`
        });

        setFooterData(footerDataArray);
        setFooterText(language === 'nl' ? 'Wij vertrouwen erop u hierbij een passend voorstel te hebben gedaan en zien uw reactie met belangstelling tegemoet.' : `We thank you for your query for your Bible(s) and/or book(s). Passionate for creating unique and highest quality products, we trust we made you a good offer.`)
    }

    async function loadChecklistData() {
        let rows = [
            {label: language === 'nl' ? 'Offertenummer' : 'Offer', content: 'OFF'}
        ];
        for (const calculationId of quote.calculations) {
            const query = qs.stringify({
                fields: ['id', 'data', 'name', 'owner', 'number', 'language', 'projectDescription']
            });

            await authAxios.get(`/calculations/${calculationId}?${query}`).then(({data: {data}}) => {
                // Store checklist
                for (const checklistItem of data.owner === 'pretore' ? data.data.checklist : data.data.checklistData) {
                    if (checklistItem.value === '' || checklistItem.value === undefined) {
                        continue;
                    }

                    rows.push({
                        label: (language === 'nl' ? checklistItem.dutchName: checklistItem.englishName) ?? checklistItem.name,
                        content: checklistItem.value
                    });
                }

                // Store amounts for price
                if (data.owner === 'pretore') {
                    rows.push({
                        price: true,
                        amounts: data.data.amounts ?? [0],
                        summary: data.data.summary ?? [{quote: 0}],
                        calculationId: data.id
                    })
                } else {

                    if (rows.length > 0) {
                        // add empty row
                        rows.push({
                            label: "",
                            content: "",
                            calculationId: data.id
                        })
                    }

                    // HJMG specific
                    let results = data.data.results;
                    // for all services put costs down
                    for (let [key, service] of Object.entries(data.data.calculationData).sort((a, b) => a[1].order - b[1].order)) {

                        const serviceResult = results[key];

                        service?.entries?.forEach((el) => {
                            // add description
                            rows.push({
                                label: key,
                                content: el.description,
                                summary: service,
                                calculationId: data.id
                            })

                            // billing period
                            if (el?.billingPeriod !== undefined) {

                                let content = "";
                                if (language === "nl") {
                                    content = el.billingPeriod === "yearly" ? "Jaarlijks" : "Maandelijks"
                                } else {
                                    content = el.billingPeriod === "yearly" ? "Yearly" : "Monthly"
                                }

                                rows.push({
                                    label: (language === 'nl' ? "Betaal periode" : "Billing period"),
                                    content: content,
                                    summary: service,
                                    calculationId: data.id
                                })
                            }

                            const projectManagementTime = Number(el.time) * (service.projectManagementPercentage / 100);
                            const projectManagementCost = projectManagementTime * _PROJECT_MANAGEMENT_RATE;
                            const calculatedPrice = Number(el.total) + projectManagementCost;
                            const calculatedPriceWithMargin = calculatedPrice * (results.margin / 100 + 1);
                            const matchingServiceResult = Array.isArray(serviceResult) ? serviceResult.find(sr => sr.description === el.description) : false;

                            if (service.isRecurring && serviceResult && matchingServiceResult) {
                                // add price
                                rows.push({
                                    HJMGPrice: true,
                                    noLeadingLine: true,
                                    summary: matchingServiceResult.quote,
                                    calculationId: data.id
                                })
                            } else {
                                // add price
                                rows.push({
                                    HJMGPrice: true,
                                    noLeadingLine: true,
                                    summary: calculatedPriceWithMargin,
                                    calculationId: data.id
                                })
                            }

                            // add empty row
                            rows.push({
                                label: "",
                                content: "",
                                calculationId: data.id
                            })
                        })
                    }

                    // If print, add
                    if (data.data.printData && data.data.printData?.summary) {

                        const printData = data.data.printData;

                        rows.push({
                            label: 'Print',
                            content: printData.customerReference,
                            calculationId: data.id
                        })

                        for (const [quantity, result] of Object.entries(printData.summary)) {
                            rows.push({
                                HJMGPrice: true,
                                noLeadingLine: true,
                                altLabel: language === 'nl' ? 'Prijs voor ' + quantity + ' stuks' : 'Price for ' + quantity + ' pieces',
                                summary: result.quote,
                                calculationId: data.id
                            })
                        }

                        // add empty row
                        rows.push({
                            label: "",
                            content: "",
                            calculationId: data.id
                        })
                    }

                    // add total price
                    rows.push({
                        HJMGPrice: true,
                        altLabel: (language === 'nl' ? "Totaal prijs" : "Total price"),
                        noLeadingLine: true,
                        summary: results.quote,
                        calculationId: data.id
                    })
                }
            })

            // If more than 1 calculation add empty row
            if (quote.calculations.length > 1) {
                rows.push({label: '', content: ''})
            }
        }

        // if this is a copy we dont need to set rows with callc rows
        if (!copy && !revision) {
            const formatted = await reformatRowData(rows);
            setRowData(formatted);
        }

    }

    function getQuoteDataFromCalculation() {
        const query = qs.stringify({
            populate: {
                company: {
                    fields: [
                        'id',
                        'name',
                    ],
                    populate: [
                        "address",
                        "contactPersons"
                    ]
                },
                contactPerson: {
                    fields: [
                        'id',
                        'firstName',
                        'prefixToSurname',
                        'surname',
                    ]
                },
                projects: true,
            },
            fields: ['id', 'number', 'name', 'owner', 'language', 'projectDescription']
        });

        authAxios.get(`/calculations/${params.calculationId}?${query}`).then(({data: {data}}) => {

            setCalculations([{
                label: data.number,
                value: data.id,
                language: data.language,
            }]);
            setCompany(data.company);
            setContactPerson(data.contactPerson);
            setDescription(data?.projectDescription)
            setOwner(data?.owner)

            setQuote({
                company: data.company.id,
                contactPerson: data?.contactPerson?.id,
                calculations: [data.id],
                projects: data.projects
            });

            // setOwner(data.owner);
            if (data.owner === 'pretore') {
                setSelectedBackground({page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_2});
            } else {
                setSelectedBackground({page1: Briefpapier_HJMG, page2: Briefpapier_HJMG_2});
            }
            setLanguage(data.language.toLowerCase());
            setDate(new Date().toLocaleDateString('fr-ca'))
            setChosenContactPerson(data.contactPerson)

        });
    }

    function getQuoteFromAPI(quoteId, isCopy = false, edit = false) {
        const query = qs.stringify({
            populate: {
                company: {
                    fields: [
                        'id',
                        'name',
                    ],
                    populate: [
                        "address",
                        "contactPersons"
                    ]
                },
                contactPerson: {
                    fields: [
                        'id',
                        'firstName',
                        'prefixToSurname',
                        'surname',
                    ]
                },
                pdfTemplate: {
                    fields: [
                        '*'
                    ]
                },
                calculations: true,
                data: true,
                projects: true,
                footerData: true,
            },
        });


        authAxios.get(`/quotes/${quoteId}?${query}`).then(async ({data: {data}}) => {
            let calculations = [];

            // copy over the footer if required
            if (copy || revision) {
                setPlaceOfOrigin(data?.placeOfOrigin)
                setIntroLine(data?.introLine)
                setFooterData(data?.footerData)
                setFooterText(data?.footerText)
            }

            setQuote({
                company: data.company.id,
                contactPerson: data?.contactPerson?.id,
                calculations: data?.calculations?.map(calc => calc.id),
                projects: data.projects
            });

            for (const calculation of data.calculations) {

                // setOwner(calculation.owner)

                if (calculation.owner === 'pretore') {
                    setSelectedBackground({page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_2});
                } else {
                    setSelectedBackground({page1: Briefpapier_HJMG, page2: Briefpapier_HJMG_2});
                }

                setLanguage(calculation.language.toLowerCase());

                // Load rows
                let formatted = [];
                for (const rowData of data.data) {

                    rowData.label = rowData.title;
                    //rowData.displayType = 'price'
                    rowData.displayType = _PRICE_REGEX.test(rowData.content) && 'price'
                    // add page if needed
                    if (formatted[rowData.page] === undefined) {
                        formatted[rowData.page] = [];
                    }
                    formatted[rowData.page].push(rowData)
                }

                let formattedData;
                if (copy || revision) {
                    formattedData = await new QuotePDFGenerator(selectedBackground, formattedRows, data.company, data.contactPerson, data.owner, false, data.language, data.date, signature, data?.placeOfOrigin, data?.introLine, data?.footerData, data?.footerText, data?.endDate, activeSender?.value).formatForPdf(formatted, true);
                } else {
                    formattedData = await new QuotePDFGenerator(selectedBackground, formattedRows, data.company, data.contactPerson, data.owner, false, data.language, data.date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, activeSender?.value).formatForPdf(formatted, true);
                }


                setFormattedRows(formattedData)
            }

            setCalculations(data.calculations.map(calculation => calculation.id))
            setDescription(isCopy && data?.description ? `Kopie van ${data.number} | ${data?.description}` : data?.description);
            setCompany(data.company);
            setContactPerson(data.contactPerson);
            setChosenContactPerson({
                id: data.contactPerson.id,
                surname: data.contactPerson.surname,
                firstName: data.contactPerson.firstName,
                prefixToSurname: data.contactPerson.prefixToSurname,
            });

            updateQuote('template', data?.pdfTemplate.id ?? null);
            setTemplate(data?.pdfTemplate ?? null);

            // set and/or get the date of the copy
            let copiedDate = data.startDate ?? new Date().toLocaleDateString('fr-ca')
            setDate(isCopy ? copiedDate : new Date().toLocaleDateString('fr-ca'))
        });
    }

    function updateQuote(key, value) {
        setQuote(prev => ({
            ...prev, [key]: value
        }));
    }

    function updateTemplates() {
        const query = qs.stringify({
            filters: {
                type: 'Quote',
                language: calculations.find(calc => calc.value === quote.calculations[0])?.language ?? undefined
            }
        });

        if (quote.calculations.length === 0) {
            updateQuote('template', null);
            setTemplate(null);
            return;
        }

        authAxios.get(`/config/pdf-templates/all?${query}`).then(({data}) => {
            updateQuote('template', data?.[0]?.id ?? null);
            setTemplate(data?.[0] ?? null);
        });
    }

    async function updateCalculations() {
        if (company !== null) {
            const query = qs.stringify({
                filters: {
                    company: company.id,
                    language: calculations.find(calc => calc.value === quote.calculations[0])?.language ?? undefined
                },
                fields: [
                    'id',
                    'name',
                    'number',
                    'language'
                ],
                sort: "id:desc"
            });

            await authAxios.get(`/calculations/all?${query}`).then(({data}) => {
                setCalculations(data.map((calculation) => ({
                    label: calculation.number, value: calculation.id, language: calculation.language
                })));
            });
        }
    }

    function getQuotePDF() {
        new QuotePDFGenerator(selectedBackground, formattedRows, company, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, activeSender?.value).getPDF()
    }


    async function saveOrUpdateQuote({ currentTarget }) {
        // disable button
        currentTarget.disabled = true;

        let formattedForStorage = [];
        for (const rowsKey in formattedRows) {
            for (const row of formattedRows[rowsKey]) {
                formattedForStorage.push({
                    title: row.label,
                    content: row.content,
                    page: rowsKey,
                    type: row.type,
                    amount: row.amount,
                    calculationId: row.calculationId
                })
            }
        }

        // If edit else store
        if (edit) {
            // Todo
            console.log('edit');
        } else {
            // Get price data
            const priceData = await getPriceFromDataRows(formattedForStorage, true);

            // Create quote data object
            const quoteData = {
                ...quote,
                placeOfOrigin: placeOfOrigin,
                introLine: introLine,
                footerText: footerText,
                footerData: footerData,
                data: formattedForStorage,
                pdfTemplate: quote.template,
                description: description,
                priceData: await getPriceFromDataRows(formattedForStorage),
                endDate: endDate,
                startDate: date,
            }

            // Loop calculations to update quote price
            for (const calculationId of quote.calculations) {

                // if we dont have set pricedata skip over this itteration
                if (priceData[calculationId] === undefined) {
                    continue;
                }

                // get current data from calculation for update
                await authAxios.get(`/calculations/${calculationId}`).then(({ data: { data } }) => {
                    // Create copy of calculation data
                    const calculationData = { ...data }

                    // Update price data for Pretore and HJMG
                    if (data.owner.toLowerCase() === 'pretore') {

                        // Loop price data for calculation
                        Object.keys(priceData[calculationId]).forEach(function (amount, amountKey) {

                            // Store price data to sumary
                            calculationData.data.summary[amount].quote = priceData[calculationId][amount].price
                        });
                    } else {
                        calculationData.data.results.quote = priceData[calculationId]?.price
                    }

                    // Update calculation
                    authAxios.put(`/calculations/${calculationId}`, {data: calculationData}).catch((reason) => {
                        console.error(reason);
                        toast.error(`Er is iets mis gegaan bij het bewerken van de calculatie offerte prijs. ${reason?.response?.status && `(${reason.response.status})`}`)
                    });
                })
            }

            if (revision) {
                quoteData.copiedFrom = params.quoteId
            }

            // create quote
            await authAxios.post('/quotes', { data: quoteData }).then((result) => {
                for (const calculation of quote.calculations) {
                    setCalculationStatus(calculation);
                }
                for (const page of formattedRows) {
                    for (const row of page) {
                        if (row.label === 'Offertenummer' || row.label === 'Offer') {
                            row.content = result.data.data.number
                        }
                    }
                }

                updateQuoteStorage(result.data.data.id, formattedRows, result.data.data.number, currentTarget);

            }).catch((reason) => {
                console.error(reason);
                toast.error(`Er is iets mis gegaan bij het opslaan van de offerte. ${reason?.response?.status && `(${reason.response.status})`}`)

                // re-enable button
                currentTarget.disabled = false;
            });
        }
    }

    async function getPriceFromDataRows(rows, object = false) {
        let price = object ? {} : [];

        for (const row of rows) {
            if (row.type !== undefined && (row.type === 'price' || row.type === 'HJMGPrice')) {

                // Validate price row
                if(row.calculationId === undefined) continue;

                if (object) {
                    // Add calculation object
                    if (price[row.calculationId] === undefined) {
                        price[row.calculationId] = {}
                    }

                    // If Pretore or HJMG price
                    if (row.type === 'price') {

                        if (price[row.calculationId][row.amount] === undefined) {
                            price[row.calculationId][row.amount] = {}
                        }

                        price[row.calculationId][row.amount] = {
                            price: getNumberFromPrice(row.content),
                            currency: getCurrencyFromPriceString(row.content)
                        }
                    }else{
                        price[row.calculationId] = {
                            price: getNumberFromPrice(row.content),
                            currency: getCurrencyFromPriceString(row.content)
                        }
                    }
                } else {
                    price.push({
                        amount: row.amount,
                        price: getNumberFromPrice(row.content),
                        currency: getCurrencyFromPriceString(row.content),
                        calculationId: row.calculationId
                    });
                }
            }
        }

        return price;
    }

    async function updateQuoteStorage(quoteId, reformattedRows, number, currentTarget) {
        const base64 = await new QuotePDFGenerator(selectedBackground, formattedRows, company, chosenContactPerson, owner, false, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, activeSender?.value).getPDF()

        let formattedForStorage = [];
        for (const pageKey in reformattedRows) {
            const page = reformattedRows[pageKey];
            for (const row of page) {
                formattedForStorage.push({
                    title: row.label,
                    content: row.content,
                    page: pageKey,
                    type: row.type,
                    calculationId: row.calculationId,
                    amount: row.amount
                })
            }
        }
        let copiedFooter = {}
        if (copy) {
            copiedFooter = footerData.map((el) => {
                return {
                    label: el.label,
                    content: el.content
                }
            })
        }
        const data = {
            ...quote,
            placeOfOrigin: placeOfOrigin,
            introLine: introLine,
            footerData: copy ? copiedFooter : footerData,
            data: formattedForStorage,
            pdfTemplate: quote.template,
            pdfBase64: base64,
            description: description
        }

        // If copy or revision remove ids from footerdata
        if(revision || copy){
            for(const footerDataItem of data.footerData){
                delete footerDataItem.id;
            }
        }

        // Update quote
        authAxios.put(`/quotes/${quoteId}`, {data: data}).then(() => {
            navigate(`/quotes/${quoteId}`)
            toast.success('Offerte opgeslagen');
        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het opslaan van de offerte. ${reason?.response?.status && `(${reason.response.status})`}`)

            // re-enable button
            currentTarget.disabled = false;
        });
    }

    async function setCalculationStatus(calcId = undefined, status = "offerte") {

        if (!(calcId ?? params.calculationId)) {

            if (process.env.NODE_ENV === 'development') {
                toast.error('Geen calculatie id gevonden! Check de console voor meer informatie.');
            }

            console.error('No calculation id found!', {
                calcId: calcId,
                params: params,
                status: status
            });
            return;
        }

        await authAxios.put(`calculations/${calcId ?? params.calculationId}`, {
            data: {
                status: status
            }
        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het updaten van de status. ${reason?.response?.status && `(${reason.response.status})`}`)
        });
    }

    return (
        <>
            <Block name={'quoteSettings'} title={'Offerte Toevoegen'} headerRightSideChildren={
                <>
                    <HasRole roles={['admin']}>
                        <Button className={'btn btn--icon-right'} onClick={getQuotePDF}>Bekijk PDF <Icon name={'eye'}/></Button>
                    </HasRole>
                    <Button className={'btn btn--round btn--black'} onClick={(e) => saveOrUpdateQuote(e)}><Icon name={'save'}/></Button>
                </>
            }>
                <Grid columns={2} customColTemplate={width > 1000 ? '3fr 1fr' : '1fr'}>
                    <span>
                        <Grid columns={2} customColTemplate={width > 700 ? '500px 500px' : '1fr'} style={{alignItems: "start"}}>
                            <IF condition={company !== null && contactPerson !== null}>
                                <Grid columns={2} customColTemplate={width > 400 ? "150px 1fr" : '1fr'} customRowTemplate={width > 400 ? "repeat(3,38px)" : '1fr'} style={{maxWidth: "500px", alignItems: "center"}} gap={"5px"}>
                                    <span>Bedrijf:</span>
                                    <Link to={`/crm/companies/${company?.id}`}>{company?.name}</Link>
                                    <span style={{marginTop: width <= 400 ? '10px' : ''}}>Contact persoon:</span>
                                    <Link to={`/crm/companies/${company?.id}/contactpersons/${contactPerson?.id}`}>{`${contactPerson?.firstName} ${(contactPerson?.prefixToSurname ?? '')} ${contactPerson?.surname}`.replace("  ", " ")}</Link>
                                    <span style={{marginTop: width <= 400 ? '10px' : ''}}>Template:</span>
                                    <span>{template?.name ?? "Geen"}</span>
                                </Grid>
                            </IF>
                            <Grid columns={1} gap={"5px"} className={'quote-settings'}>
                                <Grid columns={2} customColTemplate={width > 400 ? "150px 1fr" : '1fr'} style={{alignItems: "center"}} gap={"5px"}>
                                    <span>Calculatie(s): </span>
                                    <Select value={quote.calculations.map(id => calculations.find(cal => cal.value === id))} isDisabled={quote.company === null} isMulti id="calculationSelect" name={"calculation"} options={calculations} onChange={(values) => updateQuote('calculations', values.map(option => option.value))}/>
                                </Grid>
                                <Grid columns={2} customColTemplate={width > 400 ? "150px 1fr" : '1fr'} style={{alignItems: "center"}} gap={"5px"}>
                                    <span style={{marginTop: width <= 400 ? '10px' : ''}}>Status:</span>
                                    <Select value={_QUOTE_STATUSES.find(status => status.value === quote.status)} id="statusSelect" name={"status"} options={_QUOTE_STATUSES} onChange={option => updateQuote('status', option.value)}/>
                                </Grid>
                                <HasRole roles={allowedSendersByRole}>
                                    <Grid columns={2} customColTemplate={width > 400 ? "150px 1fr" : '1fr'} style={{ alignItems: "center" }} gap={"5px"}>
                                        <span style={{ marginTop: width <= 400 ? '10px' : '' }}>Afzender:</span>
                                        <Select id="senderSelect" value={activeSender} options={senders} name={"sender"} onChange={option => setActiveSender(option)} />
                                    </Grid>
                                </HasRole>
                            </Grid>
                        </Grid>
                    </span>
                    <Grid columns={1}>
                        <span>
                            Omschrijving:
                            <textarea maxLength={255} value={description ?? ''} onChange={(e) => {
                                setDescription(e.target.value)
                            }} style={{height: '108px', minHeight: '108px', marginBottom: 0}}/>
                        </span>
                    </Grid>
                </Grid>
            </Block>
            <Block name={'quoteEditor'}>
                <QuotePdfEditor
                    owner={owner}
                    price={price}
                    setPrice={setPrice}
                    selectedBackground={selectedBackground}
                    language={language}
                    date={date}
                    setDate={setDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    chosenSupplier={company}
                    chosenContactPerson={chosenContactPerson}
                    rowData={rowData}
                    setRowData={setRowData}
                    setFormattedRows={setFormattedRows}
                    formattedRows={formattedRows}
                    pages={pages}
                    setPages={setPages}
                    signature={signature}
                    placeOfOrigin={placeOfOrigin}
                    setPlaceOfOrigin={setPlaceOfOrigin}
                    introLine={introLine}
                    setIntroLine={setIntroLine}
                    footerData={footerData}
                    setFooterData={setFooterData}
                    footerText={footerText}
                    setFooterText={setFooterText}
                    sender={activeSender?.value}
                />
            </Block>
        </>
    )
}
