import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchContext } from 'App/Strapi/FetchContext';
import { toast } from 'react-toastify';
import Block from "UI/App/Components/Block/Block";
import Table from "UI/App/Components/Table/Table";
import Button from 'UI/App/Components/Button/Button';
import Icon from 'UI/App/Components/Icon/Icon';
import ConfirmationPopup from 'UI/App/Components/Popup/Popups/Util/ConfirmationPopup';
import { openPopup } from 'UI/App/Components/Popup/Popup';
export default function Terminals() {

    const { authAxios } = useContext(FetchContext);
    const [tableData, setTableData] = useState([]);
    const [popupData, setPopupData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        void load();
    }, [])
    async function load() {
        authAxios
            .get(`../terminals`)
            .then(({ data }) => {
                // Set data
                const tableData = [];
                for (const terminal of data.data)
                    tableData.push({
                        attributes: {
                            onClick: (e) => {
                                const url = `${terminal.id}`;
                                navigate(url);
                            }
                        },
                        data: [
                            terminal.name,
                            terminal.macAddress,
                            terminal.publicIP,
                            <div onClick={(e) => {
                                e.stopPropagation();
                            }}>
                                <Button className={'btn btn--transparent'} style={{ paddingLeft: "10px" }} onClick={(e) => { setPopupData(terminal.id); openPopup("confirmationPopup") }} ><Icon name="trash-can" /></Button>
                            </div>
                        ]
                    })

                setTableData(tableData)
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

                console.error(exception);
            });
    }
    async function deleteTerminal(e) {
        authAxios.delete(`../terminals/${popupData}`).then(({ data }) => {
            toast.success("Terminal verwijderd")
            load();
        }).catch((exception) => {
            toast.error(
                `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                } `
            );

            console.error(exception);
        });
    }
    return (
        <>
            <ConfirmationPopup
                title="Verwijderen"
                subject="Terminal verwijderen"
                description="Weet u zeker dat u de terminal wilt verwijderen?"
                functionOnConfirm={deleteTerminal}
            />
            <Block name={"terminalTableBlock"} headerRightSideChildren={<Button onClick={(e) => navigate("create")} >Terminal toevoegen</Button>}>
                <Table
                    headers={[
                        'Naam',
                        'Mac address',
                        'Publiek ip',
                        '',
                    ]}
                    structure={{

                        default: ['33%', '33%', '33%', '120px']
                    }}
                    data={tableData}
                />
            </Block>
        </>
    )

}