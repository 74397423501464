import Icon from "UI/App/Components/Icon/Icon";
import Sidebar from "UI/App/Components/Sidebar/Sidebar";
import React from "react";

function ChecklistSidebar({ checklist, language = 'nl' }) {
    const [openSidebar, setOpenSidebar] = React.useState(false);

    if (!Array.isArray(checklist) || checklist.length === 0 || !checklist.some(v => Boolean(v.value))) {
        return null;
    }

    return (
        <div className="checklist-sidebar-container" style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
            <button className="btn--round" onClick={() => setOpenSidebar(true)}>
                <Icon name='list-check'></Icon>
            </button>

            <Sidebar
                sidebarID="checklistSidebar"
                position="right"
                title="Checklist"
                closeable={true}
                isOpen={openSidebar}
                setIsOpen={(state) => setOpenSidebar(state)}
            >
                <ul style={{listStyleType: 'none'}} className="checklist">
                    {checklist.filter(i => Boolean(i.value)).map((listItem) => (
                        <li key={listItem.name}>
                            <span className="name">{(language === 'nl' ? listItem.dutchName : listItem.englishName) ?? listItem.name}</span>:
                            <span className="value">{listItem.value}</span>
                        </li>
                    ))}
                </ul>
            </Sidebar>
        </div>
    )
}

export default React.memo(ChecklistSidebar);