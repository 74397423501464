import { formatDateWithoutTime } from 'App/Util/format';
import PDF, {
    FONT_SIZE,
    LINE_HEIGHT_FACTOR,
    LINE_HEIGHT,
    PADDING_TOP,
    PADDING_LEFT,
    PADDING_RIGHT,
    TEXT_LINE_MAX_WIDTH,
    START_Y
} from 'App/Util/print';
import translate from 'App/Util/translate';
import { backgroundTopRightBoxSizes } from 'UI/App/Components/PDF/PDFMockup';

declare type TPdfChecklistItem = {
    id?: string | number;
    name: string;
    type?: string;
    value: string;
};
declare type TPdfAddress = {
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    postalCode: string | null;
    city: string | null;
    province: string | null;
    country: string | null;
};
declare type TPdfData = {
    language: 'nl' | 'en';
    template: number;
    backgroundImage: string;
    backgroundImage2: string;
    slipNumber: string;
    companyName: string;
    contactPersonName: string;
    address: TPdfAddress;
    locationInput: string;
    dateInput: string;
    customerReference: string;
    projectNumber: string;
    checklist: Array<TPdfChecklistItem>;
    totalAmount: string | number;
    boxesAmount: string | number;
    palletsAmount: string | number;
};

export default function getSlipPdf(pdfData: TPdfData, returnBase64: boolean = false) {
    // create PDF with jsPDF
    const pdf = new PDF(undefined, undefined, pdfData?.backgroundImage, pdfData?.backgroundImage2);

    // (should be moved to the PDF class constructor for the rewrite)
    pdf.setFontSize(FONT_SIZE);
    pdf.setLineHeightFactor(LINE_HEIGHT_FACTOR);

    // add templates
    if ((pdfData?.template ?? 1) === 1) {
        fillPageTemplate1(pdf, pdfData);
    } else if (pdfData?.template === 2) {
        fillPageTemplate2(pdf, pdfData);
    }

    if (returnBase64) {
        return window.btoa(pdf.output());
    }

    pdf.openPDF();
}

/**
 * PDF generation for template `1`.
 *
 * @param {PDF} pdf
 * @param {TPdfData} pdfData
 */
function fillPageTemplate1(pdf: PDF, pdfData: TPdfData): void {
    // title
    pdf.setFontStyle('bold');
    // if (pdfData?.slipNumber) {
    //     pdf.addText(
    //         `${translate('delivery note', pdfData?.language).toUpperCase()} - ${pdfData.slipNumber}`,
    //         PADDING_LEFT,
    //         pdf.currentY
    //     );
    // } else {
    pdf.addText(translate('delivery note', pdfData?.language).toUpperCase(), PADDING_LEFT, pdf.currentY);
    // }
    pdf.setFontStyle('normal');

    // +2 line breaks
    pdf.currentY += LINE_HEIGHT * 2;

    // company name
    pdf.addText(pdfData?.companyName, PADDING_LEFT, pdf.currentY);

    // contact person
    pdf.addText(pdfData?.contactPersonName, PADDING_LEFT, pdf.currentY);

    // address
    pdf.addText(pdfData?.address?.addressLine1, PADDING_LEFT, pdf.currentY);
    pdf.addText(pdfData?.address?.addressLine2, PADDING_LEFT, pdf.currentY);
    pdf.addText(pdfData?.address?.addressLine3, PADDING_LEFT, pdf.currentY);
    pdf.addText(pdfData?.address?.postalCode + `\u00A0\u00A0` + pdfData.address.city, PADDING_LEFT, pdf.currentY);
    pdf.addText(pdfData?.address?.country, PADDING_LEFT, pdf.currentY);

    // +2 line breaks
    pdf.currentY += LINE_HEIGHT * 2;

    // location + date
    pdf.addText(
        pdfData?.locationInput + ',\u00A0' + formatDateWithoutTime(pdfData?.dateInput),
        PADDING_LEFT,
        pdf.currentY
    );

    // +2 line breaks
    pdf.currentY += LINE_HEIGHT * 2;

    // customer reference
    addKeyValueLine(
        pdf,
        translate('reference', pdfData?.language, true),
        pdfData?.customerReference,
        45,
        undefined,
        true
    );

    // project number
    addKeyValueLine(pdf, 'Order', pdfData?.projectNumber, 45, undefined, true);

    // checklist
    for (const checklistItem of pdfData?.checklist ?? []) {
        const textLines = pdf.splitTextToSize2(checklistItem?.value, TEXT_LINE_MAX_WIDTH);

        // calculate if section needs to go to the next page
        const currentYBefore = pdf.currentY;
        const newY = pdf.addNewPageIfOverflown(
            pdf.currentY + LINE_HEIGHT * (textLines.length + 1),
            PADDING_TOP,
            START_Y
        );
        if (pdf.currentY > newY) {
            pdf.currentY = newY;
        } else {
            pdf.currentY = currentYBefore;
        }

        addKeyValueLine(pdf, checklistItem?.name ?? '', checklistItem?.value ?? '', 45, undefined, true);
    }

    // check if adding the 2 linebreaks + 3 lines below would overflow the page
    if (!pdf.addPageIfTextWillOverflow(5)) {
        // if no page added: add our 2 seperation linebreaks
        pdf.currentY += LINE_HEIGHT * 2;
    }
    addKeyValueLine(
        pdf,
        translate('copies amount', pdfData?.language, true),
        `${pdfData?.totalAmount || '__________'}`,
        45,
        undefined,
        true
    );
    addKeyValueLine(
        pdf,
        translate('number of boxes', pdfData?.language, true),
        `${pdfData?.boxesAmount || '__________'}`,
        45,
        undefined,
        true
    );
    addKeyValueLine(
        pdf,
        translate('number of pallets', pdfData?.language, true),
        `${pdfData?.palletsAmount || '__________'}`,
        45,
        undefined,
        true
    );

    // check if adding the 2 linebreaks + signature + 3 linebreaks + line
    if (!pdf.addPageIfTextWillOverflow(7)) {
        // if no page added: add our 2 seperation linebreaks
        pdf.currentY += LINE_HEIGHT * 2;
    }
    // signature
    pdf.addText(translate('receipt signature', pdfData?.language, true) + ':', PADDING_LEFT, pdf.currentY);
    // +3 line breaks (= 4 lines between)
    pdf.currentY += LINE_HEIGHT * 3;
    pdf.line(PADDING_LEFT, pdf.currentY, PADDING_RIGHT * 0.4, pdf.currentY);
}

/**
 * PDF generation for template `2`.
 *
 * @param {PDF} pdf
 * @param {TPdfData} pdfData
 */
function fillPageTemplate2(pdf: PDF, pdfData: TPdfData) {
    pdf.setPageStartLineBreaks(7);

    // title
    pdf.setFontStyle('bold');
    // if (pdfData?.slipNumber) {
    //     pdf.addText(
    //         `${translate('delivery note', pdfData?.language).toUpperCase()} - ${pdfData.slipNumber}`,
    //         PADDING_LEFT,
    //         pdf.currentY,
    //         {
    //             maxWidth:
    //                 TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    //         }
    //     );
    // } else {
    pdf.addText(translate('delivery note', pdfData?.language).toUpperCase(), PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });
    // }
    pdf.setFontStyle('normal');

    // +2 line breaks
    pdf.currentY += LINE_HEIGHT * 2;

    // company name
    pdf.addText(pdfData?.companyName, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });

    // contact person
    pdf.addText(pdfData?.contactPersonName, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });

    // address
    pdf.addText(pdfData?.address?.addressLine1, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });
    pdf.addText(pdfData?.address?.addressLine2, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });
    pdf.addText(pdfData?.address?.addressLine3, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });
    pdf.addText(pdfData?.address?.postalCode + `\u00A0\u00A0` + pdfData.address.city, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });
    pdf.addText(pdfData?.address?.country, PADDING_LEFT, pdf.currentY, {
        maxWidth: TEXT_LINE_MAX_WIDTH - parseInt(backgroundTopRightBoxSizes?.[pdfData?.backgroundImage]?.width ?? '0')
    });

    // +2 line breaks
    pdf.currentY += LINE_HEIGHT * 2;

    // location + date
    pdf.addText(
        pdfData?.locationInput + ',\u00A0' + formatDateWithoutTime(pdfData?.dateInput),
        PADDING_LEFT,
        pdf.currentY
    );

    // +2 line breaks
    pdf.currentY += LINE_HEIGHT * 2;

    // customer reference
    addKeyValueLine(
        pdf,
        translate('reference', pdfData?.language, true),
        pdfData?.customerReference,
        45,
        undefined,
        true
    );

    // project number
    addKeyValueLine(pdf, 'Order', pdfData?.projectNumber, 45, undefined, true);

    // checklist
    for (const checklistItem of pdfData?.checklist ?? []) {
        const textLines = pdf.splitTextToSize2(checklistItem?.value, TEXT_LINE_MAX_WIDTH);

        // calculate if section needs to go to the next page
        const currentYBefore = pdf.currentY;
        const newY = pdf.addNewPageIfOverflown(
            pdf.currentY + LINE_HEIGHT * (textLines.length + 1),
            PADDING_TOP,
            START_Y
        );

        if (pdf.currentY > newY) {
            pdf.currentY = newY + LINE_HEIGHT * 7 + LINE_HEIGHT;
        } else {
            pdf.currentY = currentYBefore;
        }

        addKeyValueLine(pdf, checklistItem?.name ?? '', checklistItem?.value ?? '', 45, undefined, true);
    }

    // check if adding the 2 linebreaks + 3 lines below would overflow the page
    if (!pdf.addPageIfTextWillOverflow(5)) {
        // if no page added: add our 2 seperation linebreaks
        pdf.currentY += LINE_HEIGHT * 2;
    }
    addKeyValueLine(
        pdf,
        translate('copies amount', pdfData?.language, true),
        `${pdfData?.totalAmount || '__________'}`,
        45,
        undefined,
        true
    );
    addKeyValueLine(
        pdf,
        translate('number of boxes', pdfData?.language, true),
        `${pdfData?.boxesAmount || '__________'}`,
        45,
        undefined,
        true
    );
    addKeyValueLine(
        pdf,
        translate('number of pallets', pdfData?.language, true),
        `${pdfData?.palletsAmount || '__________'}`,
        45,
        undefined,
        true
    );

    // check if adding the 2 linebreaks + signature + 3 linebreaks + line
    if (!pdf.addPageIfTextWillOverflow(7)) {
        // if no page added: add our 2 seperation linebreaks
        pdf.currentY += LINE_HEIGHT * 2;
    }
    // signature
    pdf.addText(translate('receipt signature', pdfData?.language, true) + ':', PADDING_LEFT, pdf.currentY);
    // +3 line breaks (= 4 lines between)
    pdf.currentY += LINE_HEIGHT * 3;
    pdf.line(PADDING_LEFT, pdf.currentY, PADDING_RIGHT * 0.4, pdf.currentY);
}

/**
 * todo: refactor into proper reusable function as method of class `PDF`.
 *
 * @param {PDF} pdf
 * @param {string} key
 * @param {string} value
 * @param {number} [offset]
 * @param {number} [maxValueWidth=TEXT_LINE_MAX_WIDTH]
 * @param {boolean} [keyBold=false]
 */
function addKeyValueLine(
    pdf: PDF,
    key: string,
    value: string,
    offset?: number,
    maxValueWidth: number = TEXT_LINE_MAX_WIDTH,
    keyBold: boolean = false
) {
    key = key.trim();
    value = value.trim();
    offset = offset ?? 0;

    // make key bold before splitting!
    if (keyBold) pdf.setFontStyle('bold');

    // split long text into array chunks by linebreaks and maxWidth
    const keyLines = pdf.splitTextToSize(key, offset);
    pdf.text(keyLines, PADDING_LEFT, pdf.currentY, {
        maxWidth: offset
    });
    pdf.setFontStyle('normal');

    // split long text into array chunks by linebreaks and maxWidth
    const valueLines = pdf.splitTextToSize(value, maxValueWidth - (offset - 2.5));
    if (key !== '') {
        pdf.text(':', PADDING_LEFT + offset, pdf.currentY, {
            maxWidth: maxValueWidth - offset
        });
    }
    pdf.text(valueLines, PADDING_LEFT + offset + 2.5, pdf.currentY, {
        maxWidth: maxValueWidth - (offset - 2.5)
    });

    // set yPostition to the highest lines
    pdf.currentY += LINE_HEIGHT * Math.max(keyLines.length, valueLines.length);
}
