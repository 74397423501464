import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Grid from 'UI/App/Components/Grid/Grid';
import Block from 'UI/App/Components/Block/Block';
import Button from 'UI/App/Components/Button/Button';
import { formatCurrency, formatDate } from 'App/Util/format';
import Container from 'UI/App/Components/Container/Container';
import Icon from 'UI/App/Components/Icon/Icon';
import MachineDetail from './MachineDetail';
import Table from 'UI/App/Components/Table/Table';
import AddMachineIssuePopup from 'UI/App/Components/Popup/Popups/Machines/AddMachineIssue';
import { openPopup } from 'UI/App/Components/Popup/Popup';
import ConfirmationPopup from 'UI/App/Components/Popup/Popups/Util/ConfirmationPopup';

export default function MachineView() {
    // create a new fetch context for fetching the machine view
    const { authAxios } = useContext(FetchContext);

    // get the machine view id from the url params
    const { machineId } = useParams();

    // create a state for the machine view data
    const [machine, setMachine] = useState({});
    const [malfunctionTable, setMalfunctionTable] = useState([]);
    const [knownIssues, setKnownIssues] = useState([])
    const [editableIssue, setEditableIssue] = useState(undefined);
    const [deleteIssueID, setDeleteIssueID] = useState(undefined);

    // fetch the machine based on the id
    useEffect(() => {
        if (machineId) {
            const query = stringify({
                populate: {
                    operators: {
                        fields: ['username'],
                        sort: ['username:ASC']
                    },
                    malfunctions: {
                        populate: {
                            reportedBy: {
                                fields: ["username"]
                            },
                            resolvedBy: {
                                fields: ["username"]
                            }
                        }
                    }
                }
            });

            authAxios
                .get(`/calculations/resources/machines/${machineId}?${query}`)
                .then(({ data }) => {
                    // set the machine
                    setMachine(data);
                    setKnownIssues(data?.malfunctions)
                    setTableData(data?.malfunctions)
                })
                .catch((error) => {
                    toast.error('Kon machine niet ophalen');
                    console.error(error);
                });


        }
    }, [authAxios, machineId]);

    useEffect(() => {
        setTableData(machine.malfunctions)
    }, [machine.malfunctions]);

    function setTableData(data) {
        let tableData = [];

        // Add guard clause
        if(data === undefined) return

        for (let malfunction of data) {
            let timeBetween = '-';
            if(malfunction?.resolvedAt !== null){
                const timeInMinutes = Math.round((new Date(malfunction?.resolvedAt).getTime() - new Date(malfunction?.reportedAt).getTime())/ 1000 / 60);

                if(timeInMinutes <= 60){
                    timeBetween = `${timeInMinutes} minuten`
                }else if(timeInMinutes < 1440){
                    timeBetween = `${Math.round(timeInMinutes / 60)} uur`
                }else {
                    timeBetween = `${Math.round(timeInMinutes / 1440)} dag(en)`
                }
            }

            tableData.push({
                data: [
                    `${formatDate(malfunction?.reportedAt)}`,
                    `${malfunction?.resolvedAt === null ? "" : formatDate(malfunction?.resolvedAt)}`,
                    timeBetween,
                    `${malfunction?.reportedIssue}`,
                    `${malfunction?.issueSolution ?? ""}`,
                    `${malfunction?.reportedBy?.username}`,
                    `${malfunction?.resolvedBy?.username ?? ""}`,
                    <>
                        <Button className={'btn btn--transparent'} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openPopup('add-machine-malfunction')
                            // setEditIssueId(malfunction?.id)
                            setEditableIssue(malfunction);
                        }}>
                            {malfunction?.resolvedAt === null ?
                                <Icon

                                    style={{ color: "var(--black)" }}
                                    name={"triangle-exclamation"}
                                />
                                :
                                <Icon
                                    name={"pencil"}
                                />
                            }

                        </Button>
                        <Button className={'btn btn--transparent'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                setDeleteIssueID(malfunction?.id)
                                openPopup("confirmationPopup")
                            }}><Icon name="trash-can" /></Button>
                    </>
                ],

            })
        }

        setMalfunctionTable(tableData)
    }

    async function deleteMalfunctionEntry() {
        if (deleteIssueID === undefined) return

        // Remove malfunction by id from machine.malfunctions
        machine.malfunctions = machine.malfunctions.filter((malfunction) => malfunction.id !== deleteIssueID)

        // query to pop the return data
        const query = stringify({
            populate: {
                malfunctions: {
                    populate: {
                        reportedBy: {
                            fields: ["username"]
                        },
                        resolvedBy: {
                            fields: ["username"]
                        }
                    }
                }
            }
        });

        authAxios.put(`/calculations/resources/machines/${machineId}?${query}`, {
            data:machine
        }).then(({ data }) => {
            toast.success("Foutmelding verwijderd")

            setMachine(data.data)
            // setKnownIssues(data.data.malfunctions)
        }).catch((exception) => {
            toast.error("Er ging wat fout met het verwijderen van de foutmelding")
            console.error(exception);
        })
    }

    return (
        <Container>
            <Block
                name='machine__details'
                title={
                    <>
                        <Link className='btn btn--transparent' to='/calculations/settings'>
                            <Icon name='arrow-left' />
                        </Link>
                        {machine.name}
                    </>
                }
                headerRightSideChildren={
                    <>
                        <i style={{ marginRight: '10px' }}>
                            <b>Laatst bijgewerkt:</b>
                            <span style={{ marginLeft: '10px' }}>{formatDate(machine?.updatedAt)}</span>
                        </i>

                        <Button
                            to={`/calculations/settings/machines/${machine?.id}/update`}
                            className='btn btn--black btn--round'
                        >
                            <Icon name='pencil' />
                        </Button>
                    </>
                }
            >
                <Grid columns='2'>
                    <div className='detail__column'>
                        <MachineDetail
                            key='name'
                            title='Machine'
                            content={[
                                <><b>Naam:</b> {machine.name}</>,
                                machine.department && (
                                    <><b>Afdeling:</b> {machine.department}</>
                                )
                            ]}
                        />
                        <MachineDetail
                            key='machine'
                            title='Machine'
                            content={[
                                <><b>Aanschafwaarde:</b> {formatCurrency(machine?.initialCost)}</>,
                                <><b>Rest waarde:</b> {formatCurrency(machine?.residualValue)}</>,
                                <><b>Afschrijving jaren:</b> {machine?.depreciationYears}</>,
                                <><b>Service kosten:</b> {formatCurrency(machine?.serviceCost)}</>,
                                <><b>Jaarlijkse afschrijving:</b> {formatCurrency(machine?.yearlyDepreciation)}</>
                            ]}
                        />
                        <MachineDetail
                            key='boeken'
                            title='Productie'
                            content={[
                                <><b>Productie uurtarief:</b> {formatCurrency(machine?.hourlyRate)}</>,
                                <><b>Bezettingsuren per jaar:</b> {machine?.occupationalHoursPerYear}</>,
                                <><b>Bruto snelheid per uur:</b> {machine?.grossSpeed} {machine?.speedUnit}</>,
                                <><b>Uptime:</b> {machine?.uptime}%</>,
                                <><b>Netto snelheid per uur</b> {machine?.netSpeedPerHour} {machine?.speedUnit}</>,
                                <><b>Opstart tijd in minuten:</b> {machine?.startupTime}</>,
                                <><b>Opstart kosten:</b> {formatCurrency(machine?.startupCost)}</>
                            ]}
                        />
                    </div>
                    <div className='detail__column'>
                        <MachineDetail
                            key='labor'
                            title='Arbeid'
                            content={[
                                <><b>Arbeidsinspanning</b> {machine?.laborEffort}%</>,
                                <><b>Arbeidsuren:</b> {machine?.laborHours}</>,
                                <><b>Uurloon Personeel:</b> {formatCurrency(machine?.employeeWage)}</>
                            ]}
                        />
                        <MachineDetail
                            key='operators'
                            title='Operators'
                            content={(machine?.operators ?? []).map((operator) => {
                                return operator.username;
                            })}
                        />
                    </div>
                </Grid>

            </Block>
            <br />
            <AddMachineIssuePopup
                machineId={machineId}
                editableIssue={editableIssue}
                setEditableIssue={setEditableIssue}
                machine={machine}
                setMachine={setMachine}
            />
            <ConfirmationPopup
                title="Verwijderen"
                subject="Foutmelding verwijderen"
                description="Weet u zeker dat u de melding wilt verwijderen?"
                functionOnConfirm={deleteMalfunctionEntry}
            />
            <Table
                headers={['Datum', 'Datum Afgemeld', 'Duur', 'Storing', 'Oplossing', 'Aangemaakt door', 'Afgemeld door', <>
                <button
                    className="btn btn--black"
                    onClick={(e) => {
                        setEditableIssue(null);
                        openPopup('add-machine-malfunction') }} >
                    <Icon name="plus"
                    /></button>
                </>]}
                data={malfunctionTable}
                structure={['200px', '200px', '120px', '100%', '100%', '200px', '200px', '100px']}
                hover
                overflowText
            />
        </Container>
    );
}
