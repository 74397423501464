import Table from "UI/App/Components/Table/Table";
import {useEffect, useState} from "react";
import {formatCurrency, formatNumberValue, tryParse} from "App/Util/format";

export default function PurchaseResultBlock({purchaseData, activeTab}) {
    const [purchaseResult, setPurchaseResult] = useState([]);

    useEffect(() => {
        if (activeTab === 'result') {
            setPurchaseResult(getPurchaseResult());
        }
    }, [activeTab]);

    useEffect(() => {
        if (purchaseData) {
            setPurchaseResult(getPurchaseResult(true, purchaseData));
        }
    }, [purchaseData]);

    if (!Array.isArray(purchaseResult) || (Array.isArray(purchaseResult) && purchaseResult.length === 0)) {
        return null;
    }

    return (
        <div className={'hjmg__calculation__result-block'}>
            <h3>Inkoop</h3>
            <Table
                headers={['Omschrijving', 'Kosten', 'Totaal']}
                data={purchaseResult}
            />
        </div>
    )
}

export function getPurchaseResult(showTotal = true, purchaseData = null) {
    const data = purchaseData ?  purchaseData : getPurchaseData();

    const result = data.entries.map((entry) => [
        entry.description,
        formatCurrency(entry.cost),
        `${formatCurrency(entry.cost)} x ${formatNumberValue(entry.surtax, 'procent')} = ${formatCurrency(entry.cost * (entry.surtax / 100 + 1))}`
    ])

    if (showTotal && result.length > 0) {
        result.push([
            <strong>Totaal</strong>,
            <strong>{formatCurrency(data.totalCost)}</strong>,
            <strong>{formatCurrency(data.totalWithSurtax)}</strong>
        ])
    }

    return result;
}

export function getPurchaseData() {
    const entries = document.querySelectorAll('.werk-derden-entry');

    let totalCost = 0;
    let totalWithSurtax = 0;

    const result = Array.from(entries).map((entry) => {
        const data = tryParse(entry.dataset.json);

        totalCost += data.quantities.cost;
        totalWithSurtax += data.quantities.cost * (data.quantities.surtax / 100 + 1);

        return data;
    });

    return {
        totalCost,
        totalWithSurtax,
        entries: result.map(entry => ({
            description: entry.description,
            cost: entry.quantities.cost,
            surtax: entry.quantities.surtax,
        }))
    };
}