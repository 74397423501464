export function getPropByPageWidth(props: string[]|number[]|string|number|Record<string|number, any>, pageWidth: number) {
    if (Array.isArray(props) || typeof props === 'string' || typeof props === 'number') {
        return props;
    } else if (props) {
        let newStructure = props['default'] ?? [];
        Object.entries(props)
            .sort((a,b) => Number(b[0]) - Number(a[0]))
            .forEach(([key, value]) => {
                if (pageWidth < Number(key)) {
                    newStructure = value;
                }
            });

        return newStructure;
    } else {
        return [];
    }
}