import {splitCamelCase} from "App/Util/format";
import React, {ReactElement} from "react";
import {TrenderType} from "./WidgetTypes";
import {toast} from "react-toastify";
import IWidget from "./IWidget";

declare type TSelectOptions = Array<{
    label: string;
    value?: string | number;
    options?: Array<{
        label: string;
        value: string;
    }>
}>

export default class WidgetBase {
    /**
     * Render type
     */
    public renderType?: TrenderType;
    public InnerHTML?: ReactElement | any;
    readonly _uniqueId: string | null = null;

    public editData: object | null = null;
    public machineId: number | null = null;
    public optionalWidgetData: {} | undefined = undefined;
    public prefix: string | undefined;

    public visibilityCheckbox: boolean | undefined;

    public static getSelectedValues(values: TSelectOptions, ...selectedValues: any[]): Array<{ value: string; label: string }> | false {
        if (!Array.isArray(values))
            return false;

        let selectedOptions: Array<{ value: string; label: string }> = [];

        for (const i in selectedValues) {
            const value = selectedValues[i];

            const valueInValuesArray = getValueFromValuesArray(value, values);

            if (valueInValuesArray !== false) {
                selectedOptions[i] = valueInValuesArray;
                continue;
            }

            if (value !== undefined && value !== null && value !== "") {
                selectedOptions[i] = {value: selectedValues[i], label: selectedValues[i]};
                delete selectedValues[i];
            }
        }

        function getValueFromValuesArray(selectedValue: any, values: TSelectOptions): { label: string, value?: string | number| undefined }|false {
            for (const value of values) {
                if (value.options !== undefined) {
                    for (const subValue of value.options) {
                        if (selectedValue == subValue.value) {
                            return subValue;
                        }
                    }
                } else {
                    if (selectedValue == value.value) {
                        return value;
                    }
                }
            }

            return false;
        }

        return selectedOptions;
    }

    /**
     * Returns json data from widgetID
     * @param widgetId
     */
    public getResultFromWidget(widgetIdwithField: string, prefix?: string): number | string | boolean {
        return getResultFromWidget(widgetIdwithField, prefix);
    }

    /**
     * Gets the values from the widgets
     */
    public getWidgetValues(widgetTypeFilter: null|Array<string> = null): TSelectOptions {
        return getWidgetValues(widgetTypeFilter);
    }

    /**
     * Creates unique id for widgets
     */
    public getUniqueId(): string {
        return (this.prefix !== undefined ? this.prefix + '-' : '') + "widget-" + this.getNewKey(this.widgetsById());
    }

    /**
     * Adds widget to widget container
     * @param widgetContent
     * @param widgetData
     */
    public addWidgetToWidgetContainer(widgetContent: string, widgetData: {}, optionalStyle: string = "", load = false) {
        /**
         * Set optional data
         */
        const id = widgetData.id !== undefined ? widgetData.id : this.getUniqueId();
        const data = widgetData.data !== undefined ? widgetData.data : widgetData;

        // Add crypto uuid if uuid undefined
        if(widgetData?.uuid === undefined){
            widgetData.uuid = crypto.randomUUID()
        }

        // this.removeWidget(id);
        let widgetContainer = document.getElementById('widget--container');
        if (widgetContainer !== null) {
            widgetContainer.innerHTML += `<div id="${id}" uuid="${(widgetData?.uuid || '')}" key="${id}" dataType='widget' widgetdata='${JSON.stringify(data).replaceAll("'", "&apos;")}' class='flow_widget'>
                <span title='bewerk widget' onclick='window.moveWidgetLeft("${id}")'> ‹ </span> 
                <div class='widget__content__wrapper'>
                    <div class="widget__header">
                        <span class='widget__number'>
                            <small>${id}</small>
                            <br/>
                            <small>${(widgetData?.uuid || '')}</small>
                        </span>
                        <div class='widget__actions'>
                            <strong title='bewerk widget' onclick='window.openEditWindow(this)'>✎</strong>
                            <strong title='verwijder widget' onclick='window.removeWidget("${id}")'>&times;</strong>
                        </div>
                    </div>
                    <div class='widget_content'>${widgetContent}</div>
                </div>
                <span title='bewerk widget' onclick='window.moveWidgetRight("${id}")'> › </span>
            </div>`

            /**
             * Scroll to new widget
             */
            // document.getElementById(id)?.scrollIntoView({behavior: "smooth"});

            if (!load) {
                toast.success(`Widget: ${id} Opgeslagen`);
            }
        }
    }

    /**
     *
     * @param widgetContent
     * @param widgetData
     * @param parentId
     */
    public addWidgetToCalculationForm(widgetContent: string, widgetData: {}, parentId: string) {
        let widgetContainer = document.getElementById(parentId);

        const id = widgetData.prefix !== undefined ? widgetData.prefix + widgetData.id : widgetData.id;

        if (widgetContainer !== null) {
            widgetContainer.innerHTML += `<div id="${id}" key="${id}" dataType='widget' widgetdata='${JSON.stringify(widgetData).replaceAll("'", "&apos;")}' class='calculationWidget'>
                <div class='widget_content'>${widgetContent}</div>
            </div>`
        }
    }

    public updateWidget(id: string, widgetContent: string, widgetData: {}, optionalStyle: string = ""): void {
        const element = document.getElementById(id);
        element.querySelector('.widget_content').innerHTML = widgetContent
        element.setAttribute('widgetdata', JSON.stringify(widgetData));

        toast.success(`${id} geüpdate!`);
    }

    /**
     *
     * @param widgetId
     * @param widgetData
     */
    public updateWidgetData(widgetId: string, widgetData: {}) {
        document?.getElementById(widgetId)?.setAttribute('widgetdata', JSON.stringify(widgetData));
    }

    /**
     * Return correct html for render type
     * @param widget
     */
    public callGetCorrectHtml(widget: IWidget) {

        /**
         * Switch between render types to render the correct innerHTML
         */
        switch (widget.renderType) {
            case "preview":
                return widget.getPreviewHTML();
            case "form":
                return widget.getFormHTML();
            case "edit":
                return widget.getEditHTML();
            case "badge":
                return widget.getBadgeHTML();
            case "calculationForm":
                return widget.getCalculationForm();
            case "previewForm":
                return widget.getPreviewFormHTML();
            case "formulaString":
                return widget.getFormulaString();
            default:
                console.error("Cannot render innerHTML for given render type or rendertype has not been set correcly")
                break;
        }
    }

    /**
     * Return the correct html
     * @param widget
     */
    public getHTML(widget: IWidget, editData: object | null = null): React.ReactElement {
        this.editData = editData;
        /**
         * Set InnerHTML for rendering
         */
        widget.InnerHTML = this.callGetCorrectHtml(widget);

        /**
         * Returns render
         */
        return this.render();
    }

    /**
     * Get all data form widget
     */
    public getAllDataFromWidget(widgetId: string) {
        return document.getElementById(widgetId).getAttribute('widgetData');
    }

    /**
     * Renders widget
     */
    public render() {
        if (this.renderType === "preview") {
            return this.renderPreview();
        } else if (this.renderType === "form") {
            return this.renderForm();
        } else if (this.renderType === "badge") {
            return this.renderBadge();
        } else if (this.renderType === "previewForm") {
            return this.renderPreviewForm();
        } else if (this.renderType === "calculationForm") {
            return this.renderForm();
        } else if (this.renderType === "formulaString") {
            return this.renderForm();
        } else if (this.renderType === "edit") {
            return this.renderForm();
        } else if (this.renderType === "editForm") {
            return this.getEditWidgetForm(this.editData);
        }

        /**
         * Throw error on invalid render type
         */
        throw new Error("No valid rendertype set given: " + this.renderType);
    }

    /**
     * Renders badge
     *
     * @return ReactElement
     */
    public renderBadge() {
        return <div>{this.InnerHTML}</div>
    }

    /**
     * Renders preview form
     *
     * @return ReactElement
     */
    public renderPreviewForm() {
        return <div>{this.InnerHTML}</div>
    }

    /**
     * Renders preview
     *
     * @return ReactElement
     */
    public renderPreview() {
        return this.InnerHTML
    }

    /**
     * Renders form
     *
     * @return ReactElement
     */
    public renderForm() {
        return this.InnerHTML
    }

    /**
     * Handles condition for conditional
     */
    handleConditional(data: { type: string, number: string, target: string }): boolean {
        let correct = false;
        /**
         * If math sign use switch else check if target value is set
         */
        if (['>', '<', '=', '>=', '<='].includes(data.type)) {
            switch (data.type) {
                case ">":
                    if (document.getElementById(data.target).value !== undefined) {
                        parseInt(document.getElementById(data.target).value) > parseInt(data.number) ? correct = true : correct = false;
                    } else {
                        JSON.parse(document.getElementById(data.target).getAttribute('widgetdata')).data.value.result > parseInt(data.number) ? correct = true : correct = false;
                    }
                    break;
                case "<":
                    if (document.getElementById(data.target).value !== undefined) {
                        parseInt(document.getElementById(data.target).value) < parseInt(data.number) ? correct = true : correct = false;
                    } else {
                        JSON.parse(document.getElementById(data.target).getAttribute('widgetdata')).data.value.result < parseInt(data.number) ? correct = true : correct = false;
                    }
                    break;
                case "<=":
                    if (document.getElementById(data.target).value !== undefined) {
                        parseInt(document.getElementById(data.target).value) <= parseInt(data.number) ? correct = true : correct = false;
                    } else {
                        JSON.parse(document.getElementById(data.target).getAttribute('widgetdata')).data.value.result <= parseInt(data.number) ? correct = true : correct = false;
                    }
                    break;
                case ">=":
                    if (document.getElementById(data.target).value !== undefined) {
                        parseInt(document.getElementById(data.target).value) >= parseInt(data.number) ? correct = true : correct = false;
                    } else {
                        JSON.parse(document.getElementById(data.target).getAttribute('widgetdata')).data.value.result >= parseInt(data.number) ? correct = true : correct = false;
                    }
                    break;
                case "=":
                    if (document.getElementById(data.target).value !== undefined) {
                        parseInt(document.getElementById(data.target).value) === parseInt(data.number) ? correct = true : correct = false;
                    } else {
                        JSON.parse(document.getElementById(data.target).getAttribute('widgetdata')).data.value.result === parseInt(data.number) ? correct = true : correct = false;
                    }
                    break;
            }
        } else {
            if (data.type !== undefined && data.type !== '') {
                /**
                 * Check if target value is set value
                 */
                return document.getElementById(data.type.split('_')[0]).value === data.type.split('_')[1]
            } else {
                return true;
            }
        }

        return correct;
    }

    protected getWidgetIdWithPrefix(): string {
        return this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
    }

    protected getWidgetElement(): HTMLElement | false {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;

        if (this !== undefined) {

            if (typeof id === undefined) {
                console.error(`Widget id does not exist in optional widget data`);
                return false;
            }

            const widgetElement = document.getElementById(id);

            if (widgetElement === null) {
                console.error(`Widget with id ${id} not found in DOM!`);
                return false;
            }

            return widgetElement;
        }

        return false;
    }

    protected generateUUID(): string {
        return crypto.randomUUID();
    }

    /**
     * Formats and returns widgets by id
     * @private
     */
    private widgetsById() {
        let widgetsById: any = {};
        /**
         * Format widgets
         */
        for (const widget of document.getElementsByClassName('flow_widget')) {
            widgetsById[widget.id] = JSON.parse(widget.getAttribute('widgetdata'));
        }

        return widgetsById;
    }

    /**
     * Helder function to find last key in object
     */
    private getLastId(object: {}) {
        let lastKey: any = false;
        for (const [key, value] of Object.entries(object)) {
            if (lastKey === false || parseInt(key.split('-')[2]) >= parseInt(lastKey.split('-')[2])) {
                lastKey = key
            }
        }
        return lastKey;
    }

    /**
     * Generate new key for widget
     * @param widgetsById
     * @private
     */
    private getNewKey(widgetsById: {}) {
        if(Object.keys(widgetsById).length === 0){
            return 0;
        }
        return parseInt(((this.getLastId(widgetsById)).split('-')[2])) + 1
    }
}

export function getResultFromWidget(widgetIdwithField: any, prefix?: string): number | string | boolean {

    // return number values directly as numbers
    if (/^[0-9]+(?:\.[0-9]+)?$/.test(widgetIdwithField)) {
        const value = parseFloat(widgetIdwithField);
        // if the value is somehow not a number after parsing, treat it as a widget id
        if (!isNaN(value)) return value;
    }

    // If the value is not of type string or does not contain the string "widget"
    if (typeof widgetIdwithField !== 'string' || (!widgetIdwithField.includes('widget') && !widgetIdwithField.includes('const'))) {
        return widgetIdwithField;
    }

    if(!widgetIdwithField.startsWith(prefix)){
        widgetIdwithField = prefix ? prefix + widgetIdwithField : widgetIdwithField;
    }

    const [widgetId, subField] = widgetIdwithField.split('.');
    const widgetElement = document.getElementById(widgetId);

    if (widgetElement !== null) {
        /**
         * Check if widget data isset else check for value
         */
        if (widgetElement?.hasAttribute('widgetdata')) {
            const widgetData = JSON.parse(widgetElement.getAttribute('widgetdata') ?? '');
            /**
             * Check if result is set else return value
             */
            if (widgetData.data?.value?.result !== undefined) {

                if (subField) {
                    return widgetData.data.value.result?.[subField];
                }

                /**
                 * Return calculated result
                 */
                return widgetData.data.value.result;
            } else {

                // First try to get value from input widget
                if (widgetElement?.querySelector('.inputWidgetInput') !== null) {
                    return widgetElement.querySelector('.inputWidgetInput')?.value ?? false
                } else if(widgetElement?.querySelector('.selectWidget') !== null){
                    // Else if try to get value from select
                    return widgetElement.querySelector('.selectWidget')?.value ?? false
                } else if (widgetElement?.querySelector('input') !== null) {

                    // Else if hidden input get from simple input
                    return widgetElement.querySelector('input')?.value ?? false
                } else {
                    // Should not happen if so log issue
                    console.warn('COULD NOT GET VALUE FROM WIDGET IN WIDGET BASE');
                }
            }
        } else if (widgetElement.value !== undefined) {

            /**
             * Return input value
             */
            if (widgetElement.value.match(/^[0-9]+(?:\.[0-9]+)?$/) !== null) {
                return parseFloat(widgetElement.value);
            }

            return widgetElement.value;
        }
    }

    // console.warn('Could not get widget result');

    return false;
}

export function getWidgetValues(widgetTypeFilter: null|Array<string> = null): TSelectOptions {

    const widgetTypeDisplayDict: { [key: string]: string } = {
        'Int': "Getal",
        'Addition': "Plus",
        'Subtraction': "Min",
        'Times': "Keer",
        'Division': "Delen",
        'Input': "Invoer",
        'MaterialChoice': "Materiaal",
        'SelectWidget': "Select",
        'ReturnWidget': "Return",
        'widgetCollectionWidget': "Collection",
        'ParenthesesWidget': "Haakjes",
        'CeilWidget': "Ceil",
        'FloorWidget': "Floor",
        'StartDataWidget': 'Start data',
        'IfWidget': 'If statement'
    }

    /**
     * The array of widget values
     */
    let widgetValues: TSelectOptions = [];

    /**
     * The widgets
     */
    let widgets = Array.from(document.getElementsByClassName('flow_widget'))

    /**
     * Calculation widgets
     */
    const calculationWidgets = Array.from(document.getElementsByClassName('calculationWidget'));

    /**
     * Calculation widgets
     */
    const globalValues = Array.from(document.getElementsByClassName('globalValue'));

    /**
     * The constants
     */
    let constants = document.getElementsByClassName('constant');

    /**
     * An array containing the options for the default widgets
     */
    let widgetOptions: Array<{ label: string, value: string }> = [];

    /**
     * Loop global values
     */
    if (widgetTypeFilter === null || widgetTypeFilter.includes('global')) {
        for (const globalValue of globalValues) {
            if (globalValue.getAttribute('id') !== null) {
                widgetOptions.push({
                    label: `label`,
                    value: globalValue.getAttribute('id')
                });
            }
        }
    }

    /**
     * Loop calculation widgets
     */
    for (const calculationWidget of calculationWidgets) {
        const widgetId = calculationWidget.getAttribute('id');

        if (widgetId === null) continue;

        const widgetData = JSON.parse(document.getElementById(widgetId)?.getAttribute('widgetData') ?? '');

        // Skip this widget if filter is set and the widget type is not in the filter
        if (widgetTypeFilter !== null && !widgetTypeFilter.includes(widgetData?.data?.type)) {
            continue;
        }

        // append a value based on the type of the widget
        switch (widgetData?.data?.type) {
            case 'HourWidget':
                widgetData?.data?.value?.returnFields.forEach((field: string) => widgetOptions.push({
                    label: `[${widgetId}] ${widgetData.data.title} > ${splitCamelCase(field)}`,
                    value: `${widgetId}.${field}`
                }));
                break;
            case 'MaterialChoice':
                widgetData?.data?.value?.returnFields.forEach((field: string) => widgetOptions.push({
                    label: `[${widgetId}] ${widgetData.data.title} > ${splitCamelCase(field)}`,
                    value: `${widgetId}.${field}`
                }));
                break;
            default:
                widgetOptions.push({
                    label: `[${widgetId}] ${widgetData.data.title}`,
                    value: widgetId
                });
                break;
        }
    }

    /**
     * loop  through the widgets
     */
    for (const widget of widgets) {
        /**
         * The parsed widget data
         */
        const widgetData = JSON.parse(widget.getAttribute('widgetData') ?? '');

        // Skip this widget if filter is set and the widget type is not in the filter
        if (widgetTypeFilter !== null && !widgetTypeFilter.includes(widgetData?.type)) {
            continue;
        }

        // append a value based on the type of the widget
        switch (widgetData?.type) {
            case 'MaterialChoice':
                widgetValues.push({
                    label: widgetData?.value?.label ?? widgetData.title,
                    options: widgetData?.value?.returnFields.map((field: string) => ({
                        label: splitCamelCase(field),
                        value: `${widget.id}.${field}`
                    }))
                });
                break;
            case 'HourWidget':
                widgetValues.push({
                    label: widgetData?.value?.label ?? widgetData.title,
                    options: widgetData?.value?.returnFields.map((field: string) => ({
                        label: splitCamelCase(field),
                        value: `${widget.id}.${field}`
                    }))
                });
                break;
            // skip return widgets
            case 'ReturnWidget':
                break;
            // skip parentheses widgets
            case 'ParenthesesWidget':
                break;
            // skip widget collection widgets
            case 'widgetCollectionWidget':
                break;
            default:
                widgetOptions.push({
                    label: `[${widgetTypeDisplayDict?.[widgetData.type] ?? widgetData.type}] ${widgetData.title}${typeof widgetData?.value !== 'object' ? `: ${widgetData?.value}` : ''}`,
                    value: widget.id
                });
                break;
        }
    }

    widgetValues.push({
        label: "Widgets",
        options: widgetOptions
    });

    /**
     * An array of constant options
     */
    let constantOptions: Array<{ label: string, value: string }> = [];

    // loop through the constants and add them to the constant options
    for (const constant of constants) {
        constantOptions.push({value: constant.id, label: `${splitCamelCase(constant.getAttribute('constname') ?? '')} (${constant.value})`})
    }

    if (widgetTypeFilter === null || widgetTypeFilter.includes('constant')) {
        widgetValues.push({
            label: "Vaste waarden",
            options: constantOptions
        });
    }

    return widgetValues;
}

export function setSelectedIndexByWidgetId(select: HTMLSelectElement|Element, selectedValue: any, prefix?: string, log = false): false|void {
    const options = select?.querySelectorAll("option") ?? [];
    const rawSelectedValue = getResultFromWidget(selectedValue, prefix);
    const value = String(rawSelectedValue);

    const newSelectedIndex = typeof rawSelectedValue === 'boolean' ? Number(rawSelectedValue) : [...options].findIndex((option) => option.text.toLowerCase() === value.toLowerCase() || option.value.toLowerCase() === value.toLowerCase());

    if (log) {
        console.log([...options].map(option => ({text: option.text, value: option.value})), rawSelectedValue, value, newSelectedIndex);
    }

    if (newSelectedIndex !== -1 && select) {
        select.selectedIndex = newSelectedIndex;
    } else {
        return false;
    }
}
