import {useEffect, useState} from "react";
import Draggable from "./Draggable";

export default function Drop({visualizeDependencies,itemList, daysInMonth, squares, finalizeChanges, extraDaysStart, daysInPrevMonth, extraDaysEnd, removeDependency, createDependency, type, setPopupCurrentMachine, setPopupFormData, setIsShowMode, isShowMode, setShowModeActivator, showDependencyOptions}) {

    // list containg squares + functional places
    const [planningList, setPlanningList] = useState([...fillPlanningList()]);

    useEffect(() => {
        setPlanningList([...fillPlanningList()]);
    }, [itemList])

    // initial fill of the planningList
    function fillPlanningList() {
        // list containing all items
        let newPlanningList = [];
        let contentItems = [];

        function createBackground() {
            // create squares
            for(let i = 0; i < squares; i++) {

                function getClassName() {
                    if(i >= extraDaysStart && i < squares - extraDaysEnd) {
                        return "planning__content__item";
                    }
                    return "planning__content__filler";
                }

                function getPlanningListItemDate() {
                    if(i < extraDaysStart) {
                        return daysInPrevMonth - extraDaysStart + i + 1;
                    } else if(i < daysInMonth + extraDaysStart) {
                        return i + 1 - extraDaysStart;
                    }
                    return i - squares + extraDaysEnd + 1;
                }

                newPlanningList.push({
                    item: null,
                    position: i,
                    content: false,
                    keyAtt: 'fill-' + i,
                    className: getClassName(),
                    date: getPlanningListItemDate(i),
                    first: true,
                });
            }
        }

        function createPlanningItems() {
            itemList.forEach((machine, machineIndex) => {
                machine.planning.forEach((planningItem, planningIndex) => {
                    contentItems.push({
                        ...planningItem,
                        item: planningItem,
                        content: true,
                        name:machine.name,
                        prevItem: null, //!
                        first: !planningItem.prevConnection,
                        className: `planning__content__transparent same-position-1`,
                        keyAtt: planningItem.uuid,
                    });
                });
            });
        }

        createBackground();
        createPlanningItems();

        format(contentItems).forEach((contentItem) => {
            newPlanningList.push(contentItem);
        })

        return newPlanningList;
    }

    function onItemDrag(newPosition, position, item) {
        finalizeChanges(newPosition - position, item);
    }

    function format(contentItems) {
        if(contentItems) {
            let newPlanningList = [];
            let squareAssignment = Array.from({ length: squares }, () => []);

            squareAssignment.forEach((_, index) =>{
                contentItems.forEach((item) =>{
                    if(item.position === index) {
                        squareAssignment[index].push(item);
                    }
                });
            });

            let squareAssignmentCopy = [...squareAssignment];

            // find the group the content from contentItems is placed in by looping through eachother
            squareAssignment.forEach((group, groupIndex) => {
                group.forEach((item, itemIndex) => {
                    // set the correct properties to an item
                    function updateItemProperties(item, yAlign) {
                        item.yAlign = yAlign;
                        item.className = `planning__content__transparent same-position-${yAlign}`;
                    }

                    // set an item to the correct index
                    function setItemToIndex(array, index, item) {
                        array[index] = item;
                    }

                    // find an item by the id
                    function getItemById(array, id) {
                        return array[id];
                    }

                    // make copies
                    let groupCopy = [...squareAssignmentCopy[groupIndex]];
                    let itemCopy = {...item};
                    // if its the first item
                    if(itemCopy.first) {
                        const itemOnPos = getItemById(groupCopy, itemIndex);
                        // if the first item has been moved by another item
                        if(itemOnPos === undefined || itemOnPos.uuid !== itemCopy.uuid) {
                            let yAlign = -1;
                            groupCopy.forEach((item, index) => {
                                if(item && item.uuid === itemCopy.uuid) {
                                    yAlign = index;
                                }
                            })
                            // if the items is present in the groupCopy
                            if(yAlign >= 0){
                                // set item properties
                                updateItemProperties(itemCopy, yAlign + 1);
                                // set the item in the groupCopy
                                setItemToIndex(groupCopy, yAlign, itemCopy);
                            } else {
                                // check if the list contains empty spots
                                let yAlign = -1;
                                groupCopy.forEach((item, index) => {
                                    if(!item) {
                                        yAlign = index;
                                    }
                                })
                                // if there is an empty spot
                                if(yAlign >= 0) {
                                // set item properties
                                    updateItemProperties(itemCopy, yAlign + 1);
                                    // set the item in the groupCopy
                                    setItemToIndex(groupCopy, yAlign, itemCopy);
                                } else {
                                // set item properties
                                    updateItemProperties(itemCopy, groupCopy.length + 1);
                                    // put the item in the list
                                    setItemToIndex(groupCopy, groupCopy.length, itemCopy);
                                }
                            }
                        } else {
                            // set item properties
                            updateItemProperties(itemCopy, itemIndex + 1);
                            // set position in the list
                            setItemToIndex(groupCopy, itemIndex, itemCopy);
                        }
                    } else {
                        const sibling = {...getItemById(squareAssignmentCopy, groupIndex - 1).filter((itemToCheck) => itemToCheck !== undefined).find((siblingItem) => itemCopy.prevConnection === siblingItem.uuid)};
                        const siblingArrayPos = sibling.yAlign - 1;
                        const itemOnPos = getItemById(groupCopy, siblingArrayPos);
                        let check = -1;
                        groupCopy.forEach((item, index) => {
                            if(item && item.uuid === itemCopy.uuid) {
                                check = index;
                            }
                        })
                        if(check >= 0) {
                            delete groupCopy[check];
                        }
                        if(itemOnPos && itemOnPos.uuid !== itemCopy.prevConnection) {
                            updateItemProperties(itemOnPos, itemIndex + 1);
                            setItemToIndex(groupCopy, itemIndex, itemOnPos);
                        }
                        updateItemProperties(itemCopy, sibling.yAlign);
                        setItemToIndex(groupCopy, siblingArrayPos, itemCopy);
                    }
                    squareAssignmentCopy[groupIndex] = groupCopy;
                });
            });
            // set the squareAssignment to the copy value
            squareAssignment = squareAssignmentCopy;

            // flatten the squareAssignment by one level and loop through
            squareAssignment.flat(1).forEach((item) => {
                // if the item is empty skip it
                if(item !== undefined) {
                    // otherwise push it to the newPlanningList
                    newPlanningList.push(item);
                }
            });
            return newPlanningList;
        }
        return;
    }

    return (
        <div className="planning__content__container">
            {
                planningList.map((item) => {
                    function getDependencies() {
                        if(item.item !== null) {
                            let newDependencies = [];
                            item.dependsOn.forEach((dependency) => {
                                let newDependency = {
                                    id: dependency,
                                    height: 0,
                                    width: 0,
                                    yAlign: 0,
                                };
                                planningList.forEach((planningItem) => {
                                    if(dependency === planningItem.uuid) {
                                        const x1 = item.item.position % 7;
                                        const y1 = Math.floor(item.item.position / 7);

                                        const x2 = planningItem.position % 7;
                                        const y2 = Math.floor(planningItem.position / 7);

                                        const deltaX = x2 - x1;
                                        const deltaY = y2 - y1;

                                        newDependency.height = deltaY;
                                        newDependency.width = deltaX;
                                        newDependency.yAlign = planningItem.yAlign;
                                    }
                                });
                                newDependencies.push(newDependency);
                            })
                            return newDependencies;
                        }
                        return [];
                    }

                    return (
                        <Draggable
                            setPopupCurrentMachine={setPopupCurrentMachine}
                            setPopupFormData={setPopupFormData}
                            visualizeDependencies={visualizeDependencies}
                            key={item.keyAtt}
                            item={item.item}
                            position={item.position}
                            content={item.content}
                            keyAtt={item.keyAtt}
                            onItemDrag={onItemDrag}
                            className={item.maxObjectsOnRow !== undefined ? item.className + ' containing-' + (item.maxObjectsOnRow + 1) : item.className}
                            date={item.date}
                            name={item.name}
                            colorIndex={item.colorIndex}
                            dependsOn={getDependencies()}
                            prevItem={item.prevItem}
                            first={item.first}
                            removeDependency={removeDependency}
                            createDependency={createDependency}
                            samePosIndex={item.yAlign}
                            squares={squares}
                            onClick={item.onClick}
                            conflicts={item.conflicts}
                            height={(32 * item.maxObjectsOnRow > 210 ? 32 * item.maxObjectsOnRow : 210)}
                            type={type}
                            setIsShowMode={setIsShowMode}
                            isShowMode={isShowMode}
                            setShowModeActivator={setShowModeActivator}
                            showDependencyOptions={showDependencyOptions}
                            isDependencyOption={item.isDependencyOption}
                        />
                    )
                })
            }
        </div>
    )
}
