import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import Block from "UI/App/Components/Block/Block";
import {toast} from "react-toastify";
import {formatCurrency} from "../../../../../../App/Util/format";
import Table from "../../../../Components/Table/Table";
import Grid from "../../../../Components/Grid/Grid";

export default function EditPrice() {
    const { authAxios } = useContext(FetchContext);
    const [materials, setMaterials] = useState([]);

    useEffect(() => {
        authAxios.get('/logistics/materials/all')
            .then((res) => {
                setMaterials(res.data);
            })
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        const id = formData.get('id');
        const salePrice = formData.get('salePrice');

        authAxios.put(`/logistics/materials/${id}`, { data: { salePrice } })
            .then((res) => {
                console.log(res);
                toast.success('Sale price is bijgewerkt');
            })
            .catch((err) => {
                toast.error('Er ging iets mis bij het bijwerken van de sale price');
            })
    }

    return (
        <Block key={materials.id} name='data' title={materials.category}>
            <Table
                headers={[
                    "Calculatie omschrijving / Leverancier SKU",
                    "Verkoopprijs",
                    "Eenheid",
                    "Nieuwe prijs"
                ]}
                data={materials.map((material, index) => [
                    `${material.supplierSKU !== null ? material.supplierSKU + ' / ' : ''} ${material.calculationDescription}`,
                    formatCurrency(material.salePrice),
                    `${material.countPerUnit !== null ? material.countPerUnit : 'Onbekend'} ${material.unit !== null ? material.unit : ''}`,
                    <form onSubmit={handleSubmit} >
                        <Grid columns={2} customColTemplate='2fr 1fr'>
                            <input type="hidden" name="id" value={material.id} />
                            <input type='number' step={'0.01'} id='salePrice' name='salePrice' defaultValue={material.salePrice !== null ? material.salePrice : '0.00'} placeholder="0.00" style={{ marginBottom: '5px' }} />
                            <button className={'btn'}>Wijzig</button>
                        </Grid>
                    </form>
                ])}
                structure={["100%", "200px", "200px", "400px"]}
            />
        </Block>
    )
}
