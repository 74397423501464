import Button from "UI/App/Components/Button/Button";
import Grid from "UI/App/Components/Grid/Grid";
import Popup, {closePopup} from 'UI/App/Components/Popup/Popup';
import IF from "UI/App/Components/Conditional/IF";

/**
 * @param {String} title Title of the popup
 * @param {String|Object} subject Subject of the popup
 * @param {String|Object} description description of the popup
 * @param {String|Object} popupId 'confirmationPopup' by default, this would allow of mutiple popups of the same type
 * @param {String|Object} confirmText Text to display on confirm button
 * @param {String|Object} declineText Text to display on decline button
 * @param {Boolean} closeOnConfirm Close this popup on confirm?
 * @param {Boolean} closeOnDecline Close this popup on decline?
 * @param {Boolean} awaitFunctions Await the functions called?
 * @param {Function} functionOnConfirm Function to call on confirmation
 * @param {Function} functionOnDecline Function to call on decline
 * @param {Object} confirmButtonProps props to pass to the confirm button
 * @param {Object} declineButtonProps props to pass to the decline button
 * @param {Boolean} singularButton if we have one button
 * @param {Array} contentArray content to show user in the modal/popup
 */
export default function ConfirmationPopup({
    title,
    subject,
    description,
    popupID = "confirmationPopup",
    confirmText = "Ja",
    declineText = "Nee",
    closeOnConfirm = true,
    closeOnDecline = true,
    awaitFunctions = false,
    functionOnConfirm = undefined,
    functionOnDecline = undefined,
    confirmButtonProps = {},
    declineButtonProps = {},
    singularButton = false,
    contentArray = []

}) {
    async function confirmed(e) {
        // check if we have a function to call
        if (typeof functionOnConfirm === "function") {
            //await if required
            if (awaitFunctions) {
                await functionOnConfirm(e)
            } else {
                functionOnConfirm(e)
            }
        }

        // close the popup if required
        if (closeOnConfirm) {
            closePopup();
        }
    }
    async function declined(e) {

        // check if we have a function to call
        if (typeof functionOnDecline === "function") {
            //await if required
            if (awaitFunctions) {
                await functionOnDecline(e)
            } else {
                functionOnDecline(e)
            }
        }

        // close the popup if required
        if (closeOnDecline) {
            closePopup();
        }
    }

    return (
        <Popup
            overflow={false}
            popupID={popupID}
            title={title ?? "Bevestigen"}
            contentStyle={{ minHeight: "40px", display: "grid" }}
        >
            <IF condition={subject}>
                <h3 style={subject?.style ?? { width: "100%", textAlign: "center" }}>{subject?.value ?? (subject ?? "")}</h3>
                <br />
            </IF>

            <IF condition={description}>
                <span style={description?.style ?? { width: "100%", textAlign: "center" }}>{description?.value ?? (description ?? "")}</span>
                <br />
            </IF>
            <span style={contentArray?.style ?? {}} >
                {contentArray.value ?? contentArray}
            </span>


            <Grid columns={singularButton === false ? 2 : 1}>
                <Button name={"confirmButton"} {...confirmButtonProps} style={confirmText?.style} onClick={(e) => confirmed(e)}>
                    {confirmText?.value ?? (confirmText ?? "Ja")}
                </Button>
                {!singularButton &&
                    <Button name={"declineButton"} {...declineButtonProps} style={declineText?.style} onClick={(e) => declined(e)}>
                        {declineText?.value ?? (declineText ?? "Nee")}
                    </Button>
                }
            </Grid>
        </Popup>
    )
}