import { useContext, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { FetchContext } from 'App/Strapi/FetchContext'
import RolesPermissions from './RolesPermissions';
import isEmpty from 'lodash/isEmpty';

export default function Roles() {
    const { authAxios } = useContext(FetchContext);

    const [rolesSelect, setRolesSelect] = useState(null);
    const [selectedRole, setSelectedRole] = useState({});
    const [usersPerRole, setUsersPerRole] = useState({});

    useEffect(() => {
        authAxios.get('../users-permissions/roles').then(({ data }) => {
            const options = [];
            const users = {};

            for (const role of data.roles) {
                // skip admin
                if ((role.name).toLowerCase() === 'admin') {
                    continue;
                }

                // add select option for this role
                options.push(<option key={`select-option-${role.id}`} value={role.id}>{role.name}</option>);
                // save how many users this role has
                users[role.id] = role.nb_users;
            }

            setRolesSelect(options);
            setSelectedRole({ name: options[0].props.children, id: options[0].props.value, });
            setUsersPerRole(users);
        }).catch((exception) => {
            toast.error('De rollen konden niet opgehaald worden');

            console.error(exception);
        });
    }, [authAxios]);

    /**
     * Handles saving the form to Strapi
     *
     * @param {Event} e
     */
    function saveRolePermissions(e) {
        e.preventDefault();

        let postData = {};
        // loop through form inputs
        Array.from(e.target).forEach(({ dataset, checked }) => {
            const permission = dataset.permission;
            const group = dataset.group;
            const name = dataset.name;

            if (!name || !group || !permission) {
                return;
            }

            // prevent overwritting objects by only creating each group & name if they dont exist yet
            if (!postData[permission]) {
                postData[permission] = { controllers: {} };
            }
            if (!postData[permission]['controllers'][group]) {
                postData[permission]['controllers'][group] = {};
            }
            postData[permission]['controllers'][group] = { ...postData[permission]['controllers'][group], [name]: { enabled: checked } };
        });

        if (isEmpty(postData)) {
            toast.error(`Er was een error met het updaten van de rol`);
            return;
        }

        // check for role
        authAxios.get(`../users-permissions/roles/${selectedRole.id}`).then(({ data }) => {
            if (isEmpty(data) || (data?.role?.name ?? '').toLowerCase() === 'admin') {
                // message shown in console on development
                return Promise.reject({ message: 'This role is not editable' });
            }

            // update role
            authAxios.put(`../users-permissions/roles/${selectedRole.id}`, {
                "permissions": postData
            }).then(() => {
                toast.success(`"${selectedRole.name}" is succesvol bijgewerkt`);

                // re-render form
                setSelectedRole((prev) => ({ ...prev }));
            });
        }).catch((exception) => {
            toast.error(`De permissies voor "${selectedRole.name}" kon niet geüpdatet worden`);

            console.error(exception);
        });
    }

    return (
        <div className='permissions'>
            <div className='permissions__header'>
                <div>
                    <label htmlFor='roleSelect'>Wijzig de
                        <select name='roleSelect' id='roleSelect' onChange={({ target }) => setSelectedRole({ name: target[target.selectedIndex].text, id: target.value })}>
                            {rolesSelect}
                        </select>
                        rol
                    </label>
                    <br />
                    <span>
                        {usersPerRole[selectedRole.id]} Gebruiker{(usersPerRole[selectedRole.id] !== 1) && 's'} met deze rol
                    </span>
                </div>
                <div>
                    <button form='rolesForm'>Update rechten voor {selectedRole.name}</button>
                </div>
            </div>
            <div className='permissions__content'>
                <form id='rolesForm' onSubmit={saveRolePermissions}>
                    <RolesPermissions selectedRole={selectedRole} />
                </form>
            </div>
        </div>
    );
}
