import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {stringify} from "qs";
import {FetchContext} from "../../../../../../../App/Strapi/FetchContext";
import Select from "react-select";
import {formatName} from "../../../../../../../App/Util/format";
import {useNavigate} from "react-router-dom";
import {closePopup} from "../../../../../Components/Popup/Popup";
import ProjectRepository from "../../../Projects/ProjectRepository";

export default function FromCalculation({ companyId }) {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    const [calculations, setCalculations] = useState([]);
    const [calculation, setCalculation] = useState(null);
    const [chosenCalculation, setChosenCalculation] = useState(null);

    const [amounts, setAmounts] = useState([]);
    const [amount, setAmount] = useState(null);

    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState(null);

    const [contactPersons, setContactPersons] = useState([])
    const [contactPerson, setContactPerson] = useState(null);

    const [reference, setReference] = useState(null)

    const [calculationOwner, setCalculationOwner] = useState(null);

    // Use effect for loading data
    useEffect(() => {
        load();
    }, []);

    // When calculation is chosen load calculation data
    useEffect(() => {
        // Check if calculation is set before fetching data
        if (calculation === null) return;
        setAmount(null)

        authAxios.get(`/calculations/${calculation.value}?sort=number:desc`)
            .then(({data}) => {
                const owner = data.data.owner;
                setCalculationOwner(owner);

                setReference(getCustomerReference(data, owner))

                setChosenCalculation(data.data)

                if (owner === 'pretore') {
                    let tableData = [];
                    for (const amount of data.data.data.amounts) {
                        tableData.push({
                            label: amount,
                            value: amount
                        })
                    }

                    setAmounts(tableData);
                }

            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }, [calculation]);

    useEffect(() => {
        console.log('get contact person');
        setContactPerson(null)
        // Only continue if company has been set
        if (company === null) return

        // Load all calculations
        authAxios.get(`/crm/companies/${company.value}/persons/all`)
            .then(({data}) => {
                let tableData = [];

                for (const contactPerson of data) {
                    tableData.push({
                        label: formatName(contactPerson.firstName, contactPerson.prefixToSurname, contactPerson.surname),
                        value: contactPerson.id
                    })
                }

                setContactPersons(tableData)
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })

    }, [company]);

    async function load() {
        const calculationParams = stringify({
            populate: {
                company: {
                    fields: [
                        'name',
                    ]
                },
            },
            fields: [
                'id',
                'number',
                'projectDescription'
            ],
            sort: {
                number: 'desc'
            },
            filters: {
                projectDescription: {
                    $nei: 'onderhoud'
                }
            }
        })

        // Load all calculations
        authAxios.get(`/calculations/all?${calculationParams}`)
            .then(({data}) => {
                let tableData = [];

                for (const calculation of data) {
                    tableData.push({
                        label: `${calculation.number} - ${calculation?.company?.name !== undefined ? `${calculation?.company?.name} - ` : ' '}${calculation.projectDescription ?? ''}`,
                        value: calculation.id
                    })
                }

                setCalculations(tableData)
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })

        const companyParam = stringify({
            fields: [
                'id',
                'name'
            ]
        })

        // Load all calculations
        authAxios.get(`/crm/companies/all?${companyParam}`)
            .then(({data}) => {
                let tableData = [];

                for (const company of data) {
                    tableData.push({
                        label: company.name,
                        value: company.id
                    })
                }

                setCompanies(tableData.sort((a, b) => a.label.trim().localeCompare(b.label.trim())))
                if (companyId !== undefined) {
                    setCompany(tableData.filter((el) => el.value === companyId)[0])
                }
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }

    const getShippingData = () => {
        const shippingData = {
            total: amount.value,
            boxes: null,
            pallets: null
        }

        // If undefined return empty shipping
        if (chosenCalculation?.data?.templateData?.data === undefined) {
            return shippingData
        }

        // Loop machines until 'Verpakken' is found
        for (const [machineKey, machine] of Object.entries(chosenCalculation?.data?.templateData?.data)) {
            if (machine.name !== undefined) {
                if (machine.name.toLowerCase() === 'verpakken') {
                    // Loop widgets until total material is found
                    for (const widget of machine.widgets) {
                        if (widget.data.title.toLowerCase().includes('aantal') && widget.data.title.toLowerCase().includes('materiaal')) {
                            // Get value from json
                            shippingData.boxes = chosenCalculation.data.calculationData[widget.data.title][amount.value];
                        }
                    }
                }
            }
        }

        return shippingData;
    }

    async function createOrder() {
        let description = ''
        if (calculationOwner === 'hjmg') {
            for (const checklistItem of chosenCalculation.data.checklistData) {
                if (checklistItem.name === 'omschrijving' || checklistItem.name === 'description') {
                    description = checklistItem.value
                }
            }
        } else {
            for (const checklistItem of chosenCalculation.data.checklist) {
                if (checklistItem.name === 'omschrijving' || checklistItem.name === 'description') {
                    description = checklistItem.value
                }
            }
        }

        let json = {
            data: {
                customerReference: reference,
                description: description,
                shipping: calculationOwner === 'pretore' ? getShippingData() : null,
                status: 'binnengekomen',
                chosenAmount: calculationOwner === 'pretore' ? amount.value : null,
                calculation: calculation.value,
                company: company.value,
                contactPerson: contactPerson.value
            }
        }

        let project = await new ProjectRepository(null, authAxios).createProject(null)

        await new ProjectRepository(project, authAxios).addCalculationsToProject([chosenCalculation.id]);

        await authAxios.post(`/orders`, json).then(({data}) => {

            // Add order to project
            new ProjectRepository(project, authAxios).addOrdersToProject([data.data.id]);

            setCalculationStatus(chosenCalculation.id);
            toast.success(`Order aangemaakt!`);
            closePopup('addOrder')
            navigate(`/orders/${data.data.id}`);

        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van een order!`);
        });
    }
    function getCustomerReference(data, owner) {
        if (data?.data?.customerReference !== undefined && data?.data?.customerReference !== null) {
            return data?.data?.customerReference
        }

        if (owner === 'hjmg') {
            for (const checklistItem of data.data.data.checklistData) {
                if (checklistItem.name === 'Kenmerk klant' || checklistItem.name === 'customerReference') {
                    return checklistItem.value
                }
            }
        } else {
            for (const checklistItem of data.data.data.checklist) {

                if (checklistItem.name === 'Kenmerk klant' || checklistItem.name === 'customerReference') {
                    return checklistItem.value
                }
            }
        }
    }
    async function setCalculationStatus(calcId = undefined, status = "order") {
        await authAxios.put(`calculations/${calcId}`, {
            data: {
                status: status
            }
        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het updaten van de status. ${reason?.response?.status && `(${reason.response.status})`}`)
        });
    }
    return (
        <>
            {/*<Block name="calculatiie">*/}
            Calculatie:
            <Select
                name='type'
                id='type'
                isClearable={false}
                options={calculations}
                value={calculation}
                onChange={(calculation) => {
                    setCalculation(calculation);
                }}
                required
            />
            {calculationOwner !== null ? calculationOwner === 'pretore' ? <>
                Aantal:
                <Select
                    name='type'
                    id='type'
                    isClearable={false}
                    options={amounts}
                    value={amount}
                    onChange={(amount) => {
                        setAmount(amount);
                    }}
                    required
                />
            </> : <></> : <></>}

            Klant:
            <Select
                name='type'
                id='type'
                isClearable={false}
                options={companies}
                value={company}
                onChange={(company) => {
                    setCompany(company);
                }}
                required
            />
            Contact persoon:
            <Select
                name='type'
                id='type'
                isClearable={false}
                options={contactPersons}
                value={contactPerson}
                onChange={(contactPerson) => {
                    setContactPerson(contactPerson);
                }}
                required
            />

            Klant referentie:
            <input
                name='reference'
                type='text'
                value={reference}
                onChange={(reference) => {
                    setReference(reference.target.value)
                }}
            />

            <br />
            <br />
            <button onClick={() => createOrder()} disabled={calculation === null || (calculationOwner === 'pretore' ? amount === null : false) || company === null || contactPerson === null}>
                Opslaan
            </button>
            {/*</Block>*/}
        </>
    )
}
