import { Fragment, ReactNode, createElement, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { mmToPx } from 'App/Util/format';
import { autoSizeTextArea } from 'App/Util/input';
import { PADDING_BOTTOM, PADDING_TOP } from 'App/Util/print';
import { Spinner } from 'UI/App/Components/Spinner';
import Order_PRETORE from 'UI/Assets/Images/PdfBackgrounds/Order_PRETORE.jpg';
import Briefpapier_HJMG from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg';
import Briefpapier_HJMG_2 from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG_2.jpg';
import Briefpapier_PRETORE from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg';
import Briefpapier_PRETORE_2 from 'UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg';

type TPDF = {
    children: JSX.Element | JSX.Element[];
    pdfData: any;
    pdfFor: string;
    pageTopBreakLines?: number | string;
};
type TPage = {
    children: ReactNode | ReactNode[];
    pageIndex: number;
    pagesAmount: number;
    isLastPage: boolean;
    backgroundImage?: string;
    breakLinesTop: { height: number; elements: JSX.Element[] };
    setPages: React.Dispatch<React.SetStateAction<(JSX.Element | JSX.Element[])[]>>;
    isGenerated: boolean;
    setIsGenerated: React.Dispatch<React.SetStateAction<boolean>>;
};
type TPDFSection = {
    children: ReactNode | ReactNode[];
    maxWidth?: string | number;
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    [props: string]: any;
};
type TPDFTextArea = {
    defaultValue?: string | null;
    onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
    minRows?: string | number;
};

/**
 * This object holds the width & height of each pdf background-image's top right content.
 *
 * The sizes were taken from roughly placing an absolute div on the top right corner of the pdf (minus padding),
 * increasing it's size in mm till the div is completely over the content,
 * then increase both sizes by **6mm**.
 */
export const backgroundTopRightBoxSizes = {
    [Order_PRETORE]: null,
    [Briefpapier_HJMG]: { width: '44mm', height: '57mm' },
    [Briefpapier_HJMG_2]: null,
    [Briefpapier_PRETORE]: { width: '50mm', height: '54mm' },
    [Briefpapier_PRETORE_2]: null
};

const pageBottomMarginPosition = mmToPx(PADDING_BOTTOM);
const pageHeight = mmToPx(PADDING_BOTTOM - PADDING_TOP);

/**
 * The PDF handler for content and pages.
 *
 * @export default
 */
export default function PDF({ children, pdfData, pdfFor, pageTopBreakLines = 0 }: TPDF): JSX.Element {
    const pdfRef = useRef<HTMLDivElement>(null);

    // state for the breaklines at the top of each page. so it doesn't have to recreate the array every render
    const [breakLinesTop, setBreakLinesTop] = useState({
        height: 0,
        elements: [] as JSX.Element[]
    });

    // the state to hold what pages there are with what content in them.
    const [pages, setPages] = useState<(JSX.Element | JSX.Element[])[]>([]);

    // state for the loading spinners
    const [isGenerated, setIsGenerated] = useState(false);

    // create state for a number that increments everytime pdfData changes, or children changes through code saving.
    // this is used to create unique keys for the <Page> component so they update correctly.
    // I tried adding a key value to the pages state or moving this higher but that caused an infinite loop doing setPages() or it broke the pages
    const [renderKey, setRenderKey] = useState(1);

    useEffect(() => {
        // update render key to mount new page components when our pdfData changes
        setRenderKey((num) => ++num);
    }, [pdfData]);

    useEffect(() => {
        if (children) {
            setIsGenerated(false);

            // update render key to fix single page pdf not removing the loading spinner
            setRenderKey((num) => ++num);

            // reset the pages when the children inside <PDF> change.
            // resetting back to 1 page (and with the unique key from the renderKey state) causes them to recalculate the amount of pages
            setPages([children]);
        }
    }, [children]);

    useEffect(() => {
        if (pdfRef.current) {
            // parse string numbers to Number
            let amount = parseInt(pageTopBreakLines as string);

            // get the line-height value used to calculate the height of the break lines
            const height = parseFloat(window.getComputedStyle(pdfRef.current).lineHeight) * amount;

            // height in px & array of repeat break lines. with key for key error
            setBreakLinesTop({
                height: !isNaN(height) ? height : 0,
                elements: Array.from(Array(!isNaN(amount) ? amount : 0), (_, key) => <br key={key} />)
            });
        }
    }, [pageTopBreakLines, pdfRef]);

    return (
        <div ref={pdfRef} className='pdf-mockup__wrapper' data-pdf-for={pdfFor} data-template={pdfData?.template ?? 1}>
            {pages.map((content, index) => {
                // add a page for every `pages` array item
                return (
                    <Page
                        key={`${renderKey}-${index}`}
                        pageIndex={index}
                        pagesAmount={pages.length}
                        isLastPage={index + 1 === pages.length}
                        backgroundImage={index === 0 ? pdfData?.backgroundImage : pdfData?.backgroundImage2}
                        breakLinesTop={breakLinesTop}
                        setPages={setPages}
                        isGenerated={isGenerated}
                        setIsGenerated={setIsGenerated}
                    >
                        {content}
                    </Page>
                );
            })}
        </div>
    );
}

/**
 * Insert a pdf page with content.
 *
 * And checks if content reaches out of the page to move that content to the next page.
 */
function Page({
    children,
    pageIndex,
    pagesAmount,
    isLastPage,
    backgroundImage,
    breakLinesTop,
    setPages,
    isGenerated,
    setIsGenerated
}: TPage): JSX.Element {
    // add a ref to this page to get its first level html element children
    const pageRef = useRef<HTMLDivElement>(null);
    // use a state for the ref children so we are sure ref.current.children are set and their offsets have been calculated by the browser.
    const [refChildren, setRefChildren] = useState<HTMLCollection | null>(null);

    useEffect(() => {
        if (!refChildren) return;

        // timeout to fix a weird bug with multiple pages
        const pageTimeout = setTimeout(() => {
            // get the elements that overflow out of the current <Page>
            try {
                const nextPageElements = getNextPageElements(refChildren, breakLinesTop.height);

                // if there are elements outside the page
                if (nextPageElements.length > 0) {
                    // set the next page
                    generateNextPage(setPages, pageIndex, nextPageElements);
                } else if (isLastPage) {
                    const isDone = setTimeout(() => {
                        setIsGenerated(true);
                    }, 300);

                    return () => {
                        // cancel the timeout on unmount
                        clearTimeout(isDone);
                    };
                }
            } catch (error) {
                console.error(error);

                if (error instanceof RangeError) {
                    toast.error(error.message);
                }

                setPages([[]]);
                setIsGenerated(true);
            }
        }, 1);
        return () => {
            clearTimeout(pageTimeout);
        };
    }, [breakLinesTop.height, isLastPage, pageIndex, refChildren, setIsGenerated, setPages]);

    useEffect(() => {
        if (pageRef.current?.children) {
            // set the ref's current children to the state
            setRefChildren(pageRef.current.children);
        }
    }, [pagesAmount]);

    return (
        <div
            className='pdf-mockup__page'
            style={{
                backgroundImage: backgroundImage ? `url('${backgroundImage}')` : undefined
            }}
            data-page={pageIndex + 1}
        >
            {!isGenerated && (
                <div className='pdf-mockup__page__loading'>
                    <Spinner />
                </div>
            )}

            {typeof backgroundImage !== 'undefined' && backgroundTopRightBoxSizes[backgroundImage] && (
                <div
                    id='topRightFloatBox'
                    style={{
                        float: 'right',
                        ...backgroundTopRightBoxSizes[backgroundImage]
                    }}
                />
            )}

            {breakLinesTop.elements}

            <div className='pdf-mockup__page__content' ref={pageRef}>
                {children}
            </div>
        </div>
    );
}

/**
 * Each section of the pdf that is used to calculate page breaks.
 *
 * @export
 */
export function PDFSection({
    children,
    maxWidth = undefined,
    flexDirection = undefined,
    justifyContent = undefined,
    ...props
}: TPDFSection): JSX.Element {
    return (
        <div
            {...props}
            className={`pdf-mockup__row ${props?.className ?? ''}`}
            style={{
                width: maxWidth,
                flexDirection,
                justifyContent,
                whiteSpace: 'break-spaces',
                ...props?.style
            }}
        >
            {children}
        </div>
    );
}

/**
 * Insert a hard page break.
 *
 * ! To insert a new page there has to be elements below the component.
 * (To render a blank page you can use an empty <PDFSection>).
 *
 * @export
 */
export function PDFPageBreak() {
    return createElement('page-break');
}

export function PDFTextArea({ defaultValue, onBlur = () => {}, minRows = 2 }: TPDFTextArea): JSX.Element {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    // use a state for the textarea value so we are sure it is set and the scrollheight has been calculated by the browser.
    const [refValueLoaded, setRefValueLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (refValueLoaded) {
            // calculate size
            autoSizeTextArea(textAreaRef.current);
        }
    }, [refValueLoaded]);

    useEffect(() => {
        if (textAreaRef.current?.defaultValue !== '') {
            // set that the ref's current value has loaded in
            setRefValueLoaded(true);
        }
    }, []);

    return (
        <textarea
            ref={textAreaRef}
            defaultValue={defaultValue ?? ''}
            rows={minRows as number}
            style={{
                minHeight: `calc(var(--pdf-line-height) * ${minRows})`
            }}
            onChange={({ target }) => {
                autoSizeTextArea(target);

                // push textarea to next page by blurring if its offsetBottom is biggger than the pageBottomMarginPosition
                if (
                    textAreaRef.current &&
                    textAreaRef.current?.offsetTop + textAreaRef.current?.offsetHeight > pageBottomMarginPosition
                )
                    textAreaRef.current.blur();
            }}
            onFocus={({ target }) => {
                autoSizeTextArea(target);
            }}
            onBlur={onBlur}
        ></textarea>
    );
}

/**
 * Calculates the elements on the current page if they need to go to the next page.
 */
function getNextPageElements(refChildren: HTMLCollection, breakLinesTopHeight: number): number[] {
    // array that will hold the indexes of the children that are outside the page
    const nextPageElements: number[] = [];

    // get the page content children
    const childrenArray = Array.from(refChildren) as HTMLElement[];

    // loop through each first level child
    for (const [childIndex, child] of Object.entries(childrenArray)) {
        // first error check if element is too tall for 1 page
        if (breakLinesTopHeight + child.offsetHeight > pageHeight) {
            throw new RangeError('Een PDF pagina sectie is te lang voor 1 pagina.');
        }

        // if the child's offsetBottom position is higher than the bottom margin of the page
        if (child.offsetTop + child.offsetHeight > pageBottomMarginPosition) {
            // add the element's index to the array for the next page
            nextPageElements.push(parseInt(childIndex));
        } else if (child.tagName === 'PAGE-BREAK') {
            // get all the element indexes that comes after the <page-break> element
            let nextPageElementsIndexes = Object.keys(childrenArray)
                .filter((index) => parseInt(index) > parseInt(childIndex))
                .map((index) => parseInt(index));

            nextPageElements.push(...nextPageElementsIndexes);

            break;
        }
    }

    return nextPageElements;
}

/**
 * Creates the new page for the pdf.
 */
function generateNextPage(
    setPages: React.Dispatch<React.SetStateAction<(JSX.Element | JSX.Element[])[]>>,
    pageIndex: number,
    nextPageElements: number[]
): void {
    // use setPages to add the next page
    setPages((currentPages) => {
        /* ! modifying/deleting elements is impossible here due to the value being react elements */
        console.time(`Generated page ${pageIndex + 2} in`);

        // cast previous page as array of jsx elements, which it always is but typescript doesn't think know
        let previousPage = currentPages[pageIndex] as JSX.Element[];

        /*
        flatten the elements in the last page due to jsx array.map() returning an array instead of indivindual reactElements
        example:
            [
                reactNode1,
                [ReactNode2, ReactNode3],
                [Fragment]
            ]
            =>
            [
                reactNode1,
                ReactNode2,
                ReactNode3,
                ...Fragment.children
            ]
        */
        previousPage = previousPage.flatMap((element) => {
            if (Array.isArray(element)) {
                return element.flatMap((element) => {
                    if (element?.type !== PDFSection && element?.type !== PDFPageBreak)
                        throw new TypeError(
                            `<${element?.type?.name ?? element?.type}> is not a valid child for <PDF />`
                        );

                    if (element.type === Fragment) {
                        return element.props.children;
                    } else {
                        return element;
                    }
                });
            } else {
                if (element?.type !== PDFSection && element?.type !== PDFPageBreak)
                    throw new TypeError(`<${element?.type?.name ?? element.type}> is not a valid child for <PDF />`);

                return element;
            }
        });

        // get array of the elements that outside the page.
        const nextPage = previousPage.filter((_, index) => nextPageElements.includes(index));

        // replace last page with only the elements that fit on it
        currentPages[pageIndex] = previousPage.filter((_, index) => !nextPageElements.includes(index));

        // return the prev pages with the new page's elements
        console.timeEnd(`Generated page ${pageIndex + 2} in`);
        return [...currentPages, nextPage];
    });
}
