import {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import { useSearchParams } from "react-router-dom";
import NavigationMenu from "./NavigationMenu";
import {ConfigContext} from "App/Strapi/ConfigContext";
import "./navigation.scss";
import Icon from "UI/App/Components/Icon/Icon";
import Popup, {openPopup} from "../../Components/Popup/Popup";
import Versions from "../Version/Versions";
import { getCssVariableColor } from "App/Util/styling";
import { Logo } from "App/Util/stylingComponents/logo";
const { version } = require("../../../../../package.json");

export default function Navigation() {
  const { authAxios } = useContext(FetchContext);
  const configContext = useContext(ConfigContext);
  const location = useLocation();

  // create a state for the navigation menu
  const [navigationMenu, setNavigationMenu] = useState([]);
  // create a state for the current active route
  const [activeRoute, setActiveRoute] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  // get and set searchParams
  const [searchParams, setSearchParams] = useSearchParams();

  // get the navigation menu from the API
  useEffect(() => {
    authAxios
      .get("config/navigation")
      .then(({ data }) => {
        setActiveRoute(getActiveRoute(data.data.navigation));

        data.data.navigation.push({
          label: "Uitloggen",
          path: "/logout",
          icon: "sign-out",
        });

        setNavigationMenu(data.data.navigation);
      })
      .catch((exception) => {
        // TODO: implement exception handling for the menu
        console.error("Trouble loading the navigation menu: %s", exception);
      });

    function getActiveRoute(items) {
      // set which route should be active by default and how many entries have been matched for this route
      const currentActiveRoute = {
        route: "",
        length: -Infinity,
      };
      // get the current open path
      const currentPath = location.pathname;
      // check for the route similarity
      checkRouteSimilarity(items);

      function checkRouteSimilarity(items) {
        // loop through all the routes
        Object.entries(items).forEach(([key, item]) => {
          // get the path of this route
          const routePath = item.path;
          // check how much of the route matches from the start
          const [match] = currentPath.match("^" + routePath) ?? [""];
          // replace the active route if the new match is longer than the previous match
          if (match.length > currentActiveRoute.length) {
            currentActiveRoute.length = match.length;
            currentActiveRoute.route = match;
          }

          if (item.subMenu?.length) {
            checkRouteSimilarity(item.subMenu, true);
          }
        });
      }

      return currentActiveRoute.route;
    }
  }, [authAxios, location.pathname]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => setIsOpen(!isOpen);
  const closeNav = () => setIsOpen(false);

  const [isNormalWidth, setIsNormalWidth] = useState(true);

  const setNavStyle = () => {
    setIsNormalWidth((prevIsNormalWidth) => {
      return !prevIsNormalWidth;
    });
  };

  // minimize/maximize on url
  const setNavStyleOnUrl = (mode) => {
    // set nav minimized on either;
    // mode=fullscreen
    // OR fullscreen
    if (searchParams?.get("mode")?.toLocaleLowerCase() === "fullscreen" || searchParams?.get("fullscreen") !== null) {
      // we only need to toggle nav style if it is currently 'open'
      if (mode) {
        setNavStyle();
      }
    }

    // set nav maximized on either;
    // mode=full-nav
    // OR full-nav
    if (searchParams?.get("mode")?.toLocaleLowerCase() === "full-nav" || searchParams?.get("full-nav") !== null) {
      // we only need to toggle nav style if it is currently 'closed'
      if (!mode) {
        setNavStyle();
      }
    }
  }

  // get status from localstorage and set variable
  useEffect(() => {
    const storedValue = JSON.parse(localStorage.getItem('isDefaultNavSize'));
    // default => no value stored = true
    setIsNormalWidth(storedValue === null ? true : storedValue);
    setNavStyleOnUrl(storedValue === null ? true : storedValue);
  }, []);

  // set item in localstorage
  useEffect(() => {
      localStorage.setItem('isDefaultNavSize', JSON.stringify(isNormalWidth));
  }, [isNormalWidth]);

  return (
    <>
      <Popup popupID='versions' title={'Versie log'}>
        <Versions/>
      </Popup>
      <div
        className={`navigation navigation--open ${isOpen ? "open" : ""} ${
          !isNormalWidth ? "opened" : ""
        }`}
      >
        <div className="navigation__container">
          <div className="item-container">
            <Logo className="navigation__logo" accentColor={getCssVariableColor("--primary")} />

            <div
              onClick={toggleNav}
              className={`hamburger ${isOpen ? "open" : ""}`}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="navigation-top-text">
              {isNormalWidth && (
                <span className={"navigation__menu-title"}>MENU</span>
              )}
            </div>
            <NavigationMenu
              items={navigationMenu}
              activeRoute={activeRoute}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              closeNav={closeNav}
            />

          </div>
          <div className={"navigation__footer"}>
            <span style={{ cursor: "pointer" }} onClick={(e) => { openPopup("versions") }} className={"app-version"}>Version: {version}</span>
            <span
              onClick={setNavStyle}
              className={
                !isNormalWidth
                  ? "navigation__arrow__container__small"
                  : "navigation__arrow__container__medium"
              }
            >
              <Icon
                className="navigation__arrow__icon"
                name={isNormalWidth ? "angle-left" : "angle-right"}
              />
            </span>
          </div>
        </div>

      </div>
    </>
  );
}

