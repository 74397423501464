import {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Inputs, {getFormData} from "UI/App/Components/Form/Inputs";
import {getCountriesList} from "../../../../../../../../App/Util/countries";
import Block from "../../../../../../Components/Block/Block";
import Icon from "../../../../../../Components/Icon/Icon";
import strapiToast from "App/Util/errorHandle";
import Container from "UI/App/Components/Container/Container";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function CreateContactPerson() {
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();

    // get the params from the url
    const params = useParams();

    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    const { width } = useWindowDimensions();

    const [tags, setTags] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [countryNames, setCountryNames] = useState([]);
    const [countryLandCodes, setCountryLandCodes] = useState([]);
    const [companyAddress, setCompanyAddress] = useState([]);
    const [companyTelephone, setCompanyTelephone] = useState([]);

    useEffect(() => {
        getCountriesList().then((result) => {
            setCountryNames(result.countries);
            setCountryLandCodes(result.countryPhoneCodes);
        });
        getCompany();
        //console.log(params);
    }, [])

    useEffect(() => {
        if(tags.length > 0) {
            setInputData(tags)
        }
    }, [tags, width])

    useEffect(() => {
        if (countryNames?.length === 0 || countryLandCodes?.length === 0 || companyAddress?.length === 0) {
            return;
        }

        authAxios.get(`/config/crm`, {
            params: {
                populate: "*"
            }
        }).then(({data: {data}}) => {
            const tags = [];
            for (const tagGroup of data?.contactPersonTags ?? []) {
                if (!tagGroup.groupName || !tagGroup.tags) {
                    continue;
                }

                const tagOptions = [];
                for (const tag of tagGroup?.tags.split('\n') ?? []) {
                    tagOptions.push({value: tag, text: tag});
                }

                tags.push({
                    type: 'checkbox',
                    name: `tags[${tagGroup.groupName}]`,
                    label: tagGroup.groupName,
                    direction: 'row',
                    // component: 'data.tag-group',
                    options: [
                        ...tagOptions
                    ]
                });
            }

            setTags(tags);
        }).catch((exception) => {
            console.error(exception);
        });
    }, [countryNames, countryLandCodes, companyAddress]);

    function getCompany() {
        authAxios.get(`/crm/${isSupplier ? 'suppliers' : 'companies'}/` + params.companyId, {
            params: {
                populate: "address,telephone"
            }
        }).then(({data: {data}}) => {
            setCompanyTelephone(data.telephone)
            setCompanyAddress(data.address);
        }).catch((exception) => {
            console.error(exception);
        });
    }

    function setInputData(tags) {
        const inputs = [
            {
                type: 'hidden',
                name: !isSupplier ? 'company' : 'supplier',
                value: params.companyId,
                required: true,
            },
            {
                type: 'select',
                name: 'approach',
                label: 'Benadering',
                options: [{
                    text: 'Formeel',
                    value: 'formeel'
                },
                    {
                        text: 'Informeel',
                        value: 'informeel'
                    }],
                value: 'informeel',
                required: true,
            },
            {
                type: 'select',
                name: 'gender',
                label: 'Geslacht',
                options: [{
                    value: 'man',
                    text: 'Man'
                }, {
                    value: 'vrouw',
                    text: 'Vrouw'
                }, {
                    value: 'anders',
                    text: 'Anders'
                }],
                required: true,
            },
            {
                type: 'multiple',
                label: 'Naam',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'title',
                        placeholder: 'Titel...',
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'firstName',
                        placeholder: 'Voornaam...',
                        required: true,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'prefixToSurname',
                        placeholder: 'Tussenvoegsel...',
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },

                    },
                    {
                        type: 'text',
                        name: 'surname',
                        placeholder: 'Achternaam...',
                        required: true,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'titleAfter',
                        placeholder: 'Achter titel...',
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'text',
                name: 'function',
                label: 'Functie',
                placeholder: 'Functie...',
            },
            {
                type: 'multiple',
                name: 'address',
                label: 'Address',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'address[addressLine1]',
                        placeholder: 'Adres regel 1...',
                        defaultValue: (companyAddress?.addressLine1 !== undefined) ? companyAddress.addressLine1 : "",
                        style: {
                            width: '100%'
                        },
                        required: true,
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine2]',
                        defaultValue: (companyAddress?.addressLine2 !== undefined) ? companyAddress.addressLine2 : "",
                        style: {
                            width: '100%'
                        },
                        placeholder: 'Adres regel 2...',
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine3]',
                        defaultValue: (companyAddress?.addressLine3 !== undefined) ? companyAddress.addressLine3 : "",
                        style: {
                            width: '100%'
                        },
                        placeholder: 'Adres regel 3...',
                    },
                    {
                        type: 'text',
                        name: 'address[postalCode]',
                        placeholder: 'Postcode...',
                        defaultValue: (companyAddress?.postalCode !== undefined) ? companyAddress.postalCode : "",
                        required: true,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[city]',
                        placeholder: 'Stad...',
                        defaultValue: (companyAddress?.city !== undefined) ? companyAddress.city : "",
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[province]',
                        placeholder: 'Provincie...',
                        defaultValue: (companyAddress?.province !== undefined) ? companyAddress.province : "",
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'select',
                        name: 'address[country]',
                        placeholder: 'Land...',
                        value: (companyAddress?.country !== undefined) ? companyAddress.country : "",
                        options: countryNames,
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'email',
                name: 'email',
                label: 'E-mailadres',
                placeholder: 'E-mailadres...',
                required: true,
            },
            {
                type: 'email',
                name: 'personalEmail',
                label: 'Privé e-mailadres',
                placeholder: 'Privé e-mailadres...',

            },
            {
                type: 'multiple',
                name: 'telephone',
                label: 'Telefoonnummer',
                required: true,
                subFields: [
                    {
                        type: 'select',
                        name: 'telephone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        value: companyTelephone?.dialingCode,
                        required: true,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'telephone[telephoneNumber]',
                        placeholder: 'Telefoonnummer...',
                        defaultValue: companyTelephone?.telephoneNumber,
                        min: 0,
                        minLength: 5, // shortest possible it looks like (Solomon Islands without country code)
                        required: true,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            {
                type: 'multiple',
                name: 'personalCellPhone',
                label: 'Privé nummer',
                subFields: [
                    {
                        type: 'select',
                        name: 'personalCellPhone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'personalCellPhone[telephoneNumber]',
                        placeholder: 'Privé nummer...',
                        min: 0,
                        minLength: 5,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            {
                type: 'multiple',
                name: 'mobilePhone',
                label: 'Mobiel nummer',
                subFields: [
                    {
                        type: 'select',
                        name: 'mobilePhone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'mobilePhone[telephoneNumber]',
                        placeholder: 'Mobiel nummer...',
                        min: 0,
                        minLength: 5,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            ...tags,
            {
                type: 'submit',
                name: 'submit',
                title: 'Opslaan',
                class: 'btn'
            },
        ];

        setInputs(inputs);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const data = await getFormData(e, authAxios);
        const personal = data.personalCellPhone;
        const mobile = data.mobilePhone;

        data.personalEmail = data.personalEmail || null;

        if (personal['dialingCode'] === '' && personal['telephoneNumber'] === '') {
            data.personalCellPhone = null;
        }

        if (mobile['dialingCode'] === '' && mobile['telephoneNumber'] === '') {
            data.mobilePhone = null;
        }

        // Check if personalCellPhone is partially filled in and show error
        if ((personal?.dialingCode && !personal?.telephoneNumber) || (!personal?.dialingCode && personal?.telephoneNumber)) {
            toast.error('Privé nummer is niet volledig ingevuld');
            return;
        }

        // Check if mobilePhone is partially filled in and show error
        if ((mobile?.dialingCode && !mobile?.telephoneNumber) || (!mobile?.dialingCode && mobile?.telephoneNumber)) {
            toast.error('Mobiel nummer is niet volledig ingevuld');
            return;
        }

        console.log(params.companyId);
        console.log(data);
        authAxios.post(!isSupplier ? '/crm/companies/persons' : '/crm/suppliers/persons', {
            data
        }).then(() => {
            toast.success('Contactpersoon opgeslagen');

            navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`);
        }).catch((e) => {

            strapiToast(e);
            console.error(e);
        });
    }

    return (
        <Container>
            <Block name={'createContactPerson'} title={<>
                <Link className={'btn btn--transparent'} to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`}><Icon name={'arrow-left'}/></Link>
                Contactpersoon toevoegen
            </>}>
                <form onSubmit={handleSubmit}>
                    <Inputs key={crypto.randomUUID()} inputs={inputs}/>
                </form>
            </Block>
        </Container>
    );
}
