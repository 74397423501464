import {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import debounce from 'lodash/debounce';
import {FetchContext} from 'App/Strapi/FetchContext';
import {formatDate} from 'App/Util/format';
import Block from 'UI/App/Components/Block/Block';
import Icon from 'UI/App/Components/Icon/Icon';
import {Spinner} from 'UI/App/Components/Spinner';

const msgRequired = 'Dit veld is verplicht!';

const operatorsQuery = stringify({
    filters: {
        confirmed: true
    },
    fields: ['id', 'username'],
    sort: ['username:ASC']
});

export default function MachineCreateUpdate({ type }) {
    // get the axios instance form the fetch context
    const { authAxios } = useContext(FetchContext);

    // navigation hook for navigating after creating a new machine
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        getValues
    } = useForm();

    const [machineData, setMachineData] = useState(false);
    const [machineName, setMachineName] = useState(false);
    const [isCalculating, setIsCalculating] = useState(false);
    const [operatorOptions, setOperatorOptions] = useState(null);
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [highestPosition, setHighestPosition] = useState(null);

    // get the params from the url
    const { machineId } = useParams();

    useEffect(() => {
        getHighestPosition();
        if (type === 'update') {
            const query = stringify({
                populate: {
                    operators: {
                        fields: ['id', 'username']
                    }
                }
            });

            authAxios
                .get(`/calculations/resources/machines/${machineId}?${query}`)
                .then(({ data }) => {
                    // set the machine data
                    setMachineData({
                        ...data,
                        operators: (data?.operators ?? []).map((operator) => {
                            return { value: operator.id, label: operator.username };
                        })
                    });
                    setMachineName(data?.name);
                })
                .catch((e) => {
                    console.error(e);

                    toast.error(
                        `Er is iets fout gegaan bij het ophalen van de machine! ${
                            e?.response?.status && `(${e.response.status})`
                        }`
                    );
                });
        } else {
            setMachineData(true);
        }

        // get users for operator options
        authAxios
            .get(`../users?${operatorsQuery}`)
            .then(({ data }) => {
                if (!Array.isArray) throw new TypeError('Users is not an array');

                setOperatorOptions(
                    data.map((employee, i) => {
                        return { value: employee.id, label: employee.username };
                    })
                );
            })
            .catch((error) => {
                toast.error(`Er is iets fout gegaan bij het ophalen van de medewerkers!`);
                console.error(error);
            });

        // get departments for department options
        authAxios
            .get(`/calculations/resources/machines/departments`)
            .then(({ data }) => {
                setDepartmentOptions(
                    data.map((department) => {
                        return { value: department, label: department };
                    })
                );
            })
            .catch((error) => {
                toast.error(`Er is iets fout gegaan bij het ophalen van de afdelingen!`);
                console.error(error);
            });
    }, [type, authAxios, machineId]);

    function getHighestPosition(){
        authAxios
            .get(`/calculations/resources/machines/all?sort[1]=position:desc`)
            .then(({ data }) => {
                // Check if no high position
                if(data[0]?.position === null ){
                    setHighestPosition(1);
                }else{
                    setHighestPosition(data[0]?.position + 1)
                }
            })
            .catch((e) => {
                console.error(e);

                toast.error(
                    `Er is iets fout gegaan bij het ophalen van de machine! ${
                        e?.response?.status && `(${e.response.status})`
                    }`
                );
            });
    }


    /**
     * Shows a span with an error message if it exists
     * @param {string} name
     * @returns {undefined|JSX.Element}
     */
    const showErrorMsg = useCallback(
        (name) => {
            return (
                errors?.[name]?.message && (
                    <span className='form--error'>{errors?.[name].message}</span>
                )
            );
        },
        [errors]
    );

    /**
     * Function that will run once the
     * @param data
     */
    const onSubmit = useCallback(
        (data) => {
            data.operators = data.operators.map(({ value }) => value);

            const departmentIsNew = data?.department?.__isNew__;
            data.department = data?.department?.value ?? null;

            // send the update or create request
            const updateOrCreatePromise = () => {
                if (type === 'update') {
                    return authAxios
                        .put(`/calculations/resources/machines/${machineId}`, { data })
                        .then(({ data }) => {
                            setMachineName(data?.data?.name);

                            // add the created option to the options state
                            if (departmentIsNew)
                                setDepartmentOptions((options) => {
                                    return [...options, { label: data.department, value: data.department }].sort();
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                            return Promise.reject(error);
                        });
                } else {
                    return authAxios
                        .post(`/calculations/resources/machines`, { data })
                        .then(({ data }) => {
                            navigate(`../${data?.data?.id}/update`);
                            setMachineName(data?.data?.name);

                            // add the created option to the options state
                            if (departmentIsNew)
                                setDepartmentOptions((options) => {
                                    return [...options, { label: data.department, value: data.department }].sort();
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                            return Promise.reject(error);
                        });
                }
            };

            // create a toast
            toast.promise(updateOrCreatePromise, {
                pending: `Machine ${type === 'update' ? 'Bijwerken' : 'Aanmaken'}...`,
                success: `Machine ${type === 'update' ? 'Bijgewerkt' : 'Aangemaakt'}.`,
                error: {
                    render({ data }) {
                        return `Er is iets fout gegaan bij het ${
                            type === 'update' ? 'bijwerken' : 'aanmaken'
                        } van de machine ${data?.response?.status && `(${data.response.status})`}`;
                    }
                }
            });
        },
        [authAxios, machineId, navigate, type]
    );

    /**
     * Calls the calculate function after 1000ms of delay which resets as soon as the function gets called again
     * @type {DebouncedFunc<(function(): void)|*>}
     */
    const debounceCalculate = debounce(() => {
        calculateNewValues();
    }, 1000);

    /**
     * Calls the debounce function for calculating and sets isCalculating to display the spinner in the calculated fields
     */
    const calculate = useCallback(() => {
        setIsCalculating(true);
        debounceCalculate();
    }, [debounceCalculate]);

    /**
     * Sends a request to the api to calculate the new values for the printer and displays them
     */
    const calculateNewValues = useCallback(() => {
        // parse the values as number
        const values = {};
        for (const [key, value] of Object.entries(getValues())) {
            if (!isNaN(parseFloat(value))) {
                values[key] = parseFloat(value);
            }
            else {
                values[key] = value
            }
        }

        // send the calculate request
        authAxios.post(`/calculations/resources/machines/calculate`, values).then(({ data }) => {
            setMachineData((machine) => ({ ...machine, ...data }));
            setIsCalculating(false);
        });
    }, [authAxios, getValues]);

    return (
        <Block
            name='machineCreateUpdate'
            title={
                <>
                    <Link to={`/calculations/settings/machines/${machineId}/view`}>
                        <Icon name='arrow-left' />
                    </Link>
                    {`
                    ${machineName ? `"${machineName}"` : 'Machine'} ${
                        type === 'update' ? 'Aanpassen' : 'Aanmaken'
                    }`}
                </>
            }
            headerRightSideChildren={
                <span key='updatedAt'>
                    {type === 'update' && (
                        <i>
                            <b>Laatst bijgewerkt:</b>
                            <span style={{ marginLeft: '10px' }}>
                                {formatDate(machineData?.updatedAt)}
                            </span>
                        </i>
                    )}
                </span>
            }
        >
            {machineData ? (
                // Show the form as soon as the machine data has been set
                <form className='form' onSubmit={handleSubmit(onSubmit)} onBlur={calculate}>
                    {/* name */}
                    <div className='input-group'>
                        <label className='required'>Naam</label>
                        {showErrorMsg('name')}
                        <input
                            type='text'
                            {...register('name', {
                                required: msgRequired,
                                minLength: 3,
                                value: machineData?.name ?? ''
                            })}
                        />
                    </div>

                    {/* initialCost */}
                    <div className='input-group'>
                        <label className='required'>Aanschafwaarde</label>
                        {showErrorMsg('initialCost')}
                        <input
                            type='number'
                            {...register('initialCost', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.initialCost ?? ''
                            })}
                        />
                    </div>

                    {/* residualValue */}
                    <div className='input-group'>
                        <label className='required'>Rest waarde</label>
                        {showErrorMsg('residualValue')}
                        <input
                            type='number'
                            {...register('residualValue', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.residualValue ?? ''
                            })}
                        />
                    </div>

                    {/* depreciationYears */}
                    <div className='input-group'>
                        <label className='required'>Afschrijving jaren</label>
                        {showErrorMsg('depreciationYears')}
                        <input
                            type='number'
                            {...register('depreciationYears', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.depreciationYears ?? ''
                            })}
                        />
                    </div>

                    {/* yearlyDepreciation */}
                    <div className='input-group'>
                        <label className='function'>Jaarlijkse afschrijving</label>
                        <input
                            type='number'
                            value={machineData?.yearlyDepreciation ?? ''}
                            disabled
                            data-loading={isCalculating}
                        />
                    </div>

                    {/* serviceCost */}
                    <div className='input-group'>
                        <label className='required'>Service kosten</label>
                        {showErrorMsg('serviceCost')}
                        <input
                            type='number'
                            {...register('serviceCost', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.serviceCost ?? ''
                            })}
                        />
                    </div>

                    {/* occupationalHoursPerYear */}
                    <div className='input-group'>
                        <label className='required'>Bezettingsuren per jaar</label>
                        {showErrorMsg('occupationalHoursPerYear')}
                        <input
                            type='number'
                            {...register('occupationalHoursPerYear', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.occupationalHoursPerYear ?? ''
                            })}
                        />
                    </div>

                    {/* hourlyRate */}
                    <div className='input-group'>
                        <label className='function'>Uurtarief</label>
                        <input
                            type='number'
                            value={machineData?.hourlyRate ?? ''}
                            disabled
                            data-loading={isCalculating}
                        />
                    </div>

                    {/* laborEffort */}
                    <div className='input-group'>
                        <label className='required'>Arbeidsinspanning</label>
                        {showErrorMsg('laborEffort')}
                        <input
                            type='number'
                            {...register('laborEffort', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.laborEffort ?? ''
                            })}
                        />
                    </div>

                    {/* laborHours */}
                    <div className='input-group'>
                        <label className='function'>Arbeidsuren</label>
                        <input
                            type='number'
                            value={machineData?.laborHours ?? ''}
                            disabled
                            data-loading={isCalculating}
                        />
                    </div>

                    {/* employeeWage */}
                    <div className='input-group'>
                        <label className='required'>Uurloon personeel</label>
                        {showErrorMsg('employeeWage')}
                        <input
                            type='number'
                            step={0.01}
                            {...register('employeeWage', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.employeeWage ?? ''
                            })}
                        />
                    </div>

                    {/* productionCostPerHour */}
                    <div className='input-group'>
                        <label className='function'>Productie uurloon</label>
                        <input
                            type='number'
                            value={machineData?.productionCostPerHour ?? ''}
                            disabled
                            data-loading={isCalculating}
                        />
                    </div>

                    {/* grossSpeed */}
                    <div className='input-group'>
                        <label className='required'>
                            Bruto snelheid <span style={{color: 'gray'}}>(per uur)</span>
                        </label>
                        {showErrorMsg('grossSpeed')}
                        <input
                            type='number'
                            {...register('grossSpeed', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.grossSpeed ?? ''
                            })}
                        />
                    </div>

                    {/* speedUnit */}
                    <div className='input-group'>
                        <label className='required'>Snelheid eenheid</label>
                        {showErrorMsg('speedUnit')}
                        <input
                            type='text'
                            {...register('speedUnit', {
                                required: msgRequired,
                                min: 0,
                                value: machineData?.speedUnit ?? ''
                            })}
                        />
                    </div>

                    {/* uptime */}
                    <div className='input-group'>
                        <label className='required'>Uptime</label>
                        {showErrorMsg('uptime')}
                        <input
                            type='number'
                            {...register('uptime', {
                                required: msgRequired,
                                min: 0,
                                max: 100,
                                value: machineData?.uptime ?? ''
                            })}
                        />
                    </div>

                    {/* netSpeedPerHour */}
                    <div className='input-group'>
                        <label className='function'>
                            Netto snelheid <span style={{color: 'gray'}}>(per uur)</span>
                        </label>
                        <input
                            type='number'
                            value={machineData?.netSpeedPerHour ?? ''}
                            disabled
                            data-loading={isCalculating}
                        />
                    </div>

                    {/* startupTime */}
                    <div className='input-group'>
                        <label className='required'>
                            Opstart tijd <span style={{color: 'gray'}}>(minuten)</span>
                        </label>
                        {showErrorMsg('startupTime')}
                        <input
                            type='number'
                            {...register('startupTime', {
                                required: msgRequired,
                                min: 0,
                                max: 100,
                                value: machineData?.startupTime ?? ''
                            })}
                        />
                    </div>

                    {/* startupCost */}
                    <div className='input-group'>
                        <label className='function'>Opstart kosten</label>
                        <input
                            type='number'
                            value={machineData?.startupCost ?? ''}
                            disabled
                            data-loading={isCalculating}
                        />
                    </div>

                    {/* waste */}
                    <div className='input-group'>
                        <label className='required'>Inschiet</label>
                        {showErrorMsg('waste')}
                        <input
                            type='number'
                            {...register('waste', {
                                required: msgRequired,
                                min: 0,
                                max: 100,
                                value: machineData?.waste ?? ''
                            })}
                        />
                    </div>

                    {/* operators */}
                    <div className='input-group'>
                        <label>Operators</label>
                        {showErrorMsg('operators')}
                        <Controller
                            name='operators'
                            control={control}
                            defaultValue={machineData?.operators ?? []}
                            rules={{
                                minLength: 1
                            }}
                            render={({field}) => {
                                return (
                                    <Select
                                        {...field}
                                        options={operatorOptions}
                                        value={field?.value ?? []}
                                        placeholder='Selecteer werknemers die deze machine kunnen bedienen'
                                        isMulti
                                    />
                                );
                            }}
                        />
                    </div>

                    {/* department */}
                    <div className='input-group'>
                        <label>Afdeling</label>
                        {showErrorMsg('department')}
                        <Controller
                            name='department'
                            control={control}
                            defaultValue={
                                machineData?.department
                                    ? {label: machineData?.department, value: machineData?.department}
                                    : null
                            }
                            render={({field}) => {
                                return (
                                    <Creatable
                                        {...field}
                                        options={departmentOptions}
                                        value={field?.value ?? null}
                                        placeholder='Selecteer of type een nieuwe afdeling voor deze machine'
                                    />
                                );
                            }}
                        />
                    </div>

                    <button className='btn' type='submit'>
                        Opslaan <Icon name='save'/>
                    </button>
                </form>
            ) : (
                // Show the spinner while the machine data is not yet loaded
                <Spinner/>
            )}
        </Block>
    );
}
