import {SpinnerCenter} from "UI/App/Components/Spinner";
import {useNavigate, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import {useCallback, useContext, useEffect, useState} from 'react';
import IF from "UI/App/Components/Conditional/IF";

export default function PlanningCreator() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const [planningResponse, setPlanningResponse] = useState(false);

    const createPlanning = useCallback(async () => {
        // First get the planning from an order
        return await authAxios.get(`/orders/${id}/planning`).then(() => {
            // If the order has a planning, redirect to the project
            navigate(`/planning/orders/${id}`);
        }).catch(async (error) => {
            // If the order has no planning, create a new planning
            if (error?.response?.status === 404) {
                return await authAxios.post(`/orders/${id}/planning`).then(() => {
                    navigate(`/planning/orders/${id}`);
                }).catch((err) => {
                    if (err.response?.data?.error?.message) {
                        console.error(err.response?.data?.error?.message, err.response?.data?.error?.details);
                    } else {
                        console.error(err);
                    }
                    return Promise.reject('Er is iets misgegaan bij het aanmaken van de planning!');
                })
            } else {
                if (error.response?.data?.error?.message) {
                    console.error(error.response?.data?.error?.message, error.response?.data?.error?.details);
                } else {
                    console.error(error);
                }
                return Promise.reject('Er is iets misgegaan bij het ophalen van de planning!');
            }
        });
    }, [authAxios, id, navigate]);

    useEffect(() => {
        createPlanning().catch((res) => {
            setPlanningResponse(res);
        })
    }, []);

    return (
        <>
            <IF condition={!planningResponse}>
                <SpinnerCenter />
            </IF>
            <IF condition={planningResponse}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    textAlign: 'center'
                }}>
                    <span style={{
                        color: 'red',
                        fontSize: '14pt'
                    }}>{planningResponse}</span>
                    <small style={{color: 'gray'}}>Fouten kunnen voorkomen bij oude orders en calculaties.<br/>Open de calculatie, loop deze door en sla daarna op.<br/>Probeer het daarna opnieuw.</small>
                </div>
            </IF>
        </>
    );

}