import './requestAccess.scss';
import {defaultLogo} from "../../../../App/Strapi/ConfigContext";
import {Link} from "react-router-dom";
import {useState} from "react";
import spinner from "../../../Assets/Spinners/spinner-primary.svg";
import {publicFetch} from "../../../../App/Util/fetch";

export default function RequestAccess({token, blocked}) {
    const _STATUS = {
        BASE: 0,
        REQUESTING: 1,
        DENIED: 2,
        ACCEPTED: 3,
        UNCHANGED: 4
    }

    const [pingInterval, setPingInterval] = useState(false);
    const [accessStatus, setAccessStatus] = useState(blocked ? _STATUS.REQUESTED_DENIED : _STATUS.BASE);

    function requestAccess() {
        setAccessStatus(_STATUS.REQUESTING);
        publicFetch.get('/users/me?populate=role', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then(({data}) => {
            if (typeof data?.role?.name !== 'undefined' && data?.role?.name !== 'Guest') {
                setAccessStatus(_STATUS.ACCEPTED);
            } else {
                setAccessStatus(_STATUS.UNCHANGED);
            }
        }).catch((reason) => {
            setAccessStatus(_STATUS.DENIED);
        });
    }

    if (accessStatus === _STATUS.DENIED) {
        return (
            <div className={'request-access__wrapper'}>
                <div className={'request-access__container'}>
                    <img className='request-access__logo' src={defaultLogo} alt='logo'/>
                    <div className={'request-access__request-blocked'}>
                        <span className={'request-access__blocked'}><span className={'cross'}>&times;</span>Uw aanvraag is afgewezen.</span>
                        <span>Als u denk dat dit een fout is, neem dan contact op met een admin.</span>
                        <div className={'request-access__actions'}>
                            <Link className={'btn btn--gray'} to={'/logout'}>Uitloggen</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'request-access__wrapper'}>
            <div className={'request-access__container'}>
                <img className='request-access__logo' src={defaultLogo} />
                {accessStatus === _STATUS.ACCEPTED && <span className={'request-access__message'}>Toegang verkregen! Log opnieuw in om <span className={'emily'}>E<span>m</span>ily</span> te gebruiken!</span>}
                {accessStatus !== _STATUS.ACCEPTED && <span className={'request-access__message'}>U heeft op dit moment nog geen toegang tot <span className={'emily'}>E<span>m</span>ily</span></span>}
                {accessStatus === _STATUS.REQUESTING && <span className={'spinner__container'}><img className="spinner" src={spinner} width="50px" height="50px" alt='spinner' /> Toegang controleren...</span>}
                <div className={'request-access__actions'}>
                    <Link className={'btn btn--gray'} to={'/logout'}>Uitloggen</Link>
                    <button onClick={requestAccess} disabled={accessStatus === 1}>{accessStatus ? 'Toegang controleren' : 'Controleer toegang'}</button>
                </div>
            </div>
        </div>
    )
}
