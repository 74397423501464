export default function CompanyDetail({title, display = true, content}) {
	// if the content (lines) is empty, return nothing
	if (!Array.isArray(content) || content?.filter(line => Boolean(line))?.length === 0 || !Boolean(display)) {
		return null;
	}

	return (
		<div style={{overflow: 'hidden'}} className='company__item'>
			<span className='company-details__title'>{title}</span>
			{
				content?.map((line, index) => {
					return line ? <div key={`${index}-${line}`} className='company-details__line'>{line}</div> : null;
				})
			}
		</div>
	)
}