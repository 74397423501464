import {Fragment, useContext, useEffect, useState} from 'react';
import 'UI/App/Partials/Content/CRM/Quotes/quote.scss';
import Button from 'UI/App/Components/Button/Button';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import 'react-quill/dist/quill.snow.css';
import 'UI/App/Css/pdf-mockup.scss';
import {AuthContext} from "App/Strapi/AuthProvider";
import {formatName, formatStringCurrency, mmToPx} from "App/Util/format";
import IF from "UI/App/Components/Conditional/IF";
import QuotePDFGenerator from "./QuotePDFGenerator";
import {getNumLines} from "App/Util/getVisibleTextWidth";

const _PRICE_REGEX = /^[$€][\s ]?[\d.,]+$/;

export default function QuotePdfEditor({
    footerText,
    setFooterText,
    footerData,
    setFooterData,
    placeOfOrigin,
    setPlaceOfOrigin,
    introLine,
    setIntroLine,
    price,
    setPrice,
    formattedRows,
    setFormattedRows,
    rowData,
    setRowData,
    materialStorage,
    setMaterialStorage,
    date,
    setDate,
    endDate,
    setEndDate,
    chosenSupplier,
    chosenContactPerson,
    language,
    chosenMaterials,
    selectedBackground,
    number,
    signature,
    owner,
    sender
}) {

    // Default states
    const { authState } = useContext(AuthContext);

    // States for rendering
    const [company, setCompany] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);

    let [zoom, setZoom] = useState(0.5);

    // Get supplier data from id
    useEffect(() => {


        if (chosenSupplier === null) {
            return;
        }
        setCompany(chosenSupplier);
        return;


    }, [chosenSupplier])

    useEffect(() => {
        new QuotePDFGenerator(selectedBackground, rowData, chosenSupplier, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, sender)
            .formatForPdf(formattedRows, true)
            .then((reformattedRows) => {
                setFormattedRows([...reformattedRows])
            });
    }, [introLine, footerText, footerData]);

    // Get contact person from id
    useEffect(() => {

        if (chosenContactPerson === null) {
            return;
        }
        setContactPerson(chosenContactPerson)

    }, [chosenContactPerson])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        handleResize()
    }, [])

    function handleResize() {
        let wrapperWidth = document.getElementById('pdf-mockup__wrapper')?.offsetWidth;
        let mockupWidth = document.getElementById('pdf-mockup__page')?.offsetWidth;
        let zoom = Math.min(1 / mockupWidth * wrapperWidth, 1);

        setZoom(zoom)
    }

    // Add new footer row
    async function addFooterRow(e = null) {
        const key = parseInt(e.currentTarget.getAttribute('rowkey'));

        const filteredFooterData = footerData;

        filteredFooterData.splice(key, 0, { label: '', content: '' });

        setFooterData([...filteredFooterData])

        const reformattedRows = await new QuotePDFGenerator(selectedBackground, rowData, company, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, sender).formatForPdf(formattedRows, true);

        setFormattedRows([...reformattedRows])
    }

    // Remove row from footer
    async function removeFooterRow(e) {
        const key = parseInt(e.currentTarget.getAttribute('rowkey'));
        const filteredFooterData = footerData;

        filteredFooterData.splice(key, 1)

        setFooterData([...filteredFooterData])

        const reformattedRows = await new QuotePDFGenerator(selectedBackground, rowData, company, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, sender).formatForPdf(formattedRows, true);

        setFormattedRows([...reformattedRows])
    }

    // Add new row
    async function addRow(e = null) {
        const key = parseInt(e.currentTarget.getAttribute('rowkey'));
        const page = parseInt(e.currentTarget.getAttribute('pagekey'));

        const filteredEntries = formattedRows;
        // If row key is not set add as first row of page
        if(isNaN(key)){
            filteredEntries[page].splice(0, 0, {label: '', content: ''});
        }else{
            filteredEntries[page].splice(key + 1, 0, {label: '', content: ''});
        }

        const reformattedRows = await new QuotePDFGenerator(selectedBackground, rowData, company, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, sender).formatForPdf(filteredEntries, true);

        setFormattedRows([...reformattedRows])
    }

    // Remove row from storage
    async function removeRow(e) {
        const key = e.currentTarget.getAttribute('rowkey');
        const page = e.currentTarget.getAttribute('pagekey');
        const filteredEntries = formattedRows;

        filteredEntries[page].splice(key, 1)

        const reformattedRows = await new QuotePDFGenerator(selectedBackground, rowData, company, chosenContactPerson, owner, true, language, date, signature, placeOfOrigin, introLine, footerData, footerText, endDate, sender).formatForPdf(filteredEntries, true);

        setFormattedRows([...reformattedRows])
    }

    // Update label and content
    const updateRowData = (e) => {
        const key = parseInt(e.target.getAttribute('data-material-key'));
        const page = parseInt(e.target.getAttribute('data-material-page'));
        const filteredEntries = formattedRows;

        // Set value to storage at page/key
        filteredEntries[page][key][e.target.name] = e.target.value

        // check if the content of this row is a valid price
        if (_PRICE_REGEX.test(filteredEntries[page][key].content)) {
            filteredEntries[page][key]['type'] = 'price';
        } else {
            filteredEntries[page][key]['type'] = 'text';
        }


        setFormattedRows([...filteredEntries])
    }

    const updateFooterData = (e) => {
        const key = parseInt(e.target.getAttribute('data-material-key'));

        const filteredFooterData = footerData;

        filteredFooterData[key][e.target.name] = e.target.value

        setFooterData([...filteredFooterData])
    }

    const updatePriceData = (e) => {
        // Gather page and key for rows
        const key = parseInt(e.target.getAttribute('data-material-key'));
        const page = parseInt(e.target.getAttribute('data-material-page'));
        const filteredEntries = formattedRows;

        // Set value to storage at page/key
        filteredEntries[page][key][e.target.name] = e.target.value.replaceAll(' ', '');
        //filteredEntries[page][key]['type'] = 'price';
        filteredEntries[page][key]['displayType'] = 'price';

        setFormattedRows([...filteredEntries])
    }

    // Get content for pdf
    const getContent = () => {

        let topSpacing = <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </>

        let header = <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <div className='pdf-mockup__row'>
                <strong>{language === 'nl' ? 'OFFERTE' : 'OFFER'}</strong>
            </div>

            <br/>
            <br/>

            <div className='pdf-mockup__row'>
                <Grid columns='1' gap='0'>
                    <IF condition={company !== undefined}>
                        <IF condition={company?.name}>
                            <span>{(company?.name ?? '').trim()}</span>
                        </IF>

                        <IF condition={contactPerson !== null}>
                            <span>
                                {formatName(contactPerson?.firstName, contactPerson?.prefixToSurname, contactPerson?.surname)}
                            </span>
                        </IF>

                        <IF condition={company?.address?.addressLine1}>
                            <span>{company?.address?.addressLine1}</span>
                        </IF>

                        <IF condition={company?.address?.addressLine2}>
                            <span>{company?.address?.addressLine2}</span>
                        </IF>

                        <IF condition={company?.address?.addressLine3}>
                            <span>{company?.address?.addressLine3}</span>
                        </IF>

                        <IF condition={company?.address?.postalCode && company?.address?.city}>
                            <span>
                                {/* double space is in original document */}
                                {`${company?.address?.postalCode.toUpperCase()}  ${company?.address?.city.toUpperCase()}`}
                            </span>
                        </IF>

                        <IF condition={company?.address?.country}>
                            <span>{company?.address?.country}</span>
                        </IF>
                    </IF>
                </Grid>
            </div>

            <br/>
            <br/>


            <div className='pdf-mockup__row'>
                <span>
                    <input
                        value={placeOfOrigin}
                        onChange={(e) => {
                            setPlaceOfOrigin(e.target.value)
                        }}
                        style={{
                            marginLeft: 0
                        }}
                    />,
                </span>
                <span>
                    <input
                        type='date'
                        min={new Date().toLocaleDateString('fr-ca')}
                        value={date ?? ''}
                        onChange={({target}) => {
                            setDate(target.value);
                        }}
                        style={{
                            marginLeft: '10px !important',
                            textAlign: 'center',
                            borderBottom: 'none'
                        }}
                    />
                </span>
            </div>

            <br/>
            <br/>

            <div className='pdf-mockup__row'>
                {contactPerson !== null ?
                    <span>
                        {language === 'nl' ? 'Beste' : 'Dear'} {contactPerson.firstName},
                        <br/>
                    </span>
                    : <></>}
            </div>

            <br/>

            <div className='pdf-mockup__row'>
                <Grid columns={1}>
                    <textarea
                        value={introLine}
                        onChange={(e) => {
                            setIntroLine(e.target.value)
                        }}
                        style={{
                            background: 'none',
                            border: 'none',
                            borderBottom: 'dashed lightgrey 1px'
                        }}
                        rows={getNumLines(introLine, '10pt helvetica', mmToPx(210 - 21 - 21))}
                    ></textarea>
                    {/*<input value={language === 'nl' ? 'Hartelijk dank voor je aanvraag. Wij bieden u graag het volgende aan:' : 'Thank you for your request. We’re pleased to send your offer.'} />*/}
                    {/*<input value={introLine} onChange={(e) => {*/}
                    {/*    setIntroLine(e.target.value)*/}
                    {/*}}/>*/}
                </Grid>
            </div>
        </>


        let footerClosingText = <>
            <br/>
            <textarea
                value={footerText}
                onChange={(e) => {
                    setFooterText(e.target.value)
                }}
                rows={getNumLines(footerText, '10pt helvetica', mmToPx(210 - 21 - 21))}
                style={{
                    textAlign: 'left',
                    border: 'none',
                    borderBottom: '1px dashed lightgrey',
                    width: '100%',
                    background: 'none',
                    color: 'black',
                    overflowY: 'auto',
                    paddingLeft: '2px',
                    borderRadius: 0
                }} />
        </>

        let footer = <>
            <br />
            <span style={{ fontWeight: 'bold', }}>{language === 'nl' ? 'Leveringsvoorwaarden' : 'Terms and Conditions'}</span>
            <Grid
                gap='0'
                customColTemplate='45mm 2mm 1fr 9mm'
                style={{ alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                key={`Geldig`}
                columns={3}
            >
                <span className='pdf-mockup__row'>
                    <input
                        value={language === 'nl' ? 'Geldig tot' : 'Offer valid'}
                        style={{ margin: 0 }}
                        name="label"
                        data-material-key={"validTill"}
                        onChange={(e) => { updateFooterData(e) }}
                    />
                </span>
                <span>
                    :
                </span>
                <span className='pdf-mockup__row'>

                    <input
                        name="content"
                        type='date'
                        data-material-key={"validTill"}
                        min={new Date().toLocaleDateString('fr-ca')}
                        value={endDate ?? ''}
                        onChange={({ target }) => {
                            setEndDate(target.value);
                        }}
                        style={{ margin: 0 }}
                    />
                </span>

            </Grid>
            {footerData.length !== 0 ? footerData.map((data, key) => {
                let footerSnippet = <>
                    <Grid
                        gap='0'
                        customColTemplate='45mm 2mm 1fr 9mm'
                        style={{ alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                        key={`Geldig`}
                        columns={3}
                    >
                        <span className='pdf-mockup__row'>
                            <input
                                value={data.label}
                                style={{ margin: 0 }}
                                name="label"
                                data-material-key={key}
                                onChange={(e) => {updateFooterData(e)}}
                            />
                        </span>
                        <span>
                            :
                        </span>
                        <span className='pdf-mockup__row'>
                            <input
                                name="content"
                                style={{ margin: 0 }}
                                value={data.content}
                                data-material-key={key}
                                onChange={(e) => {updateFooterData(e)}}
                            />
                        </span>
                        <span>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                rowkey={key}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={(e) => {
                                    if (signature !== null) removeFooterRow(e)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='times'/>
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                rowkey={key}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '1mm'
                                }}
                                onClick={(e) => {
                                    if (signature !== null) addFooterRow(e)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle'/>
                            </Button>
                        </span>
                    </Grid>
                </>
                return footerSnippet;
            }) : <></>}
        </>

        // All content is stored in this variable
        let content = []

        let rowKey = 0;
        let pageKey = 0;

        // setPages(rows);
        for (let page = 0; page < (formattedRows.length !== 0 ? formattedRows.length : 1); page++) {
            content.push(<div
                key={`page-${page}`}
                className='pdf-mockup__page'
                id="pdf-mockup__page"
                style={{
                    backgroundImage: `url('${(page === 0 ? selectedBackground.page1 : selectedBackground.page2)}')`,
                    zoom: zoom,
                    MozTransform: `scale(${zoom})`
                }}
            >
                {page === 0 ? header : topSpacing}
                <br/>
                {page === 0 ? <Grid
                    columns='3'
                    customColTemplate={'1fr 5mm'}
                    gap={5}
                    style={{
                        justifyItems: 'start'
                    }}
                >
                    <span></span>
                    <Button
                        className='btn--transparent btn--round btn--icon-green'
                        pagekey={pageKey}
                        style={{
                            padding: 0,
                            backgroundColor: 'var(--white)',
                            marginLeft: '1mm'
                        }}
                        onClick={(e) => {
                            if (signature !== null) addRow(e)
                        }}
                        title='Regel toevoegen'
                    >
                        <Icon name='plus-circle'/>
                    </Button>
                </Grid> : <></>}
                {formattedRows.length !== 0 ? formattedRows[page].filter(data => data).map((data, i) => {
                    const rowContent = <Grid
                        gap='0'
                        customColTemplate='45mm 2mm 1fr 9mm'
                        style={{alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                        key={`material-${rowKey}`}
                        columns={4}
                    >
                        <span>
                            <input name="label" className="material__item__input" data-material-key={i} data-material-page={page} value={data.label ?? ''} onChange={(e) => {
                                updateRowData(e)
                            }}/>
                        </span>
                        <span>:</span>
                        <span>
                            <input
                                name="content"
                                style={{ textAlign: (data.type === 'price' || data.type === 'HJMGPrice') || (data?.displayType === 'price' || data?.displayType === 'HJMGPrice') ? 'right' : 'left' }}
                                className="material__item__input" data-material-key={i}
                                data-material-page={page}
                                value={data.content ?? ''}
                                onChange={(e) => {
                                    (_PRICE_REGEX.test(e.target.value)) ? updatePriceData(e) : updateRowData(e)
                                }}
                                onBlur={(e) => {
                                    if (_PRICE_REGEX.test(e.target.value)) {
                                        e.target.value = formatStringCurrency(e.target.value);
                                        updatePriceData(e);
                                    }
                                }}
                            />
                        </span>
                        <span>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                rowkey={i}
                                pagekey={pageKey}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={(e) => {
                                    if (signature !== null) removeRow(e)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='times'/>
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                rowkey={i}
                                pagekey={pageKey}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '1mm'
                                }}
                                onClick={(e) => {
                                    if (signature !== null) addRow(e)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle'/>
                            </Button>
                        </span>
                    </Grid>

                    rowKey++

                    return rowContent
                }) : <></>}
                <br/>
                {pageKey + 1 === formattedRows.length ? <>
                    {footer}
                    {footerClosingText}
                    <br/>
                    <br/>
                    <div className='pdf-mockup__row'>
                        {language === 'nl' ? 'Met vriendelijke groet,' : 'Kind regards,'}
                    </div>
                    {owner === "pretore" && (
                        <div className='pdf-mockup__row'>
                            Pretore B.V.
                        </div>
                    )}
                    {owner === "hjmg" && (
                        <div className='pdf-mockup__row'>
                            HJ Media Groep B.V.
                        </div>
                    )}
                    <br />
                    {signature && (
                        <div className='pdf-mockup__row pdf__signature'>
                            <img src={signature} alt='handtekening'/>
                        </div>
                    )}
                    <br/>

                    <div className='pdf-mockup__row'>
                        <span>{sender ?? authState?.user?.username}</span>
                    </div>
                </> : <></>}
            </div>);

            pageKey++;
        }

        return content;
    }

    return (
        <div
            className='pdf-mockup__wrapper'
            data-pdf-for='purchase-quote'
            id="pdf-mockup__wrapper"
        >
            {getContent()}
        </div>
    );
}
