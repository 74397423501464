import React, {useContext, useEffect, useRef, useState} from "react";

import './createCalculation.scss';
import StepFour from "UI/App/Partials/Content/Calculations/Calculations/steps/StepFour";
import {FetchContext} from "App/Strapi/FetchContext";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {stringify} from "qs";
import Grid from "UI/App/Components/Grid/Grid";
import CalculationResult from "UI/App/Partials/Content/Calculations/Calculations/result/CalculationResult";
import {SpinnerOverlay} from "UI/App/Components/Spinner";

export default function CreateCalculationPopup({orderId}) {
    const _ORDER_QUERY = stringify({
        populate: {
            additionalMaterials: {
                populate: {
                    material: true
                }
            },
            company: {
                populate: ['managingCompany']
            },
            contactPerson: true,
            calculation: {
                populate: {
                    purchaseQuotes: {
                        fields: ['id', 'number'],
                    }
                }
            },
            quote: {
                populate: {
                    priceData: true,
                    contactPerson: true,
                    company: {
                        populate: ['telephone', 'managingCompany']
                    },
                    copies: {
                        fields: ['id', 'number'],
                    },
                }
            },
            stickers: {
                sort: ['id:desc'],
                populate: {
                    file: {
                        fields: ['id', 'name', 'mime']
                    }
                }
            },
            quoteConfirmation: {
                populate: {
                    quote: {
                        populate: {
                            contactPerson: true,
                            company: {
                                populate: ['telephone', 'managingCompany']
                            },
                            copies: {
                                fields: ['id', 'number'],
                            },
                        }
                    },
                    calculation: {
                        populate:'*',
                    }
                }
            },
            packingSlips: {
                fields: ['id', 'number'],
                sort: ['id:desc']
            },
            jobTickets: {
                fields: ['id', 'name', 'status', 'xml'],
            },
            project: {
                fields: ['id']
            },
            planning: {
                fields: ['id', 'machinePlanItems'],
                populate: {
                    machinePlanItems: {
                        populate: {
                            dayPlanning: {
                                populate: {
                                    noteUpdatedBy: true
                                }

                            },
                        }
                    },
                }
            }
        }
    });

    const [newAmount,setNewAmount] = useState(0)
    const [newMisses,setNewMisses] = useState(0)
    const [activeStep, setActiveStep] = useState(5);
    const {authAxios} = useContext(FetchContext);
    const params = useParams();

    // States for companies
    const [chosenContactPersonId, setChosenContactPersonId] = useState(null);
    const [chosenCustomerId, setChosenCustomerId] = useState(null);

    // States for template/calculation select
    const [chosenTemplate, setChosenTemplate] = useState(null);
    const [amountRows, setAmountRows] = useState([[100, 10]]);

    const [checklistData, setChecklistData] = useState([]);

    // States for result
    const [calculationValues, setCalculationValues] = useState([])
    const [rawTemplateData, setRawTemplateData] = useState([])
    const [totalResultJson, setTotalResultJson] = useState([])
    const [amounts, setAmounts] = useState([])
    const [json, setJson] = useState([])
    const [calculationData, setCalculationData] = useState(null);

    const [storedCalculation, setStoredCalculation] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [werkDerdenData, setWerkDerdenData] = useState([]);
    const [limitToOneAmount, setLimitToOneAmount] = useState(false);

    const [disabledSubmit,setDisabledSubmit] = useState(false);

    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);


    // UseEffect used to trigger order load
    useEffect(() => {
        if(orderId !== null || orderId !== undefined){
            load(orderId);
        }
    },[orderId])

    // Trigger on calculation complete
    useEffect(() => {

        if(updating && totalResultJson?.machineScheduleStorage !== undefined){
            updateOrder()
        }
    },[json,totalResultJson])

    useEffect(() => {
        setDisabledSubmit(isLoading)
    },[isLoading])

    // Trigger recalculation
    async function recalculateAndStore(){
        setDisabledSubmit(true);
        setUpdating(true);
        // setIsLoading(true);

        setTimeout(() => {
            recalculate();
        },1000)
    }

    // Function to trigger calculation with new amount
    async function recalculate(){
        // First add current amoutns
        let newAmounts = [];
        for(const currentAmountRow of amountRows){
            newAmounts.push(currentAmountRow)
        }

        // Push new amounts/misses to amount array
        newAmounts.push([
            parseInt(newAmount),
            parseInt(newMisses)
        ])

        setAmountRows(newAmounts)

        setTimeout(() => {
            setActiveStep(4);
            setTimeout(() => {
                setActiveStep(5)
            },1500)
        },500)
    }

    // Updates order data
    async function updateOrder(){
        //First update calculation
        let data = {...json};
        // Delete third party data to prevent submitting it.
        delete data.data.data.thirdParty;

        data.data.data.templateData = storedCalculation.data.templateData;
        data.data.checklistTemplateId = storedCalculation?.checklistTemplateId

        data.data.contactPerson = chosenContactPersonId;
        data.data.company = chosenCustomerId;
        data.data.customerReference = storedCalculation.customerReference;
        data.data.calculationTemplate = chosenTemplate;
        data.data.data.totalResultJson = totalResultJson;
        // data.data.projects = storedCalculation?.projects;
        data.data.projectDescription = storedCalculation?.projectDescription;
        data.data.owner = storedCalculation?.owner;
        data.data.language = storedCalculation?.language;

        data.data.data.length = storedCalculation?.data.length
        data.data.data.width = storedCalculation?.data.width

        // Checklist
        data.data.data.checklist = storedCalculation?.data?.checklist;
        data.data.data.checklistTemplateId = storedCalculation?.data?.checklistTemplateId;

        data = setSummary(data);

        await authAxios.put(`/calculations/${storedCalculation.id}?populate[purchaseQuotes][fields]=id`,
            data
        ).then(async ({data: returnData}) => {
            toast.info(`Calculatie ${returnData.data.number} bijgewerkt!`,{autoClose:1500});
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van calculatie!`);
        });

        // Update order
        // Update the order
        await authAxios.put(`/orders/${orderId}`, {
            data: {
                chosenAmount: newAmount,
            }
        }).then(async ({data: returnData}) => {
            toast.info(`Order aantal geupdate naar ${newAmount}!`,{autoClose:1500});
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van het order!`);
        });

        // remove planning
        await authAxios.delete(`/orders/${orderId}/planning`).then(() => {
            toast.success('Order uit planning verwijderd.');
        }).catch((error) => {
            console.error(error);
            toast.error('Er is iets misgegaan bij het verwijderen van de order uit de planning.');
        });

        setUpdating(false);
        window.location.reload();
    }

    // Function for loading all basic states from order
    async function load(orderId){
        // get the companies using the built params
        await authAxios
            .get(`/orders/${orderId}?${_ORDER_QUERY}`)
            .then(({data}) => {

                setStoredCalculation(data.data.calculation)
                setChosenContactPersonId(data.data.contactPerson.id)
                setChosenCustomerId(data.data.company.id)

                setLength(data.data.calculation.data.length);
                setWidth(data.data.calculation.data.width);

                let amounts = [];
                for (const key in data.data.calculation.data.amounts) {

                    amounts.push([
                        data.data.calculation.data.amounts[key],
                        data.data.calculation.data.misses[key]
                    ]);
                }
                setAmountRows(amounts)

                setChecklistData(data.data.calculation.checklist === undefined ? [] : data.data.calculation.checklist)
                setAmounts(data.data.calculation.data.amounts)
                setCalculationData(data.data.calculation)
                setChosenTemplate(data.data.calculation.data.templateData.id)

            })
            .catch((exception) => {
                toast.error('Er is iest mis gegaan met het ophalen van een order.')
                console.error(exception);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // Set summary
    function setSummary(data){

        // Create result to add data to
        const result = {};

        // Loop amounts to get specific values
        for (const amount of data.data.data.amounts) {

            const subTotal = data.data.data.calculationData['Totaal.Subtotaal']?.[amount] ?? 0;
            result[amount] = {
                amount: amount,
                subTotal: subTotal,
                margin: 50,
                workflow: 0,
                total: subTotal * 1.3,
                quote: subTotal * 1.3,
            };
        }

        // Set result
        json.data.data.summary = result

        return json;
    }

    return (
        <>
            <SpinnerOverlay visible={isLoading}>
            {/*<SpinnerOverlay visible={false}>*/}
                <Grid colums={2} customColTemplate='1fr'>
                    <Grid
                        style={{alignItems:'center'}}
                        colums={2}
                        customColTemplate='200px 1fr'
                    >
                        <b>Aantal</b>
                        <input
                            type='number'
                            value={newAmount}
                            onChange={(e) => setNewAmount(e.target.value)}
                        />
                        <b>Inschiet</b>
                        <input
                            type='number'
                            value={newMisses}
                            onChange={(e) => setNewMisses(e.target.value)}
                        />
                    </Grid>
                    <button disabled={disabledSubmit} onClick={() => recalculateAndStore()}>
                        Opslaan
                    </button>
                </Grid>
            </SpinnerOverlay>

            <span style={{display:'none'}}>
                <StepFour
                    checklistData={checklistData}
                    calculationData={calculationData}
                    activeStep={activeStep}
                    chosenCustomerId={chosenCustomerId}
                    totalResultJson={totalResultJson}
                    setTotalResultJson={setTotalResultJson}
                    setAmounts={setAmounts}
                    length={length}
                    width={width}
                    setCalculationValues={setCalculationValues}
                    setRawTemplateData={setRawTemplateData}
                    setJson={setJson}
                    amountRows={amountRows}
                    chosenTemplate={chosenTemplate}
                    editInfo={true}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    werkDerdenData={werkDerdenData}
                    setWerkDerdenData={setWerkDerdenData}
                    setLimitToOneAmount={setLimitToOneAmount}
                    copy={false}
                />
                <CalculationResult
                    viewTypeOverwrite={'advanced'}
                    checklistContent={undefined}
                    renderInfo={true}
                    rawTemplateData={rawTemplateData}
                    setTotalResultJson={setTotalResultJson}
                    amounts={amounts}
                    values={calculationValues}
                    rawTemplateJson={rawTemplateData}
                    data={json?.data?.data}
                    saveButtonEnabled={false}
                />
            </span>
        </>
);
}
