import React, {ReactElement} from "react"
import Select from 'react-select'
import CreatableSelect from "react-select/creatable";
import Grid from "UI/App/Components/Grid/Grid";
import IWidget from "./IWidget"
import WidgetBase from "./WidgetBase"
import {TrenderType} from "./WidgetTypes"

/**
 * Widget class
 * @extends WidgetBase @implements IWidget
 */
export default class IfWidget extends WidgetBase implements IWidget {
    callback: ((object: Object) => any);
    widgetData: String | null
    saveWidget: ((object: Object) => any);
    optionalWidgetData: object | undefined;
    debug: boolean;
    prefix: string | undefined;

    _COMPARISON_TYPES = [
        {value: '>', label: 'Groter dan'},
        {value: '<', label: 'Kleiner dan'},
        {value: '>=', label: 'Groter of gelijk aan'},
        {value: '<=', label: 'Kleiner of gelijk aan'},
        {value: '==', label: 'Gelijk aan'},
        {value: '!=', label: 'Niet gelijk aan'},
    ];

    _GLOBAL_TYPES = [
        {value: '&&', label: "En"},
        {value: '||', label: "Of"},
    ];

    _GRID_STYLE = {
        marginBottom: "15px",
        marginTop: "15px",
        position: "relative",
        border: "solid 1px gray",
        borderRadius: "10px",
        padding: "20px 15px 10px",
    }

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;
    }

    /**
     * @return ReactElement
     */
    public getPreviewHTML(): JSX.Element {
        const widgetData = {
            widgetType: 'IfWidget',
        }

        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>If widget</div>
    }

    /**
     * Returns from html
     */
    public getFormHTML(): JSX.Element {
        const widgetValues = this.getWidgetValues();

        return (<form onSubmit={(e) => this.handleSubmit(e)}>
            <h3>If widget</h3>
            <div className="input-group">
                Naam: <input type="text" name="title" />
            </div>

            <Grid columns={3} gap={"5px 10px"} style={this._GRID_STYLE} className="input-group">
                <strong style={{
                    position: "absolute",
                    top: "-10px",
                    left: "10px",
                    padding: "0 5px",
                    backgroundColor: "white",
                    fontWeight: "bold"
                }}>Voorwaarde 1</strong>

                <span>Waarde 1:</span>
                <span>Vergelijk type:</span>
                <span>Waarde 2:</span>

                <CreatableSelect
                    name="val1"
                    options={widgetValues}
                />
                <Select
                    name="type1"
                    options={this._COMPARISON_TYPES}
                />
                <CreatableSelect
                    name="val2"
                    options={widgetValues}
                />
            </Grid>

            <Select
                name="globalType"
                options={this._GLOBAL_TYPES}
                defaultValue={this._GLOBAL_TYPES[0]}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: "20px 0"
                    })
                }}
            />

            <Grid columns={3} gap={"5px 10px"} style={this._GRID_STYLE} className="input-group">
                <strong style={{
                    position: "absolute",
                    top: "-10px",
                    left: "10px",
                    padding: "0 5px",
                    backgroundColor: "white",
                    fontWeight: "bold"
                }}>Voorwaarde 2 (Optioneel)</strong>

                <span>Waarde 1:</span>
                <span>Vergelijk type:</span>
                <span>Waarde 2:</span>

                <CreatableSelect
                    name="val3"
                    options={widgetValues}
                />
                <Select
                    name="type2"
                    options={this._COMPARISON_TYPES}
                />
                <CreatableSelect
                    name="val4"
                    options={widgetValues}
                />
            </Grid>

            <hr />
            <br />

            {/* The value if true */}
            <div className="input-group">
                Waarde als waar:
                <CreatableSelect
                    name="valueIfTrue"
                    options={widgetValues}
                />
            </div>

            {/* The value if false */}
            <div className="input-group">
                Waarde als niet waar:
                <CreatableSelect
                    name="valueIfFalse"
                    options={widgetValues}
                />
            </div>

            <button type="submit">Opslaan</button>
        </form>)
    }

    public getEditWidgetForm(editData: { [key: string]: any }): JSX.Element {
        const widgetValues = this.getWidgetValues();
        const [selectedVal1] = WidgetBase.getSelectedValues(widgetValues, editData.value.val1);
        const [selectedType1] = WidgetBase.getSelectedValues(this._COMPARISON_TYPES, editData.value.type1);
        const [selectedVal2] = WidgetBase.getSelectedValues(widgetValues, editData.value.val2);

        const [selectedGlobalType] = WidgetBase.getSelectedValues(this._GLOBAL_TYPES, editData.value.globalType);

        const [selectedVal3] = WidgetBase.getSelectedValues(widgetValues, editData.value.val3);
        const [selectedType2] = WidgetBase.getSelectedValues(this._COMPARISON_TYPES, editData.value.type2);
        const [selectedVal4] = WidgetBase.getSelectedValues(widgetValues, editData.value.val4);

        const [selectedValIfTrue] = WidgetBase.getSelectedValues(widgetValues, editData.value.valueIfTrue);
        const [selectedValIfFalse] = WidgetBase.getSelectedValues(widgetValues, editData.value.valueIfFalse);

        return (<form onSubmit={(e) => this.handleEdit(e)}>
            <div className="input-group">
                Naam: <input type="text" name="title" defaultValue={editData.title} />
            </div>

            <Grid columns={3} gap={"5px 10px"} style={this._GRID_STYLE} className="input-group">
                <strong style={{
                    position: "absolute",
                    top: "-10px",
                    left: "10px",
                    padding: "0 5px",
                    backgroundColor: "white",
                    fontWeight: "bold"
                }}>Voorwaarde 1</strong>

                <span>Waarde 1:</span>
                <span>Vergelijk type:</span>
                <span>Waarde 2:</span>

                <CreatableSelect
                    name="val1"
                    options={widgetValues}
                    defaultValue={selectedVal1}
                />
                <Select
                    name="type1"
                    options={this._COMPARISON_TYPES}
                    defaultValue={selectedType1}
                />
                <CreatableSelect
                    name="val2"
                    options={widgetValues}
                    defaultValue={selectedVal2}
                />
            </Grid>

            <Select
                name="globalType"
                options={this._GLOBAL_TYPES}
                defaultValue={selectedGlobalType}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: "20px 0"
                    })
                }}
            />

            <Grid columns={3} gap={"5px 10px"} style={this._GRID_STYLE} className="input-group">
                <strong style={{
                    position: "absolute",
                    top: "-10px",
                    left: "10px",
                    padding: "0 5px",
                    backgroundColor: "white",
                    fontWeight: "bold"
                }}>Voorwaarde 2 (Optioneel)</strong>

                <span>Waarde 1:</span>
                <span>Vergelijk type:</span>
                <span>Waarde 2:</span>

                <CreatableSelect
                    name="val3"
                    options={widgetValues}
                    defaultValue={selectedVal3}
                />
                <Select
                    name="type2"
                    options={this._COMPARISON_TYPES}
                    defaultValue={selectedType2}
                />
                <CreatableSelect
                    name="val4"
                    options={widgetValues}
                    defaultValue={selectedVal4}
                />
            </Grid>

            <hr />
            <br />

            {/* The value if true */}
            <div className="input-group">
                Waarde als waar:
                <CreatableSelect
                    name="valueIfTrue"
                    options={widgetValues}
                    defaultValue={selectedValIfTrue}
                />
            </div>

            {/* The value if false */}
            <div className="input-group">
                Waarde als niet waar:
                <CreatableSelect
                    name="valueIfFalse"
                    options={widgetValues}
                    defaultValue={selectedValIfFalse}
                />
            </div>

            <input type="hidden" name={"id"} value={editData.id}/>
            <button type="submit">Opslaan</button>
        </form>)
    }

    /**
     * Handles submit from getFormHTML
     * @param event:any
     */
    handleSubmit(event: any): void {
        event.preventDefault();

        const data = {
            type: "IfWidget",
            title: event.target.elements.title.value,
            value: {
                val1: event.target.elements.val1.value,
                type1: event.target.elements.type1.value,
                val2: event.target.elements.val2.value,
                globalType: event.target.elements.globalType.value,
                val3: event.target.elements.val3.value,
                type2: event.target.elements.type2.value,
                val4: event.target.elements.val4.value,
                valueIfTrue: event.target.elements.valueIfTrue.value,
                valueIfFalse: event.target.elements.valueIfFalse.value,
            }
        };

        data.value.val3 = data.value.val3 || null;
        data.value.type2 = data.value.type2 || null;
        data.value.val4 = data.value.val4 || null;

        this.addWidgetToWidgetContainer(`If widget: ${data.title}`, data);
    }

    handleEdit(event: any) {
        event.preventDefault();

        const data = {
            type: "IfWidget",
            title: event.target.elements.title.value,
            value: {
                val1: event.target.elements.val1.value,
                type1: event.target.elements.type1.value,
                val2: event.target.elements.val2.value,
                globalType: event.target.elements.globalType.value,
                val3: event.target.elements.val3.value,
                type2: event.target.elements.type2.value,
                val4: event.target.elements.val4.value,
                valueIfTrue: event.target.elements.valueIfTrue.value,
                valueIfFalse: event.target.elements.valueIfFalse.value,
            }
        };

        data.value.val3 = data.value.val3 || null;
        data.value.type2 = data.value.type2 || null;
        data.value.val4 = data.value.val4 || null;

        this.updateWidget(event.target.elements.id.value, `If widget: ${data.title}`, data);
    }

    /**
     * Renders calculation form
     */
    public getCalculationForm() {
        this.addWidgetToCalculationForm(`If widget: ${this.optionalWidgetData.data.title}`, this.optionalWidgetData, this.optionalWidgetData.parentId)
        // return <div id={this.optionalWidgetData.id}>Floor</div>
    }

    /**
     * Renders preview form
     */
    public getPreviewFormHTML(): ReactElement {
        return <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.title} value={this?.optionalWidgetData?.value} />
    }

    /**
     * Returns formula string of given widget
     */
    public getFormulaString(): string {
        const value = this?.optionalWidgetData?.value;
        return `IF(${value.val1} ${value.type1} ${value.val2} ${value.val3 !== null ? ` ${value.globalType} ${value.val3} ${value.type2} ${value.val4}` : ""})`;
    }

    /**
     * Returns html for edit
     */
    getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer(`If widget: ${this.optionalWidgetData.data.title}`, this.optionalWidgetData, "", true);
    }

    getBadgeHTML() {
        return <span>If statement</span>;
    }


    /**
     * Calculation logic
     */
    calculationFunctionality(): any {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;

        const type1 = this.optionalWidgetData.data.value.type1;
        const val1 = this.getResultFromWidget(this.optionalWidgetData.data.value.val1, this.optionalWidgetData.prefix);
        const val2 = this.getResultFromWidget(this.optionalWidgetData.data.value.val2, this.optionalWidgetData.prefix);

        const globalType = this.optionalWidgetData.data.value.globalType;

        const type2 = this.optionalWidgetData.data.value.type2;
        const val3 = this.getResultFromWidget(this.optionalWidgetData.data.value.val3, this.optionalWidgetData.prefix);
        const val4 = this.getResultFromWidget(this.optionalWidgetData.data.value.val4, this.optionalWidgetData.prefix);

        const valueIfTrue = this.getResultFromWidget(this.optionalWidgetData.data.value.valueIfTrue, this.optionalWidgetData.prefix);
        const valueIfFalse = this.getResultFromWidget(this.optionalWidgetData.data.value.valueIfFalse, this.optionalWidgetData.prefix);

        if (val3 !== null && val4 !== null && type2 !== null) {
            const condition1 = this._parseCondition(val1, val2, type1, true, false);
            const condition2 = this._parseCondition(val3, val4, type2, true, false);

            switch(globalType) {
                case "&&":
                    this.optionalWidgetData.data.value.result = condition1 && condition2 ? valueIfTrue : valueIfFalse;
                    break;
                case "||":
                    this.optionalWidgetData.data.value.result = condition1 || condition2 ? valueIfTrue : valueIfFalse;
                    break;
                default:
                    this.optionalWidgetData.data.value.result = false;
                    break;
            }
        } else {
            this.optionalWidgetData.data.value.result = this._parseCondition(val1, val2, type1, valueIfTrue, valueIfFalse);
        }

        this.updateWidgetData(id, this.optionalWidgetData);

        /**
         * Handle debug
         */
        if (this.debug) {
            document.getElementById(id).innerHTML = "";
            document.getElementById(id).append(`[${this.optionalWidgetData?.prefix}${this.optionalWidgetData?.id}] If widget: ${this.optionalWidgetData.data.title}: ${val1} (${typeof val1}) ${this.optionalWidgetData.data.value.type1} ${val2} (${typeof val2}) ${val3 !== null ? `${globalType} ${val3} (${typeof val3}) ${type2} ${val4} (${typeof val4})` : ''} = ${this.optionalWidgetData.data.value.result}`)
            document.getElementById(id).classList.remove('hidden');
        } else {
            document.getElementById(id).innerHTML = "";
            document.getElementById(id).classList.add('hidden');
        }
    }

    _parseCondition(val1: any, val2: any, type: string, valueIfTrue: any, valueIfFalse: any) {
        const numberRegexp = /^[0-9]+(?:\.[0-9]+)?$/;
        // Check if both values are numbers
        if (numberRegexp.test(String(val1)) && numberRegexp.test(String(val2))) {
            // If they are, parse them to float.
            // This fixes an issue when comparing the value of a select input to a widget value.
            // The widget value would be returned as an integer while the select input value would be a string.
            val1 = parseFloat(val1);
            val2 = parseFloat(val2);
        }

        switch(type) {
            case '>':
                return val1 > val2 ? valueIfTrue : valueIfFalse;
            case '<':
                return val1 < val2 ? valueIfTrue : valueIfFalse;
            case '>=':
                return val1 >= val2 ? valueIfTrue : valueIfFalse;
            case '<=':
                return val1 <= val2 ? valueIfTrue : valueIfFalse;
            case '==':
                return val1 === val2 ? valueIfTrue : valueIfFalse;
            case '!=':
                return val1 !== val2 ? valueIfTrue : valueIfFalse;
            default:
                return null;
        }
    }
}
