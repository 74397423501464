import React, {useContext, useEffect, useState} from "react";
import Grid from "UI/App/Components/Grid/Grid";
import Select from "react-select";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import {formatDate} from "App/Util/format";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";


export default function CheckInStorage({warehouse, setWarehouse, chosenStorageSpot, setChosenStorageSpot}) {

    const {authAxios} = useContext(FetchContext);
    const [availableSpots, setAvailableSpots] = useState([]);
    const [chosenSpot, setChosenSpot] = useState(null);
    const [contentsName, setContentsName] = useState('');
    const [automaticContentsName, setAutomaticContentsName] = useState('');


    const sizeOptions = [
        {
            label: 'Europallet (80x120cm.)',
            value: 'Europallet (80x120cm.)'
        },
        {
            label: 'Pallet (80x60cm.)',
            value: 'Pallet (80x60cm.)'
        },
        {
            label: 'Mini pallet (40x60cm.)',
            value: 'Mini pallet (40x60cm.)'
        },
        {
            label: 'A4-doos (30x22cm.)',
            value: 'A4-doos (30x22cm.)'
        },
        {
            label: 'Anders',
            value: 'Anders'
        }
    ]
    const [chosenSize, setChosenSize] = useState(null);
    const [chosenAutomaticSize, setChosenAutomaticSize] = useState(null);

    const categoryOptions = [
        {
            label: 'Normale voorraad',
            value: 'Normale voorraad'
        },
        {
            label: 'Snelle voorraad',
            value: 'Snelle voorraad'
        },
        {
            label: 'Bulk voorraad',
            value: 'Bulk voorraad'
        }
    ]
    const [chosenCategory, setChosenCategory] = useState(null);
    const [chosenAutomaticCategory, setChosenAutomaticCategory] = useState(null);

    // Filter spots based on size
    useEffect(() => {
        if (chosenSize !== null && chosenCategory !== null && warehouse !== null) {

            let filteredSpots = [];

            for (const storageSpot of warehouse?.storage) {
                if (chosenSize.value === storageSpot.size && chosenCategory.value === storageSpot.category) {
                    filteredSpots.push({
                        label: `${storageSpot.name}${(storageSpot.contents !== '') ? ` | ${storageSpot.contents}` : ''}`,
                        value: storageSpot.id
                    })
                }
            }

            setAvailableSpots(filteredSpots);
        }
    }, [chosenSize, chosenCategory,warehouse]);

    useEffect(() => {
        console.log(chosenStorageSpot);
        if (chosenStorageSpot !== null) {
            setChosenSize({
                label: chosenStorageSpot.size,
                value: chosenStorageSpot.size
            },)
            setChosenCategory({
                label: chosenStorageSpot.category,
                value: chosenStorageSpot.category
            })
            setChosenSpot({
                label: chosenStorageSpot.name,
                value: chosenStorageSpot.id
            })

            setAutomaticContentsName(null);
            setChosenAutomaticSize(null);
            setChosenAutomaticCategory(null);
        }else{
            setChosenSize(null)
            setChosenCategory(null)
            setChosenSpot(null)

            setAutomaticContentsName(null);
            setChosenAutomaticSize(null);
            setChosenAutomaticCategory(null);
        }
    }, [chosenStorageSpot])

    useEffect(() => {

    },[chosenSpot])

    function checkInManually() {

        // Update storage spot
        authAxios.put(`/storage-spot/${chosenSpot.value}?populate=warehouse.storage`, {
            data: {
                size: chosenSize.value,
                category: chosenCategory.value,
                contents: contentsName,
                checkInTime: new Date()
            }
        }).then(({data}) => {
            console.log(data.data.warehouse);
            setWarehouse(data.data.warehouse)
            setChosenStorageSpot(null);
            closePopup()
            toast.success(`Ingeboekt!`,{
                type: "success",
                autoClose: 500
            });
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van de opslag positie`);
        });
    }

    async function automaticCheckIn(){
        let availableSpot = null;
        for(const storageSpot of warehouse.storage){
            if((storageSpot.contents === '' || storageSpot.contents === null) && storageSpot.size === chosenAutomaticSize.value && storageSpot.category === chosenAutomaticCategory.value){
                availableSpot = storageSpot;
                break;
            }
        }

        if(availableSpot === null){
            toast.error(`Geen positie voor ${chosenAutomaticSize.value} ${chosenAutomaticCategory.value} beschikbaar '${automaticContentsName}' is niet ingeboekt!`,{
                type: "error",
                autoClose: 1500
            })
        }else{
            console.log(availableSpot);
            // Update storage spot
            authAxios.put(`/storage-spot/${availableSpot.id}?populate=warehouse.storage`, {
                data: {
                    size: chosenAutomaticSize.value,
                    category: chosenAutomaticCategory.value,
                    contents: automaticContentsName,
                    checkInTime: new Date()
                }
            }).then(({data}) => {
                setWarehouse(data.data.warehouse)
                setChosenStorageSpot(null);

                setAutomaticContentsName(null);
                setChosenAutomaticSize(null);
                setChosenAutomaticCategory(null);

                closePopup()
                toast.success(`Ingeboekt!`,{
                    type: "success",
                    autoClose: 500
                });
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het bewerken van de opslag positie`);
            });
        }
    }

    return (
        <>
            <Grid columns={chosenStorageSpot !== null ? 1 : 2}>
                {chosenStorageSpot === null ?
                    <span style={{
                        pointerEvents: chosenSize !== null ? 'none' : 'all'
                    }}>
                    <h3>Automatische keuze</h3>
                        <Select
                            options={sizeOptions}
                            value={chosenAutomaticSize}
                            onChange={(e) => {
                                setChosenAutomaticSize(e)
                            }}
                        />
                        <label>
                            Categorie
                        </label>
                        <Select
                            options={categoryOptions}
                            value={chosenAutomaticCategory}
                            onChange={(e) => {
                                setChosenAutomaticCategory(e)
                            }}
                        />
                        <label>Omschrijving</label>
                        <input
                            disabled={chosenAutomaticSize === null}
                            type="text"
                            value={automaticContentsName}
                            onChange={((e) => {
                                setAutomaticContentsName(e.target.value)
                            })}
                        />
                        <button
                            disabled={chosenAutomaticSize === null || chosenAutomaticCategory === null || automaticContentsName === null}
                            onClick={() => {
                            automaticCheckIn();
                        }}>
                            Inboeken
                        </button>
                    </span> : <></>}
                <span style={{
                    pointerEvents: chosenAutomaticSize !== null ? 'none' : 'all'
                }}>
                        <h3>Zelf kiezen</h3>
                        {/*<label>*/}
                        {/*    Materiaal*/}
                        {/*</label>*/}
                        {/*<Select*/}
                        {/*    options={sizeOptions}*/}
                        {/*    value={chosenSize}*/}
                        {/*    onChange={(e) => {*/}
                        {/*        setChosenSize(e)*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <label>
                            Maat
                        </label>
                        <Select
                            options={sizeOptions}
                            value={chosenSize}
                            onChange={(e) => {
                                setChosenSize(e)
                            }}
                        />
                        <label>
                        Categorie
                        </label>
                        <Select
                            options={categoryOptions}
                            value={chosenCategory}
                            onChange={(e) => {
                                setChosenCategory(e)
                            }}
                        />
                        <label>
                            Posities
                        </label>
                        <Select
                            isDisabled={chosenSize === null || chosenCategory === null}
                            options={availableSpots}
                            value={chosenSpot}
                            onChange={(e) => {
                                console.log(e);
                                setChosenSpot(e)
                            }}
                        />
                        <label>Omschrijving</label>
                        <input
                            disabled={chosenSpot === null}
                            type="text"
                            value={contentsName}
                            onChange={((e) => {
                                setContentsName(e.target.value)
                            })}
                        />
                        <button
                            disabled={chosenSize === null || chosenCategory === null || chosenSpot === null || contentsName === ''}
                            onClick={() => {
                                checkInManually();
                            }}
                        >
                            Inboeken
                    </button>
                </span>
            </Grid>
        </>
    )
}
