import Table from "UI/App/Components/Table/Table";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import User from "App/Strapi/User";
import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import Grid from "UI/App/Components/Grid/Grid";


export default function MachinePlanningOverview() {
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();
    const [machines, setMachines] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [headers, setHeaders] = useState(["Machine", "Afdeling", "Netto snelheid", "Arbeidsinspanning", "Planning"])
    const [structure, setStructure] = useState(['100%', '150px', '250px', '200px', '120px'])

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadTableData();
    }, [machines]);

    function loadData(){
        if (User.hasRole(['admin'])) {
            setHeaders(["", "Machine", "Afdeling", "Netto snelheid", "Arbeidsinspanning", "Planning"])
            setStructure(['100px', '100%', '150px', '250px', '200px', '120px'])
        }

        // get the machines from the api
        authAxios
            .get(`/calculations/resources/machines/all?sort[1]=position:asc`)
            .then(({data}) => {
                setMachines(data);
            }).catch((e) => {
                console.error(e);
                toast.error(`Er is iets fout gegaan bij het ophalen van de machines! ${e?.response?.status && `(${e.response.status})`}`);
            });
    }

    function loadTableData() {
        // check for the data
        setTableData(machines
            .sort((a, b) => a.position - b.position)
            .map(/** @param {Record<string, any>} machine **/(machine) => {

            // If admin render position buttons
            if (User.hasRole(['admin'])) {
                return {
                    attributes: {
                        id: machine.id,
                    },
                    data: [
                        <Grid columns={2} gap={'5px'}>
                            <IF condition={machine.position !== null && machine.position !== 1}>
                                <button onClick={() => positionChange(machine,true)}>
                                    <Icon name={'arrow-up'}/>
                                </button>
                            </IF>
                            <IF condition={machine.position !== null}>
                                <button onClick={() => positionChange(machine,false)}>
                                    <Icon name={'arrow-down'}/>
                                </button>
                            </IF>
                        </Grid>,
                        machine.name,
                        machine.department,
                        machine?.netSpeedPerHour ? ((machine.netSpeedPerHour?.toFixed(0)) + " " + (machine?.speedUnit ?? '')) : '-',
                        machine?.laborEffort,
                        <div className={'table__action-buttons'}>
                            <Link data-title={'Planning bekijken'} className={'btn btn--transparent'} to={`/planning/machines/${machine.id}`} onClick={(e) => e.stopPropagation()}><Icon name={'calendar'}/></Link>
                        </div>
                    ]
                }
            } else {
                return {
                    attributes: {
                        id: machine.id,
                        key: machine.id,
                        onClick: () => {
                            navigate(`/planning/machines/${machine.id}`)
                        }
                    },
                    data: [
                        machine.name,
                        machine.department,
                        machine?.netSpeedPerHour ? ((machine.netSpeedPerHour?.toFixed(0)) + " " + (machine?.speedUnit ?? '')) : '-',
                        machine?.laborEffort,
                        <div className={'table__action-buttons'}>
                            <Link data-title={'Planning bekijken'} className={'btn btn--transparent'} to={`/planning/machines/${machine.id}`} onClick={(e) => e.stopPropagation()}><Icon name={'calendar'}/></Link>
                        </div>
                    ]
                }
            }
        }));
    }

    async function positionChange(targetMachine, up = true) {
        setMachines((machines) => {
            const newPosition = up ? targetMachine.position - 1 : targetMachine.position + 1;
            const machineIndex = machines.findIndex(machine => machine.id === targetMachine.id);
            const machineToUpdate = machines[machineIndex];
            const machineToReplace = machines.find(machine => machine.position === newPosition);


            if (machineToReplace) {
                machineToReplace.position = machineToUpdate.position;
            }
            machineToUpdate.position = newPosition;

            authAxios
                .put(`/calculations/resources/machines/${machineToUpdate.id}`, {data: {position: newPosition}})
                .catch((error) => {
                    console.error(error);
                    return Promise.reject(error);
                });

            if (machineToReplace) {
                authAxios
                    .put(`/calculations/resources/machines/${machineToReplace.id}`, {data: {position: machineToReplace.position}})
                    .catch((error) => {
                        console.error(error);
                        return Promise.reject(error);
                    });
            }

            return [...machines];
        });
    }

    return (
        <Table
            headers={headers}
            structure={{
                default: structure
            }}
            data={tableData}
            columnOverflowExceptions={[5]}
        />
    )
}
