import Icon from "UI/App/Components/Icon/Icon";
import "./alert.scss";

export default function Alert({
    children,
    type = "info",
}) {
    return (
        <div className={`alert alert__${type}`}>
            <Icon name={`fas ${getNotificationIcon(type)}`}/>
            {children}
        </div>
    );
}

function getNotificationIcon(type) {
    switch (type) {
        case 'success':
            return 'check-circle';
        case 'warning':
            return 'triangle-exclamation';
        case 'error':
            return 'exclamation-circle';
        default:
            return 'info-circle';
    }
}