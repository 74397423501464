import Select from "react-select";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import qs from "qs";
import {CalculationProviderContext} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import {getSelectedValue} from "App/Util/select2";

const _LANGUAGES = [
    { label: 'Nederlands', value: 'nl' },
    { label: 'Engels', value: 'en' },
]

export default function InfoTab() {
    const [customers, setCustomers] = useState([]);
    const [contactPersons, setContactPersons] = useState([]);
    //const [projectDescription, setProjectDescription] = useState('');

    const { authAxios } = useContext(FetchContext);
    const {
        activeTab,
        language,
        customer,
        setCustomer,
        setLanguage,
        contactPerson,
        setContactPerson,
        customerReference,
        setCustomerReference,
        projectDescription,
        setProjectDescription
    } = useContext(CalculationProviderContext);

    useEffect(() => {
        authAxios.get('/crm/companies/all?fields[]=name&sort=name:asc')
            .then(({ data }) => {
                setCustomers(data.map(customer => ({ label: customer.name, value: customer.id })));
            })
            .catch((err) => {
                console.error(err);
                toast.error("Er is iets fout gegaan bij het ophalen van de klanten!");
            });
    }, []);

    useEffect(() => {

        if (typeof customer !== 'number') {
            setContactPersons([]);
            return;
        }

        const params = qs.stringify({
            populate: {
                contactPersons: {
                    fields: [
                        'firstName',
                        'prefixToSurname',
                        'surname',
                    ]
                }
            },
            fields: ['name']
        })
        authAxios.get(`/crm/companies/${customer}?${params}`)
            .then(({ data }) => {
                if (!data?.data?.contactPersons.find(cp => cp.id === contactPerson)) {
                    setContactPerson(null);
                }

                setContactPersons(data?.data?.contactPersons.map(contactPerson => ({
                    label: `${contactPerson.firstName} ${contactPerson?.prefixToSurname ?? ''} ${contactPerson.surname}`.replaceAll('  ', ' '),
                    value: contactPerson.id
                })));
            }).catch((err) => {
                console.error(err);
                toast.error("Er is iets fout gegaan bij het ophalen van de contactpersonen!");
                setContactPersons([]);
            });
    }, [customer]);

    return (
        <div className={"hjmg__calculation__tab"} data-active={activeTab === 'information'}>
            <div className={"hjmg__calculation__info"}>
                <label>
                    Klant:
                    <Select
                        placeholder={'Selecteer een klant...'}
                        options={customers}
                        value={customers.find(c => c.value === customer) ?? ''}
                        onChange={newValue => setCustomer(newValue.value)}
                    />
                </label>

                <label>
                    Contactpersoon:
                    <Select
                        isDisabled={!customer}
                        placeholder={!customer ? 'Selecteer eerst een klant...' : 'Selecteer een contactpersoon...'}
                        options={contactPersons}
                        value={getSelectedValue(contactPerson, contactPersons) ?? ''}
                        onChange={newValue => setContactPerson(newValue.value)}
                    />
                </label>

                <label>
                    Klantreferentie:
                    <input name={'customerReference'} value={(customerReference ?? '')} onChange={e => setCustomerReference(e.target.value)} />
                </label>

                <label>
                    Taal:
                    <Select
                        placeholder={'Selecteer een taal...'}
                        options={_LANGUAGES}
                        value={_LANGUAGES.find(l => l.value?.toLowerCase() === language?.toLowerCase())}
                        onChange={newValue => setLanguage(newValue.value)}
                    />
                </label>
                <label>
                    Project omschrijving
                    <input value={projectDescription ?? ''} onChange={e => setProjectDescription(e.target.value)} placeholder={'Project omschrijving'} type={"text"} id={"projectDescription"} />
                </label>

            </div>
        </div>
    )
}