import './pageHeader.scss';

export default function PageHeader({
    title,
    children,
    color = 'white',
    sticky = false,
    beingEdited = false,
    className = '',
    ...props
}: {
    title: string | JSX.Element | JSX.Element[]
    children: JSX.Element | JSX.Element[],
    color?: 'primary' | 'primary-medium' | 'primary-light' | 'white',
    sticky?: boolean
    beingEdited?: boolean,
    className?: string,
    props?: Record<string, any>
}) {
    return (
        <div className={`page-header--${color} ${(sticky ? 'page-header--sticky': '')} ${className}`} {...props}>
            <span className={`page-header__title${(beingEdited ? '-edit' : '')}`}>
                {title}
            </span>

            <div className={'page-header__content'}>
                {children}
            </div>
        </div>
    )
}