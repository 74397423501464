import {FetchContext} from "App/Strapi/FetchContext";
import Popup, {openPopup,closePopup} from "UI/App/Components/Popup/Popup";
import WidgetCollection from "./Widgets/WidgetCollection";
import React, {useContext, useEffect, useState} from "react";
import Block from "UI/App/Components/Block/Block";
import Grid from "UI/App/Components/Grid/Grid";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import './widget.scss';
import Boolean from "../../../../Components/Form/Boolean";
import HasRole from "../../../../Components/Auth/HasRole";
import WidgetFormRenderer from "./Widgets/WidgetFormRenderer";
import {splitCamelCase} from "App/Util/format";
import Icon from "UI/App/Components/Icon/Icon";
import {clone} from "lodash";
import Select from "react-select";

export default function EditFlow() {
    const [popupContentCurrentIndex, setPopupContentCurrentIndex] = useState(0);
    const [chosenScheduleTextInput, setChosenScheduleTextInput] = useState(null);
    const [editPopupContent, setEditPopupContent] = useState(null);
    const [popupContent, setPopupContent] = useState([]);
    const [defaultName, setDefaultName] = useState('');
    const [widgetArrayOptions, setWidgetArrayOptions] = useState([]);
    const [rawWidgets, setRawWidgets] = useState([]);
    const [popupTitle, setPopupTitle] = useState('');
    const [viewPreview, setViewPreview] = useState('');
    const fetchContext = useContext(FetchContext);
    const [constants, setConstants] = useState([]);
    const [initial, setInitial] = useState(true);
    const [prefix, setPrefix] = useState(null);
    const [note, setNote] = useState([]);
    const params = useParams();
    let tmp = true;


    const [scheduleTextRows, setScheduleTextRows] = useState([]);

    /**
     * Get data on load
     */
    useEffect(() => {

        /**
         * Set prefix
         */
        setPrefix(params.widgetId);

        /**
         * Add const beginning
         */
        setConstants([]);

        /**
         * Initial check for debug
         */
        if (initial && tmp) {
            setInitial(false)
            tmp = false;

            /**
             * Get stored widget array
             */
            fetchContext.authAxios.get(`/calculations/resources/templates/${params.widgetId}?populate=*`,).then(({data: {data}}) => {
                setScheduleTextRows(data.widgetData.planningChecklist === undefined ? [] : data.widgetData.planningChecklist);
                setDefaultName(data.name)
                addWidgetsToFlow(data.widgetData, data.machine?.id);
                data?.widgetData?.note ? setNote(data?.widgetData?.note) : setNote("");

            }).catch((exception) => {
                console.log(exception);
            });
        }
    }, [])

    useEffect(() => {
        if (viewPreview) {
            document.getElementById('widgetTab').classList.add('hidden');
            document.getElementById('widgetPreviewTab').classList.remove('hidden');
            renderWidgets();
        } else {
            document.getElementById('widgetTab').classList.remove('hidden');
            document.getElementById('widgetPreviewTab').classList.add('hidden');
            document.getElementById('widgetPreviewTabContent').innerHTML = ''
        }
    }, [viewPreview])

    const renderWidgets = () => {
        // empty widget tab
        document.getElementById('widgetPreviewTabContent').innerHTML = '';

        setTimeout(function () { //Start the timer
            let widgets = [];
            for (const widget of document.getElementById('widget--container').children) {
                const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

                let tmpWidget = {
                    id: widget.id,
                    data: widgetData
                }

                widgets.push(tmpWidget);
            }

            new WidgetFormRenderer().renderForm(widgets, true, '0', `widgetPreviewTabContent`) //After 1 second, set render to true

            setTimeout(() => {
                calculateForm();
            }, 500)
        }, 100)
    }

    // Used to fill widget state
    function fillWidgetArray(){

        // Gather data and add to
        let options = []
        let rawWidgets = []
        for(const widget of document.getElementById('widget--container').children){
            const widgetData = JSON.parse(widget.getAttribute('widgetData'));

            if (widgetData.type === 'MaterialChoice') {
                const group = {
                    label: `[${widget.id}] ${widgetData.title}`,
                    options: []
                }
                for (const returnField of widgetData.value.returnFields) {
                    group.options.push({
                        label: `[${widget.id}] ${returnField}`,
                        value: `${widget.id}.${returnField}`
                    })
                }

                options.push(group);

                rawWidgets.push({
                    id: widget.id,
                    widgetData
                })

                continue;
            }

            options.push({
                label: `[${widget.id}] ${widgetData.title}`,
                value: widget.id
            })

            rawWidgets.push({
                id: widget.id,
                widgetData
            })
        }

        setRawWidgets(rawWidgets);
        setWidgetArrayOptions(options);
    }

    const calculateForm = () => {

        // Loop and add defaults
        for (const widget of document.getElementById('widget--container').children) {
            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
            // Add default values
            if (widgetData.type === "Input") {
                if (widgetData.title.toLowerCase() === "aantal") {
                    document.getElementById(`0-${widget.id}`).querySelector('.inputWidgetInput').value = 100
                }

                if (widgetData.title.toLowerCase() === "lengte product") {
                    document.getElementById(`0-${widget.id}`).querySelector('.inputWidgetInput').value = 150
                }

                if (widgetData.title.toLowerCase() === "breedte product") {
                    document.getElementById(`0-${widget.id}`).querySelector('.inputWidgetInput').value = 100
                }
            }
        }

        // Loop widgets
        for (const widget of document.getElementById('widget--container').children) {
            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
            let usableWidget = {
                id: widget.id,
                prefix: '0-',
                data: widgetData
            }

            new WidgetCollection(null, null, null, usableWidget, false, undefined).runCalculation(usableWidget.data.type);
        }

        setTimeout(() => {
            renderFormula();
            setTimeout(() => {
                toast.success('Klaar met berekenen');
            }, 500)
        }, 500)

    }

    const renderFormula = () => {

        let widgets = [];
        for (const widget of document.getElementById('widget--container').children) {
            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))
            let usableWidget = {
                id: widget.id,
                prefix: '0-',
                data: widgetData
            }
            widgets.push(usableWidget)
        }

        /**
         * Get formula
         */
        let formula = '';
        setTimeout(function () { //Start the timer
            formula = new WidgetFormRenderer().renderFormula(widgets, false, '0', `widgetPreviewFormula`) //After 1 second, set render to true
            parseFormula(formula, null, `widgetPreviewTabContent`);
            addValuesToFormula();
        }, 100)
    }

    const parseFormula = (formulaItems, parent, machinePrefix) => {
        let formulaString = '';

        for (const formulaItem of formulaItems) {
            if (formulaItem !== '') {

                if (formulaItem.includes('widget')) {
                    let result = getNameFromWidget(`${parent}`, formulaItem)

                    formulaString += `<span onClick="${() => selectFormulaRow(result)}">${result}</span><br>`;
                } else {

                    formulaString += `${formulaItem}<br>`
                }
            }
        }


        document.getElementById('widgetPreviewFormula').innerHTML = `${formulaString} <span></span> <hr>`;

        // formula-container
    }

    const selectFormulaRow = (string) => {
        console.log('select row');
        console.log(string);
    }

    const addValuesToFormula = () => {
        // for (const tab of document.getElementsByClassName('calculationTab')) {
        for (const widget of document.querySelector('#widgetPreviewTabContent').children) {
            const widgetData = JSON.parse(widget.getAttribute('widgetdata'))

            const resultElement = document.getElementById(`result_${widget.id}`);
            if (resultElement !== null) {
                let result = '';
                if (widgetData.data.type === 'Input') {
                    result = widget.querySelector('.inputWidgetInput').value;
                } else if (widgetData.data.type === 'MaterialChoice') {
                    result = widget.querySelector('select').value;
                } else if (widgetData.data.type === 'SelectWidget') {
                    result = widget.querySelector('.selectWidget').value;
                } else if (widgetData.data.type === 'Division' || widgetData.data.type === 'Times' || widgetData.data.type === 'Addition' || widgetData.data.type === 'Subtraction') {
                    result = widgetData.data.value.result;
                } else if (widgetData.data.type === 'Int') {
                    // result = widgetData.data.value.result;
                } else if (widgetData.data.type === 'CeilWidget' || widgetData.data.type === 'FloorWidget') {
                    result = widgetData.data.value.result;
                } else if (widgetData.data.type === 'ReturnWidget') {
                    result = widgetData.data.value.result;
                } else if (widgetData.data.type === 'IfWidget') {
                    result = widgetData.data.value.result;
                } else {
                    result = 'not found';
                }

                resultElement.innerHTML = result;
            }
        }
    }

    const getNameFromWidget = (parent, string) => {

        return string;
    }

    /**
     * Opens widget container popup
     */
    const openAddWidgetPopup = () => {
        console.log('load preview');
        /**
         * Set popup content at index 0 with all widgets
         */
        setPopupContent([<div className="popup__content__flex widgets">{new WidgetCollection("preview", selectWidget, storeWidget, null, false, prefix).getWidgets()}</div>]);
        setPopupTitle('Voeg een widget toe');
        openPopup('add', true, true, setPopupContentCurrentIndex);
    }

    const storeWidgetFlow = () => {
        let widgetStorage = {
            'widgets': [],
            'constants': [],
            'note': note
        };

        let position = 0;
        for (const widget of document.getElementById('widget--container').children) {

            const widgetData = JSON.parse(widget.getAttribute("widgetdata"));
            widgetData.position = position;

            widgetStorage.widgets.push({
                id: widget.id,
                uuid: widget.getAttribute("uuid"),
                data: widgetData
            });

            position++;
        }

        for (const constant of document.getElementsByClassName('constant')) {
            widgetStorage.constants.push({id: constant.id, name: constant.getAttribute('constname'), data: constant.getAttribute('value')});
        }

        // Store planning checklist
        widgetStorage.planningChecklist = scheduleTextRows;

        // Push to data
        const data = {
            "widgetData": widgetStorage,
            "name": document.getElementById('flow_name').value
        }

        fetchContext.authAxios.put(`/calculations/resources/templates/${params.widgetId}`, {data}).then(({data}) => {
            toast.success(`Widget flow opgeslagen!`);
        }).catch((exception) => {
            console.log(exception);
        });
    }

    const storeConst = (event) => {
        event.preventDefault()
        let val = document.getElementsByClassName("constant").length
        const key = (parseInt(val)) + 1;

        // console.log(<span><label htmlFor={"const-" + key}>{event.target.constName.value}</label><input widgettype="const" id={"const-" + key} className="constant" /></span>);
        // setConstants(prev => [...prev, <div key={key}><label htmlFor={"const-" + key}>{event.target.constname.value}</label><input widgettype="const" id={"const-" + key} constname={key} className="constant" type={event.target.consttype.value} value={event.target.constvalue.value} required readOnly={true} /></div>])
    }

    const addConst = () => {
        /**
         * Set popup content at index 0 with all widgets
         */
        setPopupContent([
            <form onSubmit={storeConst}>
                <div className="input-group">
                    <label htmlFor="constname">Naam:</label>
                    <input type="text" id="constname" name="constname"/>
                </div>
                <div className="input-group">
                    <label htmlFor="consttype">Type:</label>
                    <select name="consttype" id="consttype">
                        <option value="number">Getal</option>
                        <option value="text">Tekst</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="constvalue">Waarde: </label>
                    <input type="text" id="constvalue" name="constvalue"/>
                </div>
                <button className="btn">Toevoegen</button>
            </form>
        ]);
        setPopupTitle('Voeg een vaste waarde toe');
        openPopup('add', true, true, setPopupContentCurrentIndex);
    }

    /**
     * Stores widget to widgetsarray
     * @param data
     */
    const storeWidget = (data) => {
        console.log('store');
        console.log(data);
    }

    /**
     * select widget to store
     */
    const selectWidget = (event) => {
        /**
         * Create uniqueId
         */
        setPopupContent((prev) => [prev[0], new WidgetCollection("form", selectWidget, storeWidget, null, false, prefix).getWidget(event.widgetType)]);
        setPopupContentCurrentIndex(1);
    }


    function loadConsts(prefix) {
        /**
         * Fetch machines
         */
        if (prefix) {
            fetchContext.authAxios.get(`/calculations/resources/machines/${prefix}`, {
                params: {
                    populate: "*"
                }
            }).then(({data}) => {
                setConstants([]);
                for (const [key, value] of Object.entries(data)) {

                    if (typeof value === 'object') continue;

                    setConstants(constants => [
                        ...constants,
                        <span key={key}><label htmlFor={"const-" + key}>{splitCamelCase(key)}</label><input widgettype="const" id={"const-" + key} constname={key} className="constant" type="text" value={value} required readOnly={true}/></span>
                    ])
                }
            }).catch((exception) => {
                console.log(exception);
            });
        }
    }

    function updateNote(e) {
        setNote(e.target.value);
    }

    /**
     * Renders widgets as html in widgetcontainer
     * @param widgetArray
     */
    function addWidgetsToFlow(widgetArray, prefix) {

        for (const widget in widgetArray.widgets.sort((a, b) => a.data.position - b.data.position)) {
            if (widgetArray.widgets[widget].data.position === undefined) {
                widgetArray.widgets[widget].data.position = parseInt(widget)
            }
            new WidgetCollection("edit", null, null, widgetArray.widgets[widget], null, prefix).getWidget(widgetArray.widgets[widget].data.type)
        }


        setTimeout(() => {
            if (widgetArray.widgets[widgetArray.widgets.length - 1] !== undefined) {
                document.getElementById(widgetArray.widgets[widgetArray.widgets.length - 1].id)?.scrollIntoView({behavior: "smooth"});
            }
        }, 150)

        loadConsts(prefix);
        setTimeout(() => {

            fillWidgetArray();
        },1000)
    }

    window.moveWidgetLeft = (id) => {
        let oldWidgetArray = document.getElementsByClassName('flow_widget');

        /**
         * Check if widget id is used in any other widget
         */
        let widgetKey = null;
        let i = 0;
        for (const widget of oldWidgetArray) {
            if (widgetKey === null) {
                if (widget.id.split('-')[2] === id.split('-')[2]) {
                    widgetKey = i;
                }
            } else {
                break;
            }
            i++;
        }

        /**
         * Move widget
         * Loop up widget key
         */
        const newWidgetArray = array_move(Object.keys(oldWidgetArray).map((key) => [key, oldWidgetArray[key]]), widgetKey, widgetKey - 1);

        /**
         * Re add widgets
         * @type {string}
         */
        document.getElementById('widget--container').innerHTML = '';
        for (const newWidget of newWidgetArray) {
            document.getElementById('widget--container').append(newWidget[1])
        }

        document.getElementById(id).scrollIntoView({behavior: "smooth", inline: "center"});
    }

    window.moveWidgetRight = (id) => {
        let widgetDependencies = [];
        let dependencyString = ''
        let oldWidgetArray = document.getElementsByClassName('flow_widget');

        /**
         * Get place of widget
         */
        let widgetKey = null;
        let i = 0;
        for (const widget of oldWidgetArray) {
            if (widgetKey === null) {
                if (widget.id.split('-')[2] === id.split('-')[2]) {
                    widgetKey = i;
                }
            } else {
                break;
            }
            i++;
        }

        /**
         * Check if widget is used in next widget
         */
        for (const widget of oldWidgetArray) {
            if (widget.id.split('-')[2] > widgetKey) {
                if (widget.getAttribute('widgetdata').indexOf(`"${id}"`) !== -1 || widget.getAttribute('widgetdata').indexOf(`${id}.`) !== -1 || widget.getAttribute('widgetdata').indexOf(`.${id}`) !== -1) {

                    if (parseInt(widgetKey) + 1 == widget.id.split('-')[2]) {
                        widgetDependencies.push(widget.id);
                        dependencyString += `${widget.id} , `;
                    }
                }
            }
        }

        /**
         * If widget has dependencies return toast else remove and recalculate ids
         */
        if (widgetDependencies.length !== 0) {
            toast.warn(`Kan niet naar rechts widget is gebruikt in: ${dependencyString}`);
            return;
        }

        /**
         * Move widget
         * Loop up widget key
         */
        const newWidgetArray = array_move(Object.keys(oldWidgetArray).map((key) => [key, oldWidgetArray[key]]), widgetKey, widgetKey + 1);

        /**
         * Re add widgets
         * @type {string}
         */
        document.getElementById('widget--container').innerHTML = '';
        for (const newWidget of newWidgetArray) {
            document.getElementById('widget--container').append(newWidget[1])
        }

        document.getElementById(id).scrollIntoView({behavior: "smooth", inline: "center"});
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }

        // console.log(typeof arr);
        // let firstPart = arr.slice(0,new_index);
        // console.log(firstPart,arr);
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    }

    window.openEditWindow = (element) => {
        const parent = element.closest('.flow_widget');
        const widgetData = JSON.parse(parent.getAttribute('widgetdata'));
        widgetData.id = parent.getAttribute('id');

        setEditPopupContent('');

        setTimeout(() => {
            setEditPopupContent(new WidgetCollection("editForm", null, storeWidget, null, false, null).getWidget(widgetData.type, widgetData));
        }, 250)
        openPopup('edit');
    }

    window.removeWidget = (id) => {
        let widgetDependencies = [];
        let dependencyString = ''

        /**
         * Check if widget id is used in any other widget
         */
        for (const widget of document.getElementsByClassName('flow_widget')) {
            if (widget.getAttribute('widgetdata').indexOf(`"${id}"`) !== -1 || widget.getAttribute('widgetdata').indexOf(`${id}.`) !== -1 || widget.getAttribute('widgetdata').indexOf(`.${id}`) !== -1) {
                widgetDependencies.push(widget.id);
                dependencyString += `${widget.id} , `;
            }
        }

        /**
         * If widget has dependencies return toast else remove and recalculate ids
         */
        if (widgetDependencies.length !== 0) {
            toast.warn(`Kan niet verwijderen, Widget is gebruikt in: ${dependencyString}`);
            return;
        }

        toast.warn(`Widget: ${id} is verwijderd!`);
        document.getElementById(id).remove();
    }

    // Updating schedule text rows
    function updateTextRow(e, rowKey) {
        // Copy original
        const scheduleTextRowsCopy = clone(scheduleTextRows);

        // Add value
        scheduleTextRowsCopy[rowKey] = e.target.value

        setScheduleTextRows(scheduleTextRowsCopy);
    }

    function addWidgetIdToScheduleTextRow(e,rowKey){

        // Copy original
        const scheduleTextRowsCopy = clone(scheduleTextRows);

        scheduleTextRowsCopy[chosenScheduleTextInput] += ` ${e.value} `

        setScheduleTextRows(scheduleTextRowsCopy);
        closePopup('getWidgetId')
    }

    function translateScheduleTextRowIdWidgets(textRow){

        // If null return empty string
        if (typeof textRow !== 'string') return '';

        const widgetIds = textRow.matchAll(/(?<widgetId>\d+-widget-\d+)(?<follower>\.[A-z]+)?/gi);

        for (const widgetId of widgetIds) {
            const widget = rawWidgets.find(rawWidget => rawWidget.id === widgetId.groups.widgetId);
            textRow = textRow.replace(widgetId[0],`"${widget?.widgetData?.title}${widgetId.groups.follower ? `${widgetId.groups.follower}` : ''}"`);
        }

        return textRow
    }

    return (
        <>
            <Block name='collection__name' useSmallerPadding>
                Naam: <input type="text" id="flow_name" defaultValue={defaultName}/>
            </Block>
            <Grid columns={constants.length > 0 ? 2 : 1} customColTemplate={constants.length > 0 ? '300px 1fr' : '4fr'}>
                {constants.length > 0 &&
                    <Block name='collection__consts' title='CONST' headerRightSideChildren={<button onClick={addConst}>+</button>}>
                        <div id="const--storage">
                            {constants}
                        </div>
                    </Block>
                }

                <Grid columns='1' customColTemplate='auto'>
                    <Block name='collection__widgets' title='WIDGETS'
                           headerRightSideChildren={
                               <>
                                   <HasRole roles={['admin']}>
                                       Preview: <Boolean style={{backgroundColor: "#E2E2E2"}} value={viewPreview} displayFalse={"Uit"} field={{name: 'toggle-debug'}} setValue={(name, value) => {
                                       setViewPreview(value)
                                   }} displayTrue={"Aan"}/>
                                   </HasRole>
                                   <button type='button' onClick={openAddWidgetPopup}>+</button>
                                   <button onClick={storeWidgetFlow} className='btn--margin-l'>Opslaan</button>
                               </>
                           }>
                        <div className="widgetPreviewTab" id="widgetPreviewTab">
                            <span>
                                <button onClick={() => calculateForm()}>Bereken</button>
                                <small>Aantal is altijd 100 H*B = 100*150</small>
                            </span>
                            <br/>
                            <span id="widgetPreviewFormula">formula</span>
                            <br/>
                            <span id="widgetPreviewTabContent">
                            </span>
                        </div>
                        <div className="widgetTab" id="widgetTab">
                            <div className="widgets__wrapper">
                                <div className="widget--container" id="widget--container"/>
                            </div>
                        </div>
                    </Block>

                    <Block name='Extra information' title='Extra informatie:'>
                        <div className='widget__add'>
                            <textarea id='widget--note' onChange={updateNote} value={note} placeholder='' rows='4'></textarea>
                        </div>
                    </Block>

                    <Block
                        name='Planning informatie'
                        title='Planning informatie'
                        headerRightSideChildren={
                            <button
                                onClick={() => setScheduleTextRows(scheduleTextRows => [...scheduleTextRows, []])}
                                className="btn btn--black">
                                <Icon name="plus"/>
                            </button>
                        }
                    >
                        {scheduleTextRows
                            .map((row, index) => (
                                <Grid columns={2} customColTemplate={'1fr 40px 40px'} style={{ alignItems: 'center' }} gap={'0px'}>
                                    <small style={{ gridColumn: '1/4', color: 'grey' }}>{translateScheduleTextRowIdWidgets(row)}</small>
                                    <input
                                        value={row}
                                        onChange={(e) => {
                                            setChosenScheduleTextInput(index)
                                            updateTextRow(e, index)
                                        }}
                                    />
                                    <button onClick={() => {
                                        setChosenScheduleTextInput(index)
                                        openPopup('getWidgetId')
                                    }} className="btn btn--transparent">
                                        <Icon name="screwdriver-wrench" style={{ fill: 'black', height: '25px' }} />
                                    </button>
                                    <button className="btn btn--transparent" onClick={() => setScheduleTextRows(scheduleTextRows => ([
                                        ...scheduleTextRows.slice(0, index),
                                    ]))}>
                                        <Icon name="trash" style={{ color: '#ff7b7b', height: '25px' }} />
                                    </button>
                                </Grid>
                            )
                        )}
                    </Block>
                </Grid>
            </Grid>

            <Popup
                popupID='getWidgetId'
                title={"Kies een widget om toe te voegen"}
            >
                <label>Widget</label>
                <Select
                    options={widgetArrayOptions}
                    onChange={addWidgetIdToScheduleTextRow}
                />
            </Popup>

            <Popup popupID='add' contentStyle={{overflow: 'unset'}} title={popupTitle} contentIndex={popupContentCurrentIndex} backButtonIndexSetter={setPopupContentCurrentIndex}>
                {popupContent[popupContentCurrentIndex]}
            </Popup>

            <Popup popupID='edit' title={"edit"}>
                {editPopupContent}
            </Popup>
        </>
    )
}
