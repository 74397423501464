import Icon from "UI/App/Components/Icon/Icon";

export default function DailyHeader({selectedDateDaily, setSelectedDateDaily, formatOptions}) {

    function onDayChange(event) {
        setSelectedDateDaily(new Intl.DateTimeFormat('nl', formatOptions).format(new Date(event.target.value)))
    };

    return (
        <>
            <span className='planning__header__date__content__text'>
                {selectedDateDaily}
            </span>
            <div>
                <Icon name="calendar" className='planning__header__date__content__icon__button'/>
            </div>
            <input
                type="date" 
                className="datepicker__input"
                onChange={onDayChange}
            />
        </>
    )
}