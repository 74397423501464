import './Barcode.scss';
import { QRCode } from 'react-qrcode-logo';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import isEqual from "lodash/isEqual";

export default function QRcodeGenerator(props) {
    // keep track of props to know if we should redownload.
    const [lastProps, setLastProps] = useState(undefined)
    const [LogoState, setLogoState] = useState(false)
    const codeRef = useRef(undefined)

    useEffect(() => {

        if (props.logoImage && LogoState === undefined) {
            console.log("logo not loaded yet")
            return;
        }
        if ((props.download || props.setBase64 !== undefined) && !isEqual(props, lastProps)) {// we dont need to redownload every time the props get set, only redownload if there is a diff
            if (codeRef === undefined) return;

            if (props.download) {
                if (props.name === undefined) {
                    props.name = new Date().getTime();
                }
                codeRef.current.download("png", `QR_${props.name}`)
            }
            if (props.setBase64 !== undefined) {
                downloadImage(props.name);
            }
            setLastProps(props)
        }


    }, [props]);

    const downloadImage = async (name) => {
        const element = document.getElementById('qr-code-wrapper');
        element.setAttribute('style', 'display: flex;');
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png;base64');
        const link = document.createElement('a');

        link.href = data;
        // if we have a base64 setter 
        if (props.setBase64 !== undefined) {
            props.setBase64(data);
            return
        }
        if (name === undefined) {
            name = new Date().getTime();
        }
        document.body.appendChild(link);

        document.body.removeChild(link);


    }
    return (
        <div id={"qr-code-wrapper"} className={"qr-code-wrapper"} style={props.show ? { zoom: props.zoom ?? 1 } : { display: "none", zoom: props.zoom ?? 1 }}>
            {/* {qrElement} */}
            <QRCode

                value={props.value ?? ""}
                ecLevel={props.ecLevel ?? "H"}
                enableCORS={props.enableCors}
                size={props.size}
                quietZone={props.quietZone}
                bgColor={props.bgColor}
                fgColor={props.fgColor}
                logoImage={props.logoImage}
                logoWidth={props.logoWidth}
                logoHeight={props.logoHeight}
                logoOpacity={props.logoOpacity}
                removeQrCodeBehindLogo={props.removeQrCodeBehindLogo}
                logoPadding={props.logoPadding}
                logoPaddingStyle={props.logoPaddingStyle}
                qrStyle={props?.qrStyle ?? "dots"}
                eyeRadius={props.eyeRadius}// accepets array
                eyeColor={props.eyeColor} // accepets array
                id={props.id}
                style={props.stlye}
                ref={codeRef}
                logoOnLoad={(e) => {
                    if (LogoState === undefined) {
                        setLogoState(e)
                    }

                }}
            />
        </div>
        //qrElement

    )
}