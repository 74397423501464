import React, {useContext, useEffect, useRef, useState} from "react";
import CalculationResult from "./result/CalculationResult";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import StepFour from "./steps/StepFour";
import './createCalculation.scss';
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {stringify} from "qs";
import {SpinnerOverlay} from "UI/App/Components/Spinner";

export default function CreateCalculation({edit = false, revision = false, copy = false}) {

    const [progressNotice, setProgressNotice] = useState('');
    const [stepToCheck, setStepToCheck] = useState(1);
    const [activeStep, setActiveStep] = useState(1);
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();
    const params = useParams();
    const locationParams = useLocation();

    const fetchContext = useContext(FetchContext);
    const lockIntervalId = useRef(false)
    const [allowEdit, setAllowEdit] = useState({});

    // States for companies
    const [chosenContactPersonId, setChosenContactPersonId] = useState(null);
    const [chosenCustomerId, setChosenCustomerId] = useState(null);
    // const [customerReference, setCustomerReference] = useState('');
    const [projectDescription, setProjectDescription] = useState('');

    // States for language select
    const [chosenLanguage, setChosenLanguage] = useState(null);

    // States for template/calculation select
    const [chosenTemplate, setChosenTemplate] = useState(null);
    const [amountRows, setAmountRows] = useState([[100, 10]]);
    const [limitToOneAmount, setLimitToOneAmount] = useState(false);
    const [length, setLength] = useState(null);
    const [width, setWidth] = useState(null);

    // States for checklist
    const [checklistTemplateId, setChecklistTemplateId] = useState(null)
    const [checklistData, setChecklistData] = useState([]);

    // States for result
    const [calculationValues, setCalculationValues] = useState([])
    const [rawTemplateData, setRawTemplateData] = useState([])
    const [totalResultJson, setTotalResultJson] = useState([])
    const [amounts, setAmounts] = useState([])
    const [json, setJson] = useState([])
    const [calculationData, setCalculationData] = useState(null);

    const [storedCalculation, setStoredCalculation] = useState(null);
    const [status, setStatus] = useState(null);
    const [calculationId, setCalculationId] = useState(params.copyId ?? null);

    const [isValidForStorage, setIsValidForStorage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [werkDerdenData, setWerkDerdenData] = useState([]);


    const nextStep = () => {
        setStepToCheck(activeStep + 1);
    }

    const previousStep = () => {
        setStepToCheck((activeStep - 1));
    }

    useEffect(() => {
        if (params.copyId) {
            loadFromId(params.copyId)
        }

        if (locationParams?.state?.companyId) {
            setChosenCustomerId(locationParams?.state?.companyId)
        }
    }, [])

    // Actions on step change
    useEffect(() => {
        checkSteps();
    }, [stepToCheck]);

    useEffect(() => {
        setProgressNotice("");
    }, [activeStep])

    // Store if result is loaded
    useEffect(() => {
        if (stepToCheck === 5) {
            if (
                totalResultJsonIsEmpty(totalResultJson)
                || isEmpty(json)
                || isEmpty(totalResultJson)
                || isEqual(json?.data?.data?.totalResultJson, totalResultJson)
            ) {
                return;
            }

            storeAndEdit();
        }
    }, [totalResultJson]);

    // Create interval to set the calculation as locked for others while editing
    useEffect(() => {
        // gaurd against: mutiple timers | no id for a timer | the right mode | a user currently already editing this timer
        if ((lockIntervalId.current !== false || storedCalculation?.id === undefined) || status?.toLowerCase() !== 'concept' || allowEdit?.isBeingEdited === true) return;
        lockCalculation();
        // store the path we started on.
        const pathWeStartedOn = locationParams.pathname
        // create a interval
        lockIntervalId.current = setInterval(() => {

            // 'failsafe' if we somehow dont clear the timer on dismount.
            if (pathWeStartedOn === sessionStorage.getItem("lastRoute")) {
                console.warn("left page without dismounting timer")
                clearLock();
                return;
            }
            lockCalculation();
        }, 10000)

        // clear the timer
        return (() => {
            clearLock();
        })
        // update if: status changes | stored calculation id changes
    }, [status, storedCalculation?.id]);

    function totalResultJsonIsEmpty(totalResultJson) {
        for (const [key, entry] of Object.entries(totalResultJson)) {
            if (entry?.length === 0 && !['manualLabor', 'thirdparty', 'information'].includes(key)) {
                return true;
            }
        }
    }

    const lockCalculation = () => {
        fetchContext.authAxios.post(`/calculations/${storedCalculation.id}/lock`)
            .then(({data}) => {
            });
    }

    const clearLock = () => {
        if (lockIntervalId.current !== null) {
            clearInterval(lockIntervalId.current);
            // set current timer to false, needed for when user refreshes.
            lockIntervalId.current = false;
        }
    }

    const loadFromId = () => {
        const query = stringify({
            populate: {
                contactPerson: true,
                company: true,
                projects: true,
                purchaseQuotes: {
                    populate: {
                        quantities: true,
                        supplier: {
                            fields: ['id', 'name']
                        },
                        contactPerson: {
                            fields: ['id']
                        }
                    },
                },
                lockedBy: {
                    fields: [
                        'firstName',
                        'email'
                    ]
                }
            }
        }, {
            encodeValuesOnly: true,

        })

        setAmountRows([]);
        fetchContext.authAxios.get(`/calculations/${params.copyId}/?${query}`).then(({data: {data}}) => {

            // Set company info
            setChosenContactPersonId(data.contactPerson.id);
            setChosenCustomerId(data.company.id);
            // setCustomerReference(data.customerReference);

            if (revision) {
                setProjectDescription(`Revisie van ${data.number} | ${data.projectDescription}`);
            } else if (copy) {
                setProjectDescription(`Kopie van ${data.number} | ${data.projectDescription}`);
            } else {
                setProjectDescription(data.projectDescription);
            }

            // Add copy text is copy
            // Set language
            setChosenLanguage(data.language);

            if (data.status.toLowerCase() !== 'concept' && (copy && revision)) {

                // Clear the calculation id because this calculation is a copy, so it technically doesn't have an id yet.
                // This is important for purchase quotes because these would otherwise be linked to the original calculation.
                setCalculationId(null);
            }

            let secondsSinceLastEdit = (new Date().getTime() - new Date(data.lockedSince).getTime()) / 1000
            let currentUser = JSON.parse(window.localStorage.getItem("userInfo"));

            setAllowEdit({
                isBeingEdited: (data?.lockedBy?.id === currentUser?.id || data.status.toLowerCase() !== 'concept') ? false : (secondsSinceLastEdit <= 30 ? true : false),
                isBeingEditedBy: {
                    firstName: data?.lockedBy?.firstName,
                    lastName: data?.lockedBy?.lastName,
                    id: data?.lockedBy?.id
                }
            })

            setStatus(data.status);

            for (const key in data.data.amounts) {
                amounts.push([
                    data.data.amounts[key],
                    data.data.misses[key]
                ]);
            }

            // Set template etc
            setChosenTemplate(data.data.templateData.id);
            setAmountRows(amounts);
            setLength(data?.data?.length);
            setWidth(data?.data?.width);

            // Checklist
            setChecklistData(data.data.checklist)
            setChecklistTemplateId(data.data.checklistTemplateId);

            setStoredCalculation(data);
            setCalculationData(data);

            // Set default step
            setActiveStep(1);

        }).catch((exception) => {
            console.error(exception);
        });
    }

    const storeCalculation = () => {

        // Delay to give API the time to store on page change
        // const pendingToast = toast.loading("Bezig met opslaan.");
        setTimeout(() => {
            storeAndEdit(true);

        }, 1000)
    }

    // Checks steps for completion
    const checkSteps = () => {
        // Check if step one is correct
        if (stepToCheck > 1) {
            if (chosenCustomerId === null || chosenContactPersonId === null || chosenLanguage === null) {
                setStepToCheck(1);
                setProgressNotice('Controleer gegevens.')

                if (chosenLanguage === null) {
                    setProgressNotice('Kies een taal.')
                }

                // if (customerReference === '') {
                //     setProgressNotice('Vul een klant referentie in.')
                // }

                if (chosenContactPersonId === null) {
                    setProgressNotice('Kies een contact persoon.')
                }

                if (chosenCustomerId === null) {
                    setProgressNotice('Kies een klant.')
                }

                return false;
            }
        }

        if (stepToCheck > 3) {
            if (chosenTemplate === null || amountRows.length === 0 || length === null || width === null || (limitToOneAmount && amountRows.length > 1)) {
                setStepToCheck(3);
                if (chosenTemplate === null) {
                    setProgressNotice('Kies een template.')
                } else if (amountRows.length === 0) {
                    setProgressNotice('Voer een aantal in.')
                } else if (limitToOneAmount && amountRows.length > 1) {
                    setProgressNotice('Dit template mag niet meer dan één aantal hebben.')
                } else if (width === null) {
                    setProgressNotice('Voer een breedte in.')
                } else if (length === null) {
                    setProgressNotice('Voer een hoogte in.')
                }
                return false;
            }
        }

        // Check calculation fields
        if (stepToCheck > 4) {
            // Check manual labor
            let errors = false;

            // Loop entries
            for (const handwerkEntry of document.getElementsByClassName('handwerk-entry')) {

                // Check description
                if (handwerkEntry.querySelector('#handwerk--omschrijving').value === '') {
                    setProgressNotice('Controleer handwerk! er ontbreekt een omschrijving.')
                    toast.error('Controleer handwerk! er ontbreekt een omschrijving.',{autoClose:2000})
                    errors = true;
                }

                // Check wage
                if(handwerkEntry.querySelector('#handwerk--uurloon').value === ''){
                    setProgressNotice('Controleer handwerk! er ontbreekt een uurloon.')
                    toast.error('Controleer handwerk! er ontbreekt een uurloon.',{autoClose:2000})
                    errors = true;
                }

                // Check quantities
                for(const quantityInput of handwerkEntry.querySelectorAll('.handwerk-price-quantity')){
                    if(quantityInput.value === '' || quantityInput.value == 0){
                        setProgressNotice('Controleer handwerk! er klopt een handwerk tijd niet.')
                        toast.error('Controleer handwerk! er klopt een handwerk tijd niet.',{autoClose:2000})
                        errors = true;
                    }
                }
            }

            // if errors stop
            if (errors){
                setStepToCheck(4);
                return false;
            };
        }

        setActiveStep(stepToCheck);
        return true;
    }

    const storeAndEdit = (redirect = false, pendingToast = null) => {
        if (allowEdit.isBeingEdited === true) {
            toast.info(`De calculatie wordt al bewerkt door een andere gebruiker, aanpassingen zullen NIET doorgevoerd worden.`);
            return;
        }

        // Give storing calculation a bit more time
        setTimeout(() => {
            const data = {...json};
            // Delete third party data to prevent submitting it.
            // delete data.data.data.thirdParty;

            data.data.data.summary = JSON.parse(document.getElementById('calculationSummary').getAttribute('summaryjsondata'));
            data.data.language = chosenLanguage;
            data.data.contactPerson = chosenContactPersonId;
            data.data.company = chosenCustomerId;
            data.data.customerReference = checklistData.filter((el) => el.name === "Kenmerk klant" || el.name === "Your reference")[0]?.value;
            data.data.calculationTemplate = chosenTemplate;
            data.data.projectDescription = projectDescription;
            data.data.data.totalResultJson = totalResultJson;
            data.data.projects = storedCalculation?.projects;
            data.projectDescription = projectDescription;

            // If edit current calculation else copy or create new
            if (edit || (storedCalculation?.id && !revision && !copy)) {
                data.data.values = json.data.data.values;
                data.data.optionalMachines = json.data.data.optionalMachines

                authAxios.put(`/calculations/${storedCalculation.id}?populate[purchaseQuotes][fields]=id`, data).then(async ({data: returnData}) => {
                    toast.info(`Calculatie ${returnData.data.number} bijgewerkt!`);
                    setStoredCalculation(returnData.data);
                    // TODO add to existing project

                    if (redirect) {
                        navigate(`/calculations/${returnData.data.id}/view/`);
                    }
                }).catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het bewerken van calculatie!`);
                });
            } else {

                data.status = 'concept';
                setStatus('concept')

                // Add copy from id
                if (revision) {
                    data.data.copiedFrom = storedCalculation.id
                } else {
                    data.data.copiedFrom = null
                }

                fetchContext.authAxios.post(`/calculations`, data).then(({data}) => {
                    toast.success(`Calculatie ${data.data.number} opgeslagen!`);

                    setCalculationId(data.data.id);
                    setStoredCalculation(data.data);
                    if (redirect) {
                        navigate(`/calculations/${data.data.id}/view/`);
                    } else if (revision || copy) {
                        navigate(`/calculations/${data.data.id}/edit/`);
                    } else {
                        setStoredCalculation(data.data);

                        window.history.pushState({}, '', `/calculations/${data.data.id}/edit/`);
                    }
                }).catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het opslaan van calculatie!`);
                });
            }

            // If copy or not copy not edit create new calculation
            return
        }, 350)
    }

    function createTabs() {
        const tabs = ['Algemeen', 'Checklist', 'Instellingen', 'Calculeren', 'Resultaat'];

        return tabs.map((tab, index) => (
            <div
                key={index + 1}
                onClick={() => setStepToCheck(index + 1)}
                className={`
                        progress__item ${activeStep > index + 1 ? 'done' : ''} 
                        ${activeStep === index + 1 ? 'active' : ''}
                    `
                }
                stepid={index + 1}
            >
                {tab}
            </div>
        ));
    }

    return (
        <>
            <div>
                <SpinnerOverlay visible={isLoading && activeStep !== 4}>
                    <div className="progress__bar" data-loading={isLoading}>
                        {createTabs()}
                    </div>
                    <div className='progress__container'>
                        {allowEdit.isBeingEdited === true ?
                            <div className='progress__notice'>
                                Deze calculatie wort aangepast door: <b>{allowEdit.isBeingEditedBy.firstName} ({allowEdit.isBeingEditedBy.email})</b>
                            </div> :
                            <></>
                        }
                        {progressNotice !== '' ?
                            <div className='progress__notice'>
                                {progressNotice}
                            </div>
                            : <></>
                        }
                        {/* HTML for step one */}
                        <div className="progress__step__container" style={{display: (activeStep === 1 ? 'block' : 'none')}}>
                            <StepOne
                                projectDescription={projectDescription}
                                setProjectDescription={setProjectDescription}
                                setChosenLanguage={setChosenLanguage}
                                chosenLanguage={chosenLanguage}
                                activeStep={activeStep}
                                previousStep={previousStep}
                                nextStep={nextStep}
                                // customerReference={customerReference}
                                // setCustomerReference={setCustomerReference}
                                chosenContactPersonId={chosenContactPersonId}
                                setChosenContactPersonId={setChosenContactPersonId}
                                chosenCustomerId={chosenCustomerId}
                                setChosenCustomerId={setChosenCustomerId}
                            />
                        </div>
                        {/* HTML for step two */}
                        <div className="progress__step__container" style={{display: (activeStep === 2 ? 'block' : 'none')}}>
                            <StepTwo
                                activeStep={activeStep}
                                previousStep={previousStep}
                                checklistData={checklistData}
                                setChecklistData={setChecklistData}
                                checklistTemplateId={checklistTemplateId}
                                setChecklistTemplateId={setChecklistTemplateId}
                                chosenLanguage={chosenLanguage?.toLowerCase()}
                            />
                        </div>
                        {/* HTML for step three */}
                        <div className="progress__step__container" style={{display: (activeStep === 3 ? 'block' : 'none')}}>
                            <StepThree
                                activeStep={activeStep}
                                width={width}
                                setWidth={setWidth}
                                length={length}
                                setLength={setLength}
                                amountRows={amountRows}
                                setAmountRows={setAmountRows}
                                limitToOneAmount={limitToOneAmount}
                                chosenTemplate={chosenTemplate}
                                setChosenTemplate={setChosenTemplate}
                            />
                        </div>
                        {/* HTML for step Four */}
                        <div className="progress__step__container" style={{display: (activeStep === 4 ? 'block' : 'none')}}>
                            <StepFour
                                checklistData={checklistData}
                                checklistTemplateId={checklistTemplateId}
                                language={chosenLanguage?.toLowerCase()}
                                calculationData={calculationData}
                                activeStep={activeStep}
                                chosenCustomerId={chosenCustomerId}
                                totalResultJson={totalResultJson}
                                setTotalResultJson={setTotalResultJson}
                                setAmounts={setAmounts}
                                setCalculationValues={setCalculationValues}
                                setRawTemplateData={setRawTemplateData}
                                setJson={setJson}
                                width={width}
                                length={length}
                                amountRows={amountRows}
                                setLimitToOneAmount={setLimitToOneAmount}
                                chosenTemplate={chosenTemplate}
                                calculationId={calculationId}
                                editInfo={allowEdit.isBeingEdited}
                                setIsLoading={setIsLoading}
                                isLoading={isLoading}
                                werkDerdenData={werkDerdenData}
                                setWerkDerdenData={setWerkDerdenData}
                                copy={copy}
                            />
                        </div>
                        {/* HTML for step Five */}
                        <div className="progress__step__container" style={{display: (activeStep === 5 ? 'block' : 'none')}}>
                            <CalculationResult
                                checklistContent={checklistData}
                                renderInfo={true}
                                activeStep={activeStep}
                                rawTemplateData={rawTemplateData}
                                setTotalResultJson={setTotalResultJson}
                                amounts={amounts}
                                values={calculationValues}
                                rawTemplateJson={rawTemplateData}
                                data={json?.data?.data}
                                language={chosenLanguage?.toLowerCase()}
                                storeCalculation={storeCalculation}
                                // Avoid double save when using copy or revision
                                saveButtonEnabled={!copy && !revision && !isLoading}
                            />
                        </div>
                    </div>
                </SpinnerOverlay>
            </div>
        </>
    );
}
