import WidgetBase from "./WidgetBase";
import IWidget from "./IWidget";
import {TrenderType} from "./WidgetTypes";
import React from "react";
import HourWidgetRepeater from "../HourWidgetRepeater";
import {getFormData} from "../../../../../Components/Form/Inputs";
import {formatCurrency} from "../../../../../../../App/Util/format";

export default class HourWidget extends WidgetBase implements IWidget {
    callback: ((object: Object) => any);
    widgetData: String | null
    saveWidget: ((object: Object) => any);
    optionalWidgetData: object | undefined;
    debug: boolean;

    /**
     * The fields returned by the widget
     * Any of these values can be requested
     */
    _RETURN_FIELDS = [
        'cost',
        'timeRequired'
    ];

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;

        if (this.optionalWidgetData?.data?.value !== undefined && this.optionalWidgetData.data.type === 'HourWidget') {
            // update the return fields every time the material choice widget gets loaded
            this.optionalWidgetData.data.value.returnFields = this._RETURN_FIELDS;
        }
    }

    /**
     * @return ReactElement
     */
    public getPreviewHTML(): JSX.Element {
        const widgetData = {
            widgetType: 'HourWidget',
        }

        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>Uren</div>
    }

    /**
     * Handles submit from getFormHTML
     * @param event:any
     */
    async handleSubmit(event: any): Promise<void> {
        event.preventDefault();

        const values = await getFormData(event);

        const data = {
            type: "HourWidget",
            title: event.target.elements.title.value,
            value: {
                returnFields: this._RETURN_FIELDS,
                ...values
            }
        };

        this.addWidgetToWidgetContainer("Uren: " + data?.title, data);
    }

    /**
     * Returns from html
     */
    public getFormHTML(): JSX.Element {
        // const values = this.getWidgetValues();

        return (<form onSubmit={(e) => this.handleSubmit(e)}><h3>Uren widget</h3>
            <div className="input-group">
                Naam: <input type="text" name="title"/>
            </div>
            <HourWidgetRepeater />
            <button type="submit">Opslaan</button>
        </form>)
    }

    public getBadgeHTML() {
        return <div>Badge html:</div>
    }

    async handleEdit(event: any): Promise<void> {
        event.preventDefault();

        const values = await getFormData(event);

        const data = {
            type: "HourWidget",
            title: event.target.elements.title.value,
            value: {
                returnFields: this._RETURN_FIELDS,
                ...values
            }
        };

        (new WidgetBase()).updateWidget(event.target.elements.id.value, "Uren: " + data.title, data);
    }

    public getEditWidgetForm(editData: any) {
        return (<form onSubmit={(e) => this.handleEdit(e)}><h3>Uren widget</h3>
            <div className="input-group">
                Naam: <input type="text" name="title" defaultValue={editData?.title}/>
            </div>
            <input type={"hidden"} name={"id"} value={editData.id}/>
            <HourWidgetRepeater defaultEntries={Object.values(editData?.value?.val)}/>
            <button type="submit">Opslaan</button>
        </form>)
    }

    public getCalculationForm() {
        const id = this.optionalWidgetData?.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
        const selectId = `${id}-hours-select`;
        const widgetContent = `
            <p><b>${this.optionalWidgetData.data.title}</b></p>
            <div style="display:grid;grid-template-columns:100px calc(100% - 100px);align-items:center;">
                <label htmlFor="${id}-hours-hours">Uren</label>
                <input id="${id}-hours-hours" type="number" name="uren"/>
                <label htmlFor="${id}">Soort werk</label>
                <select id="${selectId}" name="werk">
                    ${Object.values(this.optionalWidgetData?.data?.value?.val)?.map((value) => { return `<option data-opstart="${value[0]}" data-uurloon="${value[2]}" value="${value[1]}">${value[1]} (${formatCurrency(value[2])})</option>`}).join('')}
                </select>
            </div>
        `;

        this.addWidgetToCalculationForm(`${widgetContent}`, this.optionalWidgetData, this.optionalWidgetData.parentId)
    }

    getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer(`Uren: ${this.optionalWidgetData.data.title}`, this.optionalWidgetData, '', true);
    }

    getFormulaString(): String {
        return "";
    }

    getPreviewFormHTML(): React.ReactElement {
        return (
            <div id={this?.optionalWidgetData?.id}>
                <label>{this?.optionalWidgetData?.data.title}</label>
            </div>
        );
    }

    calculationFunctionality() {
        const widgetElement = this.getWidgetElement();

        if (!widgetElement) return;

        const hoursElem = widgetElement.querySelector("[name='uren']");
        const workTypeElem = widgetElement.querySelector("[name='werk']");

        const hoursValue = parseFloat(hoursElem?.value);
        const workTypeSelectedElement = workTypeElem?.options?.[workTypeElem?.selectedIndex];

        if (hoursValue === undefined || workTypeSelectedElement === undefined) {
            console.error('[HourWidget.tsx:147] Uren of werk type niet gevonden.');
            return;
        }

        const hourlyRate = parseFloat(workTypeSelectedElement.getAttribute('data-uurloon'));
        const startupCost = parseFloat(workTypeSelectedElement.getAttribute('data-opstart'));

        if (isNaN(hoursValue)) {
            console.error('[HourWidget.tsx:147] Het ingevulde aantal uren is geen geldig getal.');
            return;
        }

        if (isNaN(hourlyRate) || isNaN(startupCost)) {
            console.error(`[HourWidget.tsx:147] Het uurloon (${hourlyRate}) of de opstart kosten (${startupCost}) konden niet worden uitgelezen. Mogelijk is de widget onjuist ingesteld.`);
            return;
        }

        // calculate the result
        const result = (hoursValue * hourlyRate) + startupCost;

        // set the result in the widget data
        this.optionalWidgetData.data.value.result = {
            cost: result,
            timeRequired: hoursValue
        };

        // update the widget data
        this.updateWidgetData((this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id), this.optionalWidgetData);

        widgetElement.querySelector('pre')?.remove();
        
        if (this.debug) {
            const element = document.createElement('pre');
            element.innerHTML = JSON.stringify({
                uren: hoursValue,
                uurloon: hourlyRate,
                opstartkosten: startupCost,
            }, null, 2);
            widgetElement.querySelector('p')?.append(element)
        }
    }
}