import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { renderToStaticMarkup } from "react-dom/server";
import defaultLogo from "UI/Assets/Images/logo-emily.svg";
import defaultFavicon from "UI/Assets/Images/favicon.ico";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { FavLogo } from "App/Util/stylingComponents/logo";
import { SvgToHtml, getCssVariableColor } from "App/Util/styling";
const ConfigContext = createContext({});

function ConfigProvider({ children }) {
	// create a state for the app config
	const [appConfig] = useState({
		organisation: process.env.REACT_APP_ORGANISATION_NAME,
		logo: process.env.REACT_APP_APPLICATION_LOGO ?? defaultLogo,
		favicon: process.env.REACT_APP_APPLICATION_FAVICON ?? defaultFavicon,
	});

	// call this function every time the app config changes
	useEffect(() => {
		// set the favicon
		const faviconElement = document?.getElementById("favicon");


		let resultHtml = renderToStaticMarkup(new FavLogo({ accentColor: getCssVariableColor("--primary") }).getSvg());
		faviconElement.href = `data:image/svg+xml,${SvgToHtml(resultHtml)}`;



		if (appConfig?.organisation === undefined) {
			return;
		}
		// set the document title
		document.title = "Emily | " + appConfig.organisation ?? '';
	}, [appConfig])

	return (
		<ConfigContext.Provider
			value={{
				appConfig,
			}}
		>
			{children}
		</ConfigContext.Provider>
	)
}

async function getOptionsFromConfig(authAxios, setBranches, setTypes) {
	// get the options from the API
	const options = await authAxios.get('/config/crm').then(({ data: { data } }) => data).catch((exception) => {
		toast.error(`Er is iets fout gegaan bij het ophalen van de branches en types (${exception.response.status})`);
        console.error(exception);

		return [];
	});

	// check for the response. If none is fount, set the types and branches to an empty array
	if (isEmpty(options)) {
		setBranches([]);
		setTypes([]);
		return [[], []];
	}

	// get and split the branches and types
	let branches = options?.companyBranches.split('\n');
	let types = options?.companyTypes.split('\n');

	types = makeSelectOptions(types);
	branches = makeSelectOptions(branches);

	function makeSelectOptions(options) {
		// set the states for the branches and types
		options = options.map((option) => {
			option = {
				value: option.toLowerCase(),
				name: capitalize(option),
				text: capitalize(option)
			};
			return option
		});

		return options;
	}

	setBranches(branches);
	setTypes(types);

	return [
		branches,
		types
	];
}

export { ConfigContext, ConfigProvider, getOptionsFromConfig, defaultLogo }
