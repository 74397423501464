import { toast } from 'react-toastify';
import { AxiosInstance } from 'axios';
import { downloadFile } from 'App/Util/fetch';

/**
 * Open a blob url in a new tab or fetch one from strapi to open in a new tab.
 *
 * @async
 * @export
 * @param {string} blob
 * @param {AxiosInstance} authAxios
 * @param {number} attachmentId
 * @param {string} mime deprecated: See {@link downloadFile}
 * @return {(Promise<string | undefined>)}
 */
export default async function openFileInNewTab(
    blob?: string,
    authAxios?: AxiosInstance,
    attachmentId?: number,
    mime?: string
): Promise<string | undefined> {
    // if blob url is supplied: open that
    // THIS OPENS THE FILE IN THE SIZE FORMAT THE BLOB WAS CREATED FROM
    if (blob) {
        window.open(blob, '_blank');
        return;
    }

    try {
        if (!authAxios || !attachmentId) throw new Error('bad request!');

        // fetch the file from strapi, turn it into a blob, & open that
        await downloadFile(authAxios, attachmentId, mime)
            .then((data) => {
                window.open(data, '_blank');
            })
            .catch((error) => {
                throw new Error(error);
            });
    } catch (error) {
        console.error(error);
        toast.error('Er is iets mis gegaan met het openen van de attachment!');
    }
}
