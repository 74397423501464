import React, {createContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import RequestAccess from "../../UI/App/Partials/Auth/RequestAccess";
import {tryParse} from "App/Util/format";
import MSGraph from "App/Strapi/MSGraph";
import {SpinnerCenter, SpinnerOverlay} from "UI/App/Components/Spinner";
import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import {publicFetch} from "App/Util/fetch";
import axios from "axios";
import {closePopup, openPopup, popupIsOpen} from "UI/App/Components/Popup/Popup";

// create a new context for the auth state
const AuthContext = createContext();

// get the provider from the context
const { Provider } = AuthContext;

function parseJWT(token) {
    if (!token) {
        return false;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

// TODO: Change the auth provider to use something more secure than local storage
function AuthProvider({ children }) {
    // create the location hook for matching the current route
    const location = useLocation();
    const [logoutMessage, setLogoutMessage] = useState(/** @type {boolean|string} */ false)
    const [msGraph, setMsGraph] = useState(null);

    // set the initial values for the token, token expiration and user
    const token = localStorage.getItem('token');
    const expiresAt = localStorage.getItem('expiresAt');
    const user = localStorage.getItem('userInfo');
    const msToken = localStorage.getItem('msToken');
    const msRefreshToken = localStorage.getItem('msRefreshToken');
    const msTokenExpiresAt = Number(localStorage.getItem('msTokenExpiresAt'));

    useEffect(() => {
        setMsGraph(new MSGraph(msToken, msRefreshToken, msTokenExpiresAt, setAuthValues));

        return () => {
            if (msGraph) {
                // clear the refresh timeout when the component unmounts
                msGraph.clearRefreshTimeout()
            }
        }
    }, []);

    // Create the initial state for the user auth.
    // These values are used throughout the app to display user info and make decisions based on whether the user is authenticated and what role they have.
    const [authState, setAuthState] = useState({
        token: token,
        expiresAt: expiresAt,
        user: tryParse(user),
        msToken: msToken,
        msRefreshToken: msRefreshToken,
        msTokenExpiresAt: msTokenExpiresAt,
    });

    // Add event listener to the window to listen for storage events
    useEffect(() => {
        async function onTokenChange(e) {
            // On token change:
            // Check if the token is empty
            if (!e.newValue) {
                // If empty, logout the user
                logout();
                return;
            }

            // If the token is not expires, validate the token
            const res = await publicFetch.get('/auth/verify-jwt', {
                headers: {
                    Authorization: `Bearer ${e.newValue}`
                }
            });

            if (res.status !== 200) {
                logout('Er is iets misgegaan met de verificatie van je sessie. Log opnieuw in.');
                return;
            }

            // If the token is valid, update the auth state
            setAuthValues({
                token: e.newValue,
                expiresAt: parseJWT(e.newValue).exp
            });

            // Then, send a request to the frontend to update the token currently in the cookie
            await axios.post('/api/set-token', {
                token: e.newValue
            }).catch(() => {});
        }

        const storageListener = (e) => {
            // If the token updates in the local storage
            if (e.key === 'token' && e.storageArea === localStorage) {
                void onTokenChange(e);
            }
        }

        // Listen for changes in the local storage
        window.addEventListener('storage', storageListener);

        return () => {
            window.removeEventListener('storage', storageListener);
        }
    }, []);

    // Create a new timeout to refresh the token when it expires
    useEffect(() => {
        if (isUnprotectedRoute()) {
            return;
        }

        // Close the login popup if it's open
        if (popupIsOpen('popup-login')) {
            closePopup('popup-login');
        }

        const diff = parseInt(authState.expiresAt) - new Date().getTime() / 1000;

        if (diff < 600) {
            openPopup('popup-login', true, false);
        }

        // Create a timeout to open a login popup 10 minutes before it expires
        const refreshTimeout = setTimeout(async () => {
            openPopup('popup-login', true, false);
        }, (diff - (10 * 60)) * 1000);

        // Create a timeout to log the user out when the token expires
        // This is in case the user didn't sign in again and the token expired
        const logoutTimeout = setTimeout(() => {
            logout('Je sessie is verlopen. Log opnieuw in.');
        }, diff * 1000);

        return () => {
            clearTimeout(refreshTimeout);
            clearTimeout(logoutTimeout);
        }
    }, [authState.expiresAt]);

    // sets the auth state using the given parameters
    function setAuthInfo({ token, expiresAt, user, msToken, msRefreshToken, msTokenExpiresAt }) {
        // save the given information in the local storage
        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(user));
        localStorage.setItem('expiresAt', expiresAt);
        localStorage.setItem('msToken', msToken);
        localStorage.setItem('msRefreshToken', msRefreshToken);
        localStorage.setItem('msTokenExpiresAt', msTokenExpiresAt);

        setAuthState({
            token,
            expiresAt,
            user,
            msToken,
            msRefreshToken,
            msTokenExpiresAt,
        });
    }

    function setAuthValues(data = {}) {
        // save the given information in the local storage
        for (const [key, value] of Object.entries(data)) {
            localStorage.setItem(key, String(value));
        }

        setAuthState((prev) => ({
            ...prev,
            ...data,
        }));
    }
    
    function setUserInfo(user) {
        localStorage.setItem('userInfo', JSON.stringify(user));
        
        setAuthState(prev => ({
            ...prev,
            user: user
        }));
    }

    // checks whether the user is currently authenticated
    function isAuthenticated() {
        // is there is no token or expiration, the user isn't authenticated
        if (!authState.token || !authState.expiresAt) {
            return false;
        }
        // if the expiration date has passed, the user isn't loggedin anymore either
        return new Date().getTime() / 1000 < parseInt(authState.expiresAt);
    }

    function isUnprotectedRoute() {
        return ['/authenticate', '/login', '/login/terminal', '/logout'].includes(location.pathname);
    }

    /**
     * Logs the user out by clearing the auth state and unsetting the local storage
     * @param {boolean|string} message
     * @param message
     * @returns {JSX.Element}
     */
    function logout(message = false) {

        // remove auth info from the local storage
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('msToken');
        localStorage.removeItem('msRefreshToken');
        localStorage.removeItem('msTokenExpiresAt');
        localStorage.removeItem('profileImage');
        localStorage.removeItem('MSDisabled');

        setLogoutMessage(message);

        if (process.env.NODE_ENV === 'development' && !['crm.local', 'php-react.local'].includes(window.location.hostname)) {
            window.location.href = '/login';
        } else {
            window.location.href = `/logout${message ? `?message=${message}` : ''}`;
        }

        return <SpinnerOverlay visible={true} message='Bezig met uitloggen...' />;
    }

    // checks is the user is currently authenticated
    if (!isAuthenticated() && !isUnprotectedRoute()) {
        // the user is not authenticated.
        // send them to the login endpoint in the API
        window.location.href = '/logout';

        return <SpinnerCenter />
    }

    if ((authState.user?.role?.name === 'Guest' || authState.user?.role === undefined) && !isUnprotectedRoute()) {
        return (
            <RequestAccess token={authState.token} blocked={authState?.user?.blocked} />
        )
    }

    return (
        <Provider
            value={{
                authState,
                setAuthInfo,
                setAuthValues,
                setUserInfo,
                isAuthenticated,
                logout,
                logoutMessage,
                msGraph
            }}
        >
            {children}
            <IF condition={msGraph?.isDisabled() || msGraph?.isDisconnected()}>
                <button
                    id='microsoft__disabled-warning'
                    className='btn--round btn--error btn__slide--left'
                >
                    <div>
                        <IF condition={msGraph?.isDisabled()}>
                            <span>Emily heeft geen verbinding met Microsoft.</span>
                        </IF>
                        <IF condition={msGraph?.isDisconnected()}>
                            <span>Emily kon geen verbinding maken met Microsoft.</span>
                        </IF>
                        <span>Functionaliteiten die hiervan afhankelijk zijn, zijn niet beschikbaar.</span>
                    </div>
                    <Icon name='circle-exclamation' />
                </button>
            </IF>
        </Provider>
    );
}

export { AuthContext, AuthProvider };
