import Block from "UI/App/Components/Block/Block";
import { FetchContext } from "App/Strapi/FetchContext";
import { useContext, useEffect, useState, useRef, Component } from "react";
import { toast } from "react-toastify";
import PopupAddTimer from "UI/App/Components/Popup/Popups/AddTimer";
import PopupEditTimer from 'UI/App/Components/Popup/Popups/EditTimer';
import Popup, { closePopup, openPopup } from "UI/App/Components/Popup/Popup";
import Grid from "UI/App/Components/Grid/Grid";
import Button from "UI/App/Components/Button/Button";
import { stringify } from "qs";

export default function TimeMonthTable() {
    const { authAxios } = useContext(FetchContext);

    const [variableBlock, setVariableBlock] = useState([]);
    const [knowTimers, setKnowTimers] = useState([]);
    const [addTimer, setAddTimer] = useState({});
    const [editTimer, setEditTimer] = useState({});
    const [daysToDisplay, setDaysToDisplay] = useState([
        { short: "Zo", long: "Zondag" },
        { short: "Ma", long: "Maandag" },
        { short: "Di", long: "Dinsdag" },
        { short: "Wo", long: "Woensdag" },
        { short: "Do", long: "Donderdag" },
        { short: "Vr", long: "Vrijdag" },
        { short: "Za", long: "Zaterdag" },
    ]);
    const [monthNames, setMonthNames] = useState([
        { short: "jan", long: "januari" },
        { short: "feb", long: "februari" },
        { short: "mrt", long: "maart" },
        { short: "apr", long: "april" },
        { short: "mei", long: "mei" },
        { short: "jun", long: "juni" },
        { short: "jul", long: "juli" },
        { short: "aug", long: "augustus" },
        { short: "sept", long: "setptember" },
        { short: "okt", long: "oktober" },
        { short: "nov", long: "november" },
        { short: "dec", long: "december" },
    ]);
    const [currentMonth, setCurrentMonth] = useState(undefined)
    const [colors, setColors] = useState({
        background: "white",
        accent: "#c4e2ec",
        selected: "#d9f2fa",
        currentTime: "#0089b4",
        currentActiveBlock: "green",
    });
    useEffect(() => {
        let date = new Date();
        setCurrentMonth(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0'))
        getDatesFromCurrentMonth();
    }, [])
    useEffect(() => {
        updateBlock()
        if (addTimer?.startInfo === undefined) return;
        if (addTimer?.endInfo === undefined) return;
        if (addTimer?.shouldPop === false) {
            openPopup("month-day-view")
        } else {
            openPopup("add-timer")
            setAddTimer({})
        }




    }, [addTimer])
    useEffect(() => {

        if (currentMonth === undefined) return;
        // let date = new Date();
        // console.log(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0'));
        // console.log(currentMonth)
        let date = new Date();
        //setCurrentMonth(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0'))
        getDatesFromCurrentMonth(currentMonth);
        //getDatesFromCurrentMonth();



    }, [currentMonth])
    useEffect(() => {
        //console.log("test")

        updateBlock();
    }, [knowTimers])
    useEffect(() => {
        if (editTimer?.id === undefined) return;
        updateBlock();
        closePopup("month-day-view");
        openPopup('edit-timer');

    }, [editTimer])
    function updateBlock() {
        if (currentMonth === undefined) return;
        let elements = []


        elements.push(<div className="timeAccounting__week">
            <input name="selectDate" type="month" value={currentMonth} onChange={(e) => setMonth(e)} />
            {monthNames[startOfMonth(currentMonth).getMonth()].long}
        </div>);
        elements.push(createTable())
        setVariableBlock(elements);
    }

    function createTable() {
        let elements = [];

        // prep named table headers
        let th = []
        th.push(<th className={"weekTable__th"}>{""}</th>)
        for (const day of daysToDisplay) {
            th.push(<th className={"weekTable__th"}>
                {day.short}
            </th>)
        }
        // prep table content
        elements.push(<>
            <table className={"weekTable"}>
                <thead >
                    <tr>
                        {th}
                    </tr>
                </thead>
                <tbody >
                    {createTableRows()}
                </tbody>
            </table>
        </>);

        // prep table popups
        elements.push(<PopupEditTimer timer={editTimer} setUpdateBlocks={reAquireTable} />)
        elements.push(<PopupAddTimer setTime={false} timerToAdd={addTimer} setUpdateBlocks={reAquireTable} />)

        let timersOfTheDay = createPopupblocks(addTimer?.content);
        elements.push(<Popup title='Timers' popupID='month-day-view'>
            <Block name="timerList" >{timersOfTheDay}</Block>
        </Popup>

        )
        return elements;
    }
    function createPopupblocks(blocks) {
        if (blocks === undefined || blocks.length === 0) return;

        let content = []
        content.push(<Button onClick={(e) => {
            e.stopPropagation();
            closePopup("month-day-view");
            openPopup("add-timer")
        }}>Toevoegen</Button>)
        for (let timer of blocks[0].timers) {
            //console.log(timer, formatDateToReadableString(timer?.startTime, true))
            content.push(<div className={"monthTable__popUpBlock"}>


                <div>
                    <div style={{ display: "inline-block", width: "50%", }}>
                        <div>{timer?.details?.description}</div>
                        <div>{formatDateToReadableString(timeToOffsetTime(timer?.startTime), false).time} > {formatDateToReadableString(timeToOffsetTime(timer?.endTime), false).time}</div>
                    </div>
                    <div style={{ display: "inline-block", width: "50%", }}>
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            setEditTimer({
                                id: timer.id,
                            });
                        }}>Aanpassen</Button>
                        <Button id={timer.id} onClick={(e) => {
                            e.stopPropagation();
                            deleteTimer(e)
                        }}>Verwijderen</Button>
                    </div>
                </div>




            </div>)
        }

        return content
    }
    function createTableBlock(dateOfBlock, data) {
        if (dateOfBlock === undefined || data === undefined) {
            //console.error("Not a parameters are set")
            return [];
        }
        let content = []

        let timersOfBlock = data.filter(e => e.date === dateOfBlock.getDate() && e.month === dateOfBlock.getMonth())

        if (timersOfBlock[0]?.timers[0]?.details?.description !== undefined) {
            content.push(<div className={"monthTable__block"}>{timersOfBlock[0]?.timers[0]?.details?.description}</div>)
        }

        if (timersOfBlock[0]?.timers.length > 1) {
            content.push(<div className={"monthTable__block"}>+{timersOfBlock[0]?.timers.length - 1} andere</div>)
        }
        return { data: timersOfBlock, content: <>{content}</> };
    }
    function createTableRows() {

        // set 'default' background
        let background = colors.background;
        // get days in month
        let totalDays = daysInMonth(currentMonth);
        // get start of the month
        let thisMonth = startOfMonth(currentMonth)
        // get which day the month starts on
        let startDay = thisMonth.getDay();
        // get which day the month ends on
        let endDay = endOfMonth(thisMonth).getDay();
        // to keep track how many days of the month we already have displayed
        let daysBefore = 0;
        let currentDay = 1;
        let daysAfter = 0;
        // is the added table <td> inside the month?
        let insideMonthDays = false;
        let formatedTimers = formatTimersToTableBlocks(knowTimers);

        // some months need an extra week
        let veritcalRows = Math.ceil((totalDays + startDay) / 7);
        let trTotal = []

        // we have 5/6 vertical rows
        for (let i = 0; i < veritcalRows; i++) {
            let trContent = [];
            // we have 7 days on every row, and a week row
            for (let _i = -1; _i < 7; _i++) {
                let dateOfBlock = new Date();
                let month = thisMonth.getMonth();
                let year = thisMonth.getFullYear();
                // the 'first row', mostly show week number
                if (_i === -1) {
                    trContent.push(<td className="monthTable__td"  >
                        {"week " + (Number(getWeekNumber(thisMonth)) + i)}
                    </td>);
                    continue;
                }
                let textContent = "";
                // increment actul month days
                if (insideMonthDays) {


                    currentDay++;
                }
                //check if we reahced endDay
                if (currentDay > totalDays) {
                    insideMonthDays = false;

                }
                // check if we reached startDay
                if ((_i) + (i * 7) === startDay) {
                    insideMonthDays = true;
                    textContent = currentDay + " " + monthNames[thisMonth.getMonth()].short
                } else if (currentDay === totalDays) {
                    textContent = currentDay + " " + monthNames[thisMonth.getMonth()].short
                } else if (insideMonthDays) {
                    textContent = currentDay
                }
                let day = currentDay;
                // set text for cases outside the current month
                if (!insideMonthDays) {

                    // check if earlier then current month
                    if (currentDay <= 1 && !insideMonthDays) {

                        let tempDate = new Date(thisMonth)
                        // get a month before current month
                        tempDate.setMonth(thisMonth.getMonth() - 1)
                        // get the date before
                        let beforeDate = new Date(endOfMonth(tempDate)).getDate() - (startDay - (daysBefore + 1))
                        dateOfBlock = new Date(endOfMonth(tempDate).setDate(beforeDate));
                        // if its the 'last' before current month add the short name for the month
                        if (daysBefore === startDay - 1) {
                            textContent = beforeDate + " " + monthNames[tempDate.getMonth()].short
                        } else {
                            textContent = beforeDate
                        }
                        day = dateOfBlock.getDate()
                        let dateToMod = dateOfBlock
                        dateToMod.setMonth(dateToMod.getMonth())
                        month = dateToMod.getMonth();
                        year = dateToMod.getFullYear();
                        daysBefore++;
                    }
                    // check if later then current month
                    if (currentDay > 1 && !insideMonthDays) {

                        let tempDate = new Date(thisMonth)
                        // get a month after current month
                        tempDate.setMonth(thisMonth.getMonth() + 1)
                        let afterDate = new Date(startOfMonth(tempDate)).getDate() + daysAfter
                        dateOfBlock = new Date(startOfMonth(tempDate).setDate(afterDate));

                        // if its the 'first' after current month add the short name for the month
                        if (daysAfter === 0) {
                            textContent = afterDate + " " + monthNames[tempDate.getMonth()].short
                        } else {
                            textContent = afterDate
                        }
                        day = dateOfBlock.getDate()
                        let dateToMod = dateOfBlock
                        dateToMod.setMonth(dateToMod.getMonth())
                        month = dateToMod.getMonth();
                        year = dateToMod.getFullYear();
                        daysAfter++;
                    }
                    // set background to diffrent colour to make it clear for user
                    background = colors.selected;
                } else if (insideMonthDays) {
                    background = colors.background;
                    // make the date to belonging to this object
                    dateOfBlock = new Date(thisMonth);
                    dateOfBlock.setDate(dateOfBlock.getDate() + currentDay - 1);

                }


                let block = createTableBlock(dateOfBlock, formatedTimers);
                let hasContent = block?.content?.props?.children?.length > 0 ? true : false;

                trContent.push(<td key={_i} className="monthTable__td" style={{ backgroundColor: background }} onClick={(e) => { blockPressed(e, day, month + 1, year, hasContent, block.data) }} >
                    {textContent}
                    {block.content}
                    <div key={_i} style={{ position: "relative", bottom: "-20px", right: "-0px", fontSize: "10px" }}>
                        {insideMonthDays === true && "week " + (Number(getWeekNumber(new Date(currentMonth + "-" + (day)), 1)))}
                    </div>
                </td>);

            }
            // wrap all td's in a tr and push to total
            trTotal.push(<tr key={i} className="monthTable__tr">
                {trContent}
            </tr>)
        }

        // return all rows
        return trTotal;
    }
    function daysInMonth(date = undefined) {
        let now = null;
        if (date !== undefined) {
            now = new Date(date);
        } else {
            now = new Date();
        }

        return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    }
    function startOfMonth(date = undefined) {
        let now = null;
        if (date !== undefined) {
            now = new Date(date);
        } else {
            now = new Date();
        }
        return new Date(now.getFullYear(), now.getMonth(), 1);
    }
    function endOfMonth(date = undefined) {
        let now = null;
        if (date !== undefined) {
            now = new Date(date);
        } else {
            now = new Date();
        }
        return new Date(now.getFullYear(), now.getMonth() + 1, 0);
    }
    function getWeekNumber(date = undefined, dowOffset = 0) {
        let curDate = null
        if (date !== undefined) {
            curDate = new Date(date)
        } else {
            curDate = new Date()
        };

        let newYear = new Date(curDate.getFullYear(), 0, 1);
        // set which day the week begins on
        let day = newYear.getDay() - dowOffset;
        day = (day >= 0 ? day : day + 7);
        let dayNum = Math.floor((curDate.getTime() - newYear.getTime() - (curDate.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;

        let weekNum;

        // if new a new year start before week middle
        if (day < 4) {
            weekNum = Math.floor((dayNum + day - 1) / 7) + 1;

            if (weekNum > 52) {
                let nYear = new Date(curDate.getFullYear() + 1, 0, 1);
                let nDay = nYear.getDay() - dowOffset;
                nDay = nDay >= 0 ? nDay : nDay + 7

                weekNum = nDay < 4 ? 1 : 53
            }
        } else {
            weekNum = Math.floor((dayNum + day - 1) / 7)

            //if we have a week 0 set it to lastyears last week
            if (weekNum == 0) {
                let nYear = new Date(curDate.getFullYear() - 1, 0, 1);
                let year = nYear.getFullYear();

                // are we a leap year?
                if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
                    // on leap years, if we start on wednesday we have 53 weeks
                    if (nYear.getDay() === 3) {
                        weekNum = weekNum > 0 ? weekNum : 53
                    } else {
                        weekNum = weekNum > 0 ? weekNum : 52
                    }

                } else {
                    // on NONE leap years, if we start on thursday we have 53 weeks
                    if (nYear.getDay() === 4) {
                        weekNum = weekNum > 0 ? weekNum : 53
                    } else {
                        weekNum = weekNum > 0 ? weekNum : 52
                    }

                }

            }
        }

        return weekNum;
    }
    function reAquireTable(e) {
        getDatesFromCurrentMonth(new Date(currentMonth));
        updateBlock();

    }
    function formatDateToReadableString(inputDate, TimeWithSec = true) {

        inputDate = new Date(inputDate);
        let returnVal = { date: "", time: "" };

        let year = inputDate.getFullYear().toString();
        let month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        let date = inputDate.getDate().toString().padStart(2, '0');

        returnVal.date = year + "-" + month + "-" + date;

        let timeOffset = new Date().getTimezoneOffset() * -1;

        let hours = new Date(inputDate.getTime() - (timeOffset * 60000)).getHours().toString().padStart(2, '0');
        let minutes = inputDate.getMinutes().toString().padStart(2, '0');
        let seconds = inputDate.getSeconds().toString().padStart(2, '0');
        if (TimeWithSec) {
            returnVal.time = hours + ":" + minutes + ":" + seconds;
        } else {
            returnVal.time = hours + ":" + minutes;
        }
        return returnVal;
    }
    function timeToOffsetTime(time, offsetMod = -1) {
        let timeOffset = new Date().getTimezoneOffset() * -1;
        return new Date(new Date(time).getTime() - ((timeOffset * 60000) * offsetMod));
    }
    function formatTimersToTableBlocks(knowTimers) {
        if (knowTimers.length === 0) return;
        let exepandedTimers = []




        // split timers spanning mutiple days
        for (const timer of knowTimers) {
            let start = timeToOffsetTime(new Date(timer.startTime))
            let end = timeToOffsetTime(new Date(timer.endTime))

            let timeDiff = end.getTime() - start.getTime();
            let dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (dayDiff === 1 || dayDiff === 0) {
                exepandedTimers.push(timer);
            } else {
                for (let i = 1; i <= dayDiff + 1; i++) {
                    let baseTimer = { ...timer };

                    //if its the first one
                    if (i === 1) {

                        let date = new Date(baseTimer.startTime);
                        date.setHours(23, 59, 59, 0)
                        baseTimer.endTime = date.toISOString();
                        exepandedTimers.push(baseTimer)

                        continue;
                    }
                    // if its the last one
                    if (i > dayDiff) {

                        let date = new Date(baseTimer.endTime);
                        date.setHours(0, 0, 0, 0)
                        baseTimer.startTime = date.toISOString();
                        exepandedTimers.push(baseTimer)

                        continue;
                    }
                    // if  its not the first or last make a timer spanning entire day.
                    let date = new Date(timer.startTime);
                    date.setDate(date.getDate() + i - 1)

                    date.setHours(0, 0, 0, 0)
                    baseTimer.startTime = date.toISOString();

                    date.setHours(23, 59, 59, 0)
                    baseTimer.endTime = date.toISOString();

                    // push to storage object
                    exepandedTimers.push(baseTimer)

                }
            }

        }
        let timerByDay = []
        let id = 0;
        for (let timer of exepandedTimers) {
            let timerDate = new Date(timer.startTime);

            let result = timerByDay.filter(entry => entry.date === timerDate.getDate() && entry.month === timerDate.getMonth())

            if (result.length > 0) {

                result[0].timers.push(timer)

            } else {
                timerByDay.push({
                    id: id,
                    timers: [timer],
                    date: timerDate.getDate(),
                    month: timerDate.getMonth(),
                })
                id++;
            }
        }
        return timerByDay
    }
    //strapi functions
    //#region
    // get dates around and in given month
    async function getDatesFromCurrentMonth(givenDate = undefined, monthsAroundGivenMonth = 2) {
        // get start and end of week, we use these to filter strapi.
        const curr = givenDate === undefined ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : new Date(givenDate);

        let first = new Date(curr);
        let last = new Date(curr);
        let firstMonth = new Date(first.setMonth(first.getMonth() - monthsAroundGivenMonth));
        let lastMonth = new Date(last.setMonth(last.getMonth() + monthsAroundGivenMonth));


        const params = stringify({
            populate: "*",
            filters: {
                $and: [
                    {
                        startTime: {
                            $gte: firstMonth
                        },
                        endTime: {
                            $lte: lastMonth
                        }

                    }
                ]

            }

        });
        await authAxios.get(`/time-registrations/all?${params}`).then(({ data }) => {
            setKnowTimers(data)
            // if (data.length === 0) {
            //     updateBlock();
            // }

        })
            .catch((e) => {

                toast.error('Er ging iets miss bij het ophalen van de timers');

                console.error(e);
            });
    }
    function deleteTimer(e) {
        e.preventDefault();

        // check for missInput
        if (!window.confirm("Weet je zeeker dat je dit blok wilt verwijderen?")) {
            return;
        }

        // delete entry and update the table view
        authAxios.delete("/time-registrations/" + e.target.id).then(() => {
            toast.success('Verwijderd.');
            reAquireTable();
            closePopup("month-day-view");
        }).catch((e) => {

            toast.error('Er ging iets fout met het verwijderen.');

            console.error(e);
        });

    }
    //#endregion

    //events
    //#region
    function blockPressed(e, day, month, year, hasContent, content = undefined) {

        let startNew = new Date(year + "-" + month + "-" + day);
        let endNew = new Date(year + "-" + month + "-" + day);

        setAddTimer({
            startInfo: startNew,
            endInfo: endNew,
            shouldPop: !hasContent,
            content: content,
        });
        updateBlock();
    }
    function setMonth(e) {
        let date = new Date(e?.target?.value)
        setCurrentMonth(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0'))
        //setCurrentMonth(e?.target?.value)

    }


    //#endregion
    return (<>
        <Block name="monthTable">
            {variableBlock}
        </Block>
    </>)
}
