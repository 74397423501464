import { useEffect, useState } from "react";
import Draggable from "UI/App/Partials/Content/Planning/ViewOptions/Week/Draggable";
import IF from "UI/App/Components/Conditional/IF";
import Popup, { closePopup, openPopup } from "UI/App/Components/Popup/Popup";
import { formatTime } from "App/Util/format";
import Select from "react-select";
import {_PLANNING_PRIORITIES} from "UI/App/Partials/Content/Planning/UpdateMachinePlanningPopup";

export default function Drop({ allPlannedItems, visualizeDependencies, item, finalizeChanges, prevItem, nextItem, removeDependency, createDependency, createAllDependency, setPopupCurrentMachine, setPopupFormData, type, id, isContent, planningIndex, selectedType, showDependencyOptions, setIsShowMode, isShowMode, setShowModeActivator, expand, expandProject, shrink }) {
    // list of the items within a single drop lane
    const [itemList, setItemList] = useState(Array(7).fill(null));
    const [expandPopupFormData, setExpandPopupFormData] = useState({})

    useEffect(() => {
        updateItemList();
    }, [item])

    function updateItemList() {
        const updatedItemList = Array(7).fill(null);
        if (isContent) {
            // Get the number of itemContents that are placed after eachother per itemContent; a chain.
            function getChainLength(planningItem, chainLength = 1, useFirstInChain = true) {
                let itemToUse = planningItem;
                if (useFirstInChain) {
                    itemToUse = getFirstInChain(planningItem);
                }
                // Depth filter
                if (chainLength < 100) {
                    // Check for connection
                    if (itemToUse?.connectsTo) {
                        // Call the function again with the connected item
                        const connectedItem = item.planning.find((loopedItemToUse) => loopedItemToUse.uuid === itemToUse.connectsTo);
                        return getChainLength(connectedItem, chainLength + 1, false);
                    }
                    // Return the chain length
                    return chainLength;
                }
                // Return 0 if depth reached
                return 0;
            }

            function getFirstInChain(planningItem, maxDepth = 0) {
                // Depth filter
                if (maxDepth < 100) {
                    const connectedItem = item.planning.find((loopedPlanningItem) => loopedPlanningItem.connectsTo === planningItem.uuid);
                    if (connectedItem) {
                        // Check if connectedItem is firstChild
                        return getFirstInChain(connectedItem, maxDepth + 1);
                    }
                    // Return planningItem as firstChild
                    return planningItem;
                }
            }

            function getFirstItemForMachine(item) {
                const planning = [...item.planning];
                planning.sort((a, b) => new Date(a.date) - new Date(b.date));
                return planning[0];
            }

            function getLastItemForMachine(item) {
                const planning = [...item.planning];
                planning.sort((a, b) => new Date(b.date) - new Date(a.date));
                return planning[0];
            }

            item.planning.forEach((planningItem, i) => {
                updatedItemList.name = item.name;
                updatedItemList[planningItem.dayPosition] = {
                    ...planningItem,
                    key: item.name + '-' + (parseInt(i) + parseInt(planningItem.itemNumber)),
                    item: planningItem,
                    name: item.description,
                    position: planningItem.dayPosition + 1,
                    itemList: updatedItemList,
                    identifier: i,
                    isFirstItem: getFirstItemForMachine(item).uuid === planningItem.uuid,
                    isLastItem: getLastItemForMachine(item).uuid === planningItem.uuid,
                    chainLength: getChainLength(planningItem),
                    isDependencyOption: planningItem.isDependencyOption,
                };
            })
        }

        setItemList(updatedItemList);
    }

    // rearrange the list based on position change
    function onPositionChange(draggingX, draggingItem, prevDraggingX) {
        // if there is an item being dragged AND it's being placed on a position in the grid
        if (draggingItem !== null && draggingX <= 7 && draggingX >= -1 && prevDraggingX !== draggingX) {
            finalizeChanges(prevDraggingX > draggingX ? -1 : 1, draggingItem);
        }
    }

    function onSubmit(e) {
        e.preventDefault();
        expandProject(item, expandPopupFormData)
        closePopup();
    }

    return (
        <div className="planning__content__bar__container" onDoubleClick={(e) => {
            const bRect = e.target.getBoundingClientRect();
            const clickPosLeft = e.clientX - bRect.left;
            const totalsize = e.target.offsetWidth;
            const daySize = totalsize / 7;
            const daysToAdd = Math.floor(clickPosLeft / daySize);

            const startOfWeek = new Date(item.startDay)
            const datePressed = new Date(startOfWeek.setDate(startOfWeek.getDate() + daysToAdd));
            const datePressedString = datePressed.toISOString().split("T")[0];

            // Skip sunday
            if (datePressed.getDay() === 0) {
                return;
            }

            setExpandPopupFormData((formData) => ({
                ...formData,
                date: datePressedString
            }));

            openPopup(`${item.id}_addToPlanning`)

        }}>

            <IF condition={isContent}>
                {
                    itemList.filter(item => item !== null).map((item) => {
                        return (
                            <>

                                <Draggable
                                    name={item?.name}
                                    allPlannedItems={allPlannedItems}
                                    visualizeDependencies={visualizeDependencies}
                                    key={item.key}
                                    item={item.item}
                                    position={item.position}
                                    onPositionChange={onPositionChange}
                                    itemList={item.itemList}
                                    display={item.display}
                                    identifier={item.identifier}
                                    colorIndex={item.colorIndex}
                                    dependsOn={item.dependsOn}
                                    prevItem={item.prevItem}
                                    nextItem={item.nextItem}
                                    isLastItem={item.isLastItem}
                                    isFirstItem={item.isFirstItem}
                                    type={type}
                                    id={id}
                                    removeDependency={removeDependency}
                                    createDependency={createDependency}
                                    isContent={isContent}
                                    chainLength={item.chainLength}
                                    setPopupCurrentMachine={setPopupCurrentMachine}
                                    setPopupFormData={setPopupFormData}
                                    showDependencyOptions={showDependencyOptions}
                                    isDependencyOption={item.isDependencyOption}
                                    setIsShowMode={setIsShowMode}
                                    isShowMode={isShowMode}
                                    setShowModeActivator={setShowModeActivator}
                                    expand={() => expand(item)}
                                    shrink={() => shrink(item)}
                                    selectedType={selectedType}
                                    createAllDependency={createAllDependency}
                                />
                            </>
                        )
                    })
                }
            </IF>
            <Popup popupID={`${item.id}_addToPlanning`} title={"Extra tijd toevoegen"} >
                <form onSubmit={(e) => {
                    onSubmit(e)
                }}>
                    <div className='input-group'>Extra tijd toevoegen voor de <b>{item.name}</b></div>
                    <div className='input-group'>
                        <label htmlFor={`StartTime`} className='required'>
                            Start datum
                        </label>
                        <input
                            type='date'
                            id={`StartTime`}
                            value={expandPopupFormData?.date}
                            onChange={(event) => {
                                setExpandPopupFormData((formData) => ({
                                    ...formData,
                                    date: event.target.value
                                }));
                            }}
                            onClick={({ target }) => {
                                // onFocus has error on requiring user gesture on browser window auto focus
                                // e.g. from closing 1 window and your OS auto focusing the browser after
                                // open picker on full input click
                                if (
                                    !target.disabled &&
                                    document.activeElement.id === target.id
                                ) {
                                    target.showPicker();
                                }
                            }}
                            required
                        />
                    </div>
                    <div className='input-group' >
                        <label htmlFor='draggable-popup-priority' className='required'>
                            Prioriteit
                        </label>

                        <Select
                            id='draggable-popup-priority'
                            options={_PLANNING_PRIORITIES}
                            value={
                                expandPopupFormData.priority ?? {
                                    label: '5 (Normaal)',
                                    value: 5,
                                }
                            }
                            onChange={(priority) => {
                                setExpandPopupFormData((formData) => ({
                                    ...formData,
                                    priority
                                }));
                            }}
                            placeholder='Selecteer de prioriteit'
                            required
                        />
                    </div>
                    {/* time to add to planning */}
                    <div className='input-group time'>
                        <label htmlFor='draggable-popup-time' className='required'>
                            Ingeplande uren:
                        </label>
                        <label className='time-input-with-preview'>

                            <input
                                id='draggable-popup-time'
                                type='number'
                                name='time'
                                value={expandPopupFormData?.time ?? ''}
                                step={0.01}
                                min={0}
                                required={true}
                                onChange={(event) => {
                                    setExpandPopupFormData((formData) => ({
                                        ...formData,
                                        time: event.target.value === '' ? '' : Math.abs(Number(event.target.value))
                                    }));
                                }}
                            />

                            <span className='time-preview'>{formatTime((expandPopupFormData?.time ?? 0))}</span>

                        </label>
                    </div>
                    <div className='input-group note'>
                        <label htmlFor='draggable-popup-note'>
                            Opmerkingen
                        </label>
                        <textarea
                            id={'draggable-popup-note'}
                            name={'note'}
                            value={expandPopupFormData?.note ?? ""}
                            onChange={(event) => {
                                setExpandPopupFormData((formData) => ({
                                    ...formData,
                                    note: event.target.value ?? ''
                                }))
                            }}
                            placeholder={'Voeg eventuele opmerkingen toe'}
                            rows={5}
                        />
                    </div>
                    <button type='submit'>Toevoegen</button>
                </form>
            </Popup >
        </div >
    )
}
