import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { FetchContext } from 'App/Strapi/FetchContext';
import { downloadFile } from 'App/Util/fetch';
import openFileInNewTab from 'App/Util/openFileInNewTab';
import { formatDateWithoutTime, formatTimeWithoutDate } from 'App/Util/format';
import Block from 'UI/App/Components/Block/Block';
import Icon from 'UI/App/Components/Icon/Icon';
import { openPopup } from 'UI/App/Components/Popup/Popup';
import { SpinnerCenter } from 'UI/App/Components/Spinner';
import PopupAddOrEditMemo from 'UI/App/Components/Popup/Popups/AddOrEditMemo';
import useWindowDimensions from 'App/Util/useWindowDimensions';

export const memosQuery = stringify({
    populate: {
        datetime: {
            fields: ['date', 'wholeDay', 'startTime', 'endTime']
        },
        recipients: {
            fields: ['id', 'username']
        },
        attachment: true,
        linkedItem: {
            populate: {
                note: {
                    fields: ['id'],
                    populate: {
                        company: {
                            fields: ['id']
                        },
                        supplier: {
                            fields: ['id']
                        },
                        contactPerson: {
                            fields: ['id']
                        },
                        attachment: {
                            fields: ['id']
                        }
                    }
                }
            }
        }
    },
    fields: ['id', 'title', 'content', 'completed', 'importance'],
    sort: ['datetime.date:asc', 'datetime.startTime:asc']
});

export default function Memos({ ...props }) {
    const { authAxios } = useContext(FetchContext);
    const { authState } = useContext(AuthContext);

    const { width } = useWindowDimensions();
    // state for loading icon
    const [isLoading, setLoading] = useState(true);

    // state for the block header selected `completed` value
    const [showCompleted, setShowCompleted] = useState(false);

    // state for the fetched memo's
    const [memos, setMemos] = useState([]);

    // states for the popup form
    const [formValues, setFormValues] = useState({});
    const [formEditId, setFormEditId] = useState(null);
    const [currentMemoIndex, setCurrentMemoIndex] = useState(null);
    const [expandedMemos, setExpandedMemos] = useState([]);

    const importanceWeight = ['high', 'normal', 'low', 'null'];
    // get the memo's
    useEffect(() => {
        if (authState?.user?.id) {
            authAxios
                .get(`../users/${authState.user.id}/memos/all?${memosQuery}`)
                .then(async ({ data }) => {
                    for (const memo of data) {
                        // change recipients to react-select format
                        memo.recipients = memo.recipients.map((user) => ({ value: user.id, label: user.username }));

                        // add thumbnail blob
                        if (memo?.attachment?.length > 0) {
                            for (let attachment of memo.attachment) {
                                if (attachment?.formats?.thumbnail?.url) {
                                    attachment.formats.thumbnail.blob = await downloadFile(
                                        authAxios,
                                        attachment?.id,
                                        attachment.formats.thumbnail?.mime,
                                        'thumbnail'
                                    ).catch(() => null);
                                } else {
                                    attachment.blob = await downloadFile(
                                        authAxios,
                                        attachment?.id,
                                        attachment?.mime
                                    ).catch(() => null);
                                }
                            }
                        }

                    }

                    // sort both on date and ENUM importance
                    data.sort((a, b) => {
                        // remove the hour part of date, if we do NOT dot we can not sort on time
                        let dateA = new Date(a.datetime.date).getTime();
                        let dateB = new Date(b.datetime.date).getTime();

                        // filter on date || filter on importance
                        return (
                            dateA - dateB ||
                            importanceWeight.indexOf(String(a.importance)) -
                                importanceWeight.indexOf(String(b.importance))
                        );
                    });

                    setMemos(data);
                })
                .catch((error) => {
                    toast.error("Er is iets mis gegaan met het ophalen van je Team-memo's!");
                    console.error(error);
                })
                .finally(() => {
                    // stop loading when this chain is executed
                    setLoading(false);
                });
        }
    }, [authAxios, authState?.user?.id]);

    const toggleCompleted = useCallback(
        (target, memoIndex, memoId) => {
            target.disabled = true;

            authAxios
                .put(`memos/${memoId}/toggle-completed`)
                .then(({ data }) => {
                    // change state on a timeout to show the checkbox tick a bit
                    setTimeout(() => {
                        setMemos((memos) => {
                            memos[memoIndex].completed = data.completed;
                            return [...memos];
                        });

                        target.disabled = false;
                    }, 100);
                })
                .catch((error) => {
                    toast.error(`Er is iets mis gegaan met ${showCompleted ? 'herstellen' : 'afvinken'} van de memo!`);
                    console.error(error);

                    target.disabled = false;
                });
        },
        [authAxios, showCompleted]
    );

    const openMemoPopup = useCallback(async (isEdit = false, memoIndex, savedMemoData = {}) => {

        let formatedMemos = [];

        // parse attachment only for edit
        if (isEdit && savedMemoData?.attachment?.length > 0) {

            for (let savedMemo of savedMemoData?.attachment) {

                savedMemo = {
                    id: savedMemo?.id,
                    blobURL: savedMemo?.formats?.thumbnail?.blob ?? savedMemo?.blob,
                    mime: savedMemo?.mime ?? null,
                    newFile: null,
                    canEdit: savedMemoData.linkedItem?.[0]?.note?.attachment?.id ? false : true
                };
                formatedMemos.push(savedMemo);
            }
            savedMemoData.attachment = formatedMemos;
        } else {
            // remove attachment from the memo state object otherwise `null` is set to attachment in the form
            delete savedMemoData.attachment;
        }

        setFormValues(savedMemoData ?? {});
        setFormEditId(isEdit ? savedMemoData.id : null);
        setCurrentMemoIndex(isEdit ? memoIndex : null);

        openPopup('add-or-edit-memo');
    }, []);

    const deleteMemo = useCallback(
        (memoIndex, memoId) => {
            if (window.confirm('weet u zeker dat deze memo voor alle ontvangers wil verwijderen?')) {
                // delete memo from strapi
                authAxios
                    .delete(`memos/${memoId}`)
                    .then(() => {
                        // remove memo from state
                        setMemos((memos) => {
                            delete memos[memoIndex].completed;
                            return [...memos];
                        });
                    })
                    .catch((error) => {
                        toast.error(`Er is iets mis gegaan met verwijderen van de memo!`);
                        console.error(error);
                    });
            }
        },
        [authAxios]
    );

    return (
        <>
            <Block
                {...props}
                name='memos'
                title="Team-memo's"
                headerRightSideChildren={
                    <>
                        <select
                            value={showCompleted}
                            onChange={({ target }) => setShowCompleted(target.value === 'true')}
                        >
                            <option value='false'>Niet afgevinkt</option>
                            <option value='true'>Afgevinkt</option>
                        </select>
                        <button
                            onClick={() => openMemoPopup()}
                            className='btn btn--black btn--round btn--small'
                            title='Memo toevoegen'
                        >
                            <Icon name='plus' />
                        </button>
                    </>
                }
            >
                {/* loading */}
                {isLoading && <SpinnerCenter />}

                {/* not loading & no memo's */}
                {!isLoading && memos?.length === 0 && <>Geen memo's gevonden!</>}

                {/* not loading & memo's found */}
                {!isLoading && memos?.length > 0 && (
                    <>
                        {/* no memo's in the current filter */}
                        {memos.filter((memo) => memo.completed === showCompleted).length === 0 && (
                            <span>Geen {showCompleted ? 'afgevinkte' : 'onafgevinkte'} Team-memo's.</span>
                        )}

                        {/* show memo's */}
                        {memos.map((memo, index) => {
                            // only show the completed/uncompleted memo's.
                            // do not filter beforehand to keep the index correct to update the state correctly
                            return (
                                memo.completed === showCompleted && (
                                    <SingleMemo
                                        key={`${memo.completed ? 'completed' : 'uncompleted'}-${memo.id}`}
                                        authAxios={authAxios}
                                        memo={memo}
                                        memoIndex={index}
                                        toggleCompleted={toggleCompleted}
                                        openMemoPopup={openMemoPopup}
                                        deleteMemo={deleteMemo}
                                        setExpandedMemos={setExpandedMemos}
                                        expandedMemos={expandedMemos}
                                    />
                                )
                            );
                        })}
                    </>
                )}

                <PopupAddOrEditMemo
                    setMemos={setMemos}
                    editStateIndex={currentMemoIndex}
                    editId={formEditId}
                    changedFormValues={formValues}
                />
            </Block>
        </>
    );
}

function SingleMemo({
    authAxios,
    memo,
    memoIndex,
    toggleCompleted,
    openMemoPopup,
    deleteMemo,
    setExpandedMemos,
    expandedMemos
}) {
    const { width } = useWindowDimensions();
    // state to hold the datetime string for this memo
    const [datetimeString, setDatetimeString] = useState('');

    // state to hold the url for the linked item of this memo
    const [URLToOriginal, setURLToOriginal] = useState(null);

    // format the date & time string of this memo
    useEffect(() => {
        const date = formatDateWithoutTime(memo.datetime.date);

        let time = '';
        if (memo.datetime.wholeDay) {
            time = 'Hele dag';
        } else {
            const localeOptions = {
                hour: '2-digit',
                minute: '2-digit'
            };
            const startTime = formatTimeWithoutDate(`${memo.datetime.date} ${memo.datetime.startTime}`, localeOptions);
            const endTime = formatTimeWithoutDate(`${memo.datetime.date} ${memo.datetime.endTime}`, localeOptions);

            time = `${startTime}-${endTime}`;
        }

        setDatetimeString(`${date} - ${time}`);
    }, [memo.datetime.date, memo.datetime.endTime, memo.datetime.startTime, memo.datetime.wholeDay]);

    // set the linked item url
    useEffect(() => {
        // linked to note
        if (memo.linkedItem?.[0]?.note) {
            const note = memo.linkedItem?.[0].note;
            let href = '/crm';

            // add company part to the href
            if (note?.company?.id) {
                href += `/companies/${note.company.id}`;
            } else if (note?.supplier?.id) {
                href += `/suppliers/${note.supplier.id}`;
            }

            // add contactperson part to the href
            if (note?.contactPerson?.id) {
                href += `/contactpersons/${note.contactPerson.id}`;
            }

            setURLToOriginal(href);
        } else {
            setURLToOriginal(null);
        }
    }, [memo.linkedItem]);

    return (
        <div className={`task priority_${memo?.importance}`} data-memo={memo.id}>
            <div className='task__status-checkbox'>
                <input
                    type='checkbox'
                    defaultChecked={memo.completed} // use default to show the checkmark before the memo dissapearing
                    onChange={(e) => toggleCompleted(e.target, memoIndex, memo.id)}
                    title={memo.completed ? 'Memo herstellen' : 'Memo afvinken'}
                />
            </div>
            <div className='task__content'>
                <div className='task__header'>
                    <div className='task__header__title'>
                        <strong>{memo.title}</strong>

                        <div className='task__header__date' title='Memo datum'>
                            <span>{datetimeString}</span>
                        </div>
                    </div>

                    <div
                        className='task__header__actions'
                        style={{
                            position: width > 400 ? 'absolute' : 'static',
                            top: 0,
                            right: 0,
                            display: 'flex'
                        }}
                    >
                        <button
                            onClick={() => openMemoPopup(true, memoIndex, { ...memo })} // destruct to prevent references overriding
                            className='btn--margin-l btn--black btn--round'
                            title='Bewerk memo'
                        >
                            <Icon name='pencil' />
                        </button>
                        <button
                            onClick={() => deleteMemo(memoIndex, memo.id)}
                            className='btn--margin-l btn--black btn--round'
                            title='Verwijder memo'
                        >
                            <Icon name='trash-can' />
                        </button>

                        <button
                            className='btn--transparent btn--no-focus'
                            title='Memo uitvouwen'
                            onClick={(e) => {
                                if (expandedMemos.includes(memo.id) === true) {
                                    setExpandedMemos((prev) => [...prev.filter((value) => value !== memo.id)]);
                                } else {
                                    setExpandedMemos((prev) => [...prev, memo.id]);
                                }
                            }}
                            style={{
                                padding: 0
                            }}
                        >
                            <Icon
                                style={{
                                    marginLeft: '10px',
                                    width: '25px',
                                    height: '20px',
                                    transform: expandedMemos.includes(memo.id) ? 'scaleY(-1)' : 'scaleY(1)'
                                }}
                                name={'angle-down'}
                            />
                        </button>
                    </div>
                </div>
                <div style={{ display: expandedMemos.includes(memo.id) !== true && 'none' }}>
                    <div className='task__recipients'>
                        <div className='task__recipients__icon' title='Alle ontvangers bij deze memo'>
                            {width > 500 && <Icon name='users' />}
                        </div>

                        <div className='task__recipients__names'>
                            {memo?.recipients.map(({ value, label }) => (
                                <span key={value} className='task__recipients__nametag' title='Ontvanger van deze memo'>
                                    {label}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className='task__content__body'>
                        <p>{memo?.content}</p>
                    </div>

                    {memo.attachment?.length > 0 &&
                        memo?.attachment?.map(file => {

                            return (
                                <div className='task__content__attachment'>
                                    {/* todo: make reusable global component for/from this */}
                                    <div
                                        className={`file-upload__preview__container ${!/^image|video/.test(file?.mime) ? 'auto-size' : ''
                                            }`}
                                    >
                                        {/* show preview image */}
                                        {/^image/.test(file?.mime) && (
                                            <div
                                                className='file-upload__preview__wrapper'
                                                onClick={() =>
                                                    openFileInNewTab(
                                                        undefined,
                                                        authAxios,
                                                        file?.id,
                                                        file?.mime
                                                    )
                                                }
                                            >
                                                <div className='overflow-hidden--center'>
                                                    <img
                                                        src={file?.formats?.thumbnail?.blob}
                                                        alt={file?.formats?.thumbnail?.name}
                                                        className='file-upload__preview-img'
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {/* show preview video */}
                                        {/^video/.test(file?.mime) && (
                                            <div
                                                className='file-upload__preview__wrapper'
                                                onClick={() =>
                                                    openFileInNewTab(
                                                        undefined,
                                                        authAxios,
                                                        file?.id,
                                                        file?.mime
                                                    )
                                                }
                                            >
                                                <div className='video__wrapper overflow-hidden--center'>
                                                    <video controls={false}>
                                                        <source src={file?.blob} type={file?.mime} />
                                                    </video>
                                                    <div className='video__overlay'>
                                                        <Icon name='circle-play' width='80px' />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* show icon for other files */}
                                        {!/^image|video/.test(file?.mime) && (
                                            <div
                                                className='file-upload__preview-icon__wrapper'
                                                onClick={() =>
                                                    openFileInNewTab(
                                                        undefined,
                                                        authAxios,
                                                        file?.id,
                                                        file?.mime
                                                    )
                                                }
                                            >
                                                <Icon name='file-paperclip' width='50px' className='file-upload__preview-img' />
                                            </div>
                                        )}
                                    </div>
                                </div>)
                        })
                    }

                    {/* link to original linked item */}
                    {memo.linkedItem?.[0]?.note && (
                        <Link to={URLToOriginal} title='Naar origineel bericht'>
                            Bekijk origineel bericht
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}
