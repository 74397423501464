export default function TimeSelector({ selectedTime, setSelectedTime }) {

    function onTimeChange(event) {
        setSelectedTime(event.target.value);
    }

    return (
        <div className="planning__header__filter__time">
            <select value={selectedTime} onChange={onTimeChange}>
                <option value="MONTHLY">Maandelijks</option>
                <option value="WEEKLY">Wekelijks</option>
                {/* <option value="DAILY">Dagelijks</option> */}
            </select>
        </div>
    );
}
