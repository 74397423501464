import {toast} from 'react-toastify';
import './Popup.scss';
import Title from "UI/App/Components/Title/Title";
import Icon from "UI/App/Components/Icon/Icon";
import {useEffect, useState} from 'react';
import IF from "UI/App/Components/Conditional/IF";

/**
 * Hides all popups
 *
 * @export
 */
export function closePopup(id = null) {
	let popups = document.querySelectorAll('.popup');
	let popupOverlays = document.querySelectorAll('.emily-overlay');

	if (id && typeof id === 'string') {
		// Change the popups to only the one with the specified id
		popups = document.querySelectorAll(`#${id}`);

		// Get all popups that are visible
		const otherVisiblePopups = [...document.querySelectorAll(`.popup:not(#${id})`)]
			.filter((popup) => popup.style.display === 'block');

		// If there are other popups visible, the overlay should not be removed
		// So we clear the overlays array
		if (otherVisiblePopups.length !== 0) {
			popupOverlays = [];
		}
	}

	// hide all popups
	for (const node of popups) {
		if (node.style.display === 'none') {
			continue;
		}

		node.style.display = 'none';

		// Dispatch event to close the popup
		const event = new CustomEvent('close-popup', { detail: { popupID: node.id } });
		document.dispatchEvent(event);
	}

	// hide all overlays
	for (const node of popupOverlays) {
		node.style.display = 'none';
	}

	// remove key listener event
	document.removeEventListener('keydown', keyListen);
}

/**
 * Go back 1 step in the popup content.
 *
 * @export
 * @param {function} setIndex The state setter that sets the contentIndex number.
 * @return {*}
 */
export function goBack(setIndex) {
	if (typeof setIndex !== 'function') {
		return;
	}

	setIndex((prev) => {
		if (prev <= 0) {
			return 0;
		}
		else {
			return prev - 1
		}
	});
}

/**
 * Open a popup with the specified id attribute.
 *
 * @export
 * @param {string} popupID Name of the popup with id attribute to open.
 * @param {boolean} [showOverlay=true] `false` to not show a background behind the popup.
 * @param {boolean} [escapable=true] `false` to disable the escape key to close the popup.
 * @param {Function} setIndex The state setter that sets the contentIndex number.
 * @return {*}
 */
export function openPopup(popupID, showOverlay = true, escapable = true, setIndex = () => {}) {
	const popup = document.getElementById(popupID);
	if (!popup) {
		toast.error(`De popup (#${popupID}) kon niet gevonden worden.`);
		return;
	}

	// show the specified popup by id
	popup.style.display = "block";

	// show transparent background
	const emilyOverlay = escapable === true ? document.getElementById('emily-overlay') : document.getElementById('emily-overlay__no-click');
	if (emilyOverlay && showOverlay) {
		emilyOverlay.style.display = 'block';
	}

	// add event for listening to key presses when a popup is open
	document.addEventListener('keydown', keyListen);

	// reset content index to 0 if the index setter is set
	if (typeof setIndex === 'function') {
		setIndex(0);
	}
}

export function popupIsOpen(popupID) {
	const popup = document.getElementById(popupID);
	if (!popup) {
		return false;
	}

	return popup.style.display === 'block';
}

/**
 * The keydown event listener
 */
function keyListen({key}) {
	if (key === 'Escape') {
		closePopup();
	}
}

/**
 * Insert a popup
 *
 * @export default
 * @param overflow
 * @param style
 * @param contentStyle
 * @param {JSX.Element} children The content.
 * @param {string} popupID ID of the popup.
 * @param {string} [title=''] The title for the popup header.
 * @param {number} contentIndex The index of the current content. used with multiple steps for content inside the popup.
 * @param {function} backButtonIndexSetter The state setter that sets the contentIndex number.
 * @param closeable
 * @return {JSX.Element} Popup html.
 */
export default function Popup({ overflow = false, style = {}, contentStyle, children, popupID, title = '', contentIndex, backButtonIndexSetter, closeable = true }) {
	// set the visibility of the back button based on the contentIndex value
	const [backButtonVisibility, setBackButtonVisibility] = useState(false);
	useEffect(() => {
		setBackButtonVisibility(() => {
			return !(!contentIndex || contentIndex <= 0);
		});
	}, [contentIndex]);

	if(overflow){
		style['overflow'] = 'visible'
	}

	return (
		<div
			key={popupID}
			style={style}
			className={`popup popup-${popupID}`}
			id={popupID}
		>
			<div key='header' className='popup__header'>
				<button key='goBack' className='btn--transparent popup__button' {...{ style: { visibility: backButtonVisibility ? 'visible' : 'hidden' } }} onClick={() => goBack(backButtonIndexSetter)}>
					<Icon name='arrow-left'></Icon>
				</button>
				<Title key='title' type="h3" className="popup__title">{title}</Title>

				<button
					style={(closeable === true ? {} : { pointerEvents: 'none' })}
					key='close'
					className='btn--transparent popup__button'
					onClick={() => { if (closeable) closePopup(); }}>
					<IF condition={closeable}>
						<Icon name='times'></Icon>
					</IF>
				</button>

			</div>

			<div
				key='content'
				className='popup__content' {...{ 'data-contentindex': contentIndex }}
				style={overflow ? { ...contentStyle, overflow: 'visible' } : { ...contentStyle }}
			>
				{children}
			</div>
		</div>
	);
}
