import './Barcode.scss';
import Barcode from 'react-barcode';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import isEqual from "lodash/isEqual";

export default function BasicBarcodeGenerator(props) {
    // keep track of props to know if we should redownload.
    const [lastProps, setLastProps] = useState(undefined)
    const codeRef = useRef(undefined)

    useEffect(() => {
        if (props.value === undefined) {
            if (props.setBase64 !== undefined) {
                props.setBase64(undefined);
            }
        }
        // we dont need to redownload every time the props get set, only redownload if there is a diff
        if ((props.download || props.setBase64 !== undefined) && !isEqual(props, lastProps)) {
            if (codeRef === undefined) return;
            downloadImage(props.name);
        }
        setLastProps(props)
    }, [props]);

    const downloadImage = async (name = undefined) => {
        let element = document.getElementById('bar-code-wrapper');

        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/jpeg;');
        const link = document.createElement('a');

        link.href = data;
        // if we have a base64 setter 
        if (props.setBase64 !== undefined) {
            props.setBase64(data);
        }
        if (name === undefined) {
            name = new Date().getTime();
        }
        if (props.download) {
            link.download = `BARC_${name}.${"jpg"}`;
        }


        document.body.appendChild(link);

        document.body.removeChild(link);


    }

    return (
        <div id={"bar-code-wrapper"} className={"bar-code-wrapper"} style={props.show ? { zoom: props.zoom ?? 1 } : { display: "none", zoom: props.zoom ?? 1 }}>

            <Barcode
                width={props?.width}
                height={props?.height}
                renderer={props?.renderer ?? "img"}
                format={props?.format ?? "EAN13"}
                displayValue={props?.displayValue}
                fontOptions={props?.fontOptions}
                font={props?.font}
                fontSize={props?.fontSize}
                textAlign={props?.textAlign}
                textPosition={props?.textPosition}
                textMargin={props?.textMargin}
                background={props?.background}
                lineColor={props?.lineColor}
                margin={props?.margin}
                marginTop={props?.marginTop}
                marginBottom={props?.marginBottom}
                marginLeft={props?.marginLeft}
                marginRight={props?.marginRight}
                ean128={props?.ean128}

                //value to display
                value={props?.value ?? ""}
            />

        </div>

    );
}