import React, {ReactElement} from "react"
import IWidget from "./IWidget"
import WidgetBase from "./WidgetBase"
import {TrenderType} from "./WidgetTypes"
import CreatableSelect from "react-select/creatable";

/**
 * Widget class
 * @extends WidgetBase @implements IWidget
 */
export default class Subtraction extends WidgetBase implements IWidget {
	callback: ((object: Object) => any);
	widgetData: String | null
	saveWidget: ((object: Object) => any);
	optionalWidgetData: object | undefined;
	debug: boolean;

	prefix: string | undefined;

	constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
		super()
		this.renderType = renderType;
		this.callback = callback;
		this.widgetData = null;
		this.saveWidget = saveWidget;
		this.optionalWidgetData = optionalWidgetData;
		this.debug = debug;
		this.prefix = prefix;
	}

	/**
	 * @return ReactElement
	 */
	public getPreviewHTML(): JSX.Element {
		const widgetData = {
			widgetType: 'Subtraction',
		}

		return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
		}}>val - val</div>
	}

	/**
	 * Returns from html
	 */
	public getFormHTML(): JSX.Element {
		const widgetValues = this.getWidgetValues();

		return <form onSubmit={(e) => this.handleSubmit(e, this)}>
			<h3>Aftrekken</h3>
			<div className="input-group">
				Naam: <input type="text" name="title" />
			</div>
			<div className="input-group">
				<CreatableSelect
					name="val1"
					options={widgetValues}
				/>
				<CreatableSelect
					name="val2"
					options={widgetValues}
				/>
			</div>
			<button type="submit">Opslaan</button>
		</form>
	}

	/**
	 * Handles submit from getFormHTML
	 * @param event:any
	 */
	handleSubmit(event: any): void {
		event.preventDefault();

		const data = {
			type: "Subtraction",
			title: event.target.elements.title.value,
			value: {
				val1: event.target.elements.val1.value,
				val2: event.target.elements.val2.value
			}
		};

		this.addWidgetToWidgetContainer(data?.title + "<br> (" + data.value.val1 + ") - (" + data.value.val2 + ")", data);
	}

	public getBadgeHTML() {
		return <div>Badge html:</div>
	}

	/**
	 * Renders calculation form
	 */
	public getCalculationForm() {

		this.addWidgetToCalculationForm(`Subtraction`, this.optionalWidgetData, this.optionalWidgetData.parentId)
		// return <div id={this.optionalWidgetData.id}>subtraction</div>
	}

	/**
	 * Renders preview form
	 */
	public getPreviewFormHTML(): ReactElement {
		return <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.title} value={this?.optionalWidgetData?.value} />
	}

	/**
	 * Returns formula string of given widget
	 */
	public getFormulaString(): string {
		return `${this?.optionalWidgetData?.value.val1} - ${this.optionalWidgetData?.value.val2}`;
	}

	/**
	 * Returns html for edit
	 */
	getEditHTML(): void {
		(new WidgetBase()).addWidgetToWidgetContainer(this.optionalWidgetData?.data?.title + "<br> (" + this.optionalWidgetData?.data.value.val1 + ") - (" + this.optionalWidgetData?.data.value.val2 + ")", this.optionalWidgetData, '', true);
	}

	/**
	 * Returns from html
	 */
	public getEditWidgetForm(editData: object): JSX.Element {
		const widgetValues = this.getWidgetValues();
		const [selectedOne, selectedTwo] = WidgetBase.getSelectedValues(widgetValues, editData.value.val1, editData.value.val2);

		return <form onSubmit={this.handleEdit}>
			<input type="hidden" name={"id"} value={editData.id} />
			<div className="input-group">
				Naam: <input type="text" name="title" defaultValue={editData.title} />
			</div>
			<div className="input-group">
				<CreatableSelect
					defaultValue={selectedOne}
					name="val1"
					options={widgetValues}
				/>
				<CreatableSelect
					defaultValue={selectedTwo}
					name="val2"
					options={widgetValues}
				/>
			</div>
			<button type="submit">Opslaan</button>
		</form>
	}

	/**
	 * Handles submit from getFormHTML
	 * @param event:any
	 */
	handleEdit(event: any): void {
		event.preventDefault();

		const data = {
			type: "Subtraction",
			title: event.target.elements.title.value,
			value: {
				val1: event.target.elements.val1.value,
				val2: event.target.elements.val2.value
			}
		};

		(new WidgetBase()).updateWidget(event.target.elements.id.value, data?.title + "<br> (" + data.value.val1 + ") + (" + data.value.val2 + ")", data);
	}

	/**
	 * Calcualtion logic
	 */
	calculationFunctionality(): any {
		const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
		const val1 = this.optionalWidgetData?.data?.value?.val1;
		const val2 = this.optionalWidgetData?.data?.value?.val2;



		// console.log(this.optionalWidgetData?.data.value?.conditional === undefined);
		/**
		 * If has no conditional logic remove hidden and remove readonly
		 */
		if (this.optionalWidgetData?.data.value?.conditional === undefined || this.optionalWidgetData?.data.value?.conditional.target === '') {
			/**
			 * Set widget data
			 */
			this.optionalWidgetData.data.value.result = +(this.getResultFromWidget(val1, this.optionalWidgetData?.prefix)) - +(this.getResultFromWidget(val2, this.optionalWidgetData?.prefix));

			/**
			 * Update widget data in storage
			 */
			this.updateWidgetData(id, this.optionalWidgetData)
		} else {
			/**
			 * If condition is right set usable else disable and hide
			 */
			if (this.handleConditional(this.optionalWidgetData.data.value.conditional)) {
				/**
				 * Set widget data
				 */
				this.optionalWidgetData.data.value.result = +(this.getResultFromWidget(val1, this.optionalWidgetData?.prefix)) - +(this.getResultFromWidget(val2, this.optionalWidgetData?.prefix));

				/**
				 * Update widget data in storage
				 */
				this.updateWidgetData(id, this.optionalWidgetData)
			} else {
				if (!document.getElementById(id).parentElement.classList.contains('hidden')) {
					/**
					 * Set widget data
					 */
					this.optionalWidgetData.data.value.result = 0;

					/**
					 * Update widget data in storage
					 */
					this.updateWidgetData(id, this.optionalWidgetData)
				}
			}
		}

		/**
		 * Handle debug
		 */
		if (this.debug) {
			document.getElementById(id).innerHTML = "";
			document.getElementById(id).append(id + `|Min id: ${this.optionalWidgetData.data.title} : ${val1} - ${val2} = ${this.optionalWidgetData.data.value.result}`)
			document.getElementById(id).classList.remove('hidden');
		} else {
			document.getElementById(id).innerHTML = "";
			document.getElementById(id).classList.add('hidden');
		}
	}
}
