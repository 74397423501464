import {useContext, useEffect, useState} from "react"
import {FetchContext} from "App/Strapi/FetchContext"
// import { usePagination } from "UI/App/Components/update/Pagination";
import {stringify} from "qs";
import Table from "UI/App/Components/Table/Table";
import {toast} from "react-toastify";
import {AuthContext} from "App/Strapi/AuthProvider";
import Icon from "UI/App/Components/Icon/Icon";
import Button from "UI/App/Components/Button/Button";
import Boolean from "../../../../Components/Form/Boolean";
import Container from "UI/App/Components/Container/Container";

export default function Users() {
	// Use context for axios call
	const { authAxios } = useContext(FetchContext);
	const [tableData, setTableData] = useState([]);
	const { authState } = useContext(AuthContext);

	/**
	 * TODO: Implement pagination either when the API has been converted, modify the endpoint manually or update the pagination hook to support using manual pagination (start and limit)
	 * @see https://github.com/strapi/strapi/issues/12911
	 */
	// const { pagination, currentPage, resultsPerPage, setTotalPages, setTotalResults, filterQuery } = usePagination({
	//     enabled: true,
	//     strapiFields: [
	//         'username',
	//         'email'
	//     ]
	// });

	useEffect(() => {
		const params = stringify({
			populate: 'role',
			'filters[id][$ne]': authState.user.id
		});
		authAxios.get(`../users?${params}`).then(({ data }) => {
			const tempData = [];

			data.sort((a, b) => a.username.localeCompare(b.username)).forEach((user) => {
				tempData.push({
					attributes: {
						user: user.id,
					},
					data: [
						user.username,
						user.email,
						user.role.name,
						<Boolean displayFalse={"Inactief"} displayTrue={"Actief"} value={!user.blocked} nullable={false} field={{name: 'active'}} falseIsRed={true} setValue={(field, value) => toggleBlocked(!user.blocked, user.id)} />,
						<div className={'table__action-buttons'}>
							<Button data-title={'Bewerken'}  className='btn btn--transparent' to={`/admin/users/${user.id}`}><Icon name='pencil' /></Button>
						</div>
					]
				})
			});

			setTableData(tempData);
		}).catch((exception) => {
			toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`)

			console.error(exception);
		});

		function toggleBlocked(blocked, userId) {
			authAxios.put(`../users/${userId}`, {
				blocked: blocked
			}).then(() => {
				setTableData(prevData => {
					return prevData.map((row) => {
						if (row.attributes.user === userId) {
							row.data[3] = <Boolean displayFalse={"Inactief"} displayTrue={"Actief"} value={blocked} nullable={false} field={{name: 'active'}} falseIsRed={true} setValue={(field, value) => toggleBlocked(!blocked, userId)} />
						}
						return row;
					})
				})

				toast.success('Gebruiker bijgewerkt!');
			});
		}
	}, [authAxios, authState.user.id]);

	return (
		<Container>
			<Table
				headers={['User', 'Email', 'Role', 'Account status', '']}
				data={tableData}
				structure={['50%', '50%', '150px', '200px', '70px']}
				hover={false}
				padding={'m'}
				columnOverflowExceptions={[4]}
			/>
		</Container>
	)
}
