import Block from "UI/App/Components/Block/Block";
import IF from "UI/App/Components/Conditional/IF";
import "../JobTicket.scss";
import JobTicketGroupContent from "UI/App/Partials/Content/CRM/Workflow/Components/JobTicketGroupContent";
import {useForm} from "react-hook-form";
import {useContext, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

export default function JobTicketTemplateForm({
    jobTicketTemplate,
    orderData,
    calculationId
}) {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    const [groupVisible, setGroupVisible] = useState({})

    const {
        register,
        handleSubmit,
        control
    } = useForm();

    const onSubmit = (data) => {
        const workflowData = {
            jobTicketTemplate: jobTicketTemplate.id,
            order: orderData.id,
            data
        }

        authAxios.post(`/workflow/job-tickets`, workflowData)
            .then(({data}) => {
                toast.success('Job ticket is aangemaakt.');
                navigate(`/orders/${orderData.id}`);
            })
            .catch(error => {
                console.error(error);
                toast.error('Er is iets misgegaan bij het aanmaken van de job ticket.')
            })

        console.log(workflowData);
    }

    return (
        <Block name={'job-ticket-template-form'} contentStyle={{
            borderRadius: '5px'
        }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {jobTicketTemplate?.groups.map(group => (
                    <div key={group.id} className={'job-ticket__group'}>
                        <span className={'job-ticket__group-title'}>
                            <IF condition={group?.optional}>
                                <input
                                    type={'checkbox'}
                                    {...(group?.optional ? register(`${group.name.toLowerCase()}.enabled`) : {})}
                                    onChange={(e) => {
                                        setGroupVisible(groupVisible => ({
                                            ...groupVisible,
                                            [group.name.toLowerCase()]: e.target.checked
                                        }))

                                    }}
                                />
                            </IF>
                            <h3>{group.name}</h3>
                        </span>

                        <JobTicketGroupContent
                            isVisible={group?.optional ? groupVisible[group.name.toLowerCase()] : true}
                            jobTicketGroup={group}
                            orderData={orderData}
                            calculationId={calculationId}
                            register={register}
                            control={control}
                        />
                    </div>
                ))}
                <button>Submit</button>
            </form>
        </Block>
    )
}