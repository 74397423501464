import Block from "../../../../Components/Block/Block";
import Select from "react-select";
import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import QuotePDFGenerator from "UI/App/Partials/Content/CRM/Quotes/QuotePDFGenerator";
// Used for quote/confirmation
import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";
import Briefpapier_HJMG from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg";
import Briefpapier_HJMG_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG_2.jpg";
import qs, {stringify} from "qs";
import {clone} from "lodash";
import {useNavigate} from "react-router-dom";

export default function CopyOrder({orderId = null,closePopup}) {
    const {authAxios} = useContext(FetchContext);
    const [originalOrderData, setOriginalOrderData] = useState(null);
    const navigate = useNavigate();

    const [amounts, setAmounts] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(null);

    const [quotePrice, setQuotePrice] = useState('');

    // UseEffect for getting order data
    useEffect(() => {
        // If order is not null or undefined load order data
        if (orderId !== null && orderId !== undefined) {
            getOrderData(orderId);
        }

    }, [orderId]);

    // UseEffect for loading order data
    useEffect(() => {
        // Load inputs for copy
        if (originalOrderData !== null) {
            loadAmounts();
        }
    }, [originalOrderData]);

    // UseEffect for loading quote price
    useEffect(() => {

        // If amount is selected get quote price
        if (selectedAmount !== null) {
            loadQuotePrice();
        }
    }, [selectedAmount]);

    function loadQuotePrice() {
        // Try to get quote price from calculation
        let potentialQuotePrice = originalOrderData?.calculation?.data?.summary[selectedAmount.value]?.quote;

        // If quote price is found add to input
        if (potentialQuotePrice !== undefined) {
            setQuotePrice(potentialQuotePrice.toFixed(2))
        }
    }

    // Load amounts from order
    function loadAmounts() {
        // Load amounts for amount select
        let amountOptions = [];
        for (const amount of originalOrderData?.calculation?.data?.amounts) {
            amountOptions.push({
                label: amount,
                value: amount
            })
        }

        setAmounts(amountOptions)
    }

    // Load order data
    function getOrderData(orderId) {

        const query = stringify({
            populate: {
                additionalMaterials: {
                    populate: {
                        material: true
                    }
                },
                company: {
                    populate: ['managingCompany']
                },
                contactPerson: true,
                calculation: {
                    populate: {
                        purchaseQuotes: {
                            fields: ['id', 'number'],
                        }
                    }
                },
                quote: {
                    populate: {
                        priceData: true,
                        contactPerson: true,
                        company: {
                            populate: ['telephone', 'managingCompany']
                        },
                        copies: {
                            fields: ['id', 'number'],
                        },
                    }
                },
                stickers: {
                    sort: ['id:desc'],
                    populate: {
                        file: {
                            fields: ['id', 'name', 'mime']
                        }
                    }
                },
                quoteConfirmation: {
                    populate: {
                        quote: {
                            populate: {
                                contactPerson: true,
                                company: {
                                    populate: ['telephone', 'managingCompany']
                                },
                                copies: {
                                    fields: ['id', 'number'],
                                },
                            }
                        },
                        calculation: {
                            populate: {
                                stockQuotes: {
                                    fields: ['id'],
                                    populate: {
                                        stockPurchaseSlip: {
                                            fields: ['id']
                                        },
                                    }
                                },
                                copies: {
                                    fields: ['id', 'number'],
                                },
                                purchaseQuotes: {
                                    fields: ['id', 'number'],
                                }
                            }
                        }
                    }
                },
                packingSlips: {
                    fields: [
                        'id',
                        'number'
                    ],
                    sort: ['id:desc']
                },
                jobTickets: {
                    populate: [
                        'id',
                        'jobTicketTemplate'
                    ],
                },
                project: {
                    fields: ['id']
                }
            }
        });

        // Reset all form data incase of order switch
        resetForm();

        // Get orderData from API
        authAxios.get(`/orders/${orderId}?${query}`)
            .then(({data}) => {
                console.log(data);
                setOriginalOrderData(data.data);
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }

    // resets values to default
    function resetForm() {
        setSelectedAmount(null);
        setQuotePrice('');
    }

    // Stores copy and handles everything needed to copy orders
    async function storeCopy() {
        const pendingUpdate = toast.loading("Templates updaten...");

        let calculation = null, quote = null, confirmation = null;

        // Handle calculations
        toast.update(pendingUpdate, {
            render: `Order kopiëren.`,
            type: "info",
            isLoading: true
        });
        // Handle order
        let newOrder = await handleOrderData({...originalOrderData},pendingUpdate);


        // Handle calculations
        toast.update(pendingUpdate, {
            render: `Calculaties kopiëren.`,
            type: "info",
            isLoading: true
        });

        await copyCalculation(originalOrderData?.calculation?.id, newOrder)

        // Handle quote
        if (originalOrderData?.quote?.id !== null || originalOrderData?.quote?.id !== undefined) {
            // Handle calculations
            toast.update(pendingUpdate, {
                render: `Offertes kopiëren.`,
                type: "info",
                isLoading: true
            });

            await copyQuote(originalOrderData?.quote?.id, newOrder);
        }

        // Handle confirmations
        if (originalOrderData?.quoteConfirmation?.id !== null || originalOrderData?.quoteConfirmation?.id !== undefined) {
            // Handle calculations
            toast.update(pendingUpdate, {
                render: `Offerte bevestiging kopiëren.`,
                type: "info",
                isLoading: true
            });

            if(originalOrderData?.quoteConfirmation?.id !== undefined){
                await copyConfirmation(originalOrderData?.quoteConfirmation?.id, calculation, quote,newOrder);
            }
        }

        toast.update(pendingUpdate, {
            render: `Order gekopiërd het nieuwe order nummer: ${newOrder.data.number}`,
            type: "success",
            isLoading: false,
            closeButton: true,
            closeOnClick: true,
            autoClose:4000
        });

        closePopup();
        navigate(`/orders/${newOrder.data.id}`)
    }

    async function handleOrderData(order,pendingUpdate) {
        const originalOrder = clone(order);

        // Change status to 'binnengekomen'
        order.status = 'binnengekomen'

        // Delete breaking keays
        delete order.id

        // Empty to re-add later with new entries
        order.packingSlips = [];
        order.jobTickets = [];
        order.stickers = [];
        order.stockData = null;

        // Handle calculations
        toast.update(pendingUpdate, {
            render: `Order aanmaken.`,
            type: "info",
            isLoading: true
        });

        // Order toevoegen
        const newOrder = await authAxios.post(`/orders`, {
            data: order
        }).then(({data}) => {
            toast.success(`Order aangemaakt!`, {
                autoClose: 800
            });

            return data;
        }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })

        // Handle calculations
        toast.update(pendingUpdate, {
            render: `pakbonnen aanmaken.`,
            type: "info",
            isLoading: true
        });
        // - Pakbonnen worden gekopieërd
        for (const originalPackingSlip of originalOrder.packingSlips) {
            const newPackingSlip = await authAxios.get(`orders/packing-slips/${originalPackingSlip.id}?populate=*`)
                .then(({data}) => {

                    return data
                }).catch((exception) => {
                    toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                    console.error(exception);
                })

            // copy and add new packing slip to order
            newPackingSlip.data.order = newOrder.data.id
            delete newPackingSlip.data.number
            delete newPackingSlip.data.id

            await authAxios.post(`orders/packing-slips`, newPackingSlip)
                .then((data) => {
                    console.log(data);
                    toast.success(`Pakbon aangemaakt!`, {
                        autoClose: 800
                    });
                }).catch((exception) => {
                    toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                    console.error(exception);
                })
        }

        // Handle calculations
        toast.update(pendingUpdate, {
            render: `Stickers aanmaken.`,
            type: "info",
            isLoading: true
        });
        // - Job tickets worden gekopieërd
        if (originalOrder.jobTickets.length !== 0) {
            // Get jobtickets to loop and reset jobtickets array
            const oldJobTickets = [...originalOrder.jobTickets];
            order.jobTickets = [];

            // Loop jobtickets to create new ones to add to the new order
            for (const jobTicket of oldJobTickets) {

                const newJobTicket = {...jobTicket}
                // Remove id to post new jobticket
                delete newJobTicket.id

                await authAxios.post(`/workflow/job-tickets/`, {
                    jobTicketTemplate: newJobTicket.jobTicketTemplate.id,
                    order: newOrder.data.id,
                    data: newJobTicket
                }).then(({data}) => {
                    toast.success(`JobTicket aangemaakt!`, {
                        autoClose: 800
                    });

                    return data;
                }).catch((exception) => {
                    toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                    console.error(exception);
                })
            }
        }


        // Stickers toevoegen aan order
        // - Job tickets worden gekopieërd
        if (originalOrder.stickers.length !== 0) {
            // Get jobtickets to loop and reset jobtickets array
            const oldStickers = [...originalOrder.stickers];
            order.jobTickets = [];

            // Loop jobtickets to create new ones to add to the new order
            for (const sticker of oldStickers) {

                const newSticker = {...sticker}
                // Remove id to post new jobticket
                delete newSticker.id

                await authAxios.post(`/stickers/`, {data:newSticker}).then(({data}) => {
                    toast.success(`Sticker aangemaakt!`, {
                        autoClose: 800
                    });

                    return data;
                }).catch((exception) => {
                    toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                    console.error(exception);
                })
            }
        }

        return newOrder
    };

    // Copy confirmations
    async function copyConfirmation(confirmationId, calculation, quote,newOrder) {
        let newConfirmationData = await authAxios.get(`/quotes/confirmations/${confirmationId}?populate=*`)
            .then(({data}) => {
                let newConfirmation = {...data.data}

                // Remove breaking keys
                delete newConfirmation.number;
                delete newConfirmation.id;

                newConfirmation.orders = [newOrder.data.id]

                // Set new data
                if (calculation !== null) {
                    newConfirmation.calculations = [calculation.data.id];
                } else {
                    delete newConfirmation.calculations;
                }

                if (quote !== null && quote !== undefined) {
                    newConfirmation.quote = quote.data.id;
                } else {
                    delete newConfirmation.quote;
                }

                return newConfirmation
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })

        // Post new confirmation and return data
        return await authAxios.post(`/quotes/confirmations/`, {data: newConfirmationData})
            .then(({data}) => {
                toast.success(`Offerte bevestiging ${data.data.number} opgeslagen!`, {
                    autoClose: 800
                });
                return data;
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })
    }

    // Copy quotes if needed
    async function copyQuote(quoteId, newOrder) {
        if (quoteId === undefined) return null;

        // Get latest quote data
        // Get latest calculation data and update needed data
        let updatedQuoteData = await authAxios.get(`/quotes/${quoteId}?populate=*`)
            .then(({data}) => {
                let newQuote = {...data.data}

                // Remove breaking keys
                delete newQuote.number;
                delete newQuote.id;
                newQuote.order = newOrder.data.id

                // Set price data correct
                for (const priceData of newQuote.priceData) {
                    // If correct amount
                    if (selectedAmount.value === priceData.amount) {
                        priceData.price = parseFloat(quotePrice).toFixed(2);
                    }
                }

                // Update data to set correct price
                for (const data of newQuote.data) {
                    // If correct amount
                    if (data.title.includes(selectedAmount.value)) {
                        let currency = data.content.includes('€') ? '€' : '$';

                        data.content = `${currency} ${parseFloat(quotePrice).toFixed(2)}`
                    }
                }

                return newQuote
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })


        let quoteBackground = {page1: Briefpapier_HJMG, page2: Briefpapier_HJMG_2}

        if (updatedQuoteData.calculations[0].owner === 'pretore') {
            quoteBackground = {page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_2};
        }

        // Reformat rows for PDF generator to handle
        let formattedRows = [];
        for (const row of updatedQuoteData.data) {
            if (formattedRows[row.page] === undefined) formattedRows[row.page] = [];
            formattedRows[row.page].push({
                label: row.title,
                content: row.content
            })
        }

        updatedQuoteData.pdfBase64 = await new QuotePDFGenerator(quoteBackground, formattedRows, updatedQuoteData.company, updatedQuoteData.contactPerson, updatedQuoteData.calculations[0].owner, false, updatedQuoteData.pdfTemplate.language, updatedQuoteData.date, null, null, updatedQuoteData.introLine, updatedQuoteData.footerData, null, updatedQuoteData.endDate).getPDF()

        // Post new calculation
        return await authAxios.post(`/quotes`, {data: updatedQuoteData}).then(({data}) => {
            toast.success(`Offerte ${data.data.number} aangemaakt!`, {
                autoClose: 800
            });
            return data
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van calculatie!`);
        });
    }

    async function copyCalculation(originalCalculationId, newOrder) {

        // Get latest calculation data and update needed data
        const newCalculationData = await authAxios.get(`/calculations/${originalCalculationId}?populate=*`)
            .then(({data}) => {
                let newCalculation = {...data.data}

                // Set quote amount to calculation amount
                newCalculation.data.summary[selectedAmount.value].quote = parseFloat(quotePrice);

                // Remove breaking keys
                delete newCalculation.number;
                delete newCalculation.id;
                newCalculation.quote_confirmation = null;

                return newCalculation

            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })

        // Post new calculation
        return await authAxios.post(`/calculations`, {data: newCalculationData}).then(({data}) => {
            toast.success(`Calculatie ${data.data.number} opgeslagen!`, {
                autoClose: 800
            });

            return data
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van calculatie!`);
        });
    }

    return (
        <>
            <Block name={'Order toevoegen'}>
                Aantal:
                <Select
                    options={amounts}
                    value={selectedAmount}
                    onChange={(e) => (setSelectedAmount(e))}
                    isDisabled={amounts === null}
                />
                Offerte bedrag:
                <input
                    type="number"
                    value={quotePrice}
                    onChange={(e) => {
                        setQuotePrice(e.target.value)
                    }}
                    disabled={amounts === null}
                />
                <button disabled={(quotePrice === null || quotePrice === '') || selectedAmount === null} onClick={() => storeCopy()}>
                    Kopieër order
                </button>
            </Block>
        </>
    )
}
