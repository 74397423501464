import React, {useState} from "react";
import ToggleSwitch from "../../../../../Components/Form/ToggleSwitch/ToggleSwitch";
import Select from "react-select";

export default function WidgetConditionalLogic() {
	const [conditionalOptions, setConditionalOptions] = useState();

	/**
	 * Toggles conditional logic
	 */
	const changeConditionalLogic = () => {
		if (document.getElementById('conditionalLogic').classList.contains('hidden')) {
			document.getElementById('conditionalLogic').classList.remove('hidden')
		} else {
			document.getElementById('conditionalLogic').classList.add('hidden')
		}
	}

	/**
	 * Create options form widgets
	 */
	let widgets = document.getElementsByClassName('flow_widget');
	const options = [];
	for (let widget of widgets) {
		let widgetData = JSON.parse(widget.getAttribute('widgetdata'));
		options.push({ value: widget.id, label: widgetData.title + " " + widgetData.value })
	}

	/**
	 * Helper for getFormHTML
	 */
	const changeTypeSelector = () => {
		console.log('asdfasd');
		if (document.getElementById('inputSettings').classList.contains('hidden')) {
			document.getElementById('inputSettings').classList.remove('hidden')
		} else {
			document.getElementById('inputSettings').classList.add('hidden')
		}
	}

	/**
	 * Set conditional widget selected
	 */
	const selectConditionalWidget = (choice) => {

		/**
		 * Get Widget
		 */
		let widgetData = JSON.parse(document.getElementById(choice.value)?.getAttribute('widgetdata'));

		switch (widgetData.type) {
			case "Input":
				setConditionalOptions([
					{ value: ">", label: ">" },
					{ value: "<", label: "<" },
					{ value: ">=", label: ">=" },
					{ value: "<=", label: "<=" },
					{ value: "=", label: "=" }
				]);

				document.getElementById('conditionalValue').classList.remove('hidden');
				document.getElementById('conditionalValue').removeAttribute('readonly');
				document.getElementById('conditionalValue').removeAttribute('disabled');
				break;
			case "Times":
				setConditionalOptions([
					{ value: ">", label: ">" },
					{ value: "<", label: "<" },
					{ value: ">=", label: ">=" },
					{ value: "<=", label: "<=" },
					{ value: "=", label: "=" }
				]);

				document.getElementById('conditionalValue').classList.remove('hidden');
				document.getElementById('conditionalValue').setAttribute('readonly', false);
				document.getElementById('conditionalValue').setAttribute('disabled', false);
				break;
			case "SelectWidget":
				let tmp = [];
				for (const option of widgetData.options) {
					tmp.push({ value: choice.value + "_" + option.value, label: option.name })
				}
				setConditionalOptions(tmp);

				document.getElementById('conditionalValue').classList.add('hidden');
				document.getElementById('conditionalValue').setAttribute('readonly', true);
				document.getElementById('conditionalValue').setAttribute('disabled', true);
				break;
		}
	}


	console.log(conditionalOptions);

	return (
		<>
			Conditioneel: <ToggleSwitch onChange={changeConditionalLogic} />
			<span id="conditionalLogic" className="hidden">
				<Select
					id="conditionalLogicWidget"
					onChange={(choice) => selectConditionalWidget(choice)}
					name="conditionalLogicWidget"
					options={options}
				/>
				<Select
					name="conditionalType"
					options={conditionalOptions}
				/>
				<input type="number" name="conditionalValue" id="conditionalValue" className="hidden" readOnly />
			</span>
		</>
	)
}
