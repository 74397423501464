import {FetchContext} from "App/Strapi/FetchContext";
import React, {useContext, useEffect, useState} from "react";
import Icon from "../../../../../Components/Icon/Icon";
import Select from "react-select";
import {openPopup} from "UI/App/Components/Popup/Popup";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import qs from "qs";


export default function StepThree({width, setWidth, length, setLength, amountRows, limitToOneAmount, setAmountRows, previousStep, nextStep, chosenTemplate, setChosenTemplate}) {
    // States
    const fetchContext = useContext(FetchContext);
    const [templates, setTemplates] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [popupValue, setPopupValue] = useState(null);


    // Run on load
    useEffect(() => {
        loadTemplates();
    }, [chosenTemplate])

    // Run on amount change
    useEffect(() => {
        // loadAmounts()
    }, [amountRows])

    // Remove amount from amount storage
    const removeAmount = (key) => {
        setAmountRows([]);
        let amounts = amountRows;

        amounts.splice(key, 1);

        setTimeout(() => {
            setAmountRows([...amounts])
        }, 50)
    }

    // Update amount
    const updateAmount = (value, key, amountType) => {
        let rows = amountRows

        rows[key][amountType] = parseInt(value)

        setAmountRows([...rows]);
    }

    // Loads templates
    function loadTemplates() {
        const templateQuery = qs.stringify({
            fields: [
                'name',
                'active',
                'isWebshopTemplate'
            ],
            filters: {
                $or: [
                    {
                        isWebshopTemplate: {
                            $eq: false
                        }
                    },
                    {
                        isWebshopTemplate: {
                            $null: true
                        }
                    }
                ]
            }
        })

        const templates = [];
        // Get all templates and set chosen selected
        fetchContext.authAxios.get(`/calculations/templates/all?${templateQuery}`).then((data) => {
            data.data.sort((a, b) => a.name > b.name ? 1 : -1).map((template) => {
                if (chosenTemplate !== null ? template.id === chosenTemplate : false) {
                    setSelectedTemplate({
                        label: template.name,
                        value: template.id
                    });

                    if (!template.active) {
                        templates.push({
                            label: template.name,
                            value: template.id
                        })
                    }
                }

                if (template.active) {
                    templates.push({
                        label: template.name,
                        value: template.id
                    })
                }
            });

            setTemplates(templates);
        }).catch((exception) => {
            console.error(exception);
        })
    }

    const onTemplateChange = (e) => {
        // if chosenTemplate is null we can set template without worry
        // if chosenTemplate is a new value other than old/current, warn the user
        if (chosenTemplate !== null && chosenTemplate !== e.value) {
            // fill the state with data needed
            setPopupValue(e.value)
            // open the popup
            openPopup("templateChangePopup")
        } else {
            // set template
            setChosenTemplate(e.value)
        }

    }
    const addNewAmount = () => {
        if (limitToOneAmount && amountRows.length >= 1) {
            return;
        }

        setAmountRows(prevState => [...prevState, [100, 10]])
    }

    return (
        <>
            <ConfirmationPopup
                popupID={"templateChangePopup"}
                subject={"Let op!"}
                description={{style: {whiteSpace: "pre-wrap", width: "100%", textAlign: "center"}, value: "Wanneer je het template wisselt kan er data verloren gaan.\r\n Controleer goed of alle velden compleet zijn, werk derden goed overgezet is en handwerk goed meegenomen is."}}
                confirmText={{style: {backgroundColor: 'white', border: 'solid var(--primary) 2px', color: 'var(--primary)', boxSizing: 'border-box'}, value: "Doorgaan"}}
                declineText={{style: {border: 'solid var(--primary) 2px', color: 'white', boxSizing: 'border-box'}, value: "Annuleren"}}
                functionOnConfirm={() => setChosenTemplate(popupValue)}
            />
            <div className="form__content">
                <div className="form__item grid__12">
                    <label
                        htmlFor="template__select"
                        className="input__label"
                    >
                        Kies een template:
                    </label>
                    <Select
                        value={selectedTemplate}
                        key="templateSelect"
                        name="template__select"
                        onChange={(e) => onTemplateChange(e)}
                        options={templates}
                        id="template__select"
                    />
                </div>
                <div className="form__item grid__6">
                    <label
                        htmlFor="width__input"
                        className="input__label"
                    >
                        Voer de product breedte in:
                    </label>
                    <input
                        value={width || ''}
                        onChange={e => setWidth(e.target.value)}
                        type="number"
                        step={1}
                        id="width__input"
                        name="width__input"
                    />
                </div>

                <div className="form__item grid__6">
                    <label
                        htmlFor="length__input"
                        className="input__label"
                    >
                        Voer de product hoogte in:
                    </label>
                    <input
                        value={length || ''}
                        onChange={e => setLength(e.target.value)}
                        type="number"
                        step={1}
                        id="length__input"
                        name="length__input"
                    />
                </div>

                <div className="form__item grid__6">
                    <label
                        htmlFor="amount__input__0"
                        className={`input__label`}
                    >
                        Aantal:
                    </label>
                </div>

                <div className="form__item grid__5">
                    <label
                        htmlFor="falls__in__input__0"
                        className="input__label"
                    >
                        Inschiet:
                    </label>
                </div>
                {
                    amountRows.map((amount, key) => [
                        <div
                            className="form__item grid__6"
                            key={key + '0'}
                        >
                            <input
                                type="text"
                                onChange={(e) => updateAmount(e.target.value, key, 0)}
                                defaultValue={amount[0] ?? ''}
                                id={`amount__input__${key}`}
                                name={`amount__input__${key}`}
                            />
                        </div>,
                        <div
                            className="form__item grid__6 form__item__with__button"
                            key={key + '1'}
                        >
                            <input
                                type="text"
                                onChange={(e) => updateAmount(e.target.value, key, 1)}
                                defaultValue={amount[1] ?? ''}
                                id={`falls__in__input__${key}`}
                                name={`falls__in__input__${key}`}
                            />
                            <div
                                className="add__amount__button"
                                onClick={() => removeAmount(key)}
                            >
                                <Icon name={'minus-circle'}/>
                            </div>
                        </div>,
                    ])
                }

                <div className="form__item grid__12">
                    {(limitToOneAmount && amountRows.length === 0) || !limitToOneAmount ?
                        <span className="bottom__button" onClick={addNewAmount}>
                            Aantal toevoegen
                        </span>
                    : null}
                </div>
            </div>
        </>
    )
}
