import './Button.scss';
import { Link } from 'react-router-dom';

export default function Button({ children, type = 'button', ...props }) {
    if (!props.to) {
        return (
            <button type={type} {...props}>
                {children}
            </button>
        );
    } else {
        return <Link {...props}>{children}</Link>;
    }
}
