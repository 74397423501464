import {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';
import PageHeader from "../../../../Components/PageHeader/PageHeader";
import capitalize from "lodash/capitalize";
import {_PURCHASE_QUOTE_STATUS_OPTIONS} from "UI/App/Partials/Content/Purchases/Quotes/PurchaseQuotesOverview";
import {formatName} from "App/Util/format";
import Button from "UI/App/Components/Button/Button";
import {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import Icon from "UI/App/Components/Icon/Icon";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";

const statusOptions = [];
for (const status of _PURCHASE_QUOTE_STATUS_OPTIONS) {
    statusOptions.push(<option key={status} value={status}>{capitalize(status)}</option>);
}


export default function PurchaseQuote() {
    const {authAxios} = useContext(FetchContext);
    const params = useParams();

    const navigate = useNavigate();
    const [quoteData, setQuoteData] = useState(null);

    useEffect(() => {
        authAxios
            .get(`/calculations/purchases/quotes/${params.purchaseQuoteId}?populate=*`)
            .then(({data}) => {
                setQuoteData(data.data);
            })
            .catch((exception) => {
                console.error(exception);
            });
    }, []);

    const updateQuote = useCallback(
        (value) => {
            authAxios
                .put(`/calculations/purchases/quotes/${quoteData?.id}`, {
                    data: {
                        status: value.toLowerCase()
                    }
                })
                .then(() => {
                    setQuoteData((prev) => {
                        return {...prev, status: value.toLowerCase()};
                    });
                    toast.success(
                        <>
                            Status omgezet naar <i>{value}</i>
                        </>
                    );
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(`Er ging iets mis met het bewerken van de inkoopofferte.`);
                });
        },
        [authAxios, quoteData?.id]
    );

    async function deletePurchaseQuote() {
        // Get orderId before deleting
        const orderId = quoteData?.order?.id

        await authAxios.delete(`/calculations/purchases/quotes/${quoteData.id}`).then(({data}) => {

        }).then(() => {
            toast.info('Inkoop bon verwijderd.')
        }).catch((error) => {
            console.error(error);
            toast.error(`Er ging iets mis met het verwijderen van de inkoopofferte.`);
        });

        navigate(`/orders/${orderId}`)
    }

    return (
        <>
            <ConfirmationPopup
                popupID={"deletePurchaseQuoteConfirm"}
                title="Verwijderen"
                subject="Inkoopbon verwijderen"
                description={`Weet u zeker dat u inkoopbon ${quoteData?.number} wil verwijderen?`}
                functionOnConfirm={(e) => deletePurchaseQuote(e)}
            />
            <Grid columns='1' gap='1lh'>
                <PageHeader
                    color='white'
                    title={`Inkoopofferte: ${quoteData?.number}${
                        quoteData?.description !== null ? ` - ${quoteData?.description}` : ''
                    }`}
                    children={
                        <>
                            <Button className='btn btn--black btn--round' onClick={() => {
                                navigate(`/purchase/quotes/${quoteData.id}/edit`)
                            }}>
                                <Icon name='pencil'/>
                            </Button>
                            <Button className='btn btn--black btn--round' onClick={() => {
                                openPopup("deletePurchaseQuoteConfirm")
                            }}>
                                <Icon name='trash-can'/>
                            </Button>
                        </>
                    }
                />
                <Grid columns='2' customColTemplate='2fr 5fr'>
                    <Block name='quoteInfo' title='Informatie'>
                        <div>
                            <label htmlFor='status'>Status</label>
                            <select
                                name='status'
                                value={quoteData?.status}
                                onChange={({target}) => {
                                    updateQuote(target.value);
                                }}
                            >
                                {statusOptions}
                            </select>
                        </div>
                        <br/>
                        <Grid columns='2'>
                            <b>Order</b>
                            <Link to={`/orders/${quoteData?.order?.id}`}>{quoteData?.order?.number}</Link>
                            <b>Leverancier</b>
                            <Link to={`/crm/suppliers/${quoteData?.supplier?.id}`}>{quoteData?.supplier?.name}</Link>
                            <b>Contact persoon</b>
                            <Link to={`/crm/suppliers/${quoteData?.supplier?.id}/contactpersons/${quoteData?.contactPerson?.id}`}>{formatName(quoteData?.contactPerson?.firstName, quoteData?.contactPerson?.prefixToSurname, quoteData?.contactPerson?.surname)}</Link>
                        </Grid>
                    </Block>
                    <Block name='quotePDF' title='PDF'>
                        <Grid columns='1'>
                            <embed
                                style={{
                                    height: '800px',
                                    width: '100%'
                                }}
                                type='application/pdf'
                                src={quoteData?.base64 ? `data:application/pdf;base64,${quoteData?.base64}` : ''}
                            />
                        </Grid>
                    </Block>
                </Grid>
            </Grid>
        </>
    );
}
