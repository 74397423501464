import Popup, {closePopup} from "UI/App/Components/Popup/Popup";
import {SpinnerCenter} from "UI/App/Components/Spinner";
import {formatDate, formatDateForInput} from "App/Util/format";
import IF from "UI/App/Components/Conditional/IF";
import {Fragment, useContext, useEffect, useState} from "react";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";

export default function OrderTimerEditPopup({ timer, callback }) {
    const { authAxios } = useContext(FetchContext);

    const [endTimerDisabled, setEndTimerDisabled] = useState(true);

    const [stopTimer, setStopTimer] = useState(false);

    const [timerData, setTimerData] = useState(null);

    useEffect(() => {
        setTimerData(timer);
        if (!timer) return;

        setEndTimerDisabled(timer.end === null);
        setStopTimer(timer.end !== null);

        setTimerData({
            start: formatDateForInput(timer.start),
            end: timer.end ? formatDateForInput(timer.end) : '',
            breaks: timer.breaks.map(b => ({
                ...b,
                start: formatDateForInput(b.start),
                end: b.end ? formatDateForInput(b.end) : ''
            }))
        });
    }, [timer]);

    useEffect(() => {
        setEndTimerDisabled(!stopTimer);
    }, [stopTimer]);

    if (!timer || !timerData) {
        return (
            <Popup
                popupID='editTimerPopup'
                title='Timer bewerken'
            >
                <SpinnerCenter containerStyle={{ height: '340px' }} message={'Timer ophalen...'} />
            </Popup>
        )
    }

    function submit(e) {
        e.preventDefault();

        const data = {...timerData};
        if (!timer.end && !stopTimer) {
            delete data.end;
        }

        authAxios
            .put(`/orders/timers/${timer.id}/update`, data)
            .then(({ data }) => {
                closePopup();
                toast.success("Timer is opgeslagen");
                callback(data);
            })
            .catch((err) => {
                console.error(err);
                if (err.response?.data?.error?.message) {
                    toast.error(`Er is iets misgegaan bij het opslaan van de timer: ${err.response.data.error.message}`);
                } else {
                    toast.error("Er is iets misgegaan bij het opslaan van de timer")
                }
            });

    }

    function removeBreakRow(e, breakId) {
        e.preventDefault();

        setTimerData(timerData => {
            const timerBreakId = timerData.breaks.findIndex(b => b.id === breakId);

            return {
                ...timerData,
                breaks: [
                    ...timerData.breaks.slice(0, timerBreakId),
                    ...timerData.breaks.slice(timerBreakId + 1)
                ]
            }
        });
    }

    function onChange(e) {
        setTimerData(timerData => ({
            ...timerData,
            [e.target.name]: e.target.value
        }));
    }

    function onBreakChange(name, value, breakId) {
        setTimerData(timerData => ({
            ...timerData,
            breaks: timerData.breaks.map(b => {
                if (b.id !== breakId) return b;

                return {
                    ...b,
                    [name]: value
                }
            })
        }));
    }

    return (
        <Popup
            popupID='editTimerPopup'
            title='Timer bewerken'
        >
            <form onSubmit={submit}>
                <IF condition={timer.order && timer.order?.company}>
                    <div className={'timer-card'}>
                        <span className={'card-header'}><a href={`/orders/${timer.order?.id}`} target={'_blank'} rel="noreferrer"><strong>[{timer.order?.number}]</strong> - {timer.order?.description}</a></span>
                        <div className={'card-body'}>
                            <span><strong>{timer.user.username}</strong> - <a href={`/crm/companies/${timer.order?.company?.id}`} target={"_blank"} rel="noreferrer">{timer.order?.company?.name}</a></span>
                            <span>{formatDate(timer.start)} - {timer.end ? formatDate(timer.end) : "nu"}</span>
                        </div>
                    </div>
                </IF>

                <input
                    type="datetime-local"
                    name='start'
                    value={timerData.start}
                    onChange={onChange}
                />

                <IF condition={timer.end === null}>
                    <label>
                        <input type={'checkbox'} checked={stopTimer} onChange={(e) => setStopTimer(Boolean(e.target.checked))} />
                        <span>Timer stoppen</span>
                    </label>
                </IF>
                <input
                    type="datetime-local"
                    name='end'
                    disabled={endTimerDisabled}
                    value={timerData.end}
                    onChange={onChange}
                />

                <h3 style={{ fontWeight: 600, margin: '10px 0' }}>Pauzes</h3>

                <IF condition={timer.breaks.length === 0}>
                    <i style={{ color: 'gray', marginBottom: '5px', display: 'block' }}>Geen pauzes</i>
                </IF>

                <Grid
                    columns={3}
                    customColTemplate={'1fr 1fr 30px'}
                    gap={'10px'}
                    style={{
                        alignItems: 'center',
                    }}
                >
                    {timerData.breaks.map(br =>
                        <Fragment>
                            <input type="datetime-local" value={br.start} onChange={(e) => onBreakChange('start', e.currentTarget.value, br.id)} />
                            <input type="datetime-local" value={br.end} onChange={(e) => onBreakChange('end', e.currentTarget.value, br.id)} />
                            <button className={'btn btn--transparent btn--icon-red'} onClick={(e) => removeBreakRow(e, br.id)}><Icon name={'times'} /></button>
                        </Fragment>
                    )}
                </Grid>

                <button><Icon name={'save'} /> Opslaan</button>
            </form>
        </Popup>
    )
}