import './editConfirmationPopup.scss';

import Popup, {closePopup} from "UI/App/Components/Popup/Popup";
import Button from "UI/App/Components/Button/Button";
import { useNavigate } from 'react-router-dom';

export default function EditConfirmationPopup({calculationId}) {
    const navigate = useNavigate();

    return (
        <Popup
            popupID='editConfirmationMessage'
            title='Let op!'
        >
            <div className='confirmation__message__text'>
                <span>Wanneer je deze calculatie bewerkt, zal het systeem automatisch de berekeningen opnieuw uitvoeren, wat kan resulteren in gewijzigde kost- en offerteprijzen.</span>
                <br />
                <span>Weet u zeker dat u deze calculatie wilt bewerken?</span>
            </div>
            <div className="confirmation__message__buttons">
                <Button 
                    onClick={() => closePopup('editConfirmationMessage')}>
                    Annuleren
                </Button>
                <Button 
                    className='btn--white'
                    onClick={() => {closePopup('editConfirmationMessage'); navigate(`/calculations/${calculationId}/edit`)}}>
                    Doorgaan
                </Button>
            </div>
        </Popup>
    )
}
