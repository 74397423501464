import Button from "UI/App/Components/Button/Button";
import Block from "UI/App/Components/Block/Block";
import Table from "UI/App/Components/Table/Table";
import Icon from "UI/App/Components/Icon/Icon";

export default function CompanyPersonsList({data = [], title, name, path, icon,structure}) {
	return (
		<Block name={name} title={title} headerRightSideChildren={<Button to={path} className='btn btn--round btn--black'><Icon name={icon} /></Button>}>
			<Table
				data={data}
				structure={structure}
				border='row'
				hover
			/>
		</Block>
	)
}
