import {openPopup} from "UI/App/Components/Popup/Popup";
import {FetchContext} from "App/Strapi/FetchContext";
import Button from "UI/App/Components/Button/Button";
import Icon from "UI/App/Components/Icon/Icon";
import {toast} from 'react-toastify';
import {useContext, useState} from "react";
import {AuthContext} from 'App/Strapi/AuthProvider';
import HasPermission from "UI/App/Components/Auth/HasPermission";
import { downloadFile as downloadFileUtill} from 'App/Util/fetch';

export default function CompanyPersonInfoMessage({ getNotes, message, messageKey, companyID, setNotes, setEditFormData, setMemoFormData, setImgUrl, setCollapseTags, collapseTags }) {
    const { authAxios } = useContext(FetchContext);
    const { authState } = useContext(AuthContext);

    // read more
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => { setIsReadMore(!isReadMore) };

    const allowedExtensions = [".jpeg", ".jpg", ".gif", ".png", ".apng", ".svg", ".bmp", ".webp", ".webm", ".pdf", ".mp4"];

    function copyMessage() {
        // copy to clipboard
        navigator.clipboard.writeText(message?.content).then(
            () => {
                // copy success message
                toast.success('Bericht gekopieërd naar klembord.');
            },
            () => {
                // copy error message
                toast.error('Bericht kon niet automatisch gekopieërd worden! Probeer het handmatig te kopiëren.');

                // select text for the user
                const range = document.createRange();
                range.selectNode(document.getElementById(`chat-message-${message?.id}-text`));
                window.getSelection().addRange(range);
            }
        );
    }

    async function openAddMemoPopup() {    
        if (message?.attachment) {
            // set memo popup form data. also resets the other fields to default values.
            setMemoFormData({
                linkedItem: message?.id
                    ? [
                          {
                              __component: 'memo.message-link',
                              note: message.id
                          }
                      ]
                    : undefined,
                attachment: [{
                    id: message.attachment?.id,
                    blobURL: await downloadFileUtill(authAxios, message.attachment?.id, message.attachment.mime),
                    mime: message.attachment?.mime ?? null,
                    newFile: null,
                    canEdit: false
                }]
            });
        } else {
            // set memo popup form data. also resets the other fields to default values.
            setMemoFormData({
                content: message?.content ?? undefined,
                linkedItem: message?.id
                    ? [
                          {
                              __component: 'memo.message-link',
                              note: message.id
                          }
                      ]
                    : undefined
            });
        }
    
        // open popup
        openPopup('add-or-edit-memo');
    }        

    function openEditPopup() {
        // open popup
        openPopup('edit-chat-message');

        console.log(message);
        // set input values to state
        setEditFormData({
            'edit-chat-message-id': message?.id,
            'edit-chat-message-key': messageKey,
            'edit-chat-user-id': message?.user?.id,
            'edit-chat-company-id': companyID,
            'edit-chat-message-text': message?.content,
            'tags': message?.tags
        });

    }
    function popupFile(data) {
        authAxios.get("../download/files/" + data.id,
            {
                responseType: 'arraybuffer',
            })
            .then((response) => {
                let url;
                if (data.ext === '.pdf') {
                    url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    window.open(url);
                    return;
                }

                if (data.ext === '.mp4') {
                    url = window.URL.createObjectURL(new Blob([response.data], {type: 'video/mp4'}));
                    window.open(url);
                    return;
                }

                if (data.ext === '.svg') {
                    url = window.URL.createObjectURL(new Blob([response.data], {type: 'image/svg+xml'}));
                } else {
                    url = window.URL.createObjectURL(new Blob([response.data]));
                }

                setImgUrl(url);
                openPopup('inspect-image');
            })
            .catch((error) => {
                toast.error("Het bestand kan niet bekeken worden");

                process.env.NODE_ENV === 'development' && console.error(error);
            });
    }

    function downloadFile(file) {
        authAxios.get("../download/files/" + file.id, {
            responseType: 'arraybuffer',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.hidden = true;
            link.setAttribute('download', (file?.name) ?? (Date.now().toString() + file.ext));
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            toast.error("Het bestand kan niet gedownload worden");

            process.env.NODE_ENV === 'development' && console.error(error);
        });
    }

    function deleteMessage() {
        if (window.confirm("Weet u zeker dat u deze informatie wilt verwijderen?")) {

            if (message.attachment !== null) {
                authAxios.delete(`../upload/files/${message.attachment.id}`).then(() => {
                }).catch((exception) => {
                    toast.error('Bijlage kon niet verwijderd worden!');
                    console.error(exception);
                });
            }

            // delete the message
            authAxios.delete(`/crm/notes/${message.id}`).then(() => {
                toast.success('Informatie is verwijderd!');
                getNotes();
            }).catch((exception) => {
                toast.error('Informatie kon niet verwijderd worden!');
                console.error(exception);
            });
        }
    }
    function previewTags() {
        // return if there are no tags
        if (message?.tags === undefined) {
            return;
        }

        const tagsData = message.tags.sort((a, b) => b.name.length - a.name.length);

        // create an array for the tag elements
        let tags = [];
        for (let tag of tagsData) {
            tags.push(<span key={tag.id} className={'message__tag__preview'} style={{ backgroundColor: tag.backgroundColor, color: tag.color }} content={tag.name}></span>);
        }
        // return with parent element
        return tags;
    }

    return (
        <div key={`crm--message-${message?.id}-text`} id={`crm--message-${message?.id}-text`} className='company__message'>
            <div className={'company__message__tags'} data-collapsed={collapseTags} onClick={() => setCollapseTags(prev => !prev)}>
                {previewTags()}
            </div>
            <div className={'company__message__content'}>
                <span className="company__message-header">
                    <span className="company__message-header-content">
                        <strong>{message?.user?.username ? message?.user?.username : 'Onbekende gebruiker'}</strong>
                        <span className={'company__message-header-content-date'}>{new Date(message?.updatedAt).toLocaleString('nl-NL', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })} {message?.updated && <small>(Bijgewerkt)</small>}</span>
                    </span>
                    <span className="company__message-actions">
                        {/* buttons for attachments */}
                        {message?.attachment && (
                            <>
                                {allowedExtensions.includes(message?.attachment?.ext) && (
                                    <Button
                                        onClick={() => popupFile(message?.attachment)}
                                        className='btn--round btn--black'
                                    >
                                        <Icon name='inspect' />
                                    </Button>
                                )}
                                <Button onClick={() => openAddMemoPopup()} className='btn--round btn--black'>
                                    <Icon name='bell' />
                                </Button>
                                <Button
                                    onClick={() => downloadFile(message?.attachment)}
                                    className='btn--round btn--black'
                                >
                                    <Icon name='download' />
                                </Button>
                            </>
                        )}
                        
                        {/* buttons for normal messages */}
                        {!message?.attachment && (
                            <>
                                <Button onClick={() => copyMessage()} className='btn--round btn--black'>
                                    <Icon name='copy' />
                                </Button>
                                <Button onClick={() => openAddMemoPopup()} className='btn--round btn--black'>
                                    <Icon name='bell' />
                                </Button>
                                <HasPermission allowedIfOwner={true} ownerId={message.user?.id} roles={['admin']}>
                                    <Button onClick={() => openEditPopup()} className='btn--round btn--black'>
                                        <Icon name='pencil' />
                                    </Button>
                                </HasPermission>
                            </>
                        )}
                        <HasPermission allowedIfOwner={true} ownerId={message.user?.id} roles={['admin']}>
                            <Button onClick={() => deleteMessage()} className='btn--round btn--black'>
                                <Icon name='trash-can' />
                            </Button>
                        </HasPermission>
                    </span>
                </span>
                <span className="company__message-text" id={`chat-message-${message?.id}-text`}>
                    {message?.attachment !== null ? 'Bestand: ' + message?.content : message?.content}
                </span>
            </div>
        </div>
    );
}
