import Block from "UI/App/Components/Block/Block";
import MaterialStockHistoryItem from "UI/App/Partials/Content/Calculations/Catalog/MaterialStockHistory/MaterialStockHistoryItem";
import './_materialStockHistory.scss';
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import {Spinner} from "UI/App/Components/Spinner";
import IF from "UI/App/Components/Conditional/IF";

export default function MaterialStockHistory({
    isLoading,
    historyData,
    getNextHistoryRecords
}) {

    return (
        <Block name='material-stock-history' className='material-stock-history' title='Geschiedenis'>
            <Grid columns={1} style={{
                maxHeight: '380px',
                overflowY: 'auto',
                paddingRight: '10px',
                minHeight: '20px'
            }}>
                {historyData.map((historyItem) => <MaterialStockHistoryItem item={historyItem} key={historyItem.id}/>)}
                <IF condition={!isLoading && historyData?.length === 0}>
                    <p style={{
                        textAlign: 'center',
                        color: 'gray'
                    }}>Geen geschiedenis gevonden</p>
                </IF>
            </Grid>

            <IF condition={historyData.length >= 10}>
                <div className='material-stock-history__footer'>
                    <button
                        onClick={getNextHistoryRecords}
                        className={'btn btn--transparent'}
                    >
                        Meer laden
                        {isLoading ? <Spinner white={true} width={15} height={15} style={{filter: "invert(0.4)"}} /> : <Icon name='angle-down' /> }
                    </button>
                </div>
            </IF>
        </Block>
    )
}
