import {useEffect, useState} from "react";
import CalculationResultFactory from "UI/App/Partials/Content/Calculations/Calculations/result/CalculationResultFactory";
import Table from "UI/App/Components/Table/Table";
import {SpinnerCenter} from "UI/App/Components/Spinner";
import Grid from "UI/App/Components/Grid/Grid";

export default function SimpleCalculationResult({
    data,
    loading,
}) {
    const [machineResultTableData, setMachineResultTableData] = useState(null);
    const [manualLaborResultTableData, setManualLaborResultTableData] = useState(null);
    const [materialTableData, setMaterialTableData] = useState(null);
    const [thirdPartyTableData, setThirdPartyTableData] = useState(null);
    const [informationTableData, setInformationTableData] = useState(null);

    useEffect(() => {
        if (!data || !data.templateData) return;

        const calcResultFactory = new CalculationResultFactory(data);
        const machineResultTableData = calcResultFactory.getSimpleMachineResultTableData();
        const manualLaborTableData = calcResultFactory.getSimpleManualLaborTableData();
        const materialTableData = calcResultFactory.getSimpleMaterialTableData();
        const thirdPartyTableData = calcResultFactory.getSimpleThirdPartyTableData();
        const informationTableData = calcResultFactory.getSimpleInformationTableData();

        setMachineResultTableData(machineResultTableData);
        setManualLaborResultTableData(manualLaborTableData);
        setMaterialTableData(materialTableData);
        setThirdPartyTableData(thirdPartyTableData);
        setInformationTableData(informationTableData);
    }, [data]);

    if (!machineResultTableData || loading) return <SpinnerCenter />;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginBottom: '20px'
        }}>
            <h3 className="result__table__header">Machinekosten</h3>
            <Grid columns={2} gap={'0'} className={"simple-calculation-result"} customColTemplate={{
                1700: '300px 1fr',
                default: '400px 1fr'
            }} style={{
                borderBottom: 'solid 1px #D0D0D0'
            }}>
                <Table
                    headers={['Machine', '']}
                    data={machineResultTableData.rowHeaders}
                    structure={['100%', '100px']}
                    hover={false}
                />

                <div style={{
                    overflowX: 'auto'
                }}>
                    <Table
                        headers={machineResultTableData.headers}
                        data={machineResultTableData.data}
                        structure={machineResultTableData.structure}
                        hover={false}
                        overflowText={false}
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    />
                </div>
            </Grid>

            {manualLaborResultTableData.data?.length > 0 && (
                <>
                    <h3 className="result__table__header">Handwerk</h3>
                    <Grid columns={2} gap={'0'} className={"simple-calculation-result"} customColTemplate={{
                        1700: '300px 1fr',
                        default: '400px 1fr'
                    }} style={{
                        borderBottom: 'solid 1px #D0D0D0'
                    }}>
                        <Table
                            headers={['Arbeid']}
                            data={manualLaborResultTableData.rowHeaders}
                            structure={['100%']}
                            hover={false}
                        />

                        <div style={{
                            overflowX: 'auto'
                        }}>
                            <Table
                                headers={manualLaborResultTableData.headers}
                                data={manualLaborResultTableData.data}
                                structure={manualLaborResultTableData.structure}
                                hover={false}
                                overflowText={false}
                                style={{
                                    whiteSpace: 'nowrap'
                                }}
                            />
                        </div>
                    </Grid>
                </>
            )}

            {materialTableData.data?.length > 0 && (
                <>
                    <h3 className="result__table__header">Materiaalkosten</h3>
                    <Grid columns={2} gap={'0'} className={"simple-calculation-result"} customColTemplate={{
                        1700: '400px 1fr',
                        default: '500px 1fr'
                    }} style={{
                        borderBottom: 'solid 1px #D0D0D0'
                    }}>
                        <Table
                            headers={['Materiaal', 'Eenheid']}
                            data={materialTableData.rowHeaders}
                            structure={['100%', '100px']}
                            hover={false}
                        />

                        <div style={{
                            overflowX: 'auto'
                        }}>
                            <Table
                                headers={materialTableData.headers}
                                data={materialTableData.data}
                                structure={materialTableData.structure}
                                hover={false}
                                overflowText={false}
                                style={{
                                    whiteSpace: 'nowrap'
                                }}
                            />
                        </div>
                    </Grid>
                </>
            )}

            {thirdPartyTableData.data?.length > 0 && (
                <>
                    <h3 className="result__table__header">Werk derden</h3>
                    <Grid columns={2} gap={'0'} className={"simple-calculation-result"} customColTemplate={{
                        1700: '400px 1fr',
                        default: '500px 1fr'
                    }} style={{
                        borderBottom: 'solid 1px #D0D0D0'
                    }}>
                        <Table
                            headers={['Werk derden']}
                            data={thirdPartyTableData.rowHeaders}
                            structure={['100%']}
                            hover={false}
                        />

                        <div style={{
                            overflowX: 'auto'
                        }}>
                            <Table
                                headers={thirdPartyTableData.headers}
                                data={thirdPartyTableData.data}
                                structure={thirdPartyTableData.structure}
                                hover={false}
                                overflowText={false}
                                style={{
                                    whiteSpace: 'nowrap'
                                }}
                            />
                        </div>
                    </Grid>
                </>
            )}

            {informationTableData.data?.length > 0 && (
                <>
                    <h3 className="result__table__header">Informatie</h3>
                    <Grid columns={2} gap={'0'} className={"simple-calculation-result"} customColTemplate={{
                        1700: '300px 1fr',
                        default: '400px 1fr'
                    }} style={{
                        borderBottom: 'solid 1px #D0D0D0'
                    }}>
                        <Table
                            headers={['Informatie']}
                            data={informationTableData.rowHeaders}
                            structure={['100%']}
                            hover={false}
                        />

                        <div style={{
                            overflowX: 'auto'
                        }}>
                            <Table
                                headers={informationTableData.headers}
                                data={informationTableData.data}
                                structure={informationTableData.structure}
                                hover={false}
                                overflowText={false}
                                style={{
                                    whiteSpace: 'nowrap'
                                }}
                            />
                        </div>
                    </Grid>
                </>
            )}
        </div>
    )
}