import {FetchContext} from "../../../../../../App/Strapi/FetchContext";
import WidgetFormRenderer from "./Widgets/WidgetFormRenderer";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import WidgetCollection from "./Widgets/WidgetCollection";
import './widget.scss';
import Popup, {openPopup} from "UI/App/Components/Popup/Popup";
import Block from "UI/App/Components/Block/Block";
import Grid from "UI/App/Components/Grid/Grid";
import {toast} from "react-toastify";

export default function CreateFlow() {
    const fetchContext = useContext(FetchContext);

    const params = useParams();
    const navigate = useNavigate();
    const [popupContentCurrentIndex, setPopupContentCurrentIndex] = useState(0);
    const [popupContent, setPopupContent] = useState([]);
    const [editPopupContent, setEditPopupContent] = useState(null);
    const [widgetArray, setWidgetArray] = useState([]);
    const [popupTitle, setPopupTitle] = useState('');
    const [constants, setConstants] = useState([]);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '500px',
            height: '500px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    // get the machine id from the params
    const machineId = params?.machineId;

    /**
     * Opens widget container popup
     */
    const openAddWidgetPopup = () => {
        /**
         * Set popup content at index 0 with all widgets
         */
        setPopupContent([<div className="popup__content__flex widgets">{new WidgetCollection("preview", selectWidget, storeWidget).getWidgets()}</div>]);
        setPopupTitle('Voeg een widget toe');
        openPopup('add', true, true, setPopupContentCurrentIndex);
    }

    const storeWidgetFlow = () => {
        let widgetStorage = {
            'widgets': [],
            'constants': []
        };

        // for (const widget of document.getElementById('widget--container').children) {
        //     widgetStorage.widgets.push({id: widget.id, data: JSON.parse(widget.getAttribute("widgetdata"))});
        // }
        //
        for (const constant of document.getElementsByClassName('constant')) {
            widgetStorage.constants.push({id: constant.id, data: {name: constant.getAttribute('constname'), data: constant.getAttribute('value')}});
        }

        console.log(widgetStorage);
        const data = {
            name: document.getElementById('flow_name').value,
            widgetData: widgetStorage,
            machine: machineId,
            templateType: machineId ? "MachineCalculationFlow" : "WidgetCollectionFlow"
        }

        fetchContext.authAxios.post(`/calculations/resources/templates`, {data}).then(({data}) => {
            toast.success(`Widget flow opgeslagen!`);
            // data.data.id
             navigate(`/calculations/widgets/${data.data.id}/edit`)
            // <Navigate to={""}
        }).catch((exception) => {
            console.log(exception);
        });
    }

    const storeConst = (event) => {
        event.preventDefault()
        let val = document.getElementsByClassName("constant").length
        const key = (parseInt(val)) + 1;
        setConstants(prev => [...prev, <div key={key}><label htmlFor={"const-" + key}>{event.target.constname.value}</label><input widgettype="const" id={"const-" + key} constname={key} className="constant" type={event.target.consttype.value} value={event.target.constvalue.value} required readOnly={true} /></div>])
    }

    const addConst = () => {
        /**
         * Set popup content at index 0 with all widgets
         */
        setPopupContent([
            <form onSubmit={storeConst}>
                <div className="input-group">
                    <label htmlFor="constname">Naam:</label>
                    <input type="text" id="constname" name="constname" />
                </div>
                <div className="input-group">
                    <label htmlFor="consttype">Type:</label>
                    <select name="consttype" id="consttype">
                        <option value="number">Getal</option>
                        <option value="text">Tekst</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="constvalue">Waarde: </label>
                    <input type="text" id="constvalue" name="constvalue" />
                </div>
                <button className="btn">Toevoegen</button>
            </form>
        ]);
        setPopupTitle('Voeg een vaste waarde toe');
        openPopup('add', true, true, setPopupContentCurrentIndex);
    }

    /**
     * Stores widget to widgetsarray
     * @param data
     */
    const storeWidget = (data) => {
        console.log('store');
        console.log(data);
    }

    /**
     * select widget to store
     */
    const selectWidget = (event) => {
        setPopupContent((prev) => [prev[0], new WidgetCollection("form", selectWidget, storeWidget).getWidget(event.widgetType)]);
        setPopupContentCurrentIndex(1);
    }

    useEffect(() => {
        /**
         * Empty widget array
         */
        setWidgetArray([]);

        /**
         * Set widget array
         */
        setWidgetArray(prevValue => [...prevValue, new WidgetCollection("badge", selectWidget, storeWidget).getWidgets()])

        /**
         * Add const beginning
         */
        setConstants([]);

        /**
         * Fetch machines
         */
        if (machineId) {
            fetchContext.authAxios.get(`/calculations/resources/machines/${machineId}`, {
                params: {
                    populate: "*"
                }
            }).then(({data}) => {
                const _constants = [];
                for (const [key, value] of Object.entries(data)) {
                    _constants.push(<div key={key} className='input-group'>
                        <label htmlFor={"const-" + key}>{key}</label><input widgettype="const" id={"const-" + key} constname={key} className="constant" type="text" value={value} required readOnly={true} />
                    </div>);
                }
                setConstants(_constants);
            }).catch((exception) => {
                console.log(exception);
            });
        }
    }, [])

    /**
     * Runs everything widgetarray or widgetFunctions is updated
     */
    useEffect(() => {
        /**
         * Loops and run widget functions
         */
        // calculateWidgets()
    }, [widgetArray])

    /**
     * Called everytime something is added to widgetConatiner
     */
    // function calculateWidgets() {
    //     console.log('kih');
    // }

    function openPreview() {
        setPopupContent([
            <>
                <span>{(new WidgetFormRenderer()).renderFormula("flow_widget")}</span>
                <hr />
                {(new WidgetFormRenderer()).renderPreviewForm("flow_widget")}
            </>
        ]);
        setPopupTitle('Voorbeeld');
        openPopup('add', true, true, setPopupContentCurrentIndex);
    }

    window.openEditWindow = (element) => {
        // console.log('kih');
        // const widgetData = JSON.parse(element.parentElement.getAttribute('widgetdata'))
        // widgetData.id = element.parentElement.getAttribute('id')
        const parent = element.closest('.flow_widget');
        const widgetData = JSON.parse(parent.getAttribute('widgetdata'));
        widgetData.id = parent.getAttribute('id');

        // console.log(new WidgetCollection("editForm", selectWidget, storeWidget).getWidget(widgetData.type, widgetData));

        setEditPopupContent(new WidgetCollection("editForm", selectWidget, storeWidget).getWidget(widgetData.type, widgetData));
        openPopup('edit');
    }

    return (
        <>
            <Block name='collection__name' useSmallerPadding>
                Naam: <input type="text" id="flow_name" />
            </Block>
            <Grid columns='2' customColTemplate='1fr 3fr'>
                <Block name='collection__consts' title='CONST' headerRightSideChildren={<button onClick={addConst}>+</button>}>
                    <div id="const--storage">
                        {constants}
                    </div>
                </Block>

                <div>
                    <button onClick={storeWidgetFlow} className='btn--margin-l'>Opslaan</button>
                </div>
            </Grid>

            <Popup popupID='add' title={popupTitle} contentIndex={popupContentCurrentIndex} backButtonIndexSetter={setPopupContentCurrentIndex}>
                {popupContent[popupContentCurrentIndex]}
            </Popup>
            <Popup popupID='edit' title={"edit"}>
                {editPopupContent}
            </Popup>
        </>
    )
}
