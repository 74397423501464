import countries from ".//countries.json";

export async function getCountriesList() {
    const result: {countries: any[], countryPhoneCodes: any[]} = {
        countries: [],
        countryPhoneCodes: []
    }

    result.countries = countries.map((country: any) => ({value: country.name.common,text: country.name.common})).sort((r1: any, r2: any) => (r1.value > r2.value) ? 1 : -1);
    result.countryPhoneCodes = mapCountryNumbers(countries);
    result.countries.unshift({value: '', text: 'Kies land...'});
    result.countryPhoneCodes.unshift({value: '', text: 'Kies landcode...'});

    return result;
}

function mapCountryNumbers(countries: any[]): any[] {
    const returnArr: any[] = [];

    const excludedCountries = [
        'united states',
        'canada'
    ];

    // set all country codes to their own array.
    countries.forEach(country => {

        if (excludedCountries.findIndex((excludedCountry) => country.name.common?.toLowerCase().includes(excludedCountry)) !== -1) {
            return;
        }

        if(country.idd.root === undefined ){
            return;
        }

        if(country.idd.suffixes !== undefined) {
            country.idd.suffixes.forEach((suffix: string) => {
                returnArr.push({value: (country.idd.root + suffix).slice(1), text: country.name.common + ' ' + country.idd.root + suffix});
            });
        } else {
            returnArr.push({value: country.idd.root.slice(1), text: country.name.common + ' ' + country.idd.root});
        }

    });

    returnArr.unshift({value: '1', text: 'United States / Canada +1'});

    return returnArr.filter((e,i,a) => i === a.indexOf(e)).sort((a, b) => {
        return a.text > b.text ? 1 : -1;
    });
}

export function updateOldCountryName(country?: string): string|undefined {
    if (typeof country !== 'string') {
        return country;
    }

    const countryDict = {
        'Netherlands': ['nederland', 'the netherlands'],
        'United Kingdom': ['england'],
        'Germany': ['duitsland'],
        'Ireland': ['ierland'],
        'Poland': ['polen']
    }

    for (const [key, value] of Object.entries(countryDict)) {
        if (value.includes(country.toLowerCase())) {
            return key;
        }
    }

    return country;
}

export function getCountriesFlags() {
    return countries.map((country) => ({
        value: country?.altSpellings?.[0].toLowerCase(),
        flag: country?.flags?.[0],
        emoji: country.flag
    }))
}
