import { useEffect, useState } from 'react';

export default function PermissionGroup({ name: permissionName, controllers }) {
	const [checkedState, setCheckedState] = useState({});
	useEffect(() => {
		const values = {};
		for (const group of Object.keys(controllers)) {
			for (const name of Object.keys(controllers[group])) {
				const value = controllers[group][name]?.enabled ?? false;
				values[name] = { current: value, initial: value };
			}
		}
		setCheckedState(values);
	}, [controllers]);

	return Object.keys(controllers).map((group, key) => {
		return (<div className='column' key={key}>
			<strong>{group}:</strong>
			<div className={`permission__group ${group}`}>

				{Object.keys(controllers[group]).map((name, key) => {
					// do not render an input if a value isn't found in the state (i.e on first render when values haven't been retrieved yet)
					if (typeof checkedState[name] === 'undefined') {
						return '';
					}

					return <div key={key} className='permission__option'>
						<label htmlFor={`${group}__${name}`}>
							<input type='checkbox' data-permission={permissionName} data-group={group} data-name={name} name={`${group}__${name}`} id={`${group}__${name}`}
								checked={checkedState[name].current} onChange={({ target }) => {
									setCheckedState((prev) => ({
										...prev,
										[name]: { current: target.checked, initial: prev[name].initial }
									}));
								}} />
							{name}
						</label>
						{checkedState[name].current !== checkedState[name].initial && <span style={{ fontSize: 'smaller', color: '#757575', marginLeft: '5px' }}>verandering nog niet opgeslagen</span>}
					</div>
				})}
			</div>
		</div>
		);
	});
}
