import { FetchContext } from "App/Strapi/FetchContext";
import { useContext, useEffect, useState, useRef } from "react";
//import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "App/Strapi/AuthProvider"
import { toast } from "react-toastify";
import Block from "../../../Components/Block/Block";
import Grid from "../../../Components/Grid/Grid";
import Popup, { closePopup, openPopup } from "UI/App/Components/Popup/Popup";
import TimeInput from "./Blocks/TimeAccountingCreateEdit/TimeAccountingInput";
import TimeDayTable from "./Blocks/TimeAccountingCreateEdit/TimeAccountingDayTable";
import TimeWeekTable from "./Blocks/TimeAccountingCreateEdit/TimeAccountingWeekTable";
import TimeMonthTable from "./Blocks/TimeAccountingCreateEdit/TimeAccountingMonthTable";
import './timeAccounting.scss';
import '../../../Components/Button/Button.scss';
import PopupAddTimer from "UI/App/Components/Popup/Popups/AddTimer";


export default function TimeAcc() {
	const { authAxios } = useContext(FetchContext);
	const authContext = useContext(AuthContext);

	const [forceUpdate, setForceUpdate] = useState(0);




	//
	const [daySelector, setDaySelector] = useState({ selectedOption: "dag" });
	const [blocks, setBlock] = useState([]);




	let minutesSinceUpdate = 0;
	// first load
	useEffect(() => {
		agendaType();
		setMainBlock();

	}, []);
	function setMainBlock() {
		let elements = [];
		elements.push(<Grid columns={1}  ><Block name={"buttons"}>{daySelector.dayButtons}</Block></Grid>)

		if (daySelector?.selectedOption === "dag" || daySelector?.selectedOption === undefined) {
			elements.push(<Grid columns={2} className="TimeAccouting__grid" >
				<TimeInput aquireOngoing={true} onTimer={forceTableUpdate} />
				<TimeDayTable forcedUpdate={forceUpdate} />

			</Grid>)
		}
		if (daySelector?.selectedOption === "week") {
			elements.push(<TimeWeekTable />)
		}
		if (daySelector?.selectedOption === "maand") {
			elements.push(<TimeMonthTable />)
		}
		elements.push(<PopupAddTimer setTime={false} setUpdateBlocks={reAquireTable} />)
		setBlock(elements)
	}
	function forceTableUpdate() {
		console.log("timer created")
		setForceUpdate(forceUpdate + 1);
	}
	function reAquireTable() {

	}
	function agendaTypePressed(e) {
		e.preventDefault();


		if (e.target.name.split("_")[1] === "add") {
			openPopup("add-timer")
			return;
		}
		let updateDaySelector = daySelector;
		updateDaySelector.selectedOption = e.target.name.split("_")[1];
		setDaySelector(updateDaySelector)
		agendaType();
		setMainBlock();
	}
	function agendaType() {
		let elements = [];
		let updateBlock = daySelector;

		let options = ["Dag", "Week", "Maand"];
		elements.push(<button name={"timer_add"} onClick={agendaTypePressed} className="TimeAccouting__tab_Button">{"Nieuwe Gebeurtenis"}</button>);
		for (let index = 0; index < options.length; index++) {
			//console.log(daySelector?.selectedOption, options[index].toLowerCase())
			if (daySelector?.selectedOption === options[index].toLowerCase()) {
				elements.push(<button value={index} name={"timer_" + options[index].toLowerCase()} onClick={agendaTypePressed} disabled className="TimeAccouting__tab">{options[index]}</button>);
			} else {
				elements.push(<button value={index} name={"timer_" + options[index].toLowerCase()} onClick={agendaTypePressed} className="TimeAccouting__tab">{options[index]}</button>);
			}
		}
		//elements.push(<button name={"timer_reload"} onClick={(e) => { setMainBlock() }} className="TimeAccouting__tab_Button">{"Herlaad"}</button>);
		if (updateBlock?.dayButtons === undefined) {
			updateBlock.dayButtons = [];
		}
		elements.push(<small>ISO 8601 agenda</small>)
		updateBlock.dayButtons = elements;
		setDaySelector(updateBlock)
	}


	return (
		<>
			{blocks}
			{/* <Grid columns={1}  ><Block>{daySelector.dayButtons}</Block></Grid>
			<Grid columns={2} className="TimeAccouting__grid" >
				<TimeInput aquireOngoing={true} onTimer={forceTableUpdate} />
				<TimeDayTable forcedUpdate={forceUpdate} />

			</Grid>
			<TimeWeekTable /> */}
		</>
	)


}
