import './Table.scss';
import Icon from 'UI/App/Components/Icon/Icon';
import useWindowDimensions from "App/Util/useWindowDimensions";
import {useEffect, useState} from "react";
import IF from "UI/App/Components/Conditional/IF";
import {getPropByPageWidth} from "App/Util/layout";
import { Spinner } from 'UI/App/Components/Spinner';

/**
 * Render a table.
 *
 * @export default
 * @param {object} headers array of items to use as table header
 * @param {boolean} useFirstDataItemAsHeaders set to use the first array index of the table data to define the table headers.
 * @param {boolean} stickyHeader set to make the table header sticky.
 * @param {boolean} transparentHeader set to not use the primary color for the `<thead>`.
 * @param {object} data array of objects/arrays for table data.
 * @param {object} tableColumns object with table column settings.
 * @param {function} setTableColumns function to set the table column settings.
 * @param {object} footers array of items to use as table footer.
 * @param {string} caption table caption.
 * @param {string} layout `fixed`/`auto`. Default = `fixed`
 * @param {object} structure set the width of each column.
 * @param {string} striped set to `odd`/`even` to set a striped color for table rows.
 * @param {string} border set to `row`/`col`/`both` to define the table borders.
 * @param {boolean} hover set to enable a hover background change on table rows.
 * @param {boolean} overflowText set to enable text overflowing with ellipsis (...).
 * @param {number[]} columnOverflowExceptions set the column indexes that should be allowed to overflow. Mainly for buttons in the last column with a fixed width and a title.
 * @param {string} className set extra classes on the table.
 * @param {object} style set extra styles on the table.
 * @param {boolean} scroll set to enable a scroll on the table.
 * @param {string} maxHeight set the max height of the table.
 * @param {string|false} customPadding set the padding of the table cells.
 * @param {'m'|'l'|'s'} padding set the padding of the table cells.
 * @param {number|false} paddingBreakpoint set the breakpoint for the custom padding.
 * @param {number|false} buttonCollapseBreakpoint set the breakpoint for collapsing the buttons.
 * @param {object} wrapperStyle set extra styles on the table wrapper.
 * @return {JSX.Element}
 */
export default function Table({
    headers,
    useFirstDataItemAsHeaders,
    stickyHeader,
    transparentHeader,
    data,
    tableColumns,
    setTableColumns,
    footers,
    caption,
    layout,
    structure,
    striped,
    border = 'row',
    hover = true,
    overflowText = true,
    columnOverflowExceptions = [],
    columnTranslationExceptions = [],
    className,
    style = {},
    scroll = false,
    maxHeight = '400px',
    customPadding = false,
    padding = 'm',
    paddingBreakpoint = false,
    buttonCollapseBreakpoint = false,
    wrapperStyle = {},
    isDataLoading = false
}) {
    /*
    to do:
        - sticky header
    */
    const { width } = useWindowDimensions();
    const [tableStructure, setTableStructure] = useState([]);

    useEffect(() => {
        setTableStructure(getPropByPageWidth(structure, width));
    }, [structure, width]);

    const handleSortingChange = (currentColumn) => {
        // hide all open sub rows before sorting
        const visibleSubRows = document.querySelectorAll('.sub-row:not([hidden])');
        visibleSubRows.forEach((subRow) => (subRow.hidden = true));

        // getting the the database field name based on the table column name
        const fields = Object.values(tableColumns.fields);
        const dbColumnName = fields.find((field) => field.name === currentColumn).field;

        setTableColumns({
            ...tableColumns,
            sort: {
                column: dbColumnName,
                currentColumn: currentColumn,
                direction: tableColumns.sort.direction === 'desc' ? 'asc' : 'desc'
            }
        });
    };

    return (
        <div
            className='table__wrapper'
            style={{
                maxHeight: scroll ? maxHeight : undefined,
                overflowY: scroll ? 'auto' : undefined,
                overflowX: scroll ? 'auto' : undefined,
                ...wrapperStyle
            }}
        >
            <table
                className={`
                    ${border ? `table--border-${border}` : ''} 
                    ${striped ? `row--striped-${striped}` : ''} 
                    ${hover ? 'row--hover' : ''} 
                    ${overflowText ? 'cell-overflow' : ''} 
                    ${(buttonCollapseBreakpoint && width < buttonCollapseBreakpoint) ? 'buttons-collapsed' : ''}
                    table--padding-${paddingBreakpoint && width < paddingBreakpoint ? 's' : padding}
                    ${className ? className : ''}
                `}
                style={{ tableLayout: layout ?? undefined, ...style }}
            >
                {caption && <caption>{caption}</caption>}

                <IF condition={tableStructure?.length > 0}>
                    <colgroup>
                        {(tableStructure ?? [])?.map((width, index) => {

                            if (typeof width === 'object') {
                                return <col key={index} width={width.width} style={{
                                    minWidth: width.minWidth,
                                }} />
                            }

                            return <col key={index} width={width} />
                        })}
                    </colgroup>
                </IF>

                {
                    (headers || useFirstDataItemAsHeaders) && <thead className={transparentHeader && 'head--transparent'}>
                        <tr>
                            {(useFirstDataItemAsHeaders ? data[0]?.data ?? data[0] ?? [] : headers ?? []).map((header, index) => {
                                if (header === undefined) {
                                    return false;
                                }
                                const allowOverFlow = columnOverflowExceptions.includes(index);

                                if (['string', 'number'].includes(typeof header) || header === null) {
                                    if (tableColumns && tableColumns['fields'][index]?.['sortable']) {
                                        return <th
                                            key={index}
                                            scope='col'
                                            style={{
                                                overflow: allowOverFlow === true ? 'unset' : 'hidden',
                                                width: structure ? structure[index] ?? undefined : undefined
                                            }}
                                            onClick={() => handleSortingChange(header)}
                                            clickable={tableColumns && tableColumns['fields'][index]['sortable'] ? 'true' : 'false'}
                                        >
                                            {!tableColumns || tableColumns.sort.currentColumn !== header && header}
                                            {tableColumns && tableColumns.sort.currentColumn === header && <div id={'sort__container'}>
                                                {header}
                                                {tableColumns && <div id={'sort__indication__container'}>
                                                    {tableColumns.sort.currentColumn === header && tableColumns.sort.direction === 'asc' && <Icon name='angle-down' />}
                                                    {tableColumns.sort.currentColumn === header && tableColumns.sort.direction === 'desc' && <Icon name='angle-up' />}
                                                </div>}
                                            </div>}
                                        </th>
                                    } else if (header.value !== undefined) {
                                        return (
                                            <th
                                                key={index}
                                                scope='col'
                                                style={{
                                                    overflow: allowOverFlow === true ? 'unset' : 'hidden',
                                                    width: structure ? structure[index] ?? undefined : undefined,
                                                    ...(header.attributes?.style ?? {})
                                                }}
                                                {...header.attributes}
                                                onClick={() =>
                                                    tableColumns &&
                                                    tableColumns['fields'][index]['sortable']
                                                        ? handleSortingChange(header)
                                                        : null
                                                }
                                                clickable={
                                                    tableColumns &&
                                                    tableColumns['fields'][index]['sortable']
                                                        ? 'true'
                                                        : 'false'
                                                }
                                            >
                                                {header.value}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th
                                                key={index}
                                                scope='col'
                                                style={{
                                                    overflow: allowOverFlow === true ? 'unset' : 'hidden',
                                                    width: structure ? structure[index] ?? undefined: undefined
                                                }}
                                                onClick={() => tableColumns && tableColumns['fields'][index]['sortable'] ? handleSortingChange(header) : null}
                                                clickable={tableColumns && tableColumns['fields'][index]?.['sortable'] ? 'true' : 'false'}
                                            >
                                                {header}
                                            </th>
                                        );
                                    }
                                } else {
                                    if (header.value !== undefined) {
                                        return (
                                            <th
                                                key={index}
                                                scope='col'
                                                {...header.attributes}
                                                style={{
                                                    overflow: allowOverFlow === true ? 'unset' : 'hidden',
                                                    width: structure ? structure[index] ?? undefined : undefined,
                                                    ...(header.attributes?.style ?? {})
                                                }}
                                            >
                                                {header.value}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th
                                                key={index}
                                                scope='col'
                                                style={{
                                                    overflow: allowOverFlow === true ? 'unset' : 'hidden',
                                                    width: structure ? structure[index] ?? undefined: undefined
                                                }}
                                                onClick={() => tableColumns && tableColumns['fields'][index]['sortable'] ? handleSortingChange(header) : null}
                                                clickable={tableColumns && tableColumns['fields'][index]['sortable'] ? 'true': 'false'}
                                            >
                                                {header}
                                            </th>
                                        );
                                    }
                                }
                            })}
                        </tr>
                    </thead>
                }

                <tbody>
                    <IF condition={isDataLoading}>
                        <tr
                            className='row'
                            clickable='false'
                        >
                            <td
                                colSpan={(useFirstDataItemAsHeaders ? (data[0]?.data ?? data[0] ?? []) : (headers ?? [])).length}
                                style={{textAlign: 'center'}}
                            >
                                <Spinner />
                            </td>
                        </tr>
                    </IF>
                        
                    <IF condition={!isDataLoading}>
                        {data?.map((row, index) => {
                            if (row === undefined || (useFirstDataItemAsHeaders && index === 0)) {
                                return false;
                            }

                            return <tr className={`row`} key={row.attributes?.id ?? index} {...row?.attributes} clickable={(typeof row?.attributes === 'object' && typeof row?.attributes?.onClick === 'function').toString()}>
                                {
                                    (row?.data ?? row ?? []).map((cell, index) => {
                                        const allowOverFlow = columnOverflowExceptions.includes(index);
                                        const denyTranslation = columnTranslationExceptions.includes(index);            
                                        if (cell === undefined) {
                                            return false;
                                        }
                                        if (typeof cell === 'object' && cell?.['$$typeof'] === undefined) {
                                            return (
                                                <td
                                                    key={index}
                                                    className={denyTranslation && "notranslate"}
                                                    title={cell?.value ?? ''}
                                                    {...cell?.attributes}
                                                    style={{
                                                        overflow: allowOverFlow === true ? 'none' : 'hidden',
                                                        width: structure?.[index] ?? undefined,
                                                        ...cell?.attributes.style
                                                    }}
                                                >
                                                    {cell?.value ?? ''}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td
                                                key={index}
                                                className={denyTranslation && "notranslate"}
                                                title={typeof cell !== 'string' ? '' : cell}
                                                style={{
                                                    overflow: allowOverFlow === true ? 'none' : 'hidden',
                                                    padding: customPadding !== false ? customPadding : '',
                                                    width: structure?.[index] ?? undefined
                                                }}
                                            >
                                                {cell}
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        })}
                            
                        {data?.length === 0 && (
                            <tr>
                                <td colSpan={tableStructure?.length ?? headers?.length}>
                                    <i style={{ color: 'gray' }}>Leeg...</i>
                                </td>
                            </tr>
                        )}
                    </IF>
                </tbody>

                {footers && (
                    <tfoot>
                        <tr>
                            {footers.map((footer, index) => {
                                if (!footer) {
                                    return false;
                                }
                                return <td key={index}>{footer}</td>;
                            })}
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );
}
