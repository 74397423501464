import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Container from 'UI/App/Components/Container/Container';
import Icon from 'UI/App/Components/Icon/Icon';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';

export default function CalculationTabsOverview() {
    const { authAxios } = useContext(FetchContext);

    const [tableData, setTableData] = useState([]);

    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        paginationStateLoaded,
        filterQuery
    } = usePagination({
        storageKey: 'calc-tabs',
        searchSettings: {
            enabled: true,
            strapiFields: ['owner.name']
        },
        htmlElements: [
            <Link className='btn btn--icon-right' to='/calculations/tabs/create'>
                Toevoegen <Icon name='plus' />
            </Link>
        ],
        resultsPerPageBreakpoint: 1350,
        resultCountBreakpoint: 1250,
        buttonCollapseBreakpoint: 1000
    });

    useEffect(() => {
        if (!paginationStateLoaded) return;

        const params = stringify({
            sort: ['owner.name'],
            populate: {
                owner: {
                    fields: ['name']
                }
            },
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            }
        });
        authAxios
            .get(`/calculation-setting?${params}&${filterQuery}`)
            .then(({ data: { data, meta } }) => {
                setTotalPages(meta.pagination.pageCount);
                setTotalResults(meta.pagination.total);

                const _tableData = data.map((entry) => [
                    entry.owner?.name,
                    <Link to={`/calculations/tabs/${entry.id}/edit/`}>Bewerken</Link>
                ]);

                setTableData(_tableData);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het ophalen van de date van de tabs!`);
            });
    }, [authAxios, currentPage, filterQuery, paginationStateLoaded, resultsPerPage, setTotalPages, setTotalResults]);

    return (
        <div>
            {filtering}
            <Container>
                <Table headers={['Naam', 'Bewerken']} structure={['70%', '15%']} data={tableData} />
            </Container>
            {paging}
        </div>
    );
}
