import { Fragment, useEffect, useState } from 'react';
import './input.scss';

type TTimelist = {
    id: string;
    includeHours: (string | number)[];
    includeMinutes?: (string | number)[];
};

const leadingZero = Intl.NumberFormat('nl-NL', {
    minimumIntegerDigits: 2,
    useGrouping: false
});

/**
 * Insert a <datalist> for a time input.
 *
 * @export default
 * @param {Object} props
 * @param {string} props.id
 * @param {(string|number)[]} [props.includeHours=[]]
 * @param {(string|number)[]} [props.includeMinutes=[0]]
 *
 * @return {JSX.Element|void}
 */
export function Timelist({
    id,
    includeHours = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
    ],
    includeMinutes = [0]
}: TTimelist): JSX.Element | void {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (
            includeMinutes.some((minute) => {
                const minuteNumber = parseInt(minute as string);

                return minuteNumber < 0 && minuteNumber > 59;
            })
        ) {
            console.error(new RangeError('minutes must be between 0 and 59'));
            setIsError(true);
        }
    }, [includeMinutes]);

    useEffect(() => {
        if (
            includeHours.some((hour) => {
                const hourNumber = parseInt(hour as string);

                return hourNumber < 0 && hourNumber > 23;
            })
        ) {
            console.error(new RangeError('hours must be between 0 and 23'));
            setIsError(true);
        }
    }, [includeHours]);

    if (isError) return;

    return (
        <datalist id={id}>
            {includeHours.flatMap((hour) => {
                const hourNumber = leadingZero.format(parseInt(hour as string));

                return (
                    <Fragment key={hourNumber}>
                        {includeMinutes.map((minute) => {
                            const minuteNumber = leadingZero.format(parseInt(minute as string));

                            return (
                                <option
                                    key={`${hourNumber}:${minuteNumber}`}
                                    value={`${hourNumber}:${minuteNumber}`}
                                ></option>
                            );
                        })}
                    </Fragment>
                );
            })}
        </datalist>
    );
}
