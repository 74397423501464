import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import {formatDate} from "App/Util/format";

export default function MaterialStockHistoryItem({
    item
}) {

    function getPastParticiple(action) {
        switch (action) {
            case 'inboeken':
                return 'ingeboekt'
            case 'reserveren':
                return 'gereserveerd'
            case 'afboeken':
                return 'afgeboekt'
            case 'verwijderen':
                return 'verwijderd'
            default:
                return action
        }
    }

    function getIconName(action) {
        switch (action) {
            case 'inboeken':
                return 'plus'
            case 'reserveren':
                return 'clipboard-check'
            case 'afboeken':
                return 'arrow-down'
            case 'verwijderen':
                return 'trash-can'
            default:
                return action
        }
    }

    function getIconColor(action) {
        switch (action) {
            case 'inboeken':
                return 'green'
            case 'reserveren':
                return 'grey'
            case 'afboeken':
                return 'red'
            case 'verwijderen':
                return 'red'
            default:
                return action
        }
    }

    function getPreposition(action) {
        switch (action) {
            case 'inboeken':
                return 'in'
            case 'reserveren':
                return 'in'
            case 'afboeken':
                return 'uit'
            case 'verwijderen':
                return 'uit'
            default:
                return action
        }
    }

    return (
        <Grid className='stock-history-item' columns={3} customColTemplate={'20px 1fr 15px'} gap={'10px'}>
            <div className='stock-history-item__timeline'></div>
            <div className='stock-history-item__content'>
                <span>
                    <small className='stock-history-item__user'>{item.user.username} </small>
                    <small className='stock-history-item__datetime'>- {formatDate(item.dateTime)}</small>
                </span>
                <span>{item.quantity} {item.unit} {getPastParticiple(item.action)} {getPreposition(item.action)} {item?.warehouse?.name} {(item?.spot !== null && item?.spot !== undefined) ? `${getPreposition(item.action)} positie ${item.spot.name}` : ''} ({item?.warehouse?.location}) </span>
            </div>
            <div className='stock-history-item__icon'>
                <Icon name={getIconName(item.action)} style={{ fill: getIconColor(item.action) }} />
            </div>
        </Grid>
    )
}
