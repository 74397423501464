export function getValuesArrayFromSelect(input: HTMLInputElement | Array<HTMLInputElement>) {
    const inputs = input instanceof HTMLInputElement ? [input] : Object.values(input);
    let returnArr = [];
    for (const input of inputs) {
        if (input.value !== '') {
            returnArr.push(input.value);
        }
    }
    return returnArr;
}

export function getSelectedValue(
    values: any,
    options: Array<{ label: any; value: any; options?: Array<any>; [key: string]: any }>,
    lookupKey: 'label' | 'value' = 'value'
) {
    // TODO: creatable select

    if (!Array.isArray(options)) {
        return null;
    }

    if (Array.isArray(values)) {
        let selectedOptions: any[] | null = null;

        for (const option of options) {
            if (Array.isArray(option?.options)) {
                const val: any = getSelectedValue(values, option.options, lookupKey);

                if (val !== null) {
                    // transform `selectedOptions` to an array first if a value is found
                    if (!Array.isArray(selectedOptions)) selectedOptions = [];

                    // add the value
                    selectedOptions.push(val);
                }
            } else {
                for (const value of values) {
                    if (option[lookupKey] === value) {
                        // transform `selectedOptions` to an array first if a value is found
                        if (!Array.isArray(selectedOptions)) selectedOptions = [];

                        // add the value
                        selectedOptions.push(option);
                    }
                }
            }
        }

        // return by type guarding. null?.flat() returns undefined instead of null
        return Array.isArray(selectedOptions) ? selectedOptions.flat() : null;
    } else {
        for (const option of options) {
            if (Array.isArray(option.options)) {
                const val: any = getSelectedValue(values, option.options, lookupKey);
                if (val !== null) return val;
            } else if (option[lookupKey] === values || (typeof values === 'string' && option[lookupKey] === values.toLowerCase())) {
                return option;
            }
        }
    }

    return null;
}
