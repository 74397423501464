import './Block.scss';

/**
 * Insert a basic block with a header and content
 *
 * @export default
 * @param {string} name the name used for extra css classes and block key.
 * @param {string|JSX.Element} title The block header title.
 * @param {boolean} [useSmallerPadding=false] Set to `true` to use a smaller padding in the content. (used for inline content blocks with no title).
 * @param {string} className Extra classes to apply to the block.
 * @param {JSX.Element} headerRightSideChildren HTML/JSX to insert on the right side of the block header. used for extra functionality like create, edit, print buttons.
 * @param {JSX.Element} children HTML/JSX to insert in the content of the block.
 * @param {object} style
 * @param {object} headerStyle
 * @param {object} contentStyle
 * @param {boolean} loading Set to `true` to show a loading spinner in the block.
 * @return {JSX.Element}
 */
export default function Block({ name, title, useSmallerPadding = false, className, headerRightSideChildren, children, style = {},headerStyle= {}, contentStyle = {}, loading = false }) {
    if (typeof name === 'undefined') {
        console.warn(`Een blok heeft geen 'name' property ${title ? `(${title})` : ''}`);
    }

    return (
        <div key={name ?? title} id={name ?? title} className={`block ${name ?? ''} ${className ?? ''}`} style={style}>
            {(title || headerRightSideChildren) &&
                <div className='block__header' style={{...headerStyle}} >
                    <strong className='block__header__title'>{title}</strong>

                    {headerRightSideChildren &&
                        <div className='block__header__right'>{headerRightSideChildren}</div>
                    }
                </div>
            }
            {children &&
                <div className={`block__content ${name && `${name}__content`} ${useSmallerPadding ? 'reduce-padding' : ''} ${loading ? 'content-loading' : ''}`} style={contentStyle}>
                    {children}
                </div>
            }
        </div>
    )
}
