import React, {ReactElement} from "react";
import CreatableSelect from "react-select/creatable";
import IWidget from "./IWidget";
import WidgetBase from "./WidgetBase";
import {TrenderType} from "./WidgetTypes";

export default class ReturnWidget extends WidgetBase implements IWidget {
	callback: ((object: Object) => any);
	widgetData: String | null
	saveWidget: ((object: Object) => any);
	inputs: ReactElement[] = [];
	optionalWidgetData: any;
	debug: boolean;

	/**
	 * A list of the most common return types for the creatable select
	 * For changing the display value of the return values
	 * @see CalculationResult.jsx > formatByUnit()
	 */
	returnTypes = [
		{ label: 'Enveloppen', value: 'enveloppen' },
		{ label: 'Euro', value: 'euro' },
		{ label: 'Kilogram', value: 'kilogram' },
		{ label: 'Meter', value: 'meter' },
		{ label: 'Minuten', value: 'minuten' },
		{ label: 'Uren', value: 'uren' },
		{ label: 'Vellen', value: 'vellen' },
		{ label: 'Stuks', value: 'stuks' },
		{ label: 'Procent', value: 'procent' }
	];

    /**
     * A list of the possible return names
     */
    returnNames = [
        {
            label: 'Machinekosten',
            options: [
                {label: 'Te verwerken eenheden', value: 'Machinekosten.Te verwerken eenheden'},
                {label: 'Opstart keren', value: 'Machinekosten.Opstart keren'},
                {label: 'Opstart kosten (per keer)', value: 'Machinekosten.Opstart kosten per keer'},
                {label: 'Opstart kosten (totaal)', value: 'Machinekosten.Opstart kosten totaal'},
                {label: 'Productie kosten (per uur)', value: 'Machinekosten.Productie kosten per uur'},
                {label: 'Totale productie kosten (zonder opstart)', value: 'Machinekosten.Totale productie kosten zonder opstart'},
                {label: 'Productie uren (zonder opstart)', value: 'Machinekosten.Productie uren'},
                {label: 'Totale productie kosten', value: 'Machinekosten.Totale productie kosten'},
            ]
        },
        {
            label: 'Arbeid',
            options: [
                {label: 'Uurtarief', value: 'Arbeid.Uurtarief'},
                {label: 'Opstart minuten (per keer)', value: 'Arbeid.Opstart minuten per keer'},
                {label: 'Opstart minuten (totaal)', value: 'Arbeid.Opstart minuten totaal'},
                {label: 'Arbeidsinspanning', value: 'Arbeid.Arbeidsinspanning'},
                {label: 'Arbeidsuren (zonder opstart)', value: 'Arbeid.Arbeidsuren'}
            ]
        },
        {
            label: 'Machine planning',
            options: [
                {label: 'Opstart uren', value: 'Machine planning.Opstart uren'},
                {label: 'Productie uren (zonder opstart)', value: 'Machine planning.Productie uren'},
                {label: 'In te plannen uren', value: 'Machine planning.In te plannen uren'},
            ]
        },
        {
            label: 'Materiaal kosten',
            options: []
        },
        {
            label: 'Totaal',
            options: [
                {label: 'Totale machine kosten (inclusief arbeid)', value: 'Totaal.Totale machine kosten inclusief arbeid'},
                {label: 'Totale materiaal kosten', value: 'Totaal.Totale materiaal kosten'},
                {label: 'Subtotaal', value: 'Totaal.Subtotaal'},
            ]
        }
    ];

	prefix: string | undefined;

	constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
		super()
		this.renderType = renderType;
		this.callback = callback;
		this.widgetData = null;
		this.saveWidget = saveWidget;
		this.optionalWidgetData = optionalWidgetData;
		this.debug = debug;
		this.prefix = prefix;
	}


	/**
	 * Returns react element for preview
	 */
	getPreviewHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
		const widgetData = {
			widgetType: 'ReturnWidget',
		}

		return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
		}}>Return</div>
	}

	/**
	 * returns form react element
	 */
	public getFormHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
		const widgetValues = this.getWidgetValues();

		const returnNameOptions = this.getReturnWidgetNames();

		return <form onSubmit={(e) => this.handleSubmit(e, this)}>
			<h3>Return</h3>
			<div className="input-group">
				Naam:
				<CreatableSelect
					name={'title'}
					options={returnNameOptions}
				/>
			</div>
			<div className="input-group">
				Waarde:
				<CreatableSelect
					name="return"
					options={widgetValues}
				/>
			</div>
			<div className="input-group">
				Eenheid (optioneel):
				<CreatableSelect
					name={'unit'}
					options={this.returnTypes}
					isClearable={true}
				/>
			</div>
			<button type="submit">Opslaan</button>
		</form>
	}

	/**
	 * Handles the form submit adds data to saveWidget function
	 * @param event
	 * @private
	 */
	handleSubmit(event: any, parentClass: any): void {
		event.preventDefault();
		const data = {
			type: "ReturnWidget",
			value: event.target.elements.return.value,
			title: event.target.elements.title.value,
			unit: null
		};

		if (event.target.elements.unit.value !== "") {
			data.unit = event.target.elements.unit.value;
		}

		/**
		 * Add to widget container
		 */
		parentClass.addWidgetToWidgetContainer("Return: " + event.target.elements.title.value, data);
	}

	/**
	 * Add input to widget
	 */
	addSelectWidgetInput(parentClass: any): void {
		document.getElementById('SelectWidgetForm')?.insertAdjacentHTML('beforeend', '<span><input type="text" name="names"/><input type="text" name="values"/></span>');
		// return <input name="test[]" />
	}

	/**
	 * Returns react element with badge html
	 */
	getBadgeHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
		return <div>Return:</div>
	}

	/**
	 * Renders calculation form
	 */
	public getCalculationForm() {

		this.addWidgetToCalculationForm('', this.optionalWidgetData, this.optionalWidgetData.parentId)
		// return <div id={this.optionalWidgetData.id}></div>
	}

	/**
	 * Returns html for edit
	 */
	getEditHTML(): void {
		(new WidgetBase()).addWidgetToWidgetContainer("Return: " + this.optionalWidgetData?.data?.title, this.optionalWidgetData, undefined, true);
	}

	/**
	 * Returns part of formula
	 */
	getFormulaString(): String {
		return `${this.optionalWidgetData?.title} (<small>${this.optionalWidgetData?.value}</small>)`;
	}

	/**
	 * Renders preview form
	 */
	getPreviewFormHTML(): React.ReactElement {
		//add what gets returned

		return <span>Return </span>;
	}

	/**
	 * returns form react element
	 */
	public getEditWidgetForm(editData: any): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
		const widgetValues = this.getWidgetValues();
		const selectedValues = WidgetBase.getSelectedValues(widgetValues, editData.value);
		const selectedReturnType = WidgetBase.getSelectedValues(this.returnTypes, editData.unit);

		if (selectedReturnType?.length === 0 && editData.unit) {
			selectedReturnType.push({
				label: editData.unit,
				value: editData.unit
			});
		}

		const returnNameOptions = this.getReturnWidgetNames();

		return <form onSubmit={this.handleEdit}>
			<input type="hidden" name={"id"} value={editData.id} />
			<div className="input-group">
				Naam:
				<CreatableSelect
					name={'title'}
					defaultValue={{ label: editData.title, value: editData.title }}
					options={returnNameOptions}
				/>
			</div>
			<div className="input-group">
				Waarde:
				<CreatableSelect
					defaultValue={selectedValues ? selectedValues : { label: editData.value, value: editData.value }}
					name="return"
					options={widgetValues}
				/>
			</div>
			<div className="input-group">
				Eenheid (optioneel):
				<CreatableSelect
					name={'unit'}
					defaultValue={selectedReturnType}
					options={this.returnTypes}
					isClearable={true}
				/>
			</div>
			<button type="submit">Opslaan</button>
		</form>
	}

	/**
	 * Handles the form submit adds data to saveWidget function
	 * @param event
	 * @private
	 */
	handleEdit(event: any): void {
		event.preventDefault();
		const data = {
			type: "ReturnWidget",
			value: event.target.elements.return.value,
			title: event.target.elements.title.value,
			unit: null
		};

		if (event.target.elements.unit.value !== "") {
			data.unit = event.target.elements.unit.value;
		}

		/**
		 * Add to widget container
		 */
		// (new WidgetBase()).addWidgetToWidgetContainer("Return", data);
		(new WidgetBase()).updateWidget(event.target.elements.id.value, "Return: " + event.target.elements.title.value, data);
	}

	calculationFunctionality() {
		const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
		/**
		 * For initial setup
		 */
		let target;
		if (this.optionalWidgetData.data.value.target === undefined) {
			target = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value : this.optionalWidgetData.data.value;
			// target = this.optionalWidgetData.data.value;
		} else {
			// target = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.target : this.optionalWidgetData.data.value.target
			target = this.optionalWidgetData.data.value.target;
		}

		let result = this.getResultFromWidget(target);

		// If the result is a string and is a number, convert it to a float
		if (typeof result === 'string' && /^\d+(\.\d+)?$/.test(result) && !isNaN(parseFloat(result))) {
			result = parseFloat(result);
		}

		/**
		 * Add data to widgetData
		 */
		this.optionalWidgetData.data.value = {
			result: result,
			target: target,
		}

		/**
		 * Update widget data in storage
		 */
		this.updateWidgetData(id, this.optionalWidgetData)

		const widgetElement = this.getWidgetElement();

		if (widgetElement) {
			if (this.debug) {
				widgetElement.innerHTML = `[${id}] Return: ${target} | ${this.optionalWidgetData.data.title} = ${result}`
				widgetElement.classList.remove('hidden');
			} else {
				widgetElement.innerHTML = "";
				widgetElement.classList.add('hidden');
			}
		}

		return {
			titel: this.optionalWidgetData.data.title,
			result: this.optionalWidgetData.data.value.result,
			unit: this.optionalWidgetData.data.unit ?? null
		};
	}

	private getReturnWidgetNames() {
		let defaultReturnNames = this.returnNames;
		// get all widgets that contain the word "MaterialChoice" in their widgetdata attribute
		const flowWidgets = Array.from(document.querySelectorAll('.flow_widget'));

		// TODO: between total and planning with sub category

		flowWidgets.forEach((widget) => {
			const widgetData = JSON.parse(widget.getAttribute('widgetdata') ?? '{}');
			if (widgetData.type === 'MaterialChoice') {
				defaultReturnNames[3].options.push(
					{
						label: `Materiaal.${widget.id}.Aantal`,
						value: `Materiaal.${widget.id}.Aantal`,
					},
					{
						label: `Materiaal.${widget.id}.Verkoopprijs`,
						value: `Materiaal.${widget.id}.Verkoopprijs`,
					},
					{
						label: `Materiaal.${widget.id}.Totaalprijs`,
						value: `Materiaal.${widget.id}.Totaalprijs`,
					}
				);
			}
		});

		return defaultReturnNames;
	}
}
