import Popup, {closePopup} from "UI/App/Components/Popup/Popup";
import {useContext} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import MaterialPopupContent from "UI/App/Components/Popup/Popups/MaterialPopup/MaterialPopupContent";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";
import {_ORDER_QUERY} from "UI/App/Partials/Content/CRM/Orders/ViewOrder";

export default function ReserveMaterialPopup({
    defaultMaterialId,
    defaultUnit,
    defaultQuantity,
    onClose,
    order,
    setOrder,
    orderMaterials,
    maximumQuantity,
}) {
    const { authAxios } = useContext(FetchContext);

    async function onSubmit(materialId, unit, unverifiedQuantity, warehouse) {

        if (unverifiedQuantity > defaultQuantity) {
            toast.error('Het aantal is groter dan de maximale hoeveelheid!');
            return;
        }

        if(warehouse === null){
            toast.error('Kies een magazijn!');
            return;
        }

        if(materialId === null){
            toast.error('Kies een materiaal!');
            return;
        }

        if(unit === null){
            toast.error('Er ging iets mis met het reserveren van een materiaal.');
            return;
        }

        const quantity =  await (new MaterialUnitMutator()).quantityMerchant(unverifiedQuantity,materialId)
        unit = await (new MaterialUnitMutator()).obtainUnitForStorage(materialId);

        // Gather order material ids
        let orderMaterialIds = [];
        for(const orderMaterialData of orderMaterials){
            orderMaterialIds.push(orderMaterialData.material.material.id);
        }

        // If material does not exist within order add to additional materials
        if(!orderMaterialIds.includes(materialId)){
            await addMaterialToOrder(materialId,quantity)
        }

        await authAxios.post(`/logistics/materials/${materialId}/stock/reserve`, {
            data: {
                unit,
                quantity,
                warehouse,
                order: order.id
            }
        }).then(() => {
            toast.success('Materiaal gereserveerd');
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Materiaal kon niet worden gereserveerd${exception?.response?.status ? ` (${exception?.response?.status})` : ''}`);
        })

        // Update order stock data
        let stockData = order.stockData === null ? {} : order.stockData

        // If material does not exist add material to stock data
        // Else add to stock data
        if(!stockData[materialId]){
            stockData[materialId] = {
                reserved: 0
            }
        } else if (!stockData[materialId]['reserved']) {
            stockData[materialId]['reserved'] = 0;
        }

        stockData[materialId]['reserved'] = stockData[materialId]['reserved'] += quantity;

        await authAxios.put(`/orders/${order.id}?${_ORDER_QUERY}`, {
            data: {
                stockData: stockData
            }
        }).then((data) => {
            setOrder(data.data.data)
        }).catch((exception) => {
            toast.error('Er ging iets mis met het bewerken van een order.');
            console.error(exception);
        });

        onClose();
        closePopup();
    }

    async function addMaterialToOrder(materialId,quantity){

        let data = {...order}
        data.additionalMaterials.push({
            material:materialId,
            quantity:quantity
        });

        await authAxios.put(`/orders/${order.id}`, {
            data
        }).then((data) => {
            toast.info('Materiaal toegevoegd aan order.')
        }).catch((exception) => {
            toast.error('Er ging iets mis met het bewerken van een order.');
            console.error(exception);
        });
    }

    return (
        <Popup popupID={'reserveMaterial'} title={'Materiaal reserveren'}>
            <MaterialPopupContent
                onSubmit={onSubmit}
                defaultMaterialId={defaultMaterialId}
                defaultUnit={defaultUnit}
                defaultQuantity={defaultQuantity}
                maximumQuantity={maximumQuantity}
                buttonText={'Reserveren'}
            />
        </Popup>
    )
}
