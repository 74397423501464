import Popup from 'UI/App/Components/Popup/Popup';
import Grid from 'UI/App/Components/Grid/Grid';
import {useCallback, useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Table from "UI/App/Components/Table/Table";
import {formatDateWithoutTime, formatSeconds, formatTimeWithoutDate} from "App/Util/format";
import {calculateTotalTime} from "UI/App/Partials/Content/Calculations/HJMG/steps/util";
import {SpinnerCenter, SpinnerOverlay} from "UI/App/Components/Spinner";
import useWindowDimensions from "App/Util/useWindowDimensions";
import debounce from "lodash/debounce";

const updatePopupWidth = debounce((width, setPopupWidth) => {
    if (width > 2000) {
        setPopupWidth(50);
    } else if (width > 1700) {
        setPopupWidth(60);
    } else if (width > 1300) {
        setPopupWidth(70);
    } else {
        setPopupWidth(80);
    }
}, 100);

export default function UserdataMachinePopup({
    machine,
    orderId
}) {
    const { authAxios } = useContext(FetchContext);
    const { width } = useWindowDimensions();

    const [machineTimerData, setMachineTimerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [popupWidth, setPopupWidth] = useState(50);

    /**
     * Fetch the timer data
     * @returns {Promise<any[]>}
     */
    const getOrderTimersForMachine = useCallback(() => {
        return authAxios.get(`/orders/${orderId}/timers/${machine}`)
            .then(response => {
                return response.data;
            })
            .catch((e) => {
                toast.error("Er is iets fout gegaan bij het ophalen van de timers!")
                console.error(e);
                return [];
            });
    }, [authAxios, orderId, machine]);

    useEffect(() => {
        if (!orderId || !machine) return;

        setLoading(true);
        getOrderTimersForMachine()
            .then((data) => {
                setMachineTimerData(
                    data.map(timer => ([
                        formatDateWithoutTime(timer.createdAt),
                        `${timer.user.username} (${timer.user.email})`,
                        formatSeconds(calculateTotalTime(timer.start, timer.end, timer.breaks), false),
                        formatTimeWithoutDate(timer.start, { hour: '2-digit', minute: '2-digit' }),
                        timer.end ? formatTimeWithoutDate(timer.end, { hour: '2-digit', minute: '2-digit' }) : '-'
                    ]))
                );
            }).finally(() => {
                setLoading(false);
            })
    }, [orderId, machine, getOrderTimersForMachine]);

    useEffect(() => {
        updatePopupWidth(width, setPopupWidth);
    }, [width]);

    // return popup
    return (
        <Popup title={machine} style={{
            width: popupWidth + '%',
            left: `calc(50vw - ${popupWidth / 2}%)`
        }} popupID='UserdataMachinePopup'>
            {loading ?
                <SpinnerCenter message={"Uren ophalen..."} containerStyle={{ minHeight: 'inherit'}} />
                :
                <Table
                    structure={{
                        1700: ['115px', '100%', '200px', '115px', '125px'],
                        1900: ['125px', '100%', '225px', '125px', '135px'],
                        2200: ['150px', '100%', '250px', '150px', '150px'],
                        default: ['200px', '100%', '250px', '200px', '200px']
                    }}
                    headers={['Datum', 'User', 'Totale gebruikte tijd', 'Inklok tijd', 'Uitklok tijd']}
                    data={machineTimerData}
                />
            }
        </Popup >
    )
}