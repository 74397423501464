import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import Popup from "UI/App/Components/Popup/Popup";
import {useState} from "react";
import {Attachment} from "UI/App/Components/Form/FileUpload/@types";
import './_fileUpload.scss';

// TODO: Add support for multiple files
export function useFileUpload(accept: string,  content: string, icon: string, title: string) {
    const [attachment, setAttachment] = useState<Attachment>({
        name: '',
        url: '',
        file: null,
    })

    return {
        FileUpload: () => <FileUpload
            attachment={attachment}
            setAttachment={setAttachment}
            accept={accept}
            content={content}
            icon={icon}
            title={title}
        />,
        attachment,
        setAttachment
    }
}

export function FileUpload({
    attachment,
    setAttachment,
    accept = '*',
    icon = 'file',
    content = 'Upload een bestand',
    title = 'Bekijk bestand',
    ...props
}: {
    attachment: Attachment,
    setAttachment: (attachment: Attachment|((attachment: Attachment) => Attachment)) => void,
    accept?: string,
    icon?: string,
    content?: string,
    title?: string,
}) {
    return (
        <label className='upload__placeholder' htmlFor='attachment' {...props}>
            <IF condition={!attachment?.url && !attachment?.file}>
                <Icon name={icon}/>
            </IF>
            <IF condition={attachment?.url || attachment?.file}>
                <FilePreview file={attachment?.file} />
                {/* @ts-ignore */}
                <Popup title={title} popupID={'bijlage-preview'}>
                    <img src={attachment?.file ? URL.createObjectURL(attachment.file) : attachment.url} alt={attachment?.file ? attachment?.file?.name : attachment.name}/>
                </Popup>
            </IF>
            <span>{(attachment?.name || attachment?.file) ? (attachment?.file?.name ?? attachment.name) : content}</span>
            <input id='attachment' type='file' accept={accept} onChange={event => {
                setAttachment((attachment: Attachment) => ({
                    ...attachment,
                    file: event.target.files?.[0] ?? null
                }));
            }}/>
        </label>
    );
}

function FilePreview({ file }: { file: File|null }) {
    if (file && /image\/\w+/.test(file?.type)) {
        return <img src={URL.createObjectURL(file)} alt={file?.name} />
    }

    switch (file?.type) {
        case 'application/pdf':
            return <Icon name={'file-pdf'}/>
        default:
            return <Icon name={'file'}/>
    }
}