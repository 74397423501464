import Block from "UI/App/Components/Block/Block";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import {SpinnerCenter} from "UI/App/Components/Spinner";

export default function PowerBI() {
    const { authAxios } = useContext(FetchContext);

    const [chosenReport, setChosenReport] = useState(null);

    const [reports, setReports] = useState([]);

    const [fatalError, setFatalError] = useState(false);

    // Get dashboards and embed token on initial load
    useEffect(() => {
        authAxios.get('/dashboards')
            .then(({ data }) => {
                setReports(data);
            })
            .catch((err) => {
                console.error(err);
                toast.error('Er is iets mis gegaan bij het ophalen van de Dashboards!');
                setFatalError(true);
            })
    }, []);

    // Set the chosen report to the first report if there are reports and no chosen report
    useEffect(() => {
        if (reports?.length > 0 && !chosenReport) {
            setChosenReport(reports[0]);
        }
    }, [reports]);

    if (reports?.length === 0 || !reports) {
        return null;
    }

    return (
        <Block
            title={'Dashboards'}
            name={'powerBI'}
            contentStyle={{
                height: 'fit-content',
            }}
            style={{
                marginTop: '20px',
                overflow: 'visible',
            }}
            headerRightSideChildren={(
                <select
                    value={chosenReport?.id ?? ''}
                    onChange={(e) => {
                        setChosenReport(reports.find((report) => report.id === parseInt(e.target.value)))
                    }}
                >
                    <option key='0' value={''}>Kies dashboard</option>
                    {
                        reports.map(report => (
                            <option key={report.id} value={report.id}>{report.name}</option>
                        ))
                    }
                </select>
            )}
        >
            {chosenReport?.reportId && <EmbedContainer chosenReport={chosenReport} />}
            {(!chosenReport?.reportId) && !fatalError && <SpinnerCenter className={'powerBIReport'} />}
            {fatalError && <p style={{ color: 'var(--text-error)' }}>Er is iets mis gegaan bij het laden van het dashboard!</p>}
        </Block>
    );
}

function EmbedContainer({
    chosenReport,
}) {
    return (
        <div
            style={{
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <iframe
                className={'powerBIReport'}
                title="PowerBI"
                src={`https://app.powerbi.com/reportEmbed?reportId=${chosenReport.reportId}&autoAuth=true`}
                style={{
                    width: '1750px',
                    height: '1100px',
                    border: 0,
                }}
                allowFullScreen
                referrerPolicy="no-referrer"
            />
        </div>
    )
}
