import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import capitalize from "lodash/capitalize";
import {stringify} from "qs";
import IF from "UI/App/Components/Conditional/IF";
import Select from "react-select";
import {getSelectedValue} from "App/Util/select2";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import { openPopup } from "UI/App/Components/Popup/Popup";


export default function StepTwo({checklistData, setChecklistData, chosenLanguage, checklistTemplateId, setChecklistTemplateId}) {
    const fetchContext = useContext(FetchContext);
    const [chosenChecklist, setChosenChecklist] = useState(null);
    const [checklistTemplates, setChecklistTemplates] = useState([])
    const [popupData, setPopupData] = useState({});
    const [inputValues, setInputValues] = useState([]);

    // Effect on language
    useEffect(() => {
        const query = stringify({
            populate: '*',
            filters: {
                $or: [
                    {
                        owner: {
                            $eqi: 'pretore'
                        }
                    },
                    {
                        owner: {
                            $eqi: 'both'
                        }
                    }
                ]
            }
        }, {
            encodeValuesOnly: true
        });
        /**
         * Get all templates and set chosen selected
         */
        fetchContext.authAxios.get(`/calculations/checklists/templates?${query}`).then(({ data: { data } }) => {
            if (checklistData !== undefined && checklistData.length !== 0) {
                const productionList = data.find((el) => el.name.toLowerCase() === "productie");
                setChosenChecklist({
                    label: productionList.name,
                    value: productionList.id,
                    inputs: checklistData
                });
                setChecklistTemplateId(productionList.id);
                setChecklistData(checklistData);
                setInputValues(checklistData);
            }
            setChecklistTemplates(data.map((template) => ({
                label: template.name,
                value: template.id,
                inputs: template.inputs
            })))


        }).catch((exception) => {
            console.error(exception);
        });


    }, [chosenLanguage])

    useEffect(() => {
        const newChecklistData = inputValues.map((input) => ({
            type: input.type,
            name: input.name,
            value: input.value,
            numberOfLines: input.numberOfLines ?? 1,
            dutchName: input.dutchName,
            englishName: input.englishName,
        }));
        setChecklistData(newChecklistData);
    }, [inputValues]);

    const updateChecklist = (index, event) => {
        const { value } = event.target;
        setInputValues(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = { ...newValues[index], value };
            return newValues;
        });
    };

    const mergeInputs = (newInputs) => {
        const mergedInputs = newInputs.map(newInput => {
            const existingInput = inputValues.find(input => input.name === newInput.name);
            return existingInput ? { ...newInput, value: existingInput.value } : newInput;
        });
        setInputValues(mergedInputs);
    };

    return (
        <>
            <ConfirmationPopup
                title="Let op!"
                subject="Veranderen van checklist"
                description={<>Met het veranderen van de checklist kan er data <b>verloren</b> gaan,<br />
                    Er wordt geprobeerd zoveel mogelijk data over te nemen.<br /><br />
                    Kijk de checklist goed na als u doorgaat!
                </>}
                confirmText="Doorgaan"
                declineText="Onderbreken"
                functionOnConfirm={(e) => {
                    mergeInputs(popupData.inputs);
                    setChosenChecklist(popupData.e);
                    setChecklistTemplateId(popupData.value);
                    setChecklistData(popupData.inputs);
                }} />

            <div>
                <label htmlFor="checklistSelect">Kies een checklist:</label>
                <Select
                    options={checklistTemplates}
                    onChange={(e) => {
                        setPopupData({ e: e, value: e.value, inputs: e.inputs });

                        if(checklistTemplateId !== null) {
                            openPopup("confirmationPopup")
                        }else{
                            setChosenChecklist(e);
                            setChecklistTemplateId(e.value);
                            setChecklistData(e.inputs);
                            setInputValues(e.inputs);
                        }
                    }}
                    value={getSelectedValue(checklistTemplateId, checklistTemplates)}
                    noOptionsMessage={() => "Geen checklists gevonden"}
                />
            </div>
            <IF condition={Array.isArray(chosenChecklist?.inputs)}>
                <hr />
                <br/>
                {chosenChecklist?.inputs.map((input, index) => {
                    return (
                        <span key={'checklist-' + input.name}>
                            {capitalize((chosenLanguage === 'nl' ? input.dutchName : input.englishName) ?? input.name)}:
                            <IF condition={input?.numberOfLines === 1}>
                                <input
                                    onChange={(e) => updateChecklist(index, e)}
                                    className={"checklistInput"}
                                    type={"text"}
                                    name={input.name}
                                    value={inputValues[index]?.value || ""}
                                    data-dutch-name={input.dutchName}
                                    data-english-name={input.englishName}
                                />
                            </IF>
                            <IF condition={input?.numberOfLines !== 1}>
                                <textarea
                                    rows={input?.value ? input?.value?.split('\n')?.length : input?.numberOfLines ?? 2}
                                    onChange={(e) => updateChecklist(index, e)}
                                    className={"checklistInput"}
                                    name={input.name}
                                    value={inputValues[index]?.value || ""}
                                    data-dutch-name={input.dutchName}
                                    data-english-name={input.englishName}
                                >
                                </textarea>
                            </IF>
                        </span>
                    )
                })}
            </IF>
        </>
    )
}
