import Block from "UI/App/Components/Block/Block";
import { FetchContext } from "App/Strapi/FetchContext";
import { useContext, useEffect, useState, useRef, Component } from "react";
import { toast } from "react-toastify";
import { stringify } from "qs";
import compact  from "lodash/compact";
import min from "lodash/min";
import PopupAddTimer from "UI/App/Components/Popup/Popups/AddTimer";
import PopupEditTimer from 'UI/App/Components/Popup/Popups/EditTimer';
import { openPopup } from "UI/App/Components/Popup/Popup";
import Button from "UI/App/Components/Button/Button";
import Icon from "UI/App/Components/Icon/Icon";

export default function TimeWeekTable() {
    const { authAxios } = useContext(FetchContext);
    const ref = useRef();

    const [variableBlock, setVariableBlock] = useState([]);
    const [knowTimers, setKnowTimers] = useState([]);
    const [table, setTable] = useState([]);
    const [subMenu, setSubMenu] = useState({ idOfTimer: undefined, elements: <></> });
    const [editTimer, setEditTimer] = useState({});
    const [addTimer, setAddTimer] = useState({});

    const [weekSelector, setWeekSelector] = useState({ curDate: new Date() })
    // which roles are allowed to use the create part of the select
    const [daysToDisplay, setDaysToDisplay] = useState([
        { short: "Zo", long: "Zondag" },
        { short: "Ma", long: "Maandag" },
        { short: "Di", long: "Dinsdag" },
        { short: "Wo", long: "Woensdag" },
        { short: "Do", long: "Donderdag" },
        { short: "Vr", long: "Vrijdag" },
        { short: "Za", long: "Zaterdag" },
    ]);
    const [colors, setColors] = useState({
        background: "white",
        accent: "#c4e2ec",
        selected: "#d9f2fa",
        currentTime: "#0089b4",
        currentActiveBlock: "green",
    });
    useEffect(() => {
        // get timers spanning this week.
        getDatesFromCurrentWeek();
        getWeekByDate(undefined, weekSelector?.curDate)
    }, [])
    useEffect(() => {
        if (knowTimers === undefined) return;
        updateBlock();
    }, [knowTimers, subMenu.idOfTimer, editTimer, weekSelector, addTimer])
    useEffect(() => {

        if (addTimer.startInfo === undefined) return;
        if (addTimer.endInfo === undefined) return;
        openPopup("add-timer")

        setAddTimer({})

    }, [addTimer])
    function updateBlock() {
        let elements = []


        elements.push(<div className="timeAccounting__week">
            <input name="selectDate" type="date" value={weekSelector?.selectedDate} onChange={(e) => getWeekByDate(e)} />
            Week: {weekSelector?.selectedWeek}
        </div>);
        elements.push(createTable())
        setVariableBlock(elements);
    }

    function createTable() {
        let elements = [];

        // prep named table headers
        let th = []
        th.push(<th className={"weekTable__th"}>{"[]"}</th>)
        for (const day of daysToDisplay) {
            th.push(<th className={"weekTable__th"}>
                {day.short}
            </th>)
        }

        // prep table content
        elements.push(<>
            <table className={"weekTable"}>
                <thead >
                    <tr>
                        {th}
                    </tr>
                </thead>
                <tbody >
                    {createTableRows(30, 5)}
                </tbody>
            </table>
        </>);

        // prep table popups
        elements.push(<PopupEditTimer timer={editTimer} setUpdateBlocks={reAquireTable} />)
        elements.push(<PopupAddTimer timerToAdd={addTimer} setUpdateBlocks={reAquireTable} />)

        return elements;

    }
    function createTableRows(stepSize = 30, minSpacing = 5) {

        stepSize = stepSize >= minSpacing ? Math.round(stepSize / minSpacing) * minSpacing : minSpacing;
        let subDivision = stepSize / minSpacing;
        //let timersToDisplay = await 
        let blocks = formatTimersToTableBlocks(knowTimers, stepSize, minSpacing)

        // 1440 minutes in a day.
        let steps = Math.floor(1440 / stepSize);



        let trTotal = []

        for (let i = 0; i < steps; i++) {
            let tr = []

            tr.push(<td className="weekTable__td_time" style={{ padding: "0px" }}> {padMinutes(i * stepSize)}</td >)
            for (const [key, day] of Object.entries(daysToDisplay)) {


                tr.push(<td className="weekTable__td" name={`${i}_${day.short}`} >
                    <table className="weekSubTable">{createSubTable(subDivision, i, key, blocks, stepSize)}</table>
                </td>);
            }

            // wrap all td's in a tr and push to total
            trTotal.push(<tr className="weekTable__tr">
                {tr}
            </tr>)
        }
        // return all rows
        return trTotal;

    }
    function createSubTable(subDivision, index, day, blocks, stepsize) {
        let content = []
        let background = colors.background;

        // if we have NO blocks, make an empty table
        if (blocks === undefined) {
            let _content = []
            // empty row
            _content.push(<td style={{ backgroundColor: background }} className="weekSubTable__td">{" "}</td>);
            // mutiple subrows depending on division
            for (let _i = 0; _i < subDivision; _i++) {
                // make the background a diffrenct color so the user knows where he pressed the table
                if (addTimer?.startRowInfo !== undefined) {

                    if (addTimer?.startRowInfo.row === index && addTimer?.startRowInfo.day === Number(day) && addTimer?.startRowInfo.subrow === _i) {
                        background = colors.currentTime;
                        _content = <td style={{ backgroundColor: background }} className="weekSubTable__td">{" "}</td>;
                    } else {
                        background = colors.background;
                        _content = <td style={{ backgroundColor: background }} className="weekSubTable__td">{" "}</td>;
                    }
                }


                content.push(<tr onClick={(e) => { tableClick(e, `${index}_${day}_${stepsize}_${_i}_${subDivision}`) }} className="weekSubTable__tr" style={{ color: background, backgroundColor: background, }}>{_content}</tr>)
            }
            // return placeholder row
            return content
        }

        // if we do have blocks check how many vertical rows we need
        let maxRows = 0
        if (blocks[day] !== undefined) {
            maxRows = Math.max(...blocks[day].map(entry => entry.rowInfo.row))

        }

        // make sub rows
        for (let _i = 0; _i < subDivision; _i++) {
            let _content = new Array(maxRows)

            // make the background a diffrenct color so the user knows where he pressed the table
            let currentRowIsSelected = false;
            if (addTimer?.startRowInfo !== undefined) {

                if (addTimer?.startRowInfo.row === index && addTimer?.startRowInfo.day === Number(day) && addTimer?.startRowInfo.subrow === _i) {
                    currentRowIsSelected = true;
                }
            }

            // if we dont have any data use a placeholder
            if (blocks[day] === undefined) {
                background = (currentRowIsSelected) ? colors.currentTime : colors.background
                _content.push(<td style={{ backgroundColor: background, }} className="weekSubTable__td">{" "}</td>);
            }


            for (let trRow = 1; trRow <= maxRows; trRow++) {
                if (_content[trRow] === undefined) {
                    _content[trRow] = [];
                }
                let inRow = timerInRow(blocks[day], index, _i, subDivision, trRow)
                let extraStyling = (inRow.inRow ? "weekSubTable__td_active" : "");
                let text = "";
                let submenu = <></>;

                if (inRow.inRow) {

                    background = (currentRowIsSelected) ? colors.currentTime : colors.selected
                    // if its the first row
                    if (inRow.top) {

                        extraStyling += " weekSubTable__td_top";
                        text = inRow.timer.entry.details.description;
                        submenu = inRow.timer.entry.id === subMenu.idOfTimer ? subMenu.elements : <></>;
                    }
                    // if its the last row
                    if (inRow.bot) {

                        extraStyling += " weekSubTable__td_bot";
                    }



                    _content.push(
                        <td
                            ref={ref}
                            style={{ backgroundColor: background, }}
                            className={"weekSubTable__td " + extraStyling}
                            onClick={(e) => { timerClicked(e, inRow.inRow ? inRow.timer.entry.id : undefined, ref) }}
                            onMouseOver={(e) => { timerHover(e, inRow.timer.entry.id) }}

                        >
                            {submenu}{text}
                        </td >);
                } else {
                    background = (currentRowIsSelected) ? colors.currentTime : colors.background
                    //background = colors.background;
                    _content.push(<td style={{ backgroundColor: background, }} className="weekSubTable__td">{" "}</td>);
                }



            }
            content.push(<tr onClick={(e) => { tableClick(e, `${index}_${day}_${stepsize}_${_i}_${subDivision}`) }} onMouseLeave={clearSubmenu} className="weekSubTable__tr" style={{ color: colors.accent }}>{_content}</tr>)

        }
        return content
    }
    function timerInRow(timers, mainRow, subRow, subDivision, row) {
        let returnVal = false;
        let isTopLayer = false;
        let isBotLayer = false;
        let timerInRow = undefined
        for (let timer of timers) {
            if (row !== timer.rowInfo.row) continue;


            let actulRow = subDivision * mainRow + subRow;
            if (actulRow >= timer.rowInfo.subDivision.start && actulRow <= timer.rowInfo.subDivision.end) {
                returnVal = true
                timerInRow = timer;
            }
            if (mainRow === timer.rowInfo.startIndex && actulRow === timer.rowInfo.subDivision.start) {
                isTopLayer = true;
            }

            if (mainRow === timer.rowInfo.endIndex && actulRow === timer.rowInfo.subDivision.end) {
                isBotLayer = true;
            }

        }
        return { inRow: returnVal, top: isTopLayer, bot: isBotLayer, timer: timerInRow }
    }
    function getStartDateByWeek(week, year) {

        if (week < 1 || week > 53) {
            throw new Error("week must be between 1 and 53")
        }
        let updateWeekSelector = weekSelector;

        let simpleDate = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
        let dayOfWeek = simpleDate.getDay();
        let isoWeekStart = simpleDate;

        isoWeekStart.setDate(simpleDate.getDate() - dayOfWeek + 1);
        if (dayOfWeek > 4) {
            isoWeekStart.setDate(isoWeekStart.getDate() + 7);
        }

        // The latest possible ISO week starts on December 28 of the current year.
        if (isoWeekStart.getFullYear() > year ||
            (isoWeekStart.getFullYear() == year &&
                isoWeekStart.getMonth() == 11 &&
                isoWeekStart.getDate() > 28)) {
            throw new Error(`${year} has no ISO week ${week}`);
        }

        updateWeekSelector.isoWeekStart = new Date(isoWeekStart);

        setWeekSelector(updateWeekSelector);

        return isoWeekStart;
    }
    function getWeekByDate(e, date) {
        let updateWeekSelector = weekSelector;
        if (e === undefined) {


            // get and set weeknumber
            let curDate = new Date(date);
            let startDate = new Date(curDate.getFullYear(), 0, 1);
            let days = Math.floor((curDate - startDate) /
                (24 * 60 * 60 * 1000));

            let weekNumber = Math.ceil(days / 7);

            updateWeekSelector.selectedWeek = weekNumber;

            // set date of datePicker
            let year = curDate.getFullYear().toString();
            let month = (curDate.getMonth() + 1).toString().padStart(2, '0');;
            let day = curDate.getDate().toString().padStart(2, '0');
            updateWeekSelector.selectedDate = year + "-" + month + "-" + day;

            // set usestate
            setWeekSelector(updateWeekSelector);

            getStartDateByWeek(updateWeekSelector.selectedWeek, curDate.getFullYear())
        } else {

            // get changed time
            updateWeekSelector.selectedDate = e.target.value;
            // get the current day (0-6)
            updateWeekSelector.selectedDay = new Date(e.target.value).getDay();

            // get and set weeknumber
            let curDate = new Date(e.target.value);
            let startDate = new Date(curDate.getFullYear(), 0, 1);
            let days = Math.floor((curDate - startDate) /
                (24 * 60 * 60 * 1000));

            let weekNumber = Math.ceil(days / 7);

            updateWeekSelector.selectedWeek = weekNumber;


            // set usestate
            setWeekSelector(updateWeekSelector);

            getDatesFromCurrentWeek(getStartDateByWeek(updateWeekSelector.selectedWeek, new Date(e.target.value).getFullYear()));
            // since this is an event update block to make changes visible
            updateBlock();
        }

    }
    //function
    function getDatesFromCurrentWeek(givenDate = undefined) {
        // get start and end of week, we use these to filter strapi.
        let curr = givenDate === undefined ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) : givenDate;
        let first = curr.getDate() - curr.getDay();

        let firstDay = new Date(curr.setDate(first));
        firstDay.setDate(firstDay.getDate() - 3)
        let lastDay = new Date(curr.setDate(firstDay.getDate() + 12));



        const params = stringify({
            populate: "*",
            filters: {
                $and: [
                    {
                        startTime: {
                            $gte: firstDay
                        },
                        endTime: {
                            $lte: lastDay
                        }

                    }
                ]

            }

        });
        authAxios.get(`/time-registrations/all?${params}`).then(({ data }) => {
            setKnowTimers(data)
            if (data.length === 0) {
                updateBlock();
            }

        })
            .catch((e) => {

                toast.error('Er ging iets miss bij het ophalen van de timers');

                console.error(e);
            });
    }
    function formatTimersToTableBlocks(knowTimers, stepsize, minSpacing, week = undefined) {
        if (knowTimers.length === 0) return;
        let exepandedTimers = []
        let indexedBlocks = [];



        // split timers spanning mutiple days
        for (const timer of knowTimers) {
            let start = timeToOffsetTime(new Date(timer.startTime))
            let end = timeToOffsetTime(new Date(timer.endTime))

            let timeDiff = end.getTime() - start.getTime();
            let dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
            if (dayDiff === 1 || dayDiff === 0) {
                exepandedTimers.push(timer);
            } else {
                for (let i = 1; i <= dayDiff + 1; i++) {
                    let baseTimer = { ...timer };

                    //if its the first one
                    if (i === 1) {

                        let date = new Date(baseTimer.startTime);
                        date.setHours(23, 59, 59, 0)
                        baseTimer.endTime = date.toISOString();
                        exepandedTimers.push(baseTimer)

                        continue;
                    }
                    // if its the last one
                    if (i > dayDiff) {

                        let date = new Date(baseTimer.endTime);
                        date.setHours(0, 0, 0, 0)
                        baseTimer.startTime = date.toISOString();
                        exepandedTimers.push(baseTimer)

                        continue;
                    }
                    // if  its not the first or last make a timer spanning entire day.
                    let date = new Date(timer.startTime);
                    date.setDate(date.getDate() + i - 1)

                    date.setHours(0, 0, 0, 0)
                    baseTimer.startTime = date.toISOString();

                    date.setHours(23, 59, 59, 0)
                    baseTimer.endTime = date.toISOString();

                    // push to storage object
                    exepandedTimers.push(baseTimer)

                }
            }

        }


        // calculate start/end points of main blocks and subdivisions
        for (let timer of exepandedTimers) {
            let start = timeToOffsetTime(new Date(timer.startTime))
            let end = timeToOffsetTime(new Date(timer.endTime))

            let startDay = start.getDay();
            let endDay = end.getDay();
            let startIndex = (start.getHours() * 60 / stepsize) + Math.floor(start.getMinutes() / stepsize);
            let endIndex = (end.getHours() * 60 / stepsize) + Math.floor(end.getMinutes() / stepsize);

            let subStart = Math.floor((((start.getHours() * 60) + start.getMinutes()) / minSpacing));
            let subEnd = Math.floor((((end.getHours() * 60) + end.getMinutes()) / minSpacing));

            indexedBlocks.push({
                dayInfo: { start: startDay, end: endDay },
                rowInfo: { startIndex: startIndex, endIndex: endIndex, subDivision: { start: subStart, end: subEnd } },
                entry: timer
            })

        }

        // subdived the row if needed, this will allow mutliple things to exist next to eachother instead of ontop
        for (let block of indexedBlocks) {
            let row = 1

            let start = block.rowInfo.subDivision.start + (minSpacing * block.rowInfo.startIndex + (Math.floor(1440 / stepsize) * minSpacing) * block.dayInfo.start);
            let end = block.rowInfo.subDivision.end + (minSpacing * block.rowInfo.endIndex + (Math.floor(1440 / stepsize) * minSpacing) * block.dayInfo.end);


            for (let _block of indexedBlocks) {
                // dont compare with itself
                if (block.entry.id === _block.entry.id) {
                    continue;
                }

                let _start = _block.rowInfo.subDivision.start + (minSpacing * _block.rowInfo.startIndex + (Math.floor(1440 / stepsize) * minSpacing) * _block.dayInfo.start);
                let _end = _block.rowInfo.subDivision.end + (minSpacing * _block.rowInfo.endIndex + (Math.floor(1440 / stepsize) * minSpacing) * _block.dayInfo.end);

                // only increment row if it woudent fit between existing blocks
                if (start >= _start && start <= _end && _block.rowInfo.row === row) {

                    row = row + 1;

                }

            }
            // set row of this block to the right offset
            block.rowInfo.row = row;
        }
        let divedBlocks = Object.values(indexedBlocks).reduce((acc, val) => {
            //console.log(acc, val)
            if (acc[val?.dayInfo?.start] === undefined) {
                acc[val?.dayInfo?.start] = []
            }
            acc[val?.dayInfo?.start].push(val);
            //console.log(acc, val)
            return acc;
        }, {});
        return divedBlocks;
    }
    function timeToOffsetTime(time, offsetMod = 0) {

        let timeOffset = new Date().getTimezoneOffset() * 1;
        return new Date(new Date(time).getTime() - timeOffset - (60000 * offsetMod));


        //return time
    }
    function padMinutes(minutes) {
        let hours = Math.floor(minutes / 60)

        minutes = minutes - (60 * hours)

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
    }

    // strapi functions
    //#region
    function deleteTimer(e) {
        e.preventDefault();

        // check for missInput
        if (!window.confirm("Weet je zeeker dat je dit blok wilt verwijderen?")) {
            return;
        }

        // delete entry and update the table view
        authAxios.delete("/time-registrations/" + e.target.id).then(() => {
            toast.success('Verwijderd.');
            reAquireTable();
        }).catch((e) => {
            toast.error('Er ging iets fout met het verwijderen.');

            console.error(e);
        });

    }
    //#endregion

    // events
    //#region
    function tableClick(e, id) {

        let updateAddTimer = addTimer;
        let parts = id.split("_")

        let totalMinutes = (Number(parts[0]) * Number(parts[2])) + ((Number(parts[2]) / Number(parts[4])) * Number(parts[3]));
        let time = padMinutes(totalMinutes)


        let dateOfCreation = timeToOffsetTime(new Date(weekSelector.isoWeekStart), -1)
        dateOfCreation.setDate(dateOfCreation.getDate() + Number(parts[1]) - 1)
        dateOfCreation.setHours(Math.floor(totalMinutes / 60))
        dateOfCreation.setMinutes(totalMinutes - (Math.floor(totalMinutes / 60) * 60))


        if (updateAddTimer?.startInfo === undefined) {
            updateAddTimer.startInfo = dateOfCreation
            updateAddTimer.startRowInfo = {
                row: Number(parts[0]),
                subrow: Number(parts[3]),
                day: Number(parts[1])
            }
        } else {
            updateAddTimer.endInfo = dateOfCreation
            updateAddTimer.endRowInfo = {
                row: Number(parts[0]),
                subrow: Number(parts[3]),
                day: Number(parts[1])
            }

        }

        setAddTimer({
            startInfo: updateAddTimer?.startInfo,
            endInfo: updateAddTimer?.endInfo,
            startRowInfo: updateAddTimer?.startRowInfo,
            endRowInfo: updateAddTimer?.endRowInfo
        })

        updateBlock();


    }
    function timerClicked(e, id, ref) {
        e.preventDefault();

        //console.log(e, id)
        if (e.detail != 2) return;
        console.log("Double Clicked")
        console.log(ref.position)
        let updatesubMenu = subMenu;
        updatesubMenu.idOfTimer = id;

        let elements = [];
        //style={{ left: e.clientX - 150, top: e.clientY }}
        elements.push(<div className="weekTable__subMenu">
            <Button>Aanpassen</Button>
            <Button>Verwijderen</Button>

        </div>)
        updatesubMenu.elements = elements
        setSubMenu(subMenu);
        updateBlock();

    }
    function timerHover(e, id) {
        e.preventDefault();

        let updatesubMenu = subMenu;
        updatesubMenu.idOfTimer = id;
        if (updatesubMenu.active) return;
        let elements = [];

        elements.push(<div onClick={(e) => { subMenuClicked(e, id) }} className="weekTable__subMenu_hover">
            <Icon name={'inspect'} className="weekTable__subMenu_hover_icon" />
        </div>)
        updatesubMenu.elements = elements
        // set subMenu info
        setSubMenu(subMenu);
        // update the info
        updateBlock();

    }
    function subMenuClicked(e, id) {
        e.preventDefault();
        e.stopPropagation()
        let updatesubMenu = subMenu;
        updatesubMenu.idOfTimer = id;
        updatesubMenu.active = true;
        let elements = [];
        setEditTimer({
            id: id,
        });

        elements.push(<div onMouseOver={(e) => { timerHover(e, id) }} className="submenuActive">
            <Icon name={'inspect'} className="weekTable__subMenu_hover_icon" />
            <Button onClick={(e) => { e.stopPropagation(); openPopup('edit-timer') }}>Aanpassen</Button>
            <Button id={id} onClick={(e) => { e.stopPropagation(); deleteTimer(e) }}>Verwijderen</Button>
        </div>)
        updatesubMenu.elements = elements
        // set subMenu info
        setSubMenu(subMenu);
        // update the info
        updateBlock();
    }
    function clearSubmenu(e) {

        let updatesubMenu = subMenu;

        updatesubMenu.idOfTimer = undefined;
        updatesubMenu.active = false;
        updatesubMenu.elements = [];

        // set subMenu info
        setSubMenu(subMenu);
        // update the info
        updateBlock();
    }
    function reAquireTable(e) {
        getDatesFromCurrentWeek();
        //updateBlock();

    }
    //#endregion
    return (<>
        <Block name="weekTable">
            {variableBlock}
        </Block>

    </>)
}
