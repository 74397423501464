import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { publicFetch } from '../Util/fetch';
import { AuthContext } from './AuthProvider';
import { Spinner } from 'UI/App/Components/Spinner';

function StrapiAuthenticator() {
    const [authenticationState, setAuthenticationState] = useState('Authenticating...');
    const [authFailed, setAuthFailed] = useState(false);

    // get the search params from the URL
    const [searchParams] = useSearchParams();

    localStorage.removeItem('MSDisabled');
    // make it clear this is NOT a terminal user
    localStorage.setItem("isTerminalUser", "false");

    // get an instance of the auth provider which will be used to set the current auth state after authentication
    const { setAuthInfo, isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        ValidateMSAccessToken(searchParams, setAuthInfo, isAuthenticated)
            .then((result) => {
                // Redirect to the home page
                // get last know path
                let lastKnowRoute = localStorage.getItem('lastRoute');

                // check if we have anything to work with
                if (lastKnowRoute?.length === undefined || lastKnowRoute.length === 0) {
                    lastKnowRoute = '/';
                }

                // if were login in via a popup close the popup.
                if (window?.opener && window.opener !== window && window.name === 'Re-login') {
                    // close this window after 2 seconds, the delay is here so the user can follow whats happening
                    setTimeout(() => {
                        if (window.opener.closePopup) {
                            window.opener.closePopup();
                        } else {
                            window.close();
                        }
                    }, 2000);
                } else {
                    // go to last know path
                    window.location.href = `${lastKnowRoute}`;
                }
            })
            .catch((reason) => {
                console.error(reason);
                setAuthenticationState(reason);
                setAuthFailed(true);
            });
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                width: '100vw',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <span
                style={{
                    marginBottom: '20px'
                }}
            >
                {authenticationState}
            </span>
            {!authFailed && <Spinner />}
            {authFailed && <button onClick={() => (window.location.href = '/logout')}>Uitloggen</button>}
        </div>
    );
}

async function ValidateMSAccessToken(searchParams, setAuthInfo, isAuthenticated) {
    // check if the user is already authenticated
    if (isAuthenticated() && window?.opener === null) {
        return Promise.resolve(true);
    }

    // get the microsoft access token
    const msAccessToken = searchParams.get('access_token');
    const msRefreshToken = searchParams.get('refresh_token');

    // get an access token from strapi using the microsoft access token
    return await publicFetch
        .get('/auth/microsoft/callback?populate=*', {
            params: {
                access_token: msAccessToken
            }
        })
        .then(async ({ status, data }) => {
            // make sure the response was 'OK'
            if (status === 200) {
                // get the access token from the response body
                const accessToken = data.jwt;
                // decode the jwt to get the expiration
                const expiresAt = getExpirationTimeFromJWT(accessToken);

                // try to update and get the user details through the Microsoft API. if the response is empty, use the user details from the authentication request.
                const microsoftUpdateResponse = await updateAndGetUserUsingMS(msAccessToken, accessToken);

                // add a fallback role of 'Guest' to the user in case the update endpoint failed
                const user =
                    microsoftUpdateResponse === null
                        ? {
                              ...data.user,
                              role: {
                                  name: 'Guest',
                                  type: 'guest',
                                  description:
                                      "A guest can't really do anything and should be given a new role by an admin"
                              }
                          }
                        : microsoftUpdateResponse;

                const updateResult = await getAndSetProfilePictureFromMicrosoft(msAccessToken, accessToken, user);

                // create an empty variable for the updated user information
                let updatedUser;

                // make sure the update succeeded
                if (updateResult !== false && typeof updateResult['updatedUser'] === 'object') {
                    updatedUser = updateResult['updatedUser'];
                } else {
                    updatedUser = user;
                }

                const authInfo = {
                    token: accessToken,
                    expiresAt: expiresAt,
                    user: updatedUser,
                    msToken: msAccessToken,
                    msRefreshToken: msRefreshToken,
                    msTokenExpiresAt: getExpirationTimeFromJWT(msAccessToken)
                };

                setAuthInfo(authInfo);

                return authInfo;
            }
        })
        .catch((result) => {
            return Promise.reject('Access token aanvraag mislukt. Probeer opnieuw in te loggen.');
        });

    /**
     * Updates the user details through the Microsoft API.
     * Returns the updated user details or null if the request didn't succeed.
     * @param {string} msAccessToken
     * @param {string} strapiAccessToken
     * @returns { Promise<object | null> }
     */
    async function updateAndGetUserUsingMS(msAccessToken, strapiAccessToken) {
        return publicFetch
            .get('/users/me/update-from-microsoft', {
                params: {
                    access_token: msAccessToken
                },
                headers: {
                    Authorization: 'Bearer ' + strapiAccessToken
                }
            })
            .then(({ data }) => data)
            .catch((exception) => {
                process.env.NODE_ENV === 'development' && console.error(exception);
                return null;
            });
    }

    /**
     * Retrieves a new access_token & refresh_token for use with the Graph api
     * @param {string} msRefreshToken
     * @param {string} strapiAccessToken
     * @returns { object | null }
     */
    async function getGraphToken(msRefreshToken, strapiAccessToken) {
        return await publicFetch
            .get('/auth/refresh-graph-api', {
                params: {
                    refresh_token: msRefreshToken
                },
                headers: {
                    Authorization: 'Bearer ' + strapiAccessToken
                }
            })
            .then(({ data }) => data)
            .catch((exception) => {
                console.error(exception);
                return { access_token: null, refresh_token: null, expires_in: null };
            });
    }

    /**
     * Retrieves a new profile picture from Microsoft
     * @param msToken
     * @param strapiAccessToken
     * @returns {Promise<object|false>}
     */
    async function getAndSetProfilePictureFromMicrosoft(msToken, strapiAccessToken, user) {
        return publicFetch
            .get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
                headers: {
                    Authorization: 'Bearer ' + msToken
                },
                responseType: 'arraybuffer'
            })
            .then(async (response) => {
                // create new form data object for sending when uploading
                const formData = new FormData();
                // create blob of the image and append to the formdata
                const image = new Blob([response.data], { type: 'image/jpeg' });
                formData.append('files', image, `profile-picture-${user.id}-${Date.now()}`);

                // upload the file
                return await publicFetch
                    .post('/upload', formData, {
                        headers: {
                            Authorization: 'Bearer ' + strapiAccessToken,
                            'Content-Type': response.data.type
                        }
                    })
                    .then(async ({ data }) => {
                        const imageId = data[0].id;
                        // set the new image
                        return await publicFetch
                            .put(
                                '/users/me/update',
                                { profileImage: imageId },
                                {
                                    headers: {
                                        Authorization: 'Bearer ' + strapiAccessToken
                                    }
                                }
                            )
                            .then(({ data }) => {
                                return data;
                            })
                            .catch((reason) => {
                                // Unable to update the users profile. This is most likely because of insufficient permissions.
                                return false;
                            });
                    })
                    .catch((reason) => {
                        // Unable to upload a new file. This is most likely because of insufficient permissions.
                        return false;
                    });
            })
            .catch((reason) => {
                // Unable to fetch the profile picture from Microsoft. This can have plenty of reasons, but the most common one is that the user never uploaded profile picture.
                // In this case, we will simply return false. There is no reason to log this exception since the application can run perfectly fine without it.
                return false;
            });
    }
}

/**
 * Returns the jwt expiration timestamp.
 * Returns 0 if the exp parameter couldn't be found
 * @param {string} JWT
 * @returns {number}
 */
export function getExpirationTimeFromJWT(JWT) {
    return JSON.parse(window.atob(JWT.split('.')[1])).exp ?? 0;
}

export { StrapiAuthenticator };
