import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import PersonDetailItem from './PersonDetailItem'
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';
import Button from 'UI/App/Components/Button/Button';
import Icon from 'UI/App/Components/Icon/Icon';
import {toast} from 'react-toastify';
import capitalize from 'lodash/capitalize';
import {useContext, useEffect, useState} from 'react';
import {FetchContext} from 'App/Strapi/FetchContext';
import {formatName} from "App/Util/format";

export default function PersonDetails(data) {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    // get the params from the url
    const params = useParams();

    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    const [email, setEmail] = useState([]);
    const [emailVisible, setEmailVisible] = useState(false);

    const [telephone, setTelephone] = useState([]);
    const [telephoneVisible, setTelephoneVisible] = useState(false);

    // get the company data from the data object
    const company = data?.attributes ?? {};

    const [tagGroups, setTagGroups] = useState([]);
    useEffect(() => {
        authAxios.get(`/config/crm`, {
            params: {
                populate: "*"
            }
        }).then(({ data: { data } }) => {
            setTagGroups(() => {
                return (data?.contactPersonTags ?? []).map((tagGroup) => {
                    return tagGroup.groupName;
                });
            });

        });

        if (data.data.email !== undefined && data.data.email !== null) {
            setEmailVisible(true);
            setEmail(prevState => [...prevState, <>Zakelijk : <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + data.data.email} target='_blank' rel='noreferrer'>{data.data.email}</a></>])
        }

        if (data.data.personalEmail !== undefined && data.data.personalEmail !== null) {
            setEmailVisible(true);
            setEmail(prevState => [...prevState, <>Privé : <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + data.data.personalEmail} target='_blank' rel='noreferrer'>{data.data.personalEmail}</a></>])
        }

        if (data.data?.telephone !== undefined && data.data?.telephone !== null) {
            setTelephoneVisible(true);
            setTelephone(prevState => [...prevState, <>Zakelijk : <a href={`tel: +${data.data.telephone.dialingCode} ${data.data.telephone.telephoneNumber}`}>+{data.data.telephone.dialingCode} {data.data.telephone.telephoneNumber}</a></>])
        }
        if (data.data?.personalCellPhone !== undefined && data.data?.personalCellPhone !== null) {
            setTelephoneVisible(true);
            setTelephone(prevState => [...prevState, <>Privé : <a href={`tel: +${data.data.personalCellPhone.dialingCode} ${data.data.personalCellPhone.telephoneNumber}`}>+{data.data.personalCellPhone.dialingCode} {data.data.personalCellPhone.telephoneNumber}</a></>])
        }
        if (data.data?.mobilePhone !== undefined && data.data?.mobilePhone !== null) {
            setTelephoneVisible(true);
            setTelephone(prevState => [...prevState, <>Mobiel : <a href={`tel: +${data.data.mobilePhone.dialingCode} ${data.data.mobilePhone.telephoneNumber}`}>+{data.data.mobilePhone.dialingCode} {data.data.mobilePhone.telephoneNumber}</a></>])
        }
    }, [])

    function deleteContactPerson(id) {
        if (window.confirm("Weet u zeker dat u deze contactpersoon wilt verwijderen?")) {
            // delete the contact person
            authAxios.delete(`/crm/companies/persons/${id}`).then(() => {
                toast.success('Contactpersoon is verwijderd!');

                navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`);
            }).catch((exception) => {
                toast.error('Contactpersoon kon niet verwijderd worden!');
                console.error(exception);
            });
        }
    }

    return (
        <Block
            name='details'
            title={<>
                <Link className={'btn btn--transparent'} to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`}><Icon name={'arrow-left'} /></Link>
                {`${(data.data.title ?? '')} ${formatName(data.data.firstName, data.data.prefixToSurname, data.data.surname)} ${(data.data.titleAfter ?? '')} ${(!/^\W+$/.test(data.data.function) ? `(${data.data.function})` : '')}`.replaceAll('  ', ' ').trim()}
            </>}
            headerRightSideChildren={
                <div className="company__list-actions">
                    <Button to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}/contactpersons/${params.contactPersonId}/update`} className='btn btn--round btn--black'><Icon name={'pencil'} /></Button>
                    <Button onClick={() => deleteContactPerson(params.contactPersonId)} className="btn--round btn--black"><Icon name='trash-can' /></Button>
                </div>
            }
        >
            <Grid columns={data?.data?.tags ? 2 : 1}>
                <div key='1'>
                    <PersonDetailItem title='BENADERING' display={capitalize(data.data.approach)} content={[
                        <p>{capitalize(data.data.approach)}</p>
                    ]} />

                    {data.data?.address !== null && data.data?.address !== undefined && (
                        <PersonDetailItem title='ADRES' display={company.data?.address} content={[
                            data.data?.address?.addressLine1,
                            data.data?.address?.addressLine2,
                            data.data?.address?.addressLine3,
                            `${data.data?.address?.postalCode} ${data.data?.address?.city}`,
                            data.data?.address?.province,
                            data.data?.address?.country
                        ]} />
                    )}

                    <PersonDetailItem style={{ overflowWrap: "break-word" }} title='EMAIL' display={emailVisible} content={email} />

                    <PersonDetailItem title='TELEFOONNUMMER' display={telephoneVisible} content={telephone} />
                </div>
                {data?.data?.tags &&
                    <div key='2'>
                        {Object.keys(data?.data?.tags ?? {}).map((tagGroup) => {
                            return tagGroups.includes(tagGroup) && <PersonDetailItem key={`tags__${tagGroup}`} title={tagGroup} display={data.data.tags} content={
                                // check if tagGroup values = string or array
                                (Array.isArray(data?.data?.tags[tagGroup]) ? [...data?.data?.tags[tagGroup]] : [data?.data?.tags[tagGroup]]).map((tag, key) => {
                                    return <p key={key}>{tag}</p>
                                })
                            } />
                        })}
                    </div>
                }
            </Grid>
        </Block>
    )
}
