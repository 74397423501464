import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {stringify} from "qs";
import {FetchContext} from "../../../../../../../App/Strapi/FetchContext";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import {closePopup} from "../../../../../Components/Popup/Popup";
import ProjectRepository from "../../../Projects/ProjectRepository";


export default function FromConfirmation() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    const [confirmations, setConfirmations] = useState([]);
    const [confirmation, setConfirmation] = useState(null);
    const [knowConfirmations, setKnowConfirmations] = useState([]);
    const [reference, setReference] = useState(null)


    // Use effect for loading data
    useEffect(() => {
        load();
    }, []);
    const params = stringify({
        populate: {
            quote: {
                populate: {
                    company: true,
                    description: true,
                    calculations: true
                }
            }
        }
    })
    async function load() {
        // Load confirmations
        authAxios.get(`/quotes/confirmations/all?${params}&fields=number&sort=number:desc`)
            .then(({ data }) => {
                let tableData = [];

                for (const confirmation of data) {

                    tableData.push({
                        label: `${confirmation.number} - ${confirmation.quote.company.name} - ${confirmation.quote.description}`,
                        value: confirmation.id,
                        reference: confirmation?.quote?.calculations[0]?.data?.checklist?.find(item => ["customerReference", "Your reference"].includes(item.name))?.value ?? ""
                    })
                }

                setConfirmations(tableData);
                setKnowConfirmations(data.data)
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })
    }

    const getShippingData = (data) => {
        const shippingData = {
            total: data.data.chosenAmount,
            boxes: null,
            pallets: null
        }

        // If undefined return empty shipping
        if (data?.data?.calculation?.data?.templateData?.data === undefined) {
            return shippingData
        }

        // Loop machines until 'Verpakken' is found
        for (const [machineKey, machine] of Object.entries(data?.data?.calculation?.data?.templateData?.data)) {
            if (machine.name !== undefined) {
                if (machine.name.toLowerCase() === 'verpakken') {
                    // Loop widgets until total material is found
                    for (const widget of machine.widgets) {
                        if (widget.data.title.toLowerCase().includes('aantal') && widget.data.title.toLowerCase().includes('materiaal')) {
                            // Get value from json
                            shippingData.boxes = data?.data?.calculation.data.calculationData[widget.data.title][data.data.chosenAmount];
                        }
                    }
                }
            }
        }

        return shippingData;
    }

    async function createOrder() {
        const params = stringify({
            populate: {
                quote: {
                    populate: {
                        company: true,
                        contactPerson: true,
                        calculations:true,
                    }
                }
            }
        })

        // let chosenConfirmation = knowConfirmations.filter((el) => el.number === confirmation.label)[0];
        //
        // let quoteData = {
        //     id: [chosenConfirmation.quote.id],
        //     number: [chosenConfirmation.quote.number],
        //     calculations: [chosenConfirmation.calculation]
        // }
        //
        // let project = await new ProjectRepository(null, authAxios).addProjectOnQoute(null, quoteData);

        // Create new project
        let project = await new ProjectRepository(null, authAxios).createProject(null)
        let orderIds = [];
        let calculationIds = [];

        // Load confirmations
        const createdConformation = await authAxios.get(`/quotes/confirmations/${confirmation.value}?${params}&fields=number&sort=number:desc`)
            .then(({ data }) => {

                // add confirmation to project
                new ProjectRepository(project, authAxios).addConfirmationQuoteToProject(data.data.id);

                let description = ''
                if (data.data.quote.description !== null && data.data.quote.description !== undefined) {
                    description = data.data.quote.description
                }

                // Create order for calculations
                for(const calculation of data.data.quote.calculations){
                    // Gather calculations ids to add to project
                    calculationIds.push(calculation.id);

                    let json = {
                        data: {
                            description: description,
                            customerReference: reference,
                            shipping: getShippingData(data),
                            status: 'binnengekomen',
                            chosenAmount: data.data.chosenAmount,
                            calculation: calculation.id,
                            company: data.data.quote.company,
                            contactPerson: data.data.quote.contactPerson,
                            quoteConfirmation: data.data.id,
                            quote: data.data.quote.id
                        }
                    }

                    authAxios.post(`/orders`, json).then(({ data }) => {
                        console.log(data);
                        toast.success(`Order ${data.data.number} aangemaakt!`);
                    }).catch((exception) => {
                        console.error(exception);
                        toast.error(`Er ging iets mis met het opslaan van een order!`);
                    });
                }

                return data;
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                console.error(exception);
            })

        console.log(createdConformation,calculationIds,orderIds);
        // Add projects
        new ProjectRepository(project, authAxios).addCalculationsToProject(calculationIds);

        // Add orders to project
        new ProjectRepository(project, authAxios).addOrdersToProject(orderIds);

        closePopup('addOrder')
        navigate(`/orders/confirmation/${createdConformation.data.id}`);
    }

    return (
        <>
            {/*<Block name="calculatiie">*/}
            Bevestiging:
            <Select
                name='type'
                id='type'
                isClearable={false}
                options={confirmations}
                value={confirmation}
                onChange={(confirmation) => {
                    setConfirmation(confirmation);
                    setReference(confirmation.reference)
                }}
                required
            />
            Klant referentie:
            <input
                name='reference'
                type='text'
                value={reference}
                onChange={(reference) => {
                    setReference(reference.target.value)

                }}
            />

            <br />
            <button onClick={() => createOrder()} disabled={confirmation === null}>
                Opslaan
            </button>
            {/*</Block>*/}
        </>
    )
}
