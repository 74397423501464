import {useContext, useEffect, useState} from "react"
import {Link, useLocation} from "react-router-dom"
import {AuthContext} from "App/Strapi/AuthProvider"
import {ConfigContext} from 'App/Strapi/ConfigContext';
import {GetPageTitleByPath} from "../../AppShell"
import Icon from "UI/App/Components/Icon/Icon";
import './header.scss';
import {FetchContext} from "App/Strapi/FetchContext";
import PopupReLogin from "UI/App/Components/Popup/Popups/ReLoginPopup";
import { LanguageSelector } from "App/Util/translate/translate";

// const _TICK_COUNTER_MAX = 20;
// const _TICK_COUNTER_INACTIVE_THRESHOLD = 15;
// const _TICK_COUNTER_TIMEOUT = 1000;

export default function Header({ title }) {
	const authContext = useContext(AuthContext);
	const configContext = useContext(ConfigContext);
	const { authAxios } = useContext(FetchContext);

	const locationParams = useLocation();
	const [bread, setBread] = useState([]);
	const [crumbPath, setCrumbPath] = useState([]);
	// list with exceptions (this will only replace the last of 2 back to back copy's)
	const [crumbNameExceptions] = useState({
		'Leveranciers': 'Leverancier',
	});
	const [profileImage, setProfileImage] = useState();

	let userRole = '';

	useEffect(() => {
		// on url change make crumbs
		makeCrumbs();

		// Get the application name
		const applicationName = process.env.REACT_APP_APPLICATION_NAME ?? 'Emily';

		let name = GetPageTitleByPath(locationParams);
		// set title, Emily is default
		if (name === undefined) {
			return;
		}

		setTitle(applicationName + ' | ' + (name === 'Emily' ? 'Home' : name));

	}, [locationParams]);

	if (authContext.authState.user.role !== undefined) {
		userRole = <span className="header__account-role">{authContext.authState.user.email}</span>;
	}

	useEffect(() => {

		const profileImage = authContext.authState.user.profileImage;

		if (profileImage?.id !== undefined) {
			authAxios.get(`../download/files/${profileImage.id}`, {
				responseType: 'arraybuffer'
			}).then((response) => {
				if (profileImage.ext === '.svg') {
					const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/svg+xml' }));
					setProfileImage(url);
					return;
				}

				const url = window.URL.createObjectURL(new Blob([response.data]));
				setProfileImage(url);
			});
		}

		// // add an event listener to look for mouse activity
		// window.addEventListener('mousemove', mouseMoveHandler);
		//
		// // clear old/existing one
		// return (() => {
		// 	window.removeEventListener('mousemove', mouseMoveHandler);
		// })
	}, []);

	return (
		<header className='header'>
			<div className="header__title-wrap">
				{crumbPath}
			</div>

			<div className="header__account-card">
				<LanguageSelector />
				{profileImage && <img alt={'profile-picture'} className="header__account-picture" src={profileImage} />}
				{!profileImage && <span className={"header__account-picture"}>{authContext?.authState?.user?.username.slice(0, 1)}</span>}
				<p className="header__account-info notranslate">
					<span className="header__account-name">{authContext?.authState?.user?.username}</span>
					{userRole}
				</p>
				<Link className={'btn btn--transparent'} to={'/logout'}><Icon name={'sign-out'} /></Link>
			</div>
			<PopupReLogin />
		</header>
	)

	function makeCrumbs() {
		let newBread = bread;
		newBread.lastRoute = newBread.route === undefined ? "" : newBread.route;
		newBread.route = locationParams.pathname;

		// save last route in session storage so we can use on other locations (error handeling)
		sessionStorage.setItem('lastRoute', newBread.lastRoute);
		localStorage.setItem('lastRoute', newBread.route);

		// get path data from session storage, this allowes path to funtion after refresh
		let crums = sessionStorage.getItem('crums') === null ? [] : JSON.parse(window.sessionStorage.getItem('crums'));

		// get traversd paths from session storage
		let knowCrums = sessionStorage.getItem('knowCrums') === null ? [] : JSON.parse(window.sessionStorage.getItem('knowCrums'));
		// Get all parts of path
		let routeArray = locationParams.pathname.split("/");

		if (locationParams.pathname === '/') {
			routeArray = ['dashboard'];
		}

		// array to store the identifiers
		let identifiers = []
		// Get all parts of path without indentifiers
		let correctedRoute = routeArray.map(function (part) {

			if (part !== "" && !isNaN(part)) {

				identifiers.push(part);
				return ":id"
			} else {
				return part
			}
		});

		// make a string out of array for checks later on
		let completeCorrectedRoute = correctedRoute.join("/");

		// remove un-needed slash from crum
		if (completeCorrectedRoute.length > 1 && completeCorrectedRoute.endsWith('/')) {
			completeCorrectedRoute = completeCorrectedRoute.substring(0, completeCorrectedRoute.length - 1)
		}

		knowCrums.push(completeCorrectedRoute);
		knowCrums = [...new Map(knowCrums.map(v => [v, v])).values()]


		// if we are NOT on the home page create crums
		if (completeCorrectedRoute !== "/") {
			knowCrums.map(function (crum) {

				// only do something with crums related to current path
				if (completeCorrectedRoute.startsWith(crum) && crum !== "/") {

					// split knowcrum
					let crumParts = crum.split('/');

					// keep track at which identifier we need
					let identifierIndex = 0;

					// text parts
					let crumNames = [];

					// replace text id with actual id
					let usableCrum = crumParts.map(function (part) {
						if (part === ":id") {

							let cleanPart = part.replace(":id", "") + identifiers[identifierIndex]
							identifierIndex++
							return cleanPart
						} else {
							crumNames.push(part);
							return part
						}
					}).join("/");

					// get last page in url
					let urlName = crumNames.filter(x => isNaN(x)).pop();

					// set first Letter to upper
					urlName = urlName.charAt(0).toUpperCase() + urlName.slice(1);

					// get name of based on url, switch for translated word if possible
					let name = GetPageTitleByPath({ pathname: usableCrum }, true) === "Emily" ? urlName : GetPageTitleByPath({ pathname: usableCrum }, true);

					// if name is undefined go with UrlName
					name = name === undefined ? urlName : name;

					// store crum
					crums.push({ crumb: usableCrum, topping: name, filterdpath: crum });
				}
			});

		} else {
			// reset crums to an empty array
			crums = []
		}

		// enforce uniq urls, will remove duplicates
		crums = [...new Map(crums.map(v => [v.filterdpath, v])).values()]


		// enforce that all parts come from the same 'tree', will remove parts from diffrent routes/later on route
		crums = crums.filter(function (part) {
			if (completeCorrectedRoute.startsWith(part.filterdpath)) {
				return part
			}
		});

		// save crums in session storage
		sessionStorage.setItem('crums', JSON.stringify(crums));
		sessionStorage.setItem('knowCrums', JSON.stringify(knowCrums));
		// create elements
		newBread.elements = createCrumbPath(crums)

		// remove current page from path.
		// if (newBread.elements.length > 1) {
		// 	newBread.elements.length = newBread.elements.length - 1;
		// }

		// set needed usestated
		setBread(newBread);
		setCrumbPath(newBread.elements);
	}

	function createCrumbPath(newBakery) {
		let crumbs = [];

		// home page
		crumbs.push(<Link key={'home-link'} to={"/"} title={"Ga terug naar het dashboard"} className={'header__breadcrumb emily notranslate'}>Emily</Link>);
		// add a link for every page traveld
		newBakery.forEach((slice, index) => {

			// small check if to locations have the same name, only checks between next and current crumb.
			// only replaces name if we have something to replace it with
			if (newBakery[index + 1] !== undefined && newBakery[index].topping === newBakery[index + 1].topping && crumbNameExceptions[newBakery[index + 1].topping] !== undefined) {
				// replace last duplicate with exception.
				newBakery[index + 1].topping = crumbNameExceptions[newBakery[index + 1].topping]
			}
			crumbs.push(<Link key={index} to={slice.crumb} title={slice.crumb} className={'header__breadcrumb'}><Icon name='angle-right' className='header__breadcrumb-svg' /> {slice.topping}</Link>);

		});
		return crumbs;
	}

	// set title
	function setTitle(title) {
		document.title = title;
	}
}
