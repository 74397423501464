import Block from "UI/App/Components/Block/Block";
import Grid from "UI/App/Components/Grid/Grid";
import {Fragment, useEffect, useState} from "react";
import './layourEditor.scss';

export default function LayoutEditor({availableWidgets, layout, setLayout}) {

    const [content, setContent] = useState(<>Laden..</>);
    const [showRowCreation, setShowRowCreation] = useState(null);

    useEffect(() => {
        renderContent();
    }, [availableWidgets, showRowCreation, layout]);


    // Adds row at given position to layout
    function addRow(rowKey, amountRowsToAdd) {
        // Make copy for changing layout
        let layoutCopy = [...layout];

        // Add amount of columns needed
        let columnsToAdd = [];
        for (let i = 0; i < amountRowsToAdd; i++) {
            columnsToAdd.push('')
        }

        // Add columns to layout
        layoutCopy.splice(rowKey + 1, 0, columnsToAdd);

        // Update layout state
        setLayout(layoutCopy);

        // Close layout addition form
        setShowRowCreation(null);
    }

    function removeRow(rowKey){
        let layoutCopy = [...layout];

        layoutCopy.splice(rowKey, 1)

        setLayout(layoutCopy);
    }

    function renderRow(row,rowKey){
        let selects = [];

        let colCount = 0;
        selects.push(row.map(function (col) {
            colCount++;

            return renderSelect(col,rowKey,colCount - 1)
        }))

        return <>
            {selects}
            <button type="button" onClick={() => {
                if (showRowCreation === rowKey) {
                    setShowRowCreation(null)
                } else {
                    setShowRowCreation(rowKey)
                }
            }}>
                {rowKey === showRowCreation ? '-' : '+'}
            </button>
            <button type="button" className="btn--error" onClick={(e) => {
                removeRow(rowKey)
            }}> -
            </button>
        </>
    }

    function renderSelect(selectedValue, rowKey, colKey) {
        // Create array for storing options
        let options = []

        // If available widgets object is empty stop
        if (Object.keys(availableWidgets).length === 0) return <>
            <select></select>
        </>

        // Loop available widgets to create select
        for (const [key, widget] of Object.entries(availableWidgets)) {
            const machineName = document.getElementById(`tablink_${widget.parentId}`).querySelector('span').innerHTML;

            options.push(<option key={`${widget.prefix}${widget.id}`} value={`${widget.prefix}${widget.id}`} selected={`${widget.prefix}${widget.id}` === selectedValue}>{machineName} | {widget.data.title}</option>)
        }

        return <>
            <select onChange={(e) => updateLayout(e,rowKey,colKey)}>
                {options}
            </select>
        </>
    }

    function updateLayout(e,rowKey,colKey){
        let layoutCopy = [...layout];

        layoutCopy[rowKey][colKey] = e.target.value;

        setLayout(layoutCopy);
    }

    function renderContent() {
        if (layout.length === 0) {
            setContent(<Block title="Vormgeving" name="layoutEditor">
                <>
                    <Grid columns={4}>
                        <span className="gridSelector" onClick={() => addRow(0, 1)}>
                            <Grid columns={1} gap={'unset'}>
                                <span className="gridColumn"></span>
                            </Grid>
                        </span>
                        <span className="gridSelector" onClick={() => addRow(0, 2)}>
                            <Grid columns={2} gap={'unset'}>
                                <span className="gridColumn"></span>
                                <span className="gridColumn"></span>
                            </Grid>
                        </span>
                        <span className="gridSelector" onClick={() => addRow(0, 3)}>
                            <Grid columns={3} gap={'unset'}>
                                <span className="gridColumn"></span>
                                <span className="gridColumn"></span>
                                <span className="gridColumn"></span>
                            </Grid>
                        </span>
                        <span className="gridSelector" onClick={() => addRow(0, 4)}>
                            <Grid columns={4} gap={'unset'}>
                                <span className="gridColumn"></span>
                                <span className="gridColumn"></span>
                                <span className="gridColumn"></span>
                                <span className="gridColumn"></span>
                            </Grid>
                        </span>
                    </Grid>
                </>
            </Block>)
            return
        }

        setContent(<Block title="Vormgeving" name="layoutEditor">
            <>
                {layout.map(function (row, rowKey) {
                    return <Fragment key={rowKey}>
                        <Grid columns={row.length + 1} customColTemplate={'1fr '.repeat(row.length) + '35px 35px'}>
                            {renderRow(row,rowKey)}
                        </Grid>
                        <span style={{display: rowKey === showRowCreation ? 'block' : 'none'}}>
                            <Grid columns={4}>
                                <span className="gridSelector" onClick={() => addRow(rowKey, 1)}>
                                    <Grid columns={1} gap={'unset'}>
                                        <span className="gridColumn"></span>
                                    </Grid>
                                </span>
                                <span className="gridSelector" onClick={() => addRow(rowKey, 2)}>
                                    <Grid columns={2} gap={'unset'}>
                                        <span className="gridColumn"></span>
                                        <span className="gridColumn"></span>
                                    </Grid>
                                </span>
                                <span className="gridSelector" onClick={() => addRow(rowKey, 3)}>
                                    <Grid columns={3} gap={'unset'}>
                                        <span className="gridColumn"></span>
                                        <span className="gridColumn"></span>
                                        <span className="gridColumn"></span>
                                    </Grid>
                                </span>
                                <span className="gridSelector" onClick={() => addRow(rowKey, 4)}>
                                    <Grid columns={4} gap={'unset'}>
                                        <span className="gridColumn"></span>
                                        <span className="gridColumn"></span>
                                        <span className="gridColumn"></span>
                                        <span className="gridColumn"></span>
                                    </Grid>
                                </span>
                            </Grid>
                        </span>
                    </Fragment>
                })}
            </>
        </Block>)
    }

    return (
        <>
            {content}
        </>
    )
}
