export default class OrderTimerRepository {
    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getMyRunningTimers() {
        return await this.axiosInstance
            .get('/orders/timers/mine/running')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error);
                throw error;
            });
    }
}