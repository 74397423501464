export { }

declare global {
    interface Console {
        prodLog: any,
    }
}

// store the console.log in an object, so we can use for function that need it outside development
const consoleLogObject = console.log;

// if were on production console log shouldn't be allowed
if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
}

// if we need to log on production we need to do it via this function
console.prodLog = consoleLogObject.bind((...data: any[]) => {
    consoleLogObject(...data);
});


