
import {useEffect, useState} from "react";
import Select from "react-select";
import Block from "../../../../Components/Block/Block";
import FromCalculation from "./Create/FromCalculation";
import FromConfirmation from "./Create/FromConfirmation";
import FromQuote from "./Create/FromQuote";

export default function CreateOrder({ companyId = undefined }) {
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);

    useEffect(() => {
        setTypes([
            {
                value: 'Calculation',
                label: 'Calculatie'
            },
            {
                value: 'Quote',
                label: 'Offerte'
            },
            {
                value: 'Confirmation',
                label: 'Bevestiging'
            }
        ])
    }, []);


    return (
        <>
            <Block name={'Order toevoegen'}>
                Waar van wilt u een order maken?
                <Select
                    name='type'
                    id='type'
                    isClearable={false}
                    options={types}
                    value={type}
                    onChange={(type) => {
                        setType(type);
                    }}
                    required
                />
                {type?.value === 'Calculation' ? <FromCalculation companyId={companyId} /> : ''}
                {type?.value === 'Confirmation' ? <FromConfirmation /> : ''}
                {type?.value === 'Quote' ? <FromQuote /> : ''}
            </Block>
        </>
    )
}
