import React, {ReactElement} from "react";
import IWidget from "./IWidget";
import WidgetBase from "./WidgetBase";
import {TrenderType} from "./WidgetTypes";

export default class IntWidget extends WidgetBase implements IWidget {
    callback: ((object: Object) => any);
    widgetData: String | null
    saveWidget: ((object: Object) => any);
    optionalWidgetData: object | undefined;
    debug: boolean;

    prefix: string | undefined;

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;
    }

    /**
     * Returns react element for preview
     */
    getPreviewHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        const widgetData = {
            widgetType: 'Int',
        }


        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>
            Vaste waarde
        </div>
    }

    /**
     * Handles the form submit adds data to saveWidget function
     * @param event
     * @private
     */
    handleSubmit(event: any, parentClass: any): void {
        event.preventDefault();

        const data = {
            type: "Int",
            value: event.target.elements.number.value,
            title: event.target.elements.title.value
        };

        parentClass.addWidgetToWidgetContainer("Vaste waarde: " + data?.title + " <br> " + data.value, data);
    }

    /**
     * returns form react element
     */
    public getFormHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {

        return <form onSubmit={(e) => this.handleSubmit(e, this)}><h3>Vaste waarde</h3>
            <div className='input-group'>
                Naam: <input type="text" name="title"/>
            </div>
            <div className='input-group'>
                Waarde: <input type="number" name="number" step="0.0000000001"/>
            </div>
            <button type="submit">Opslaan</button>
        </form>
    }

    /**
     * Returns react element with badge html
     */
    getBadgeHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return <div>intbadge html:</div>
    }

    /**
     * Renders calculation form
     */
    public getCalculationForm(): ReactElement {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;

        const widgetContent = `<div className="calculationWidget">
            <label className="hidden" id="label_${id}" htmlFor="${id}">[${id}] Vaste waarde: ${this.optionalWidgetData.data.title} = ${this?.optionalWidgetData?.data?.value}</label>
            <input key=${Date.now()} id="${id}" type="hidden" name="${this?.optionalWidgetData?.data?.title}" value="${this?.optionalWidgetData?.data?.value}"/>
        </div>`
        this.addWidgetToCalculationForm(widgetContent,this.optionalWidgetData, this.optionalWidgetData.parentId)
        // return <div className={"calculationWidget"}>
        //     <label className={"hidden"} id={"label_" + id} htmlFor={id}>{this.optionalWidgetData.id}|{this.optionalWidgetData.data.title}</label>
        //     <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.data?.title} value={this?.optionalWidgetData?.data?.value}/><br/>
        // </div>
    }

    /**
     * Renders preview form
     */
    public getPreviewFormHTML(): ReactElement {
        return <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.title} value={this?.optionalWidgetData?.value}/>
    }

    /**
     * Returns formula string of given widget
     */
    public getFormulaString(): string {
        return `${this.optionalWidgetData?.title} = ${this?.optionalWidgetData?.value}`;
    }

    /**
     * returns form react element
     */
    public getEditWidgetForm(editData: object): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return <form onSubmit={this.handleEdit}>
            <input type="hidden" name={"id"} value={editData.id}/>
            <div className='input-group'>
                Naam: <input type="text" name="title" defaultValue={editData.title}/>
            </div>
            <div className='input-group'>
                Waarde: <input type="number" name="number" step="0.000000000000001" defaultValue={editData.value}/>
            </div>
            <button type="submit">Opslaan</button>
        </form>
    }

    /**
     * Handles submit from getFormHTML
     * @param event:any
     */
    handleEdit(event: any): void {
        event.preventDefault();

        const data = {
            type: "Int",
            value: event.target.elements.number.value,
            title: event.target.elements.title.value
        };

        (new WidgetBase()).updateWidget(event.target.elements.id.value, "Vaste waarde: " + data?.title + " <br> " + data.value, data);
    }

    calculationFunctionality(): any {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;

        /**
         * Add title and Remove hidden
         */
        if (this.debug) {
            document.getElementById(id).setAttribute('type', '')
            document.getElementById("label_" + id).classList.remove('hidden')
            // document.getElementById(id).parentElement.classList.remove('hidden')
            document.getElementById(id).classList.remove('hidden')
        } else {
            document.getElementById(id).setAttribute('type', 'hidden')
            document.getElementById(id).classList.add('hidden')
            document.getElementById("label_" + id).classList.add('hidden')
            // document.getElementById(id).parentElement.classList.add('hidden')
        }
    }

    /**
     * Retruns edit widget
     */
    private getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer("Vaste waarde: " + this.optionalWidgetData?.data?.title + " <br> " + this.optionalWidgetData?.data?.value, this.optionalWidgetData, '', true)
    }
}
