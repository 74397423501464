import React from "react";
import { toast } from "react-toastify";

export default class ProjectRepository extends React.Component {
    project;
    authAxios;
    constructor(project = null, authAxios = null) {
        super();
        this.project = project;
        this.authAxios = authAxios;
    }
    // Creates new project
    async createProject(description) {
        let data = {
            description: description === null ? '' : description
        }
        // Persist to API
        return await this.authAxios.post(`/projects`, { data: data }).then(({ data }) => {
            toast.success(`Project aangemaakt.`);
            // Add project to class
            this.project = data

            return data.data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het aan maken van een project.`);
        });
    }
    async addProjectOnQoute(project, qouteData, description = null) {
        let neededToCreate = false;
        // If project not provided create new project
        if (project === null) {
            neededToCreate = true
            project = await this.createProject(description);
        }
        // TO DO: Detect if theres already a project with this qoute, and notify user?


        // data from the qoute
        let data = {
            qoute: qouteData,
        }

        // persist to API
        return await this.authAxios.put(`/projects/${project.id}/create-on-quote`, { data: data }).then(({ data }) => {
            if (!neededToCreate) {
                toast.success(`Project aangepast.`);
            }

            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een offerte bevestiging aan een project`);
        });
    }

    async addQuoteToProject(quotesids) {

        // Add calculation
        let data = {
            quotes: quotesids,
        }

        // Persist to API
        await this.authAxios.put(`/projects/${this.project.id}`, { data: data }).then(({ data }) => {
            toast.success(`Offerte toegevoegd aan ${data.data.number}.`);

            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een calculatie aan een project.`);
        });
    }

    // Add to project
    async addCalculationsToProject(calculations, description = null) {
        // If project not provided create new project
        if (this.project === null) {
            this.project = await this.createProject(description);
        }

        // Add calculation
        let data = {
            calculations: calculations,
        }

        // Persist to API
        await this.authAxios.put(`/projects/${this.project.id}`, { data: data }).then(({ data }) => {
            toast.success(`Calculaties toegevoegd aan ${data.data.number}.`);

            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een calculatie aan een project.`);
        });
    }

    // Add to project
    async addCalculationToProject(project, calculation, description = null) {
        // If project not provided create new project
        if (project === null) {
            project = await this.createProject(description);
        }
        // Add calculation
        let data = {
            calculation: calculation.id,
        }
        // Persist to API
        return await this.authAxios.put(`/projects/${project.id}`, { data: data }).then(({ data }) => {
            toast.success(`Calculatie toegevoegd aan ${data.data.number}.`);
            // this.addPurchaseQuotesToProjectsOnCalculations(calculation.id);
            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een calculatie aan een project.`);
        });
    }

    // add quote to existing project
    // async addQuoteToProject(quote) {
    //     // Add calculation
    //     let data = {
    //         quote: quote.id,
    //     }
    //     // Persist to API
    //     return await this.authAxios.put(`/projects/${project.id}`, { data: data }).then(({ data }) => {
    //         toast.success(`Offerte toegevoegd aan ${data.data.number}.`);
    //         return data;
    //     }).catch((exception) => {
    //         console.error(exception);
    //         toast.error(`Er ging iets mis met het toevoegen van een offerte aan een project`);
    //     });
    // }

    // Adds order to project
    async addOrdersToProject (orderIds) {
        // Add calculation
        let data = {
            orders: orderIds,
        }

        // Persist to API
        return await this.authAxios.put(`/projects/${this.project.id}`, {data: data}).then(({data}) => {
            toast.success(`Order toegevoegd aan ${data.data.number}.`);
            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een offerte bevestiging aan een project`);
        });
    }

    // add confirmation quote to existing project
    async addConfirmationQuoteToProject(confQuoteId) {
        // Add calculation
        let data = {
            quote_confirmations: [confQuoteId],
        }

        console.log(this.project,confQuoteId,data);

        // Persist to API
        return await this.authAxios.put(`/projects/${this.project.id}`, { data: data }).then(({ data }) => {

            toast.success(`Offerte bevestiging toegevoegd aan ${data.data.number}.`);
            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een offerte bevestiging aan een project`);
        });
    }

    async addPurchaseQuotesToProjectsOnCalculations(calculationId) {
        // Add calculation
        let data = {
            calculationId: calculationId,
        }

        // Persist to API
        return await this.authAxios.put(`/projects/${calculationId}/purchase-quote-on-calculation`, { data: data }).then(({ data }) => {

            toast.success(`Inkoop offerte toegevoegd aan project(en).`);
            return data;
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het toevoegen van een inkoop offerte`);
        });
    }

}
