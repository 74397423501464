import Container from "UI/App/Components/Container/Container";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import Icon from "UI/App/Components/Icon/Icon";
import Block from "UI/App/Components/Block/Block";
import Select from "react-select";
import {getSelectedValue} from "App/Util/select2";
import IF from "UI/App/Components/Conditional/IF";
import JobTicketTemplateForm from "UI/App/Partials/Content/CRM/Workflow/Components/JobTicketTemplateForm";
import {stringify} from "qs";
import {SpinnerCenter} from "UI/App/Components/Spinner";
import ChecklistSidebar from "UI/App/Components/Sidebar/Sidebars/ChecklistSidebar";

const orderQuery = stringify({
    populate: {
        quoteConfirmation: {
            fields: ['id'],
        },
        project: {
            fields: 'id'
        },
        calculation: {
            fields: ['id', 'language', 'data']
        },
    }
}, { encodeValuesOnly: true })

export default function CreateJobTicket() {
    const { orderId} = useParams();
    const { authAxios } = useContext(FetchContext);

    const [orderData, setOrderData] = useState(null);

    const [jobTicketTemplateOptions, setJobTicketTemplateOptions] = useState([]);
    const [jobTicketTemplate, setJobTicketTemplate] = useState(null);

    useEffect(() => {
        /**
         * Fetch all job ticket templates to be used in the dropdown.
         */
        authAxios
            .get(`/workflow/job-tickets/templates/all`)
            .then(({data}) => {
                /**
                 * Set the job ticket template options for the dropdown.
                 */
                setJobTicketTemplateOptions(data.map(template => ({ label: template.name, value: template.id, template: template })));
            })
            .catch(error => {
                /**
                 * On error, show in console and show toast.
                 */
                console.error(error);
                toast.error('Er is iets misgegaan bij het ophalen van de job ticket templates.')
            });

        /**
         * Fetch the order data.
         */
        authAxios
            .get(`/orders/${orderId}?${orderQuery}`)
            .then(({data}) => {
                /**
                 * Set the order data.
                 */
                setOrderData(data.data);
            })
            .catch(error => {
                /**
                 * On error, show in console and show toast.
                 */
                console.error(error);
                toast.error('Er is iets misgegaan bij het ophalen van de order.')
            });

    }, [authAxios, orderId]);

    if (orderData !== null && !orderData.calculation?.id) {
        return (
            <div className={'job-ticket__full-page-warning'}>
                <Icon name={'circle-exclamation'} />
                <span>De calculatie van deze order kan niet worden gevonden!</span>
                <Link to={`/orders/${orderId}`} >Terug naar order pagina</Link>
            </div>
        )
    }

    if (!orderData || !jobTicketTemplateOptions) {
        return (
            <SpinnerCenter/>
        )
    }

    return (
        <Container>
            <PageHeader title={'Job ticket aanmaken'} />
            <Block name={'Job ticket template'} title={'Job ticket template'}>
                <div className="input-group" style={{
                    width: '100%'
                }}>
                    <label className='required'>JOB TICKET TEMPLATE</label>
                    <Select
                        options={jobTicketTemplateOptions}
                        value={getSelectedValue(jobTicketTemplate, jobTicketTemplateOptions, 'template')}
                        onChange={(value) => setJobTicketTemplate(value.template)}
                    />
                </div>
            </Block>
            <br/>
            <IF condition={jobTicketTemplate && orderData}>
                <JobTicketTemplateForm
                    jobTicketTemplate={jobTicketTemplate}
                    orderData={orderData}
                    calculationId={orderData?.calculation?.id}
                />
            </IF>

            <ChecklistSidebar
                checklist={orderData?.calculation?.data?.checklist}
                language={orderData?.calculation?.language}
            />
        </Container>
    )
}