import React, {ReactElement} from "react"
import CreatableSelect from "react-select/creatable";
import IWidget from "./IWidget"
import WidgetBase from "./WidgetBase"
import {TrenderType} from "./WidgetTypes"

/**
 * Widget class
 * @extends WidgetBase @implements IWidget
 */
export default class Division extends WidgetBase implements IWidget {
	callback: ((object: Object) => any);
	widgetData: String | null
	saveWidget: ((object: Object) => any);
	optionalWidgetData: object | undefined;
	debug: boolean;
	prefix: string | undefined;

	constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
		super()
		this.renderType = renderType;
		this.callback = callback;
		this.widgetData = null;
		this.saveWidget = saveWidget;
		this.optionalWidgetData = optionalWidgetData;
		this.debug = debug;
		this.prefix = prefix;
	}


	/**
	 * @return ReactElement
	 */
	public getPreviewHTML(): JSX.Element {
		const widgetData = {
			widgetType: 'Division',
		}

		return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
		}}>val / val</div>
	}

	/**
	 * Returns from html
	 */
	public getFormHTML(): JSX.Element {
		const widgetValues = this.getWidgetValues();

		return <form onSubmit={(e) => this.handleSubmit(e)}>
			<h3>Delen</h3>
			<div className='input-group'>
				Naam: <input type="text" name="title" />
			</div>
			<div className='input-group'>
				<CreatableSelect
					name="val1"
					options={widgetValues}
				/>
				<CreatableSelect
					name="val2"
					options={widgetValues}
				/>
			</div>
			<button type="submit">Opslaan</button>
		</form>
	}

	/**
	 * Handles submit from getFormHTML
	 * @param event:any
	 */
	handleSubmit(event: any): void {
		event.preventDefault();

		const data = {
			type: "Division",
			title: event.target.elements.title.value,
			value: {
				val1: event.target.elements.val1.value,
				val2: event.target.elements.val2.value
			}
		};

		this.addWidgetToWidgetContainer("Delen: " + data?.title + "<br> (" + data.value.val1 + ") / (" + data.value.val2 + ")", data);
	}

	public getBadgeHTML() {
		return <div>Badge html:</div>
	}

	/**
	 * Renders calculation form
	 */
	public getCalculationForm() {
		this.addWidgetToCalculationForm(``, this.optionalWidgetData, this.optionalWidgetData.parentId)
		// return <div id={this.optionalWidgetData.id}>Delen</div>
	}

	/**
	 * Renders preview form
	 */
	public getPreviewFormHTML(): ReactElement {
		return <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.title} value={this?.optionalWidgetData?.value} />
	}

	/**
	 * Returns formula string of given widget
	 */
	public getFormulaString(): string {
		return `${this?.optionalWidgetData?.value.val1} / ${this.optionalWidgetData?.value.val2}`;
	}

	/**
	 * Returns html for edit
	 */
	getEditHTML(): void {
		const valid1 = this.optionalWidgetData.prefix !== undefined && this.optionalWidgetData.data.value.val1.match(/^[0-9]+$/) === null ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.val1 : this.optionalWidgetData.data.value.val1;
		const valid2 = this.optionalWidgetData.prefix !== undefined && this.optionalWidgetData.data.value.val2.match(/^[0-9]+$/) === null ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.val2 : this.optionalWidgetData.data.value.val2;


		(new WidgetBase()).addWidgetToWidgetContainer("Delen: " + this.optionalWidgetData?.data?.title + "<br> (" + valid1 + ") / (" + valid2 + ")", this.optionalWidgetData, '', true);
	}

	/**
	 * Returns from html
	 */
	getEditWidgetForm(editData: object): JSX.Element {
		const widgetValues = this.getWidgetValues();
		const [selectedOne, selectedTwo] = WidgetBase.getSelectedValues(widgetValues, editData.value.val1, editData.value.val2);

		return <form onSubmit={this.handleEdit}>
			<input type="hidden" name={"id"} value={editData.id} />
			<div className='input-group'>
				Naam: <input type="text" name="title" defaultValue={editData.title} />
			</div>
			<div className='input-group'>
				<CreatableSelect
					defaultValue={selectedOne}
					name="val1"
					options={widgetValues}
				/>
				<CreatableSelect
					defaultValue={selectedTwo}
					name="val2"
					options={widgetValues}
				/>
			</div>
			<button type="submit">Opslaan</button>
		</form>
	}

	/**
	 * Handles submit from getFormHTML
	 * @param event:any
	 */
	handleEdit(event: any): void {
		event.preventDefault();

		const data = {
			type: "Division",
			title: event.target.elements.title.value,
			value: {
				val1: event.target.elements.val1.value,
				val2: event.target.elements.val2.value
			}
		};

		(new WidgetBase()).updateWidget(event.target.elements.id.value, "Delen: " + data?.title + "<br> (" + data.value.val1 + ") / (" + data.value.val2 + ")", data);
	}

	calculationFunctionality() {
		const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;

		let valid1 = this.optionalWidgetData.data.value.val1;
		let valid2 = this.optionalWidgetData.data.value.val2;

		// If prefix is not added check
		if(!valid1.startsWith(this.optionalWidgetData.prefix)){
			valid1 = this.optionalWidgetData.prefix !== undefined && this.optionalWidgetData.data.value.val1.match(/^[0-9]+(?:\.[0-9]+)?$/) === null ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.val1 : this.optionalWidgetData.data.value.val1;
		}

		// If prefix is not added
		if(!valid2.startsWith(this.optionalWidgetData.prefix)){
			valid2 = this.optionalWidgetData.prefix !== undefined && this.optionalWidgetData.data.value.val2.match(/^[0-9]+(?:\.[0-9]+)?$/) === null ? this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.val2 : this.optionalWidgetData.data.value.val2;
		}
		/**
		 * Set widget data
		 */
		this.optionalWidgetData.data.value.result = this.getResultFromWidget(valid1) / this.getResultFromWidget(valid2);

		/**
		 * Update widget data in storage
		 */
		this.updateWidgetData(id, this.optionalWidgetData)
		/**
		 * Handle debug
		 */

		const element = document.getElementById(id);

		if (!element) return;

		if (this.debug) {
			element.innerHTML = "";

			element.append(`[` + id + `] Delen id: ${this.optionalWidgetData.data.title} : ${valid1}/${valid2} = ${this.optionalWidgetData.data.value.result}`)
			// document.getElementById(this.optionIalWidgetData.id).append('Delen: waarde: ' + this.optionalWidgetData.data.value.result)
			element.classList.remove('hidden');
		} else {
			element.innerHTML = "";
			element.classList.add('hidden');
		}
	}
}
