import Icon from "../../../../../Components/Icon/Icon";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency} from "App/Util/format";
import {FetchContext} from "App/Strapi/FetchContext";
import PurchaseQuoteGenerator from "../../../Purchases/Quotes/PurchaseQuoteGenerator";
import {toast} from "react-toastify";
import IF from "UI/App/Components/Conditional/IF";
import {AuthContext} from 'App/Strapi/AuthProvider';
import {downloadFile} from 'App/Util/fetch';

function WerkderdenPurchaseTab({ werkDerdenData, setWerkDerdenData, quantities, thirdParty, id, checklistData, calculationId, base64 = false }) {
    const {authState} = useContext(AuthContext);

    const { authAxios } = useContext(FetchContext);

    const [signature, setSignature] = useState(null);


    useEffect(() => {
        if (signature !== null) {
            void loadThirdPartyData();
        }
    }, [thirdParty, signature])

    // get authstate user signature image
    useEffect(() => {
        if (authState?.user?.signature?.id) {
            // get the image as a dataURL
            downloadFile(authAxios, authState.user.signature.id, 'base64').then((base64) => {
                setSignature(`data:${authState.user.signature.mime};base64,${base64}`);
            });
        } else {
            setSignature(false);
        }
    }, [authAxios, authState.user.signature?.id, authState.user.signature?.mime]);

    async function loadThirdPartyData() {
        let quantitiesWithMinutes = {};

        if (!quantities) {

            quantitiesWithMinutes = {
                cost: 0,
                surtax: 0
            }
        } else {
            quantities.forEach(([quantity, misses]) => {
                if (typeof quantity === "string") {
                    quantity = parseInt(quantity);
                    quantity = isNaN(quantity) ? 0 : quantity;
                }

                if (typeof misses === 'string') {
                    misses = parseInt(misses);
                    misses = isNaN(misses) ? 0 : misses;
                }

                quantitiesWithMinutes[quantity] = {
                    quantity: quantity + misses,
                    cost: 0,
                    surtax: 0
                };
            });
        }

        let cleanChecklistData = [];
        for (const checklistItem of thirdParty?.data ?? checklistData ?? []) {
            if (checklistItem.value !== '') {
                cleanChecklistData.push({
                    label:  checklistItem.name,
                    content: checklistItem.value,
                    englishName: checklistItem.englishName,
                    dutchName: checklistItem.dutchName,
                })
            }
        }

        const formattedRows = await new PurchaseQuoteGenerator(null, null, null, null, null, authAxios, true, undefined, undefined, signature).formatForPdf(cleanChecklistData);

        if (thirdParty) {
            setWerkDerdenData([
                ...thirdParty.map(thirdParty => ({
                    id: calculationId ? (thirdParty.id ?? null) : null,
                    number: calculationId ? thirdParty.number ?? null : null,
                    language: thirdParty.language,
                    date: thirdParty.date,
                    description: thirdParty.description,
                    base64: thirdParty.base64,
                    data: thirdParty.data,
                    supplier: thirdParty.supplier?.id ?? thirdParty.supplier ?? '',
                    contactPerson: thirdParty.contactPerson?.id ?? thirdParty.contactPerson ?? '',
                    // Convert the quantities from an array of objects into an object with the quantity as key
                    // E.g. [{quantity: 1000, cost: 500, surtax: 20}] => {1000: {cost: 500, surtax: 20, price: 600}}
                    quantities: !quantities ? thirdParty.quantities[0] : Array.isArray(thirdParty?.quantities) ? Object.fromEntries(
                        thirdParty?.quantities
                            ?.map(quantity => [
                                quantity.quantity,
                                {
                                    quantity: quantity?.purchaseQuantity ?? quantity?.quantity ?? 0,
                                    cost: quantity.cost,
                                    surtax: quantity.surtax ?? 0,
                                    price: formatCurrency(quantity.cost * (quantity.surtax / 100 + 1)),
                                }
                            ])) : thirdParty?.quantities ?? quantitiesWithMinutes,
                    initialRowData: formattedRows
                }))
            ])
        }
    }

    useEffect(() => {
        if (!quantities) return;

        setWerkDerdenData(werkderdenData => {
            // If the quantity lengths are the same, some quantity was probably updated
            // Soo we find the quantity in this entry, that doesn't exist in the calculation quantities
            // That quantity was updated, to the quantity in the calculation quantities that doesn't exist in this entry
            // We then update the entry with the new quantity
            werkderdenData.map((werkderdenEntry) => {
                if (quantities.length === Object.values(werkderdenEntry.quantities).length) {
                    const quantityMissingInEntry = quantities.find(([quantity, misses]) => {
                        return !Object.keys(werkderdenEntry.quantities).includes(String(quantity));
                    });

                    const quantityMissingInQuantities = Object.keys(werkderdenEntry.quantities).find(quantity => {
                        return !quantities.map(q => q[0]).includes(parseInt(quantity));
                    });

                    // If we don't have a quantity that's missing in the entry, or a quantity that's missing in the quantities
                    // We don't need to update the entry
                    if (!quantityMissingInEntry || !quantityMissingInQuantities) return werkderdenEntry;

                    // Add the updated quantity as a new quantity in the entry
                    werkderdenEntry.quantities = {
                        ...werkderdenEntry.quantities,
                        [quantityMissingInEntry[0]]: {
                            ...werkderdenEntry.quantities[Number(quantityMissingInQuantities)],
                            quantity: quantityMissingInEntry[0] + quantityMissingInEntry[1]
                        }
                    }

                    // Remove the old quantity from the entry
                    delete werkderdenEntry.quantities[Number(quantityMissingInQuantities)];

                    return {...werkderdenEntry};
                }
                // If there are more quantities in the calculation than in the entry, we add the missing quantities to the entry
                else if (quantities.length > Object.values(werkderdenEntry.quantities).length) {
                    const quantityMissingInEntry = quantities.find(([quantity, misses]) => {
                        return !Object.keys(werkderdenEntry.quantities).includes(String(quantity));
                    });

                    // If we don't have a quantity that's missing in the entry, we don't need to update the entry
                    // The quantity was probably a duplicate
                    if (!quantityMissingInEntry) return werkderdenEntry;

                    // Add the updated quantity as a new quantity in the entry
                    werkderdenEntry.quantities = {
                        ...werkderdenEntry.quantities,
                        [quantityMissingInEntry[0]]: {
                            cost: 0,
                            price: formatCurrency(0),
                            surtax: 0,
                            quantity: quantityMissingInEntry[0] + quantityMissingInEntry[1]
                        }
                    }

                    return {...werkderdenEntry};
                }
                // If there are more quantities in the entry than in the calculation, we remove the missing quantities from the entry
                else if (quantities.length < Object.values(werkderdenEntry.quantities).length) {
                    const quantityMissingInQuantities = Object.keys(werkderdenEntry.quantities).find(quantity => {
                        return !quantities.map(q => q[0]).includes(parseInt(quantity));
                    });

                    // If we don't have a quantity that's missing in the quantities, we don't need to update the entry
                    // The quantity was probably a duplicate
                    if (!quantityMissingInQuantities) return werkderdenEntry;

                    // Remove the old quantity from the entry
                    delete werkderdenEntry.quantities[Number(quantityMissingInQuantities)];

                    return {...werkderdenEntry};
                }

                return {...werkderdenEntry};
            });

            return [...werkderdenData];
        });
    }, [quantities]);

    const removeWerkderdenEntry = (key) => {
        if (!window.confirm('LET OP: DIT IS NIET TERUG TE DRAAIEN! Weet je zeker dat je deze inkoop offerte wilt verwijderen?')) return;

        const entry = { ...werkDerdenData[key] };

        if (entry.id) {
            authAxios.delete(`/calculations/purchases/quotes/${entry.id}`)
                .catch((err) => {
                    console.error(err);
                    toast.error("Er is iets misgegaan bij het verwijderen van de inkoopofferte.");
                });
        }

        setWerkDerdenData(prev => {
            // Update werkderden data by setting the entry to null
            // Don't use delete because that will mess up the indexes and in turn
            // causes the wrong entry to be deleted due to a useState issue
            return prev.map((e, k) => k === key ? null : e);
        });
    }

    const addWerkDerdenEntry = () => {
        setWerkDerdenData((prev) => {
            let quantitiesWithMinutes = {};

            if (!quantities) {
                quantitiesWithMinutes = {
                    cost: 0,
                    surtax: 0
                }
            } else {
                quantities.forEach(([quantity, misses]) => {
                    if (typeof quantity === "string") {
                        quantity = parseInt(quantity);
                        quantity = isNaN(quantity) ? 0 : quantity;
                    }

                    if (typeof misses === 'string') {
                        misses = parseInt(misses);
                        misses = isNaN(misses) ? 0 : misses;
                    }

                    quantitiesWithMinutes[quantity] = {
                        quantity: quantity + misses,
                        cost: 0,
                        surtax: 0
                    };
                });
            }

            const formatted = [];
            let rowCount = 0;
            for (const row of checklistData) {
                if ((row.value === '' || !row.value) && row.value !== 0) {
                    continue;
                }

                if (formatted[rowCount] === undefined) {
                    formatted[rowCount] = {
                        dutchLabel: row.dutchName,
                        englishLabel: row.englishName,
                        defaultLabel: '',
                        label: null,
                        content: ''
                    }
                }

                if (row.name !== '') {
                    formatted[rowCount].defaultLabel = row.name
                }

                if (row.value.match(/\n/) !== null) {
                    for (const line of (row.value.split(/\r?\n/))) {
                        if (formatted[rowCount] === undefined) {
                            formatted[rowCount] = {
                                dutchLabel: row.dutchName,
                                englishLabel: row.englishName,
                                defaultLabel: '',
                                label: null,
                                content: ''
                            }
                        }

                        formatted[rowCount].content = line
                        rowCount++;
                    }
                } else {
                    formatted[rowCount].content = row.value
                    rowCount++;
                }
            }

            return [...prev, {
                description: '',
                supplier: '',
                quantities: quantitiesWithMinutes,
                initialRowData: formatted
            }];
        });
    }

    return (
        <div className={"calculationTab"} id={id}>
            {!base64 ? <button className={'werk-derden--btn-add btn'} onClick={addWerkDerdenEntry}><Icon name={'plus'} /></button> : <></>}
            {[...werkDerdenData].map((entry, key) => {
                if (!entry) {
                    return null;
                }

                return <WerkderdenPurchaseEntry
                    entry={{ ...entry }}
                    entryKey={key}
                    key={key}
                    removeEntry={removeWerkderdenEntry}
                    base64={base64}
                    useQuantities={Boolean(quantities)}
                />
            })}
        </div>
    );
}

function WerkderdenPurchaseEntry({ entry, entryKey, removeEntry, base64, useQuantities = true }) {
    // Default states
    const [werkDerdenEntryData, setWerkDerdenEntryData] = useState(entry);

    useEffect(() => {
        setWerkDerdenEntryData(prev => ({
            ...prev,
            quantities: entry.quantities
        }));
    }, [entry.quantities]);

    const updateThisEntry = (quantity, key, value) => {
        if (key === 'description') {
            setWerkDerdenEntryData(
                (prev) => ({
                    ...prev,
                    description: value
                })
            )

            return;
        }

        const prev = quantity ? werkDerdenEntryData.quantities[quantity] : werkDerdenEntryData.quantities;
        const requestQuantity = parseInt(key === 'quantity' ? value : prev.quantity);
        const cost = parseFloat(key === 'cost' ? value : prev.cost);
        const surtax = parseFloat(key === 'surtax' ? value : prev.surtax);
        const price = cost + (cost * surtax / 100);

        if (!quantity) {
            setWerkDerdenEntryData(
                (prev) => ({
                    ...prev,
                    quantities: {
                        quantity: requestQuantity,
                        cost,
                        surtax,
                        price: formatCurrency(price)
                    }
                })
            )
        } else {
            setWerkDerdenEntryData(
                (prev) => ({
                    ...prev,
                    quantities: {
                        ...prev.quantities,
                        [quantity]: {
                            quantity: requestQuantity,
                            cost,
                            surtax,
                            price: formatCurrency(price)
                        }
                    }
                })
            )
        }
    }

    if (base64) {
        return (
            <embed
                style={{
                    height: '800px', width: '100%'
                }}
                src={entry.base64 ? `data:application/pdf;base64,${entry.base64}` : ''}
            />
        )
    } else {
        return (
            <div className={'werk-derden-entry'} data-json={JSON.stringify(werkDerdenEntryData)} data-key={entryKey}>
                <button className={'werk-derden--btn-remove btn btn--transparent btn--icon-red'} onClick={() => removeEntry(entryKey)}><Icon name={'minus'}/></button>
                <label htmlFor={`werk-derden--omschrijving`}>
                    Omschrijving
                </label>
                <input
                    className={'werk-derden--omschrijving'}
                    id={`werk-derden--omschrijving`}
                    type={'text'}
                    placeholder={'Omschrijving'}
                    value={werkDerdenEntryData.description}
                    onChange={e => updateThisEntry(false, 'description', e.target.value)}
                />
                <div className={'werk-derden--quantities'}>
                    <IF condition={useQuantities}>
                        {Object.entries(werkDerdenEntryData?.quantities ?? {}).map(([quantity, data], key) => {
                            return (
                                <ThirdPartyQuantity
                                    key={key}
                                    quantity={quantity}
                                    data={data}
                                    updateThisEntry={updateThisEntry}
                                    useQuantities={useQuantities}
                                />
                            )
                        })}
                    </IF>
                    <IF condition={!useQuantities}>
                        <ThirdPartyQuantity
                            key={0}
                            quantity={0}
                            data={werkDerdenEntryData?.quantities ?? {}}
                            updateThisEntry={updateThisEntry}
                            useQuantities={false}
                        />
                    </IF>
                </div>
            </div>
        )
    }
}

function ThirdPartyQuantity({quantity, data, updateThisEntry, useQuantities = true}) {
    const intQuantity = parseInt(quantity);
    let cost = isNaN(data.cost) ? 0 : data.cost;
    let price = data.cost * (data.surtax / 100 + 1);
    let surtax = isNaN(data.surtax) ? 0 : data.surtax;
    price = isNaN(price) ? 0 : price;

    return (
        <div>
            <IF condition={useQuantities}>
                <div>
                    <label htmlFor={`werk-derden--aantal--${quantity}`}>
                        Bestel aantal
                    </label>
                    <input
                        className={'werk-derden--aantal'}
                        id={`werk-derden--aantal--${quantity}`}
                        type={'number'}
                        placeholder={'Aantal'}
                        value={isNaN(data.quantity) ? '' : data.quantity}
                        step={1}
                        onChange={e => updateThisEntry((useQuantities ? intQuantity : false), 'quantity', e.target.value)}
                    />
                </div>
            </IF>
            <div>
                <label htmlFor={`werk-derden--kosten--${quantity}`}>
                    Kosten
                    <IF condition={useQuantities}> voor {data.quantity} stuks</IF>
                </label>
                <input
                    className={'werk-derden--kosten'}
                    id={`werk-derden--kosten--${quantity}`}
                    type={'number'}
                    placeholder={'Kosten'}
                    value={isNaN(data.cost) ? '' : data.cost}
                    step={0.01}
                    onChange={e => updateThisEntry((useQuantities ? intQuantity : false), 'cost', e.target.value)}
                />
            </div>
            <div>
                <label htmlFor={`werk-derden--toeslag--${quantity}`}>Toeslag (%)</label>
                <input
                    className={'werk-derden--toeslag'}
                    id={`werk-derden--toeslag--${quantity}`}
                    type={'number'}
                    placeholder={'Toeslag'}
                    value={isNaN(data.surtax) ? '' : data.surtax}
                    step={0.1}
                    onChange={e => updateThisEntry((useQuantities ? intQuantity : false), 'surtax', e.target.value)}
                />
            </div>
            <div>
                <label htmlFor={`werk-derden--prijs--${quantity}`}>Prijs</label>
                <input
                    className={'werk-derden--prijs'}
                    id={`werk-derden--prijs--${quantity}`}
                    type={'text'}
                    disabled={true}
                    value={formatCurrency(price)}
                    data-quantity={quantity}
                    data-price={cost}
                    data-total={price}
                    data-surtax={surtax}
                    onChange={() => {
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(WerkderdenPurchaseTab);
