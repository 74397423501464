import PopupAddOrEditMemo from 'UI/App/Components/Popup/Popups/AddOrEditMemo';
import PopupEditCompanyMessage from 'UI/App/Components/Popup/Popups/EditCompanyMessage';
import {useContext, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom"
import {FetchContext} from 'App/Strapi/FetchContext';
import {AuthContext} from 'App/Strapi/AuthProvider';
import {ConfigContext} from 'App/Strapi/ConfigContext';
import PDF from 'App/Util/print';
import Icon from "UI/App/Components/Icon/Icon";
import CompanyInfoMessage from './CompanySingleMessage';
import {toast} from 'react-toastify';
import useWindowDimensions from 'App/Util/useWindowDimensions';


//import components
import Block from 'UI/App/Components/Block/Block';
import Popup from 'UI/App/Components/Popup/Popup';

import './CompanyMessages.scss';
import qs from "qs";

export default function CompanyInfo({ company, setCompany, companyID, companyName }) {
	const { authAxios } = useContext(FetchContext);
	const { authState } = useContext(AuthContext);
	const { appConfig } = useContext(ConfigContext);

	const { width } = useWindowDimensions();

	// check if on suppliers path
	const location = useLocation();
	const isSupplier = /\/suppliers\//.test(location.pathname);

    const [memoFormData, setMemoFormData] = useState({});
	const [editFormData, setEditFormData] = useState({});
	const [file, setFile] = useState(null);
	const [notes, setNotes] = useState([]);
	const [imgUrl, setImgUrl] = useState("");
	const [tagElements, setTagElements] = useState([]);
	const [tagData, setTagData] = useState(false);
	const [tagState, setTageState] = useState([]);
	const [messageText, setMessageText] = useState("");
	const [collapseTags, setCollapseTags] = useState(true);

	//Fileupload popup
	const ref = useRef()
	const handleClick = (e) => {
		ref.current.click()
	}

	const handleFileChange = event => {
		setFile(event.target.files[0]);
	};

	const handleSubmit = async event => {
		uploadFile();
		event.preventDefault();
	};

	const getNotes = () => {
		const params = qs.stringify({
			filters: {
				$and: [
					{
						...(isSupplier ? { supplier: companyID } : { company: companyID }),
						contactPerson: {
							id: {
								$null: true,
							}
						}
					}
				],
			},
			populate: [
				'attachment',
				'tags',
				'user',
			],
			sort: 'createdAt:DESC',
		}, { encode: false });

		authAxios.get(`/crm/notes?${params}`).then(({ data: { data } }) => {
			setNotes(data);
		});
	}

	/**When a new note is added the pages informatie tab is expanded with the new note */
	useEffect(() => {
		getTags();
		getNotes();
	}, []);

	useEffect(() => {
		createTagElements();
	}, [tagData, tagState]);

	function updateText(e) {
		setMessageText(e.target.value);
	}

	function getTags() {
		authAxios.get('/crm/notes/tags',).then(({ data }) => {
			setTagData(data);
		}).catch((exception) => {
			toast.error("Kon tags niet ophalen");
			console.log(exception);
		});
	}

	function updateTag(e) {
		console.log("tag updated");
		// store the state of the checkbox on ID of the tag
		let tagArray = tagState;
		tagArray[e.target.value] = e.target.checked;
		setTageState(tagArray);
		createTagElements();
	}

	function createTagElements() {
		// return if no data
		if (tagData === false) {
			return;
		}
		let tagArray = [];

		// create an input for the tag with state
		for (const tag of tagData.data) {
			tagArray.push(<li key={tag.id}><input checked={(tagState[tag.id] === undefined) ? false : tagState[tag.id]} type={"checkbox"} name={"tags"} onChange={updateTag} value={tag.id} id={"tag-" + tag.id} /><label htmlFor={"tag-" + tag.id} style={{ backgroundColor: tag.backgroundColor, color: tag.color }}>{tag.name}</label></li >)
		}
		setTagElements(tagArray);
	}

	function createMessage() {
		// stop if no text value
		if (messageText === "") {
			return;
		}

		let chosenTags = [];
		for (let key of Object.keys(tagState)) {
			// if checked store tag ID in array
			if (tagState[key]) {
				chosenTags.push(key);
			}
		}
		setTageState([]);

		// post the messsage
		authAxios.post('/crm/notes', {
			data: {
				content: messageText,
				company: !isSupplier ? companyID : undefined,
				supplier: isSupplier ? companyID : undefined,
				user: authState?.user?.id,
				tags: chosenTags
			},
		}).then(({ data: { data } }) => {
			toast.success('Bericht toegevoegd');

			// empty the textarea
			document.getElementById('newCompanyMessage').value = null

			getNotes();
			setMessageText("");
		}, (exception) => {
			toast.error(`Er is een probleem opgetreden bij het opslaan van het bericht!`);
		});
	}

	/**
	 * Creates a temporary pdf with the company's info messages for printing
	 */
	async function createMessagesPDF() {
		// abort if there are no messages
		if ((notes ?? 0) === 0) {
			toast.info('Er zijn geen berichten om te printen');
			return;
		}

		const pdf = new PDF(`${companyName}\nachtergrond informatie`, appConfig?.logo);

		// add table for each message
		for (const message of notes ?? []) {
			const username = message?.user?.username ?? "Onbekende gebruiker";
			const datePosted = new Date(message?.createdAt)
				.toLocaleString('nl-NL',
					{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
				);

			const tags = [];
			for (const tag of message?.tags ?? []) {

				const tagColor = tag.color.startsWith('rgb') ? tag.color.match(/\d{1,3}/g).map((x) => parseInt(x)) : tag.color;
				tags.push({
					content: tag.name,
					styles: {
						textColor: tagColor,
						overflow: 'ellipsize',
						// overflow: 'visible',
						lineWidth: { bottom: 0, top: 0 },
						fontSize: 9,
					},
				});
			}

			const body = tags.length > 0 ? [
				[
					{
						content: '', // empty string otherwise `[object] [object]` is shown.
						colSpan: 2,
						styles: { lineWidth: { bottom: 0, top: 0 } }
					}
				],
				[
					{
						content: message?.content,
						colSpan: 2,
						styles: { lineWidth: { bottom: 0, top: 0 } }
					}
				]
			] : [
				[
					{
						content: message?.content,
						colSpan: 2,
						styles: { lineWidth: { bottom: 0, top: 0 } }
					}
				]
			];

			const didDrawCell = tags.length > 0 ? (data) => {
				if (data.row.index === 0 && data.row.section === 'body') {
					pdf.autoTable({
						startY: data.cell.y,
						tableWidth: 'wrap',
						// tableWidth: pdf.getPageWidth() - 28 - 3,
						body: [tags],
					})
				}
			} : undefined;


			// add table for current message
			await pdf.addTable([
				[
					{ content: username },
					{
						content: datePosted + ' ',
						styles: { fontStyle: 'italic', fontSize: 9, halign: 'right' }
					}
				]
			], body, { didDrawCell })
		}

		pdf.openPDF();
	}

	function uploadFile() {
		if (!file) {
			return;
		}
		const pendingUpload = toast.loading("Bestand uploaden...");
		let bodyFormData = new FormData();
		bodyFormData.append('file', file);
		try {
			let formData = new FormData();
			formData.append("files", file);

			authAxios.post("../upload", formData)
				.then((response) => {
					const fileId = response.data[0].id;
					authAxios.post('/crm/notes', {
						data: {
							content: file.name,
							company: !isSupplier ? companyID : undefined,
							supplier: isSupplier ? companyID : undefined,
							user: authState?.user?.id,
							attachment: [fileId],
						},
					}).then(({ data: { data } }) => {
						toast.update(pendingUpload, {
							render: "Bestand geupload.",
							type: "success",
							isLoading: false,
							autoClose: 2500
						});
						// empty the file area
						document.getElementById('fileUpload').value = null;
						//empty setfile const to free up space for new uploads
						setFile(null);

						getNotes();
					}).catch((exception) => {
						toast.error('Er was een probleem opgetreden met het uploaden van de bijlage!');
					});
				})
				.catch((error) => {
					if (error.response.status === 413) {
						toast.update(pendingUpload, {
							render: "Bestand is te groot om te uploaden. (Max 32MB)",
							type: "error",
							isLoading: false,
							autoClose: 4000
						});
						return;
					}
					toast.update(pendingUpload, {
						render: "Bestand uploaden mislukt.",
						type: "error",
						isLoading: false,
						autoClose: 2500
					})
					console.log(error);
				})
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<Block name='chat' title='Informatie' headerRightSideChildren={<button onClick={() => createMessagesPDF()} className='btn--round btn--black'><Icon name='print' /></button>}>
				<div className='company__chat'>
                {(notes ?? []).map((note, key) => {
                    return (
                        <CompanyInfoMessage
                            key={`message-${key}`}
                            getNotes={getNotes}
                            companyID={companyID}
                            setMemoFormData={setMemoFormData}
                            setEditFormData={setEditFormData}
                            setImgUrl={setImgUrl}
                            setCollapseTags={setCollapseTags}
                            collapseTags={collapseTags}
                            messageKey={key}
                            message={note}
                        />
                    );
                    })}
					{notes?.length === 0 && <span style={{ color: 'gray', fontStyle: 'italic', padding: '15px' }}>Er zijn nog geen berichten...</span>}
				</div>
				<div className='company__add'>
					<ul className='company__message__labels'>
						{tagElements}
					</ul>
					<textarea onChange={updateText} id='newCompanyMessage' placeholder='Informatie toevoegen' rows='4'></textarea>
					<button className="btn" onClick={() => createMessage()}>{width > 350 && 'Toevoegen'} <Icon name={'plus'} /></button>
					<div className='company__message__file-upload'>
						<button onClick={handleClick} className="btn btn--black button--popup" htmlFor="#fileUpload">
							<Icon name="paperclip" />
						</button>
						<input id='fileUpload' className="hidden" ref={ref} onInput={handleFileChange} onChange={handleSubmit} type="file" />
						{width > 600 && <span className='company__message__file-upload__text'>32 MB maximale bestandsgrootte</span>}
					</div>
				</div>
                
				{/* add popups */}
                <PopupAddOrEditMemo changedFormValues={memoFormData} />
				<PopupEditCompanyMessage company={company} tagData={tagData} getNotes={getNotes} editFormData={editFormData} />
				<Popup title='Bijlage' popupID={'inspect-image'}>
					<img className={'preview-attachment-image'} src={imgUrl} alt="" />
				</Popup>
			</Block>
		</>
	)
}
