import "./runningOrderTimersPopup.scss";
import {memo, useCallback, useContext, useEffect, useRef, useState} from "react";
import OrderTimerRepository from "UI/App/Partials/Content/CRM/Orders/OrderTimer/OrderTimerRepository";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import LiveTimer from "UI/App/Partials/Content/Dashboard/Blocks/Timer/LiveTimer";
import {formatTimeWithoutDate} from "App/Util/format";
import Grid from "UI/App/Components/Grid/Grid";
import {useLocation, useNavigate} from "react-router-dom";
import Icon from "UI/App/Components/Icon/Icon";
import OrderTimer from "UI/App/Partials/Content/CRM/Orders/OrderTimer/OrderTimer";;

const RunningOrderTimersPopup = memo(function RunningOrderTimersPopup({
    visible,
    closePopup,
    setTimerCount
}) {

    const { authAxios } = useContext(FetchContext);

    // State for the running timers
    const [orderTimers, setOrderTimers] = useState([]);

    // Create a new instance of the OrderTimerRepository
    const orderTimerRepo = useRef(null);
    if (orderTimerRepo.current === null) {
        orderTimerRepo.current = new OrderTimerRepository(authAxios);
    }

    // Create a navigate function
    const navigate = useNavigate();
    const location = useLocation();

    const getMyRunningTimers = useCallback(() => {
        // Fetch the running timers
        orderTimerRepo.current.getMyRunningTimers()
            .then((timers) => {

                setTimerCount(timers.length);

                if (timers.length === 0) {
                    closePopup();
                }

                let orderTimers = {};
                for (let timer of timers) {
                    if (!orderTimers[timer.order.id]) {
                        orderTimers[timer.order.id] = {
                            id: timer.order.id,
                            number: timer.order.number,
                            description: timer.order.description,
                            company: timer.order.company,
                            timers: [],
                        };
                    }

                    orderTimers[timer.order.id].timers.push(timer);
                }

                setOrderTimers(orderTimers);
            })
            .catch((error) => {
                toast.error('Er is een fout opgetreden bij het ophalen van de timers');
            });
    }, [closePopup, setTimerCount]);

    const endTimer = useCallback((orderId, timerId) => {
        const timerRepo = new OrderTimer(orderId, authAxios);
        timerRepo.stop(timerId)
            .then(getMyRunningTimers)
            .catch((error) => {
                toast.error('Er is een fout opgetreden bij het stoppen van de timer');
                console.error(error);
            });
    }, [getMyRunningTimers]);

    // Re-fetch the timers every time the popup is opened
    useEffect(() => {
        // Avoid fetching the timers if the popup is not visible
        if (!visible && orderTimers.length !== 0) return;

        getMyRunningTimers();
    }, [getMyRunningTimers, visible]);

    // Listen for timer events
    useEffect(() => {
        // Listen for timer updates
        window.addEventListener('timer::update', getMyRunningTimers);

        // Cleanup
        return () => {
            window.removeEventListener('timer::update', getMyRunningTimers);
        }
    }, [getMyRunningTimers]);

    const pathname = location.pathname;

    useEffect(() => {
        closePopup();
    }, [pathname]);

    return (
        <div className={'global__order-timers-popup' + (visible ? ' visible' : '')}>
            <div className={'order-timers__header'}>
                <span className={'order-timers__header__title'}>Lopende order timers</span>
                <button className={'btn btn--transparent'} onClick={closePopup}>&times;</button>
            </div>

            <div className={'order-timers__content'}>
                {Object.values(orderTimers).map((order) =>
                    <div className={'order-timers__order'} key={order.id}>
                        <span className={'order-timer__order-header'} onClick={() => navigate(`/orders/${order.id}`)}><strong>[{order.number}]</strong> <span className={"notranslate"}>{order.company?.name}</span></span>
                        {order.timers.map((timer) =>
                            <Grid columns={2} customColTemplate={'100% 115px'} key={timer.id} className={'running-timer'}>
                                <div>
                                    <strong>{timer.timerFor}</strong>
                                    <span style={{color: 'grey'}}>{formatTimeWithoutDate(timer.start, { hour: '2-digit', minute: '2-digit' })} - Nu</span>
                                </div>
                                <LiveTimer start={new Date(timer.start)} breaks={timer.breaks} />

                                <button className={'btn btn--transparent order-timer__stop-timer'} onClick={() => endTimer(order.id, timer.id)}>
                                    <Icon name={'circle-xmark'} />
                                </button>
                            </Grid>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
});

export default RunningOrderTimersPopup;