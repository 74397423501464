import NavigationLink from "./NavigationLink";

export default function NavigationMenu({items, activeRoute, isSubMenu = false, collapsed, setCollapsed, parentId = null, closeNav}) {
    // create the navigation menu by looping through the given navigation items
    const navigationMenu = items?.map((item) =>
        <NavigationLink item={item} activeRoute={activeRoute} key={(item.subMenu ? 'sub-' : '') + item.id} parentId={parentId} collapsable={Boolean(item.subMenu && item.subMenu.length > 0)} collapsed={collapsed} setCollapsed={setCollapsed} closeNav={closeNav} />
    );

    if (isSubMenu) {
        return (
            <div className={`navigation__items--submenu ${collapsed === parentId ? '' : 'navigation__items--submenu--collapsed'}`}>
                {navigationMenu}
            </div>
        )
    } else {
        return (
            <nav className='navigation__items'>
                {navigationMenu}
            </nav>
        )
    }
}
