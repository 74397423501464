import Title from "UI/App/Components/Title/Title";
import Grid from "UI/App/Components/Grid/Grid";
import CompanyDetail from "UI/App/Partials/Content/CRM/Companies/Company/Blocks/CompanyDetails/CompanyDetail";
import Block from "UI/App/Components/Block/Block";
import { formatName } from "App/Util/format";
import { useEffect, useState } from 'react';
import IF from 'UI/App/Components/Conditional/IF';

export default function CompanyBlock({calculation, company = {}, contactPerson = null, otherEntries = [], ...props }) {
    const [websiteURL, setWebsiteURL] = useState('');
    const [telephoneNumber, setTelephoneNumber] = useState('');
    
    useEffect(() => {
        if (typeof company?.website === 'string') {
            setWebsiteURL(company?.website?.trim()?.replace(/^(?!https?:\/\/)/, 'https://'));
        }
    }, [company?.website]);
    
    useEffect(() => {
        if (company?.telephone?.dialingCode !== null && typeof company?.telephone?.telephoneNumber === 'string') {
            setTelephoneNumber(`+${company?.telephone?.dialingCode} ${company?.telephone?.telephoneNumber}`.trim());
        }
    }, [company?.telephone]);

    return (
        <Block
            name='Klant'
            title='Klant'
            {...props}
        >
            {/* Company name */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between'
                }}
            >
                <Title type='h3' className={"notranslate"}>{company?.name}</Title>
                <div className='owner-indicator'>
                    <IF condition={company?.managingCompany?.HJMG}>
                        <span className='circle__big circle-hjmg' />
                    </IF>
                    <IF condition={company?.managingCompany?.Pretore}>
                        <span className='circle__big circle-pretore' />
                    </IF>
                </div>
            </div>

            <Grid columns='1'>
                <div key='1' className='company__column'>
                    {/* KVK */}
                    <CompanyDetail
                        display={company?.kvk}
                        title='kvk'
                        content={[
                            company?.kvk
                        ]}
                    />

                    {/* Branche */}
                    <CompanyDetail
                        display={company?.industry}
                        title='branche'
                        content={[
                            company?.industry
                        ]}
                    />

                    {/* Contact person */}
                    <CompanyDetail
                        display={contactPerson}
                        title='Contact persoon'
                        content={[
                            formatName(contactPerson?.firstName, contactPerson?.prefixToSurname, contactPerson?.surname)
                        ]}
                    />

                    {/* General email */}
                    <CompanyDetail
                        display={company?.generalEmail}
                        title='email'
                        content={[
                            <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + company?.generalEmail} target='_blank' rel='noreferrer'>
                                {company?.generalEmail}
                            </a>
                        ]}
                    />

                    {/* General email */}
                    <CompanyDetail
                        display={company?.invoiceEmail}
                        title='Facturatie e-mailadres'
                        content={[
                            <a href={'https://outlook.office.com/?path=/mail/action/compose&to=' + company?.invoiceEmail} target='_blank' rel='noreferrer'>
                                {company?.invoiceEmail}
                            </a>
                        ]}
                    />

                    {/* Phone number */}
                    <CompanyDetail
                        display={telephoneNumber}
                        title='telefoon'
                        content={[
                            <a href={`tel: ${telephoneNumber}`}>
                                {telephoneNumber}
                            </a>
                        ]}
                    />

                    {/* Website */}
                    <CompanyDetail
                        display={websiteURL}
                        title='website'
                        content={[
                            <a href={websiteURL} target='_blank' rel='noreferrer'>
                                {websiteURL}
                            </a>
                        ]}
                    />

                    {otherEntries.map((entry, index) => (
                        <CompanyDetail
                            key={index}
                            title={entry.title}
                            content={[
                                entry.content
                            ]}
                        />
                    ))}
                </div>
            </Grid>
        </Block>
    )
}
