import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {finalizeOrderChanges, getDateFromUTC, getDependents} from "../../Helpers";
import Drop from "./Drop";

export default function Monthly({visualizeDependencies, type, data, selectedDateMonthly, setData, setPopupCurrentMachine, setPopupFormData, setIsShowMode, isShowMode}) {
    // month translations
    const MONTHS = {
        'januari': 'January',
        'februari': 'February',
        'maart': 'March',
        'april': 'April',
        'mei': 'May',
        'juni': 'June',
        'juli': 'July',
        'augustus': 'August',
        'september': 'September',
        'oktober': 'October',
        'november': 'November',
        'december': 'December',
    };

    //days in the week on index
    const DAYS = [
        'maandag',
        'dinsdag',
        'woensdag',
        'donderdag',
        'vrijdag',
        'zaterdag',
        'zondag'
    ];

    // get month and year from string
    const [month, year] = selectedDateMonthly.split(' ');
    //get date from month and year
    const selectedDate = new Date(`${MONTHS[month]} 1, ${year}`);
    // number of days in selected month
    const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate()
    // number of days in the month prior to the selected one
    const daysInPrevMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 0).getDate()
    // first day of the month ex. monday
    let firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();
    if(firstDayOfMonth === 0) {
        firstDayOfMonth = 7;
    }
    // last day of month ex. monday
    let lastDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDay();
    if(lastDayOfMonth === 0) {
        lastDayOfMonth = 7;
    }
    // how much days are needed at the front of the calender to make a perfect square
    const extraDaysStart = firstDayOfMonth - 1 === 0 ? 7 : firstDayOfMonth - 1;
    // how much days are needed at the end of the calender to make a perfect square
    const extraDaysEnd = 7 - lastDayOfMonth === 0 ? 7 : 7 - lastDayOfMonth;
    // total amount of squares needed
    const squares = extraDaysStart + daysInMonth + extraDaysEnd;

    const [itemList, setItemList] = useState([]);
    const [showModeActivator, setShowModeActivator] = useState(undefined);

    useEffect(() => {
        let warningString;
        if(type === 'machines') {
            warningString = 'Deze machine';
        } else if(type === 'orders') {
            warningString = 'Dit project';
        } else if(type === undefined) {
            warningString = 'Deze weergave'
        } else {
            warningString = type;
        }

        if(!data[0] && !data.machines){
            toast.warning(`${warningString} bevat geen planbare items`);
        }

        // type of data that's being passed (machine / project)
        if((type === 'machines' || type === 'orders' || type === 'all') && Array.isArray(data.machines)) {
            createItemList([...data.machines]);
            return;
        }

        createItemList([]);
    }, [data, selectedDateMonthly]);

    useEffect(() => {
        if(!isShowMode && type === 'orders') {
            showDependencyOptions(null, true);
        }
    }, [isShowMode])

    function createItemList(items) {
        if(data[0] || data.machines) {
            const firstShownDay = new Date(selectedDate);
            firstShownDay.setDate(selectedDate.getDate() - extraDaysStart)
            const lastShownDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, extraDaysEnd);
            const shownMonth = selectedDateMonthly.split(' ')[0];

            // check if planningItem is between shown dates
            // push correct items to the itemlist
            const newItems = [];
            items.forEach((machine) => {
                const machineStart = getDateFromUTC(machine.startDate);
                const machineEnd = getDateFromUTC(machine.endDate);

                if(firstShownDay <= machineStart && lastShownDay >= machineStart || firstShownDay <= machineEnd && lastShownDay >= machineEnd) {
                    let newMachine = {...machine};
                    newMachine.planning = [];
                    machine.planning.forEach((planningItem, planningIndex) => {
                        const planningItemDate = getDateFromUTC(planningItem.date);
                        if(firstShownDay <= planningItemDate && lastShownDay >= planningItemDate) {

                            function getMonthByMonthNumber() {
                                return Object.values(MONTHS)[planningItemDate.getMonth()];
                            }

                            function getMonthlyPosition() {
                                if(MONTHS[shownMonth] === getMonthByMonthNumber()) {
                                    return planningItemDate.getDate() + extraDaysStart - 1;
                                } else if(extraDaysStart + daysInMonth + planningItemDate.getDate() <= squares) {
                                    return extraDaysStart + daysInMonth + planningItemDate.getDate() - 1;
                                }
                                return planningItemDate.getDate() - daysInPrevMonth + extraDaysStart - 1;
                            }

                            function getItemListKeyAtt() {
                                return planningItem.uuid.replace(/\s/g, "");
                            }

                            // Get the amount of days are between two items,
                            // returns a positive number if date2 is smaller then date1.
                            // where returnValue 1 means date1 is one day before date2.
                            function getDaysInBetween(date1, date2) {
                                return (date1 - date2) / (1000 * 60 * 60 * 24);
                            }

                            // check if the current planningItem has a planningItem directly next to it on the right; tomorrow.
                            function getNextConnection() {
                                const nextItem = machine.planning[planningIndex + 1];
                                if(nextItem) {
                                    const nextStart = getDateFromUTC(nextItem.date);
                                    if(getDaysInBetween(nextStart, getDateFromUTC(planningItem.date)) === 1) {
                                        return nextItem.uuid;
                                    }
                                }
                            }

                            // check if the current planningItem has a planningItem directly next to it on the right; tomorrow.
                            function getPrevConnection() {
                                const prevItem = machine.planning[planningIndex - 1];
                                if(prevItem) {
                                    const prevStart = getDateFromUTC(prevItem.date);
                                    if(getDaysInBetween(getDateFromUTC(planningItem.date), prevStart) === 1) {
                                        return prevItem.uuid;
                                    }
                                }
                            }

                            newMachine.planning.push({
                                ...planningItem,
                                position: getMonthlyPosition(),
                                keyAtt: getItemListKeyAtt(),
                                connection: getNextConnection(),
                                prevConnection: getPrevConnection(),
                            });
                        }
                    });
                    newItems.push(newMachine);
                }
            });

            for (const item of newItems) {
                if (item.planning.length > 0) {
                    for (const planDay of item.planning) {
                        planDay.dependents = getDependents(planDay, newItems, firstShownDay, lastShownDay);
                    }
                }
            }

            setItemList(newItems);
        }
    }

    function finalizeChanges(dif, draggingItem) {
        if(dif !== 0) {
            const newData = {...data};
            setData(finalizeOrderChanges(newData, dif, draggingItem));
        }
    }

    function removeDependency(item, uuid) {
        let newData = {...data};
        newData.machines = newData.machines.map((machine) => {
            machine.planning = machine.planning.map((planningItem) => {
                if (planningItem.uuid === item.uuid) {
                    return {
                        ...planningItem,
                        dependsOn: planningItem.dependsOn.filter((dependencyId) => dependencyId !== uuid),
                    };
                }
                return planningItem;
            });
            return machine;
        });
        setData(newData)
    }

    function createDependency(item) {
        const newData = {...data};
        for (const machine of newData.machines) {
            for (const planningItem of machine.planning) {
                if(planningItem.uuid === item.uuid) {
                    planningItem.dependsOn.push(showModeActivator.uuid);
                }
            }
        }
        setData(newData)
        setIsShowMode(false);
    }

    function getTitleDisplay() {
        if(type === 'orders') {
            return data.description;
        } else if(type === 'machines') {
            return data.name;
        }
        return;
    }

    function showDependencyOptions(item, isShowingOptions) {
        const newData = {...data};

        newData.machines.forEach((machine) => {
            machine.planning.forEach((planningItem) => {
                if(isShowingOptions) {
                    planningItem.isDependencyOption = false;
                } else {
                    const dateToCompare = new Date(item.date);
                    dateToCompare.setHours(0, 0, 0, 0);
                    const date = new Date(planningItem.date);
                    date.setHours(0, 0, 0, 0);
                    if(item.uuid !== planningItem.uuid) {
                        if(!planningItem.dependsOn.some(dependency => dependency === item.uuid)) {
                            planningItem.isDependencyOption = true;
                        }
                    }
                }
            })
        });

        setData(newData);
    }

    return (
        <>
            <div className="planning__content__header__month">
                <div className="planning__content__header__info__container">
                    <span className='planning__content__header__info__item'>{getTitleDisplay()}</span>
                </div>
                <div className="planning__content__header__date__container__month">
                    {
                        DAYS.map((day, index) => (
                            <span
                                className="planning__content__header__date__item"
                                key={index}
                            >
                                {day}
                            </span>
                        ))
                    }
                </div>
            </div>

            <Drop
                visualizeDependencies={visualizeDependencies}
                itemList={itemList}
                daysInMonth={daysInMonth}
                squares={squares}
                lastDayOfMonth={lastDayOfMonth}
                finalizeChanges={finalizeChanges}
                extraDaysStart={extraDaysStart}
                daysInPrevMonth={daysInPrevMonth}
                extraDaysEnd={extraDaysEnd}
                removeDependency={removeDependency}
                createDependency={createDependency}
                type={type}
                setPopupCurrentMachine={setPopupCurrentMachine}
                setPopupFormData={setPopupFormData}
                setIsShowMode={setIsShowMode}
                isShowMode={isShowMode}
                setShowModeActivator={setShowModeActivator}
                showDependencyOptions={showDependencyOptions}
            />
        </>
    )
}
