export default function MachineDetail({title, display = true, content}) {
    // if the content (lines) is empty, return nothing
    if (!(content?.length ?? 0) || !display) {
        return null;
    }

    return (
        <div className='material__item'>
            <span className='material-details__title'>{title}</span>
            {
                content?.map((line, index) => {
                    return line ? <div key={`${index}-${line}`} className='material-details__line'>{line}</div> : null;
                })
            }
        </div>
    )
}
