import isObject from 'lodash/isObject';
import {parseTimeToNumber} from "App/Util/format";

export function planningSuffix(suffix, useShorthand = false) {
    switch(suffix) {
        case 'weekly':
            return useShorthand ? ' p/w' : ' per week';
        case 'monthly':
            return useShorthand ? ' p/m' : ' per maand';
        case 'yearly':
            return useShorthand ? ' p/j' : ' per jaar';
        default:
            return '';
    }
}

/**
 * Calculate and set the total cost for the given entry
 * @param entry
 * @param activity
 * @returns {*}
 */
export function calculateTotalEntryCost(entry, activity) {
    /**
     * Get the cost for the given entry and activity
     * @param entry
     * @param activity
     * @returns {number|*|number|number|number}
     */
    const getCost = (entry, activity) => {
        if (activity?.isRecurring) {
            if (entry?.work?.isCustom) {
                return Number(entry?.customHourlyRate ?? entry?.work?.cost);
            } else if (entry?.work?.isChargedHourly === false) {
                return isObject(entry?.work?.cost) ? entry.work.cost[entry?.billingPeriod] ?? 0 : entry?.work?.cost ?? 0;
            } else {
                return entry?.work?.cost ?? 0
            }
        } else {
            return entry?.hourlyRate ?? 0;
        }
    }

    // If the activity is of recurring type, calculate the total cost based on the time and recurring planning.
    if (activity?.isRecurring) {
        // If the price is not charged hourly, calculate the total cost based on the cost and the billing period.
        if (entry?.work?.isChargedHourly === false) {
            entry.total = getCost(entry, activity);
        } else {
            // If the activity is of recurring type and is charged by the hour, calculate the total cost based on the time and recurring planning.
            entry.total = timeRecurrenceToTargetTimeRecurrence(parseTimeToNumber(entry?.time), entry?.recurringTaskPlanning, entry?.billingPeriod) * getCost(entry, activity);
        }
    } else {
        // If the activity is not of recurring type, calculate the total cost based on the time and the hourly rate.
        entry.total = getCost(entry, activity) * parseTimeToNumber(entry?.time);
    }

    return entry;
}

/**
 * Convert time to the given target time
 * @param {number} time
 * @param {"weekly"|"monthly"|"yearly"} currentTimeRecurrence The current time
 * @param {"weekly"|"monthly"|"yearly"} targetTimeRecurrence The time to convert to
 * @returns {number}
 */
export function timeRecurrenceToTargetTimeRecurrence(time, currentTimeRecurrence, targetTimeRecurrence) {
    if (targetTimeRecurrence === 'weekly') {
        switch(currentTimeRecurrence) {
            case 'monthly':
                return time / 4;
            case 'yearly':
                return time / 52;
            default:
                return time;
        }
    } else if (targetTimeRecurrence === 'monthly') {
        switch(currentTimeRecurrence) {
            case 'weekly':
                return time * 4;
            case 'yearly':
                return time / 12;
            default:
                return time;
        }
    } else {
        switch(currentTimeRecurrence) {
            case 'weekly':
                return time * 52;
            case 'monthly':
                return time * 12;
            default:
                return time;
        }
    }
}

export function calculateTotalTime(start, end, breaks) {
    const startDate = new Date(start);
    const endDate = new Date(end ?? new Date());
    const diff = endDate - startDate;
    return Math.ceil((diff - getBreakLength(breaks)) / 1000);
}

export function getBreakLength(breaks) {
    return breaks.reduce((acc, current) => {
        const diff = new Date(current.end ?? new Date()) - new Date(current.start);
        return acc + diff;
    }, 0);
}