import {useEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import {stringify} from 'qs';
import Boolean from 'UI/App/Components/Form/Boolean';
import Icon from 'UI/App/Components/Icon/Icon';
import './pagination.scss';
import useWindowDimensions from "App/Util/useWindowDimensions";
import IF from "UI/App/Components/Conditional/IF";
import { isEmpty } from 'lodash';

/**
 * The partial key used for saving settings to the session storage.
 * This would be used like "{page-specific-key}-${partial-storage-key}";
 * @type {string}
 */
const partialStorageKey = 'pagination-state';

export function Paging({
    page,
    totalPages,
    setCurrentPage = (callback) => {},
}) {
    const [pagingPosition, setPagingPosition] = useState('sticky');

    const prevPage = () => {
        setCurrentPage((prevValue) => (prevValue <= 1 ? 1 : prevValue - 1));
    };

    const nextPage = () => {
        setCurrentPage((prevValue) => (prevValue >= totalPages ? totalPages ?? 1 : prevValue + 1));
    };

    const content = document.getElementsByClassName('content')[0];
    const contentScrollHeight = content?.scrollHeight;
    const contentClientHeight = content?.clientHeight;

    useEffect(() => {
        setPagingPosition(contentScrollHeight > contentClientHeight ? 'sticky' : 'static');
    }, [contentScrollHeight, contentClientHeight])

    return (
        <div 
            className='pagination paging'
            style={{
                position: pagingPosition
            }}
        >
            {/* Page select */}
            <div className='pagination__filter pagination-page-select'>
                <span className='pagination__label pagination__paging__label'>Pagina</span>
                <span className='btn--transparent pagination__button' onClick={prevPage}>
                    <Icon name='chevron-left' />
                </span>
                <span className='pagination__number'>{page ?? '...'}</span>
                <span className='pagination__label'>van</span>
                <span className='pagination__number'>{totalPages ?? '...'}</span>
                <span className='btn--transparent pagination__button' onClick={nextPage}>
                    <Icon name='chevron-right' />
                </span>
            </div>
        </div>
    );
}

export function Filtering({
    totalResults,
    searchEnabled = false,
    setFilterValues = (callback) => {},
    filterValues = {},
    filters = [],
    htmlElements = [],
    page,
    totalPages,
    setCurrentPage = (callback) => {},
    resultsPerPage,
    setResultsPerPage = (callback) => {},
    buttonCollapseBreakpoint = null,
    resultsPerPageBreakpoint = null,
    paginationBreakpoint = null,
    resultCountBreakpoint = null,
    filterPopupBreakpoint = 1000,
}) {
    const searchRef = useRef(null);
    const popupRef = useRef(null);
    const filterButtonRef = useRef(null);
    const { width, height } = useWindowDimensions();
    const debounceRequest = debounce((type, name, value) => {
        updateFilterValues({ [name]: value });
    }, 400);
    const [isFilterPopup, setIsFilterPopup] = useState(false);
    const [popupHeight, setPopupHeight] = useState('fit-content');

    function updateFilterValues(newValues) {
        setFilterValues((prevValue) => ({ ...prevValue, ...newValues }));
    }

    const prevPage = () => {
        setCurrentPage((prevCurrentPage) => prevCurrentPage === 1 ? totalPages : prevCurrentPage - 1)
    };

    const nextPage = () => {
        setCurrentPage((prevCurrentPage) => prevCurrentPage === totalPages ? 1 : prevCurrentPage + 1)
    };

    useEffect(() => {
        if(popupRef.current) {
            // make sure the popup will always be visible 
            // by making it scrollable when the bottom half of the bar overlaps
            setPopupHeight(() => {
                // offset from top of popup to top of page
                const topOffset = popupRef.current.getBoundingClientRect().top
                // offset from bottom of popup to top of page
                const fullOffset = popupRef.current.getBoundingClientRect().bottom
                //height of the paging bar
                const barHeight = 60;

                if(fullOffset + barHeight >= height) {
                    return (height - (topOffset + barHeight))
                }
                return 'fit-content'
            })
        }
    }, [width, height, popupRef, isFilterPopup])

    useEffect(() => {
        if (width >= filterPopupBreakpoint) {
            setIsFilterPopup(false);
        }
    }, [width])

    useEffect(() => {
        // close popup when clicked outside
        const handleClickOutside = (event) => {
            if(!popupRef.current?.contains(event.target) && !filterButtonRef.current?.contains(event.target)) {
                setIsFilterPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFilterPopup])

    function buildCustomFilters(filters) {
        // the resulting filters
        const filterResult = [];

        for (const index in filters) {
            // get the filter by index
            const filter = filters[index];
            // don't render disabled filters
            if (filter.enabled === false) continue;

            // skip empty filters
            if (!filter) continue;

            // check the type
            else if (filter.type === 'select') {
                filterResult.push(
                    <span key={index} className='pagination__filter pagination__select__filter'>
                        {filter?.label && (
                            <label htmlFor={`pagination__filter__${filter.name}`}>
                                {filter.label}
                            </label>
                        )}
                        <select
                            className='filter__select'
                            key={filter.name}
                            name={filter.name}
                            onChange={({ target }) =>
                                updateFilterValues({ [target.name]: target.value })
                            }
                            value={filterValues?.[filter.name] ?? filter?.defaultValue ?? ''}
                        >
                            {filter.options && filter.options.map((option, index) => {
                                if (Array.isArray(option.options)) {
                                    // Optgroup
                                    return (
                                        <optgroup key={index} label={option.name}>
                                            {option.options.map((opt, optionIndex) => (
                                                <option key={optionIndex} value={opt.value}>
                                                    {opt.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    );
                                } else {
                                    // Simple select
                                    return (
                                        <option key={index} value={option.value || option}>
                                            {option.name || option}
                                        </option>
                                    );
                                }
                            })}
                        </select>
                    </span>
                );
            } else if (filter.type === 'boolean') {
                filterResult.push(
                    <span key={index} className='pagination__filter'>
                        {filter?.label && (
                            <label htmlFor={`pagination__filter__${filter.name}`}>
                                {filter.label}
                            </label>
                        )}
                        <Boolean
                            displayFalse='Uit'
                            displayTrue='Aan'
                            value={filterValues?.[filter.name]}
                            defaultValue={filter?.defaultValue}
                            field={{ name: filter.name }}
                            setValue={(name, value) => {
                                updateFilterValues({ [name]: value });
                            }}
                        />
                    </span>
                );
            }
            // use an input with the given type as default
            else {
                filterResult.push(
                    <span key={index} className='pagination__filter'>
                        {filter?.label && (
                            <label htmlFor={`pagination__filter__${filter.name}`}>
                                {filter.label}:
                            </label>
                        )}
                        <input
                            type={filter.type}
                            name={filter.name}
                            onChange={({ target }) => debounceRequest(filter.type, filter.name, target.value)}
                            value={filterValues?.[filter.name] || filter?.defaultValue || ''}
                            defaultValue={filter?.defaultValue}
                        />
                    </span>
                );
            }
        }
        return filterResult;
    }

    const ResultCount = () => (
        width > 500 && <div className='pagination__results'>
            <span className='pagination__results__content'>
                {`${totalResults ?? '...'}`}
                <IF condition={width > resultCountBreakpoint || width <= 800}>
                    <span className="pagination__results__label">
                        {totalResults === 1 ? ' Resultaat' : ' Resultaten'}
                    </span>
                </IF>
            </span>
        </div>
    )

    const RowsPerPage = () => (
        <span className='pagination__filter pagination__select__filter pagination__select__labeled'>
            <select
                className='pagination__page-control filter__select'
                value={resultsPerPage}
                onChange={({ target }) => {
                    setCurrentPage(1);
                    setResultsPerPage(target.value);
                }}
            >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>75</option>
                <option>100</option>
            </select>
        </span>
    )

    const HtmlElements = () => (
        htmlElements
            .filter((element) => element)
            .map((element, key) => (
                <span key={key} className='pagination__filter'>
                    {element}
                </span>
            ))
    )

    const PageSelect = () => (
        <IF condition={width > paginationBreakpoint}>
            <div className='pagination__filter pagination-page-select'>
                <span className='pagination__label pagination__paging__label'>Pagina</span>
                <span className='btn--transparent pagination__button' onClick={prevPage}>
                    <Icon name='chevron-left' />
                </span>
                <span className='pagination__number'>{page ?? '...'}</span>
                <span className='pagination__label'>van</span>
                <span className='pagination__number'>{totalPages ?? '...'}</span>
                <span className='btn--transparent pagination__button' onClick={nextPage}>
                    <Icon name='chevron-right' />
                </span>
            </div>
        </IF>
    );

    const customFilters = buildCustomFilters(filters);

    return (
        <div className='pagination filtering'>
            <div className={
                "pagination__filters "
                + (width < Number(buttonCollapseBreakpoint) && width > 800 ? 'buttons-collapsed ' : ' ')
                + (width < Number(resultsPerPageBreakpoint) ? 'results-collapsed ' : ' ')
            }>

                <div 
                    className="pagination__section__left"
                    style={{
                        width: `${width <= 800 ? '100%' : ''}`
                    }}
                >

                    <ResultCount/>
                    
                    {searchEnabled && (
                        <span 
                            className='pagination__search'
                            style={{
                                width: `${width <= 800 ? '100%' : ''}`,
                                minWidth: `${width <= 500 ? '100px' : ''}`
                            }}
                        >
                            <input
                                type='text'
                                ref={searchRef}
                                placeholder='Zoeken...'
                                name='search'
                                className='input--search'
                                onInput={({ target }) => debounceRequest(target.type, target.name, target.value)}
                                defaultValue={filterValues?.['search']}
                            />
                        </span>
                    )}

                    {(width > filterPopupBreakpoint) && 
                        <>
                            {/* Type select */}
                            {customFilters}
                            <RowsPerPage/>
                        </>
                    }
                </div>

                <div className="pagination__section__right">
                    {width > 800 && <>
                        <HtmlElements/>
                        <PageSelect/>
                    </>}
                    {(width < filterPopupBreakpoint) && (
                        <span className='pagination__filter addItem' key='link--add'>
                            <button
                                ref={filterButtonRef}
                                onClick={() => setIsFilterPopup(prev => !prev)} 
                                name="FILTER" 
                                className='btn btn--primary btn--icon-right'
                            >
                                <Icon name='filter'/>
                            </button>
                        </span>
                    )}
                </div>
                
                { isFilterPopup && <div 
                    ref={popupRef}
                    className="filter__popup"
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: '100%',
                        background: 'white',
                        width: 'fit-content',
                        height: popupHeight,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        padding: '20px',
                        borderBottom: '1px solid var(--dark-grey)',
                        borderLeft: '1px solid var(--dark-grey)',
                        // ability to scroll vertically in case screen height is smaller than window height
                        overflowY: 'scroll',
                        overflowX: 'auto',
                        zIndex: 100,
                    }}
                >
                    {(width <= filterPopupBreakpoint) && <div className="filter__popup__container">
                        <div 
                            className="container__title"
                            style={{
                                color: 'var(--primary)',
                                marginBottom: '5px'
                            }}
                        >
                            <b>
                                <span>
                                    Filters:
                                </span>
                            </b>
                        </div>

                        <div
                            className='filter__popup__items'
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            {customFilters}
                            <RowsPerPage/>
                        </div>
                    </div>}
                    {(width <= 800 && !isEmpty(HtmlElements())) && <div className="filter__popup__container">
                        <div 
                            className="container__title"
                            style={{
                                color: 'var(--primary)',
                                marginBottom: '5px'
                            }}
                        >
                            <b>
                                <span>
                                    Acties:
                                </span>
                            </b>
                        </div>

                        <div
                            className='filter__popup__items'
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            <HtmlElements/>
                        </div>
                    </div>}
                </div>}
            </div>
        </div>
    );
}

/**
 * @param {string|boolean} storageKey
 * @param {{enabled: boolean; strapiFields: Array<string>; strapiFilter?: string}} searchSettings
 * @param {{name: string, type: 'select'|'input', options?: Array<{value: string; name: string}>, defaultValue?: any, enabled?: boolean; strapiFilter: string; strapiFilterFields: Array<any> }[]} filters
 * @param {Array<JSX.Element>} htmlElements
 * @param {number|null} buttonCollapseBreakpoint The breakpoint at which the buttons should collapse
 * @param {number|null} resultsPerPageBreakpoint The breakpoint at which the results per page should collapse
 * @param {number|null} paginationBreakpoint The breakpoint at which the pagination should be hidden
 * @param {number|null} resultCountBreakpoint The breakpoint at which the result count should be hidden
 * @param {number} filterPopupBreakpoint The breakpoint at which the filters should be displayed in an popup
 * @returns {{filterValues: {}, paginationStateLoaded: boolean, resultsPerPage: number, setTotalResults: (value: unknown) => void, paging: JSX.Element, currentPage: number, filtering: JSX.Element, setTotalPages: (value: unknown) => void, filterQuery: string}}
 */
export function usePagination({
    storageKey = false,
    searchSettings = {
        enabled: false,
        strapiFields: [],
        strapiFilter: '$containsi'
    },
    filters = [],
    htmlElements = [],
    buttonCollapseBreakpoint = null,
    resultsPerPageBreakpoint = null,
    paginationBreakpoint = null,
    resultCountBreakpoint = null,
    filterPopupBreakpoint = 1000,
} = {}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage, setResultsPerPage] = useState(25);

    const [totalPages, setTotalPages] = useState(null);
    const [totalResults, setTotalResults] = useState(null);

    const [filterValues, setFilterValues] = useState({});
    const [filterQuery, setFilterQuery] = useState('');

    const [paginationStateLoaded, setPaginationStateLoaded] = useState(false);

    useEffect(() => {
        const navigationState = getNavigationState(storageKey);

        if (navigationState === false) {
            setPaginationStateLoaded(true);
            return;
        }

        setCurrentPage(navigationState.currentPage);
        setResultsPerPage(navigationState.resultsPerPage);
        setFilterValues(navigationState.filterValues);

        // 10 ms timeout to give the above states some time
        setTimeout(() => {
            setPaginationStateLoaded(true);
        }, 10);
    }, [storageKey]);

    // trigger save state
    useEffect(() => {
        savePaginationState(resultsPerPage, currentPage, filterValues, storageKey);
    }, [filterValues, resultsPerPage, currentPage, storageKey]);

    useEffect(() => {
        if (paginationStateLoaded) {
            setCurrentPage(1);
        }

        setFilterQuery(buildFilterQuery(filterValues, filters, searchSettings));
    }, [filterValues]);

    function reloadData() {
        setFilterValues((prevValue) => ({ ...prevValue }));
    }

    const paging = (
        <Paging
            page={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}
            htmlElements={htmlElements}
        />
    );

    const filtering = (
        <Filtering
            page={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
            setResultsPerPage={setResultsPerPage}

            totalResults={totalResults}
            searchEnabled={searchSettings.enabled}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            filters={filters}
            htmlElements={htmlElements}
            buttonCollapseBreakpoint={buttonCollapseBreakpoint}
            resultsPerPageBreakpoint={resultsPerPageBreakpoint}
            paginationBreakpoint={paginationBreakpoint}
            resultCountBreakpoint={resultCountBreakpoint}
            filterPopupBreakpoint={filterPopupBreakpoint}
        />
    );

    return {
        paging,
        filtering,
        currentPage,
        setCurrentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded,
        setFilterValues,
        reloadData
    };
}

/**
 * Saves the current stage of the pagination to the session storage
 * @param resultsPerPage
 * @param currentPage
 * @param filterValues
 * @param storageKey
 */
function savePaginationState(resultsPerPage, currentPage, filterValues, storageKey) {
    if (storageKey === false) {
        return false;
    }

    sessionStorage.setItem(
        `${storageKey}-${partialStorageKey}`,
        JSON.stringify({
            resultsPerPage,
            currentPage,
            filterValues
        })
    );
}

/**
 * Get the saved navigation state by key and return it
 * @param storageKey
 * @returns {{filterValues: any, resultsPerPage: any, currentPage: any}|false}
 */
function getNavigationState(storageKey) {
    if (storageKey === 'false') {
        return false;
    }

    // get the navigation state from the session storage
    const navigationState = sessionStorage.getItem(`${storageKey}-${partialStorageKey}`);

    // make sure a navigation state was saved
    if (navigationState === null) {
        return false;
    }

    try {
        const { currentPage, resultsPerPage, filterValues } = JSON.parse(navigationState);

        return {
            currentPage,
            resultsPerPage,
            filterValues
        };
    } catch (e) {
        return false;
    }
}

function buildFilterQuery(filterValues, filters, searchSettings) {
    let queryParams = {
        filters: {
            $and: []
        }
    };

    const specialCharFilterDict = {
        '0-9': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        '#': [
            '!',
            '@',
            '#',
            '$',
            '^',
            '&',
            '*',
            '(',
            ')',
            '-',
            '=',
            '+',
            '[',
            '{',
            ']',
            '}',
            ';',
            ':',
            "'",
            '"',
            '\\',
            '|',
            ',',
            '<',
            '.',
            '>',
            '/',
            '?',
            ' '
        ] // Should be changed to work with regex at some point "[^a-z0-9]" | some characters are excluded due to giving unexpected results
    };

    // handle custom callbacks
    if (filters.length > 0) {
        filters.forEach((filter) => {
            // if the filter has a custom function call it and remove its value from filterValues
            if (filter?.callbackFunction !== undefined) {
                // get the custom query part
                const queryPart = filter.callbackFunction({ ...filter, currentValue: filterValues[filter.name] })
                queryParams.filters['$and'].push(queryPart)
            }
        })
    }


    // use the default values if the filter values are empty
    if (Object.keys(filterValues).length === 0) {
        filters.forEach((filter) => {

            if (filter.enabled === false) {
                filterValues[filter.name] = '*';
            }
            // if the filter has a defaultValue set > set this as the filter value
            else if (filter?.defaultValue !== undefined) {
                filterValues[filter.name] = filter.defaultValue;
            }
            // if the filter doesn't have a defaultValue, and is of type select, and has options > Use the first value
            else if (filter?.type === 'select' && filter?.options.length > 0) {
                const firstValue = Object.values(filter.options)[0];
                filterValues[filter.name] =
                    firstValue?.value !== undefined ? firstValue.value : firstValue;
            }
        });
    }

    for (const filterField in filterValues) {

        // get the original filter settings
        const filterSettings = filters.find((filter) => filter.name === filterField);

        // if the filter is disabled > skip
        if (filterSettings?.enabled === false) continue;

        // Skip filters with a custom callback function
        if (filterSettings?.callbackFunction) continue;

        // get the filter value. if the filter value is part of the special filter dictionary, use that value
        const filterValue = specialCharFilterDict?.[filterValues[filterField]]
            ? specialCharFilterDict[filterValues[filterField]]
            : filterValues[filterField];

        // if the filter value is *, skip this filter
        if (['*', '', null, undefined].includes(filterValue)) {
            continue;
        }

        // search filter settings
        if (filterField === 'search') {
            // create a new base filter object

            // filterValue in search is always a string. so split by spaces to allow searching with multiple words in multiple fields
            // loop through each word
            for (const word of filterValue.toString().split(' ')) {
                if (word === '') {
                    continue;
                }

                const filter = { $or: [] };

                // loop through the strapi fields
                for (const strapiSearchField of searchSettings.strapiFields) {
                    // append each field to the or filter
                    filter['$or'].push({
                        [strapiSearchField]: {
                            [filterSettings?.strapiFilter ?? '$containsi']: word
                        }
                    });
                }

                // append the filter object to the query params
                queryParams.filters['$and'].push(filter);
            }

            // go to the next value
            continue;
        }

        // check if there are custom filter fields set
        if (filterSettings?.strapiFilterFields) {
            // create a new base filter object
            const filter = { $or: [] };
            // loop through the strapi fields
            filterSettings.strapiFilterFields.forEach((strapiFilterField) => {
                // append each field to the or filter
                if (Array.isArray(filterValue)) {
                    filterValue.forEach((value) => {
                        filter['$or'].push({
                            [strapiFilterField]: {
                                [filterSettings?.strapiFilter ?? '$eq']: value
                            }
                        });
                    });
                } else {
                    filter['$or'].push({
                        [strapiFilterField]: {
                            [filterSettings?.strapiFilter ?? '$eq']: filterValue
                        }
                    });
                }
            });
            // append the filter object to the query params
            queryParams.filters['$and'].push(filter);
        } else {
            // append the filter value to the query params
            queryParams.filters['$and'].push({
                [filterField]: {
                    [filterSettings?.strapiFilter ?? '$eq']: filterValue
                }
            });
        }
    }

    // build the query
    const query = stringify(queryParams, {
        encodeValuesOnly: true // prettify URL
    });

    return query;
}
