import Grid from "UI/App/Components/Grid/Grid";
import {formatSeconds, formatTimeWithoutDate} from "App/Util/format";
import React, {useEffect, useState} from "react";
import "./breaks.scss";

export default function Breaks({
    breaks
}) {

    const [breakList, setBreakList] = useState([]);

    useEffect(() => {
        const formatBreakList = () => {
            setBreakList(breaks.map((breakI) => {
                const startTime = new Date(breakI.start);
                const endTime = new Date(breakI.end ?? new Date());

                const breakLength = (endTime - startTime) / 1000;

                return {
                    ...breakI,
                    length: breakLength
                }
            }));
        }

        formatBreakList();

        const interval = setInterval(formatBreakList, 1000);

        return () => clearInterval(interval);
    }, [breaks]);

    return (
        <div className={'timer__breaks'}>
            <Grid columns={2} customColTemplate={'100% 75px'} className={'timer__breaks__total'}>
                <strong>PAUZES:</strong>
                <span>{formatSeconds(breakList.reduce((acc, breakI) => acc + breakI.length, 0), false)}</span>
            </Grid>
            {breakList.map((breakI) => {
                const startTime = formatTimeWithoutDate(breakI.start, {hour: '2-digit', minute: '2-digit'});
                const endTime = breakI.end ? formatTimeWithoutDate(breakI.end, {hour: '2-digit', minute: '2-digit'}) : 'Nu';

                return (
                    <Grid key={breakI.id} columns={2} customColTemplate={'100% 75px'} className={'timer__break'}>
                        <span>{startTime} - {endTime}</span>
                        <span>{formatSeconds(breakI.length)}</span>
                    </Grid>
                )
            })}
        </div>
    )
}