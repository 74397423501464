import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FetchContext } from "App/Strapi/FetchContext";
import Icon from 'UI/App/Components/Icon/Icon';
import Inputs from "UI/App/Components/Form/Inputs";
import { toast } from "react-toastify";
import Block from "../../../../Components/Block/Block";
import { stringify } from 'qs';

export default function CreateManager() {
    const [inputs, setInputs] = useState([]);
    const [currentManagerType, setCurrentManagerType] = useState([]);
    const fetchProvider = useContext(FetchContext);
    const authAxios = fetchProvider.authAxios;

    const [usersOptions, setUsersOptions] = useState({});
    const [defaultUserOptions, setDefaultUserOptions] = useState([])

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        // get all users that are able to manage
        authAxios.get('/crm/managers/viable-managers').then((data) => {
            let formatedUsers = [];

            for (let user of data.data) {
                formatedUsers.push({ value: user.id, label: user.username })

            }
            setUsersOptions(formatedUsers);
        })

        if (params.id !== undefined) {
            let queryParams = {
                populate: {
                    usersWithAccess: true,

                },

            };
            const query = stringify(queryParams);
            authAxios.get(`/crm/managers/${params.id}?${query}`).then(({ data }) => {
                let formatedUsers = [];
                for (const user of data.data.usersWithAccess) {
                    formatedUsers.push({ value: user.id, label: user.username })
                }

                setDefaultUserOptions(formatedUsers);
                setCurrentManagerType(data.data)

            })
        } else {
            setInputData();
        }
    }, [authAxios]);

    useEffect(() => {
        setInputData()
    }, [currentManagerType, currentManagerType?.allowMultiple, defaultUserOptions, usersOptions]);
    function setInputData() {
        const inputs = [
            {
                type: 'text',
                name: 'name',
                label: 'Naam',
                value: currentManagerType?.name,
                onChange: e => { handleChange(e) },
                placeholder: 'Naam...',
                required: true,
            },
            {
                type: 'text',
                name: 'description',
                label: 'Omschrijving',
                onChange: e => { handleChange(e) },
                placeholder: 'Omschrijving...',
                value: currentManagerType?.description,
                required: true,
            },
            {
                type: 'radio',
                name: 'allowMultiple',
                label: 'Meerder Managers',
                onChange: e => { handleChange(e) },
                options: [{ value: true, text: "Ja", checked: currentManagerType?.allowMultiple === true && true }, { value: false, text: "Nee", checked: currentManagerType?.allowMultiple === false && true }],
                required: true,
            }, {
                label: 'Managers bij dit type',
                type: 'multiSelect',
                options: usersOptions,
                value: defaultUserOptions,
                closeMenuOnSelect: false,
                isMulti: true,
                fullEvent: true,
                onChange: (e) => { handleChange({ name: 'usersWithAccess', value: e }) }

            },
            {
                type: 'submit',
                name: 'submit',
                class: 'btn'
            },
        ];
        setInputs(inputs);
    }
    function handleChange(e) {
        // do something diffrent if we get data from the select
        if (e.name === 'usersWithAccess') {

            // format for the strapi side, this way we can pass this without extra steps
            let formatedValue = [];
            // if we are allow mutiple
            for (const user of e.value) {
                formatedValue.push(user.value)
            }
            e.value = formatedValue;

            // get the user belonging to the id
            let completeUser = [];
            completeUser = usersOptions.filter((el) => {
                // if the selected user has an id of know users
                if (e.value.includes(el.value)) {
                    return el
                }
            })
            setDefaultUserOptions(completeUser);

        }

        // set the object belong to this manager type
        setCurrentManagerType(prev => ({
            ...prev,
            [e.name]: e.value
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (params.id !== undefined) {
            authAxios.put(`crm/managers/${params.id}`, { data: currentManagerType }).then(({ data }) => {
                toast.success(`Manger typen aangepast!`);
                navigate("/admin/managers");
            }).catch((exception) => {
                toast.error(`Manager Type kon niet worden opgeslagen`);
                console.error(exception);
            });
        } else {
            authAxios.post(`crm/managers`, { data: currentManagerType }).then(({ data }) => {
                toast.success(`Manger typen aangemaakt!`);
                navigate("/admin/managers");
            }).catch((exception) => {
                toast.error(`Manager Type kon niet worden opgeslagen`);
                console.error(exception);
            });
        }

    }
    return (
        <Block name={'CreateCompany'} title={<>
            <Link className={'btn btn--transparent'} to={`/admin/managers`}><Icon name={'arrow-left'} /></Link>
            {params.id !== undefined ? "Manager type aanpassen" : "Manager type toevoegen"}
        </>}>
            {/* <Link className={'btn btn--transparent'} style={{ color: "var(--primary)", paddingLeft: 0 }} to={`/admin/managers`}><Icon name={'angle-left'} /> Terug </Link> */}
            <form onSubmit={handleSubmit}>
                <Inputs inputs={inputs} />

                {/* <Select options={usersOptions} value={defaultUserOptions} closeMenuOnSelect={false} isMulti={true} onChange={(e) => { handleChange({ name: 'usersWithAcces', value: e }) }} /> */}
            </ form>
        </Block>
    );
}
