import {useEffect, useRef, useState} from "react";
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import {formatTime} from "../../Helpers";
import {openPopup} from "UI/App/Components/Popup/Popup";

export default function Draggable({name,setPopupCurrentMachine, setPopupFormData, visualizeDependencies,item, position, content, keyAtt, onItemDrag, className, date, colorIndex, dependsOn, prevItem, first, removeDependency, createDependency, samePosIndex, squares, onClick, height, conflicts, type, setIsShowMode, isShowMode, setShowModeActivator, showDependencyOptions, isDependencyOption}) {
    // the item that's being dragged
    const [draggingItem, setDraggingItem] = useState(null);
    // the x position of the item in the grid
    const [draggingX, setDraggingX] = useState((position % 7) + 1);
    // the y position of the item in the grid
    const [draggingY, setDraggingY] = useState(Math.ceil((position + 1) / 7));

    const errorPopup = useRef(null);
    const [errorPopupVisible, setErrorPopupVisible] = useState(false);
    // small debounce to stop twitching when dragging
    const debounceHandleDrag = debounce(handleDrag, 1);

    // handle conflicts div position
    useEffect(() => {
        if (errorPopup.current && errorPopupVisible) {
            const errorPopupHeight = errorPopup.current.getBoundingClientRect().height;
            // set div to above machine
            errorPopup.current.style.top = `-${errorPopupHeight}px`;

            // get updated y value
            const errorPopupYtop = errorPopup.current.getBoundingClientRect().y;

            // if conflict div y position is lower than header height
            if (errorPopupYtop < 60) {
                // set div to below machine
                errorPopup.current.style.top = '30px';
            }
        }
    }, [errorPopupVisible]);

    // when draggingX or -Y changes
    useEffect(() => {
        // and something is being dragged
        if(draggingItem !== null) {
            // call onItemDrag in parent
            onItemDrag((draggingX - 1 + (draggingY * 7) - 7), position, item)
            if(draggingX === 7) {
                setDraggingItem(null);
            }
        }
    }, [draggingX, draggingY]);

    useEffect(() => {
        setDraggingX((position % 7) + 1);
        setDraggingY(Math.ceil((position + 1) / 7));
    }, [item])

    function handleDragStart(e) {
        // set custom drag image to remove the ghost object when dragging
        const customDragImage = document.createElement('div');
        customDragImage.setAttribute('id', 'custom-drag-image')
        customDragImage.classList.add('custom__drag__image');
        document.body.appendChild(customDragImage);
        e.dataTransfer.setDragImage(customDragImage, 0, 0);

        // set dragging item to item
        setDraggingItem(item);
    }

    function handleDrag(e) {
        if (draggingItem && e.clientX !== 0 && e.clientY !== 0) {
            const initialDragX = (e.target.getBoundingClientRect().left - (e.target.clientWidth * ((draggingX - 1) % 7)));
            // const initialDragY = (e.target.getBoundingClientRect().top - (height * ((draggingY - 1) % 7)) - (samePosIndex !== undefined ? samePosIndex * 30 : 30));
            const initialDragY = (e.target.getBoundingClientRect().top - (210 * (draggingY - 1)) - 30);

            // width of the object being dragged
            const objectWidth = e.target.clientWidth;
            // x coordinates of the mouse
            const mouseX = e.clientX - initialDragX;
            // y coordinates of the mouse
            const mouseY = e.clientY - initialDragY;
            // target position x
            const hoverPositionX = Math.floor(mouseX / objectWidth);
            // target position y
            const hoverPositionY = Math.floor(mouseY / height);

            if (
                hoverPositionX >= 0 &&
                hoverPositionX < 7 &&
                hoverPositionY >= 0 &&
                hoverPositionY < (squares / 7)
            ) {
                setDraggingX(hoverPositionX + 1);
                setDraggingY(hoverPositionY + 1);
            }
        }
    }

    function handleDragEnd(e) {
        // remove ghost element
        try {
            document.body.removeChild(document.getElementById('custom-drag-image'));
        } catch (err) {}
        e.preventDefault();
        // set dragging element to null
        setDraggingItem(null);
    }

    function getMachineBlockColTemplate() {
        let template = '1fr';
        // prepend 22px if conflicts are present
        if (first && !isEmpty(conflicts)) template = '22px ' + template;

        // prepend 22px if it is the first machine with a previous machine available
        if (first && prevItem !== undefined) template = '22px ' + template;

        return template;
    }

    function getZIndexValue() {
        if(isShowMode && isDependencyOption) {
            return 101;
        } else {
            if(errorPopupVisible) {
                return 2;
            } else {
                return 'auto';
            }
        }
    }

    function loadItemContent() {
        if(content) {
            return(
                <div
                    key={'draggable-content-' + keyAtt}
                    className={`planning__content__item__content ${'bg-' + colorIndex}`}
                    draggable='true'
                    onDragStart={(e) => handleDragStart(e)}
                    onDrag={(e) => debounceHandleDrag(e)}
                    onDragEnd={(e) => handleDragEnd(e)}
                    onClick={(e) => {
                        //! same as weekly
                        if(isShowMode) {
                            e.stopPropagation();
                            createDependency(item);
                        } else {
                            setPopupCurrentMachine(
                                {
                                    machineId: item.machineId,
                                    projectId: item?.projectId,
                                    name: item.name,
                                    uuid: item.uuid,
                                    time: item.time,
                                    note: item.note
                                }
                            );
                            setPopupFormData({});
                            openPopup('draggable-click-popup');
                        }
                    }}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsShowMode((prev) => !prev);
                        setShowModeActivator(item);
                        showDependencyOptions(item, isShowMode);
                    }}
                >
                    <span className='planning__content__item__content__text'>
                        {name + ' ' + formatTime(item.time)}
                    </span>
                </div>
            );
        }
        return(
            <div className="planning__content__item__content__number">
                {date}
            </div>
        );
    }

    function loadDependencies() {
        if(!visualizeDependencies) return;

        return dependsOn.map((dependency, index) => {

            function createArrow(className, width, height, top, right, bottom, left) {
                const commonProps = {
                    onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeDependency(item, dependency.id);
                    },
                    onClick: (e) => e.stopPropagation(),
                    key: item.uuid + '-' + index,
                };

                return (
                    <div
                        {...commonProps}
                        className={className}
                        style={{
                            width: width,
                            height: height,
                            top: top,
                            right: right,
                            bottom: bottom,
                            left: left,
                        }}
                    />
                )
            }

            const arrowHeight = `7px`;
            const itemHeight = `30px`;
            const padding = `2px`;
            const squareHeight= `190px`;
            if(dependency.width > 0) {
                if((dependency.height === 0 && dependency.yAlign > samePosIndex) || dependency.height > 0) {
                    //top left
                    return createArrow(
                        `dependency__arrow__top__left`,
                        `calc((-${arrowHeight} + ${padding}) + 50% + (100% * ${Math.abs(dependency.width) - 1}))`,
                        `calc(-${arrowHeight} + ${dependency.yAlign - samePosIndex} * (${itemHeight} + ${padding}) + ${Math.abs(dependency.height)} * (${squareHeight} + ${padding}))`,
                        `${arrowHeight}`,
                        `calc(-50% + (${arrowHeight} - ${padding}) - (100% * ${Math.abs(dependency.width) - 1}))`,
                        null,
                        null,
                    );
                } else if((dependency.height === 0 && dependency.yAlign < samePosIndex) || dependency.height < 0) {
                    //bottom left
                    return createArrow(
                        `dependency__arrow__bottom__left`,
                        `calc((${arrowHeight} + (${padding} * 2)) + 50% + (100% * ${Math.abs(dependency.width) - 1}))`,
                        `calc((-${itemHeight} + ${arrowHeight} + ${padding}) + (${samePosIndex - dependency.yAlign} * (${itemHeight} + ${padding})) + (${Math.abs(dependency.height)} * (${squareHeight} + ${padding})))`,
                        null,
                        `calc((-${arrowHeight} - (${padding} * 2)) - 50% - (100% * ${Math.abs(dependency.width) - 1}))`,
                        `calc(${itemHeight} - ${arrowHeight} - ${padding})`,
                        null,
                    );
                } else if(dependency.yAlign === samePosIndex) {
                    const className = `dependency__arrow__middle__left`;
                    if(dependency.width === 1) {
                        //straight left direct
                        return createArrow(
                            className,
                            `10px`,
                            `${padding}`,
                            `${arrowHeight}`,
                            `-${arrowHeight}`,
                            null,
                            null
                        );
                    } else {
                        //straight left
                        return createArrow(
                            className,
                            `calc((${arrowHeight} - ${padding}) + (100% * ${Math.abs(dependency.width) - 1}))`,
                            `${padding}`,
                            `${arrowHeight}`,
                            `calc((-${arrowHeight} + ${padding}) - (100% * ${dependency.width - 1}))`,
                            null,
                            null
                        );
                    }
                }
            } else if(dependency.width < 0) {
                if((dependency.height === 0 && dependency.yAlign > samePosIndex) || dependency.height > 0) {
                    //top right
                    return createArrow(
                        `dependency__arrow__top__right`,
                        `calc((${arrowHeight} + (${padding} * 2)) + 50% + (100% * ${Math.abs(dependency.width) - 1}))`,
                        `calc((-100% + ${arrowHeight} + ${padding}) + ${dependency.yAlign - samePosIndex} * (${itemHeight} + ${padding}) + ${Math.abs(dependency.height)} * (${squareHeight} + ${padding}))`,
                        `calc(100% - ${arrowHeight} - ${padding})`,
                        null,
                        null,
                        `calc(-50% + (-${arrowHeight} - ${padding} * 2) - (100% * ${Math.abs(dependency.width) - 1}))`,
                    );
                } else if((dependency.height === 0 && dependency.yAlign < samePosIndex) || dependency.height < 0) {
                    //bottom right
                    return createArrow(
                        `dependency__arrow__bottom__right`,
                        `calc((-${arrowHeight} + ${padding}) + 50% + (100% * ${Math.abs(dependency.width) - 1}))`,
                        `calc((-${arrowHeight}) + (${samePosIndex - dependency.yAlign} * (${itemHeight} + ${padding})) + (${Math.abs(dependency.height)} * (${squareHeight} + ${padding})))`,
                        null,
                        null,
                        `calc(${arrowHeight})`,
                        `calc(-50% + (${arrowHeight} - ${padding}) - (100% * ${Math.abs(dependency.width) - 1}))`,
                    );
                } else if(dependency.yAlign === samePosIndex) {
                    const className = `dependency__arrow__middle__right`;
                    if(dependency.width === -1) {
                        //straight right direct
                        return createArrow(
                            className,
                            `10px`,
                            `${padding}`,
                            null,
                            null,
                            `${arrowHeight}`,
                            `calc(-${arrowHeight} + ${padding})`,
                        );
                    } else {
                        //straight right
                        return createArrow(
                            className,
                            `calc((${arrowHeight} - ${padding}) + (100% * ${Math.abs(dependency.width) - 1}))`,
                            `${padding}`,
                            `calc(${itemHeight} - ${arrowHeight} - ${padding})`,
                            null,
                            null,
                            `calc((-${arrowHeight} + ${padding}) - (100% * ${Math.abs(dependency.width) - 1}))`,
                        );
                    }
                }
            } else if(dependency.width === 0) {
                if((dependency.height === 0 && dependency.yAlign > samePosIndex) || dependency.height > 0) {
                    const className = `dependency__arrow__middle__up`;
                    if(dependency.height === 0 && dependency.yAlign - samePosIndex === 1) {
                        //middle up direct
                        return createArrow(
                            className,
                            `${padding}`,
                            `calc(10px + ${padding})`,
                            `calc(${itemHeight} - ${arrowHeight})`,
                            `calc(50% + ${arrowHeight})`,
                            null,
                            null,
                        );
                    } else {
                        //middle up
                        return createArrow(
                            className,
                            `${padding}`,
                            `calc(${padding} + (${itemHeight} + ${padding}) * ${dependency.yAlign - samePosIndex - 1} + (${squareHeight} + ${padding}) * ${Math.abs(dependency.height)})`,
                            `100%`,
                            `calc(50% + ${arrowHeight})`,
                            null,
                            null,
                        );
                    }
                } else if((dependency.height === 0 && dependency.yAlign < samePosIndex) || dependency.height < 0) {
                    const className = `dependency__arrow__middle__down`;
                    if(dependency.height === 0 && dependency.yAlign - samePosIndex === -1) {
                        //middle down direct
                        return createArrow(
                            className,
                            `${padding}`,
                            `50%`,
                            `-10px`,
                            `calc(50% - (${arrowHeight} + ${padding}))`,
                            null,
                            null,
                        );
                    } else {
                        //middle down
                        return createArrow(
                            className,
                            `${padding}`,
                            `calc(${padding} + (${itemHeight} + ${padding}) * ${samePosIndex - dependency.yAlign - 1} + (${squareHeight} + ${padding}) * ${Math.abs(dependency.height)})`,
                            null,
                            `calc(50% - (${arrowHeight} + ${padding}))`,
                            `100%`,
                            null,
                        );
                    }
                }
            }
            return;
        })
    }

    return (
        <>
            <div
                className={className}
                key={'draggable-' + keyAtt}
                id={keyAtt}
                style={{
                    gridColumn: `${(position % 7) + 1} / span 1`,
                    gridRow: `${Math.ceil((position + 1) / 7)} / span 1`,
                    zIndex: getZIndexValue(),
                }}
            >
                {loadItemContent()}
                {loadDependencies()}
            </div>
        </>
    )
}
