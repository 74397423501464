import './Sidebar.scss';
import Title from "UI/App/Components/Title/Title";
import Icon from "UI/App/Components/Icon/Icon";
import { useEffect, useState, useCallback } from 'react';
import IF from "UI/App/Components/Conditional/IF";

/**
 * Insert a sidebar
 *
 * @export default
 * @param {boolean} position The position of the sidebar.
 * @param style
 * @param {JSX.Element} children The content.
 * @param {string} sidebarID ID of the sidebar.
 * @param {string} [title=''] The title for the sidebar header.
 * @param {number} contentIndex The index of the current content. used with multiple steps for content inside the popup.
 * @param {function} backButtonIndexSetter The state setter that sets the contentIndex number.
 * @param closeable
 * @param {boolean} [escapable=true] `false` to disable the escape key to close the sidebar.
 * @return {JSX.Element} Popup html.
 */
export default function Sidebar({
    position,
    style = {},
    contentStyle,
    children,
    sidebarID,
    title = '',
    contentIndex,
    backButtonIndexSetter,
    closeable = true,
    isOpen,
    setIsOpen,
    escapable = true
}) {
    const [backButtonVisibility, setBackButtonVisibility] = useState(false);

    useEffect(() => {
        setBackButtonVisibility(contentIndex > 0);
    }, [contentIndex]);

    const handleKeydown = useCallback(({ key }) => {
        if (key === 'Escape' && closeable) {
            setIsOpen(false);
        }
    }, [closeable, setIsOpen]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeydown);
        } else {
            document.removeEventListener('keydown', handleKeydown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [isOpen, handleKeydown]);

    return (
        <>
            <div
                className='emily-overlay'
                style={{ display: isOpen ? 'block' : 'none' }}
                id='emily-overlay'
                onClick={() => { if (closeable && escapable) setIsOpen(false); }}
            ></div>
            <div
                key={sidebarID}
                style={style}
                className={`sidebar ${isOpen ? 'open' : ''} sidebar-${position}`}
                id={sidebarID}
            >
                <div key='header' className='sidebar__header'>
                    <button
                        key='goBack'
                        className='btn--transparent sidebar__button'
                        style={{ visibility: backButtonVisibility ? 'visible' : 'hidden' }}
                        onClick={() => goBack(backButtonIndexSetter)}
                    >
                        <Icon name='arrow-left'></Icon>
                    </button>

                    <Title key='title' type="h3" className="sidebar__title">{title}</Title>

                    <button
                        style={closeable ? {} : { pointerEvents: 'none' }}
                        className='btn--transparent sidebar__button'
                        onClick={() => { if (closeable) setIsOpen(false); }}
                    >
                        <IF condition={closeable}>
                            <Icon name='times'></Icon>
                        </IF>
                    </button>
                </div>
                <div key='content' className='sidebar__content' dataContentIndex={contentIndex} style={contentStyle}>
                    {children}
                </div>
            </div>
        </>
    );
}

/**
 * Go back 1 step in the sidebar content.
 *
 * @export
 * @param {function} setIndex The state setter that sets the contentIndex number.
 * @return {*}
 */
export function goBack(setIndex) {
    if (typeof setIndex !== 'function') {
        return;
    }

    setIndex((prev) => (prev <= 0 ? 0 : prev - 1));
}