import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {Link} from "react-router-dom";
import Table from "UI/App/Components/Table/Table";
import {toast} from "react-toastify";
import Block from "../../../../Components/Block/Block";
import {usePagination} from "../../../../Components/Pagination/Pagination";
import Icon from "../../../../Components/Icon/Icon";
import {stringify} from "qs";

export default function Checklists() {
    const [checklists, setCheckLists] = useState([]);
    const { authAxios } = useContext(FetchContext);

    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded
    } = usePagination({
        storageKey: 'checklists',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
                '[company][name]'
            ]
        },
        filters: [],
        htmlElements: [
            <span className="pagination__filter addItem" key="link--add">
                <Link className='btn' to='./create'>Checklist Toevoegen{`\u00A0`}<Icon name='plus' /></Link>
            </span>
        ]
    });

    useEffect(() => {
        if (!paginationStateLoaded) return;

        const params = stringify({
            populate: '*',
            sort: ['createdAt:DESC'],
            pagination: {
                pageSize: resultsPerPage,
                page: currentPage
            },
        });

        // get the checklists from the api
        authAxios.get(`/calculations/checklists?${params}&${filterQuery}`).then(({ data: {data, meta} }) => {
            setTotalPages(meta.pagination.pageCount)
            setTotalResults(meta.pagination.total)

            // check for the data
            if (data) {
                setCheckLists(data.map((checklist) => {
                    return {
                        attributes: {
                            id: checklist.id,
                        },
                        data: [
                            checklist.name ?? <i style={{color: 'lightgray'}}>Leeg</i>,
                            checklist?.company?.name ?? checklist.data.find((field) => field.name === 'customer')?.value ?? <i style={{color: 'lightgray'}}>Geen</i>,
                            <Link to={`./${checklist.id}/update`}>Edit</Link>
                        ]
                    }
                }))
            }
        }).catch((e) => {
            if (process.env.NODE_ENV === 'development') console.error(e);
            toast.error(`Er is iets fout gegaan bij het ophalen van de checklists! ${e?.response?.status && `(${e.response.status})`}`);
        });
    }, [filterQuery, paginationStateLoaded, resultsPerPage, currentPage]);

    return (
        <Block contentStyle={{backgroundColor: 'transparent'}} name={'checklists'}>
            {filtering}
            <Table
                headers={["Titel","Bedrijf",'Bewerk']}
                structure={['40%','40%','20%',]}
                data={checklists} />
            {paging}
        </Block>
    )
}
