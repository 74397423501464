import {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Inputs, {getFormData} from "UI/App/Components/Form/Inputs";
import {getCountriesList, updateOldCountryName} from "../../../../../../../../App/Util/countries";
import Block from "../../../../../../Components/Block/Block";
import strapiToast from "App/Util/errorHandle";
import {sanitizeString} from "App/Util/transform";
import Container from "UI/App/Components/Container/Container";
import Icon from "UI/App/Components/Icon/Icon";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function EditContactPerson() {
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();

    // get the params from the url
    const params = useParams();
    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    const { width } = useWindowDimensions();

    const [personData, setPersonData] = useState([]);
    const [tags, setTags] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [countryNames, setCountryNames] = useState([]);
    const [countryLandCodes, setCountryLandCodes] = useState([]);
    const [companyAddress, setCompanyAddress] = useState([]);
    const [companyTelephone, setCompanyTelephone] = useState([]);


    useEffect(() => {
        getCountriesList().then((result) => {
            setCountryNames(result.countries);
            setCountryLandCodes(result.countryPhoneCodes);
        });
        getCompany();
    }, [])

    useEffect(() => {
        console.log(personData, tags)
        if(personData && tags.length > 0) {
            setInputData(personData, tags);
        }
    }, [personData, tags, width])

    useEffect(() => {
        if (countryNames?.length === 0 || countryLandCodes?.length === 0 || companyAddress?.length === 0 || companyTelephone?.length === 0) {
            return;
        }

        authAxios.get(`${!isSupplier ? '/crm/suppliers/persons' : '/crm/companies/persons'}/${params.contactPersonId}`, {
            params: {
                populate: [
                    'address',
                    'telephone',
                    'mobilePhone',
                    'personalCellPhone'
                ]
            }
        }).then(({data: {data}}) => {
            const personData = data;
            authAxios.get(`/config/crm`, {
                params: {
                    populate: "*"
                }
            }).then(({data: {data}}) => {
                const tags = [];
                for (const tagGroup of data?.contactPersonTags ?? []) {
                    if (!tagGroup.groupName || !tagGroup.tags) {
                        continue;
                    }

                    const tagOptions = [];
                    for (const tag of tagGroup?.tags.split('\n') ?? []) {
                        tagOptions.push({value: tag, text: tag, checked: (personData?.tags?.[tagGroup?.groupName] ?? []).includes(tag)});
                    }

                    tags.push({
                        type: 'checkbox',
                        name: `tags[${tagGroup.groupName}]`,
                        label: tagGroup.groupName,
                        direction: 'row',
                        options: [
                            ...tagOptions
                        ]
                    });
                }
                setPersonData(personData);
                setTags(tags);
            }).catch((exception) => {
                console.error(exception);
            });
        });
    }, [countryNames, countryLandCodes, companyAddress, companyTelephone]);

    function getCompany() {

        authAxios.get(`/crm/${isSupplier ? 'suppliers' : 'companies'}/` + params.companyId, {
            params: {
                populate: ["address", "telephone"]
            }
        }).then(({data: {data}}) => {
            setCompanyAddress(data.address);
            setCompanyTelephone(data.telephone);
        }).catch((exception) => {
            console.error(exception);
        });
    }

    function defaultOnEmpty(e, defaultValue) {
        // set to default value if empty
        if (e.value === "" && ["string", "number"].includes(typeof defaultValue)) {
            e.value = defaultValue;
        }
    }

    function setInputData(data, tags) {
        const inputs = [
            {
                type: 'hidden',
                name: !isSupplier ? 'company' : 'supplier',
                value: params.companyId,
                required: true,
            },
            {
                type: 'select',
                name: 'approach',
                label: 'Benadering',
                value: data?.approach,
                options: [{
                    text: 'Formeel',
                    value: 'formeel'
                },
                    {
                        text: 'Informeel',
                        value: 'informeel'
                    }],
                required: true,
            },
            {
                type: 'select',
                name: 'gender',
                label: 'Geslacht',
                defaultValue: data?.gender,
                options: [{
                    value: 'man',
                    text: 'Man'
                }, {
                    value: 'vrouw',
                    text: 'Vrouw'
                }, {
                    value: 'anders',
                    text: 'Anders'
                }],
                required: true,
            },
            {
                type: 'multiple',
                label: 'Naam',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'title',
                        placeholder: 'Titel...',
                        defaultValue: data?.title,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'firstName',
                        placeholder: 'Voornaam...',
                        required: true,
                        defaultValue: data?.firstName,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'prefixToSurname',
                        placeholder: 'Tussenvoegsel...',
                        defaultValue: data?.prefixToSurname,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },

                    },
                    {
                        type: 'text',
                        name: 'surname',
                        placeholder: 'Achternaam...',
                        defaultValue: data?.surname,
                        required: true,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'titleAfter',
                        placeholder: 'Achter titel...',
                        defaultValue: data?.titleAfter,
                        style: {
                            width: width > 800 ? '20%' : width > 500 ? '50%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'text',
                name: 'function',
                label: 'Functie',
                placeholder: 'Functie...',
                defaultValue: data?.function,
            },
            {
                type: 'multiple',
                name: 'address',
                label: 'Adres',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'address[addressLine1]',
                        placeholder: 'Adres regel 1...',
                        defaultValue: data?.address?.addressLine1 ?? companyAddress.addressLine1,
                        onBlur: ((e) => defaultOnEmpty(e, companyAddress.addressLine1)),
                        style: {
                            width: '100%'
                        },
                        required: true,
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine2]',
                        placeholder: 'Adres regel 2...',
                        defaultValue: data?.address?.addressLine2 ?? companyAddress.addressLine2,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine3]',
                        placeholder: 'Adres regel 3...',
                        defaultValue: data?.address?.addressLine3 ?? companyAddress.addressLine3,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[postalCode]',
                        placeholder: 'Postcode...',
                        defaultValue: data?.address?.postalCode ?? companyAddress.postalCode,
                        onBlur: ((e) => defaultOnEmpty(e, companyAddress.postalCode)),
                        required: true,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[city]',
                        placeholder: 'Stad...',
                        defaultValue: sanitizeString(data?.address?.city ?? companyAddress.city),
                        onBlur: ((e) => defaultOnEmpty(e, sanitizeString(companyAddress.city))),
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[province]',
                        placeholder: 'Provincie...',
                        defaultValue: data?.address?.province ?? companyAddress.province,
                        onBlur: ((e) => defaultOnEmpty(e, companyAddress.province)),
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'select',
                        name: 'address[country]',
                        placeholder: 'Land...',
                        options: countryNames,
                        value: updateOldCountryName(data?.address?.country ?? companyAddress.country),
                        onBlur: ((e) => defaultOnEmpty(e, updateOldCountryName(companyAddress.country))),
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'email',
                name: 'email',
                label: 'E-mailadres',
                placeholder: 'E-mailadres...',
                defaultValue: data?.email,
                required: true,
            },
            {
                type: 'email',
                name: 'personalEmail',
                label: 'Privé e-mailadres',
                placeholder: 'Privé e-mailadres...',
                defaultValue: data?.personalEmail,
            },
            {
                type: 'multiple',
                name: 'telephone',
                label: 'Telefoonnummer',
                required: true,
                subFields: [
                    {
                        type: 'select',
                        name: 'telephone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        value: data?.telephone?.dialingCode ?? companyTelephone?.dialingCode,
                        onBlur: ((e) => defaultOnEmpty(e, companyTelephone.dialingCode)),
                        required: true,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'telephone[telephoneNumber]',
                        placeholder: 'Telefoonnummer...',
                        min: 0,
                        minLength: 5,
                        defaultValue: data?.telephone?.telephoneNumber ?? companyTelephone?.telephoneNumber,
                        onBlur: ((e) => defaultOnEmpty(e, companyTelephone.telephoneNumber)),
                        required: true,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            {
                type: 'multiple',
                name: 'personalCellPhone',
                label: 'Privé nummer',
                subFields: [
                    {
                        type: 'select',
                        name: 'personalCellPhone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        value: data?.personalCellPhone?.dialingCode,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'personalCellPhone[telephoneNumber]',
                        placeholder: 'Privé nummer...',
                        min: 0,
                        minLength: 5,
                        defaultValue: data?.personalCellPhone?.telephoneNumber,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            {
                type: 'multiple',
                name: 'mobilePhone',
                label: 'Mobiel nummer',
                subFields: [
                    {
                        type: 'select',
                        name: 'mobilePhone[dialingCode]',
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        value: data?.mobilePhone?.dialingCode,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'mobilePhone[telephoneNumber]',
                        placeholder: 'Mobiel nummer...',
                        min: 0,
                        minLength: 5,
                        defaultValue: data?.mobilePhone?.telephoneNumber,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            ...tags,
            {
                type: 'submit',
                name: 'submit',
                class: 'btn'
            },
        ];

        setInputs(inputs);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        // Retrieve form data using authAxios
        const data = await getFormData(e, authAxios);
        const personal = data.personalCellPhone;
        const mobile = data.mobilePhone;

        data.personalEmail = data.personalEmail || null;

        if (personal['dialingCode'] === '' && personal['telephoneNumber'] === '') {
            data.personalCellPhone = null;
        }

        if (mobile['dialingCode'] === '' && mobile['telephoneNumber'] === '') {
            data.mobilePhone = null;
        }

        // Check if personalCellPhone is partially filled in and show error
        if ((personal?.dialingCode && !personal?.telephoneNumber) || (!personal?.dialingCode && personal?.telephoneNumber)) {
            toast.error('Privé nummer is niet volledig ingevuld');
            return;
        }

        // Check if mobilePhone is partially filled in and show error
        if ((mobile?.dialingCode && !mobile?.telephoneNumber) || (!mobile?.dialingCode && mobile?.telephoneNumber)) {
            toast.error('Mobiel nummer is niet volledig ingevuld');
            return;
        }

        authAxios.put(`${!isSupplier ? '/crm/companies/persons' : '/crm/suppliers/persons'}/${params.contactPersonId}`, {
            data
        }).then(() => {
            toast.success('Contactpersoon aangepast');

            navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}/contactpersons/${params.contactPersonId}`);
        }).catch((e) => {
            //toast.error('Er ging iets mis met het aanmaken van de contactpersoon');
            strapiToast(e);
            console.error(e);
        });
    }

    return (
        <Container>
            <Block name={"EditContactPerson"} title={<>
                <Link className={'btn btn--transparent'} to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}/contactpersons/${params.contactPersonId}`}><Icon name={'arrow-left'}/></Link>
                Contactpersoon aanpassen
            </>}>
                <form onSubmit={handleSubmit}>
                    <Inputs inputs={inputs} key={crypto.randomUUID()}/>
                </form>
            </Block>
        </Container>
    );
}
