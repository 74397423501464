import { useContext, useEffect, useState, useRef, Component } from "react";
import { FetchContext } from "App/Strapi/FetchContext";
import { AuthContext } from "App/Strapi/AuthProvider"
import Block from "UI/App/Components/Block/Block";
import Creatable, { useCreatable } from 'react-select/creatable';
import Select from 'react-select'
import { toast } from "react-toastify";
import { stringify } from "qs";
import Grid from "UI/App/Components/Grid/Grid";


export default function TimeInput({ aquireOngoing = true, editTimerWithid = undefined, knowCompanies = undefined, onUpdate = undefined, onTimer = undefined, timerToAdd = undefined, setTime = true }) {
    const { authAxios } = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [variableBlock, setVariableBlock] = useState()
    const [timerInfo, setTimerInfo] = useState({ timerText: "Start Timer", timerStarted: false });
    const [timerOptions, setTimerOptions] = useState({});
    const [companies, setCompanies] = useState([]);
    const [stopwatch, setStopWatch] = useState('00:00:00');
    const [currentUser, setCurrentUser] = useState([]);

    // which roles are allowed to use the create part of the select
    const [allowedToModify, setAllowedToModify] = useState([
        "admin",
        "authenticated"
    ])

    // on first load set and gate needed data
    useEffect(() => {

        setCurrentUser(authContext.authState.user);
        if (knowCompanies !== undefined) {
            setCompanies(knowCompanies);
        } else {
            getCompanies();
        }

        getTimeOptions();

        if (aquireOngoing && editTimerWithid === undefined) {
            getOngoingTimers();
        } else if (aquireOngoing && editTimerWithid !== undefined) {
            console.error("Cannot aquire ongoing and edit at the same time.\n\r Will default to aquire ongoing")
        }



    }, []);
    // if edit Timer id changes get new timer
    useEffect(() => {
        if (aquireOngoing === false && editTimerWithid !== undefined) {
            editTimerWithid = Number(editTimerWithid);
            getTimerById(editTimerWithid)
        }
    }, [editTimerWithid]);
    // update the counter every second
    useEffect(() => {

        if (timerInfo?.timerStarted === undefined) {
            return
        }


        // update the timer (visauly) every second
        let interval = null;

        if (!timerInfo.timerStarted) {
            return () => clearInterval(interval);
        }
        //updateInputBlock();
        interval = setInterval(() => {
            timerUpdate();
        }, 1000);

        //updateFields();
        return () => clearInterval(interval);

    }, [timerInfo]);
    // on update of the main data, rerender
    useEffect(() => {
        updateBlock();
        if (onUpdate !== undefined) {
            onUpdate();
        }
    }, [timerInfo, timerOptions, companies, stopwatch]);
    useEffect(() => {

        if (timerToAdd?.startInfo === undefined) return;
        if (timerToAdd?.endInfo === undefined) return;
        let updateTimerInfo = timerInfo;

        let start = formatDateToReadableString(timerToAdd?.startInfo, false, false)
        let end = formatDateToReadableString(timerToAdd?.endInfo, false, false)

        if (timerInfo?.details === undefined) {
            timerInfo.details = {}
        }
        timerInfo.details.startDate = start.date
        if (setTime) {
            timerInfo.details.startTime = start.time;
        } else {
            timerInfo.details.startTime = undefined;
        }

        //timerInfo.details.startDate = start.date
        //timerInfo.details.startTime = start.time


        timerInfo.details.endDate = end.date

        if (setTime) {
            timerInfo.details.endTime = end.time
        } else {
            timerInfo.details.endTime = undefined;
        }
        //timerInfo.details.endDate = end.date
        //timerInfo.details.endTime = end.time


        setTimerInfo(updateTimerInfo)
        updateBlock();
    }, [timerToAdd?.startInfo, timerToAdd?.endInfo])
    function updateBlock() {
        let elements = [];


        // add company select
        elements.push(<div>
            Bedrijven:
            <Select required={true} value={timerInfo?.company} name="companyId" options={companies === undefined ? undefined : companies} onChange={updateCompany} />
        </div>)

        // add project select, use is validNewOption to disable create function (based on rol?)
        elements.push(<div>
            Project:

            <Creatable required={true} isValidNewOption={userAllowedToCreate} value={timerOptions?.selectedProject} name="project" options={timerOptions?.project} onChange={updateProject} onCreateOption={createNewProject} />

        </div>)

        // add activity select, use is validNewOption to disable create function (based on rol?)
        elements.push(<div>
            Activiteit:
            <Creatable required={true} isValidNewOption={userAllowedToCreate} value={timerOptions?.selectedActivity} name="activity" options={timerOptions?.activity} onChange={updateActivity} onCreateOption={createNewActivity} />
        </div>)

        // add description field
        elements.push(<div>
            Omschrijving:
            <textarea required name="description" type="textarea" row="6" value={timerInfo?.details?.description === undefined ? "" : timerInfo?.details?.description} onChange={(e) => saveInputOnChange(e)} />
        </div>);

        // add dateTime selector
        elements.push(<div className="TimeAccouting__time">
            <div className="TimeAccouting__starttime">
                Start tijd:<input name="startTime" type="time" value={timerInfo?.details?.startTime === undefined ? "" : timerInfo.details?.startTime} onChange={(e) => saveInputOnChange(e)} />
                <input name="startDate" type="date" value={timerInfo?.details?.startDate === undefined ? "" : timerInfo.details?.startDate} onChange={(e) => saveInputOnChange(e)} />
            </div>
            <div className="TimeAccouting__stoptime">
                Eind tijd:<input name="endTime" type="time" value={timerInfo?.details?.endTime === undefined ? "" : timerInfo.details?.endTime} onChange={(e) => saveInputOnChange(e)} />
                <input name="endDate" type="date" value={timerInfo?.details?.endDate === undefined ? "" : timerInfo.details?.endDate} onChange={(e) => saveInputOnChange(e)} />
            </div>
        </div>);

        // add pause field
        elements.push(<div>
            <div>Pauze/uren verwijderen:</div>
            <div className="TimeAccouting__pauze">
                <div className="TimeAccouting__pausetime">
                    Uren:<input name="pauseHour" type="number" value={isNaN(timerInfo.pauseHour) ? 0 : timerInfo.pauseHour} onChange={(e) => saveInputOnChange(e)} />
                </div>
                <div className="TimeAccouting__pausetime">
                    Minuten:<input name="pauseMinutes" type="number" value={isNaN(timerInfo.pauseMinute) ? 0 : timerInfo.pauseMinute} onChange={(e) => saveInputOnChange(e)} />
                </div>
            </div>
        </div>);

        // 
        elements.push(<>
            <Grid columns={((editTimerWithid === undefined || aquireOngoing === true) && timerToAdd === undefined) ? 2 : 1}>

                {timerInfo.timerStarted === true && <div>
                    <input type='submit' name='submit' class='btn' disabled={true} />
                </div>}

                {timerInfo.timerStarted === false &&
                    <input type='submit' name='submit' class='btn' />

                }

                {/* a */}
                {((editTimerWithid === undefined || aquireOngoing === true) && timerToAdd === undefined) &&
                    <button name='timer' style={{ marginTop: "5px", marginBottom: "10px", padding: "none", }} onClick={startTimer}>{timerInfo.timerText}</button>
                }
            </Grid>

            <span>
                {timerInfo.timerStarted ? stopwatch : ""}
            </span>

        </>)

        setVariableBlock(elements)
    }

    function createTimer(e) {
        console.log(timerInfo);
        // only submit if the submit came from the SUBMIT button
        if (e.nativeEvent.submitter.name !== "submit" || !requiredParts()) {
            console.log(timerInfo.details)
            return;
        }

        let startDateTime = timerInfo.details.startDate + "T" + timerInfo.details.startTime;
        let endDateTime = timerInfo.details.endDate + "T" + timerInfo.details.endTime;

        // set smallest of the two to start
        if (new Date(endDateTime).getTime() / 1000 < new Date(startDateTime).getTime() / 1000) {
            startDateTime = timerInfo.details.endDate + "T" + timerInfo.details.endTime;
            endDateTime = timerInfo.details.startDate + "T" + timerInfo.details.startTime;
        } else {
            startDateTime = timerInfo.details.startDate + "T" + timerInfo.details.startTime;
            endDateTime = timerInfo.details.endDate + "T" + timerInfo.details.endTime;
        }

        // check if necesary and right data is here
        if (!validDateTime(startDateTime, "De start datum is niet compleet") || !validDateTime(endDateTime, "De eind datum is niet compleet")) {
            return;
        }
        // get total time in seconds
        let totalTimeInSec = Math.floor((new Date(endDateTime).getTime() - new Date(startDateTime).getTime()) / 1000);

        // subtract pause from total
        totalTimeInSec = (totalTimeInSec - (timerInfo.hours * 60 * 60)) - (timerInfo.minutes * 60);


        let pauseHour = isNaN(Number(timerInfo.details.pauseHour)) ? 0 : Number(timerInfo.details.pauseHour);
        let pauseMinute = isNaN(Number(timerInfo.details.pauseMinute)) ? 0 : Number(timerInfo.details.pauseMinute);
        let pause = (pauseHour * 60 + pauseMinute) * 60
        // make an object to act as payload
        let data = {
            startTime: new Date(startDateTime),
            endTime: new Date(endDateTime),
            user: authContext.authState.user.id,
            totalRunTimeInSec: totalTimeInSec,
            company: timerInfo.details.companyId,
            details: timerInfo.details,
            pause: pause,
        }

        createTimeAcc(data)
    }
    function editTimer(e) {
        // only submit if the submit came from the SUBMIT button
        if (e.nativeEvent.submitter.name !== "submit" || !requiredParts()) {
            return;
        }
        let startDateTime = timerInfo.details.startDate + "T" + timerInfo.details.startTime;
        let endDateTime = timerInfo.details.endDate + "T" + timerInfo.details.endTime;
        // check if necesary and right data is here
        if (!validDateTime(startDateTime, "De start datum is niet compleet") || !validDateTime(endDateTime, "De eind datum is niet compleet")) {

            return;
        }
        // get total time in seconds
        let totalTimeInSec = Math.floor((new Date(endDateTime).getTime() - new Date(startDateTime).getTime()) / 1000);

        // subtract pause from total
        totalTimeInSec = (totalTimeInSec - (timerInfo.hours * 60 * 60)) - (timerInfo.minutes * 60);
        let timeOffset = new Date().getTimezoneOffset() * -1;


        // make an object to act as payload
        let startDate = new Date(new Date(startDateTime).getTime());
        let endDate = new Date(new Date(endDateTime).getTime());


        let pauseHour = isNaN(Number(timerInfo.pauseHour)) ? 0 : Number(timerInfo.pauseHour);
        let pauseMinute = isNaN(Number(timerInfo.pauseMinute)) ? 0 : Number(timerInfo.pauseMinute);
        let pause = (pauseHour * 60 + pauseMinute) * 60

        let data = {
            startTime: startDate.setHours(startDate.getHours()),
            endTime: endDate.setHours(endDate.getHours()),
            user: currentUser.id,
            totalRunTimeInSec: totalTimeInSec,
            company: timerInfo.company.value,
            details: timerInfo.details,
            pause: pause
        }
        updateTimeAcc(data);
    }
    // stopWatch
    //#region
    function timerUpdate() {
        // if timer is on update visual side
        if (timerInfo.timerStarted) {
            let updateTimerInfo = timerInfo;

            let currentDate = new Date();


            let timeOffset = new Date().getTimezoneOffset() * -1;
            // set to offsetTime, needed for accurate time calculation
            let offsetDate = new Date(currentDate.getTime() + (timeOffset * 60000));
            let readAbleTime = formatDateToReadableString(offsetDate, true)

            updateTimerInfo.endDate = readAbleTime.date;
            updateTimerInfo.endTime = readAbleTime.time;

            // get diffrence between current and start time
            let difference = offsetDate.getTime() - new Date(updateTimerInfo.startDateTime.getTime() + (timeOffset * 60000)).getTime();
            // set and round down total seconds
            updateTimerInfo.timeInSec = Math.floor(difference / 1000);

            // save hours and subtract them from total
            let hours = Math.floor(difference / 1000 / 60 / 60);
            difference -= hours * 1000 * 60 * 60;

            // save minutes and subtract them from total
            var minutes = Math.floor(difference / 1000 / 60);
            difference -= minutes * 1000 * 60;


            // save remaning time as seconds
            var seconds = Math.floor(difference / 1000);


            // store the parts as one string
            let currentTime = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');
            ///updateTimerInfo.currentTime = currentTime;

            setStopWatch(currentTime);
        }
    }
    //#endregion

    // validation/formatting functions
    //#region
    // check if all requird fields are filled
    function requiredParts() {

        if (timerInfo?.details?.description === undefined || timerInfo?.details?.companyId === undefined || timerInfo?.details?.project === undefined || timerInfo?.details?.activity === undefined) {
            return false;
        }
        return true;
    }
    function formatDateToReadableString(inputDate, TimeWithSec = true, offset = true) {

        inputDate = new Date(inputDate);
        let returnVal = { date: "", time: "" };

        let year = inputDate.getFullYear().toString();
        let month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        let date = inputDate.getDate().toString().padStart(2, '0');

        returnVal.date = year + "-" + month + "-" + date;
        let timeOffset = 0;
        if (offset) {
            timeOffset = new Date().getTimezoneOffset() * -1;
        }


        let hours = new Date(inputDate.getTime() - (timeOffset * 60000)).getHours().toString().padStart(2, '0');
        let minutes = inputDate.getMinutes().toString().padStart(2, '0');
        let seconds = inputDate.getSeconds().toString().padStart(2, '0');
        if (TimeWithSec) {
            returnVal.time = hours + ":" + minutes + ":" + seconds;
        } else {
            returnVal.time = hours + ":" + minutes;
        }
        return returnVal;
    }
    // check if a timestamp is valid if not send message
    function validDateTime(dateTime, errorMsg) {
        let result = new Date(dateTime);

        if (result.toString() === "Invalid Date") {
            toast.error(errorMsg);
            return false;
        }
        return true;
    }
    // Check if usere is allowed to create entry.
    function userAllowedToCreate(e) {
        let role = currentUser?.role?.name?.toLowerCase();
        // check if user has required role, also check if new entry is longer then 3 characters.
        if (allowedToModify.includes(role) && e !== "" && e.length >= 3) {
            return true
        }
        return false
    }
    //#endregion


    // strapi functions
    //#region
    function getTimeOptions() {

        authAxios.get(`/time-options/knowOptions?`)
            .then(({ data }) => {
                const updateTimerOptions = {}

                updateTimerOptions.selectedProject = timerOptions?.selectedProject;
                updateTimerOptions.selectedActivity = timerOptions?.selectedActivity;

                for (const [key, _data] of Object.entries(data)) {

                    if (updateTimerOptions[key] === undefined) {
                        updateTimerOptions[key] = []
                    }

                    for (const option of _data) {
                        updateTimerOptions[key].push({ value: option.id, label: option.Name })
                    }

                }
                setTimerOptions(updateTimerOptions)
            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

                console.error(exception);
            })
    }
    // get the ongoing timer based on user ID
    async function getOngoingTimers() {
        let updateTimerInfo = [];
        const params = stringify({
            populate: {
                company: {
                    id: true
                },
                details: {
                    populate: {
                        description: true,
                        project: {
                            id: true,
                            Name: true
                        },
                        activity: {
                            id: true,
                            Name: true
                        },
                    }
                },
            },
            filters: {
                $and: [
                    {
                        user: authContext.authState.user.id,
                        endTime: {
                            $null: true
                        }
                    }
                ]

            }

        });
        //"/time-registrations?populate=*&filters[endTime][$null]=null&filters[user]=" + authContext.authState.user.id
        authAxios.get(`time-registrations?${params}`).then(({ data }) => {


            // only set all ongoing timer info if we have an ongoing timer.
            if (data?.data[0]?.endTime === null) {
                updateTimerInfo.timerStarted = true;

                let aquiredDate = new Date(data.data[0].startTime);
                // set the id for the ongoing timer so we can update it later on
                updateTimerInfo.timeId = data.data[0].id;

                let timeOffset = new Date().getTimezoneOffset() * -1;

                // get and pad hours, minutes and seconds
                let hours = new Date(aquiredDate.getTime()).getHours().toString().padStart(2, '0');
                let minutes = aquiredDate.getMinutes().toString().padStart(2, '0');
                let seconds = aquiredDate.getSeconds().toString().padStart(2, '0');

                // set time for time picker
                updateTimerInfo.startTime = hours + ":" + minutes; //+ ":" + seconds;

                // set dateTime to know when we started
                updateTimerInfo.startDateTime = aquiredDate;

                // get and set the date out of datetime
                let year = aquiredDate.getFullYear().toString();
                let month = (aquiredDate.getMonth() + 1).toString().padStart(2, '0');
                let date = aquiredDate.getDate().toString().padStart(2, '0');

                // set date in format the date picker will expect
                updateTimerInfo.startDate = year + "-" + month + "-" + date;

                // set button text to stop timer.
                updateTimerInfo.timerText = "Stop Timer";

                let details = data.data[0].details;
                console.log(details)
                updateTimerInfo.company = { value: data.data[0].company.id, label: data.data[0].company.name };
                updateTimerInfo.timeId = data.data[0].id
                // save ongoing timer detials in the useState
                updateTimerInfo.details = {
                    description: details.description,
                    project: details.project,
                    activity: details.activity,
                    companyId: data.data[0].company.id,

                }
                let updateTimerOptions = timerOptions;
                updateTimerOptions.selectedProject = { value: details.project.id, label: details.project.Name }
                updateTimerOptions.selectedActivity = { value: details.activity.id, label: details.activity.Name }

                setTimerOptions(updateTimerOptions)
            } else {
                updateTimerInfo.timerStarted = false;
                updateTimerInfo.timerText = "Start Timer";
            }

            // save data in useState
            setTimerInfo(updateTimerInfo);

        }).catch((e) => {

            toast.error('er ging iets fout met het ophaalen van de gaande timer');

            console.error(e);
        });

    }
    function getCompanies() {

        authAxios.get(`/crm/companies/all`)
            .then(({ data }) => {
                let compNes = [];
                data.sort((a, b) => (a.companyName > b.companyName) ? 1 : ((b.companyName > a.companyName) ? -1 : 0))
                for (let company of data) {

                    compNes.push({ value: company.id, label: company.name });
                }
                setCompanies(compNes);

            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

                console.error(exception);
            })
    }
    // stop the currently going timer
    function closeOngoingTimers(data) {


        authAxios.put("/time-registrations/" + timerInfo.timeId, { data }).then(() => {
            toast.success('Timer gestopt');
            getOngoingTimers();

        }).catch((e) => {

            toast.error('Er ging iets fout met het stoppend van de gaande timer');

            console.error(e);
        });

    }
    async function createTimeAcc(data, _timerInfo = undefined) {

        authAxios.post("/time-registrations", { data }).then((data) => {
            toast.success('Opgeslagen.');

            let updateTimerInfo = _timerInfo === undefined ? timerInfo : _timerInfo;

            updateTimerInfo.timeId = data.data.data.id;



            setTimerInfo(updateTimerInfo);
            if (onTimer !== undefined) {
                onTimer();
            }


        }).catch((e) => {

            toast.error('Er ging iets fout met het aanmaken van de timer');

            console.error(e);
        });
        //getBlocksFormCurrentDate();
    }
    function timeToOffsetTime(time, offsetMod = -1) {
        let timeOffset = new Date().getTimezoneOffset() * -1;
        return new Date(new Date(time).getTime() - ((timeOffset * 60000) * offsetMod));
    }
    async function getTimerById(id) {

        const params = stringify({
            populate: {
                company: {
                    id: true
                },
                details: {
                    populate: {
                        description: true,
                        project: {
                            id: true,
                            Name: true
                        },
                        activity: {
                            id: true,
                            Name: true
                        },
                    }
                },
            },
            filters: {
                id: {
                    $eq: id
                }
            }

        });
        // "/time-registrations?populate=*&filters[id][$eq]=" + id
        authAxios.get(`/time-registrations?${params}`).then(({ data }) => {
            if (data.data[0] === undefined) return;
            let updateTimerInfo = timerInfo;


            // format start and end time
            let timeOffset = new Date()
            let startTime = formatDateToReadableString(timeToOffsetTime(new Date(data.data[0].startTime)).getTime(), false);
            let endTime = formatDateToReadableString(timeToOffsetTime(new Date(data.data[0].endTime)).getTime(), false);


            // set the id for the ongoing timer so we can update it later on
            updateTimerInfo.timeId = data?.data[0]?.id;

            // Set start time and date
            updateTimerInfo.startTime = startTime.time;
            updateTimerInfo.startDate = startTime.date;


            if (data.data[0].endTime != undefined) {

                // Set end time and date
                updateTimerInfo.endTime = endTime.time;
                updateTimerInfo.endDate = endTime.date;

            } else {
                // set end time and date to be empty.
                updateTimerInfo.endTime = "";
                updateTimerInfo.endDate = "";
            }


            let details = data.data[0].details;
            updateTimerInfo.company = { value: data.data[0].company.id, label: data.data[0].company.name };


            let hours = Math.floor(data.data[0].pause / 3600);
            let minutes = (data.data[0].pause - (3600 * hours)) / 60;
            updateTimerInfo.pauseHour = hours;
            updateTimerInfo.pauseMinute = minutes;


            // save ongoing timer detials in the useState
            updateTimerInfo.details = {
                description: details?.description,
                project: details?.project?.id,
                activity: details?.activity?.id,
                companyId: data?.data[0]?.company?.id,
                startDate: updateTimerInfo?.startDate,
                startTime: updateTimerInfo?.startTime,
                endDate: updateTimerInfo?.endDate,
                endTime: updateTimerInfo?.endTime,
            }

            let updateTimerOptions = timerOptions;
            updateTimerOptions.selectedProject = { value: details?.project?.id, label: details?.project?.Name }
            updateTimerOptions.selectedActivity = { value: details?.activity?.id, label: details?.activity?.Name }

            //setTimerOptions(updateTimerOptions)
            // save data in useState
            setTimerInfo(updateTimerInfo);
            updateBlock();
        }).catch((e) => {

            toast.error('Het ophaalen van de timer is mislukt');

            console.error(e);
        });
    }
    function updateTimeAcc(data) {
        authAxios.put("/time-registrations/" + editTimerWithid, { data }).then(() => {
            toast.success('Opgeslagen.');
            if (onTimer !== undefined) {
                onTimer();
            }


        }).catch((e) => {

            toast.error('Het aanpassen van de timer is mislukt.');

            console.error(e);
        });
    }
    //#endregion

    // event handlers
    //#region
    function updateCompany(e) {

        // store state in object so old values can be used to set new ones.
        let updateTimerInfo = timerInfo;

        if (timerInfo.timerStarted) {
            return
        }
        // set details
        if (updateTimerInfo?.details === undefined) {
            updateTimerInfo.details = {
                activity: undefined,
                project: undefined,
                description: undefined,
                companyId: e.value === undefined ? undefined : Number(e.value)
            };
        }

        updateTimerInfo.details.companyId = e.value === undefined ? undefined : e.value.toString();

        updateTimerInfo.company = { value: e.value, label: e.label }
        setTimerInfo(updateTimerInfo);
        updateBlock();
    }
    function updateProject(e) {
        // store state in object so old values can be used to set new ones.
        let updateTimerInfo = timerInfo;

        if (timerInfo.timerStarted) {
            return
        }
        // set details
        if (updateTimerInfo?.details === undefined) {
            updateTimerInfo.details = {
                activity: undefined,
                project: e.value === undefined ? undefined : e.value.toString(),
                description: undefined,
                companyId: undefined,
            };
        }

        updateTimerInfo.details.project = e.value === undefined ? undefined : e.value.toString();


        // update the selected
        let updateTimerOptions = timerOptions;
        updateTimerOptions.selectedProject = { value: e.value, label: e.label }
        setTimerOptions(updateTimerOptions);
        updateBlock();

    }
    function createNewProject(e) {
        // set the packet
        let data = {
            Name: e,
            type: "project",
            creator: authContext.authState.user.id
        }
        // add new option
        authAxios.post(`/time-options`, { data }).then(({ data }) => {

            let updateTimerOptions = timerOptions;
            updateTimerOptions.selectedProject = { value: data.data.id, label: data.data.Name }
            setTimerOptions(updateTimerOptions);
            getTimeOptions();

        }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

            console.error(exception);
        })
    }
    function updateActivity(e) {
        // store state in object so old values can be used to set new ones.
        let updateTimerInfo = timerInfo;

        if (timerInfo.timerStarted) {
            return
        }
        // set details
        if (updateTimerInfo?.details === undefined) {
            updateTimerInfo.details = {
                activity: e.value === undefined ? undefined : e.value.toString(),
                project: undefined,
                description: undefined,
                companyId: undefined,
            };
        }

        updateTimerInfo.details.activity = e.value === undefined ? undefined : e.value.toString();

        // update the selected
        let updateTimerOptions = timerOptions;
        updateTimerOptions.selectedActivity = { value: e.value, label: e.label }
        setTimerOptions(updateTimerOptions);
        updateBlock();
    }
    function createNewActivity(e) {
        // set the packet
        let data = {
            Name: e,
            type: "activity",
            creator: authContext.authState.user.id
        }
        // add new option
        authAxios.post(`/time-options`, { data }).then(({ data }) => {

            let updateTimerOptions = timerOptions;
            updateTimerOptions.selectedActivity = { value: data.data.id, label: data.data.Name }
            setTimerOptions(updateTimerOptions);
            getTimeOptions();

        }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

            console.error(exception);
        })
    }
    function saveInputOnChange(e) {

        // store state in object so old values can be used to set new ones.
        let updateTimerInfo = timerInfo;
        // depending on input change, save under specif name in usestate.
        // if there already is a timer started we cant go set variabels
        if (!timerInfo.timerStarted) {

            // set details
            if (updateTimerInfo?.details === undefined) {
                updateTimerInfo.details = {
                    activity: undefined,
                    project: undefined,
                    description: undefined
                };
            }

            updateTimerInfo.details[e.target.name] = e.target.value === undefined ? e.target.text : e.target.value;

        } else if (!e?.target?.name.startsWith("pause")) {
            toast.error('Er is al een timer gaande, stop de timer om iets toe te voegen');
        }
        // pause parts, this can be set while a timer is going on

        if (e?.target?.name === 'pauseMinutes') {
            let minutes = e.target.value;

            // if more then 60 minutes turn the minutes in to hours
            if (minutes >= 60) {
                let times = Math.floor(minutes / 60);
                minutes = minutes - 60 * times;

                timerInfo.pauseHour = (timerInfo.pauseHour === undefined ? 0 : timerInfo.pauseHour) + times;
            }
            timerInfo.pauseMinute = minutes;

        }

        if (e?.target?.name === 'pauseHour') {
            timerInfo.pauseHour = e.target.value;
        }
        // store changed data
        setTimerInfo(updateTimerInfo);
        // update the block with the new data
        updateBlock();
    }
    // create an entry in the api with an undefined enddate
    function startTimer(e) {
        e.preventDefault();
        // check if necesary parts are here
        if (!requiredParts()) {

            return;
        }


        let updateTimerInfo = Object.create(timerInfo);

        // get local time
        let localDate = new Date();

        // if timer is going
        if (timerInfo.timerStarted) {
            // set the state to false so visual side no longer gets updated
            //setTimerStarted(false);
            let totalTimeInSec = Math.floor((localDate.getTime() - timerInfo.startDateTime.getTime()) / 1000);

            // subtract pause from total
            totalTimeInSec = (totalTimeInSec - (timerInfo.hours * 60 * 60)) - (timerInfo.minutes * 60);
            // create payload for api reqeust

            let data = {
                timeId: timerInfo.timeId,
                startTime: timerInfo.startDateTime,
                endTime: localDate,
                user: authContext.authState.user.id,
                totalRunTimeInSec: totalTimeInSec,
                company: timerInfo.details.companyId,
                details: timerInfo.details,
            }

            closeOngoingTimers(data);

            // replace button text
            updateTimerInfo.timerText = "Start Timer";
            updateTimerInfo.timerStarted = false;

            setTimerInfo(updateTimerInfo);
            //getBlocksFormCurrentDate()

        } else {
            let dateTime = formatDateToReadableString(new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000))

            updateTimerInfo.startDateTime = new Date(localDate.getTime());
            updateTimerInfo.startDate = dateTime.date;
            updateTimerInfo.startTime = dateTime.time.substring(0, dateTime.time.length - 3);
            updateTimerInfo.details = timerInfo.details;

            // make payload for api reqeust
            let data = {
                startTime: localDate,
                endTime: undefined,
                user: authContext.authState.user.id,
                totalRunTimeInSec: null,
                company: timerInfo.details.companyId,
                details: timerInfo.details,
                pause: (timerInfo.details.pauseHour === undefined ? 0 : timerInfo.details.pauseHour * 60 + timerInfo.details.pauseMinute === undefined ? 0 : timerInfo.details.pauseMinute) * 60
            }



            // set the state to true so visual side gets updated

            updateTimerInfo.timerText = "Stop Timer";
            updateTimerInfo.timerStarted = true;
            createTimeAcc(data, updateTimerInfo);
            getOngoingTimers();
            //setTimerInfo(updateTimerInfo);
        }


    }
    function handleSubmit(e) {
        e.preventDefault();

        if ((aquireOngoing && editTimerWithid === undefined) || timerToAdd !== undefined) {
            createTimer(e);
        } else if (editTimerWithid !== undefined) {
            editTimer(e);
        }

    }

    //#endregion

    return (<Block name={"InputBlock"} className="TimeAccouting_form">
        <form onSubmit={handleSubmit}>
            {variableBlock === undefined ? 'Loading...' : variableBlock}
        </form>
    </Block>)
}
