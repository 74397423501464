import Icon from "UI/App/Components/Icon/Icon";
import RunningOrderTimersPopup from "UI/App/Partials/Content/CRM/Orders/OrderTimer/RunningOrderTimersPopup";
import {useCallback, useState} from "react";
import "./runningOrderTimersPopup.scss";

export default function RunningOrderTimers() {
    const [visible, setVisible] = useState(false);
    const [timerCount, setTimerCount] = useState(0);

    const togglePopup = useCallback(() => {
        setVisible((v) => !v);
    }, []);

    const closePopup = useCallback(() => {
        setVisible(false);
    }, []);

    return (
        <>
            <button
                className={'btn__toggle-order-timers btn--round' + (timerCount === 0 ? ' hidden' : '')}
                onClick={togglePopup}
                data-bubble={timerCount}
            >
                <Icon name={'fas clock'} />
            </button>
            <RunningOrderTimersPopup
                visible={visible}
                closePopup={closePopup}
                setTimerCount={setTimerCount}
            />
        </>
    )
}