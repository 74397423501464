import './grid.scss';
import useWindowDimensions from "App/Util/useWindowDimensions";
import {useEffect, useState} from "react";
import {getPropByPageWidth} from "App/Util/layout";

/**
 * Add columns
 *
 * @export default
 * @param {number|string|Record<number|'default',number>} [columns] The number of columns to display.
 * @param {number | string} [rows] The number of rows to display.
 * @param {string} [id] [optional] The id used for the `key` & `id` attributes.
 * @param {string | number} [gap] [optional] Change the gap between columns from default.
 *  Can use `css variables`, `number string as pixels` and `other valid css strings` (use space to specify both `row-gap column-gap`) .
 * @param {string|Record<number|string, string>} [customColTemplate=''] [optional] Set a custom `grid-template-columns`. default = equal width.
 * @param {string} [customRowTemplate=''] [optional] Set a custom `grid-template-rows`.
 * @param {string} [className=''] [optional] Extra class names applied to the div if needed.
 * @param {JSX.Element} [children]
 * @param {object} [style={}] [optional] Extra styles applied to the div if needed.
 * @param {object} [props] [optional] Extra props applied to the div if needed.
 * @return {JSX.Element}
 */
export default function Grid({columns, rows, id, gap, customColTemplate, customRowTemplate, className, children, style = {}, ...props}) {
    const { width } = useWindowDimensions();
    const [columnCount, setColumnCount] = useState(/** @type {number|string|undefined} **/ getPropByPageWidth(columns, width));
    const [colTemplate, setColTemplate] = useState(/** @type {string|undefined} **/ formatColTemplate(getPropByPageWidth(customColTemplate, width)));

    useEffect(() => {
        setColumnCount(getPropByPageWidth(columns, width));
    }, [columns, width]);

    useEffect(() => {
        setColTemplate(formatColTemplate(getPropByPageWidth(customColTemplate, width)));
    }, [customColTemplate, width]);

    // format gap value if set
    if (gap && ['string', 'number'].includes(typeof gap)) {
        let formattedGap = '';
        for (const gapSingle of `${gap}`.split(/\s/, 2)) {
            // add px to gapSingle if it's a number
            if (/\d$/.test(gapSingle)) {
                formattedGap += `${gapSingle}px `;
            }
            // change gapSingle to variable if it starts with "--"
            else if (/^--/.test(gapSingle)) {
                formattedGap += `var(${gapSingle}) `;
            } else {
                formattedGap += `${gapSingle} `;
            }
        }
        gap = formattedGap;
    }
    else {
        gap = undefined;
    }

    function formatColTemplate(colTemplate) {
        // format customColTemplate into `minmax(0, n) minmax(0, n)` if set
        if (colTemplate && ['string', 'number'].includes(typeof colTemplate)) {
            let formattedCustomColTemplate = '';
            for (const value of `${colTemplate}`.split(/\s/)) {
                let formattedValue = '';
                // add px to value if it's a number
                if (/^\d+$/.test(value)) {
                    formattedValue = `${value}px `;
                }
                // change value to variable if it starts with "--"
                else if (/^--/.test(value)) {
                    formattedValue = `var(${value}) `;
                } else {
                    formattedValue = `${value} `;
                }
                formattedCustomColTemplate += ['repeat', 'minmax'].some((item) => formattedValue.includes(item)) ? `${formattedValue} ` : `minmax(0, ${formattedValue}) `;
            }
            return formattedCustomColTemplate;
        }
        else {
            return undefined;
        }
    }

    if (columns && !columnCount) return null;

    return (
        <div {...{
            key: id,
            id: id,
            className: `grid ${columnCount ? `columns-${columnCount}` : ''} ${rows ? `rows-${rows}` : ''} ${className ? className : ''}`,
            style: {...style, gap, gridTemplateColumns: colTemplate, gridTemplateRows: customRowTemplate},
        }} {...props}>
            {children}
        </div>
    );
}
