import Block from "UI/App/Components/Block/Block";
import React, {useContext, useEffect, useState} from "react";
import {stringify} from "qs";
import {FetchContext} from "App/Strapi/FetchContext";
import Select from "react-select";
import capitalize from "lodash/capitalize";
import Grid from "UI/App/Components/Grid/Grid";
import Table from "UI/App/Components/Table/Table";
import {toast} from "react-toastify";

export default function ChecklistEditor({chosenChecklistTemplate, setChosenChecklistTemplate, selectedLanguage, setSelectedLanguage, widgets, checklistData, setChecklistData, chosenChecklist, setChosenChecklist}) {
    const [checklistTemplates, setChecklistTemplates] = useState([])
    const fetchContext = useContext(FetchContext);

    const [widgetSearchValue, setWidgetSearchValue] = useState('');
    const [widgetSearchResult, setWidgetSearchResult] = useState([]);

    const [languages, setLanguages] = useState([
        {
            label: 'Nederlands',
            value: 'NL'
        },
        {
            label: 'Engels',
            value: 'EN'
        }
    ]);

    useEffect(() => {
        let searchResult = [];

        // Check if search string is in any of the fixed values
        if('aantal'.includes(widgetSearchValue.toLowerCase())){
            searchResult.push({
                string: `Gekozen aantal in calculatie`,
                id: `CALCULATION-AMOUNT`
            })
        }

        // Check if search string is in any of the fixed values
        if('hoogte'.includes(widgetSearchValue.toLowerCase())){
            searchResult.push({
                string: `Gekozen hoogte in calculatie`,
                id: `CALCULATION-HEIGHT`
            })
        }

        // Check if search string is in any of the fixed values
        if('breedte'.includes(widgetSearchValue.toLowerCase())){
            searchResult.push({
                string: `Gekozen breedte in calculatie`,
                id: `CALCULATION-WIDTH`
            })
        }

        Object.keys(widgets).map((widget, key) => {
            const widgetData = widgets[widget];
            const widgetString = `${widgetData.prefix}${widgetData.id} | ${widgetData.machineName} | ${widgetData.data.title}`;


            // If widget search sting matches search value add to search result
            if (widgetString.toLowerCase().includes(widgetSearchValue.toLowerCase())) {

                // Skip aantal/length/width these are auto filled inputs
                if(widgetData.data.title === 'Aantal' || widgetData.data.title === 'Lengte product' ||widgetData.data.title === 'Breedte product'){
                    return;
                }

                if (widgetData.data.type === 'MaterialChoice') {
                    // Loop trough options
                    for(const searchOption of widgetData.data.value.returnFields){
                        searchResult.push({
                            string: `${widgetData.machineName} ${widgetData.data.title}.${searchOption}`,
                            id: `${widgetData.prefix}${widgetData.id}.${searchOption}`
                        });
                    }
                } else {
                    searchResult.push({
                        string: `${widgetData.machineName} ${widgetData.data.title}`,
                        id: `${widgetData.prefix}${widgetData.id}`
                    });
                }
            }
        })

        // Check if search string is in any of the fixed values
        if('aantal'.includes(widgetSearchValue.toLowerCase())){
            searchResult.push({
                string: `Gekozen aantal in calculatie`,
                id: `CALCULATION-AMOUNT`
            })
        }

        // Check if search string is in any of the fixed values
        if('hoogte'.includes(widgetSearchValue.toLowerCase())){
            searchResult.push({
                string: `Gekozen hoogte in calculatie`,
                id: `CALCULATION-HEIGHT`
            })
        }

        // Check if search string is in any of the fixed values
        if('breedte'.includes(widgetSearchValue.toLowerCase())){
            searchResult.push({
                string: `Gekozen breedte in calculatie`,
                id: `CALCULATION-WIDTH`
            })
        }

        if (widgetSearchValue !== '') {
            setWidgetSearchResult(searchResult)
        } else {
            setWidgetSearchResult([])
        }

    }, [widgetSearchValue])

    // Effect on language
    useEffect(() => {
        const query = stringify({
            populate: '*',
            filters: {
                $or: [
                    {
                        owner: {
                            $eqi: 'pretore'
                        }
                    },
                    {
                        owner: {
                            $eqi: 'both'
                        }
                    }
                ]
            }
        }, {
            encodeValuesOnly: true
        });
        /**
         * Get all templates and set chosen selected
         */
        fetchContext.authAxios.get(`/calculations/checklists/templates?${query}`).then(({data: {data}}) => {
            setChecklistTemplates(data.map((template) => ({
                label: template.name,
                value: template.id,
                inputs: template.inputs,
                id:template.id
            })))
        }).catch((exception) => {
            console.error(exception);
        });

    }, [])

    useEffect(() => {
        if (chosenChecklistTemplate !== null && selectedLanguage !== null) {
            const data = {...checklistData};

            chosenChecklistTemplate?.inputs.map((input) => {
                data[input.id] = input;
            })

            setChecklistData(data);
        }
    }, [chosenChecklistTemplate, selectedLanguage])

    // Helper for updating checklist data
    const updateChecklist = ((string, inputKey) => {
        const dataCopy = {...checklistData};

        dataCopy[inputKey].value = string;

        setChecklistData(dataCopy);
    })

    // Used to add widget ID to clipboard
    function toClipboard(string) {
        navigator.clipboard.writeText(string);
        toast.info(`${string} in gekopiëerd naar het klembord.`)
    }

    return (
        <Block title="Checklist" name="checklistEditor">
            <Grid columns={2} customColTemplate={'1fr 2fr'}>
                <span>
                    <span>
                        <b>Zoeken: {widgetSearchValue}</b> <br/>
                        <small>Aantal, hoogte of breedte uit de calculatie vind je door onderstaand er op te zoeken.</small>
                        <input
                            type="text"
                            value={widgetSearchValue}
                            onChange={(e) => setWidgetSearchValue(e.target.value)}
                        />
                    </span>
                    <Table
                        headers={['Naam']}
                        scroll
                        data={Object.keys(widgetSearchResult).map((resultId, key) => {
                            const searchResult = widgetSearchResult[resultId];

                            return {
                                attributes: {
                                    onClick: () => toClipboard(`${searchResult.id}`),
                                },
                                data: [
                                    searchResult.string
                                ]
                            }
                        })}
                        // data={Object.keys(widgetSearchResult).map((resultId, key) => {
                        //     const widgetData = widgetSearchResult[resultId];
                        //
                        //     return [
                        //         <span onClick={() => toClipboard(`${widgetData.prefix}${widgetData.id}`)}>
                        //             {widgetData.machineName} {widgetData.data.title}
                        //             </span>,
                        //     ]
                        // })}
                        hover
                        border='row'
                        className='table--padding-s'
                    />
                </span>
                <span>
                <div>
                    <label htmlFor="checklistSelect">Kies een checklist:</label>
                    <Select
                        options={checklistTemplates}
                        onChange={(e) => {
                            setChosenChecklistTemplate(e);
                        }}
                        value={chosenChecklistTemplate}
                        noOptionsMessage={() => "Geen checklists gevonden"}
                    />
                </div>
                Taal
                <Select
                    value={selectedLanguage}
                    key="languageSelect"
                    name={'language'}
                    onChange={(e) => setSelectedLanguage(e)}
                    options={languages}
                    id={"checklistCompanySelect"}
                />
                <hr/>
                <br/>
                    {Object.keys(checklistData).map((inputKey, key) => {
                        const input = checklistData[inputKey]
                        return (
                            <Grid key={input.name} columns={2} customColTemplate="250px 100%" style={{alignItems: "center"}}>
                                {capitalize((selectedLanguage.value === 'NL' ? input.dutchName : input.englishName) ?? input.name)}:
                                {input.numberOfLines === 1 ? <input
                                    type="text"
                                    onChange={(e) => updateChecklist(e.target.value, inputKey)}
                                    value={input.value}
                                /> : <textarea
                                    onChange={(e) => updateChecklist(e.target.value, inputKey)}
                                    value={input.value}
                                />}
                            </Grid>
                        )
                    })}
                </span>
            </Grid>
        </Block>
    )
}
