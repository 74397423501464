import React, {useContext, useEffect, useState} from "react";
import Select from "react-select";
import {FetchContext} from "App/Strapi/FetchContext";
import {formatName} from "App/Util/format";


export default function StepOne({projectDescription, setProjectDescription, setChosenLanguage, chosenLanguage, chosenContactPersonId, setChosenContactPersonId, setChosenCustomerId, chosenCustomerId}) {
    const fetchContext = useContext(FetchContext)
    const [companies, setCompanies] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedContactPerson, setSelectedContactPerson] = useState(null);
    const [contactPersons, setContactPersons] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [languages, setLanguages] = useState([
        {
            label: 'Nederlands',
            value: 'NL'
        },
        {
            label: 'Engels',
            value: 'EN'
        }
    ]);

    useEffect(() => {
        for (const language of languages) {
            if (language.value === chosenLanguage) {
                setSelectedLanguage(language)
            }
        }

    }, [chosenLanguage])

    // Run on load
    useEffect(() => {
        getCompanies()
    }, [])

    // Run on company choice
    useEffect(() => {
        // Get contact persons
        getContactPersons();

        // Update state value
        getCompanies();
        // Get contact persons
    }, [chosenCustomerId])

    useEffect(() => {
        // update contact persons
        getContactPersons();
    }, [chosenContactPersonId])

    async function getContactPersons() {
        if (chosenCustomerId === null) return;
        const tmpContentPersons = [];
        setSelectedContactPerson(null);

        await fetchContext.authAxios.get(`/crm/companies/persons?filters[company]=${chosenCustomerId}`).then(({ data: { data } }) => {

            data.map((contactPerson) => {

                if (chosenContactPersonId !== null ? contactPerson.id === chosenContactPersonId : false) {
                    setSelectedContactPerson({
                        label: formatName(contactPerson.firstName, contactPerson.prefixToSurname, contactPerson.surname),
                        value: contactPerson.id
                    });
                }

                tmpContentPersons.push({
                    label: formatName(contactPerson.firstName, contactPerson.prefixToSurname, contactPerson.surname),
                    value: contactPerson.id
                });
            });

            setContactPersons(tmpContentPersons);
        });
    }

    // Get companies
    function getCompanies() {
        const tmpCompanies = [];

        // Load companies
        fetchContext.authAxios.get(`/crm/companies/all`).then(({data}) => {
            data.sort((a,b) => a.name.toLowerCase().trim() > b.name.toLowerCase().trim() ? 1 : -1).map((company) => {
                if (chosenCustomerId !== null ? company.id === chosenCustomerId : false) {
                    setSelectedCustomer({
                        label: company.name,
                        value: company.id,
                    });
                }

                tmpCompanies.push({
                    label: company.name,
                    value: company.id,
                })
            })

            setCompanies(tmpCompanies);
        }).catch((exception) => {
            console.error(exception);
        });
    }

    return (
        <>
            Kies een klant
            <Select
                key="customerSelect"
                value={selectedCustomer}
                name={'customer'}
                onChange={(e) => setChosenCustomerId(e.value)}
                options={companies}
                id={"checklistCompanySelect"}
            />

            Kies een contact persoon
            <Select
                key="contactPersonSelect"
                value={selectedContactPerson}
                name={'contactPerson'}
                onChange={(e) => setChosenContactPersonId(e.value)}
                options={contactPersons}
                id={"checklistCompanySelect"}
            />

            {/*Klant referentie*/}
            {/*<input value={(customerReference ?? '')} onChange={e => setCustomerReference(e.target.value)} placeholder={'Klant referentie'} type={"text"} id={"customerReference"}/>*/}

            Taal
            <Select
                value={selectedLanguage}
                key="languageSelect"
                name={'language'}
                onChange={(e) => setChosenLanguage(e.value)}
                options={languages}
                id={"checklistCompanySelect"}
            />

            Project omschrijving
            <input value={(projectDescription ?? '')} onChange={e => setProjectDescription(e.target.value)} placeholder={'Project omschrijving'} type={"text"} id={"projectDescription"}/>
        </>
    )
}
