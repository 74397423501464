import React, {ReactElement, useState} from "react";
import Icon from "../../../../Components/Icon/Icon";
import Grid from "../../../../Components/Grid/Grid";

export default function HourWidgetRepeater({defaultEntries = []}: {defaultEntries?: Array<any>}): ReactElement {
    const [entries, setEntries] = useState(defaultEntries);

    const HourEntry = ({entry, index}: {entry: any, index: number}) => {
        return <div style={{
            display: 'flex'
        }}>
            <Grid columns='3' customColTemplate='1fr 2fr 1fr'>

                <input type={'number'} step={0.01} name={`val[${index}][opstart]`} placeholder={'Opstartkosten'} defaultValue={entry[0]} onChange={({target: {value}}) => setEntries((prev) => {
                    prev[index][0] = value ? value : '';
                    return prev;
                })} />

                <input type={'text'} name={`val[${index}][werk]`} placeholder={'Soort werk'} defaultValue={entry[1]} onChange={({target: {value}}) => setEntries((prev) => {
                    prev[index][1] = value ? value : '';
                    return prev;
                })} />

                <input type={'number'} step={0.01} name={`val[${index}][uurloon]`} placeholder={'Uurloon'} defaultValue={entry[2]} onChange={({target: {value}}) => setEntries((prev) => {
                    prev[index][2] = value ? value : '';
                    return prev;
                })} />

            </Grid>
            <button type={'button'} className={'btn btn--transparent btn--icon-red'} onClick={() => {setEntries((prev) => {
                delete prev[index];
                return prev.filter((elem) => elem);
            })}}><Icon name={'minus'} /></button>
        </div>
    }

    return (
        <div id={`widget-values-container`}>
            <button type={'button'} onClick={() => { setEntries((prev) => [...prev, []]) }} ><Icon name={'plus'} /></button>
            {entries.map((entry, key) => <HourEntry key={key} index={key} entry={entry}/>)}
        </div>
    );
}