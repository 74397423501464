/**
 * dynamically set the height of the textarea on input change.
 *
 * using height instead of rows allows it to take font size/line height into account automatically.
 */
export function autoSizeTextArea(target: any) {
    // reset the height to 1px so `scrollHeight` is reset and this stops weird resizing on first few character inputs
    target.style.height = '1px';
    // set the height to the `scrollHeight`
    target.style.height = `${target.scrollHeight}px`;

    return `${target.scrollHeight}px`;
}
