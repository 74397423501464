import Icon from "UI/App/Components/Icon/Icon";
import {useEffect} from "react";

export default function WeeklyHeader({monday, setMonday, setWeeklyMonday, sunday, setSunday, setWeeklySunday, startDate}) {

    useEffect(() => {
        startDate && setMonday(setWeeklyMonday(startDate));
        startDate && setSunday(setWeeklySunday(startDate));
    }, []);

    function onWeekChange(type) {
        function applyChanges(prevDay, dayType) {
            if (dayType === 'MONDAY') {
                const newDate = new Date(prevDay);
                newDate.setUTCDate(newDate.getUTCDate() - prevDay.getUTCDay() + 1 + (type === 'NEXT' ? 7 : -7));
                newDate.setUTCHours(0, 0, 0, 0);
                return newDate;
            } else if (dayType === 'SUNDAY') {
                const newDate = new Date(prevDay);
                newDate.setUTCDate(newDate.getUTCDate() - prevDay.getUTCDay() + (type === 'NEXT' ? 7 : -7));
                newDate.setUTCHours(0, 0, 0, 0);
                return newDate;
            }
        }

        setMonday((prevMonday) => applyChanges(prevMonday, 'MONDAY'));
        setSunday((prevSunday) => applyChanges(prevSunday, 'SUNDAY'));
    }

    useEffect(() => {
        function changeDateWithArrowKeys(e) {
            if(e.key === 'ArrowLeft') {
                onWeekChange('PREV');
            } else if(e.key === 'ArrowRight') {
                onWeekChange('NEXT');
            } else if (e.key === 'ArrowUp') {
                setMonday(setWeeklyMonday(new Date()));
                setSunday(setWeeklySunday(new Date()));
            }
        }

        document.addEventListener('keydown', changeDateWithArrowKeys);

        return () => {
            document.removeEventListener('keydown', changeDateWithArrowKeys)
        }
    }, [])

    return (
        <>
            <div onClick={() => onWeekChange('PREV')}>
                <Icon
                    name="chevron-left"
                    className='planning__header__date__content__icon__button'
                />
            </div>
            <span className='planning__header__date__content__text'>
                {monday.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short' })}. - {sunday.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short' })}. {sunday.toLocaleDateString('nl-NL', { year: 'numeric' })}
            </span>
            <div onClick={() => onWeekChange('NEXT')}>
                <Icon
                    name="chevron-right"
                    className='planning__header__date__content__icon__button'
                />
            </div>
        </>
    )
}
