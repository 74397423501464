import React, {ReactElement} from "react"
import WidgetValuesContainer from "../WidgetValuesContainer";
import IWidget from "./IWidget"
import WidgetBase from "./WidgetBase"
import {TrenderType} from "./WidgetTypes"

/**
 * Widget class
 * @extends WidgetBase @implements IWidget
 */
export default class Times extends WidgetBase implements IWidget {
    callback: ((object: Object) => any);
    widgetData: String | null
    saveWidget: ((object: Object) => any);
    optionalWidgetData: object | undefined;
    debug: boolean;
    prefix: string | undefined;

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;
    }

    /**
     * @return ReactElement
     */
    public getPreviewHTML(): JSX.Element {
        const widgetData = {
            widgetType: 'Times',
        }

        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>val x val</div>
    }

    /**
     * Returns from html
     */
    public getFormHTML(): JSX.Element {
        const widgetValues = this.getWidgetValues();

        return <form onSubmit={(event) => this.handleSubmit(event)}>
            <h3>Keer</h3>
            <div className='input-group'>
                Naam: <input type="text" id='title' name="title"/>
            </div>
            <WidgetValuesContainer widgetValues={widgetValues} />
            <button type="submit">Opslaan</button>
        </form>
    }

    public getBadgeHTML() {
        return <div>Badge html:</div>
    }

    /**
     * Handles submit from getFormHTML
     * @param event:any
     */
    handleSubmit(event: any): void {
        event.preventDefault();

        let values: { [key: string]: string } = {};

        event.target.elements?.['val[]']?.forEach((value: HTMLInputElement, key: number) => {
            values[`val${key}`] = value.value;
        });

        const data = {
            type: "Times",
            title: event.target.elements.title.value,
            value: values
        };

        this.addWidgetToWidgetContainer("Keer: " + data?.title, data);
    }

    /**
     * Renders calculation form
     */
    public getCalculationForm():void {
        this.addWidgetToCalculationForm(``,this.optionalWidgetData, this.optionalWidgetData.parentId)

        // return <div id={this.optionalWidgetData.id}>times</div>
    }

    /**
     * Renders preview form
     */
    public getPreviewFormHTML(): ReactElement {
        return <input key={Date.now()} id={this?.optionalWidgetData?.id} type={"hidden"} name={this?.optionalWidgetData?.title} value={this?.optionalWidgetData?.value}/>
    }

    /**
     * Returns formula string of given widget
     */
    public getFormulaString(): string {
        let str = '';
        Object.entries(this?.optionalWidgetData?.value).filter(([key, value]) => /^val[0-9]+$/.test(key)).map(([key, value]) => {
            str += `${value} * `
        });

        return str.slice(0,-2);
    }

    /**
     * Returns html for edit
     */
    getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer("Keer: " + this.optionalWidgetData?.data?.title, this.optionalWidgetData, '', true);
    }

    getEditWidgetForm(editData: { value: any; title: any; id: any; }): JSX.Element {
        const widgetValues = this.getWidgetValues();

        return <form onSubmit={(event) => this.handleEdit(event)}>
            <input type="hidden" name={"id"} value={editData.id}/>
            <div className='input-group'>
                Naam: <input type="text" id='title' name="title" defaultValue={editData.title}/>
            </div>
            <WidgetValuesContainer widgetValues={widgetValues} selected={editData.value}/>
            <button type="submit">Opslaan</button>
        </form>
    }

    /**
     * Handles submit from getFormHTML
     * @param event:any
     */
    handleEdit(event: any): void {
        event.preventDefault();

        let values: { [key: string]: string } = {};

        event.target.elements?.['val[]']?.forEach((value: HTMLInputElement, key: number) => {
            values[`val${key}`] = value.value;
        });

        const data = {
            type: "Times",
            title: event.target.elements.title.value,
            value: values
        };

        this.updateWidget(event.target.elements.id.value, "Keer: " + data?.title, data);
    }

    /**
     * Calcualtion logic
     */
    calculationFunctionality(): any {
        const widgetElement = this.getWidgetElement();

        if (!widgetElement) return;

        if (typeof this?.optionalWidgetData?.data?.value !== 'undefined') {
            const res = Object.entries(this.optionalWidgetData.data.value).map(([key, value]) => {
                if (key.match(/^val[0-9]+(?:\.[0-9]+)?$/) !== null && typeof value === 'string') {
                    const widgetValue = this.getResultFromWidget(value, this.optionalWidgetData?.prefix);

                    if (typeof widgetValue === 'boolean') {
                        return 1;
                    } else if (typeof widgetValue === 'string') {
                        const parsedValue = parseFloat(widgetValue);

                        if (isNaN(parsedValue)) return 1;

                        return parsedValue;
                    } else if (isNaN(widgetValue)) {
                        return 1;
                    } else return widgetValue;
                }
                return 1;
            });
            const result = res.reduce((partial, val) => partial * val);

            this.optionalWidgetData.data.value.result = result;
        } else {
            this.optionalWidgetData.data.value.result = 0;
        }


        this.updateWidgetData(this.getWidgetIdWithPrefix(), this.optionalWidgetData);

        /**
         * Handle debug
         */
        if (this.debug) {
            widgetElement.innerHTML = `[${this.optionalWidgetData?.prefix}${this.optionalWidgetData?.id}] Keer: ${this.optionalWidgetData.data.title} ${Object.entries(this.optionalWidgetData.data.value).filter((val) => val[0].match(/^val[0-9]+$/) !== null).map((val) => val[1]).join(' &times; ')} = ${(this.optionalWidgetData.data.value.result ?? 0)}`;
            widgetElement.classList.remove('hidden');
        } else {
            widgetElement.innerHTML = "";
            widgetElement.classList.add('hidden');
        }
    }
}
