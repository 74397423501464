import Icon from 'UI/App/Components/Icon/Icon';
import {useContext, useEffect, useState} from 'react';
import {stringify} from 'qs';
import {FetchContext} from 'App/Strapi/FetchContext';
import {toast} from 'react-toastify';
import Block from 'UI/App/Components/Block/Block';
import {openPopup} from 'UI/App/Components/Popup/Popup';

export default function MachineDropdown({ plannedMachines, setAddMachinePopupData}) {
    const { authAxios } = useContext(FetchContext);
    
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const [unplannedMachines, setUnplannedMachines] = useState([]);

    useEffect(() => {
        const machinesQuery = {
            fields: ['name'],
            sort: ['name:ASC'],
            noCalculate: true,
        };

        if (!Array.isArray(plannedMachines)) return;
        
        authAxios
            .get(`/calculations/resources/machines/all?${stringify(machinesQuery)}`)
            .then(({ data }) => {
                setUnplannedMachines(data);
            })
            .catch((error) => {
                toast.error('Er is een fout opgetreden bij het ophalen van de machines!');
                console.error(error);
            });
    }, [authAxios, plannedMachines])

    return (
        <Block
            name='unplanned__machines'
            title='Niet ingeplande machines:'
            headerRightSideChildren={
                <span className='unplanned__machines__toggle' onClick={() => setIsDropdownOpened((prev) => !prev)}> 
                    <Icon
                        name='chevron-up'
                        className={isDropdownOpened ? 'open' : undefined}
                    />
                </span>
            }
            contentStyle={{padding:0}}
        >
            <div className={`toggled_content ${isDropdownOpened ? 'open' : 'closed'}`}>
                    {unplannedMachines.map((machine, index) => {
                        return (
                            <div className='machine__name' key={`${machine.name}-${index}`}>
                                {machine.name}
                                <div className="machine__add__container">
                                    <button
                                        className={'btn btn--primary btn--round'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            openPopup('addMachineToPlanningPopup');
                                            setAddMachinePopupData(machine);
                                        }}
                                    >
                                        <Icon name={'plus'}/>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </Block>
    );
}
