import { useEffect, useState } from 'react';
import { getCssVariableColor } from 'App/Util/styling'

import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from "chart.js";
import 'chartjs-adapter-moment';
import { Line } from "react-chartjs-2";

// register chartJs
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export default function LineGraph({
    dataset,
    wrapperProps,
    title,
    scales,
    customOptions,
    labelSuffix
}) {

    const options = customOptions ?? {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: title !== undefined,
                text: title ?? "No Title",
            },
            tooltip: {
                callbacks: {
                    title: (e) => e[0].label,
                    label: (e) => `${e.formattedValue}${labelSuffix ? `${labelSuffix}` : ''}`
                }
            },
        },
        locale: "nl-NL",
        elements: {
            point: {
                borderWidth: 0,
                radius: 0
            }
        },
        scales: scales ?? {
            x: {
                type: "time",
                time: {
                    tooltipFormat: 'DD MMM HH:mm',
                    unit: 'day',
                    displayFormats: {
                        millisecond: 'HH:mm:ss.SSS',
                        second: 'HH:mm:ss',
                        minute: 'HH:mm',
                        hour: 'HH'
                    }
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 7
                }

            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
        }
    }

    console.log(options);

    const [labels, setLabels] = useState(["0", "1"])
    const [graphElement, setGraphElement] = useState(<></>)

    useEffect(() => {
        if (scales === undefined) {
            const now = new Date();
            const nowSevenDays = [];

            // push today to the array
            nowSevenDays.push(new Date(now).toISOString());
            for (let i = 0; i < 6; i++) {
                nowSevenDays.push(new Date(now.setDate(now.getDate() - 1)).toISOString())
            }
            // reverse the order to make current day be on the right
            nowSevenDays.reverse();
            setLabels(nowSevenDays)
        }

    }, [])

    useEffect(() => {
        if (dataset !== undefined) {
            let filteredDataset = []
            let errorInData = false;
            if (Array.isArray(dataset)) {
                for (const set of dataset) {
                    if (set?.label === undefined) {
                        errorInData = true;
                        console.error("graph dataset contains no field 'label'")
                    }
                    if (set?.data === undefined) {
                        errorInData = true;
                        console.error("graph dataset contains no field 'data'")
                    }
                    if (!errorInData) {
                        set.borderColor = set.borderColor ?? getCssVariableColor("--primary-medium");
                        set.backgroundColor = set.backgroundColor ?? getCssVariableColor("--primary");
                        filteredDataset.push(set)
                    }
                }
            } else {
                if (dataset?.label === undefined) {
                    errorInData = true;
                    console.error("graph dataset contains no field 'label'")
                }
                if (dataset?.data === undefined) {
                    errorInData = true;
                    console.error("graph dataset contains no field 'data'")
                }
                if (!errorInData) {
                    filteredDataset.push(dataset)
                }
            }

            const formatedDataSets = {
                labels: labels,
                datasets: filteredDataset
            }

            if (!errorInData) {
                setGraphElement(<Line data={formatedDataSets} options={options} />)
            } else {
                setGraphElement(<>Er is iets fout gegaan met de grafiek...</>)
            }

        }
    }, [dataset, labels])

    return (
        <div id="canvasWrapper" className='canvas_wrapper' {...wrapperProps ?? ""}>
            {graphElement}
        </div>
    )
}