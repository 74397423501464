import React, {ReactElement} from "react";
import WidgetInformation from "UI/App/Partials/Content/Calculations/Widgets/Widgets/WidgetInformation";
import Icon from "../../../../../Components/Icon/Icon";
import IWidget from "./IWidget";
import WidgetBase, {setSelectedIndexByWidgetId} from "./WidgetBase";
import WidgetConditionalLogic from "./WidgetConditionalLogic";
import {TrenderType} from "./WidgetTypes";
import {all} from "axios";

export default class SelectWidget extends WidgetBase implements IWidget {
    callback: ((object: Object) => any);
    widgetData: String | null
    saveWidget: ((object: Object) => any);
    inputs: ReactElement[] = [];
    optionalWidgetData: any;
    debug: boolean;
    allowHidden: boolean;

    prefix: string | undefined;

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false, showVisibilityCheckbox: boolean = false, allowHidden: boolean = true) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;
        this.visibilityCheckbox = showVisibilityCheckbox;
        this.allowHidden = allowHidden;
    }

    /**
     * Returns react element for preview
     */
    getPreviewHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        const widgetData = {
            widgetType: 'SelectWidget',
        }

        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>Select</div>
    }

    /**
     * returns form react element
     */
    public getFormHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        const values = this.getWidgetValues(['SelectWidget', 'MaterialChoice']);
        return <form onSubmit={(e) => this.handleSubmit(e, this)} id="SelectWidgetForm">
            <h3>Meerkeuze</h3>
            <div className="input-group">
                Naam: <input type="text" name="name"/>
            </div>
            <div className="input-group">
                Standaard waarde (optioneel):
                <select name={'linkedValue'}>
                    <option value={""}>Geen</option>
                    {values.map(
                        (value) =>
                            <optgroup label={value.label}>
                                {value.options?.map((option) => <option value={option.value}>{option.label}</option>)}
                            </optgroup>
                    )}
                </select>
            </div>
            <div className="input-group">
                Waardes:
                <button type="button" className={'btn btn--margin-l btn--round'} onClick={() => this.addSelectWidgetInput(this)}><Icon name={"plus"}/></button> <br/>
            </div>
            <div className="input-group">
                <span style={{width: '50%', display: 'inline-block', fontWeight: 'bold'}}>Name</span>
                <span style={{width: '50%', display: 'inline-block', fontWeight: 'bold'}}>Value</span>
                <div id="selectInputs">
                    <span><input type="text" defaultValue={'Maak een keuze'} style={{backgroundColor: '#f3f3f3'}} disabled /><input type="text" defaultValue={'geen'} style={{backgroundColor: '#f3f3f3'}} disabled/></span>
                    {this.inputs}
                </div>
            </div>
            <div className="input-group">
                <WidgetInformation/>
            </div>
            <div className="input-group">
                <WidgetConditionalLogic/>
            </div>
            <button type="submit">Opslaan</button>
        </form>
    }

    /**
     * Handles the form submit adds data to saveWidget function
     * @param event
     * @private
     */
    handleSubmit(event: any, parentClass: any): void {
        event.preventDefault();
        let cleanData: { [key: string]: [] } = {
            'name': [],
            'names': [],
            'values': []
        }
        const data = {
            type: "SelectWidget",
            options: [],
            title: "Select widget",
            value: {
                linkedValue: null,
                conditional: {
                    number: event.target.elements?.conditionalValue.value,
                    type: event.target.elements?.conditionalType.value,
                    target: event.target.elements?.conditionalLogicWidget.value
                },
                information: event.target.elements?.information.value
            }
        };

        /**
         * CLean data / Add title
         */
        for (const val of (new FormData(event.target).entries())) {
            // console.log('hier');
            // console.log(val);
            if (val[0] === 'name') {
                data.title = val[1];
            } else if (val[0] === 'information') {
                // console.log('information');
                // console.log(val);
            } else if (val[0] === 'linkedValue') {
                data.value.linkedValue = val[1] === '' ? null : val[1];
            } else if (val[0] === 'conditionalLogicWidget') {
                // console.log('conditionalLogicWidget');
                // console.log(val);
            } else if (val[0] === 'conditionalType') {
                // console.log('conditional type');
                // data.value.conditional.target = val[1]
                console.log(val)
            } else if (val[0] === 'conditionalValue') {
                // console.log('conditional value');
                // console.log(val);
                // data.value.conditional.number = val[1]
            } else {
                // console.log(val);
                cleanData[val[0]].push(val[1])
            }
        }


        /**
         * Push cleaned data to data for storage
         */
        for (let i = 0; i < cleanData.names.length; i++) {
            data.options.push({name: cleanData.names[i], value: cleanData.values[i]});
        }

        /**
         * Add to widget container
         */
        parentClass.addWidgetToWidgetContainer("Select: " + data.title, data);
    }

    /**
     * returns form react element
     */
    public getEditWidgetForm(editData: any): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        const values = this.getWidgetValues(['SelectWidget', 'MaterialChoice', 'Input']);
        let inputs = [];
        for (const option of editData.options) {
            inputs.push(<span><input type="text" name="names" defaultValue={option.name}/><input type="text" name="values" defaultValue={option.value}/></span>);
        }

        return <form onSubmit={this.handleEdit} id="SelectWidgetForm">
            <input type="hidden" name={"id"} value={editData.id}/>
            <div className="input-group">
                Naam: <input type="text" name="name" defaultValue={editData.title}/>
            </div>
            <div className="input-group">
                Standaard waarde (optioneel):
                <select name={'linkedValue'} defaultValue={editData?.value?.linkedValue}>
                    <option value={""}>Geen</option>
                    {values.map(
                        (value) =>
                            <optgroup label={value.label}>
                                {value.options?.map((option) => <option value={option.value}>{option.label}</option>)}
                            </optgroup>
                    )}
                </select>
            </div>
            <div className="input-group">
                Waardes:
                <button type="button" className={'btn btn--margin-l btn--round'} onClick={() => this.addSelectWidgetInput(this)}><Icon name={"plus"}/></button> <br/>
            </div>
            <div className="input-group">
                <span style={{width: '50%', display: 'inline-block', fontWeight: 'bold'}}>Name</span>
                <span style={{width: '50%', display: 'inline-block', fontWeight: 'bold'}}>Value</span>
                <div id="selectInputs">
                    <span><input type="text" defaultValue={'Maak een keuze'} style={{backgroundColor: '#f3f3f3'}} disabled /><input type="text" defaultValue={'geen'} style={{backgroundColor: '#f3f3f3'}} disabled/></span>
                    {inputs}
                </div>
            </div>
            <div className="input-group">
                <WidgetInformation content={editData?.value?.information}/>
            </div>
            <div className="input-group">
                <WidgetConditionalLogic/>
            </div>
            <button type="submit">Opslaan</button>
        </form>
    }

    /**
     * Handles the form submit adds data to saveWidget function
     * @param event
     * @private
     */
    handleEdit(event: any): void {
        event.preventDefault();
        let cleanData: { [key: string]: [] } = {
            'name': [],
            'names': [],
            'values': []
        }
        const data = {
            type: "SelectWidget",
            options: [],
            title: "Select widget",
            value: {
                linkedValue: event.target.elements?.linkedValue,
                conditional: {
                    number: event.target.elements?.conditionalValue.value,
                    type: event.target.elements?.conditionalType.value,
                    target: event.target.elements?.conditionalLogicWidget.value
                },
                information: event.target.elements?.information.value
            }
        };

        /**
         * CLean data / Add title
         */
        for (const val of (new FormData(event.target).entries())) {
            if (val[0] === 'name') {
                data.title = val[1];
            } else if (val[0] === 'information') {
                // console.log('information');
            } else if (val[0] === 'linkedValue') {
                data.value.linkedValue = val[1] === '' ? null : val[1];
            } else if (val[0] === 'conditionalLogicWidget') {
                // console.log('conditionalLogicWidget');
                // console.log(val);
            } else if (val[0] === 'conditionalType') {
                // console.log('conditional type');
                // data.value.conditional.target = val[1]
                console.log(val)
            } else if (val[0] === 'conditionalValue') {
                // console.log('conditional value');
                // console.log(val);
                // data.value.conditional.number = val[1]
            } else if (val[0] === 'id') {
                // console.log('conditional value');
                // console.log(val);
                // data.value.conditional.number = val[1]
            } else {
                // console.log(val);
                cleanData[val[0]].push(val[1])
            }
        }


        /**
         * Push cleaned data to data for storage
         */
        for (let i = 0; i < cleanData.names.length; i++) {
            data.options.push({name: cleanData.names[i], value: cleanData.values[i]});
        }

        /**
         * Add to widget container
         */
        (new WidgetBase()).updateWidget(event.target.elements.id.value, "Select: " + data.title, data);
    }

    /**
     * Add input to widget
     */
    addSelectWidgetInput(parentClass: any): void {
        document.getElementById('selectInputs')?.insertAdjacentHTML('beforeend', '<span><input type="text" name="names"/><input type="text" name="values"/></span>');
        // return <input name="test[]" />
    }

    /**
     * Returns react element with badge html
     */
    getBadgeHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return <div>Select widget html:</div>
    }

    public getCalculationForm() {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
        let options = ""

        let hasSelectedOption = false;
        const defaultValue = this.optionalWidgetData.data.selectedValue || this.optionalWidgetData.data.defaultValue;

        for (const option of this.optionalWidgetData.data.options) {
            const isSelected = defaultValue === option.value;
            if (isSelected) {
                hasSelectedOption = true;
            }

            options += `<option value="${option.value}" ${isSelected ? "selected" : ""}>${option.name}</option>`
        }

        // If there is a visibility checkbox, we are in calculation template mode.
        // In that case we want to be able to choose the value of the select widget, or clear the default value.
        const firstOptionDisabled = this.visibilityCheckbox ? '' : 'disabled';
        const firstOptionText = this.visibilityCheckbox ? 'Geen standaard waarde' : 'Maak een keuze';

        // Whether the first option is selected or not depends on whether there is a selected option.
        const firstOptionSelected = hasSelectedOption ? '' : 'selected';

        const widgetContent = `<span>
            <span class="visibleInputCheckbox">
                Verbergen: <input type="checkbox" class="visibleInputCheckbox" ${this.optionalWidgetData?.data?.hidden ? 'checked' : ''}>
            </span>
            <label for="${id}">
                <span class="widgetId hidden">[${id}]</span>
                ${this.optionalWidgetData.data.title}
                ${this.optionalWidgetData.data.value.linkedValue ? "" : `<div class="chooseInputValue">
                    <select class="valueSetterSelect"></select>
                </div>`}
            </label>
            <select class="selectWidget select-${id}" id="${id}">
                <option value="null" ${firstOptionDisabled} ${firstOptionSelected}>${firstOptionText}</option>
                ${options}
            </select>
        </span>`;

        this.addWidgetToCalculationForm(widgetContent, this.optionalWidgetData, this.optionalWidgetData.parentId);

        if (this.optionalWidgetData.data.value.linkedValue) {
            const linkedWidgetID = this.optionalWidgetData.prefix + this.optionalWidgetData.data.value.linkedValue;

            document.addEventListener('change', event => {
                if (event?.target?.id === linkedWidgetID) {
                    this.calculationFunctionality();
                }
            });
        }
    }

    /**
     * Edit functionality
     */
    getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer("Select: " + this.optionalWidgetData.data.title, this.optionalWidgetData, undefined, true);
    }

    /**
     * Returns part of formula
     */
    getFormulaString(): String {
        return "";
    }

    /**
     * Renders preview form
     */
    getPreviewFormHTML(): React.ReactElement {
        let options: ReactElement[] = [];

        for (const option of this.optionalWidgetData.options) {
            options.push(<option value={option.value}>{option.name}</option>)
        }

        return <span>{this.optionalWidgetData.title} <select>{options}</select></span>;
    }

    async provideData(): Promise<Record<string, any>> {
        let options = [];
        for (const option of this.optionalWidgetData.data.options) {
            options.push({value: option.value, title: option.name});
        }
        return {
            [this.optionalWidgetData.data.title]: options
        }
    }

    /**
     * Calcualtion logic
     */
    calculationFunctionality(): any {
        const widgetElement = this.getWidgetElement();

        if (!widgetElement) return;

        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
        const widgetDataString = widgetElement.getAttribute("widgetData");
        const widgetData = JSON.parse(widgetDataString ?? "{}");
        const parentElement = document.getElementById(id);

        if (parentElement === null) return;

        const select = parentElement?.querySelector<HTMLSelectElement>("select.selectWidget");

        if (select === null) return;

        let options = select.querySelectorAll("option:not([disabled])");

        // If not debug
        if (!this.debug) {
            // If one option hide
            if (options.length === 1) {
                // Set only option selected
                options[0].selected = 'selected'

                // Hide select
                widgetElement?.classList.add('hidden');
            }
        } else {
            widgetElement?.classList.remove('hidden');
        }

        // Handle default value
        if (widgetData.data.defaultValue !== undefined) {
            if (!select.value) {
                setSelectedIndexByWidgetId(select, widgetData.data.defaultValue);
            }
        }


        // Handle linked values
        if (this.optionalWidgetData.data.value.linkedValue !== null) {

            if (select) {
                const res = setSelectedIndexByWidgetId(select, this.optionalWidgetData.data.value.linkedValue, this.optionalWidgetData?.prefix);

                // show selectbox in debug or if the value was not found
                if (!this.debug && res !== false) {
                    parentElement.classList.add('hidden');
                } else {
                    parentElement.classList.remove('hidden');
                }
            }
        }

        /**
         * If selected value is set get value and set
         */
        if (widgetData.data.selectedValue !== undefined && widgetData.data.selectedValue) {

            const res = setSelectedIndexByWidgetId(select, widgetData.data.selectedValue);

            if (!this.debug && res !== false) {
                widgetElement.classList.add('hidden');
            }
        }

        // Handle visibility checkbox
        if (this.visibilityCheckbox) {
            widgetElement?.querySelector('.visibleInputCheckbox')?.classList.remove('hidden')
        } else {
            widgetElement?.querySelector('.visibleInputCheckbox')?.classList.add('hidden')
        }

        // Handle debug
        if (this.debug) {
            widgetElement.querySelector('label')?.querySelector('.widgetId')?.classList.remove('hidden')
            if (widgetElement.querySelector('label')?.querySelector('.chooseInputValue') !== null && !widgetData.data.value.linkedValue) {
                widgetElement.querySelector('label')?.querySelector('.chooseInputValue')?.classList.remove('hidden')

                /**
                 * If not selected no options exist add options if selected handle change
                 */
                if (widgetElement.querySelector('label')?.querySelector('.chooseInputValue')?.querySelector('.valueSetterSelect')?.value === "") {
                    const types = this.getWidgetValues();
                    let options = "";
                    let oldTab = '';
                    options += `<option value="" selected>Kies waarde</option>`
                    options += `<option value="0">Leeg (0)</option>`;
                    options += `<option value="1">Leeg (1)</option>`;

                    for (const type of types) {
                        for (const value of type.options) {
                            // Continue if blank label
                            if (value.label === 'label') continue


                            const tabNumber = value.label.match(/\[(\d+-\d+).*]/)?.[1];
                            const tabHandleId = tabNumber !== undefined ? `tablink_${tabNumber}` : undefined;
                            const tabHandleName = tabHandleId !== undefined ? document.getElementById(tabHandleId)?.innerText : undefined;
                            const tabCustomName = tabNumber !== undefined ? document.getElementById(tabNumber)?.querySelector('input.machineTitle')?.value : undefined;

                            if (oldTab === '' && tabHandleId !== undefined) {
                                options += `<optgroup label="${(tabCustomName ?? tabHandleName)}">`;
                                oldTab = tabHandleId;
                            }

                            if (tabHandleId !== oldTab && tabHandleId !== undefined) {
                                options += `</optgroup><optgroup label="${(tabCustomName ?? tabHandleName)}">`;
                                oldTab = tabHandleId;
                            }

                            let selected = '';

                            if (widgetData.data.selectedValue !== undefined) {
                                if (widgetData.data.selectedValue === value.value) {
                                    selected = 'selected'
                                }
                            }

                            options += `<option value="${value.value}" ${selected}>${(tabCustomName ?? tabHandleName)} ${value.label}</option>`
                        }
                    }

                    widgetElement.querySelector('label').querySelector('.chooseInputValue').querySelector('.valueSetterSelect').innerHTML = ""
                    widgetElement.querySelector('label')?.querySelector('.chooseInputValue')?.querySelector('.valueSetterSelect')?.insertAdjacentHTML("afterbegin", options);
                } else {
                    const selectedIndex = widgetElement.querySelector('label')?.querySelector('.chooseInputValue')?.querySelector('.valueSetterSelect')?.options.selectedIndex;
                    const selectedValue = widgetElement.querySelector('label')?.querySelector('.chooseInputValue')?.querySelector('.valueSetterSelect')?.options[selectedIndex].value

                    if (selectedValue !== "") {
                        setSelectedIndexByWidgetId(select, selectedValue);

                        widgetData.data.selectedValue = selectedValue;
                    }
                }
            }

        } else {
            widgetElement.querySelector('label')?.querySelector('.chooseInputValue')?.classList.add('hidden')
            widgetElement.querySelector('label')?.querySelector('.widgetId')?.classList.add('hidden')
        }

        const defaultValue = widgetElement?.querySelector<HTMLSelectElement>('select.selectWidget')?.value;

        if (defaultValue && defaultValue !== 'null') {
            widgetData.data.defaultValue = defaultValue;
        } else if (widgetData.data.defaultValue) {
            delete widgetData.data.defaultValue;
        }

        // If not debug handle visibliby
        if (this.allowHidden) {
            if (!this.debug && !this.visibilityCheckbox) {
                if (this.optionalWidgetData.data.hidden !== undefined) {
                    if (this.optionalWidgetData.data.hidden) {
                        widgetElement?.classList.add('hidden')
                    }
                }
            }
        }

        parentElement.setAttribute('widgetdata', JSON.stringify(widgetData));
    }

}
