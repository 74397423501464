import React, {useState} from "react";
import WidgetBase from "./Widgets/WidgetBase";
import CreatableSelect from "react-select/creatable";
import Icon from "../../../../Components/Icon/Icon";

export default function WidgetValuesContainer({ widgetValues, selected = {} }: { widgetValues: Array<any>, selected?: { [key: string]: string } }) {
	if (selected?.conditional) delete selected.conditional;
	const [inputs, setInputs] = useState(Object.values(selected));

	const ValueSelect = ({ selected, inputKey }: { selected: string, inputKey: number }) => {
		let selectedValue;

		// check if the selected value is just a number
		if (selected.match(/^[0-9]+(\.[0-9]*)?$/) !== null) {
			selectedValue = { label: selected, value: selected };
		} else {
			selectedValue = WidgetBase.getSelectedValues(widgetValues, selected);
		}

		return <div className={'input-group'} style={{ display: 'flex', width: '100%' }}>
			<CreatableSelect
				defaultValue={selectedValue} name={"val[]"} options={widgetValues} onChange={(value) => setInputs((prev) => {
					prev[inputKey] = value ? value.value : '';
					return prev;
				})} />
			<button type={'button'} className={'btn btn--transparent btn--icon-red'} onClick={() => {
				setInputs((prev) => {
					delete prev[inputKey];
					return prev.filter((elem) => elem);
				})
			}}><Icon name={'minus'} /></button>
		</div>;
	};

	return (
		<div id={`widget-values-container`}>
			<button type={'button'} onClick={() => { setInputs((prev) => [...prev, '']) }} ><Icon name={'plus'} /></button>
			{inputs.map((input, key) => <ValueSelect key={key} inputKey={key} selected={input} />)}
		</div>
	)
}
