import logo from "./logo192.png";

export default function swDev() {
    // Check if the browser supports service workers
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register(`${process.env.PUBLIC_URL}/sw.js`)
            .then((registration) => {
                // Asks permissions to send notifications
                Notification.requestPermission();
            })
            .catch((error) => {
                console.error("Error registering Service Worker:", error);
            });
    }

    // Function to send desktop notifications
    function sendDesktopNotification(eventSubject) {
        const notificationOptions = {
            body: `Event "${eventSubject}" is starting soon!`,
            icon: `${logo}`, // Replace with the path to your notification icon
            badge: `${logo}`, // Replace with the path to your notification badge
            // Other options you can customize
            // image: 'URL_TO_IMAGE',
            // vibrate: [200, 100, 200],
        };

        if (Notification.permission === "granted") {
            new Notification("Upcoming Event Notification", notificationOptions);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    new Notification("Upcoming Event Notification", notificationOptions);
                }
            });
        }
    }

    // Polling interval for checking events
    const eventPollingInterval = setInterval(async () => {
        try {
            const accessToken = localStorage.getItem('msToken')
            if (!accessToken || accessToken === 'undefined') {
                return;
            }

            // Query Microsoft Graph API for events
            const response = await fetch("https://graph.microsoft.com/v1.0/me/events", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });


            if (response.ok) {
                const events = await response.json();
                const currentTime = new Date();

                // Check for upcoming events
                for (const event of events.value) {
                    const eventStartTime = new Date(event.start.dateTime);

                    const timeDifference = eventStartTime.getTime() - currentTime.getTime();
                    const reminderTime = event.reminderMinutesBeforeStart * 60 * 1000;

                    const minimalTime = reminderTime - 60010;
                    //here it's checking the time 
                    if (timeDifference > minimalTime && timeDifference <= reminderTime) {
                        sendDesktopNotification(event.subject);
                    }
                }
            } else {
                const errorResponse = await response.json();
                console.error("Error fetching events:", errorResponse.error.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }, 60000);

    return eventPollingInterval;
}
