export default function Daily({type, data, selectedDateDaily}) {

    const MONTHS = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',   
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
    ];

    function parseDateFromString(dateString) {
        const [day, date, month, year] = dateString.split(' ');
        return new Date(year, MONTHS.indexOf(month), parseInt(date));
    }

    let itemList = [];

    function createItemList(items) {
        itemList = [];
        items.forEach((item) => {            
            const daySpan = Math.floor((item.endDate - item.startDate) / (1000 * 60 * 60 * 24)) + 1;
            for (let date = item.startDate; date <= item.endDate; date.setDate(date.getDate() + 1)) {
                if(date.toDateString() === parseDateFromString(selectedDateDaily).toDateString()) {
                    itemList.push({
                        name: item.name,
                        hourSpan: (item.totalHours / daySpan)
                    })
                }
            }
        })
    }

    if(type === 'machine') {
        createItemList(data[0].projects);
    } else {
        createItemList(data[0].machines);
    }

    return (
        <>
            <div className="planning__content__header__day">
                <div className="planning__content__header__info__container">
                    <span className='planning__content__header__info__item'>{data[0].name}</span>
                </div>
            </div>
            {
                itemList.map((item, index) => {
                    return (
                        <div key={item + '-' + index} className="planning__content__item">
                            <div className="planning__content__name">
                                <span className="planning__content__name__content">{item.name}</span>
                            </div>
                            <div className="planning__content__text__container">
                                <span className="planning__content__text__container__content">{item.hourSpan + ' uur'}</span>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}