import { jsPDF } from 'jspdf';
// eslint-disable-next-line no-unused-vars
import 'jspdf-autotable';
import { Canvg } from 'canvg';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { ptToMm } from 'App/Util/format';
import 'UI/Assets/Fonts/helvetica-UTF-8-normal';
import 'UI/Assets/Fonts/helvetica-UTF-8-bold';
import 'UI/Assets/Fonts/helvetica-UTF-8-italic';
import 'UI/Assets/Fonts/helvetica-UTF-8-bolditalic';

// @template get the height of the image that you want to use/have used.
// const imageHeight = await new Promise((resolve) => {
//     const image = new Image();
//     image.src = 'the-image-src'; // dataURL, imported asset name, or whatever that works.
//     image.onload = () => {
//         // get the aspect (width to height) ratio of the image
//         const aspectRatio = image.naturalWidth / image.naturalHeight;

//         // get the width of the image based on the pdf max writable width / the max width you want the image to be (e.g. 3 = max-width: 33%)
//         const width = (210 - paddingLeft * 2) / 3;
//         // return the calculated height the image will be + 1 linebreak
//         resolve((width / aspectRatio) + lineHeight);
//     };
//     image.onerror = () => {
//         resolve(0);
//     };
// });

const FONT_SIZE = 10; // pt
const LINE_HEIGHT_FACTOR = 1.193; // 1.15 default
const LINE_HEIGHT = ptToMm(FONT_SIZE) * LINE_HEIGHT_FACTOR;
const [PAGE_WIDTH, PAGE_HEIGHT] = [210, 297];
const PADDING_TOP = 21;
const PADDING_RIGHT = PAGE_WIDTH - 21;
const PADDING_BOTTOM = PAGE_HEIGHT - 21;
const PADDING_LEFT = 21;
const START_Y = PADDING_TOP;
const TEXT_LINE_MAX_WIDTH = PAGE_WIDTH - PADDING_LEFT * 2;

export {
    FONT_SIZE,
    LINE_HEIGHT_FACTOR,
    LINE_HEIGHT,
    PAGE_WIDTH,
    PAGE_HEIGHT,
    PADDING_TOP,
    PADDING_RIGHT,
    PADDING_BOTTOM,
    PADDING_LEFT,
    START_Y,
    TEXT_LINE_MAX_WIDTH
};

// TODO: rewrite for global use (letter pdfs & company messages) + in typescript and with proper syntax.
/**
 * Create a pdf document.
 *
 * @export default
 * @see jsPDF documentation & demo: https://raw.githack.com/MrRio/jsPDF/master/docs/index.html
 * @see jsPDF autotable documentation: https://github.com/simonbengtsson/jsPDF-AutoTable
 * @class PDF
 * @extends {jsPDF}
 */
export default class PDF extends jsPDF {
    // the top left logo
    logo;
    #logoQuality = 512; // higher values is slower but produce higher quality png's
    #logoResolve;
    #logoReject;
    #logoPromise = new Promise((resolve, reject) => {
        this.#logoResolve = resolve;
        this.#logoReject = reject;
    });

    /** the main title of the document. */
    title;

    /** jsPDF-autotable default styling. */
    tableDefaultOptions = {
        theme: 'plain',
        styles: {
            textColor: 'black',
            fontSize: 10
        },
        headStyles: {
            cellPadding: { top: 2.5, bottom: 2.5, left: 1.5, right: 1.5 },
            fillColor: '#E8F4F8',
            fontStyle: 'bold',
            lineColor: 'black',
            lineWidth: { top: 0.2 }
        },
        bodyStyles: {
            fillColor: '#FFF',
            cellPadding: { top: 1.5, bottom: 1.5, left: 1.5, right: 1.5 },
            lineWidth: { bottom: 0.1, top: 0.1 }
        },
        // startY: default 30
        showHead: 'firstPage' // don't show table header when table overlaps to another page
    };

    /** The start Y-axis position of every page. */
    pageStartY = START_Y;

    /** The current Y-axis position. */
    currentY = START_Y;

    /** The background image for the first page. */
    backgroundImageFirstPage = null;
    /** The background image for the subsquent pages. */
    backgroundImageSubsequentPages = null;

    /**
     * Creates an instance of PDF.
     *
     * @param {string} [title='']
     * @param {string} [logo='']
     * @param {string|null} [backgroundImageFirstPage=null]
     * @param {string|null} [backgroundImageSubsequentPages=undefined] `null` to not use an image for subsequent pages. `undefined` to use same background as first page (which can be `null`).
     * @param {jsPDFOptions} [options={}]
     */
    constructor(title = '', logo = '', backgroundImageFirstPage = null, backgroundImageSubsequentPages = undefined, options = {}) {
        // extend jsPDF
        super(options);

        // set default table style
        this.autoTableSetDefaults(this.tableDefaultOptions);

        // set properties
        this.title = (title ?? '').trim();
        this.logo = (logo ?? '').trim();

        // set background first page
        if (typeof backgroundImageSubsequentPages === 'string') {
            this.backgroundImageFirstPage = backgroundImageFirstPage.trim();
        }

        // set background subsequent pages
        if (typeof backgroundImageSubsequentPages === 'string') {
            this.backgroundImageSubsequentPages = backgroundImageSubsequentPages.trim();
        } else if (backgroundImageSubsequentPages === undefined) {
            // use the same image as the first page if the background for subsequent pages is not supplied and not specifically `null`
            this.backgroundImageSubsequentPages = backgroundImageFirstPage;
        } else {
            this.backgroundImageSubsequentPages = null;
        }

        // add the background for the fist page
        this.setBackgroundImage(backgroundImageFirstPage);

        // set the font for the document
        // (was just for company messages)
        this.setFont('helvetica UTF-8', 'bold');
        this.setFontSize(15);

        try {
            // set the document title
            this.#setTitle();

            // set the logo in top left of the document
            this.#setLogo();
        } catch (error) {
            error.message = `PDF(): ${error.message}`;
            toast.error(error.message);

            throw error;
        }
    }

    /**
     * add `linebreaks` for every page + 1 linebreak to go to the line after the amount of `linebreaks`
     *
     * @param {int} linebreaks
     */
    setPageStartLineBreaks = (linebreaks = 0) => {
        this.pageStartY = START_Y + LINE_HEIGHT * (linebreaks + 1);
    };

    setFontStyle = (style = 'normal') => {
        this.setFont('helvetica UTF-8', style.toLowerCase());
    };

    /**
     * Adds the title to top of the first page.
     *
     * @returns {void}
     */
    #setTitle = () => {
        if (!this.title) {
            return;
        } else if (this.title.length > 140 || (this.title.match(/\n/g) ?? []).length > 3) {
            throw new Error('Het document titel is te lang');
        }

        this.text(this.title, 105, 9.4, {
            align: 'center',
            maxWidth: 105
        });
    };

    /**
     * Adds logo to top-left of the first page.
     *
     * @returns {Promise}
     */
    #setLogo = async () => {
        if (!this.logo) {
            // fulfill logo promise, resolve to continue
            this.#logoResolve();
            return;
        }

        if (!/^https?:\/\//.test(this.logo)) {
            this.logo = `${window.location.origin}${this.logo}`;
        } else {
            this.logo = /(^https?:\/\/.*?\/.*?\.[a-zA-Z]{2,4})(?:\?.*?)?$/.exec(this.logo)?.[1] ?? null;
        }

        if (!this.logo) {
            console.warn('PDF(): `logo` is geen correct url');
            // fulfill logo promise, resolve to continue
            this.#logoResolve();
            return;
        }

        try {
            // get format from image url
            const format = this.logo.split('.').pop().toUpperCase();

            // create canvas to paste the image/svg on
            const canvas = document.createElement('canvas');

            // set the resolution of the canvas.
            // must be square
            canvas.width = canvas.height = this.#logoQuality;

            if (format === 'SVG') {
                // create 2d context
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);

                // get image data from url
                // options: https://canvg.js.org/api/interfaces/IOptions
                const v = await Canvg.from(context, this.logo);

                // render the first frame onto the canvas
                v.render();
            } else {
                // create new htmlImageElement
                const img = new Image();

                // set image source to logo url
                img.src = this.logo;

                // important: set the crossOrigin to allow usage of url's from other domains
                img.crossOrigin = 'anonymous';

                // await for image to load
                await img.decode();

                let maxSize = 0;
                let heightOffset = 0;
                let widthOffset = 0;

                // calculate max size and appropriate offset to center image
                if (img.width > img.height) {
                    // wide image
                    maxSize = img.width;
                    heightOffset = (maxSize - img.height) / 2;
                } else if (img.width > img.height) {
                    // tall image
                    maxSize = img.height;
                    widthOffset = (maxSize - img.width) / 2;
                } else {
                    // square image
                    maxSize = img.width;
                    // no offset needed
                }

                // resize canvas is image size is smaller than default.
                if (maxSize < this.#logoQuality) {
                    canvas.width = maxSize;
                    canvas.height = maxSize;
                }

                // create 2d context
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);

                // draw image onto the context
                context.drawImage(img, widthOffset, heightOffset);
            }

            // create png data uri from canvas
            const imgData = canvas.toDataURL('image/png');

            // add logo to top-left
            this.addImage(imgData, 'PNG', 10, 0, 30, 30);

            // fulfill logo promise
            this.#logoResolve();
        } catch (error) {
            // fulfill logo promise, resolve to continue
            this.#logoResolve(error.message);

            toast.error(`PDF(): ${error.message}`);

            console.error('PDF(): ' + error);
        }
    };

    /**
     * Sets the `background` for the current page.
     *
     * @param {string} background
     */
    setBackgroundImage = (background) => {
        if (!background) return;

        this.addImage(background, 'JPEG', 0, 0, PAGE_WIDTH, PAGE_HEIGHT);
    };

    /**
     * todo: add dynamic maxWidth on first page based on the height of the top right box
     *
     * @param {string| string[] | null | undefined} text
     * @param {number} x
     * @param {number} y
     * @param {TextOptionsLight | undefined} [options=undefined]
     * @param {any} [transform=undefined]
     * @memberof PDF
     */
    addText = (text, x, y, options = {}, transform = undefined) => {
        if (text === undefined || text === null) return;

        // set currentY. if `y` is smaller than the pageStartY then use pageStartY
        this.currentY = Math.max(y, this.pageStartY);

        // set maxWidth option if not set
        options.maxWidth = options.maxWidth ?? TEXT_LINE_MAX_WIDTH;

        // split the text into seperate lines
        const textLines = this.splitTextToSize2(text, options.maxWidth);

        // calculate if section needs to go to the next page
        this.addPageIfTextWillOverflow(textLines?.length);

        // add the textLines
        this.text(textLines, x, this.currentY, options, transform);

        // set currentY to the last line
        this.currentY += LINE_HEIGHT * textLines.length;
    };

    /**
     * split text by maxWidth and \n's.
     *
     * this creates an array where every item is automatically a new line.
     *
     * @param {string} text
     * @param {number} maxlen
     */
    splitTextToSize2 = (text, maxlen) => {
        let textLines = [];

        try {
            // type guard
            if (typeof text !== 'string' && !Array.isArray(text)) {
                throw new TypeError('`text` must be string[] | string.');
            }

            // if text is a string we can put it into an array to reduce code
            if (typeof text === 'string') {
                text = [text];
            }

            if (Array.isArray(text)) {
                // check each text line if they are too long
                // then flatten it to get a single array of line strings
                textLines = text.flatMap((line) => this.splitTextToSize(line, maxlen));
            }
        } catch (error) {
            error.message = `PDF(): ${error.message}`;
            toast.error(error.message);

            throw error;
        }

        return textLines;
    };

    /**
     * Add a table to the document. Call with await!
     *
     * @param {any[]} [head=[]] the heads for the table.
     * @param {any[]} [data=[]] the data for the table.
     * @param {Object} [overwriteOptions={}] Table properties to overwrite from the
     * @memberof PDF
     */
    addTable = async (head = [], data = [], overwriteOptions = {}) => {
        // wait for logo promise to fulfill to fix image being displayed on the wrong page.
        await this.#logoPromise.finally(() => {
            try {
                if (isEmpty(head) && isEmpty(overwriteOptions?.head)) {
                    console.warn('PDF(): Een tabel heeft geen headers');
                }

                if (isEmpty(data) && isEmpty(overwriteOptions?.body)) {
                    console.warn('PDF(): Een tabel heeft geen data');
                }

                const options = {
                    head: head,
                    body: data,
                    startY: this?.lastAutoTable?.finalY ? undefined : 31,
                    ...overwriteOptions
                };

                this.autoTable(options);
            } catch (error) {
                toast.error(`PDF(): ${error.message}`);

                console.error('PDF(): ' + error);
            }
        });
    };

    /**
     * Checks if the amount of lines you want to insert are too much for the current rest of the page.
     *
     * Will create a new page and set `this.currentY` to the start if it does.
     *
     * @param {number} [textLinesLength=0]
     */
    addPageIfTextWillOverflow = (textLinesLength = 0) => {
        try {
            // check if the text would overflow a full page
            if (this.pageStartY + LINE_HEIGHT * (textLinesLength + 1) > PADDING_BOTTOM) {
                toast.error('PDF(): Een textgedeelte is te lang voor 1 pagina!');

                throw new RangeError(
                    `unable to add ${textLinesLength} lines. Max: ${Math.floor(
                        (PADDING_BOTTOM - this.pageStartY) / LINE_HEIGHT - 1
                    )}`
                );
            }
        } catch (error) {
            error.message = `PDF(): ${error.message}`;
            toast.error(error.message);

            throw error;
        }

        // checks the currentY + amount of lines are > the bottom of the page
        if (this.currentY + LINE_HEIGHT * (textLinesLength + 1) > PADDING_BOTTOM) {
            // add the page
            this.addPage();
            // set background
            this.setBackgroundImage(this.backgroundImageSubsequentPages);

            // set `currentY` to the start of the page
            this.currentY = this.pageStartY;

            return true;
        }

        return false;
    };

    addNewPageIfOverflown = (offset, limit = 20, startHeight = 15, background = null) => {
        if (offset > this.getPageHeight() - limit) {
            this.addPage();

            if (background || this.backgroundImageSubsequentPages) {
                this.setBackgroundImage(background ?? this.backgroundImageSubsequentPages);
            }
            return startHeight;
        }

        return offset;
    };

    /**
     * Open the created pdf after all async calls are completed.
     * @returns {void}
     */
    openPDF = async () => {
        // wait for logo promise to fulfill
        // once more images are needed then change this to Promise.allSettled() and create a promises array
        await this.#logoPromise.finally(() => {
            // open pdf in new window (filename not supported with 'bloburi')
            window.open(this.output('bloburi'));
        });
    };
}
