import ReactDOMServer from 'react-dom/server';
import Icon from "UI/App/Components/Icon/Icon";

export default function WidgetInformation({ content }: { content?: string|undefined }) {
    return (
        <div>
            <h3>Widget informatie</h3>
            <textarea className={'widgetInformation'} name={'information'} rows={4} defaultValue={(content ?? '')}></textarea>
        </div>
    )
}

export function WidgetInformationDisplay(widgetData: any) {

    if (!widgetData.widgetData?.data.value?.information || widgetData.widgetData?.data?.value?.information === '') {
        return null;
    }

    return (
        <span className={'widget__information-popup'} data-information-content={widgetData.widgetData?.data?.value?.information}>
            <Icon name={'information'} />
        </span>
    )
}

export function getWidgetInformationDisplay(widgetData: any) {
    return ReactDOMServer.renderToString(<WidgetInformationDisplay widgetData={widgetData} />);
}