import Popup, {closePopup} from "UI/App/Components/Popup/Popup";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import {useContext, useEffect, useState} from "react";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";
import {stringify} from "qs";
import Select from "react-select";

export default function OrderAddAdditionalMaterial({
          order,
          setOrder
      }) {
    const { authAxios } = useContext(FetchContext);
    const [materialOptions,setMaterialOptions] = useState([]);
    const [chosenMaterial, setChosenMaterial] = useState(null);
    const [chosenQuantity, setChosenQuantity] = useState(null);
    const [unit,setUnit] = useState('');

    useEffect(() => {
        load();
    }, []);

    async function load(){
        await authAxios.get(`/logistics/materials/all`)
            .then(({data}) => {
                let options = [];

                for (const material of data) {

                    options.push({
                        label: `${material.calculationDescription} ${(material?.internalSKU) ? `(${material?.internalSKU})` : ""}`,
                        value: material.id
                    })
                }

                setMaterialOptions(options);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Voorraad kon niet worden opgehaald');
                return [];
            });
    }

    async function handleSubmit (e) {
        e.preventDefault();

        let data = {...order}
        data.additionalMaterials.push({
            material: chosenMaterial.value,
            quantity: await new MaterialUnitMutator().quantityMerchant(chosenQuantity, chosenMaterial.value, false)
        });

        const query = stringify({
            populate: {
                additionalMaterials: {
                    populate: {
                        material: true
                    }
                },
                company: {
                    populate: ['managingCompany']
                },
                contactPerson: true,
                calculation: true,
                quote: true,
                stickers: {
                    sort: ['id:desc'],
                    populate: {
                        file: {
                            fields: ['id', 'name', 'mime']
                        }
                    }
                },
                quoteConfirmation: {
                    populate: {
                        quote: {
                            populate: {
                                contactPerson: true,
                                company: {
                                    populate: ['telephone', 'managingCompany']
                                },
                                copies: {
                                    fields: ['id', 'number'],
                                },
                            }
                        },
                        calculation: {
                            populate: {
                                stockQuotes: {
                                    fields: ['id'],
                                    populate: {
                                        stockPurchaseSlip: {
                                            fields: ['id']
                                        },
                                    }
                                },
                                copies: {
                                    fields: ['id', 'number'],
                                },
                                purchaseQuotes: {
                                    fields: ['id', 'number'],
                                }
                            }
                        }
                    }
                },
                packingSlips: {
                    fields: ['id', 'number'],
                    sort: ['id:desc']
                },
                jobTickets: {
                    fields: ['id', 'name', 'status', 'xml'],
                },
                project: {
                    fields: ['id']
                },
                planning: {
                    fields: ['id'],
                }
            }
        });

        authAxios.put(`/orders/${order.id}?${query}`, {
            data
        }).then((data) => {
            setOrder(data.data.data)
            closePopup('OrderAddAdditionalMaterial');
        }).catch((exception) => {
            toast.error('Er ging iets mis met het bewerken van een order.');
            console.error(exception);
        });
    }

    useEffect(() => {
        if(chosenMaterial !== null){
            getUnit(chosenMaterial.value);
        }
    },[chosenMaterial])

    async function getUnit(material){
        setUnit(await (new MaterialUnitMutator()).obtainUnit(material));
    }

    return (
        <Popup popupID={'OrderAddAdditionalMaterial'} title={'Materiaal toevoegen'}>
            <form onSubmit={handleSubmit}>
                <div className='input-group'>
                    <label htmlFor='material'>Materiaal</label>
                    <Select
                        id='material'
                        name='material'
                        value={chosenMaterial}
                        onChange={(material) => setChosenMaterial(material)}
                        options={materialOptions}
                        isLoading={materialOptions?.length === 0}
                    />
                </div>
                <div className='input-group'>
                    <label htmlFor='material'>Aantal {unit}</label>
                    <input
                        value={(chosenQuantity ?? '')}
                        onChange={(e) => {setChosenQuantity(e.target.value)}}
                    />
                </div>
                <button type="submit">Toevoegen</button>
            </form>
        </Popup>
)
}
