import { useContext, useEffect, useState } from 'react';

import './../Quotes/quote.scss';
import 'react-quill/dist/quill.snow.css';
import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_page_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";
import Button from "UI/App/Components/Button/Button";
import ConfirmationPDFGenerator from "./ConfirmationPDFGenerator";
import Grid from "UI/App/Components/Grid/Grid";
import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import { AuthContext } from "App/Strapi/AuthProvider";
import { formatName, mmToPx } from "App/Util/format";
import Briefpapier_HJMG from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg";
import Briefpapier_HJMG_page_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG_2.jpg";
import { getNumLines } from 'App/Util/getVisibleTextWidth';


const _PRICE_REGEX = /^[$€][\s ]?[\d.,]+$/;

export default function ConfirmationPdfEditor({ activeStep, contactPerson, language, shippingCost, setShippingCost, shippingAdres, setShippingAdres, shipment, setShipment, deliveryTime, setDeliveryTime, price, setPrice, date, setDate, formattedRows, setFormattedRows, rowData, setRowData, company, quote, signature, owner = 'pretore', placeOfOrigin, setPlaceOfOrigin, introLine, setIntroLine, footerData, setFooterData, footerData2, setFooterData2, footerText, setFooterText, backgroundOne, backgroundTwo, setBackgroundOne, setBackgroundTwo, chosenAmount }) {
    const { authState } = useContext(AuthContext);

    useEffect(() => {
        // Set backgrounds
        if (owner === 'pretore') {
            setBackgroundOne(Briefpapier_PRETORE);
            setBackgroundTwo(Briefpapier_PRETORE_page_2);
        } else {
            setBackgroundOne(Briefpapier_HJMG);
            setBackgroundTwo(Briefpapier_HJMG_page_2);
        }
    }, [owner]);

    // const [date, setDate] = useState(null);
    let [zoom, setZoom] = useState(0.5);

    useEffect(() => {
        handleResize()
    }, [activeStep])

    function handleResize() {
        let wrapperWidth = document.getElementById('pdf-mockup__wrapper')?.offsetWidth;
        let mockupWidth = document.getElementById('pdf-mockup__page')?.offsetWidth;
        let zoom = Math.min(1 / mockupWidth * wrapperWidth, 1);

        if (!isNaN(zoom) && zoom !== undefined && zoom !== null) {
            setZoom(zoom)
        }
    }

    // Add new footer row
    async function addFooterRow(currentTarget, data, setter) {
        const key = parseInt(currentTarget.getAttribute('rowkey'));

        const filteredFooterData = data;

        filteredFooterData.splice(key, 0, { label: '', content: '' });

        setter([...filteredFooterData])

        const reformattedRows = await new ConfirmationPDFGenerator({ page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_page_2 }, formattedRows, null, company, contactPerson, owner, shippingCost, shippingAdres, shipment, deliveryTime, price, true, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText, chosenAmount?.toString()).formatForPdf(formattedRows, true);


        setFormattedRows([...reformattedRows])
    }

    // Remove row from footer
    async function removeFooterRow(currentTarget, data, setter) {
        const key = parseInt(currentTarget.getAttribute('rowkey'));
        const filteredFooterData = data;

        filteredFooterData.splice(key, 1)

        setter([...filteredFooterData])
        const reformattedRows = await new ConfirmationPDFGenerator({ page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_page_2 }, formattedRows, null, company, contactPerson, owner, shippingCost, shippingAdres, shipment, deliveryTime, price, true, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText, chosenAmount?.toString()).formatForPdf(formattedRows, true);

        setFormattedRows([...reformattedRows])
    }

    async function addRow(e) {
        const key = parseInt(e.currentTarget.getAttribute('rowkey'));
        const page = parseInt(e.currentTarget.getAttribute('pageKey'));

        const filteredEntries = formattedRows;
        filteredEntries[page].splice(key + 1, 0, { label: '', content: '' });

        const reformattedRows = await new ConfirmationPDFGenerator({ page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_page_2 }, formattedRows, null, company, contactPerson, owner, shippingCost, shippingAdres, shipment, deliveryTime, price, true, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText, chosenAmount?.toString()).formatForPdf(filteredEntries, true);

        setFormattedRows([...reformattedRows])
    }


    async function removeRow(e) {
        // const key = e.target.parentElement.getAttribute('rowkey');
        // const filteredEntries = rowData;
        // delete filteredEntries[key];

        // setRowData(filteredEntries.filter((elem) => elem));

        const key = e.currentTarget.getAttribute('rowkey');
        const page = e.currentTarget.getAttribute('pageKey');
        const filteredEntries = formattedRows;

        filteredEntries[page].splice(key, 1)

        const reformattedRows = await new ConfirmationPDFGenerator({ page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_page_2 }, formattedRows, null, company, contactPerson, owner, shippingCost, shippingAdres, shipment, deliveryTime, price, true, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText, chosenAmount?.toString()).formatForPdf(filteredEntries, true);

        setFormattedRows([...reformattedRows])
    }

    const updatePriceData = (e) => {
        // Gather page and key for rows
        const key = parseInt(e.target.getAttribute('data-material-key'));
        const page = parseInt(e.target.getAttribute('data-material-page'));
        const filteredEntries = formattedRows;

        // Set value to storage at page/key
        filteredEntries[page][key][e.target.name] = e.target.value.replaceAll(' ', '');
        filteredEntries[page][key]['type'] = 'price'

        setFormattedRows([...filteredEntries])
    }

    const updateFooterData = (target, data, setter) => {
        const key = parseInt(target.getAttribute('data-material-key'));

        const filteredFooterData = data;

        filteredFooterData[key][target.name] = target.value

        setter([...filteredFooterData])
    }

    const updateRowData = (e) => {
        const key = parseInt(e.target.getAttribute('data-material-key'));
        const page = parseInt(e.target.getAttribute('data-material-page'));
        const filteredEntries = formattedRows;

        // Set value to storage at page/key
        filteredEntries[page][key][e.target.name] = e.target.value

        setFormattedRows([...filteredEntries])
    }

    useEffect(() => {
        if (signature !== null) {
            reformatData();
        }
    }, [rowData, signature])

    async function reformatData() {
        const formattedData = await new ConfirmationPDFGenerator({ page1: Briefpapier_PRETORE, page2: Briefpapier_PRETORE_page_2 }, formattedRows, null, company, contactPerson, owner, shippingCost, shippingAdres, shipment, deliveryTime, price, true, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText, chosenAmount?.toString()).formatForPdf(rowData);
        setFormattedRows(formattedData);
    }

    const getContent = () => {
        if (quote === null) {
            return <></>
        }

        let company = quote.company;
        let contactPerson = quote.contactPerson;

        let content = []

        let topSpacing = <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </>

        let header = <>
            {topSpacing}

            <div className='pdf-mockup__row'>
                <strong>{language === 'NL' ? 'BEVESTIGING' : 'CONFIRMATION'}</strong>
            </div>

            <br />
            <br />

            <div className='pdf-mockup__row'>
                <Grid columns='1' gap='0'>
                    <IF condition={company !== undefined}>
                        <IF condition={company?.name}>
                            <span>{(company?.name ?? '').trim()}</span>
                        </IF>

                        <IF condition={contactPerson !== null}>
                            <span>
                                {formatName(contactPerson?.firstName, contactPerson?.prefixToSurname, contactPerson?.surname)}
                            </span>
                        </IF>

                        <IF condition={company?.address?.addressLine1}>
                            <span>{company?.address?.addressLine1}</span>
                        </IF>

                        <IF condition={company?.address?.addressLine2}>
                            <span>{company?.address?.addressLine2}</span>
                        </IF>

                        <IF condition={company?.address?.addressLine3}>
                            <span>{company?.address?.addressLine3}</span>
                        </IF>

                        <IF condition={company?.address?.postalCode && company?.address?.city}>
                            <span>
                                {company?.address?.postalCode.toUpperCase()}
                                {/* double space is in original document */}
                                {' '}
                                {company?.address?.city.toUpperCase()}
                            </span>
                        </IF>

                        <IF condition={company?.address?.country}>
                            <span>{company?.address?.country}</span>
                        </IF>
                    </IF>
                </Grid>
            </div>

            <br />
            <br />

            <div className='pdf-mockup__row'>
                <span>
                    <input
                        value={placeOfOrigin}
                        onChange={(e) => {
                            setPlaceOfOrigin(e.target.value)
                        }}
                        style={{
                            marginLeft: 0
                        }}
                    />,
                </span>
                <span>
                    <input
                        type='date'
                        min={new Date().toLocaleDateString('fr-ca')}
                        value={date}
                        onChange={({ target }) => {
                            setDate(target.value);
                        }}
                        style={{
                            marginLeft: '10px !important',
                            textAlign: 'center',
                            borderBottom: 'none'
                        }}
                    />
                </span>
            </div>

            <br />
            <br />

            <div className='pdf-mockup__row'>
                {(contactPerson !== null && contactPerson !== undefined) ?
                    <span>
                        {language === 'NL' ? 'Beste' : 'Dear'} {contactPerson.firstName},
                        <br />
                        <br />
                    </span>
                    : <></>}
            </div>


            <div className='pdf-mockup__row'>
                <textarea
                    value={introLine}
                    onChange={({ target }) => {
                        setIntroLine(target.value)
                    }}
                    style={{
                        background: 'none',
                        border: 'none',
                        borderBottom: 'dashed lightgrey 1px'
                    }}
                    rows={getNumLines(introLine, '10pt helvetica', mmToPx(210 - 21 - 21))}
                ></textarea>
            </div>
        </>

        let footerClosingText = <>
            <br />
            <textarea
                value={footerText}
                onChange={({ target }) => {
                    setFooterText(target.value)
                }}
                rows={getNumLines(footerText, '10pt helvetica', mmToPx(210 - 21 - 21))}
                style={{
                    textAlign: 'left',
                    border: 'none',
                    borderBottom: '1px dashed lightgrey',
                    width: '100%',
                    background: 'none',
                    color: 'black',
                    overflowY: 'auto',
                    paddingLeft: '2px',
                    borderRadius: 0
                }} />
        </>

        let footer = <>
            <br />
            <Grid
                gap='0'
                customColTemplate='140mm 5mm 1fr'
                style={{ alignItems: 'start', margin: '0' }}
                key={`Price`}
                columns={4}
            >
                <span>
                    {language === 'NL' ? `Prijs voor ${chosenAmount} stuks` : `Price for ${chosenAmount} pieces`}
                </span>
                <span>
                    €
                </span>
                <span>
                    <input name="label" style={{ textAlign: 'right' }} value={price} className="material__item__input" data-material-key={'price'} onChange={(e) => {
                        setPrice(e.target.value)
                    }} onBlur={(e) => {
                        setPrice(parseFloat(e.target.value).toFixed(2))
                    }} />
                </span>
            </Grid>
            <Grid
                gap='0'
                customColTemplate='140mm 5mm 1fr'
                style={{ alignItems: 'start', margin: '0' }}
                key={`options`}
                columns={4}
            >
                <span>
                    {language === 'NL' ? 'Verzendkosten excl BTW:' : 'Shipping excl VAT:'}
                </span>
                <span>
                    €
                </span>
                <span>
                    <input name="label" style={{ textAlign: 'right' }} className="material__item__input" value={shippingCost} data-material-key={'shippingCost'} onChange={(e) => {
                        setShippingCost(e.target.value)
                    }} onBlur={(e) => {
                        setShippingCost(parseFloat(e.target.value).toFixed(2))
                    }} />
                </span>
            </Grid>
            <br />
            <b>{language === 'NL' ? 'Voorwaarden' : 'Terms'}</b>
            <Grid
                gap='0'
                customColTemplate='45mm 2mm 1fr'
                style={{ alignItems: 'start', margin: '0' }}
                key={`DeliveryTime`}
                columns={4}
            >
                <span>
                    {language === 'NL' ? 'Levertijd' : 'Delivery time'}
                </span>
                <span>
                    :
                </span>
                <span>
                    <input name="label" value={deliveryTime} className="material__item__input" data-material-key={'deliveryTime'} onChange={(e) => {
                        setDeliveryTime(e.target.value)
                    }} />
                </span>
            </Grid>
            {(footerData ?? []).map((data, key) => {
                // key error: no idea how to fix here
                return <>
                    <Grid
                        gap='0'
                        customColTemplate='45mm 2mm 1fr 9mm'
                        style={{ alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                        key={`footer-data-${key}`}
                    >
                        <span className='pdf-mockup__row'>
                            <input
                                value={data.label}
                                style={{ margin: 0 }}
                                name="label"
                                data-material-key={key}
                                onChange={({ target }) => {
                                    updateFooterData(target, footerData, setFooterData)
                                }}
                            />
                        </span>
                        <span>
                            :
                        </span>
                        <span className='pdf-mockup__row'>
                            <input
                                name="content"
                                style={{ margin: 0 }}
                                value={data.content}
                                data-material-key={key}
                                onChange={({ target }) => {
                                    updateFooterData(target, footerData, setFooterData)
                                }}
                            />
                        </span>
                        <span>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                rowkey={key}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={({ currentTarget }) => {
                                    if (signature !== null) removeFooterRow(currentTarget, footerData, setFooterData)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='times' />
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                rowkey={key}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '1mm'
                                }}
                                onClick={({ currentTarget }) => {
                                    if (signature !== null) addFooterRow(currentTarget, footerData, setFooterData)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle' />
                            </Button>
                        </span>
                    </Grid>
                </>
            })}
            <br />
            <b>Transport</b>
            <Grid
                gap='0'
                customColTemplate='45mm 2mm 1fr'
                style={{ alignItems: 'start', margin: '0' }}
                key={`Shipping`}
                columns={4}
            >
                <span>
                    {language === 'NL' ? 'Verzending' : 'Shipping'}
                </span>
                <span>
                    :
                </span>
                <span>
                    <input name="label" value={shipment} className="material__item__input" data-material-key={'shipment'} onChange={(e) => {
                        setShipment(e.target.value)
                    }} />
                </span>
            </Grid>
            <Grid
                gap='0'
                customColTemplate='45mm 2mm 1fr'
                style={{ alignItems: 'start', margin: '0' }}
                key={`Address`}
                columns={4}
            >
                <span>
                    {language === 'NL' ? 'Afleveradres' : 'Address'}
                </span>
                <span>
                    :
                </span>
                <span>
                    <input name="label" className="material__item__input" value={shippingAdres} data-material-key={'shippingAdres'} onChange={(e) => {
                        setShippingAdres(e.target.value)
                    }} />
                </span>
            </Grid>
            {(footerData2 ?? []).map((data, key) => {
                // key error: no idea how to fix here
                return <>
                    <Grid
                        gap='0'
                        customColTemplate='45mm 2mm 1fr 9mm'
                        style={{ alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                        key={`footer-data-2-${key}`}
                    >
                        <span className='pdf-mockup__row'>
                            <input
                                value={data.label}
                                style={{ margin: 0 }}
                                name="label"
                                data-material-key={key}
                                onChange={({ target }) => {
                                    updateFooterData(target, footerData2, setFooterData2)
                                }}
                            />
                        </span>
                        <span>
                            :
                        </span>
                        <span className='pdf-mockup__row'>
                            <input
                                name="content"
                                style={{ margin: 0 }}
                                value={data.content}
                                data-material-key={key}
                                onChange={({ target }) => {
                                    updateFooterData(target, footerData2, setFooterData2)
                                }}
                            />
                        </span>
                        <span>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                rowkey={key}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={({ currentTarget }) => {
                                    if (signature !== null) removeFooterRow(currentTarget, footerData2, setFooterData2)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='times' />
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                rowkey={key}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '1mm'
                                }}
                                onClick={({ currentTarget }) => {
                                    if (signature !== null) addFooterRow(currentTarget, footerData2, setFooterData2)
                                }}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle' />
                            </Button>
                        </span>
                    </Grid>
                </>
            })}

            {footerClosingText}
            < br />
            <br />
            {language === 'NL' ? 'Met vriendelijke groet,' : 'Kind regards,'}

            {owner === "pretore" && (
                <div className='pdf-mockup__row'>
                    Pretore B.V.
                </div>
            )}
            {owner === "hjmg" && (
                <div className='pdf-mockup__row'>
                    HJ Media Groep B.V.
                </div>
            )}

            <br />


            {
                signature && (<>
                    <br />
                    <div className='pdf-mockup__row pdf__signature'>
                        <img src={signature} alt='handtekening' />
                    </div>
                </>)
            }
            <br />

            <div className='pdf-mockup__row'>
                <span>{authState?.user?.username}</span>
            </div>
        </>

        // let pageKey = 0;

        for (let page = 0; page < (formattedRows?.length !== 0 ? formattedRows?.length : 1); page++) {
            content.push(
                <div
                    className='pdf-mockup__page'
                    id='pdf-mockup__page'
                    style={{
                        backgroundImage: `url('${page === 0 ? backgroundOne : backgroundTwo}')`,
                        zoom: zoom,
                        MozTransform: `scale(${zoom})`
                    }}
                    key={`page-${page}`}
                >
                    {page === 0 ? header : topSpacing} <br />
                    {formattedRows.length !== 0 && (
                        formattedRows[page]
                            .filter((data) => data)
                            .map((data, i) => {
                                return (
                                    <Grid
                                        gap='0'
                                        customColTemplate='45mm 2mm 1fr 9mm'
                                        style={{ alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                                        key={`material-${i}`}
                                        columns={4}
                                    >
                                        <span>
                                            <input
                                                name='label'
                                                className='material__item__input'
                                                data-material-key={i}
                                                data-material-page={page}
                                                value={data.label}
                                                onChange={(e) => {
                                                    updateRowData(e);
                                                }}
                                            />
                                        </span>
                                        <span>:</span>
                                        <span>
                                            <input
                                                name='content'
                                                style={{ textAlign: data.type === 'price' ? 'right' : 'left' }}
                                                className='material__item__input'
                                                data-material-key={i}
                                                data-material-page={page}
                                                value={data.content}
                                                onChange={(e) => {
                                                    _PRICE_REGEX.test(e.target.value) ? updatePriceData(e) : updateRowData(e);
                                                }}
                                            />
                                        </span>
                                        <span>
                                            <Button
                                                className='btn--transparent btn--round btn--icon-red'
                                                rowkey={i}
                                                pagekey={page}
                                                style={{
                                                    padding: 0,
                                                    backgroundColor: 'var(--white)'
                                                }}
                                                onClick={(e) => {
                                                    if (signature !== null) {
                                                        removeRow(e);
                                                    }
                                                }}
                                                title='Regel toevoegen'
                                            >
                                                <Icon name='times' />
                                            </Button>
                                            <Button
                                                className='btn--transparent btn--round btn--icon-green'
                                                rowkey={i}
                                                pagekey={page}
                                                style={{
                                                    padding: 0,
                                                    backgroundColor: 'var(--white)',
                                                    marginLeft: '1mm'
                                                }}
                                                onClick={(e) => {
                                                    if (signature !== null) {
                                                        addRow(e);
                                                    }
                                                }}
                                                title='Regel toevoegen'
                                            >
                                                <Icon name='plus-circle' />
                                            </Button>
                                        </span>
                                    </Grid>
                                );
                            })
                    )}
                    <br />
                    {page + 1 === formattedRows?.length && footer}
                </div>
            );
        }

        return content;
    }

    return (
        <div
            className='pdf-mockup__wrapper'
            data-pdf-for='purchase-quote'
            id="pdf-mockup__wrapper"
            key={'pdf-mockup-preview'}
        >
            {getContent()}
        </div>
    );
}
