import {ConfigContext} from 'App/Strapi/ConfigContext';
import {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthContext} from '../../../../App/Strapi/AuthProvider';
import Icon from "UI/App/Components/Icon/Icon";
import Title from "UI/App/Components/Title/Title";

import './login.scss';
import IF from "UI/App/Components/Conditional/IF";

export default function Login() {
	// use the auth context to check if the user is logged in
	const authContext = useContext(AuthContext);
	const configContext = useContext(ConfigContext);

	// redirect to the dashboard on login
	if (authContext.isAuthenticated()) {
		// make it clear this is NOT a terminal user
		localStorage.setItem("isTerminalUser", "false");

		return (
			<Navigate replace to="/" />
		)
	}

	return (
		<div className='login_background'>
			<div className='login-container'>
				<div className='login login__left'>
					<div className='login__content'>
						<Title type="h1" className="is-white">Welkom terug</Title>
						Log in om door te gaan
					</div>
				</div>
				<div className='login login__right'>
					<div className='login__content'>
						<img className='login__logo' alt={`Logo ${configContext?.appConfig?.organisation}`} src={configContext.appConfig.logo} />
						<Title type="h2" className="">Inloggen</Title>
						<IF condition={authContext.logoutMessage}>
							<span className={'login__message'}>{authContext.logoutMessage}</span>
						</IF>
						<a className='btn login__btn' href={process.env.REACT_APP_API_URL + '/connect/microsoft'}>
							<Icon name='microsoft' className='login__btn__icon' />
							Inloggen met Microsoft
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
