import {formatStringCurrency} from "App/Util/format";

/**
 * Formats a data array for a PDF into a 2d array containing the rows for each page
 */
export function formatDataForPDF({
    company = null,
    contactPerson = null,
    data = [],
    debug = false,
    footerRowCount = 7,
    headerRowCount = 11,
    isDataPreFormatted = false,
    language = 'nl',
    maxRowCount = 48,
    signature = undefined,
    preserveData = false,
} : {
    company: Record<string, any>|null;
    contactPerson: Record<string, any>|null;
    data: Record<string,any>[];
    debug: boolean;
    footerRowCount: number;
    headerRowCount: number;
    isDataPreFormatted: boolean;
    language?: string,
    maxRowCount: number;
    signature?: string,
    preserveData?: boolean,
}) {
    const addressRows = [
        company?.name,
        contactPerson ? true : false,
        company?.address?.addressLine1,
        company?.address?.addressLine2,
        company?.address?.addressLine3,
        company?.address?.postalCode + ' ' + company?.address?.city,
        company?.address?.country
    ].filter(line => Boolean(line)).length;
    // Add the address rows to the header rows
    const totalHeaderRows = addressRows + headerRowCount;
    // Add the signature rows to the footer rows
    const totalFooterRows = signature ? footerRowCount + 4 : footerRowCount;
    // The total number of rows
    let rowCount = 0;
    // An array containing the indexes where the data should be split
    let splitArrayFromIndexes = [];
    let formatted: Record<string|number, any> = {};

    if (!isDataPreFormatted) {
        // Loop through the data to format it for the PDF
        for (const row of data) {
            if (!formatted[rowCount]) {
                formatted[rowCount] = {
                    ...(preserveData ? row : {}),
                    label: '',
                    content: ''
                }
            }
            // Check for price data (Quote specific)
            if (row.price !== undefined || row.HJMGPrice !== undefined) {

                // removing leading line if passed
                if (row.noLeadingLine === undefined) {
                    // Add empty row as spacer
                    formatted[rowCount] = {
                        ...(preserveData ? row : {}),
                        label: '',
                        content: ''
                    }
                    rowCount++;
                }
                // If calculation for Pretore or HJMG
                if (row.price) {
                    // Loop through the amounts
                    for (const amount of row.amounts) {
                        // Add a new row for each amount
                        let label = `${language === 'nl' ? 'Prijs voor' : 'Price for'} ${amount ?? 0} ${language === 'nl' ? 'stuks' : 'Copies'}`
                        formatted[rowCount] = {
                            type: 'price',
                            label: row.altLabel ?? label,
                            content: formatStringCurrency(row.summary[amount].quote),
                            amount: amount,
                            calculationId: row.calculationId
                        }
                        rowCount++;
                    }
                } else {
                    let label = `${language === 'nl' ? 'Prijs' : 'Price'}`
                    formatted[rowCount] = {
                        type: 'HJMGPrice',
                        label: row.altLabel ?? label,
                        content: formatStringCurrency(row.summary),
                        calculationId: row.calculationId
                    }
                    rowCount++;
                }

                // Continue to the next row
                continue;
            }

            // Set the label for the row
            if (row.label !== '') {
                formatted[rowCount].label = row.label
            }

            // IF the content contains a newline, split it
            if (/\n/.test(row.content)) {
                // Add each line as a new row
                for (const line of (row.content.split(/\r?\n/))) {
                    if (!formatted[rowCount]) {
                        formatted[rowCount] = {
                            ...(preserveData ? row : {}),
                            label: '',
                            content: '',
                        }
                    }
                    formatted[rowCount].content = line
                    rowCount++;
                }
            }
            // IF the content doesn't have a newline, just split it
            // TODO: split the content if it's too long
            else {
                formatted[rowCount].content = row.content
                rowCount++;
            }
        }
    } else {
        formatted = data
    }

    // A one dimensional array containing all rows
    const canonicalRows: Record<string, any>[] = [];
    if (isDataPreFormatted) {
        for (const page of Object.values(formatted)) {
            for (const row of page) {
                canonicalRows.push(row);
            }
        }
    } else {
        for (const row of Object.values(formatted)) {
            canonicalRows.push(row);
        }
    }
    // A 2d array containing all rows split by page
    let returnData = [];
    // The current row we're on
    // Default it to the number of rows in the header because they take up space and are not counted in the canonicalRows array
    let currentRow = totalHeaderRows;
    // loop through the canonicalRows array
    for (const rowKey in canonicalRows) {
        const row = canonicalRows[rowKey];
        const rowHeight = !row.rows ? 1 : Number(row.rows);
        // If the row is bigger than the maxRowsOnPage, split it
        if (currentRow + rowHeight > maxRowCount) {
            splitArrayFromIndexes.push(Number(rowKey));
            // reset the currentRow
            currentRow = 0;
        }
        currentRow += rowHeight;
    }
    // Add the last page if the footer does not fit on the last page
    if (currentRow + totalFooterRows > maxRowCount) {
        splitArrayFromIndexes.push(canonicalRows.length);
    }
    let lastIndex = undefined;
    for (const splitIndex of splitArrayFromIndexes.reverse()) {
        returnData.push(canonicalRows.slice(splitIndex, lastIndex));
        lastIndex = splitIndex;
    }
    // Add the first page
    returnData.push(canonicalRows.slice(0, lastIndex));
    if (debug) {
        console.log({
            input: {
                data,
                company,
                contactPerson,
                isDataPreFormatted,
                signature,
                headerRowCount,
                footerRowCount,
                maxRowCount,
            },
            output: {
                formatted,
                returnData,
                splitArrayFromIndexes,
                currentRow,
                footerRowCount,
                maxRowCount,
                addressRows,
            }
        })
    }
    return returnData.reverse();
}
