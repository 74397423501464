import Block from "UI/App/Components/Block/Block"
import Table from "UI/App/Components/Table/Table"
import { useNavigate } from "react-router-dom";
import Icon from "UI/App/Components/Icon/Icon";
import Button from "UI/App/Components/Button/Button";

export default function CompanyCalculations({ calculations, clickFunction, icon }) {
	const navigate = useNavigate();
	const tableData = calculations.sort((a, b) => b.id - a.id).map((calc, i) => {
		return {
			data: [
				calc.number,
				calc.projectDescription,
				calc.status,
				<button
					className='btn btn--round btn--primary'
					onClick={(e) => {
						e.stopPropagation();
						navigate(`/calculations/${calc.id}/view`)
					}}
				>
					<Icon name='eye' />
				</button>
			]
		}
	});

	return (
		<Block name='calculations' title='Calculaties' headerRightSideChildren={<Button onClick={(e) => clickFunction(e)} className='btn btn--round btn--black'><Icon name={icon} /></Button>}>

			<Table
				headers={
					[
						{
							value: 'Nummer',
							attributes: {
								style: {
									background: "white",
									color: "black",
									margin: '0px',
								}
							}
						},
						{
							value: 'Omschrijving',
							attributes: {
								style: {
									background: "white",
									color: "black",
									margin: '0px'
								}
							}
						},
						{
							value: 'Status',
							attributes: {
								style: {
									background: "white",
									color: "black",
									margin: '0px'
								}
							}
						},
						{
							value: ' ',
							attributes: {
								style: {
									background: "white",
									color: "black",
									margin: '0px'
								}
							}
						},
					]

				}
				data={tableData}
				structure={{
					1500: ['115px', '100%', '120px', '65px'],
					1800: ['130px', '100%', '150px', '65px'],
					default: ['150px', '100%', '175px', '65px']
				}}
				columnOverflowExceptions={[3]}
				border='row'
				scroll
				hover
			/>
		</Block>
	)
}
