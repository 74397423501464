import WidgetCollection from "./WidgetCollection";

export default class WidgetFormRenderer {

    public debug: boolean = false;

    /**
     * Renders preview form from widgets
     * @param className<string>
     */
    public renderPreviewForm(className: string) {
        let widgetPreviewHtml = [];
        const widgetElements = document.getElementsByClassName(className);
        for (const widget of widgetElements) {
            const widgetData = JSON.parse(widget.getAttribute('widgetdata'));
            widgetPreviewHtml.push(new WidgetCollection("previewForm", null, null, widgetData).getWidget(widgetData.type));
        }

        return widgetPreviewHtml;
    }

    /**
     * Renders the calculation form
     * @param widgets
     */
    public renderForm(widgets: Array<any>, debug = false, prefix:string|null,parentCLass:string|null,showVisibilityCheckbox:boolean = false) {

        let widgetPreviewHtml = [];
        for (const widget of widgets.sort((a, b) => a.data.position - b.data.position)) {
            if(prefix !== null){
                widget.prefix = prefix + '-'
            }
            if(parentCLass !== null){
                widget.parentId = parentCLass;
            }
            widgetPreviewHtml.push(new WidgetCollection("calculationForm", null, null, widget, debug,undefined,showVisibilityCheckbox).getWidget(widget.data.type));
        }

        return widgetPreviewHtml;
    }

    /**
     * Renders formula
     * @param className
     */
    public renderFormula(widgets: Array<any>,className: string): Array<any> {
        let formulaArray: [] = [];

        for (const widget of widgets.sort((a, b) => a.data.position - b.data.position)) {
            let formulaString = `<span>[${widget.data.type}]</span> <span>${widget.prefix}${widget.id}:</span> <span>${widget.data.title} |</span><span>`
            formulaString += new WidgetCollection("formulaString", null, null, widget.data).getWidget(widget.data.type);
            formulaString += ' = </span><span id="result_'  + widget.prefix + widget.id + '">{RESULT}</span>'
            formulaArray.push(formulaString);
        }

        return formulaArray;
    }
}
