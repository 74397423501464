import Block from "UI/App/Components/Block/Block";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import Icon from "UI/App/Components/Icon/Icon";
import {Spinner} from "UI/App/Components/Spinner";
import {toast} from "react-toastify";

export default function CreateUpdateChecklist({ type }) {
	const {authAxios} = useContext(FetchContext);

	// Params to get the id from the url
	const params = useParams();
	const [checklist, setChecklist] = useState(null);

	useEffect(() => {
		if (type === 'update' && params.checklistId !== undefined) {
			authAxios.get('/calculations/checklists/' + params.checklistId, {
				params: {
					populate: 'company'
				}
			}).then(({data: {data, meta}}) => {
				setChecklist(data);
			});
		}
	}, [type, params.checklistId, authAxios]);

	return (
		<Block name={"checklist"} title={type === 'update' ? "Checklist bijwerken" : "Checklist aanmaken"}>
			{type === 'create' && <CreateChecklist />}
			{type === 'update' && <UpdateChecklist selectedChecklist={checklist} />}
		</Block>
	)
}

function CreateChecklist() {
	const {authAxios} = useContext(FetchContext);

	// The options for the checklist templates
	const [checklistTemplates, setChecklistTemplates] = useState([]);
	// The selected checklist template
	const [selectedChecklistTemplate, setSelectedChecklistTemplate] = useState(null);

	// Get checklist templates on initial render
	useEffect(() => {
		authAxios.get('/calculations/checklists/templates/all').then(({data}) => {
			setChecklistTemplates(data.map(template => ({
				value: template.id,
				label: template.name,
				inputs: template.inputs,
				name: template.name
			})));
		});
	}, [authAxios]);

	// Render the template select if type is create meaning no template is selected
	if (selectedChecklistTemplate === null) {
		return (
			<>
				<label>Template</label>
				<Select options={checklistTemplates} id={'checklist--template'} onChange={(newValue) => setSelectedChecklistTemplate(newValue)} />
			</>
		)
	}

	// Render the update checklist component if a template is selected
	// Pass the selected checklist template to the component
	// This way the component can render the inputs of the template
	// We can simply pass the checklist template as checklist since the only difference
	// between the two is that the template doesn't have values for the inputs
	return <UpdateChecklist selectedChecklist={selectedChecklistTemplate} type={'create'} />
}

function UpdateChecklist({selectedChecklist, type = 'update'}) {
	const { authAxios } = useContext(FetchContext);
	const navigate = useNavigate();

	// The options for the companies
	const [companies, setCompanies] = useState([]);

	// States to keep track of the form values
	const [formValues, setFormValues] = useState([]);
	const [company, setCompany] = useState({});
	const [checklistTitle, setChecklistTitle] = useState('');

	// Get companies on initial render
	useEffect(() => {
		authAxios.get('/crm/companies/all', {
			params: {
				fields: [
					'id',
					'name'
				]
			}
		}).then(({data}) => {
			// Set the company options
			setCompanies(data.map(company => ({
				value: company.id,
				label: company.name
			})));
		})
	}, [authAxios]);

	useEffect(() => {
		setChecklistTitle(selectedChecklist?.name ?? '')
		setFormValues(selectedChecklist?.inputs ?? selectedChecklist?.data ?? []);
		setCompany({
			value: selectedChecklist?.company?.id ?? '',
			label: selectedChecklist?.company?.name ?? ''
		})
	}, [selectedChecklist])

	if (selectedChecklist === null) {
		return <div style={{
			display: 'flex',
			justifyContent: 'center',
		}}>
			<Spinner />
		</div>
	}

	function updateFormValue(target, id) {
		setFormValues(prev => {
			const index = prev.findIndex(input => input.id === id);
			const newFormValues = [...prev];
			newFormValues[index].value = target.value;
			return newFormValues;
		})
	}

	function onSubmit() {
		const data = {
			name: checklistTitle,
			company: company.value,
			data: formValues
		}

		if (type === 'create') {
			authAxios.post('/calculations/checklists', {data}).then((response) => {
				navigate('/calculations/checklists/' + response.data.data.id + '/update');
			}).catch((reason) => {
				toast.error('Er is iets misgegaan bij het aanmaken van de checklist. ' + (reason.response.status ? `(${reason.response.status})` : ''));
				console.log(reason);
			})
		} else {
			authAxios.put('/calculations/checklists/' + selectedChecklist.id, {data}).then((response) => {
				console.log(response);
				toast.success('Checklist succesvol bijgewerkt');
			}).catch((reason) => {
				toast.error('Er is iets misgegaan bij het updaten van de checklist. ' + (reason.response.status ? `(${reason.response.status})` : ''));
				console.log(reason);
			})
		}
	}

	return (
		<>
			{type === 'create' && <><h3>Template: {selectedChecklist.name}</h3><br /></>}
			<div>
				<label className={'required'}>Bedrijf</label>
				<Select options={companies} id={'checklist--company'} value={company} onChange={newValue => setCompany(newValue)} />
			</div>

			<div>
				<label className={'required'}>Checklist titel</label>
				<input type={'text'} value={checklistTitle} onChange={e => setChecklistTitle(e.target.value)} />
			</div>

			{formValues.map((input, index) => (
				<ChecklistInput input={input} key={index} onChange={updateFormValue} />
			))}

			<div style={{
				display: 'flex',
				justifyContent: 'flex-end'
			}}>
				<button onClick={() => onSubmit()} className={'btn btn--icon-right'}>Opslaan <Icon name={'save'} /></button>
			</div>
		</>
	)
}

function ChecklistInput({input, onChange}) {

	const getInput = (input) => {
		if (input.numberOfLines === 1) {
			return <input id={`checklist--${input.id}`} onChange={({target}) => onChange(target, input.id)} value={input?.value ?? ''} />
		} else {
			return <textarea id={`checklist--${input.id}`} rows={input.numberOfLines} onChange={({target}) => onChange(target, input.id)} value={input?.value ?? ''}></textarea>
		}
	}

	return (
		<div key={input.id}>
			<label className={input.required ? 'required' : ''} htmlFor={`checklist--${input.id}`}>{input.name}</label>
			{getInput(input)}
		</div>
	);
}