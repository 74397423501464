import {useCallback, useEffect, useState} from "react";

export default function LiveTimer({
    start,
    end = null,
    breaks,
    paused = false
}) {
    const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);

    const calculateTotalBreakLength = useCallback(() => {
        return breaks.reduce((acc, current) => {
            const diff = new Date(current.end ?? new Date()) - new Date(current.start);
            return acc + diff;
        }, 0);
    }, [breaks]);

    const calculateTimeDiff = useCallback(() => {
        const endDate = new Date(end ?? new Date());
        const diff = endDate - start;
        setTimeDiffSeconds(Math.ceil((diff - calculateTotalBreakLength()) / 1000));
    }, [end, start, calculateTotalBreakLength]);

    useEffect(() => {
        calculateTimeDiff();

        if (paused) return;

        const interval = setInterval(calculateTimeDiff, 1000);

        return () => clearInterval(interval);
    }, [calculateTimeDiff, paused]);

    const hours = String(Math.floor(timeDiffSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((timeDiffSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(timeDiffSeconds % 60).padStart(2, '0')

    if (isNaN(timeDiffSeconds)) return null;

    return (
        <span className={'timer__runtime notranslate' + (paused ? ' paused' : '')}>{hours}:{minutes}<small className={'timer__runtime__seconds'}>:{seconds}</small></span>
    )
}