import React from "react";
import {toast} from "react-toastify";
import axios from 'axios';
import isObject from "lodash/isObject";
import {toLower} from "lodash";

export default class MaterialUnitMutator extends React.Component {

    axios;

    constructor() {
        super();

        // Create trial instance
        this.axios = axios.create({
            baseURL: process.env.REACT_APP_API_URL + '/v1'
        });

        //  Trial of Authorization
        this.axios.interceptors.request.use(
            config => {
                const {origin} = new URL(config.baseURL);
                const allowedOrigins = [
                    process.env.REACT_APP_BACKEND_URL
                ];

                if (allowedOrigins.includes(origin)) {
                    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
                }
                return config;
            },
            error => {
                Promise.reject(error);
            }
        );
    }

    // The merchant of quantity give the correct quantity back based on raw materials
    async quantityMerchant(quantity, material, fromKG = false, round = false){
        // First things first fetch chosen material from the cellar
        const chosenMaterial = await this.materialTrial(material);

        // If quality of goods is bad enough mutate goods to fit market value
        if(['vellen', 'papier rol', 'bordkarton', 'sulfaatkarton'].includes(chosenMaterial.category.toLowerCase())) {

            // Marchant passes material to mutating facility
            if(round){
                return Math.round(this.mutate(quantity, chosenMaterial, fromKG));
            }else{
                return this.mutate(quantity, chosenMaterial, fromKG);
            }
        }

        // Goods of sufficient value
        return quantity;
    }

    // Mutate given amount to correct unit
    mutate(amount, material, fromKG = false){

        // If material is of category 'vellen' mutate accordingly
        if(['vellen', 'bordkarton', 'sulfaatkarton'].includes(toLower(material.category))){

            // From or to KG based on consumer question
            if(fromKG) {
                // Material amount beging mutated to fit value in Sheets
                // old return (amount / (material.weightInGram / 1000)) / (material.lengthInMeters * material.widthInMeters);
                // old 2return (amount / ((material.weightInGram / 1000)) * (material.lengthInMeters * material.widthInMeters)
                // 14,72 / ((200 / 1000) * (0,46 * 0,32))
                return amount / ((material.weightInGram / 1000) * (material.lengthInMeters * material.widthInMeters));
            }else{

                // Material amount beging mutated to fit value in KG
                return ((material.lengthInMeters * material.widthInMeters) * (material.weightInGram / 1000)) * amount;
            }
        }

        // If material is of category 'Papier rol' mutate accordingly
        if(['papier rol'].includes(toLower(material.category))){
            // From or to KG based on consumer question
            if(fromKG){

                //  Material amount beging mutated to fit value in rol
                // old return (amount / (material.weightInGram / 1000)) / material.widthInMeters
                return amount / ((material.weightInGram / 1000) * material.widthInMeters)
            }else{
                //  Material amount beging mutated to fit value in KG
                return (amount * material.widthInMeters) * (material.weightInGram / 1000)
            }
        }
    }

    // Returns unit from material
    async obtainUnit(unProvenMaterial){

        // Returns hero material
        const chosenMaterial = await this.materialTrial(unProvenMaterial);

        // If a hero is found
        if(chosenMaterial !== null){

            // Embark on the trial of unit to get the correct matching unit
            const unit = await this.unitTrial(chosenMaterial.unit, chosenMaterial);

            // Provide unit of measurement
            return unit;
        }
    }

    // Provides material unit for storage
    async obtainUnitForStorage(material){
        // First things first fetch chosen material from the cellar
        const chosenMaterial = await this.materialTrial(material);

        // If material is rol or sheet return in kg
        switch(toLower(material.category)){
            case 'vellen':
            case 'bordkarton':
            case 'sulfaatkarton':
            case 'papier rol':
                return 'kilogram'
            default:
                return chosenMaterial.unit
        }
    }

    // A trial of unit may the correct win!
    async unitTrial(unit, chosenMaterial){

        // The gatekeeper checks if unit and chosenMaterial are of correct origin
        if(typeof unit !== 'string' || !isObject(chosenMaterial)) return ''

        // Ask the house of unit to return the correct unit
        return this.houseOfUnit(unit, chosenMaterial)
    }

    // A trial of material may the correct overcome!
    async materialTrial(unProvenMaterial){
        // If material is already proven return as hero
        if(isObject(unProvenMaterial)) return unProvenMaterial

        // Continue to trial
        return await this.axios.get(`/logistics/materials/${unProvenMaterial}`).then(async ({data}) => {
            // Returns hero for continued adventures
            return data;
        }).catch((e) => {
            toast.error('Er is mis gegaan met het ophalen van een materiaal.')
            return null;
        })
    }

    // The house of unit returns the unit matching of material house
    houseOfUnit(unit,material){

        // Check if unit is type of superior (not in KG)
        if(!['vellen', 'papier rol', 'bordkarton', 'sulfaatkarton'].includes(material.category.toLowerCase())) return unit

        // House checks if material is sheet or rol ands acts accordingly
        switch(toLower(material.category)){
            case 'vellen':
            case 'bordkarton':
            case 'sulfaatkarton':
                return 'vellen'
            case 'papier rol':
                return 'meter'
            default:
                return unit
        }
    }
}
