import { useContext } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { getSelectedValue } from 'App/Util/select2';
import { openPopup } from 'UI/App/Components/Popup/Popup';
import Icon from 'UI/App/Components/Icon/Icon';
import { priorityOptions } from 'UI/App/Components/Popup/Popups/PostAndUpdateTask';
import useWindowDimensions from 'App/Util/useWindowDimensions';
import { formatDate } from 'App/Util/format';

export default function NotesTask({
    task,
    taskKey,
    listID,
    setUpdateTaskID,
    setTaskFormData,
    setTasks,
    expandedTasks,
    setExpandedTasks
}) {
    const { msGraph } = useContext(AuthContext);
    const { width } = useWindowDimensions();
    /**
     * Toggles the status of the task
     * @param {Event} e
     */
    async function toggleTaskStatus({ target }) {
        // check if listID exists
        if (!listID) {
            toast.error('De bijbehorende takenlijst van deze taak is niet gevonden');
            return;
        }

        // prevent double pressing
        target.disabled = true;

        setTimeout(async () => {
            // create post object with changed status
            const status = {
                status: target.checked ? 'completed' : 'notStarted'
            };

            // update status
            await msGraph
                .patch(`/me/todo/lists/${listID}/tasks/${task?.id}`, status)
                .then((response) => {
                    // update task status
                    setTasks((prev) => {
                        // copy the "value" property
                        const newValue = prev.value;

                        // add "Z" to the end of the reminder dateTime
                        if (typeof response?.reminderDateTime?.dateTime === 'string') {
                            response.reminderDateTime.dateTime = response.reminderDateTime.dateTime + 'Z';
                        }

                        // replace the task at key with the new response
                        newValue[taskKey] = response;

                        // return prev with new value array
                        return { ...prev, value: newValue };
                    });
                })
                .catch((error) => {
                    error?.message
                        ? toast.error(error.message)
                        : toast.error('Er is een probleem opgetreden bij het veranderen van de status van deze taak');

                    console.error(error);
                });
        }, 50);
    }

    /**
     * Opens the post&update popup and set to update
     */
    function editTask() {
        const date = new Date(task?.reminderDateTime?.dateTime);
        const dateValue =
            date.getFullYear() +
            '-' +
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            date.getDate().toString().padStart(2, '0') +
            'T' +
            date.getHours().toString().padStart(2, '0') +
            ':' +
            date.getMinutes().toString().padStart(2, '0');

        // set input values to task data
        setTaskFormData({
            key: taskKey,
            title: task?.title,
            content: task?.body?.content,
            priority: getSelectedValue(task?.importance, priorityOptions, 'value'),
            isReminder: task?.reminderDateTime ? true : false, // task.isReminderOn becomes false if the reminde datetime has passed
            date: dateValue,
            status: task?.status
        });

        // set which task to update
        setUpdateTaskID({ id: task?.id, key: taskKey });

        openPopup('add-and-update-task');
    }

    /**
     * Deletes the task from the user's MS todo list
     */
    function deleteTask() {
        if (!listID) {
            toast.error('De bijbehorende takenlijst van deze taak is niet gevonden');
            // stop function
            return;
        }

        if (window.confirm('weet u zeker dat deze taak wil verwijderen?')) {
            // send delete command to MS Graph api
            msGraph
                .delete(`/me/todo/lists/${listID}/tasks/${task?.id}`)
                .then(() => {
                    // delete task
                    setTasks((prev) => {
                        // copy the "value" property
                        const newValue = prev.value;

                        // delete the task at array key
                        delete newValue[taskKey];

                        // return prev with new value array
                        return { ...prev, value: newValue };
                    });
                })
                .catch((error) => {
                    error?.message ? toast.error(error.message) : toast.error('De taak kon niet verwijderd worden.');

                    console.error(error);
                });
        }
    }

    return (
        <div className={`task priority_${task.importance}`}>
            <div className='task__status-checkbox'>
                <input type='checkbox' onChange={toggleTaskStatus} defaultChecked={task?.status === 'completed'} />
            </div>

            <div className='task__content'>
                <div className='task__header'>
                    <div className='task__header__title'>
                        <strong>{task?.title}</strong>

                        {task?.reminderDateTime !== undefined ? (
                            <div className={'task__header__date'}>{formatDate(task.reminderDateTime?.dateTime)}</div>
                        ) : (
                            <div className={'task__header__date'}>Herinnering n.v.t.</div>
                        )}
                    </div>

                    <div
                        className='task__header__actions'
                        style={{
                            position: width > 400 ? 'absolute' : 'static',
                            top: 0,
                            right: 0,
                            display: 'flex',
                            gap: '10px'
                        }}
                    >
                        <button onClick={editTask} className='btn--black btn--round' title='Bewerk todo'>
                            <Icon name='pencil' />
                        </button>
                        <button onClick={deleteTask} className=' btn--black btn--round' title='Verwijder todo'>
                            <Icon name='trash-can' />
                        </button>
                        <button
                            className='btn--transparent btn--no-focus'
                            title='Task uitvouwen'
                            onClick={(e) => {
                                if (expandedTasks.includes(task.id) === true) {
                                    setExpandedTasks((prev) => [...prev.filter((value) => value !== task.id)]);
                                } else {
                                    setExpandedTasks((prev) => [...prev, task.id]);
                                }
                            }}
                            style={{
                                padding: 0
                            }}
                        >
                            <Icon
                                style={{
                                    marginLeft: '10px',
                                    width: '25px',
                                    height: '20px',
                                    transform: expandedTasks.includes(task.id) ? 'scaleY(-1)' : 'scaleY(1)'
                                }}
                                name={'angle-down'}
                            />
                        </button>
                    </div>
                </div>

                <div
                    className='task__content__body'
                    style={{ display: expandedTasks.includes(task.id) !== true && 'none' }}
                >
                    <p>{task?.body?.content}</p>
                    <br />
                    <a
                        href={`https://to-do.office.com/tasks/id/${task?.id}/details`}
                        target='_blank'
                        title='Link naar task'
                        rel='noreferrer nofollow'
                        style={{ paddingTop: '20px' }}
                    >
                        Bekijk todo
                    </a>
                </div>
            </div>
        </div>
    );
}
