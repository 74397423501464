import Popup, { closePopup } from 'UI/App/Components/Popup/Popup';
import TimeInput from "UI/App/Partials/Content/TimeAccounting/Blocks/TimeAccountingCreateEdit/TimeAccountingInput";

export default function PopupAddTimer({ timerToAdd, setUpdateBlocks, setTime = true }) {

    function handleClose() {
        setUpdateBlocks();
        closePopup();
    }

    return (
        <Popup title='Timer toevoegen' popupID='add-timer'>

            <TimeInput aquireOngoing={false} timerToAdd={timerToAdd} onTimer={handleClose} setTime={setTime} />
        </Popup>
    );
}