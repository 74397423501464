import React from "react";

export default function VersionBlock({version, features, fixes, changes}) {
    return (
        <div className="version">
            <div className={'version-title'}>
                {version}
            </div>
            <div className="version-content">
                {features && <b>🚀 Nieuw:</b>}
                {features && <ul>{features.map((feature, index) => <li key={index}>{feature}</li>)}</ul>}
                {fixes && <b>🔥 Bug fixes:</b>}
                {fixes && <ul>{fixes.map((fix, index) => <li key={index}>{fix}</li>)}</ul>}
                {changes && <b>⚙️ Aanpassingen:</b>}
                {changes && <ul>{changes.map((change, index) => <li key={index}>{change}</li>)}</ul>}
            </div>
        </div>
    )
}