import './input.scss';

type TFieldset = {
    children: any;
    label: any;
    disabled?: boolean;
    form?: string;
    name?: string;
    transparent: boolean;
    noBorder: boolean;
};

/**
 * Insert a <fieldset>.
 *
 * @export default
 * @param {Object} props
 * @param {any} props.children
 * @param {any} [props.label=null] - Content of the fieldset's `<legend>`
 * @param {boolean} [props.disabled=undefined] - > If this Boolean attribute is set, all form controls that are descendants of the `<fieldset>`, are disabled.
 * @param {string} [props.form=undefined] - > This attribute takes the value of the id of a \<form\> element you want the `<fieldset>` to be  part of, even if it is not inside the form.
 * @param {string} [props.name=undefined] - > The name associated with the group.
 * @param {boolean} [props.transparent=false]
 * @param {boolean} [props.noBorder=false]
 *
 * @return {JSX.Element}
 */
export default function Fieldset({
    children,
    label = null,
    disabled = undefined,
    form = undefined,
    name = undefined,
    transparent = false,
    noBorder = false
}: TFieldset): JSX.Element {
    return (
        <fieldset
            {...{
                disabled,
                form,
                name,
                className: `${transparent ? 'fieldset__transparent' : ''} ${noBorder ? 'fieldset__borderless' : ''}`
            }}
        >
            <legend>{label}</legend>
            <div className='fieldset__content'>{children}</div>
        </fieldset>
    );
}
