import Popup, { closePopup } from 'UI/App/Components/Popup/Popup';
import { FetchContext } from "App/Strapi/FetchContext";
import { AuthContext } from "App/Strapi/AuthProvider"
import { useContext, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Creatable, { useCreatable } from 'react-select/creatable';
import Select from 'react-select'
// popup to add a timer through mouse presses
export default function AddTimeAccounting({ companies, points, date, update }) {
	const { authAxios } = useContext(FetchContext);
	const authContext = useContext(AuthContext);
	const [currentUser, setCurrentUser] = useState([]);
	const [requiredFields, setRequiredFields] = useState(<></>);
	const [timerInfo, setTimerInfo] = useState({});
	const [_companies, setCompanies] = useState(null);
	const [timerOptions, setTimerOptions] = useState({});

	useEffect(() => {
		setCurrentUser(authContext.authState.user);
		updateFields();
		getTimeOptions();
		if (companies !== undefined && companies.length > 0) {
			setCompanies(companies);
		}

	}, []);
	useEffect(() => {

		if (companies !== undefined && companies.length > 0) {

			setCompanies(companies);
		}

		let start = points?.start === undefined ? 0 : points.start;
		let end = points?.end === undefined ? 0 : points.end;

		let startHour = 0;
		let startMinute = start * 5;
		let forCycles = Math.floor((start * 5) / 60);
		for (let index = 0; index < forCycles; index++) {
			startHour++;
			startMinute = startMinute - 60;
		}

		let endHour = 0;
		let endMinute = end * 5;
		forCycles = Math.floor((end * 5) / 60);
		for (let index = 0; index < forCycles; index++) {
			endHour++;
			endMinute = endMinute - 60;
		}

		startHour = startHour.toString().padStart(2, '0');
		startMinute = startMinute.toString().padStart(2, '0');

		endHour = endHour.toString().padStart(2, '0');
		endMinute = endMinute.toString().padStart(2, '0');

		let updateTimerInfo = timerInfo;

		updateTimerInfo.startTime = startHour + ":" + startMinute;
		updateTimerInfo.endTime = endHour + ":" + endMinute;
		updateTimerInfo.startDate = date;
		updateTimerInfo.endDate = date;
		setTimerInfo(updateTimerInfo);
		updateFields();


	}, [companies, points, date, timerOptions]);


	async function handleSubmit(e) {
		e.preventDefault();

		// only submit if the submit came from the SUBMIT button
		if (e.nativeEvent.submitter.name !== "submit" || !requiredParts()) {
			toast.error("niet alle velden zijn ingevuld")
			return;
		}
		let startDateTime = timerInfo.startDate + "T" + timerInfo.startTime;
		let endDateTime = timerInfo.endDate + "T" + timerInfo.endTime;

		// check if necesary and right data is here
		if (!validDateTime(startDateTime, "De start datum is niet compleet") || !validDateTime(endDateTime, "De eind datum is niet compleet")) {

			return;
		}
		// get total time in seconds
		let totalTimeInSec = Math.floor((new Date(endDateTime).getTime() - new Date(startDateTime).getTime()) / 1000);

		// subtract pause from total
		totalTimeInSec = (totalTimeInSec - (timerInfo.hours * 60 * 60)) - (timerInfo.minutes * 60);


		let timeOffset = new Date().getTimezoneOffset() * 600000;
		// make an object to act as payload
		let startDate = new Date(new Date(startDateTime).getTime());
		let endDate = new Date(new Date(endDateTime).getTime());

		let pauseHour = isNaN(Number(timerInfo.details.pauseHour)) ? 0 : Number(timerInfo.details.pauseHour);
		let pauseMinute = isNaN(Number(timerInfo.details.pauseMinute)) ? 0 : Number(timerInfo.details.pauseMinute);
		let pause = (pauseHour * 60 + pauseMinute) * 60

		let data = {
			startTime: startDate,
			endTime: endDate,
			user: currentUser.id,
			totalRunTimeInSec: totalTimeInSec,
			company: timerInfo.details.companyId,
			details: timerInfo.details,
			pause: pause
		}

		createTimeAcc(data);
	}

	function updateFields() {




		let submitButton = <input type='submit' name='submit' class='btn' />

		setRequiredFields(<>

			{/* Bedrijven:<select name="companyId" required value={timerInfo.company} onChange={(e) => saveInputOnChange(e)}>
				<option value=''></option>
				{_companies}
			</select> */}
			<div>Bedrijven:<Select required={true} name="companyId" options={companies} onChange={updateCompany} ></Select></div>

			Project:<Creatable required={true} value={timerOptions?.selectedProject} name="project" options={timerOptions?.project} onChange={updateProject} onCreateOption={createNewProject}></Creatable>

			Activiteit:<Creatable required={true} value={timerOptions?.selectedActivity} name="activity" options={timerOptions?.activity} onChange={updateActivity} onCreateOption={createNewActivity}></Creatable>

			Omschrijving:<textarea required name="description" type="textarea" row="6" value={timerInfo?.details?.description} onChange={(e) => saveInputOnChange(e)} />

			<div className="TimeAccouting__time">
				<div className="TimeAccouting__starttime">
					Start tijd:<input name="startTime" type="time" value={timerInfo.startTime} onChange={(e) => saveInputOnChange(e)} />
					<input name="startDate" type="date" value={timerInfo.startDate} onChange={(e) => saveInputOnChange(e)} />
				</div>
				<div className="TimeAccouting__stoptime">
					Eind tijd:<input name="endTime" type="time" value={timerInfo.endTime} onChange={(e) => saveInputOnChange(e)} />
					<input name="endDate" type="date" value={timerInfo.endDate} onChange={(e) => saveInputOnChange(e)} />
				</div>
			</div>

			<div>Pauze/ uren verwijderen:</div>
			<div className="TimeAccouting__pauze">
				<div className="TimeAccouting__pausetime">
					Uren:<input name="pauseHour" type="number" value={isNaN(timerInfo.pauseHour) ? 0 : timerInfo.pauseHour} onChange={(e) => saveInputOnChange(e)} />
				</div>
				<div className="TimeAccouting__pausetime">
					Minuten:<input name="pauseMinutes" type="number" value={isNaN(timerInfo.pauseMinute) ? 0 : timerInfo.pauseMinute} onChange={(e) => saveInputOnChange(e)} />
				</div>
			</div>

			{submitButton}
		</>)
	}
	function updateCompany(e) {

		// store state in object so old values can be used to set new ones.
		let updateTimerInfo = timerInfo;

		if (timerInfo.timerStarted) {
			return
		}
		// set details
		if (updateTimerInfo?.details === undefined) {
			updateTimerInfo.details = {
				activity: undefined,
				project: undefined,
				description: undefined,
				companyId: e.value === undefined ? undefined : e.value.toString()
			};
		}

		updateTimerInfo.details.companyId = e.value === undefined ? undefined : e.value.toString();



	}
	function updateProject(e) {
		// store state in object so old values can be used to set new ones.
		let updateTimerInfo = timerInfo;

		if (timerInfo.timerStarted) {
			return
		}
		// set details
		if (updateTimerInfo?.details === undefined) {
			updateTimerInfo.details = {
				activity: undefined,
				project: e.value === undefined ? undefined : e.value.toString(),
				description: undefined,
				companyId: undefined,
			};
		}

		updateTimerInfo.details.project = e.value === undefined ? undefined : e.value.toString();


		// update the selected
		let updateTimerOptions = timerOptions;
		updateTimerOptions.selectedProject = { value: e.value, label: e.label }
		setTimerOptions(updateTimerOptions);
		updateFields();

	}
	function createNewProject(e) {
		// set the packet
		let data = {
			Name: e,
			type: "project",
			creator: authContext.authState.user.id
		}
		// add new option
		authAxios.post(`/time-options`, { data }).then(({ data }) => {

			let updateTimerOptions = timerOptions;
			updateTimerOptions.selectedProject = { value: data.data.id, label: data.data.Name }
			setTimerOptions(updateTimerOptions);
			getTimeOptions();

		}).catch((exception) => {
			toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

			console.error(exception);
		})
	}
	function updateActivity(e) {
		// store state in object so old values can be used to set new ones.
		let updateTimerInfo = timerInfo;

		if (timerInfo.timerStarted) {
			return
		}
		// set details
		if (updateTimerInfo?.details === undefined) {
			updateTimerInfo.details = {
				activity: e.value === undefined ? undefined : e.value.toString(),
				project: undefined,
				description: undefined,
				companyId: undefined,
			};
		}

		updateTimerInfo.details.activity = e.value === undefined ? undefined : e.value.toString();

		// update the selected
		let updateTimerOptions = timerOptions;
		updateTimerOptions.selectedActivity = { value: e.value, label: e.label }
		setTimerOptions(updateTimerOptions);
		updateFields();
	}
	function createNewActivity(e) {
		// set the packet
		let data = {
			Name: e,
			type: "activity",
			creator: authContext.authState.user.id
		}
		// add new option
		authAxios.post(`/time-options`, { data }).then(({ data }) => {

			let updateTimerOptions = timerOptions;
			updateTimerOptions.selectedActivity = { value: data.data.id, label: data.data.Name }
			setTimerOptions(updateTimerOptions);
			getTimeOptions();

		}).catch((exception) => {
			toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

			console.error(exception);
		})
	}
	function getTimeOptions() {

		authAxios.get(`/time-options/knowOptions?`)
			.then(({ data }) => {
				const updateTimerOptions = {}

				updateTimerOptions.selectedProject = timerOptions?.selectedProject;
				updateTimerOptions.selectedActivity = timerOptions?.selectedActivity;

				for (const [key, _data] of Object.entries(data)) {

					if (updateTimerOptions[key] === undefined) {
						updateTimerOptions[key] = []
					}

					for (const option of _data) {
						updateTimerOptions[key].push({ value: option.id, label: option.Name })
					}

				}
				setTimerOptions(updateTimerOptions)
			}).catch((exception) => {
				toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

				console.error(exception);
			})
	}
	function saveInputOnChange(e) {
		// store state in object so old values can be used to set new ones.
		let updateTimerInfo = timerInfo;

		// depending on input change, save under specif name in usestate.
		// if there already is a timer started we cant go set variabels
		if (!timerInfo.timerStarted) {

			// set details
			if (updateTimerInfo?.details === undefined) {
				updateTimerInfo.details = {
					activity: undefined,
					project: undefined,
					description: undefined
				};
			}

			updateTimerInfo.details[e.target.name] = e.target.value === undefined ? e.target.text : e.target.value;

		} else if (!e?.target?.name.startsWith("pause")) {
			toast.error('Er is al een timer gaande, stop de timer om iets toe te voegen');
		}
		// pause parts, this can be set while a timer is going on

		if (e?.target?.name === 'pauseMinutes') {
			let minutes = e.target.value;

			// if more then 60 minutes turn the minutes in to hours
			if (minutes >= 60) {
				let times = Math.floor(minutes / 60);
				minutes = minutes - 60 * times;

				timerInfo.pauseHour = (timerInfo.pauseHour === undefined ? 0 : timerInfo.pauseHour) + times;
			}
			timerInfo.pauseMinute = minutes;

		}

		if (e?.target?.name === 'pauseHour') {
			timerInfo.pauseHour = e.target.value;
		}

		setTimerInfo(updateTimerInfo);
		updateFields();
	}
	function requiredParts() {

		if (timerInfo?.details?.description === undefined || timerInfo?.details?.companyId === undefined || timerInfo?.details?.project === undefined || timerInfo?.details?.activity === undefined) {
			return false;
		}
		return true;
	}
	function validDateTime(dateTime, errorMsg) {
		let result = new Date(dateTime);
		if (result.toString() === "Invalid Date") {
			toast.error(errorMsg);
			return false;
		}
		return true;
	}
	function createTimeAcc(data) {
		console.log(data)
		authAxios.post("/time-registrations", { data }).then(() => {
			toast.success('Opgeslagen.');


			let updateTimerOptions = timerOptions
			updateTimerOptions.selectedActivity = { value: undefined, label: undefined };
			updateTimerOptions.selectedProject = { value: undefined, label: undefined };
			setTimerOptions(updateTimerOptions);

			update(true);
			closePopup();

		}).catch((e) => {

			toast.error('Oeps, er ging iets fout.');

			console.error(e);
		});
	}
	return (
		<Popup title='Timer toevoegen' popupID='add-day-timer'>
			<form onSubmit={handleSubmit}>
				{requiredFields}
			</form>
		</Popup>
	);
}
