import { useContext, useEffect, useState, useRef, Component } from "react";
import { FetchContext } from "App/Strapi/FetchContext";
import { AuthContext } from "App/Strapi/AuthProvider"
import Block from "UI/App/Components/Block/Block";
import PopupAddTimer from 'UI/App/Components/Popup/Popups/AddTimeAccounting';
import PopupEditTimer from 'UI/App/Components/Popup/Popups/EditTimer';
import { openPopup } from "UI/App/Components/Popup/Popup";
import { toast } from "react-toastify";

export default function TimeDayTable({ knowCompanies = undefined, forcedUpdate = false }) {
    const { authAxios } = useContext(FetchContext);
    const authContext = useContext(AuthContext);
    const ref = useRef(null);

    const [variableBlock, setVariableBlock] = useState(knowCompanies);
    const [tableRows, setTableRows] = useState(<></>);
    const [daySelector, setDaySelector] = useState({});
    const [companies, setCompanies] = useState({});
    const [editTimer, setEditTimer] = useState([]);
    const [extraTh, setExtraTh] = useState(<></>);
    const [knowTimers, setKnowTimers] = useState([]);

    const [timeBlocks, setTimeBlocks] = useState([]);
    const [addTimeblock, setAddTimeblock] = useState({ clicked: false });
    const [rowBlocks, setRowBlocks] = useState([]);

    const [colors, setColors] = useState({
        background: "white",
        accent: "#c4e2ec",
        selected: "#A5A5A5",
        currentTime: "#0089b4",
        currentActiveBlock: "green",
    });
    useEffect(() => {

        if (knowCompanies !== undefined) {
            setCompanies(knowCompanies);
        } else {
            getCompanies();
        }

        let currentDay = daySelector;

        let curDate = new Date();
        let year = new Date(curDate.getFullYear(), 0, 1);
        let days = Math.floor((curDate - year) / (24 * 60 * 60 * 1000));
        currentDay.selectedWeek = getWeekNumber(new Date(), 1);
        currentDay.selectedDay = new Date().getDay();

        currentDay.selectedDate = formatDateToReadableString(curDate).date;


        setDaySelector(currentDay);
        getAllTimersByDate(currentDay.selectedDate);
        //setPacket();


        updateBlock();

        // const interval = setInterval(() => {
        //     createTableRows();
        // }, 60000);

        // return () => {
        //     clearInterval(interval);
        // };

    }, [])

    useEffect(() => {
        setPacket();

    }, [timeBlocks])
    useEffect(() => {
        createTableRows();
    }, [rowBlocks])
    useEffect(() => {
        updateBlock();
    }, [tableRows, editTimer])
    useEffect(() => {
        if (!forcedUpdate) {
            return;
        }
        reAquireTable()


    }, [forcedUpdate])

    // format timers in to blocks, with necesary info
    function setPacket() {

        if (!(timeBlocks.length > 0)) {
            return;
        }
        // store all know timers
        let allBlocks = timeBlocks;


        // give blocks coresponding attributes
        for (let block of allBlocks) {
            // find timer with same id as block
            block.attributes = knowTimers.filter((obj) => {
                return obj.id === block.id;
            }, {})[0];
        }


        // give all blocks a row if needed so that they wont overlap
        // compares evry block with all blocks
        for (let block of allBlocks) {
            let rowOffset = 1;
            for (let _block of allBlocks) {
                // dont compare with itself
                if (block.id === _block.id) {
                    continue;
                }
                // only increment row if it woudent fit between existing blocks
                if (block.start >= _block.start && block.start <= _block.end && _block.row === rowOffset) {

                    rowOffset = rowOffset + 1;

                }

            }
            // set row of this block to the right offset
            block.row = rowOffset;

        }

        // dived the object in to mutiple object on row number
        let divedBlocks = [];
        let index = [];

        divedBlocks = Object.values(allBlocks).reduce((acc, val) => {

            if (acc[val.row] === undefined) {
                acc[val.row] = []
            }
            // check if we already have a index counter for this row, not needed but looks neat :)
            if (index[val.row]?.indexCount === undefined) {
                index.push({
                    indexCount: 0
                });
            }

            if (index[val.row - 1]?.indexCount === undefined) {

                index[val.row - 1] = { indexCount: 0 };

            }

            acc[val.row][index[val.row - 1].indexCount] = val

            // increment the counter for the row
            index[val.row - 1].indexCount++;
            return acc;
        }, {});

        // turn outer object to array
        divedBlocks = Object.keys(divedBlocks).map(key => {
            return divedBlocks[key];
        });

        // create extra headers if we have more then 1 row
        let thForExtraRows = [];
        for (let index = 0; index < divedBlocks.length - 1; index++) {
            thForExtraRows.push(<th ></th>);

        }

        setExtraTh(thForExtraRows);
        setTimeBlocks(allBlocks);
        setRowBlocks(divedBlocks);

    }
    // create table rows, needs 
    function createTableRows() {

        let elements = [];
        let currentDate = new Date();
        // keep track of hours and minutes
        let hours = 0;
        let minutes = 0;

        // 1440 minutes in a day 1440/5 = 288
        let stepSize = 5
        for (let index = 0; index < 1440 / stepSize; index++) {
            let backStyle = colors.background;

            let element = <></>;

            let packet = [];
            // keep track of what time belongs to this index
            if (minutes >= 60) {
                minutes = minutes - 60;
                hours++;
            }

            for (let rows of rowBlocks) {
                let textToShow = "";
                // if current index is in the block the user is adding, give it a diffrent color
                backStyle = indexInAddBox(index) ? colors.accent : colors.background;


                let data = blocksWhereIndexIsStart(rows, index);


                if (indexInRowBox(rows, index)) {


                    // only set the color if its not already set
                    if (backStyle === colors.background) {
                        backStyle = colors.selected;
                    }


                    // if data is NOT undefined it means this is the beginning of a time block
                    if (data !== undefined) {
                        packet.push(
                            <td className={"timeTd timePacketTd"} style={{ backgroundColor: backStyle }}>
                                {data[0]?.attributes?.details?.description}
                                {data[0]?.isOngoing && <small><br />Deze timer is gaande.</small>}
                                <div>
                                    {!data[0]?.isOngoing && <button id={data[0].id} onClick={editTimers} className="TimeAccouting__modButton">edit</button>}

                                    {!data[0]?.isOngoing && <button id={data[0].id} onClick={deleteTimer} className="TimeAccouting__modButton">delete</button>}
                                </div>
                            </td>
                        );
                    } else {
                        packet.push(
                            <td className={"timeTd timePacketTd"} style={{ backgroundColor: backStyle }}>

                            </td>
                        );
                    }

                } else {

                    packet.push(<td className={"timeTd timePacketTd"} style={{ backgroundColor: backStyle }}></td>);
                }

            }
            // set the time and 'empty' block background, only applies if user adds a block via table
            backStyle = indexInAddBox(index) ? colors.accent : colors.background;

            // if we have no active 'blocks' we just give it an empty td so table still looks nice
            if (rowBlocks.length === 0) {
                packet.push(<td className={"timeTd timePacketTd"} style={{ backgroundColor: backStyle }}></td>);
            }


            if (Math.floor((currentDate.getMinutes()) / 5) * 5 === minutes && currentDate.getHours() === hours) {

                element = <tr ref={ref} className={"timeTrCurrent timeTable__tr"} style={{ color: colors.currentTime, backgroundColor: backStyle }} value={index} onMouseDown={(e) => { elementPressed(e, index) }} >
                    <td className={"timeTd timeIndicatorTd"} style={{ backgroundColor: colors.currentTime }}></td>
                    <td className={"timeTd timeDisplayTd"} >
                        {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}
                    </td>
                    {packet}

                </tr>;
            } else {

                element = <tr className={"timeTr timeTable__tr"} style={{ backgroundColor: backStyle }} onMouseDown={(e) => { elementPressed(e, index) }}>
                    <td className={"timeTd timeIndicatorTd"}></td>
                    <td className={"timeTd timeDisplayTd"}>
                        {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}
                    </td>
                    {packet}

                </tr>;
            }

            minutes = minutes + stepSize;
            elements.push(element)

        }

        ref.current?.parentElement.scrollTo(0, ref.current.offsetTop - 55);
        setTableRows(elements);
    }
    // turn two timestamps in to an index start and end point
    function timeStampsToTimeBox(startTime, endTime) {

        // get offset so user has a visualy accurate timestamp
        let timeOffset = new Date().getTimezoneOffset() * 1;
        let isOngoing = false;
        if (endTime == null) {
            endTime = new Date();
            isOngoing = true;
        }
        // turn string in to timestamp with offset
        startTime = new Date(new Date(startTime).getTime() - (timeOffset * 60000));
        endTime = new Date(new Date(endTime).getTime() - (timeOffset * 60000));

        // get year, month, date
        let year = startTime.getFullYear().toString();
        let month = (startTime.getMonth() + 1).toString().padStart(2, '0');
        let date = startTime.getDate().toString().padStart(2, '0');

        // get minutes from day 'start'
        let dayStart = new Date(year + "-" + month + "-" + date);
        let startMinutes = diffrenceInMinutes(dayStart, startTime);
        // get minutes between the two timestamps
        let minutes = diffrenceInMinutes(startTime, endTime);

        // turn the minutes in to steps (1 step is 5 minutes)
        let startStep = Math.floor((startMinutes) / 5);
        let steps = Math.floor((minutes) / 5);
        let endStep = startStep + steps;

        // return the end and start step
        return { start: startStep, end: endStep, isOngoing: isOngoing };
    }
    // checks if the index is in the row the user selected
    function indexInAddBox(tableIndex) {
        let allBlocks = addTimeblock;


        if (allBlocks?.points?.start === tableIndex) {
            return true;
        } else {
            return false;
        }
    }
    // get diffrence in minutes between timestamps
    function diffrenceInMinutes(dt2, dt1) {
        // get diffrence between times
        let diff = ((dt1.getTime() - dt2.getTime()) / 1000) / 60;
        // round it so we dont get minutes with decimals
        return Math.abs(Math.round(diff));
    }
    // return data for the start of a block based on row and index
    function blocksWhereIndexIsStart(rows, tableIndex) {
        // store all know timers
        let allBlocks = rows;

        let returnArray = allBlocks.filter((obj) => {
            return obj.start === tableIndex;
        }, {});

        if (returnArray.length > 0) {
            return returnArray;
        }

    }

    // checks if the index is in the given row
    function indexInRowBox(row, tableIndex) {


        // loop through a row and return true in index falls in a block
        for (let index = 0; index < row.length; index++) {

            if (tableIndex <= row[index].end && tableIndex >= row[index].start) {

                return true;
            }
        }

        return false;
    }
    function updateBlock() {
        updateDaySelect();
        //getAllTimersByDate(daySelector.selectedDate);
        let elements = [];
        elements.push(daySelector.dayButtons)
        elements.push(<>

            <table className={"timeTable"}>

                <thead className={"timeTable__th"}>
                    <tr >
                        <th>{""}</th>
                        <th>time</th>
                        <th>rows</th>

                        {/* extraTh is only here for the rare but possible case that we need mutiple rows */}
                        {/* {extraTh} */}

                    </tr>
                </thead>

                <tbody className={"timeTable__tb"}>
                    {tableRows}
                </tbody>

            </table>
            <PopupAddTimer companies={companies} points={addTimeblock.points} date={daySelector.selectedDate} block={timeBlocks} setBlock={setTimeBlocks} update={reAquireTable} />
            <PopupEditTimer timer={editTimer} setUpdateBlocks={reAquireTable} />
        </>)
        setVariableBlock(elements);
    }
    function updateDaySelect() {
        let elements = [];
        let updateBlock = daySelector;
        elements.push(<div className="timeAccounting__week">
            <input name="selectDate" type="date" value={daySelector.selectedDate} onChange={(e) => dayButtonPressed(e)} />
            Week: {daySelector.selectedWeek}
        </div>)


        let days = ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"];
        for (let index = 0; index < days.length; index++) {
            if (daySelector?.selectedDay === index) {
                elements.push(<button value={index} name={"timer_" + days[index].toLowerCase()} onClick={dayButtonPressed} disabled className="TimeAccouting__tab">{days[index]}</button>);
            } else {
                elements.push(<button value={index} name={"timer_" + days[index].toLowerCase()} onClick={dayButtonPressed} className="TimeAccouting__tab">{days[index]}</button>);
            }
        }

        if (updateBlock?.dayButtons === undefined) {
            updateBlock.dayButtons = [];
        }

        updateBlock.dayButtons = elements;
        setDaySelector(updateBlock)

    }
    function reAquireTable(e) {
        getAllTimersByDate(daySelector.selectedDate);
        updateBlock();
    }
    // validation/formatting functions
    //#region
    // check if all requird fields are filled

    function formatDateToReadableString(inputDate, TimeWithSec = true) {

        inputDate = new Date(inputDate);
        let returnVal = { date: "", time: "" };

        let year = inputDate.getFullYear().toString();
        let month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        let date = inputDate.getDate().toString().padStart(2, '0');

        returnVal.date = year + "-" + month + "-" + date;

        let timeOffset = new Date().getTimezoneOffset() * -1;

        let hours = new Date(inputDate.getTime() - (timeOffset * 60000)).getHours().toString().padStart(2, '0');
        let minutes = inputDate.getMinutes().toString().padStart(2, '0');
        let seconds = inputDate.getSeconds().toString().padStart(2, '0');
        if (TimeWithSec) {
            returnVal.time = hours + ":" + minutes + ":" + seconds;
        } else {
            returnVal.time = hours + ":" + minutes;
        }
        return returnVal;
    }
    function getWeekNumber(date = undefined, dowOffset = 0) {
        let curDate = null
        if (date !== undefined) {
            curDate = new Date(date)
        } else {
            curDate = new Date()
        };

        let newYear = new Date(curDate.getFullYear(), 0, 1);
        // set which day the week begins on
        let day = newYear.getDay() - dowOffset;
        day = (day >= 0 ? day : day + 7);
        let dayNum = Math.floor((curDate.getTime() - newYear.getTime() - (curDate.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;

        let weekNum;

        // if new a new year start before week middle
        if (day < 4) {
            weekNum = Math.floor((dayNum + day - 1) / 7) + 1;

            if (weekNum > 52) {
                let nYear = new Date(curDate.getFullYear() + 1, 0, 1);
                let nDay = nYear.getDay() - dowOffset;
                nDay = nDay >= 0 ? nDay : nDay + 7

                weekNum = nDay < 4 ? 1 : 53
            }
        } else {
            weekNum = Math.floor((dayNum + day - 1) / 7)

            //if we have a week 0 set it to lastyears last week
            if (weekNum == 0) {
                let nYear = new Date(curDate.getFullYear() - 1, 0, 1);
                let year = nYear.getFullYear();

                // are we a leap year?
                if (((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
                    // on leap years, if we start on wednesday we have 53 weeks
                    if (nYear.getDay() === 3) {
                        weekNum = weekNum > 0 ? weekNum : 53
                    } else {
                        weekNum = weekNum > 0 ? weekNum : 52
                    }

                } else {
                    // on NONE leap years, if we start on thursday we have 53 weeks
                    if (nYear.getDay() === 4) {
                        weekNum = weekNum > 0 ? weekNum : 53
                    } else {
                        weekNum = weekNum > 0 ? weekNum : 52
                    }

                }

            }
        }

        return weekNum;
    }

    //#endregion

    // strapi functions
    //#region
    function getCompanies() {

        authAxios.get(`/crm/companies/all`)
            .then(({ data }) => {
                let compNes = [];
                data.sort((a, b) => (a.companyName > b.companyName) ? 1 : ((b.companyName > a.companyName) ? -1 : 0))
                for (let company of data) {

                    compNes.push({ value: company.id, label: company.name });
                }
                setCompanies(compNes);

            }).catch((exception) => {
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

                console.error(exception);
            })
    }
    function getAllTimersByDate(date) {
        // turn given date in to a workable datetime
        let startdate = new Date(date + "T00:00:00");

        // add a day then get date time part and pad if needed
        startdate.setDate(startdate.getDate() + 1);
        let endYear = startdate.getFullYear().toString();
        let endMonth = (startdate.getMonth() + 1).toString().padStart(2, '0');
        let endDay = startdate.getDate().toString().padStart(2, '0');

        // format datatime to yyyy-mm-dd
        let qformatEnd = endYear + "-" + endMonth + "-" + endDay;

        let updateDaySelector = { timersByDate: null }; //daySelector;
        // get all timers BETWEEN the given date and day after
        //"/time-registrations/all?populate=*&filters[$and][0][startTime][$gt]=" + date + "&filters[$and][1][startTime][$lt]=" + qformatEnd + "&filters[$and][2][user]=" + authContext.authState.user.id
        authAxios.get("/time-registrations/all?populate=*&filters[$and][0][startTime][$gt]=" + date + "&filters[$and][1][startTime][$lt]=" + qformatEnd + "&filters[$and][2][user]=" + authContext.authState.user.id).then(({ data }) => {

            updateDaySelector.timersByDate = data;
            //console.log(updateDaySelector)
            setKnowTimers(data);

            // empty know blocks if we dont have new results to replace them with
            let empty = [];

            setRowBlocks([]);

            // get start and end points from timestamps, we can use these to color the table
            let updateTimerBlocks = [];
            for (let timer of data) {

                let timerPoints = timeStampsToTimeBox(timer.startTime, timer.endTime);
                let points = { start: timerPoints.start, end: timerPoints.end, id: timer.id, isOngoing: timerPoints.isOngoing };

                updateTimerBlocks.push(points);
            }

            setTimeBlocks(updateTimerBlocks);
            // setTimers(knowTimers?.timersByDate);
            // setPacket();

        }).catch((e) => {

            toast.error('Er ging iets miss bij het ophalen van gaande timers');

            console.error(e);
        });
    }
    function deleteTimer(e) {
        e.preventDefault();

        // check fir missInput
        if (!window.confirm("Weet je zeeker dat je dit blok wilt verwijderen?")) {
            return;
        }

        // delete entry and update the table view
        authAxios.delete("/time-registrations/" + e.target.id).then(() => {
            toast.success('Verwijderd.');
            reAquireTable();

            //setUpdateBlocks(true);
            // setAquireOngoing();

        }).catch((e) => {

            toast.error('Er ging iets fout met het verwijderen.');

            console.error(e);
        });

    }
    //#endregion

    // event handlers
    //#region
    function dayButtonPressed(e) {
        e.preventDefault();
        let updateDaySelector = daySelector;

        // set week number
        let curDate = new Date();
        let year = new Date(curDate.getFullYear(), 0, 1);
        let days = Math.floor((curDate - year) / (24 * 60 * 60 * 1000));
        //updateDaySelector.selectedWeek = Math.ceil((curDate.getDay() + 1 + days) / 7);
        updateDaySelector.selectedWeek = getWeekNumber(curDate, 1)
        //getAllTimersByDate(updateDaySelector.selectedDate);

        // update button
        if (e?.target?.name.startsWith("timer_")) {

            let currentDate = new Date(updateDaySelector.selectedDate);

            let step = e.target.value - updateDaySelector.selectedDay;
            currentDate.setDate(currentDate.getDate() + step);

            let year = currentDate.getFullYear().toString();
            let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');;
            let day = currentDate.getDate().toString().padStart(2, '0');
            updateDaySelector.selectedDate = year + "-" + month + "-" + day;


            updateDaySelector.selectedDay = Number(e.target.value);
        }

        // if datetime changed
        if (e?.target?.name === 'selectDate') {

            // get changed time
            updateDaySelector.selectedDate = e.target.value;
            // get the current day (0-6)
            updateDaySelector.selectedDay = new Date(e.target.value).getDay();

            // get and set weeknumber
            let curDate = new Date(e.target.value);
            let year = new Date(curDate.getFullYear(), 0, 1);
            let days = Math.floor((curDate - year) / (24 * 60 * 60 * 1000));
            updateDaySelector.selectedWeek = getWeekNumber(curDate, 1);
        }

        // set selected day values
        setDaySelector(updateDaySelector);

        getAllTimersByDate(updateDaySelector.selectedDate);
        // update the datePicker and buttons with the new date

        // render changes
        updateBlock();
    }
    function elementPressed(e, index) {
        let updateTimeBlock = addTimeblock;

        // if left mouse button i used add block
        if (e.button === 0 && e.target.id === "") {
            // if clicked is false get the index of pressed row and store it,
            // if true, create a timer with an api reqeust
            if (updateTimeBlock.clicked === false) {
                updateTimeBlock.clicked = true;
                updateTimeBlock.points = { start: index, end: null };
                setAddTimeblock(updateTimeBlock);
                createTableRows();
            } else {
                // // get total points so we modify it
                // let updateTimerBlocks = timeBlocks;

                // switch values for the next step if needed
                if (index < updateTimeBlock.points.start) {
                    updateTimeBlock.points = { start: index, end: updateTimeBlock.points.start };
                } else {
                    updateTimeBlock.points = { start: updateTimeBlock.points.start, end: index };
                }

                // set clicked to false, so we can await next user input
                updateTimeBlock.clicked = false;

                setAddTimeblock(updateTimeBlock);
                createTableRows();
                // give the user the popup to add the timer
                openPopup('add-day-timer');
            }
        }
    }
    function editTimers(e) {
        e.preventDefault();
        setEditTimer({
            id: e.target.id,
            companies: knowCompanies
        });
        openPopup('edit-timer');
    }

    //#endregion
    return (<Block name={"dayView"} className="TimeAccouting__table">

        {variableBlock === undefined ? 'Loading...' : variableBlock}

    </Block>)
}
