import {useEffect, useRef, useState} from "react";
import debounce from "lodash/debounce";
import {openPopup} from "UI/App/Components/Popup/Popup";
import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import useWindowDimensions from "App/Util/useWindowDimensions";
import {formatTime} from "../../Helpers";

export default function Draggable({ name,allPlannedItems, visualizeDependencies, item, position, onPositionChange, itemList, identifier, colorIndex, removeDependency, createDependency, createAllDependency, isFirstItem, isLastItem, setPopupCurrentMachine, setPopupFormData, type, id, isContent, chainLength, showDependencyOptions, isDependencyOption, setIsShowMode, isShowMode, setShowModeActivator, expand, shrink}) {
    // the item that is currently being dragged
    const [draggingItem, setDraggingItem] = useState(null);
    // the position of the item in the grid
    const [draggingX, setDraggingX] = useState(position);
    // previous position of the item
    const [prevDraggingX, setPrevDraggingX] = useState(draggingX);
    // size in px from left of the screen to the dragged item
    const [initialDragX, setInitialDragX] = useState(0);
    const [elementWidth, setElementWidth] = useState(0);
    const [singleElementWidth, setSingleElementWidth] = useState(0);
    const [marginDef] = useState(10);
    const [errorPopupVisible, setErrorPopupVisible] = useState(false);
    const [initialResizePosition, setInitialResizePosition] = useState();
    const {width} = useWindowDimensions();
    const errorPopup = useRef(null);
    const debounceHandleDrag = debounce(handleDrag, 1);

    let isConflicted = false;

    // handle conflicts div position
    useEffect(() => {
        if (errorPopup.current && errorPopupVisible) {
            const errorPopupHeight = errorPopup.current.getBoundingClientRect().height;
            // set div to above machine
            errorPopup.current.style.top = `-${errorPopupHeight}px`;

            // get updated y value
            const errorPopupYtop = errorPopup.current.getBoundingClientRect().y;

            // if conflict div y position is lower than header height
            if (errorPopupYtop < 60) {
                // set div to below machine
                errorPopup.current.style.top = '30px';
            }
        }
    }, [errorPopupVisible]);

    useEffect(() => {
        if (draggingX !== prevDraggingX) {
            onPositionChange(draggingX, draggingItem, prevDraggingX);
        }
    }, [draggingX])

    useEffect(() => {
        setPrevDraggingX(draggingX)
        setDraggingX(position)
    }, [position])

    useEffect(() => {
        if (item) {
            const element = document.getElementById(item.uuid);
            // element && setElementWidth((element.clientWidth - marginDef * 2) * (item.endDay - item.startDay + 1));
            element && setElementWidth((element.clientWidth - marginDef * 2) * chainLength);
            element && setSingleElementWidth((element.clientWidth));
        }
    }, [width]);

    function handleDragStart(event, item) {
        // Prevent dragging through the arrows
        event.stopPropagation();
        if (event.target !== event.currentTarget) {
            return;
        }
        // set custom drag image to remove the ghost object when dragging
        const customDragImage = document.createElement('div');
        customDragImage.setAttribute('id', 'custom-drag-image')
        customDragImage.classList.add('custom__drag__image');
        document.body.appendChild(customDragImage);
        event.dataTransfer.setDragImage(customDragImage, 0, 0);

        setDraggingItem(item);
        setInitialDragX(event.target.getBoundingClientRect().left - (event.target.clientWidth * (draggingX - 1)));
    }

    function handleDragEnd(event) {
        // remove the custom drag image
        try {
            document.body.removeChild(document.getElementById('custom-drag-image'));
        } catch (err) {
        }
        event.preventDefault();
        setDraggingItem(null);
    }

    function handleDrag(event) {
        if (draggingItem && event.clientX !== 0) {
            // The elements that the item can be dragged to, to move to the previous or next week
            const moveToPrevWeekDropElement = document.querySelector('.planning__content__item__filler:nth-child(2)');
            const moveToNextWeekDropElement = document.querySelector('.planning__content__item__filler:last-of-type');

            // If none of the elements exist, return
            // This means that there are no planned items this week
            if (!moveToPrevWeekDropElement || !moveToNextWeekDropElement) {
                return;
            }

            // The X position of the elements that the mouse should be hovered over to move to the previous or next week
            const moveToPrevWeekDropElementX = moveToPrevWeekDropElement.getBoundingClientRect().x + moveToNextWeekDropElement.clientWidth;
            const moveToNextWeekDropElementX = moveToNextWeekDropElement.getBoundingClientRect().x;

            // width of the selected object
            const objectWidth = event.target.clientWidth;
            // position of the mouse
            const mouseX = (event.clientX - initialDragX + objectWidth);
            // position the mouse is being hovered over
            const hoverPosition = Math.floor(mouseX / objectWidth);
            // set values
            if (event.clientX < moveToPrevWeekDropElementX) {
                setPrevDraggingX(draggingX)
                setDraggingX(-1)
            } else if (event.clientX > moveToNextWeekDropElementX) {
                setPrevDraggingX(draggingX)
                setDraggingX(8)
            } else if (hoverPosition > 0 && hoverPosition <= 7 && hoverPosition !== draggingX) {
                setPrevDraggingX(draggingX)
                setDraggingX(hoverPosition)
            }else{
                setPrevDraggingX(draggingX)
                setDraggingX(hoverPosition)
            }
        }
    }

    function loadFlowLines() {
        // only show flow lines if the item has dependents
        if (!Array.isArray(item.dependents) || item.dependents?.length === 0 || visualizeDependencies === false) {
            return null;
        }


        const dependents = [...item.dependents];
        return dependents.map((dependent, index) => {
            const zIndex = isNaN(100 - dependent.height) ? 100 : 100 - dependent.height;
            const commonProps = {
                onContextMenu: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeDependency(item, dependent.uuid);
                },
                draggable: true,
                onDragStart: (e) => e.preventDefault(),
                onClick: (e) => e.stopPropagation(),
                key: item.uuid + '-' + index,
            };

            // For dependents on the same row
            if (dependent.yOffset === 0) {
                if (dependent.xOffset > 0) {
                    return <ArrowDirect key={index} dependent={dependent} zIndex={zIndex} commonProps={commonProps}/>;
                } else {
                    return <ArrowDirectNegative key={index} dependent={dependent} zIndex={zIndex} commonProps={commonProps}/>;
                }
            }
            // For dependents in the same column
            else if (dependent.xOffset === 0) {
                return <ArrowLeveled key={index} dependent={dependent} zIndex={zIndex} commonProps={commonProps}/>
            }
            // For dependents in the previous column
            else if (dependent.xOffset < 0) {
                return <ArrowBack key={index} dependent={dependent} zIndex={zIndex} commonProps={commonProps}/>
            }
            // For all others
            else {
                return <Arrow key={index} dependent={dependent} zIndex={zIndex} commonProps={commonProps}/>;
            }
        });
    }

    function ArrowDirect({dependent, zIndex, commonProps}) {
        return (
            <div
                {...commonProps}
                className="dependency__arrow__direct"
                style={{
                    width: (dependent.xOffset - 1) * singleElementWidth + (dependent.xOffset - 1 === 0 ? 5 : -8),
                    zIndex: zIndex
                }}
            />
        )
    }

    function ArrowDirectNegative({dependent, zIndex, commonProps}) {
        return (
            <div
                {...commonProps}
                className="dependency__arrow__direct__negative"
                style={{
                    width: (Math.floor(Math.abs(dependent.xOffset) - 1) * singleElementWidth + 5) - 10,
                    left: -Math.floor(Math.abs(dependent.xOffset) - 1) * singleElementWidth + 5,
                    zIndex: zIndex
                }}
            />
        )
    }

    function ArrowLeveled({dependent, zIndex, commonProps}) {
        return (
            <div
                {...commonProps}
                className={dependent.yOffset > 0 ? "dependency__arrow__leveled" : "dependency__arrow__leveled__negative"}
                style={{
                    height: 80 + ((Math.abs(dependent.yOffset) - 1) * 87),
                    zIndex: zIndex,
                }}
            />
        )
    }

    function Arrow({dependent, zIndex, commonProps}) {
        return (
            <div
                {...commonProps}
                className={dependent.yOffset > 0 ? "dependency__arrow" : "dependency__arrow__negative"}
                style={{
                    zIndex: zIndex,
                    width: (dependent.xOffset - 1) * singleElementWidth + 75,
                    height: 50 + ((Math.abs(dependent.yOffset) - 1) * 87),
                }}
            />
        )
    }

    function ArrowBack({dependent, zIndex, commonProps}) {
        return (
            <div
                {...commonProps}
                className={dependent.xOffset > 0 ? "dependency__arrow" : dependent.yOffset > 0 ? "dependency__arrow__reverse" : "dependency__arrow__double__reverse"}
                style={{
                    zIndex: zIndex,
                    width: (Math.abs(dependent.xOffset + 1)) * singleElementWidth + 75,
                    height: 50 + ((Math.abs(dependent.yOffset) - 1) * 87),
                }}
            />
        )
    }

    function loadConflicts(returnValue = false) {
        // Check if more than 8 hours are scheduled
        let conflict = false;
        let conflictText = '';

        // If item has more than 8 hours scheduled
        if (item.time > 8) {
            conflict = true
            conflictText += `Meer dan 8 uur ${formatTime(item.time, true)} ingepland op deze dag.`
        }

        let totalTime = 0;
        for(const plannedMachine of allPlannedItems) {
            if (plannedMachine.id === item.parentId) {
                for (const plannedItem of plannedMachine.planning) {
                    if (plannedItem !== null) {
                        totalTime += Number(plannedItem.time)
                    }
                }
            }
        }

        if(item.totalHours !== parseFloat(totalTime.toFixed(2))){
            conflict = true;
            conflictText += `Geplande tijd niet gelijk aan benodigde tijd (${formatTime(totalTime, false)}/${formatTime(item.totalHours, false)}). \n`
        }

        // If return value retun value instead of HTML
        if(returnValue) return conflict;
        if(conflict) {
            isConflicted = true;
        }
        return(
            conflict ? <div className="conflict" data-conflict-text={conflictText}>
                <Icon name="white-circle-exclamation" className="conflict__icon"></Icon>
                <div className="conflictPopup">
                    {conflictText}
                </div>
            </div> : <></>
        )
    }

    function loadItemContent() {
        return (
            <div
                className="planning__content__bar__item__content"
                style={{
                    zIndex: 1,
                }}
            >

                {<span className="planning__content__item__content__text__tooltip">{name} {formatTime(item.time)}</span>}
                <div className="row">
                    <b>{`[${item.number}]`}</b>
                </div>
                <div className="row">
                    <b>{name !== undefined ? name : item.name}</b>
                </div>
                <div className="row">
                    {formatTime(item.time)}
                </div>
            </div>
        )
    }

    function getZIndexValue() {
        if (isShowMode && isDependencyOption) {
            return 101;
        } else {
            if (isFirstItem && errorPopupVisible) {
                return 2;
            } else {
                return 'auto';
            }
        }
    }

    function loadResizeOptions() {
        if (!isLastItem) {
            return null;
        }

        let isExecuted = false;

        function handleDragStart(e) {
            setInitialResizePosition(e.clientX);
        }

        function handleDrag(e) {
            e.stopPropagation();
            if (!isExecuted && initialResizePosition && e.clientX > 0) {
                const trigger = (elementWidth / chainLength + marginDef * 2) / 2
                if ((e.clientX - initialResizePosition >= trigger)) {
                    isExecuted = true;
                    expand();
                    handleDragEnd();
                }
                if (!isFirstItem && (e.clientX - initialResizePosition <= 0 - trigger)) {
                    isExecuted = true;
                    shrink();
                    handleDragEnd();
                }
            }
        }

        function handleDragEnd(e) {
            setInitialResizePosition(undefined)
        }

        return (
            <div
                className={`clickable__resize bg-${colorIndex}-dark`}
                id="clickable__resize"
                draggable={true}
                onDragStart={(e) => handleDragStart(e)}
                onDrag={(e) => handleDrag(e)}
                onDragEnd={(e) => handleDragEnd(e)}
            >|</div>
        )
    }

    return (
        <IF condition={isContent}>
            <div
                id={item.uuid}
                className={`planning__content__bar__item bg-${colorIndex}`}
                draggable='true'
                onDragStart={(e) => handleDragStart(e, item)}
                onDragEnd={(e) => handleDragEnd(e)}
                onDrag={(e) => debounceHandleDrag(e)}
                style={{
                    gridColumn: `${draggingX} / span 1`, // set grid positioning of the item based on variable
                    zIndex: getZIndexValue(),
                }}
                onClick={(e) => {
                    if (isShowMode) {
                        e.stopPropagation();
                        if(type === 'all') {
                            createAllDependency(item);
                        } else {
                            createDependency(item);
                        }
                    } else {
                        setPopupCurrentMachine({
                            machineId: item.machineId,
                            projectId: item?.projectId,
                            name: item.name,
                            uuid: item.uuid,
                            time: item.time,
                            totalHours: item.totalHours,
                            priority: item.priority,
                            progress: item.progress,
                            persons: item.persons,
                            amountProduced: item.amountProduced,
                            note: item.note,
                        });
                        setPopupFormData({});
                        openPopup('draggable-click-popup');
                    }
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsShowMode((prev) => !prev);
                    setShowModeActivator(item);
                    showDependencyOptions(item, isShowMode);
                }}
            >
                {loadConflicts()}
                {loadItemContent()}
                {loadResizeOptions()}
                {loadFlowLines()}
            </div>
        </IF>
    )
}
