import {Link} from "react-router-dom";
import NavigationMenu from "./NavigationMenu";
import Icon from "UI/App/Components/Icon/Icon";
import {useEffect} from "react";

export default function NavigationLink({ item, activeRoute, collapsable, collapsed, setCollapsed, closeNav }) {

    useEffect(() => {
        if (item?.subMenu && item.subMenu?.map(i => i.path).includes(activeRoute)) {
            setCollapsed(item.id);
        }
    }, [])

	// create a new variable to potentially be filled with a submenu
	let subMenu;

	// check if the item contains a submenu
	if (item.subMenu) {
		subMenu = <NavigationMenu items={item.subMenu} activeRoute={activeRoute} isSubMenu collapsed={collapsed} setCollapsed={setCollapsed} parentId={item.id} closeNav={closeNav} />
	}

	/**
	 * Toggles whether the submenu is collapsed or not
	 * @param {object} e
	 */
	function toggleSubMenu(e) {
		e.preventDefault();
        e.stopPropagation();
		setCollapsed(collapsed === item.id ? false : item.id);
	}

    // return non-clickable navigation item if the item has no path and is collapsable
    if (item.path === null && collapsable) {
        return (
            <>
                <label
                    key={item.id}
                    title={item.label}
                    className={`
                        navigation__item 
                        navigation__item--collapsed 
                        ${(activeRoute === item.path) && !item.subMenu?.map(i => i.path).includes(activeRoute) ? 'navigation__item--active' : ''} 
                        ${item.subMenu?.map(i => i.path).includes(activeRoute) ? 'navigation__item--sub-active' : ''} 
                        ${collapsed === item.id && subMenu ? 'navigation__item--sub-open' : ''}
                    `}
                >
                    <Icon name={item.icon} className='navigation__item__icon' />
                    <span className='navigation__item__name'>{item.label}</span>
                    {
                        collapsable &&
                        <span className={`navigation__item__submenu-toggle ${collapsed === item.id ? 'rotate' : ''}`} onClick={toggleSubMenu}>
                            <Icon name='angle-down' />
                        </span>
                    }
                </label>
                {subMenu ? subMenu : null}
            </>
        )
    } else {
        return (
            <>
                <Link
                    key={item.id}
                    to={item.path === null && collapsable ? '#' : item.path}
                    title={item.label}
                    replace={true}

                    onClick={closeNav}
                    className={`
                        navigation__item 
                        navigation__item--collapsed 
                        ${(activeRoute === item.path) && !item.subMenu?.map(i => i.path).includes(activeRoute) ? 'navigation__item--active' : ''} 
                        ${item.subMenu?.map(i => i.path).includes(activeRoute) ? 'navigation__item--sub-active' : ''} 
                        ${collapsed === item.id && subMenu ? 'navigation__item--sub-open' : ''}
                    `}
                >
                    <Icon name={item.icon} className='navigation__item__icon' />
                    <span className='navigation__item__name'>{item.label}</span>
                    {
                        collapsable &&
                        <span className={`navigation__item__submenu-toggle ${collapsed === item.id ? 'rotate' : ''}`} onClick={toggleSubMenu}>
                            <Icon name='angle-down' />
                        </span>
                    }
                </Link>
                {subMenu ? subMenu : null}
            </>
        )
    }
}