import JobTicketField from "UI/App/Partials/Content/CRM/Workflow/Components/JobTicketField";

export default function JobTicketGroupContent({ jobTicketGroup, orderData, calculationId, register, control, isVisible }) {

    return (
        <div className={`job-ticket__group-content ${!isVisible ? 'hidden' : ''}`}>
            {jobTicketGroup.fields.map(field => (
                <JobTicketField
                    key={field.id}
                    groupName={jobTicketGroup.name.toLowerCase()}
                    orderNumber={orderData?.number}
                    customerReference={orderData?.customerReference}
                    calculationId={calculationId}
                    field={field}
                    register={register}
                    control={control}
                />
            ))}
        </div>
    )
}