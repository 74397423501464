import {RouterProvider} from "react-router-dom";
import router from "./router";
import spinner from "UI/Assets/Spinners/spinner-primary.svg";
import "App/Util/console";

export default function App() {
	return (
		<RouterProvider
			router={router}
			fallbackElement={
				<div style={{
					display: 'flex',
					width: '100vw',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
					<img className="spinner" src={spinner} width="50px" height="50px" alt='spinner' />
				</div>
			}
		/>
	)
}
