import {getExpirationTimeFromJWT} from "App/Strapi/StrapiAuthenticator";
import {publicFetch} from "App/Util/fetch";
import axios, {AxiosInstance} from "axios";
import qs from "qs";

export default class MSGraph {
    private _graphDisconnected = false;
    private _graphDisabled = false;
    private _accessToken: string = '';
    private _refreshToken: string = '';
    private _expiresAt: number = 0;
    private _setAuthInfo: (info: Record<string, any>) => any;
    private _expirationTimeout: NodeJS.Timeout | undefined;
    private _api: AxiosInstance;

    constructor(
        accessToken: string,
        refreshToken: string,
        expiresAt: number,
        setAuthInfo: (info: Record<string, any>) => any
    ) {
        this._accessToken = accessToken;
        this._refreshToken = refreshToken;
        this._expiresAt = expiresAt;
        this._setAuthInfo = setAuthInfo;

        if (localStorage.getItem('MSDisabled') === 'true') {
            this._graphDisabled = true;
        }

        this._api = axios.create({
            baseURL: 'https://graph.microsoft.com/v1.0',
        });

        this._api.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${this._accessToken}`;

            return config;
        });

        this._refreshBeforeExpiration();
    }

    public disable() {
        this._log(`Connection with Microsoft Graph disabled.`, 'warn');
        localStorage.setItem('MSDisabled', String(true));
        this._graphDisabled = true;
    }

    public enable() {
        this._log(`Connection with Microsoft Graph enabled.`);
        localStorage.removeItem('MSDisabled');
        this._graphDisabled = false;
    }

    public isDisabled() {
        return this._graphDisabled;
    }

    public isDisconnected() {
        return this._graphDisconnected;
    }

    public isReady() {
        if (this.isDisabled() || this.isDisconnected()) {
            return false;
        }

        if (this._expiresAt * 1000 - Date.now() <= 0) {
            return false;
        }

        return true;
    }

    public async delete(endpoint: string, headers: Record<string, any> = {}) {
        this._checkGraphDisabled();

        return this._api.delete( endpoint, {
            headers
        })
            .then(res => res.data)
            .catch(err => Promise.reject(err));
    }

    public async get(endpoint: string, params: any = {}, headers: Record<string, any> = {}) {
        this._checkGraphDisabled();

        const query = qs.stringify(params, {encodeValuesOnly: true, addQueryPrefix: true});

        return this._api.get( `${endpoint}${query}`, {
            headers
        })
            .then(res => res.data)
            .catch(err => Promise.reject(err));
    }

    public async post(endpoint: string, data: any = {}, headers: Record<string, any> = {}) {
        this._checkGraphDisabled();

        return this._api.post( endpoint, data, {
            headers
        })
            .then(res => res.data)
            .catch(err => Promise.reject(err));
    }

    public async put(endpoint: string, data: any = {}, headers: Record<string, any> = {}) {
        this._checkGraphDisabled();

        return this._api.put( endpoint, data, {
            headers
        })
            .then(res => res.data)
            .catch(err => Promise.reject(err));
    }

    public async patch(endpoint: string, data: any = {}, headers: Record<string, any> = {}) {
        this._checkGraphDisabled();

        return this._api.patch( endpoint, data, {
            headers
        })
            .then(res => res.data)
            .catch(err => Promise.reject(err));
    }

    public clearRefreshTimeout() {
        clearTimeout(this._expirationTimeout);
    }

    protected refreshAccessToken() {
        if (this._graphDisabled) {
            return;
        }

        this._log(`Refreshing access token...`);
        this._requestNewAccessToken().then((response) => {
            if (!response) {
                return Promise.reject('No response from server');
            }

            this._log(`Refreshing successful. \nToken: ${response.access_token} \n\nRefresh Token: ${response.refresh_token} \n\nExpires In: ${response.expires_in} \n\nExpires At: ${getExpirationTimeFromJWT(response.access_token)}`);
            this._graphDisconnected = false;

            this._writeTokenToAuthState();
            this._refreshBeforeExpiration();
        }).catch((err) => {

            // Clear timeout
            this.clearRefreshTimeout();

            if (err === 404) {
                if (process.env.NODE_ENV === 'development') {
                    this._log(`No access token found in local storage. This is expected behaviour on the login page and while logging in.`, 'warn');
                }
                return;
            }

            this._graphDisconnected = true;

            // Try again in 10 seconds...
            // This could in theory cause an infinite loop, but this will send a basic request every 10 seconds
            // Also this should only ever happen if the server is down, so in that case, we've got bigger problems
            this._expirationTimeout = setTimeout(() => {
                this.refreshAccessToken();
            }, 10000)

            this._log(err, 'error');
        });
    }

    private _checkGraphDisabled() {
        if (this._graphDisabled) {
            throw new Error('Connection with Microsoft Graph disabled.');
        }
    }

    private _refreshBeforeExpiration() {
        if (this._graphDisabled) {
            return;
        }

        // If the token expires now or in the past, refresh it
        if (this._expiresAt * 1000 - Date.now() - 10000 <= 0) {
            this.clearRefreshTimeout();
            this.refreshAccessToken();
            return;
        }

        this._log(`expiresAt: ${this._expiresAt}`);
        this._log(`timeout set to: ${((this._expiresAt * 1000) - Date.now() - 10000)} ms from now (${((this._expiresAt * 1000) - Date.now() - 10000) / 1000 / 60} min)`);

        this.clearRefreshTimeout();

        this._expirationTimeout = setTimeout(() => this.refreshAccessToken(), ((this._expiresAt * 1000) - Date.now() - 10000));
    }

    private async _requestNewAccessToken() {
        if (this._graphDisabled) {
            return;
        }

        const accessToken = localStorage.getItem('token');

        if (accessToken === null) {
            return Promise.reject(404);
        }

        return publicFetch.post(
            "/auth/refresh-graph-api",
            {
                refresh_token: this._refreshToken
            },
            {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }
        )
            .then(({
               data
            }: {
                data: {
                    access_token: string;
                    refresh_token: string;
                    expires_in: number;
                }
            }) => {
                this._accessToken = data.access_token;
                this._refreshToken = data.refresh_token;
                this._expiresAt = getExpirationTimeFromJWT(data.access_token);

                return Promise.resolve(data);
            })
            .catch((err) => {
                return Promise.reject(err);
            })
    }

    private _writeTokenToAuthState() {
        this._setAuthInfo({
            msToken: this._accessToken,
            msRefreshToken: this._refreshToken,
            msTokenExpiresAt: this._expiresAt
        });
    }

    private _log(message: any, type: 'log'|'warn'|'error' = 'log') {
        const color = {
            log: '#0078d4',
            warn: '#ffb900',
            error: '#d13438'
        }

        const name = {
            log: 'Info',
            warn: 'Warning',
            error: 'Error'
        }

        if (process.env.NODE_ENV === 'development') {
            console[type](`%c[MSGraph]%c [%c${name[type]}%c] ${message}`, `background-color: ${color[type]}; color: #fff; padding: 2px;`, '', `color: ${color[type]}`, '');
        } else {
            if (type === 'error') {
                console.error(`%c[MSGraph]%c ${message}`, `background-color: ${color[type]}; color: #fff; padding: 2px;`, '');
            }
        }
    }
}