import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import qs from "qs";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import ViewCalculation from "UI/App/Partials/Content/Calculations/Calculations/view";
import ViewHJMGCalculation from "UI/App/Partials/Content/Calculations/HJMG/ViewHJMGCalculation";
import NotFound from "UI/App/Partials/App/NotFound";

export default function ViewSwitcher() {
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    const [calculationType, setCalculationType] = useState(null);

    useEffect(() => {
        if (!params.calculationId) return;

        const query = qs.stringify({
            fields: [
                'id',
                'owner'
            ]
        });

        authAxios.get(`/calculations/${params.calculationId}?${query}`).then(({ data: { data } }) => {
            setCalculationType(data.owner.toLowerCase())
        }).catch((err) => {
            setCalculationType(false);
        })
    }, [params.calculationId]);


    if (calculationType === 'hjmg') {
        return <ViewHJMGCalculation key={params.calculationId} />
    } else if (calculationType === 'pretore') {
        return <ViewCalculation key={params.calculationId}/>
    } else if (calculationType === false) {
        return <NotFound />
    }

    return (
        <div style={{
            display: 'block',
            height: '100%',
            width: '100%'
        }}>
            <span>{calculationType}</span>
            <SpinnerOverlay visible={true} />
        </div>
    )
}
