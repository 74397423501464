import React from "react";
import PDF from "../../../../../../App/Util/print";
import { getNumLines } from "App/Util/getVisibleTextWidth";
import { formatCurrency, mmToPx } from "App/Util/format";
import { formatDataForPDF } from "App/Util/pdf";

export default class ConfirmationPDFGenerator extends React.Component {
    background;
    rowData;
    chosenContactPerson;
    authAxios;
    date;
    showPdf;
    language;
    quoteData;
    owner;
    shippingAdres;
    shipment;
    deliveryTime;
    price;
    shippingCost;
    signature;
    chosenAmount;

    constructor(selectedBackground, rowData, quoteData, company, owner, contactPerson, shippingCost, shippingAdres, shipment, deliveryTime, price, showPdf = false, language = 'nl', date, signature, placeOfOrigin = '', introLine = '', footerData = [], footerData2 = [], footerText = '', chosenAmount = '') {
        super();
        this.background = selectedBackground;
        this.rowData = rowData
        this.company = company
        this.chosenContactPerson = contactPerson;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.showPdf = showPdf;
        this.language = language;
        this.quoteData = quoteData;
        this.owner = owner;
        this.shippingAdres = shippingAdres
        this.shipment = shipment
        this.deliveryTime = deliveryTime
        this.price = typeof price === 'number' ? price.toString() : price
        this.date = date;
        this.shippingCost = typeof shippingCost === 'number' ? shippingCost.toString() : shippingCost;
        this.signature = signature;
        this.placeOfOrigin = placeOfOrigin;
        this.introLine = introLine;
        this.footerData = footerData;
        this.footerData2 = footerData2;
        this.footerText = footerText;
        this.chosenAmount = chosenAmount;
    }

    async formatForPdf(data, preformatted = false) {
        return formatDataForPDF({
            data: data,
            company: this.company,
            contactPerson: this.chosenContactPerson,
            isDataPreFormatted: preformatted,
            signature: this.signature,
            headerRowCount: 9 + getNumLines((this.introLine ?? ''), '10pt helvetica', mmToPx(210 - 21 - 21)),
            footerRowCount: 13 + this.footerData.length + this.footerData2.length + getNumLines((this.footerText ?? ''), '10pt helvetica', mmToPx(210 - 21 - 21))
        });
    }

    async getPDF() {
        // let lineHeight = 3.852;
        let lineHeight = 4;
        let currentlineHeight = 50;
        let paddingLeft = 20;
        let pageCutoffXCords = 295;
        let fontSize = 10;

        /**
         * Add background
         */
        var doc = new PDF();
        if (this.background?.page1) {
            doc.addImage(this.background.page1, 'JPEG', 0, 0, 210, 297);
        }

        doc.setFont('Helvetica', 'normal');

        // Add title
        doc.setFontStyle('bold');
        doc.setFontSize(fontSize);
        doc.text(this.language === 'NL' ? 'Bevestiging' : 'Confirmation', paddingLeft, currentlineHeight);

        currentlineHeight += (lineHeight * 2);
        doc.setFontStyle('normal');

        // Add supplier
        if (this.quoteData.company) {

            // Add title
            // supplier name
            if (this.quoteData.company.name) {
                doc.text(this.quoteData.company.name, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            if (this.quoteData.contactPerson) {
                doc.text(`${this.quoteData.contactPerson.firstName}${this.quoteData.contactPerson.prefixToSurname !== null ? ` ${this.quoteData.contactPerson.prefixToSurname.trim()} ` : ' '}${this.quoteData.contactPerson.surname}`, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }


            // supplier addressLine1
            if (this.quoteData.company.address?.addressLine1) {
                doc.text(this.quoteData.company.address?.addressLine1, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier addressLine2
            if (this.quoteData.company.address?.addressLine2) {
                doc.text(this.quoteData.company.address?.addressLine2, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier addressLine3
            if (this.quoteData.company.address?.addressLine3) {
                doc.text(this.quoteData.company.address?.addressLine3, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier postal & city
            if (this.quoteData.company.address?.postalCode || this.quoteData.company.address?.city) {
                // double space is in original document
                doc.text(
                    `${this.quoteData.company.address?.postalCode ?? ''}  ${this.quoteData.company.address?.city ?? ''
                        }`
                        .trim()
                        .toUpperCase(),
                    paddingLeft,
                    currentlineHeight
                );
                currentlineHeight += lineHeight;
            }

            // supplier country
            if (this.quoteData.company.address?.country) {
                doc.text(this.quoteData.company.address?.country, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }
        }


        currentlineHeight += (lineHeight * 3);

        let formattedDate = '';
        if (this.date !== undefined) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const locale = this.language === 'NL' ? 'nl-NL' : 'en-US';

            // Makes sure you don't get 'Invalid date' on the frontend
            if (typeof this.date === 'string') {
                formattedDate = (new Date(this.date)).toLocaleDateString(locale, options)
            }
        }


        // the date
        doc.text(
            `${this.placeOfOrigin}, ${formattedDate}`,
            paddingLeft,
            currentlineHeight
        );
        currentlineHeight += (lineHeight * 3);

        doc.text(`${this.language === 'NL' ? 'Beste' : 'Dear'} ${this.quoteData.contactPerson.firstName},`, paddingLeft, currentlineHeight);
        currentlineHeight += (lineHeight * 2);

        if (this.introLine !== '') {
            doc.text(this.introLine,
                paddingLeft,
                currentlineHeight,
                {
                    maxWidth: 210 - 21 - 21
                }
            );
            const lineCount = getNumLines(this.introLine, '10pt helvetica', mmToPx(210 - 21 - 21))
            currentlineHeight += lineHeight + (lineHeight * lineCount);
        }

        // let formattedRows = await this.formatForPdf(this.rowData)

        // Add data formattedRows
        let currentPage = 0;
        if (this.rowData.length !== 0) {
            for (const rows of this.rowData) {
                if (currentPage !== 0) {
                    currentlineHeight = 50;
                    doc.addPage()
                    if (this.background?.page2) {
                        doc.addImage(this.background.page2, 'JPEG', 0, 0, 210, 297);
                    }
                }

                for (const row of rows) {

                    // Add a new page if the row doesn't fit properly on the current page
                    const totalRowHeight = getNumLines(row.content, '10pt helvetica', mmToPx(120)) * lineHeight;
                    // currentlineHeight = doc.addNewPageIfOverflown(currentlineHeight, 15 + totalRowHeight, 20);

                    //First part of row
                    doc.text(row.label, paddingLeft, currentlineHeight);

                    // Only add spacer if label is set
                    if (row.label !== '') {
                        // Add spacer
                        doc.text(`:`, paddingLeft + 45, currentlineHeight);
                    }

                    if (row?.type === "price") {
                        let leadingSymbol = row.content.substring(0, 1);
                        let price = row.content.substring(1);
                        doc.text(`${leadingSymbol}`, paddingLeft + 145, currentlineHeight);
                        doc.text(formatCurrency(price).slice(1), paddingLeft + 170, currentlineHeight, 'right');
                    } else {
                        // Add value
                        doc.text(row.content, paddingLeft + 50, currentlineHeight, {
                            maxWidth: 120
                        });
                    }

                    // currentlineHeight += totalRowHeight;

                    currentlineHeight += totalRowHeight;
                    currentlineHeight += 1;
                }

                currentPage++;
            }
        }
        currentlineHeight += (lineHeight * 2);

        doc.text(this.language === 'NL' ? `Prijs voor ${this.chosenAmount} stuks` : `Price for ${this.chosenAmount} pieces`, paddingLeft, currentlineHeight);
        doc.text(`€`, paddingLeft + 145, currentlineHeight);
        doc.text(formatCurrency(this.price).slice(1), paddingLeft + 170, currentlineHeight, 'right');
        currentlineHeight += lineHeight;

        doc.text(this.language === 'NL' ? 'Verzendkosten excl BTW' : 'Shipping excl VAT', paddingLeft, currentlineHeight);
        doc.text(`€`, paddingLeft + 145, currentlineHeight);
        doc.text(formatCurrency(this.shippingCost).slice(1), paddingLeft + 170, currentlineHeight, 'right');
        currentlineHeight += (lineHeight * 1);

        doc.setFontStyle('bold');

        currentlineHeight += (lineHeight * 2);
        doc.text(this.language === 'NL' ? 'Voorwaarden' : 'Terms', paddingLeft, currentlineHeight);
        currentlineHeight += lineHeight;
        doc.setFontStyle('normal');

        doc.text(this.language === 'NL' ? 'Levertijd' : 'Delivery time\n', paddingLeft, currentlineHeight);
        doc.text(`:`, paddingLeft + 45, currentlineHeight);
        doc.text(this.deliveryTime, paddingLeft + 50, currentlineHeight);
        currentlineHeight += lineHeight;

        // Add footer rows
        for (const row of this.footerData) {
            // con 1
            doc.text(
                row.label,
                paddingLeft,
                currentlineHeight
            );
            doc.text(`:`, paddingLeft + 45, currentlineHeight);
            doc.text(
                row.content,
                paddingLeft + 50,
                currentlineHeight
            );
            currentlineHeight += (lineHeight * 1);
        }

        doc.setFontStyle('bold');
        currentlineHeight += lineHeight;
        doc.text('Transport', paddingLeft, currentlineHeight);
        currentlineHeight += lineHeight;
        doc.setFontStyle('normal');

        doc.text(this.language === 'NL' ? 'Verzending' : 'Shipping', paddingLeft, currentlineHeight);
        doc.text(`:`, paddingLeft + 45, currentlineHeight);
        doc.text(this.shipment, paddingLeft + 50, currentlineHeight);
        currentlineHeight += lineHeight;

        doc.text(this.language === 'NL' ? 'Afleveradres' : 'Address', paddingLeft, currentlineHeight);
        doc.text(`:`, paddingLeft + 45, currentlineHeight);
        doc.text(this.shippingAdres, paddingLeft + 50, currentlineHeight);
        currentlineHeight += lineHeight;

        // Add footer rows
        for (const row of this.footerData2) {
            // con 1
            doc.text(
                row.label,
                paddingLeft,
                currentlineHeight
            );
            doc.text(`:`, paddingLeft + 45, currentlineHeight);
            doc.text(
                row.content,
                paddingLeft + 50,
                currentlineHeight
            );
            currentlineHeight += (lineHeight * 1);
        }

        currentlineHeight += (lineHeight * 1);

        doc.text(this.footerText, paddingLeft, currentlineHeight, {
            maxWidth: 210 - 21 - 21
        });

        currentlineHeight += getNumLines(this.footerText, '10pt helvetica', mmToPx(210 - 21 - 21)) * lineHeight;

        currentlineHeight += lineHeight;

        doc.text(
            this.language === 'NL' ? 'Met vriendelijke groet,' : `Kind regards,`,
            paddingLeft,
            currentlineHeight
        );
        currentlineHeight += lineHeight;

        if (this.owner === "pretore") {
            doc.text(
                'Pretore B.V.',
                paddingLeft,
                currentlineHeight
            );
        }
        if (this.owner === "hjmg") {
            doc.text(
                'HJ Media Groep B.V.',
                paddingLeft,
                currentlineHeight
            );
        }

        currentlineHeight += lineHeight * 2;

        if (this.signature) {
            const prevlineHeight = currentlineHeight;

            const imageHeight = lineHeight * 4;

            // calculate if section needs to go to the next page
            const currentLineYPosisition = doc.addNewPageIfOverflown(currentlineHeight + imageHeight, 21, 50, this.background.page2);
            currentlineHeight =
                currentLineYPosisition > prevlineHeight ? prevlineHeight : currentLineYPosisition;

            // add the image to the correct lineHeight
            doc.addImage(this.signature, undefined, paddingLeft, currentlineHeight, 0, imageHeight);

            // set the currentlineHeight to below the image
            currentlineHeight += imageHeight + lineHeight;
        }

        currentlineHeight += lineHeight;

        if (this.userInfo !== undefined) {
            doc.text(
                `${this.userInfo.firstName} ${this.userInfo.lastName}`,
                paddingLeft,
                currentlineHeight
            );
        }

        if (this.showPdf) {
            doc.openPDF()
        }

        return window.btoa(doc.output());
    }
}
