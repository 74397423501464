import User from "App/Strapi/User";

export default function ExcludeRole({ roles, children }: { roles: string[], children: any }) {
    const hasRole = User.hasRole(roles);

    if (!hasRole) {
        return children;
    } else {
        return null;
    }
}