import {FetchContext} from "App/Strapi/FetchContext";
import {useContext, useEffect, useState} from 'react';
import 'UI/App/Partials/Content/CRM/Quotes/quote.scss';
import Button from 'UI/App/Components/Button/Button';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import 'react-quill/dist/quill.snow.css';
import 'UI/App/Css/pdf-mockup.scss';
import {AuthContext} from "App/Strapi/AuthProvider";
import {formatName} from "App/Util/format";
import IF from "UI/App/Components/Conditional/IF";
import useWindowDimensions from "App/Util/useWindowDimensions";
import PurchaseQuoteGenerator from "./PurchaseQuoteGenerator";

export default function PurchaseQuotePdfEditor({ formattedRows, setFormattedRows, rowData, setRowData, date, setDate, extraInformation, setExtraInformation, chosenSupplier, chosenContactPerson, language, selectedBackground, selectedBackgroundPage2, number, resizeTrigger = null, signature, quantity }) {

    // Default states
    const {authAxios} = useContext(FetchContext);
    const {authState} = useContext(AuthContext);

    // States for rendering
    const [supplier, setSupplier] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);

    const {width} = useWindowDimensions();
    const [zoom, setZoom] = useState(1);
    const [lastRowInfo, setLastRowInfo] = useState({});

    useEffect(() => {
        if (signature !== null) {
            reformatRowData();
        }
    }, [rowData, signature])


    async function reformatRowData() {
        let formattedData = [];
        if (rowData?.[0]?.[0] === undefined) {
            formattedData = await new PurchaseQuoteGenerator({page1: selectedBackground, page2: selectedBackgroundPage2}, rowData, chosenSupplier, chosenContactPerson, date, authAxios, true, undefined, undefined, signature).formatForPdf(rowData);
        } else {
            formattedData = rowData
        }

        setFormattedRows(formattedData);
    }


    // Get supplier data from id
    useEffect(() => {
        if (chosenSupplier === null || chosenSupplier === undefined) {
            return;
        }
        authAxios
            .get(`/crm/suppliers/${chosenSupplier}?populate=*`)
            .then(({data}) => {
                setSupplier(data);
            });

    }, [chosenSupplier])

    // Get contact person from id
    useEffect(() => {
        if (!chosenContactPerson) {
            return;
        }
        authAxios
            .get(`/crm/suppliers/persons/${chosenContactPerson}?populate=*`)
            .then(({data}) => {
                setContactPerson(data);
            });

    }, [chosenContactPerson])

    useEffect(() => {
        handleResize();
    }, [width, resizeTrigger]);

    function handleResize() {
        const wrapperWidth = document.getElementById('pdf-mockup__wrapper')?.offsetWidth;
        const mockupWidth = document.getElementById('pdf-mockup__page')?.offsetWidth;
        const zoom = Math.min(1 / mockupWidth * wrapperWidth, 1);
        setZoom(isNaN(zoom) ? 1 : zoom);
    }

    async function addRow(e = null, rowKey = null, pageKey = null, keepLastWord = false) {

        // Create variables
        let key, page, rows, label, type, content = '';
        if (e !== null) {
            key = parseInt(e.currentTarget.getAttribute('data-material-key'));
            page = parseInt(e.currentTarget.getAttribute('data-material-page'));
            rows = parseInt(e.currentTarget.getAttribute('data-material-rows'));
            label = e.currentTarget.getAttribute('data-material-name');
            type = e.currentTarget.getAttribute('data-material-type');
        } else {
            key = rowKey;
            page = pageKey;
            label = '';
        }

        // Copy current rows
        const filteredEntries = [...formattedRows];

        // When keep last word is true get and remove last part form previous row to continue typing
        if (keepLastWord) {
            let splitContent = (filteredEntries[page][key].content).split(' ');

            // get last word
            content = splitContent[splitContent.length - 1];
        }

        // add row with or without new content
        filteredEntries[page].splice(key + 1, 0, {label: label === null ? '' : label, content: content, type: type, rows: rows});

        // Reformat data
        const reformattedRows = await new PurchaseQuoteGenerator({page1: selectedBackground, page2: selectedBackgroundPage2}, rowData, chosenSupplier, chosenContactPerson, date, authAxios, true, undefined, undefined, signature).formatForPdf(filteredEntries, true);
        setFormattedRows([...reformattedRows])

        // focus on last added row
        document.getElementById(`row-${key + 1}-page-${page}-content`).focus();
    }


    async function removeRow(e) {

        const key = e.currentTarget.getAttribute('data-material-key');
        const page = e.currentTarget.getAttribute('data-material-page');

        const filteredEntries = [...formattedRows];

        filteredEntries[page].splice(key, 1)
        const reformattedRows = await new PurchaseQuoteGenerator({page1: selectedBackground, page2: selectedBackgroundPage2}, rowData, chosenSupplier, chosenContactPerson, date, authAxios, true, undefined, undefined, signature).formatForPdf(filteredEntries, true);

        setFormattedRows([...reformattedRows])
    }


    const renderTemplateContent = () => {
        if (selectedBackground !== null) {

            return getContent();
        }
        return <>Kies een achtergrond.</>
    }

    function getStringWidth(string) {
        let stringToCheck = string
        //v
        let text = document.createElement("span");
        document.body.appendChild(text);

        text.style.fontFamily = "helvetica";
        text.style.position = 'absolute';
        text.style.whiteSpace = 'nowrap';
        text.innerHTML = stringToCheck;
        text.style.fontSize = "10pt";
        text.style.height = 'auto';
        text.style.width = 'auto';
        text.style.opacity = '1';
        text.style.left = 0;
        text.style.top = 0;
        // Get content width
        let contentWidth = text.clientWidth;

        // add spaces offset
        contentWidth += (stringToCheck.split(" ").length - 1) * 5;

        // Remove used element
        document.body.removeChild(text);

        return contentWidth
    }

    function validateRow(value, maxRowWidth = 462) {

        // add spaces offset
        const contentWidth = getStringWidth(value);

        return contentWidth < maxRowWidth;
    }

    const updateRowData = (e) => {
        const key = parseInt(e.target.getAttribute('data-material-key'));
        const page = parseInt(e.target.getAttribute('data-material-page'));

        // If row to long add new row and focus
        if (e.target.type !== 'textarea') {
            if (!validateRow(e.target.value)) {
                addRow(null, key, page, true);
                return;
            }
        }

        const filteredEntries = formattedRows;

        // Set value to storage at page/key
        filteredEntries[page][key][e.target.name] = e.target.value
        console.log([...filteredEntries]);
        setFormattedRows([...filteredEntries])
    }

    // Get content for pdf
    const getContent = () => {

        let topSpacing = <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </>

        // HTML Header
        let header = <>
            {topSpacing}

            <div className='pdf-mockup__row'>
                <strong>{language === 'nl' ? 'OFFERTE AANVRAAG' : 'REQUEST FOR QUOTATION'}</strong>
            </div>

            <br/>
            <br/>

            <div className='pdf-mockup__row'>
                <Grid columns='1' gap='0'>
                    <IF condition={supplier !== undefined}>
                        <IF condition={supplier?.data?.name}>
                            <span>{(supplier?.data?.name ?? '').trim()}</span>
                        </IF>

                        <IF condition={contactPerson && contactPerson?.data}>
                            {formatName(contactPerson?.data?.firstName, contactPerson?.data?.prefixToSurname, contactPerson?.data?.surname)}
                        </IF>

                        <IF condition={supplier?.data?.address?.addressLine1}>
                            <span>{supplier?.data?.address?.addressLine1}</span>
                        </IF>

                        <IF condition={supplier?.data?.address?.addressLine2}>
                            <span>{supplier?.data?.address?.addressLine2}</span>
                        </IF>

                        <IF condition={supplier?.data?.address?.addressLine3}>
                            <span>{supplier?.data?.address?.addressLine3}</span>
                        </IF>

                        <IF condition={supplier?.data?.address?.postalCode && supplier?.data?.address?.city}>
                            <span>
                                {supplier?.data?.address?.postalCode.toUpperCase()}
                                {/* double space is in original document */}
                                {'  '}
                                {supplier?.data?.address?.city.toUpperCase()}
                            </span>
                        </IF>

                        <IF condition={supplier?.data?.address?.country}>
                            <span>{supplier?.data?.address?.country}</span>
                        </IF>
                    </IF>
                </Grid>
            </div>

            <br/>
            <br/>

            <div className='pdf-mockup__row'>
            {selectedBackground && selectedBackground.includes('Briefpapier_HJMG')  ? (
                <span>Ridderkerk,</span>
            ) : (
                <span>Dordrecht,</span>
            )}

                <span>
                        <input
                            type='date'
                            min={new Date().toLocaleDateString('fr-ca')}
                            value={date ?? new Date().toLocaleDateString('fr-ca')}
                            onChange={({target}) => {
                                setDate(target.value);
                            }}
                            style={{
                                marginLeft: '10px !important',
                                textAlign: 'center',
                                borderBottom: 'none'
                            }}
                        />
                    </span>
            </div>

            <br/>
            <br/>

            <div className='pdf-mockup__row'>
                <Grid gap='0' customColTemplate='45mm 2.5mm 1fr'>
                    <span>{language === 'nl' ? 'Ons inkoopnummer' : 'Our reference'}</span>
                    <span>:</span>
                    <span>
                        {number}
                    </span>
                </Grid>
            </div>

            <br/>
            <br/>

            <div className='pdf-mockup__row'>
                {contactPerson !== null ?
                    <span>
                        {language === 'nl' ? 'Beste' : 'Dear'} {contactPerson.data.firstName},
                        <br/>
                    </span>
                    : <></>}
            </div>

            <br/>

            <div className='pdf-mockup__row'>
                <span>{language === 'nl' ? 'Graag willen we een offerte aanvragen voor het onderstaande:' : 'We would like to request the following from you:'}</span>
            </div>
        </>

        // HTML Footer
        let footer = <>
            <br/>
            <div className='pdf-mockup__row'>
                <span>{language === 'nl' ? 'Bij eventuele vragen of opmerkingen kunt u ons altijd bellen of mailen.' : `If there are any questions, we would like to hear from you!`}</span>
            </div>
            <br/>
            <div className='pdf-mockup__row'>
                {language === 'nl' ? 'Met vriendelijke groet,' : `Kind regards,`}
            </div>
            {selectedBackground && selectedBackground.includes('Briefpapier_HJMG')  ? (
                'HJ Media Groep B.V.'
            ) : (
                'Pretore B.V.'
            )}

            <br/>

            {signature ? (<>
                <br/>
                <div className='pdf-mockup__row pdf__signature'>
                    <img src={signature} alt='handtekening'/>
                </div>
            </>) : (<br />)}
            <br/>
            <div className='pdf-mockup__row'>
                <span>{authState?.user?.username}</span>
            </div>
        </>


        // All content is stored in this variable
        let content = []
        let pageKey = 0;
        let rowKey = 0;
        let rowId = 0;
        for (let page = 0; page < (formattedRows.length !== 0 ? formattedRows.length : 1); page++) {
            content.push(<div
                className='pdf-mockup__page'
                id="pdf-mockup__page"
                style={{
                    backgroundImage: `url('${(page === 0 ? selectedBackground : selectedBackgroundPage2)}')`,
                    zoom: zoom,
                    MozTransform: `scale(${zoom})`
                }}
            >
                {page === 0 ? header : topSpacing} <br />
                {formattedRows.length !== 0 ? formattedRows[page].filter(data => data).map((data, i) => {

                    if (data?.defaultLabel?.toLowerCase() === "quantity") {
                        let firstQuantity = quantity[Object.keys(quantity)[0]].quantity;

                        data.content = isNaN(firstQuantity) ? "" : firstQuantity.toString()
                    }
                    let [gridCol, gridColumns] = getGridColumnsByType(data.type);

                    let rowContent = <Grid
                        gap='0'
                        customColTemplate={gridCol}
                        style={{ alignItems: 'start', margin: '1.5px 0', overflow: 'hidden' }}
                        key={`material-${i}`}
                        columns={gridColumns}
                    >

                        {getElementsByType(data.type, pageKey, rowId, data)}
                        <span>
                            <Button
                                className='btn--transparent btn--round btn--icon-red'
                                data-material-key={rowId}
                                data-material-page={pageKey}
                                data-material-rows={getOffsetByType(data.type)}
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)'
                                }}
                                onClick={(e) => removeRow(e)}
                                title='Regel verwijderen'
                            >
                                <Icon name='times'/>
                            </Button>
                            <Button
                                className='btn--transparent btn--round btn--icon-green'
                                style={{
                                    padding: 0,
                                    backgroundColor: 'var(--white)',
                                    marginLeft: '1mm'
                                }}
                                data-material-key={rowId}
                                data-material-page={pageKey}
                                data-material-rows={getOffsetByType(data.type)}
                                onClick={(e) => addRow(e)}
                                title='Regel toevoegen'
                            >
                                <Icon name='plus-circle'/>
                            </Button>
                        </span>
                        </Grid>

                        rowKey += getOffsetByType(data.type);
                        rowId++;
                        return rowContent;
                    }
                ) : <></>}
                <IF condition={pageKey + 1 === formattedRows.length}>
                    <br/>
                    <Grid
                        columns='3'
                        customColTemplate={'70px 1fr 15px'}
                        gap={5}
                        style={{
                            justifyItems: 'start'
                        }}
                    >
                        <span>Extra info</span>
                        <Button
                            data-material-key={rowKey + 1}
                            data-material-page={pageKey}
                            data-material-type={"textarea"}
                            data-material-name={'extraInfo'}
                            data-material-rows={5}
                            className='btn--transparent btn--round btn--icon-green'
                            style={{
                                padding: 0,
                                backgroundColor: 'var(--white)',
                            }}
                            onClick={(e) => addRow(e)}
                            title='Regel toevoegen'
                        >
                            <Icon name='plus-circle'/>
                        </Button>

                        {formattedRows[0]?.length === 0 && <Button
                            data-material-key={rowKey + 1}
                            data-material-page={pageKey}
                            className='btn--transparent btn--round btn--icon-green'
                            style={{
                                padding: 0,
                                backgroundColor: 'var(--white)',
                                marginRight: '2px'
                            }}
                            onClick={(e) => addRow(e)}
                            title='Regel toevoegen'
                        >
                            <Icon name='plus-circle' />
                        </Button>}
                    </Grid>
                    {footer}
                </IF>
            </div>);
            pageKey++;
            rowId = 0;
        }

        return content
    }

    function getElementsByType(type, pageKey, rowKey, data) {

        switch (type) {
            case 'textarea':
                return <textarea
                    maxLength={250}
                    rows={5}
                    id={`row-${rowKey}-page-${pageKey}-content`}
                    data-material-key={rowKey}
                    data-material-page={pageKey}
                    data-material-type={data.type}
                    data-material-rows={1}
                    name="content"
                    onChange={(e) => {
                        updateRowData(e)
                    }}
                    value={data.content}
                    style={{
                        marginLeft: '10px !important',
                        textAlign: 'left',
                        border: 'none',
                        borderRadius: 0,
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.05)',
                        backgroundImage: 'none',
                        color: 'black',
                        overflowY: 'auto',
                        padding: '0 2px'
                    }}
                />

            default:
                return <>
                    <span>
                        <input
                            name="label"
                            id={`row-${rowKey}-page-${pageKey}-label`}
                            className="material__item__input"
                            data-material-key={rowKey}
                            data-material-page={pageKey}
                            value={data.label ?? (language?.toLowerCase() === 'nl' ? data.dutchLabel : data.englishLabel) ?? data.defaultLabel}
                            onChange={(e) => {
                                updateRowData(e)
                            }}
                        />
                    </span>
                    <span>: </span>
                    <span>
                        <input
                            name="content"
                            className="material__item__input"
                            id={`row-${rowKey}-page-${pageKey}-content`}
                            data-material-key={rowKey}
                            data-material-page={pageKey}
                            value={data.content}
                            onChange={(e) => {
                                updateRowData(e)
                            }}
                        />
                    </span>
                </>
        }
    }

    function getOffsetByType(type) {
        switch (type) {
            case 'textarea':
                return 5

            default:
                return 1
        }
    }

    function getGridColumnsByType(type) {
        switch (type) {
            case 'textarea':
                return ['1fr 9mm', 2]

            default:
                return ['45mm 2mm 1fr 9mm', 4]
        }
    }

    return (
        <div

            className='pdf-mockup__wrapper'
            data-pdf-for='purchase-quote'
            id="pdf-mockup__wrapper"
        >
            {renderTemplateContent()}
        </div>
    );
}
