import {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import isEmpty from 'lodash/isEmpty';
import PersonDetails from "./Blocks/PersonDetails/PersonDetails";
import PersonCompanyList from "./Blocks/CompaniesList/PersonCompanyList";
import CompanyPersonInfo from "./Blocks/PersonDetails/CompanyPersonInfo";
import Grid from "../../../../../../Components/Grid/Grid";
import {sanitizeData} from "App/Util/transform";
import Container from "UI/App/Components/Container/Container";


export default function ContactPerson() {
	const fetchContext = useContext(FetchContext);

	// get the params from the url
	const params = useParams();
	const companyId = params.companyId;

	// check if on suppliers path
	const location = useLocation();
	const isSupplier = /\/suppliers\//.test(location.pathname);

	const [person, setPerson] = useState([]);
	const [personId, setPersonId] = useState(null);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		fetchContext.authAxios.get(`${!isSupplier ? '/crm/companies/persons/' : '/crm/suppliers/persons/'}${params.contactPersonId}`, {
			params: {
				populate: "*"
			}
		}).then(({ data: { data } }) => {
			data = sanitizeData(data);
			setPerson(data);
			setPersonId(data.id)
		}).catch((exception) => {
			console.error(exception);
		});

		fetchContext.authAxios.get(`${!isSupplier ? '/crm/companies' : '/crm/suppliers'}?filters[contactPersons][id][$eq]=${params.contactPersonId}`, {
			// params: {
			// populate: "*"
			// }
		}).then(({ data }) => {
			setCompanies(data.data);
		}).catch((exception) => {
			console.error(exception);
		});
	}, [fetchContext.authAxios, params.contactPersonId]);

	if (isEmpty(person)) {
		// to-do: insert html and text for company page when company isn't found.
		return (
			<div className='no-person'></div>
		)
	}

	return (
		<Container>
			<Grid
				columns={{
					1300: 1,
					default: 2
				}}
			  	style={{
				  marginBottom: '20px'
				}}
			>
				<PersonDetails data={person} />
				<CompanyPersonInfo personId={personId} person={person} />
			</Grid>
			<PersonCompanyList data={companies} />
			{/*<CompanyInfo company={company} setCompany={setCompany} companyID={company?.data?.id} companyName={company?.data?.attributes?.companyName} />*/}
		</Container>
	);
}
