import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FetchContext } from "App/Strapi/FetchContext";
import { toast } from "react-toastify";
import Icon from "UI/App/Components/Icon/Icon";
import capitalize from "lodash/capitalize";
import Container from "UI/App/Components/Container/Container";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import IF from "UI/App/Components/Conditional/IF";
import Table from "UI/App/Components/Table/Table";
import HasRole from "UI/App/Components/Auth/HasRole";
import Button from "../../../Components/Button/Button";
import Grid from "../../../Components/Grid/Grid";
import Block from "../../../Components/Block/Block";
import { usePagination } from "../../../Components/Pagination/Pagination";
import { stringify } from "qs";

export default function Project() {
    const [quoteConfirmation, setQuoteConfirmation] = useState(undefined);
    const [contactPerson, setContactPerson] = useState(null);
    const [calculations, setCalculations] = useState(null);
    const [company, setCompany] = useState(null);
    const [status, setStatus] = useState('concept');
    const [base64, setBase64] = useState(null);
    const [quotePdf, setQuotePdf] = useState(null);
    const [quote, setQuote] = useState(null)
    const fetchContext = useContext(FetchContext)
    const navigate = useNavigate();
    const params = useParams();
    const statusses = [
        'concept',
        'uitgebracht',
        'herzien',
        'geaccepteerd',
        'afgewezen prijs',
        'afgewezen planning',
        'afgewezen klant',
        'afgewezen overig',
        'verlopen',
    ];
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        searchSettings: {
            enabled: true,
            strapiFields: ['number', '[company][name]']
        },
        filters: [
            {
                name: 'status',
                type: 'select',
                options: [
                    { value: '*', name: 'Alle' },
                    { value: 'concept', name: 'Concept' },
                    { value: 'uitgebracht', name: 'Uitgebracht' },
                    { value: 'herzien', name: 'Herzien' },
                    { value: 'geaccepteerd', name: 'Geaccepteerd' },
                    { value: 'afgewezen prijs', name: 'Afgewezen prijs' },
                    { value: 'afgewezen planning', name: 'Afgewezen planning' },
                    { value: 'afgewezen klant', name: 'Afgewezen klant' },
                    { value: 'afgewezen overig', name: 'Afgewezen overig' },
                    { value: 'verlopen', name: 'Verlopen' }
                ]
            }
        ]
    });
    // set base quotes fetch query
    const projectsQuery = {
        populate: {
            calculation: {
                fields: ['id', 'number', 'copiedFrom', 'copies']
            },
            order: {
                fields: ['id', 'number']
            },
            number: true,
            description: true,
            machinePlanItems: true,
        },
        sort: 'id:desc'
    };
    // get the table data using the useEffect hook
    useEffect(() => {
        fetchContext.authAxios.get(`/projects/${params.projectId}?${stringify(projectsQuery)}&${filterQuery}`).then(({ data }) => {
            console.log(data);
            // setBase64(data.data.pdfBase64);
            // setQuote(data.data);
            // setCompany(data.data.company)
            // setContactPerson(data.data.contactPerson)
            //
            // let tableData = [];
            //
            // for(const calculation of data.data.calculations){
            //     tableData.push({
            //         attributes: {
            //             onClick: () => { navigate(`/calculations/${calculation.id}/view`) }
            //         },
            //         data: [
            //             <div className={'owner-indicator'}>
            //                 {calculation.owner !== null ? calculation.owner.toLowerCase() === 'hjmg' ? <span className={'circle circle-hjmg'}></span> : <span className={'circle circle-pretore'}></span> : <></>}
            //             </div>,
            //             calculation.number,
            //             calculation.description,
            //             calculation.customerReference
            //         ]
            //     })
            // }
            //
            // setCalculations(tableData);
            // setStatus(data.data.status)
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });
        fetchContext.authAxios.get(`/quotes/confirmations?filters[quote][id]=${params.quoteId}`).then(({ data }) => {
            setQuoteConfirmation(data.data[0])
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });
    }, []);
    const updateStatus = (e, quoteId) => {
        setStatus(e.target.value);
        fetchContext.authAxios.put(`/quotes/${quoteId}`, {
            "data": {
                "status": e.target.value
            }
        }).then(({ data }) => {
            toast.success(`Offerte: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.log(exception);
            toast.error(`Er ging iets mis met het bewerken van offerte.`);
        });
    }
    return (
        <Container>
            <PageHeader title={`Offerte: ${quote?.number}`}>
                <HasRole roles={['admin']}>
                    <Button data-title={'Kopiëren'} className={'btn btn--black btn--round'} to={`/quotes/${quote?.id}/copy`}><Icon name={'copy'} /></Button>
                </HasRole>
                <IF condition={quote?.calculations.every(calc => calc.owner === 'pretore')}>
                    <Button data-title={'Bevestigen'} className={'btn btn--black btn--round'} to={`/orders/confirmations/${quote?.id}`}><Icon name={'file-check'} /></Button>
                </IF>
            </PageHeader>
            <br />
            <Grid columns={2} customColTemplate={'2fr 2fr'}>
                <Block name={'quoteInfo'} title={"Offerte informatie"}>
                    <Grid columns={1} gap={'10px'}>
                        <span>
                            <b>Status:</b><br />
                            <select
                                value={status}
                                onChange={(e, id) => {
                                    updateStatus(e, quote?.id)
                                }}>
                                {statusses.map((status, id) =>
                                    <option key={id} value={status}>{capitalize(status)}</option>
                                )}
                            </select>
                        </span>
                        <span>
                            <b>Bedrijf:</b><br />
                            {company !== null ? <Link to={`/crm/companies/${company.id}`}>{company?.name}</Link> : ''}
                        </span>
                        <span>
                            <b>Contact persoon:</b><br />
                            {contactPerson !== null ? <Link to={`/crm/companies/${company.id}/contactpersons/${contactPerson.id}`}>{`${contactPerson?.firstName} ${(contactPerson?.prefixToSurname ?? '')} ${contactPerson?.surname}`.replace('  ', ' ')}</Link> : ''}
                        </span>
                        <IF condition={company && company?.address}>
                            <span>
                                <b>Adresgegevens:</b><br />
                                {company?.address.addressLine1}<br />
                                <IF condition={company?.address.addressLine2}>
                                    {company?.address.addressLine2}
                                </IF>
                                <IF condition={company?.address.addressLine3}>
                                    {company?.address.addressLine3}
                                </IF>
                                {company?.address.postalCode} {company?.address.city}<br />
                                <IF condition={company?.address.province}>
                                    {company?.address.province}<br />
                                </IF>
                                {company?.address.country}<br />
                            </span>
                        </IF>
                        <IF condition={quote?.description}>
                            <span>
                                <b>Omschrijving:</b>
                                {quote?.description && <span style={{ display: 'block', whiteSpace: 'break-spaces' }}>
                                    {quote?.description ?? ''}
                                </span>}
                            </span>
                        </IF>
                    </Grid>
                </Block>
                <Block title={'Calculaties'} useSmallerPadding={true}>
                    <Table
                        structure={['40px', '150px', '100%']}
                        data={calculations}
                        overflowText={true}
                        border='row'
                        hover
                    />
                </Block>
            </Grid>
        </Container>
    )
}