import capitalizeFn from 'lodash/capitalize';

type TLang = 'nl' | 'en';

// can't be typed without duplicating every key.
// typing with `[key: string]` causes TKeyDict to not work & the expected type to be string|number instead of the defined keys.
const dict = {
    address: {
        nl: 'adres',
        en: 'address'
    },
    'contact person': {
        nl: 'contactpersoon',
        en: 'contact person'
    },
    'copies amount': {
        nl: 'aantal',
        en: 'copies'
    },
    customer: {
        nl: 'klant',
        en: 'customer'
    },
    'customer reference': {
        nl: 'klantreferentie',
        en: 'customer reference'
    },
    date: {
        nl: 'datum',
        en: 'date'
    },
    'dear formal': {
        nl: 'geachte',
        en: 'dear'
    },
    'dear informal': {
        nl: 'beste',
        en: 'dear'
    },
    'delivery note': {
        nl: 'afleverbon',
        en: 'delivery note'
    },
    description: {
        nl: 'omschrijving',
        en: 'description'
    },
    mr: {
        forceCurrentCasing: true,
        nl: 'heer',
        en: 'Mr'
    },
    ms: {
        forceCurrentCasing: true,
        nl: 'mevrouw',
        en: 'Ms'
    },
    'mr/ms': {
        forceCurrentCasing: true,
        nl: 'heer/mevrouw',
        en: 'Mr/Ms'
    },
    'number of boxes': {
        nl: 'aantal dozen',
        en: 'number of boxes'
    },
    'number of pallets': {
        nl: 'aantal pallets',
        en: 'number of pallets'
    },
    'order form': {
        nl: 'bestelbon',
        en: 'order form'
    },
    'order number': {
        nl: 'ordernummer',
        en: 'order number'
    },
    'packing slip number': {
        nl: 'pakbonnummer',
        en: 'packing slip number'
    },
    'purchase quote': {
        nl: 'inkoopofferte',
        en: 'purchase quote'
    },
    quantity: {
        nl: 'aantal',
        en: 'quantity'
    },
    'receipt signature': {
        nl: 'handtekening voor ontvangst',
        en: 'signature for receipt'
    },
    reference: {
        nl: 'kenmerk',
        en: 'reference'
    },
    regards: {
        nl: 'met vriendelijke groet',
        en: 'best regards'
    }
};

// create type from dict keys
type TKeyDict = keyof typeof dict;

/**
 * Translate a defined string of text into the language given.
 *
 * @export
 * @template TKey
 * @param {Lowercase<TKey> extends TKeyDict ? TKey : TKeyDict} [key] The key from the dict to translate (cas insensitive).
 * @param {TLang} [lang='nl'] The language to translate into. Default is `'nl'`.
 * @param {boolean} [capitalize=false] Capitalize the fist letter if `forceCurrentCasing` is not `true` for that key
 * @return {string}
 */
export default function translate<TKeyParam extends string, TLangParam extends string>(
    key?: Lowercase<TKeyParam> extends TKeyDict ? TKeyParam : TKeyDict,
    lang: Lowercase<TLangParam> extends TLang ? TLangParam : TLang = 'nl',
    capitalize: boolean = false
): string {
    if (typeof key === 'undefined') {
        return '';
    }

    // sanitize key and language to lowercase
    const lcKey = key?.toLowerCase() as TKeyDict;
    const lcLang = lang?.toLowerCase() as TLang;

    // @ts-ignore: `forceCurrentCasing` only has to be set where needed
    const forceCurrentCasing = dict?.[lcKey]?.forceCurrentCasing ?? false;

    const translation = dict?.[lcKey]?.[lcLang] ?? key;

    return capitalize && !forceCurrentCasing ? capitalizeFn(translation) : translation;
}
