import {useContext} from "react";
import Activity from "UI/App/Partials/Content/Calculations/HJMG/steps/ActivityTab/Activity";
import {CalculationProviderContext} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import Icon from "UI/App/Components/Icon/Icon";

export default function ActivityTab({ name, activity, index, registerEntry, updateEntry, removeEntry }) {
    const calculationProvider = useContext(CalculationProviderContext);

    return (
        <div className={"hjmg__calculation__tab"} data-active={calculationProvider.activeTab === index}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span><b>{name}</b></span>
                <button className={'btn'} onClick={() => registerEntry(name, {
                    work: '',
                    description: '',
                    hourlyRate: activity.hourlyRate,
                    time: 0
                })}><Icon name={'plus'} /></button>
            </div>
            {activity.entries?.map((entry, index) =>
                <Activity
                    key={index}
                    index={index}
                    entry={entry}
                    options={activity?.options}
                    activityName={name}
                    activity={activity}
                    updateEntry={updateEntry}
                    removeEntry={removeEntry}
                />
            )}
            {((activity.entries?.length ?? 0) === 0) && <span style={{color: 'grey'}}><i>Geen {name.toLowerCase()} werkzaamheden...</i></span>}
        </div>
    )
}