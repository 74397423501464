import {getValuesArrayFromSelect} from "App/Util/select2";
import axios from "axios";
import capitalize from "lodash/capitalize";
import qs from "qs";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {toast} from "react-toastify";
import {type LoadingData} from "select2";
import IWidget from "./IWidget";
import WidgetBase from "./WidgetBase";
import {TrenderType} from "./WidgetTypes";

export default class MaterialChoiceWidget extends WidgetBase implements IWidget {
    callback: ((object: Object) => any);
    widgetData: String | null
    saveWidget: ((object: Object) => any);
    optionalWidgetData: undefined | { type: string, value: unknown, title: string, id: string, data?: Record<string, any> };
    debug: boolean;
    allowHidden:boolean;

    prefix: string | undefined;

    /**
     * The fields fetched from Strapi
     * Any of these values can be requested
     * @TODO: fetch these values from the API
     */
    _RETURN_FIELDS = [
        "category",
        "internalSKU",
        "supplierSKU",
        "quotationNameEN",
        "quotationNameNL",
        "calculationDescription",
        "lengthInMeters",
        "widthInMeters",
        "heightInMeters",
        "weightInGram",
        "thicknessInMM",
        "unitsPerOrder",
        "countPerUnit",
        "unit",
        "runningDirection",
        "purchasePrice",
        "regularPrice",
        "salePrice",
        "coated",
        "lastPriceUpdate"
    ];

    constructor(renderType: TrenderType, callback: () => any, saveWidget: () => any, prefix: string | undefined, optionalWidgetData?: object, debug: boolean = false, showVisibilityCheckbox: boolean = false,allowHidden:boolean = true) {
        super()
        this.renderType = renderType;
        this.callback = callback;
        this.widgetData = null;
        this.saveWidget = saveWidget;
        this.optionalWidgetData = optionalWidgetData;
        this.debug = debug;
        this.prefix = prefix;
        this.visibilityCheckbox = showVisibilityCheckbox;
        this.allowHidden = allowHidden;

        if (this.optionalWidgetData?.data?.value !== undefined && this.optionalWidgetData.data.type === 'MaterialChoice') {
            // update the return fields every time the material choice widget gets loaded
            this.optionalWidgetData.data.value.returnFields = this._RETURN_FIELDS;
        }
    }

    /**
     * Returns react element for preview
     */
    getPreviewHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        const widgetData = {
            widgetType: 'MaterialChoice',
        }

        return <div key={widgetData.widgetType} className="widget" onClick={this.callback !== undefined ? () => this.callback(widgetData) : () => {
        }}>Materiaal keuze</div>
    }

    /**
     * Handles the form submit adds data to saveWidget function
     * @param event
     * @private
     */
    handleSubmit(event: any): void {
        event.preventDefault();

        const inputMaterials = event.target.elements.materials;
        const inputCategories = event.target.elements.categories;

        const data = {
            type: "MaterialChoice",
            title: event.target.elements.label.value,
            value: {
                filters: {
                    materials: getValuesArrayFromSelect(inputMaterials),
                    categories: getValuesArrayFromSelect(inputCategories),
                },
                returnFields: this._RETURN_FIELDS,
                label: event.target.elements.label.value
            }
        };

        this.addWidgetToWidgetContainer(`Materiaal keuze: ${data.title}`, data);
    }

    handleEdit(event: any): void {
        event.preventDefault();

        const inputMaterials = event.target.elements.materials;
        const inputCategories = event.target.elements.categories;

        const data = {
            type: "MaterialChoice",
            title: event.target.elements.label.value,
            value: {
                filters: {
                    materials: getValuesArrayFromSelect(inputMaterials),
                    categories: getValuesArrayFromSelect(inputCategories),
                },
                returnFields: this._RETURN_FIELDS,
                label: event.target.elements.label.value
            },
        };

        (new WidgetBase()).updateWidget(event.target.elements.id.value, `Materiaal keuze: ${data.title}`, data);
    }


    async provideData(selectId: string): Promise<[] | boolean> {
        const filterQuery = buildFilterQuery(this.optionalWidgetData.data.value.filters);
        const data = await axios.get(`${process.env.REACT_APP_API_URL}/v1/logistics/materials/all?${filterQuery}`, {
            headers: {
                'authorization': `bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            let options: { [key: string]: Array<{ [key: string]: any }> } = {};
            response.data.forEach((item: any) => {
                const catCap = capitalize(item.category)
                if (typeof options[catCap] === 'undefined') {
                    options[catCap] = [];
                }
                options[catCap].push({value: item.id, dataMaterial: item, title: item.calculationDescription});
            });
            return options;
        }).catch((reason) => {
            console.error(reason)
            toast.error(`Something went wrong trying to get material data! ${reason.response?.status ? `(${reason.response.status})` : ''}`)
            return Promise.reject(false);
        })

        return data;
    }

    /**
     * returns form react element
     */
    public getFormHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {

        return (<form onSubmit={(event: any) => this.handleSubmit(event)}>
            <h3>Materiaal keuze</h3>
            <div className='input-group'>
                <label>Label</label>
                <input type={'text'} name={'label'}/>
            </div>
            <div className='input-group'>
                <label>Materialen</label>
                <MaterialSelect />
            </div>
            <div className='input-group'>
                <label>Categorieën</label>
                <CategoriesSelect />
            </div>
            <button type="submit">Opslaan</button>
        </form>)
    }

    /**
     * Returns react element with badge html
     */
    getBadgeHTML(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return <div>MaterialWidget html:</div>
    }

    public getEditWidgetForm(editData: object) {
        return (
            <form onSubmit={(event) => this.handleEdit(event)}>
                <div className='input-group'>
                    <label>Label</label>
                    <input type={'text'} name={'label'} defaultValue={editData?.value?.label}/>
                </div>
                <input type={"hidden"} name={"id"} value={editData.id}/>
                <div className='input-group'>
                    <label>Materialen</label>
                    <MaterialSelect selectedItems={editData?.value?.filters?.materials?.length ? editData.value.filters.materials : undefined}/>
                </div>
                <div className='input-group'>
                    <label>Categorieën</label>
                    <CategoriesSelect selectedItems={editData?.value?.filters?.categories?.length ? editData?.value?.filters?.categories : undefined}/>
                </div>
                <button type="submit">Opslaan</button>
            </form>
        );
    }

    public getCalculationForm() {
        const id = this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id;
        const selectId = `${id}-material-select`;
        const widgetContent = `
			<span class="visibleInputCheckbox">
                Verbergen: <input type="checkbox" class="visibleInputCheckbox" ${this.optionalWidgetData?.data?.hidden ? 'checked' : ''}>
            </span>
            <label htmlFor="${id}">${this.optionalWidgetData.data.title}</label>
            <select id="${selectId}" name="material" data-loading=${true}>
                <option></option>
            </select>
        `;

        this.addWidgetToCalculationForm(`${widgetContent}`, this.optionalWidgetData, this.optionalWidgetData.parentId)

        // get the filter query for the request
        const filterQuery = buildFilterQuery(this.optionalWidgetData?.data?.value?.filters);
        // get the materials from the API and fill the API
        GetMaterialsAndFillSelect(filterQuery, selectId, this.optionalWidgetData?.data?.templateSpecificFilters);
    }

    getEditHTML(): void {
        (new WidgetBase()).addWidgetToWidgetContainer(`Materiaal keuze: ${this.optionalWidgetData.data.value.label}`, this.optionalWidgetData, '', true);
    }

    getFormulaString(): String {
        return ``;
    }

    getPreviewFormHTML(): React.ReactElement {
        return (
            <div id={this?.optionalWidgetData?.id}>
                <label>{this?.optionalWidgetData?.data?.value?.label}</label>
            </div>
        );
    }

    calculationFunctionality() {
        const widgetElement = this.getWidgetElement();

        if (!widgetElement) return;

        if (!this.optionalWidgetData) return;

        // Handle visibility is one option

        /**
         * Get the DOM element of the material select
         */
        const materialSelectBox = widgetElement.querySelector("[name='material']");

        if (materialSelectBox === null) return;

        let options = materialSelectBox.querySelectorAll<HTMLOptionElement>("option:not([disabled])");

        // If not debug
        if (!this.debug) {
            // If one option hide
            if (options.length === 1) {
                // Set only option selected
                options[0].selected = true

                // Hide select
                widgetElement?.classList.add('hidden');
            }
        } else {
            widgetElement?.classList.remove('hidden');
        }

        let selectedOption = materialSelectBox.querySelector<HTMLOptionElement>("option:checked");

        // If default value and no value set set default
        if(this.optionalWidgetData.data.value.defaultValue !== null || this.optionalWidgetData.data.value.defaultValue !== undefined){
            // Check if material select has options
            if(materialSelectBox.options.length !== 0){
                // Check if there is not a value set by a user
                if (selectedOption === null || selectedOption?.disabled){
                    // set option selected by looping and setting option seleced
                    for(const option of materialSelectBox.options){
                        if(this.optionalWidgetData.data.value.defaultValue === option.value){
                            // Set selected option
                            selectedOption = option;

                            // Set Select option
                            option.selected = true;

                            // Break loop
                            break;
                        }
                    }
                }
            }
        }

        // Handle visibility checkbox
        if (this.visibilityCheckbox) {
            widgetElement?.querySelector('.visibleInputCheckbox')?.classList.remove('hidden')
        } else {
            widgetElement?.querySelector('.visibleInputCheckbox')?.classList.add('hidden')
        }

        // Get the filter select box for this template
        const templateSpecificFilterSelect: HTMLSelectElement = widgetElement.querySelector('.templateSpecificFilterSelect');

        if (templateSpecificFilterSelect) {
            // Get the selected values
            const selectedOptions = [...templateSpecificFilterSelect.selectedOptions];


            const selectedCategories = selectedOptions.filter((option) => option.classList.contains('category')).map((option) => option.value);
            const selectedMaterials = selectedOptions.filter((option) => !option.classList.contains('category')).map((option) => parseInt(option.value));

            // Set the filters in the widget data
            this.optionalWidgetData.data.templateSpecificFilters = {
                categories: selectedCategories,
                materials: selectedMaterials
            }

            // Update the widget data
            this.updateWidgetData((this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id), this.optionalWidgetData);

            // Update the visible items in the material select box
            const optGroups = widgetElement.querySelectorAll<HTMLOptionElement>('select[name=material] optgroup');

            for (const optGroup of optGroups) {
                const materials = optGroup.querySelectorAll<HTMLOptionElement>('option');
                for (const material of materials) {
                    const isFilteredOut = selectedCategories.includes(optGroup.label) || selectedMaterials.includes(parseInt(material.value)) || (selectedCategories.length === 0 && selectedMaterials.length === 0);

                    if (isFilteredOut) {
                        material.classList.add('isFilteredItem');
                        material.classList.remove('hidden');
                    } else {
                        material.classList.remove('isFilteredItem');
                        material.classList.add('hidden');
                    }
                }
            }
        }

        if (this.debug) {
            addTemplateSpecificFilterSelectBox(widgetElement, this.optionalWidgetData?.data?.templateSpecificFilters);
        } else {
            templateSpecificFilterSelect?.remove();
        }

        if (selectedOption === null || selectedOption?.disabled) return;

        const material = JSON.parse(selectedOption.getAttribute('data-material') ?? "{}");

        this.optionalWidgetData.data.value.result = material;
        this.optionalWidgetData.data.value.defaultValue = selectedOption.value;

        this.updateWidgetData((this.optionalWidgetData.prefix !== undefined ? this.optionalWidgetData.prefix + this.optionalWidgetData.id : this.optionalWidgetData.id), this.optionalWidgetData);


        if (this.debug) {
            widgetElement.querySelector('label').innerHTML = "";
            const element = document.createElement('pre');
            element.innerHTML = JSON.stringify(this.optionalWidgetData.data.value.result, null, 2);
            widgetElement.querySelector('label')?.append(`[${this.optionalWidgetData?.prefix}${this.optionalWidgetData?.id}] ` + this.optionalWidgetData.data.value.label)
            widgetElement.querySelector('label')?.append(element);
        } else {
            widgetElement.querySelector('label').innerHTML = "";
            widgetElement.querySelector('label').innerHTML = this.optionalWidgetData.data.value.label;
        }

        // If not debug handle visibility
        if(this.allowHidden){
            if (!this.debug && !this.visibilityCheckbox) {
                // Check if input has value set else skip
                // if (widgetElement.querySelector(".inputWidgetInput").value !== '' || widgetElement.querySelector(".inputWidgetInput").value !== null) {
                if (this.optionalWidgetData.data.hidden !== undefined) {
                    if (this.optionalWidgetData.data.hidden) {
                        widgetElement?.classList.add('hidden')
                    }
                }
                // }
            }
        }
    }
}

function CategoriesSelect({selectedItems}: { selectedItems: Array<number> }) {
    const [items, setItems] = useState([{value: null, label: '...', disabled: true}]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/v1/logistics/materials/categories", {
            headers: {
                'authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                setItems(response.data.categories?.map((item: any) => {
                    return {value: item, label: capitalize(item)};
                }));
                setIsLoading(false);
            })
            .catch((reason) => {
                console.error(reason)
                toast.error(`Something went wrong trying to get material data! ${reason.response?.status ? `(${reason.response.status})` : ''}`)
            })
    }, [])

    return (
        <Select
            name={"categories"}
            isMulti={true}
            isLoading={isLoading}
            options={items}
            defaultValue={selectedItems?.map((item) => ({value: item, label: item}))}
        />
    )
}

function MaterialSelect({selectedItems}: { selectedItems: Array<number> }) {
    const [items, setItems] = useState([{value: null, label: '...', disabled: true}]);
    const [defaultValue, setDefaultValue] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/v1/logistics/materials/all", {
            headers: {
                'authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                setItems(response.data.map((item: any) => {
                    return {value: item.id, label: `${item.calculationDescription}${(item?.internalSKU ? ` (${item.internalSKU})` : '')}`};
                }));
                setIsLoading(false);
            })
            .catch((reason) => {
                console.error(reason)
                toast.error(`Something went wrong trying to get material data! ${reason.response?.status ? `(${reason.response.status})` : ''}`)
            })
    }, [])

    useEffect(() => {

        if (items[0]?.value !== null && Array.isArray(selectedItems) && selectedItems.length > 0) {
            const selectedValues = WidgetBase.getSelectedValues(items, ...selectedItems);
            setDefaultValue(selectedValues);
        }

        if (typeof selectedItems === 'undefined') {
            setDefaultValue(true);
        }
    }, [items])

    return (
        defaultValue && <Select
            isMulti={true}
            name={"materials"}
            isLoading={isLoading}
            options={items}
            defaultValue={defaultValue}
        />
    )

}

function GetMaterialsAndFillSelect(filterQuery: string, selectId: string, templateSpecificFilters?: any) {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/logistics/materials/all?${filterQuery}`, {
        headers: {
            'authorization': `bearer ${localStorage.getItem('token')}`
        }
    })
        .then((response) => {
            let options: { [key: string]: Array<string> } = {};
            response.data.forEach((item: any) => {
                // Define whether this item should be filtered out
                const isFilteredOut = templateSpecificFilters ?
                    (templateSpecificFilters.categories?.includes(item.category) || templateSpecificFilters.materials?.includes(item.id)) || (templateSpecificFilters.categories.length === 0 && templateSpecificFilters.materials.length === 0)
                    :
                    true;

                const catCap = capitalize(item.category)
                if (typeof options[catCap] === 'undefined') {
                    options[catCap] = [];
                }

                options[catCap].push(`<option class="${isFilteredOut ? 'isFilteredItem' : 'hidden'}" value="${item.id}" data-material='${JSON.stringify(item)}'>${item.calculationDescription}${(item.internalSKU ? ` (${item.internalSKU})` : '')}</option>`);
            });

            const selectElement = document.getElementById(selectId);

            if (selectElement === null) {
                // Only show error message when on the edit page
                // This is in case the user has navigated away from the page
                if (/calculations\/templates\/\d+\/edit\/?$/.test(window.location.pathname) || /calculations\/\d+\/edit\/?$/.test(window.location.pathname)) {
                    console.error(`[MaterialChoice]: Select with id '${selectId}' not found!`);
                    toast.error(`Something went wrong trying to set material data!`);
                }
                return;
            }

            let optGroupedValues: Array<string> = [];

            Object.entries(options).map(([key, value]) => {
                optGroupedValues.push(`<optgroup  label="${key}">${value.join('')}</optgroup>`);
            })

            selectElement.innerHTML = `<option disabled selected>Kies materiaal...</option>` + optGroupedValues.join('');
            selectElement.removeAttribute('data-loading');
        })
        .catch((reason) => {
            console.error(reason)
            toast.error(`Something went wrong trying to get material data! ${reason.response?.status ? `(${reason.response.status})` : ''}`)
        })
}

function buildFilterQuery(filters: { materials: Array<any>; categories: Array<any>; }) {
    let filtersArray: { filters: { "$and": Array<any> } } = {
        filters: {
            "$and": []
        }
    };

    if (filters?.materials?.length > 0) {
        filtersArray.filters["$and"].push({
            "id": filters.materials
        });
    }

    if (filters?.categories?.length > 0) {
        filtersArray.filters["$and"].push({
            "category": filters.categories
        });
    }

    filtersArray.filters["$and"].push({
        "active": {
            "$eq": true
        },
        "salePrice": {
            "$notNull": true,
        }
    });

    return qs.stringify(filtersArray);
}

function addTemplateSpecificFilterSelectBox(widgetElement: HTMLElement, filters: { materials: Array<any>; categories: Array<any>; }) {
    let templateSpecificFilterSelect = widgetElement.querySelector('.templateSpecificFilterSelect');

    // Avoid adding the select box multiple times
    if (templateSpecificFilterSelect) {
        return;
    }

    // Create a select element. Give it a class so it can be styled with select2 and append it to the widget
    const filterSelect = document.createElement('select');
    filterSelect.classList.add('templateSpecificFilterSelect');
    filterSelect.multiple = true;

    // Get all materials and categories from the DOM
    const categories = Array.from(widgetElement.querySelectorAll('select[name=material] optgroup')) as HTMLOptGroupElement[];

    for (const category of categories) {
        const option = document.createElement('option');
        option.value = category.label;
        option.text = category.label;
        option.classList.add('category');
        filterSelect.appendChild(option);

        // Check if the category is in the template specific filters
        if (filters?.categories?.includes(category.label)) {
            option.selected = true;
        }

        // Get all materials in the category
        const materials = Array.from(category.querySelectorAll('option')) as HTMLOptionElement[];

        for (const material of materials) {

            const materialData = JSON.parse(material.getAttribute('data-material') ?? "{}");

            const option = document.createElement('option');
            option.value = material.value;
            option.text = materialData.internalSKU ?? material.text;
            filterSelect.appendChild(option);

            // Check if the material is in the template specific filters
            if (filters?.materials?.includes(parseInt(material.value))) {
                option.selected = true;
            }
        }
    }

    const filterSelectElement = widgetElement.appendChild(filterSelect);

    $(filterSelectElement).select2({
        width: '100%',
        placeholder: 'Filter op categorie of materiaal',
        allowClear: true,
        closeOnSelect: false,
        templateResult: function (data: LoadingData) {
            // We only really care if there is an element to pull classes from
            if (!data.element) {
                return data.text;
            }

            const $element = $(data.element);

            const $wrapper = $('<span></span>');
            $wrapper.addClass($element[0].className);

            $wrapper.text(data.text);

            return $wrapper;
        }
    });
}