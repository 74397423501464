import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Block from 'UI/App/Components/Block/Block';
import Button from 'UI/App/Components/Button/Button';
import Fieldset from 'UI/App/Components/Form/Fieldset';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import IF from 'UI/App/Components/Conditional/IF';
import PageHeader from 'UI/App/Components/PageHeader/PageHeader';
import ToggleSwitch from 'UI/App/Components/Form/ToggleSwitch/ToggleSwitch';

export default function CreateEditCalculationTabs() {
    const { authAxios } = useContext(FetchContext);
    const params = useParams();

    const [jsonData, setJsonData] = useState([]);
    const [ownerOptions, setOwnerOptions] = useState(null);
    const [owner, setOwner] = useState(null);
    const [title, setTitle] = useState('');

    const getOwners = useCallback(() => {
        const params = stringify({
            fields: ['id', 'name'],
            filters: {
                calculationSetting: {
                    id: {
                        $null: true
                    }
                }
            }
        });

        authAxios
            .get(`../owners?${params}`)
            .then(({ data }) => {
                setOwnerOptions(
                    (data?.data ?? []).map(({ id, name }) => {
                        return { value: id, label: name };
                    })
                );
            })
            .catch((exception) => {
                console.error(exception);
                toast.error(`Er ging iets mis met het ophalen van de eigenaren data!`);
            });
    }, [authAxios]);

    const loadFromStrapi = useCallback(
        (id) => {
            const params = stringify({
                populate: {
                    owner: {
                        fields: ['id', 'name']
                    },
                    data: true
                }
            });

            authAxios
                .get(`/calculation-setting/${id}?${params}`)
                .then(({ data }) => {
                    setOwner({ value: data.data?.owner?.id, label: data.data?.owner?.name ?? '' });
                    setTitle(data.data?.owner?.name ?? '');

                    // set the JSON to an array for jsx mapping and sort by `order`
                    setJsonData(Object.entries(data.data?.data).sort(([, a], [, b]) => a?.order - b?.order));
                })
                .catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het ophalen van de tabs data!`);
                });
        },
        [authAxios]
    );

    const addTab = useCallback(() => {
        setJsonData((current) => {
            const highestOrder = current.reduce((a, [, b]) => {
                return b?.order > a ? b.order : a;
            }, -1);

            current.push([
                'Nieuwe tab',
                {
                    order: highestOrder + 1,
                    entries: [],
                    hourlyRate: null,
                    projectManagementPercentage: 7.5,
                    isRecurring: false
                }
            ]);

            return [...current];
        });
    }, []);

    const addRecurringOption = useCallback((tabIndex) => {
        setJsonData((current) => {
            current[tabIndex][1].options.push({
                cost: null,
                label: null,
                value: null,
                isCustom: false,
                isChargedHourly: false,
                projectManagementPercentage: 7.5
            });

            return [...current];
        });
    }, []);

    const removeRecurringOption = useCallback((tabIndex, optionIndex) => {
        setJsonData((current) => {
            current[tabIndex][1].options.splice(optionIndex, 1);

            return [...current];
        });
    }, []);

    const saveTabsData = useCallback(
        async (target, id) => {
            target.disabled = true;

            // convert json data array back to object
            const postJSON = Object.fromEntries(jsonData.toSorted(([, a], [, b]) => a?.order - b?.order));

            if (id) {
                await authAxios
                    .put(`/calculation-setting/${id}`, {
                        data: {
                            data: postJSON
                        }
                    })
                    .then(() => {
                        toast.success(`De tabs zijn succesvol opgeslagen.`);
                    })
                    .catch((exception) => {
                        console.error(exception);
                        toast.error(`Er ging iets mis met het opslaan van de tabs!`);
                    });
            } else {
                await authAxios
                    .post(`/calculation-setting/`, {
                        data: {
                            owner: owner?.value,
                            data: postJSON
                        }
                    })
                    .then(() => {
                        toast.success(`De tabs zijn succesvol opgeslagen.`);
                    })
                    .catch((exception) => {
                        console.error(exception);
                        toast.error(`Er ging iets mis met het opslaan van de tabs!`);
                    });
            }

            target.disabled = false;
        },
        [authAxios, jsonData, owner?.value]
    );

    // on page load
    useEffect(() => {
        if (params?.tabsId) {
            loadFromStrapi(params.tabsId);
        } else {
            setTitle('Nieuwe tabs');

            getOwners();
        }
    }, [getOwners, loadFromStrapi, params?.tabsId]);

    return (
        <Grid columns='1'>
            <PageHeader title={title}>
                <Button
                    className='btn btn--round btn--black'
                    onClick={({ target }) => {
                        saveTabsData(target, params?.tabsId);
                    }}
                >
                    <Icon name='save' />
                </Button>
            </PageHeader>

            <IF condition={!params?.tabsId}>
                <div className='input-group'>
                    <label className='required' htmlFor='owner'>
                        eigenaar
                    </label>
                    <Select
                        name='owner'
                        id='owner'
                        placeholder='Selecteer een eigenaar'
                        options={ownerOptions}
                        value={owner}
                        onChange={(owner) => setOwner(owner)}
                    />
                </div>
            </IF>

            <div>
                <Button onClick={() => addTab()} title='tab toevoegen'>
                    Tab toevoegen <Icon name='plus' />
                </Button>
            </div>

            <Grid columns='1'>
                {jsonData.map(([name, data], tabIndex) => {
                    return (
                        <Block
                            key={`tab-${tabIndex}`}
                            name={`tab-${tabIndex}`}
                            title={name}
                            headerRightSideChildren={
                                <Button
                                    className='btn--black btn--round'
                                    onClick={() =>
                                        setJsonData((current) => {
                                            current.splice(tabIndex, 1);

                                            return [...current];
                                        })
                                    }
                                    title='Tab verwijderen'
                                >
                                    <Icon name='trash-can' />
                                </Button>
                            }
                        >
                            <pre style={{ maxHeight: '400px' }}>{JSON.stringify(data, undefined, 2)}</pre>

                            {/* name */}
                            <div className='input-group'>
                                <label className='required' htmlFor={`${name}__name`}>
                                    Naam
                                </label>
                                <input
                                    type='text'
                                    id={`${name}__name`}
                                    name={`${name}__name`}
                                    value={name}
                                    onChange={({ target }) => {
                                        setJsonData((current) => {
                                            current[tabIndex][0] = target.value;

                                            return [...current];
                                        });
                                    }}
                                    min='1'
                                    required
                                />
                            </div>

                            {/* order */}
                            <div className='input-group'>
                                <label className='required' htmlFor={`${name}__order`}>
                                    Volgorde
                                </label>
                                <input
                                    type='number'
                                    id={`${name}__order`}
                                    name={`${name}__order`}
                                    value={data?.order + 1} // ++ = shorthand overriding assignment, which is not what we're doing
                                    onChange={({ target }) => {
                                        setJsonData((current) => {
                                            current[tabIndex][1].order = parseInt(target.value) - 1;

                                            return [...current];
                                        });
                                    }}
                                    step='1'
                                    min='1'
                                    required
                                />
                            </div>

                            {/* recurring */}
                            <div className='input-group' style={{ display: 'flex', flexDirection: 'column' }}>
                                <label htmlFor={`${name}__recurring`}>Is herhalend</label>
                                <ToggleSwitch
                                    attributes={{ id: `${name}__recurring`, name: `${name}__recurring` }}
                                    checked={data?.isRecurring}
                                    onChange={({ target }) => {
                                        setJsonData((current) => {
                                            current[tabIndex][1].isRecurring = target.checked;

                                            if (target.checked) {
                                                current[tabIndex][1].options = [];
                                                current[tabIndex][1].hourlyRate = null;
                                                current[tabIndex][1].projectManagementPercentage = null;
                                            } else if (!target.checked) {
                                                delete current[tabIndex][1].options;
                                            }

                                            return [...current];
                                        });
                                    }}
                                />
                            </div>
                            <IF condition={data?.isRecurring}>
                                <>
                                    <Button onClick={() => addRecurringOption(tabIndex)} title='optie toevoegen'>
                                        <Icon name='plus' />
                                    </Button>

                                    <Grid columns='1'>
                                        {(data?.options ?? []).map((option, optionIndex) => {
                                            return (
                                                <RecurringOption
                                                    key={optionIndex}
                                                    tabIndex={tabIndex}
                                                    optionIndex={optionIndex}
                                                    name={name}
                                                    data={option}
                                                    setJsonData={setJsonData}
                                                    removeRecurringOption={removeRecurringOption}
                                                />
                                            );
                                        })}
                                    </Grid>
                                </>
                            </IF>

                            {/* hourlyRate */}
                            <IF condition={!data?.isRecurring}>
                                <div className='input-group'>
                                    <label className='required' htmlFor={`${name}__hourly-rate`}>
                                        Uurloon
                                    </label>
                                    <input
                                        type='number'
                                        id={`${name}__hourly-rate`}
                                        name={`${name}__hourly-rate`}
                                        value={data?.hourlyRate ?? ''}
                                        onChange={({ target }) => {
                                            setJsonData((current) => {
                                                if (target.value === '') {
                                                    current[tabIndex][1].hourlyRate = null;
                                                } else {
                                                    current[tabIndex][1].hourlyRate = Number(target.value);
                                                }

                                                return [...current];
                                            });
                                        }}
                                        step='0.01'
                                        min='0'
                                        required
                                    />
                                </div>

                                {/* projectManagementPercentage */}
                                <div className='input-group'>
                                    <label className='required' htmlFor={`${name}__project-management-percentage`}>
                                        project management percentage
                                    </label>
                                    <input
                                        type='number'
                                        id={`${name}__project-management-percentage`}
                                        name={`${name}__project-management-percentage`}
                                        value={data?.projectManagementPercentage ?? ''}
                                        onChange={({ target }) => {
                                            setJsonData((current) => {
                                                if (target.value === '') {
                                                    current[tabIndex][1].projectManagementPercentage = null;
                                                } else {
                                                    current[tabIndex][1].projectManagementPercentage = Number(
                                                        target.value
                                                    );
                                                }
                                                return [...current];
                                            });
                                        }}
                                        step='0.01'
                                        min='0'
                                    />
                                </div>
                            </IF>
                        </Block>
                    );
                })}
            </Grid>
        </Grid>
    );
}

function RecurringOption({ tabIndex, optionIndex, name, data, setJsonData, removeRecurringOption }) {
    return (
        <div style={{ position: 'relative' }}>
            <Fieldset>
                <pre style={{ maxHeight: '400px' }}>{JSON.stringify(data, undefined, 2)}</pre>

                {/* type name */}
                <div className='input-group'>
                    <label className='required' htmlFor={`${name}__type-name`}>
                        Soort naam
                    </label>
                    <input
                        type='text'
                        id={`${name}__type-name`}
                        name={`${name}__type-name`}
                        value={data?.label ?? ''}
                        onChange={({ target }) => {
                            setJsonData((current) => {
                                current[tabIndex][1].options[optionIndex].label = target.value;

                                return [...current];
                            });
                        }}
                    />
                </div>

                {/* type value */}
                <div className='input-group'>
                    <label className='required' htmlFor={`${name}__type-value`}>
                        Soort waarde
                    </label>
                    <input
                        type='text'
                        id={`${name}__type-value`}
                        name={`${name}__type-value`}
                        value={data?.value ?? ''}
                        onChange={({ target }) => {
                            setJsonData((current) => {
                                current[tabIndex][1].options[optionIndex].value = target.value;

                                return [...current];
                            });
                        }}
                    />
                </div>

                {/* is custom */}
                <div className='input-group' style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor={`${name}__custom`}>Is custom</label>
                    <ToggleSwitch
                        attributes={{ id: `${name}__custom`, name: `${name}__custom` }}
                        checked={data?.isCustom ?? false}
                        onChange={({ target }) => {
                            setJsonData((current) => {
                                current[tabIndex][1].options[optionIndex].isCustom = target.checked;

                                if (target.checked) {
                                    current[tabIndex][1].options[optionIndex].isChargedHourly = true;
                                } else {
                                    if (typeof current[tabIndex][1].options[optionIndex]?.cost == 'object') {
                                        current[tabIndex][1].options[optionIndex].cost = null;
                                    }
                                }

                                return [...current];
                            });
                        }}
                    />
                </div>

                {/* is charged hourly */}
                <IF condition={!data?.isCustom}>
                    <div className='input-group' style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor={`${name}__charged-hourly`}>Is kosten per uur</label>
                        <ToggleSwitch
                            attributes={{ id: `${name}__charged-hourly`, name: `${name}__charged-hourly` }}
                            checked={data?.isChargedHourly ?? false}
                            onChange={({ target }) => {
                                setJsonData((current) => {
                                    current[tabIndex][1].options[optionIndex].isChargedHourly = target.checked;

                                    if (target.checked) {
                                        current[tabIndex][1].options[optionIndex].cost = null;
                                    } else {
                                        current[tabIndex][1].options[optionIndex].cost = {
                                            yearly: null,
                                            monthly: null
                                        };
                                    }

                                    return [...current];
                                });
                            }}
                        />
                    </div>
                </IF>

                {/* costs */}
                <IF condition={data?.isChargedHourly || data?.isCustom}>
                    <div className='input-group'>
                        <label className='required' htmlFor={`${name}__costs-hourly`}>
                            Kosten per uur
                        </label>
                        <input
                            type='number'
                            id={`${name}__costs-hourly`}
                            name={`${name}__costs-hourly`}
                            value={typeof data?.cost !== 'object' ? data?.cost ?? '' : ''}
                            onChange={({ target }) => {
                                setJsonData((current) => {
                                    if (target.value === '') {
                                        current[tabIndex][1].options[optionIndex].cost = null;
                                    } else {
                                        current[tabIndex][1].options[optionIndex].cost = Number(target.value);
                                    }

                                    return [...current];
                                });
                            }}
                            step='0.01'
                            min='0'
                            required
                        />
                    </div>
                </IF>
                <IF condition={!data?.isChargedHourly && !data?.isCustom}>
                    <Grid columns='2'>
                        {/* yearly */}
                        <div className='input-group'>
                            <label className='required' htmlFor={`${name}__costs-yearly`}>
                                Kosten jaardelijks
                            </label>
                            <input
                                type='number'
                                id={`${name}__costs-yearly`}
                                name={`${name}__costs-yearly`}
                                value={data?.cost?.yearly ?? ''}
                                onChange={({ target }) => {
                                    setJsonData((current) => {
                                        if (typeof current[tabIndex][1].options[optionIndex]?.cost !== 'object') {
                                            current[tabIndex][1].options[optionIndex].cost = {
                                                yearly: null,
                                                monthly: null
                                            };
                                        }

                                        if (target.value === '') {
                                            current[tabIndex][1].options[optionIndex].cost.yearly = null;
                                        } else {
                                            current[tabIndex][1].options[optionIndex].cost.yearly = Number(
                                                target.value
                                            );
                                        }

                                        return [...current];
                                    });
                                }}
                                step='0.01'
                                min='0'
                                required
                            />
                        </div>

                        {/* monthly */}
                        <div className='input-group'>
                            <label className='required' htmlFor={`${name}__costs-monthly`}>
                                Kosten maandelijks
                            </label>
                            <input
                                type='number'
                                id={`${name}__costs-monthly`}
                                name={`${name}__costs-monthly`}
                                value={data?.cost?.monthly ?? ''}
                                onChange={({ target }) => {
                                    setJsonData((current) => {
                                        if (typeof current[tabIndex][1].options[optionIndex]?.cost !== 'object') {
                                            current[tabIndex][1].options[optionIndex].cost = {
                                                yearly: null,
                                                monthly: null
                                            };
                                        }
                                        if (target.value === '') {
                                            current[tabIndex][1].options[optionIndex].cost.monthly = null;
                                        } else {
                                            current[tabIndex][1].options[optionIndex].cost.monthly = Number(
                                                target.value
                                            );
                                        }

                                        return [...current];
                                    });
                                }}
                                step='0.01'
                                min='0'
                                required
                            />
                        </div>
                    </Grid>
                </IF>

                {/* projectManagementPercentage */}
                <div className='input-group'>
                    <label className='required' htmlFor={`${name}__${tabIndex}__project-management-percentage`}>
                        project management percentage
                    </label>
                    <input
                        type='number'
                        id={`${name}__${tabIndex}__project-management-percentage`}
                        name={`${name}__${tabIndex}__project-management-percentage`}
                        value={data?.projectManagementPercentage ?? ''}
                        onChange={({ target }) => {
                            setJsonData((current) => {
                                if (target.value === '') {
                                    current[tabIndex][1].options[optionIndex].projectManagementPercentage = null;
                                } else {
                                    current[tabIndex][1].options[optionIndex].projectManagementPercentage = Number(
                                        target.value
                                    );
                                }
                                return [...current];
                            });
                        }}
                        step='0.01'
                        min='0'
                    />
                </div>
            </Fieldset>

            <Button
                className='btn--transparent btn--round btn--icon-red'
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0
                }}
                onClick={() => removeRecurringOption(tabIndex, optionIndex)}
                title='optie verwijderen'
            >
                <Icon name='times' />
            </Button>
        </div>
    );
}
