import BasicBarcodeGenerator from "./1dBarcodeGenerator"
import QRcodeGenerator from "./2dBarcodeGenerator"
import { useCallback, useContext, useEffect, useState, useRef } from 'react';

export default function BarcodeGenerator(props) {

    useEffect(() => {
        if (!props?.d1 && !props.d2) {
            console.warn("No type given falling back to D1")
        }
        if (!props?.setBase64 && !props?.download) {
            console.error("No setBase64 has been set")
        }
    }, [props]);
    return (<>

        {props?.d1 && <BasicBarcodeGenerator {...props} />}
        {props?.d2 && <QRcodeGenerator {...props} />}

        {(!props?.d2 && !props.d1) && <BasicBarcodeGenerator {...props} />}

    </>)
}