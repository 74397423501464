import {formatCurrency, formatNumberValue, formatTime, parseTimeToNumber} from "App/Util/format";
import Table from "UI/App/Components/Table/Table";
import {_PROJECT_MANAGEMENT_RATE} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import {planningSuffix, timeRecurrenceToTargetTimeRecurrence} from "UI/App/Partials/Content/Calculations/HJMG/steps/util";
import {useEffect} from "react";

export default function ActivityResultBlock({activity, data, registerResult, results}) {

    const totalTime = getTotalTime(data);
    const totalCost = getTotalCost(data);
    const projectManagementTime = totalTime * (data?.projectManagementPercentage / 100);
    const projectManagementCost = projectManagementTime * _PROJECT_MANAGEMENT_RATE;

    let monthlyCost, yearlyCost, monthlyTime, yearlyTime, monthlyProjectManagementTime, yearlyProjectManagementTime, monthlyProjectManagementCost, yearlyProjectManagementCost;

    if (data?.isRecurring) {
        const monthlyEntries = (data.entries ?? [])?.filter(e => e?.billingPeriod === 'monthly');
        const yearlyEntries = (data.entries ?? [])?.filter(e => e?.billingPeriod === 'yearly');

        monthlyCost = Number(monthlyEntries?.reduce((total, entry) => Number(total) + Number(entry.total), 0) ?? 0);
        monthlyTime = Number(monthlyEntries?.reduce((total, entry) => Number(total) + Number(parseTimeToNumber(entry.time)), 0) ?? 0);

        yearlyCost = Number(yearlyEntries?.reduce((total, entry) => Number(total) + Number(entry.total), 0) ?? 0);
        yearlyTime = Number(yearlyEntries?.reduce((total, entry) => Number(total) + Number(parseTimeToNumber(entry.time)), 0) ?? 0);

        monthlyProjectManagementTime = monthlyEntries?.reduce((total, entry) => Number(total) + Number(timeRecurrenceToTargetTimeRecurrence(parseTimeToNumber(entry.time), entry?.recurringTaskPlanning, 'monthly')) * ((entry?.work?.projectManagementPercentage ?? 0) / 100), 0);
        monthlyProjectManagementCost = monthlyProjectManagementTime * _PROJECT_MANAGEMENT_RATE;

        yearlyProjectManagementTime = yearlyEntries?.reduce((total, entry) => Number(total) + Number(parseTimeToNumber(entry.time)) * ((entry?.work?.projectManagementPercentage ?? 0) / 100), 0);
        yearlyProjectManagementCost = yearlyProjectManagementTime * _PROJECT_MANAGEMENT_RATE;
    }

    let result = [];

    if (data.entries !== undefined) {
        result = [
            ...data.entries.map(entry => [
                entry.description,
                entry.time ? formatTime(parseTimeToNumber(entry.time)) + planningSuffix(entry?.recurringTaskPlanning) : <i style={{color: 'gray'}}>N.v.t.</i>,
                formatCurrency(entry.total) + planningSuffix(entry?.billingPeriod),
                data?.isRecurring ?
                    entry?.work?.projectManagementPercentage ?
                        formatTime(parseTimeToNumber(entry.time)) + " x " + formatNumberValue(entry?.work?.projectManagementPercentage, 'procent') + " = " + formatTime(parseTimeToNumber(entry.time) * (entry?.work?.projectManagementPercentage / 100)) + planningSuffix(entry?.recurringTaskPlanning)
                        : null
                    : undefined
            ])
        ];
    }

    if (data?.projectManagementPercentage) {
        result.push(
            [
                <b>Totaal exl. project management</b>,
                <b>{formatTime(totalTime)}</b>,
                <b>{formatCurrency(totalCost)}</b>
            ],
            [
                <span>Projectmanagement ({data?.projectManagementPercentage}%)</span>,
                formatTime(projectManagementTime),
                formatCurrency(projectManagementCost)
            ]
        );
    }

    if (data?.isRecurring) {
        result.push(
            [
                <b>Totaal per maand</b>,
                <b>{formatTime(monthlyTime)}</b>,
                <b>{formatCurrency(monthlyCost)}</b>,
                <b>{formatTime(monthlyProjectManagementTime)} x {formatCurrency(_PROJECT_MANAGEMENT_RATE)} = {formatCurrency(monthlyProjectManagementCost)}</b>
            ],
            [
                <b>Totaal per jaar</b>,
                <b>{formatTime(yearlyTime)}</b>,
                <b>{formatCurrency(yearlyCost)}</b>,
                <b>{formatTime(yearlyProjectManagementTime)} x {formatCurrency(_PROJECT_MANAGEMENT_RATE)} = {formatCurrency(yearlyProjectManagementCost)}</b>
            ]
        );
    } else {
        result.push(
            [
                <b>Totaal</b>,
                <b>{formatTime(totalTime + projectManagementTime)}</b>,
                <b>{formatCurrency(totalCost + projectManagementCost)}</b>
            ]
        )
    }

    function getTotalTime(data) {
        return Number((data.entries ?? [])?.reduce((total, entry) => Number(total) + parseTimeToNumber(entry.time), 0) ?? 0)
    }

    function getTotalCost(data) {
        return Number((data.entries ?? [])?.filter(entry => !isNaN(Number(entry.total)))?.reduce((total, entry) => Number(total) + Number(entry.total), 0) ?? 0)
    }


    useEffect(() => {
        if (!data?.isRecurring) {
            registerResult(activity, {
                time: totalTime + projectManagementTime,
                cost: totalCost + projectManagementCost
            });
        } else {

            if (data.entries !== undefined) {

                registerResult(
                    activity,
                    data.entries.map((entry, index) => {
                        const margin = results[activity]?.[index]?.margin ?? 20;
                        const marginMultiplier = (margin / 100) + 1;

                        const projectManagementTime = entry.time * ((entry?.work?.projectManagementPercentage ?? 0) / 100);
                        const projectManagementCost = projectManagementTime * _PROJECT_MANAGEMENT_RATE;

                        return {
                            description: entry.description,
                            cost: Number(entry.total),
                            billingPeriod: entry.billingPeriod,
                            margin: margin,
                            subTotal: entry.total + projectManagementCost,
                            total: (entry.total + projectManagementCost) * marginMultiplier,
                            quote: results[activity]?.[index]?.quote ?? entry.total * ((entry?.work?.projectManagementPercentage ?? 0) / 100 + 1) * marginMultiplier,
                        }
                    })
                );
            }
        }
    }, [totalTime, totalCost, monthlyTime, yearlyTime, monthlyCost, yearlyCost]);

    return (
        <>
            {data?.entries !== undefined ? data?.entries.length !== 0 ? <>
                <div className={'hjmg__calculation__result-block'}>
                    <h3>{activity}</h3>
                    <Table
                        headers={['Omschrijving', 'Uren', 'Kosten', ...(data?.isRecurring ? ['Projectmanagement'] : [])]}
                        data={result}
                    />
                </div>
            </> : <></> : <></>}
        </>
    )
}
