import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ConfigContext } from 'App/Strapi/ConfigContext';
import Popup, { closePopup } from 'UI/App/Components/Popup/Popup';
import { AuthContext } from 'App/Strapi/AuthProvider';
import Icon from 'UI/App/Components/Icon/Icon';
import ToggleSwitch from 'UI/App/Components/Form/ToggleSwitch/ToggleSwitch';

export const priorityOptions = [
    {
        label: 'laag',
        value: 'low'
    },
    {
        label: 'normaal',
        value: 'normal'
    },
    {
        label: 'hoog',
        value: 'high'
    }
];

/**
 * popup voor Todo task aanmaken en update
 */
export default function PopupPostAndUpdateTask({ listID, taskFormData, setTaskFormData, updateTaskID, setTasks }) {
    const { appConfig } = useContext(ConfigContext);
    const { msGraph } = useContext(AuthContext);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    /**
     * Submits the task to the user's MS Todo list.
     *
     * @param {Event} e
     */
    async function submitTask(e) {
        e.preventDefault();

        // disable the submit button
        setSubmitDisabled(true);

        // remember listID in this scope to make sure the function uses the right id after creating the todo list
        let _listID = listID;

        // check if todo list exists
        if (!_listID) {
            // create todo list
            await msGraph.post('me/todo/lists', { displayName: appConfig?.organisation ?? 'Emily' })
                .then(({ data }) => {
                    // set listID
                    setTasks((prev) => {
                        return { ...prev, listID: data.id }
                    });
                    _listID = data.id;
                })
                .catch((error) => {
                    error?.message ? toast.error(error) : toast.error('Er is een probleem opgetreden bij het aanmaken van de takenlijst');

                    console.error(error);
                });
        }

        // check again if listID exists
        if (!_listID) {
            // stop function
            return;
        }

        // create todo task object
        const task = {
            title: taskFormData.title,
            body: {
                content: taskFormData.content,
                contentType: 'text'
            },
            status: ['notStarted', 'completed'].includes(taskFormData.status) ? taskFormData.status : 'notStarted',
            importance: ['low', 'normal', 'high'].includes(taskFormData.priority.value)
                ? taskFormData.priority.value
                : 'normal',
            isReminderOn: taskFormData.isReminder,
            reminderDateTime: taskFormData.isReminder
                ? {
                      dateTime: `${taskFormData.date}:00.0000000`,
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
                : null
        };

        if (updateTaskID?.id) {
            // update task in todo list
            await msGraph.patch(`me/todo/lists/${_listID}/tasks/${updateTaskID.id}`, task, { 'Content-Type': 'application/json' })
                .then((response) => {
                    // update tasks
                    setTasks((prev) => {
                        // copy the "value" property
                        const newValue = prev.value;
                        
                        // add "Z" to the end of the reminder dateTime
                        if (typeof response?.reminderDateTime?.dateTime === 'string') {
                            response.reminderDateTime.dateTime = response.reminderDateTime.dateTime + 'Z';
                        }
                        
                        // replace the task at key with the new response
                        newValue[updateTaskID.key] = response;
                        
                        // return prev with new value array
                        return { ...prev, value: newValue };
                    });

                    toast.success('Taak geüpdatet.');
                    closePopup();
                })
                .catch((error) => {
                    error?.message ? toast.error(error.message) : toast.error('Er is een probleem opgetreden bij het updaten van de taak.');

                    console.error(error);
                });
        } else {
            // post task to todo list
            await msGraph.post(`me/todo/lists/${_listID}/tasks`, task, { 'Content-Type': 'application/json' })
                .then((response) => {
                    // add bool variable to make sure setState doesn't run twice on .post() after also creating the list.
                    let tasksStateUpdated = false;

                    // push new task
                    setTasks((prev) => {
                        // check if this setState didn't run twice.
                        if (tasksStateUpdated) {
                            return { ...prev };
                        }
                        
                        // add "Z" to the end of the reminder dateTime
                        if (typeof response?.reminderDateTime?.dateTime === 'string') {
                            response.reminderDateTime.dateTime = response.reminderDateTime.dateTime + 'Z';
                        }

                        // copy the "value" property
                        const newValue = prev.value;

                        // push the task to the beginning of the value array
                        newValue.unshift(response);

                        tasksStateUpdated = true;

                        // return prev with new value array
                        return { ...prev, value: newValue };
                    });

                    toast.success('Taak aangemaakt.');
                    closePopup();
                })
                .catch((error) => {
                    error?.message ? toast.error(error.message) : toast.error('Er is een probleem opgetreden bij het aanmaken van de taak.');

                    console.error(error);
                });

            // close the popup
            closePopup();
        }

        // disable the submit button
        setSubmitDisabled(false);
    }

    // return popup
    return (
        <Popup title='Notitie' popupID='add-and-update-task'>
            <form onSubmit={submitTask} key={taskFormData['key']}>
                {/* title */}
                <div className='input-group'>
                    <label className='required' htmlFor='note__title'>
                        Titel
                    </label>
                    <input
                        type='text'
                        id='note__title'
                        name='note__title'
                        placeholder='Titel'
                        value={taskFormData.title}
                        onChange={({ target }) => setTaskFormData((task) => ({ ...task, title: target.value }))}
                        onBlur={({ target }) =>
                            setTaskFormData((task) => ({ ...task, title: (target.value ?? '').trim() }))
                        }
                        required
                    />
                </div>

                {/* content */}
                <div className='input-group'>
                    <label className='required' htmlFor='note__content'>
                        Bericht
                    </label>
                    <textarea
                        id='note__content'
                        name='note__content'
                        placeholder='Bericht'
                        value={taskFormData.content}
                        onChange={({ target }) => setTaskFormData((task) => ({ ...task, content: target.value }))}
                        onBlur={({ target }) =>
                            setTaskFormData((task) => ({ ...task, content: (target.value ?? '').trim() }))
                        }
                        required
                        rows='10'
                    />
                </div>

                {/* priority */}
                <div className='input-group'>
                    <label className='required' htmlFor='task__priority'>
                        Prioriteit
                    </label>
                    <Select
                        name='task__priority'
                        id='task__priority'
                        placeholder='Kies een prioriteit'
                        options={priorityOptions}
                        value={taskFormData.priority}
                        onChange={(priority) => setTaskFormData((task) => ({ ...task, priority }))}
                    />
                </div>

                {/* reminder */}
                <div className='input-group' style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor='task__isReminder'>Reminder</label>
                    <ToggleSwitch
                        attributes={{ id: 'task__isReminder', name: 'task__isReminder' }}
                        checked={taskFormData.isReminder}
                        onChange={({ target }) => setTaskFormData((task) => ({ ...task, isReminder: target.checked }))}
                    />
                </div>

                {/* reminder: date */}
                <div className='input-group' style={{ display: !taskFormData.isReminder ? 'none' : undefined }}>
                    <label htmlFor='task__date' className='required'>
                        Datum en tijd
                    </label>
                    <input
                        type='datetime-local'
                        id='task__date'
                        name='task__date'
                        value={taskFormData.date}
                        onChange={({ target }) => setTaskFormData((task) => ({ ...task, date: target.value }))}
                        required={taskFormData.isReminder}
                    />
                </div>

                {/* submit */}
                <div className='input-group'>
                    <button type='submit' disabled={submitDisabled}>
                        Todo opslaan <Icon name='save' />
                    </button>
                </div>
            </form>
        </Popup>
    );
}
