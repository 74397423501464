import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import capitalize from "lodash/capitalize";
import qs from "qs";
import {toast} from "react-toastify";
import Select from "react-select";
import IF from "UI/App/Components/Conditional/IF";
import {CalculationProviderContext} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import {getSelectedValue} from "App/Util/select2";


export default function ChecklistSelect({checklistData, setChecklistData, chosenLanguage}) {
    const fetchContext = useContext(FetchContext);
    const {
        checklistTemplateId,
        setChecklistTemplateId,
        customerReference,
    } = useContext(CalculationProviderContext);
    const [chosenChecklist, setChosenChecklist] = useState(null);
    const [checklistTemplates, setChecklistTemplates] = useState([]);

    // Effect on language
    useEffect(() => {
        if(checklistData.length !== 0){
            setChosenChecklist({
                inputs : checklistData,
                value: checklistTemplateId,
            });
        }

        if (checklistTemplates?.length === 0) {
            const query = qs.stringify({
                populate: 'inputs',
                fields: ['id', 'name'],
                filters: {
                    $or: [
                        {
                            owner: {
                                $eqi: 'hjmg'
                            }
                        },
                        {
                            owner: {
                                $eqi: 'both'
                            }
                        }
                    ]
                }
            })
            fetchContext.authAxios.get(`/calculations/checklists/templates?${query}`).then(({ data: { data } }) => {
                setChecklistTemplates(data.map((checklistTemplate) => ({
                    label: checklistTemplate.name,
                    value: checklistTemplate.id,
                    inputs: checklistTemplate.inputs,
                })));
            }).catch((exception) => {
                toast.error("Er is iets misgegaan bij het ophalen van de checklists!");
                console.error(exception);
            });
        }
    }, [checklistData])

    const updateChecklist = () => {

        let checklistData = [];
        setChecklistData([]);
        for (const element of document.getElementsByClassName('checklistInput')) {
            checklistData.push({
                type: element.type,
                name: element.getAttribute('name'),
                value: element.value,
                numberOfLines: Number(element.getAttribute('rows') ?? 1),
                dutchName: element.getAttribute('data-dutch-name'),
                englishName: element.getAttribute('data-english-name'),
            })
        }

        setChecklistData(checklistData)
    }

    return (
        <div className="form__content">
            <div>
                <label htmlFor="checklistSelect">Kies een checklist:</label>
                <Select
                    options={checklistTemplates}
                    onChange={(e) => {
                        setChosenChecklist(e);
                        setChecklistTemplateId(e.value);
                        setChecklistData(e.inputs);
                    }}
                    value={getSelectedValue(checklistTemplateId, checklistTemplates)}
                    noOptionsMessage={() => "Geen checklists gevonden"}
                />
            </div>
            <IF condition={Array.isArray(chosenChecklist?.inputs)}>
                <hr />
                <br/>
                {chosenChecklist?.inputs.map((input, key) => (
                    <div className="form__item grid__12" key={key}>
                        <label
                            htmlFor={`${input.name}__select`}
                            className="input__label"
                        >
                            {capitalize((chosenLanguage === 'nl' ? input.dutchName : input.englishName) ?? input.name)}:
                        </label>
                        {
                            input?.numberOfLines === 1 ? (
                                <input
                                    onChange={() => updateChecklist()}
                                    checklistid={input.id}
                                    className={"checklistInput"}
                                    type={"text"}
                                    name={input.name}
                                    defaultValue={input.value ? input.value : input.name === 'customerReference' ? customerReference : ''}
                                    id={`${input.name}__select`}
                                    data-dutch-name={input.dutchName}
                                    data-english-name={input.englishName}
                                />
                            )
                            :
                            (
                                <textarea
                                    rows={input?.numberOfLines ?? 2}
                                    onChange={() => updateChecklist()}
                                    checklistid={input.id}
                                    className={"checklistInput"}
                                    name={input.name}
                                    defaultValue={input.value}
                                    id={`${input.name}__select`}
                                    data-dutch-name={input.dutchName}
                                    data-english-name={input.englishName}
                                ></textarea>
                            )
                        }
                    </div>
                ))}
            </IF>
        </div>
    )
}
