/**
 * Change the theme programmatically to define/distinguish the dev environment
 * @param {boolean} ignoreUrl set to true to enable dev theme without checking url
 */
export function setDevTheme(ignoreUrl = false) {

    if (ignoreUrl || window.location.href.startsWith("https://pretore.hjmediagroep.dev/")) {
        console.warn("[Alert] Changing theme via code, Its possible that not all colors will work as intended");
        setCssVariableColor('--primary', "#a83232")
        setCssVariableColor('--primary-medium', "#fa9696")
        setCssVariableColor('--primary-light', "#fadcdc")
        setCssVariableColor('--text-primary', "#a83232")
    } else if (window.location.href.startsWith("http://localhost:3000/")) {
        console.warn("[Alert] Changing theme via code, Its possible that not all colors will work as intended");
        setCssVariableColor('--primary', "#324ea8")
        setCssVariableColor('--primary-medium', "#71b5da")
        setCssVariableColor('--primary-light', "#dce6fa")
        setCssVariableColor('--text-primary', "#3242a8")
    } else {
        setCssVariableColor('--primary', "#0089B4")
        setCssVariableColor('--primary-medium', "#C4E2EC")
        setCssVariableColor('--primary-light', "#E8F4F8")
        setCssVariableColor('--text-primary', "#0089B4")
    }

}

/**
 * Get css color based on Variable name
 * @param {string} propertyName The --variable name given to the css property
 */
export function getCssVariableColor(propertyName: string) {
    let color = document.body.style.getPropertyValue(propertyName.toString());
    return color;
}

/**
 * Set css Variable color
 * * @param {string} propertyName The --variable name given to the css property
 * * @param {string} color color to set
 * * @param {string} errorMask the 'fallback' color incase color is invalid
 */
export function setCssVariableColor(propertyName: string, color: string, errorMask = "#a020f0") {


    if (color.length <= 7 && !/^#[0-9A-F]{6}$/i.test(color)) {
        console.error("Invalid rgb Hex");
        color = errorMask

    } else if (color.length > 7 && !/^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(color)) {
        console.error("Invalid rgbA Hex");
        color = errorMask

    }

    document.body.style.setProperty(propertyName.toString(), color.toString());


}
/**
 * Set css Variable value, if possible use setCssVColor.
 * * @param {string} propertyName The --variable name given to the css property
 * * @param {string} value color to set
 */
export function setCssVariableValue(propertyName: string, value: string | number) {

    document.body.style.setProperty(propertyName.toString(), value.toString());
}

export function SvgToHtml(unsafe: string) {
    return unsafe.replace(/</g, "%3C")
        .replace(/>/g, "%3E")
        .replace(/"/g, "'")
        .replace(/#/g, "%23");
}
