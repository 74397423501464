import Select from "react-select";
import {getSelectedValue} from "App/Util/select2";
import React, {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";
import Grid from "UI/App/Components/Grid/Grid";
import Boolean from "UI/App/Components/Form/Boolean";
import {toast} from "react-toastify";

export default function MaterialPopupContent({
    onSubmit,
    defaultMaterialId,
    defaultUnit,
    defaultWarehouse,
    defaultQuantity,
    maximumQuantity,
    buttonText,
    materialLocked,
    displayAllWarehouses,
    writeOffMaterial
}) {
    const { authAxios } = useContext(FetchContext);

    const [materials, setMaterials] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehousesLoaded, setWarehousesLoaded] = useState(false);

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedQuantity, setSelectedQuantity] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    const [chosenSpotSize,setChosenSpotSize] = useState(null);
    const [spotSizes,setSpotSizes] = useState([
        {
            label:'Europallet (80x120cm.)',
            value:'Europallet (80x120cm.)'
        },
        {
            label:'Pallet (80x60cm.)',
            value:'Pallet (80x60cm.)'
        },
        {
            label:'Mini pallet (40x60cm.)',
            value:'Mini pallet (40x60cm.)'
        },
        {
            label:'A4-doos (30x22cm.)',
            value:'A4-doos (30x22cm.)'
        },
        {
            label:'Anders',
            value:'Anders'
        }
    ]);

    const [selectedStorageSpot, setSelectedStorageSpot] = useState(null);
    const [storageSpots, setStorageSpots] = useState(null);

    /**
     * Get the materials from the API
     */
    useEffect(() => {
        authAxios
            .get('/logistics/materials/all')
            .then(({data}) => {
                const materials = data
                    .filter(material => material.active === true)
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map(material => ({
                        category: material.category,
                        value: material.id,
                        label: material.calculationDescription,
                    }));

                const categories = materials
                    .map(material => material.category)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort();

                setMaterials(
                    categories.map(category => ({
                        label: category,
                        options: materials
                            .filter(material => material.category === category)
                            .sort((a, b) => a.label.localeCompare(b.label))
                    }))
                );
            }).catch((exception) => {
                console.error(exception);
            });
    }, [authAxios]);

    useEffect(() => {
        loadDefaults();
    }, [defaultMaterialId, writeOffMaterial,defaultUnit, defaultQuantity, defaultWarehouse]);

    useEffect(() => {
        if(selectedWarehouse !== null){
            // Get spots when warehouse is selected
            authAxios
                .get(`logistics/warehouses/${selectedWarehouse}?populate=storage.stock.material`)
                .then(({data}) => {
                    let options = [];

                    for(const storageSpot of data.data?.storage){
                        if(storageSpot?.stock){
                            // If spot is filled with another material skip
                            if(storageSpot.stock?.material?.id !== defaultMaterialId) continue;
                        }

                        options.push({
                            label:`${storageSpot.name} ${storageSpot?.size} ${storageSpot?.stock ? 'Bezet' : ''}`,
                            value:storageSpot.id,
                            taken:storageSpot?.stock ? true : false,
                        })
                    }

                    setStorageSpots(options)
                }).catch((exception) => {
                console.error(exception);
            });
        }
    }, [selectedWarehouse,chosenSpotSize]);

    async function loadDefaults(){
        if (defaultMaterialId) {
            setSelectedMaterial(defaultMaterialId);
            setSelectedUnit( await (new MaterialUnitMutator()).obtainUnit(defaultMaterialId));
        }

        if (defaultQuantity) {
            setSelectedQuantity(defaultQuantity);
        }

        if (defaultWarehouse) {
            setSelectedWarehouse(defaultWarehouse);
        }

        if(writeOffMaterial){
            setChosenSpotSize({
                label:writeOffMaterial.spot.size,
                value:writeOffMaterial.spot.size
            })

            setSelectedStorageSpot({
                label:writeOffMaterial.spot.name,
                value:writeOffMaterial.spot.id
            })
        }


    }

    useEffect(() => {
        if (!selectedMaterial) return;
        updateSelectedMaterial(selectedMaterial);
    }, [authAxios, selectedMaterial, defaultMaterialId])

    async function updateSelectedMaterial(selectedMaterial) {
        // Get material unit
        let materialUnit = await (new MaterialUnitMutator()).obtainUnit(selectedMaterial);

        // Set select unit type
        setSelectedUnit(materialUnit);

        setWarehousesLoaded(false);

        if (displayAllWarehouses) {
            authAxios.get(`/logistics/warehouses/all`).then(({data}) => {
                setWarehouses(
                    data.map(warehouse => ({
                        value: warehouse.id,
                        label: warehouse.name,
                    }))
                );
            }).catch((exception) => {
                console.error(exception);
            }).finally(() => {
                setWarehousesLoaded(true);
            });
        } else {
            authAxios.get(`/logistics/materials/${selectedMaterial}/stock`).then(({data}) => {
                const wareHouses = data.data.stock
                    .filter(stockItem => stockItem.total !== null)
                    .map(stockItem => ({
                        value: stockItem.warehouse.id,
                        label: stockItem.warehouse.name,
                        unit: stockItem.unit,
                    }))
                    .filter((value, index, self) => self.findIndex(item => item.value === value.value) === index)

                setWarehouses(wareHouses);
            }).catch((exception) => {
                console.error(exception);
            }).finally(() => {
                setWarehousesLoaded(true);
            });
        }
    }

    function selectEmptySpot(){
        let firstAvailableSpot = null;

        for(const spot of storageSpots){
            if(firstAvailableSpot !== null) break;

            if(!spot.taken){
                firstAvailableSpot = spot
            }
        }

        if(firstAvailableSpot === null){
            toast.warn('Geen positie beschikbaar voor dit formaat.');
        }else{
            toast.info(`Positie ${firstAvailableSpot.label} geselecteerd.`)
        }

        setSelectedStorageSpot(firstAvailableSpot);
    }


    function handleSubmit(event) {
        event.preventDefault();
        onSubmit(selectedMaterial, selectedUnit, selectedQuantity, selectedWarehouse,selectedStorageSpot?.value);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='input-group'>
                <label htmlFor='material'>Materiaal</label>
                <Select
                    id='material'
                    name='material'
                    value={getSelectedValue(selectedMaterial, materials)}
                    onChange={(material) => setSelectedMaterial(material.value)}
                    isMulti={false}
                    isClearable
                    options={materials}
                    isLoading={materials?.length === 0}
                    isDisabled={materialLocked}
                />
            </div>

            <div className='input-group'>
                <label htmlFor='quantity'>Aantal {selectedUnit}</label>
                <input
                    id='quantity'
                    type='number'
                    max={maximumQuantity}
                    placeholder='Aantal'
                    value={(selectedQuantity ?? '')}
                    onChange={(event) => setSelectedQuantity(event.target.value === '' ? '' : Number(event.target.value))}
                    name={'quantity'}
                />
            </div>

            <div className="input-group">
                <label htmlFor='warehouse'>Magazijn</label>
                <Select
                    id='warehouse'
                    value={getSelectedValue(selectedWarehouse, warehouses)}
                    onChange={(warehouse) => setSelectedWarehouse(warehouse.value)}
                    isMulti={false}
                    isClearable
                    options={warehouses}
                    noOptionsMessage={() => 'Geen magazijnen gevonden waarin dit materiaal beschikbaar is.'}
                    isLoading={!warehousesLoaded}
                />
            </div>

            <div className="input-group">
                <label htmlFor='warehouse'>Positie</label>
                <Grid columns={2} customColTemplate={'1fr 100px'}>
                    <span>
                        <Select
                            id='storageSpot'
                            value={selectedStorageSpot}
                            onChange={(storageSpot) => setSelectedStorageSpot(storageSpot)}
                            isMulti={false}
                            isClearable
                            options={storageSpots}
                            noOptionsMessage={() => 'Geen magazijnen gevonden waarin dit materiaal beschikbaar is.'}
                            isLoading={!warehousesLoaded}
                        />
                    </span>
                    <span>
                        <button disabled={selectedWarehouse === null} type={'button'} onClick={() => selectEmptySpot()}>
                            Kies voor mij
                        </button>
                    </span>
                </Grid>
            </div>

            <div>
                <button disabled={selectedQuantity === '' || selectedQuantity === null} style={{width: '100%'}} className='btn--primary'>{buttonText}</button>
            </div>
        </form>
    )
}
