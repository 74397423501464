import Icon from "UI/App/Components/Icon/Icon";
import {formatDateWithoutTime, getDateString} from "App/Util/format";
import Grid from "UI/App/Components/Grid/Grid";
import Popup from "UI/App/Components/Popup/Popup";
import React, {useEffect, useState} from "react";
import {getNotificationIcon} from "UI/App/Partials/Content/Dashboard/Blocks/Timer/Timer";

export default function TimerCustomEndTimePopup({
    alertMessage = null,
    currentTimer,
    onSubmit
}) {
    const [endTime, setEndTime] = useState(new Date().toISOString());

    useEffect(() => {
        setEndTime(new Date().toISOString());
    }, [currentTimer]);

    return (
        <Popup
            popupID='timer__custom-end-time'
            title='Uitklokken'
            style={{
                width: '400px',
            }}
            contentStyle={{
                minHeight: 'unset'
            }}
        >
            {alertMessage &&
                <>
                    <div className={`timer__alert error`}>
                        <Icon name={getNotificationIcon('error')} /><span>{alertMessage}</span>
                    </div>
                    <br/>
                </>
            }
            {formatDateWithoutTime(currentTimer?.start) !== formatDateWithoutTime(new Date().toISOString()) &&
                <Grid columns={2} style={{
                    backgroundColor: '#efefef',
                    padding: '10px 15px',
                    marginBottom: '1em'
                }}>
                    <strong>TIMER GESTART OP:</strong>
                    <span style={{ textAlign: 'right' }}>{formatDateWithoutTime(currentTimer?.start)}</span>
                </Grid>
            }
            <div className={'input-group'}>
                <label>Uitklok datum en tijd</label>
                <input
                    type='datetime-local'
                    value={getDateString(endTime)}
                    onChange={(e) => setEndTime(e.target.value)}
                />
            </div>
            <button
                className='btn'
                onClick={() => onSubmit(endTime)}
                style={{ width: '100%' }}
            >
                <Icon name={'clock'} /> Uitklokken
            </button>
        </Popup>
    )
}