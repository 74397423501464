import {formatCurrency, formatNumberValue, formatSmallCurrency, formatTime} from "App/Util/format";
import isObject from "lodash/isObject";
import {TCalculationDataResult, TCalculationTabData, TCalculationValue, TCalculationWidget, TSimpleCalculationMachineResult} from "UI/App/Partials/Content/Calculations/Calculations/calculation";

export default class CalculationResultFactory {

    /**
     * The original data directly from the calculation
     * @private
     */
    private readonly data: TCalculationDataResult;

    /**
     * The combined results and machines
     * @param data
     */
    private readonly resultsAndMachines: Array<{
        tabId: string,
        machineName: string,
        result: any,
        constants: any[]
    }> = [];

    constructor(data: TCalculationDataResult) {
        this.data = data;
        this.resultsAndMachines = this.combineResultsAndMachines();
    }

    public getSimpleMachineResults(): Record<string, Record<number, TSimpleCalculationMachineResult>> {
        // The object to fill with the results
        let machineResults: Record<string, Record<number, TSimpleCalculationMachineResult>> = {};

        // Loop through the results and machines
        for (const {machineName, result, constants} of this.resultsAndMachines) {
            // Get the data for the current machine and amount
            machineResults[machineName] = {};

            // Get the setup time object per amount
            const setupTime = result['Machine planning.Opstart uren'] as Record<string, number>;
            // Get the run time object per amount
            const runTime = result['Machine planning.Productie uren'];

            if (!setupTime || !runTime) {
                delete machineResults[machineName];
                continue;
            }

            // The hourly rate is the cost for running the machine WITHOUT any materials or operator wages
            const hourlyRate = Number(constants.find(({id}) => id.includes('const-hourlyRate'))?.value);
            // The operator hourly rate is the cost for an operator WITHOUT any materials or machine costs
            const employeeHourlyRate = Number(constants.find(({id}) => id.includes('const-employeeWage'))?.value);
            // The labor effort is the percentage of the runtime that an operator is working
            const laborEffort = Number(constants.find(({id}) => id.includes('const-laborEffort'))?.value);

            for (const amount of this.data.amounts) {
                const setupTimeInHours = setupTime[amount];
                const runtimeInHours = runTime[amount];
                const setupCostPerHour = hourlyRate + employeeHourlyRate;
                const runtimeCostPerHour = hourlyRate + (employeeHourlyRate * laborEffort / 100);


                machineResults[machineName][amount] = {
                    setupTimeInHours,
                    runtimeInHours,
                    setupCostPerHour,
                    runtimeCostPerHour,
                    setupCost: setupTimeInHours * setupCostPerHour,
                    runtimeCost: runtimeInHours * runtimeCostPerHour,
                }
            }
        }

        return machineResults;
    }

    public getSimpleMachineResultTableData() {
        // Create the table data array to fill
        const resultTableData: any[] = [];

        // Create the row headers array to fill
        let rowHeaders: any[] = [];

        // Get the simple machine results
        const simpleMachineResults = this.getSimpleMachineResults();

        // Loop through the simple machine results
        for (const [machineName, results] of Object.entries(simpleMachineResults)) {

            // If there are already results in the table, add an empty row to separate the machines
            if (resultTableData.length !== 0) {
                resultTableData.push({
                    attributes: {
                        style: {
                            backgroundColor: '#f6f6f6',
                        }
                    },
                    data: Array(this.data.amounts.length * 3 + this.data.amounts.length - 1).fill('')
                });
            }

            // The setup row for the machine
            const machineDataSetupRow = []

            // The runtime row for the machine
            const machineDataRuntimeRow = []

            // Add the row headers for the machine
            rowHeaders.push(
                [machineName, 'Instellen'],
                ['', 'Draaitijd'],
                ['', '']
            )

            // Loop through the results for the machine
            // Add the amount, cost per hour and total cost for both setup and runtime
            for (const amount of this.data.amounts) {
                const result = results?.[amount];

                if (!result) continue;

                // If there are not two values in the row, add empty values to add an empty cell in between each quantity result
                if (machineDataSetupRow.length !== 0 && machineDataRuntimeRow.length !== 0) {
                    machineDataSetupRow.push('');
                    machineDataRuntimeRow.push('');
                }

                // Add the setup and runtime results to the rows
                machineDataSetupRow.push(
                    formatTime(result.setupTimeInHours),
                    formatCurrency(result.setupCostPerHour),
                    formatCurrency(result.setupCost),
                );

                // Add the runtime results to the rows
                machineDataRuntimeRow.push(
                    formatTime(result.runtimeInHours),
                    formatCurrency(result.runtimeCostPerHour),
                    formatCurrency(result.runtimeCost),
                );
            }

            // Add the setup and runtime rows to the table
            resultTableData.push(machineDataSetupRow);
            resultTableData.push(machineDataRuntimeRow);
        }

        const headers = this.data.amounts.map(amount => ['', String(amount), 'Tarief', 'Totaal']).flat();
        headers.shift();

        // Add grey rows to separate the machines and add a dashed border to all rows except the ones in between the machines
        rowHeaders = rowHeaders.map((row, index) => ({
            attributes: {
                style: {
                    borderRight: index % 3 === 2 ? 'none' : 'dashed 1px lightgray',
                    backgroundColor: index % 3 === 2 ? '#f6f6f6' : 'inherit'
                }
            },
            data: row
        }));
        rowHeaders.pop();

        const structure: any[] = this.data.amounts.map(amount => ['100px', '100px', '100px', '100px']).flat();
        // Remove the last two elements of the array
        structure.pop();
        structure.pop();

        const lastItem = {
            minWidth: '100px',
            width: '100%'
        }
        structure.push(lastItem);

        return {
            headers: headers,
            rowHeaders,
            data: resultTableData,
            structure: structure
        }
    }

    public getSimpleManualLaborTableData() {
        // If there are no manual labor results, return false
        if (this.data.manualLabor?.length === 0 || !this.data.manualLabor) {
            return false;
        }

        let rowHeaders = [];
        const resultTableData = [];

        for (const manualLaborItem of (this.data.manualLabor ?? [])) {

            const manualLaborRow = [];

            for (const amount of this.data.amounts) {
                manualLaborRow.push(
                    formatTime(manualLaborItem.quantities[amount].minutes / 60),
                    formatCurrency(manualLaborItem.hourlyWage),
                    manualLaborItem.quantities[amount].price,
                    ''
                )
            }

            rowHeaders.push([manualLaborItem.description]);
            manualLaborRow.pop();
            resultTableData.push(manualLaborRow);
        }

        const headers = this.data.amounts.map(amount => ['', String(amount), 'Tarief', 'Totaal']).flat();
        headers.shift();

        rowHeaders = rowHeaders.map((row, index) => ({
            attributes: {
                style: {
                    borderRight: 'dashed 1px lightgray',
                }
            },
            data: row
        }));

        const structure: any[] = this.data.amounts.map(amount => ['100px', '100px', '100px', '100px']).flat();
        // Remove the last two elements of the array
        structure.pop();
        structure.pop();

        const lastItem = {
            minWidth: '100px',
            width: '100%'
        }
        structure.push(lastItem);

        return {
            headers,
            rowHeaders,
            data: resultTableData,
            structure
        };
    }

    public getSimpleMaterialTableData() {

        const resultTableData = [];
        let rowHeaders = [];

        // Get the important data from the material widgets
        const materialWidgetData = Object.entries(this.data.templateData.data)

            // Filter out any tabs that are not machine tabs
            .filter((tab: [string, any]): tab is [`${number}-${number}`, TCalculationTabData] => tab[1].widgets)

            // Filter out any tabs that are optional and not enabled
            .filter((tab) => this.data.optionalMachines[tab[0]] !== 'false')

            // Map the widgets to only the return widgets that contain the word 'Materiaal' in the title
            .map(([tabId, tabData]) => {
                const tabValues = this.data.values[tabId];

                // Get all material choice widgets from the tab
                let materialWidgets = tabData.widgets.filter(widget => widget.data.type === 'MaterialChoice');

                // Get all return widgets that match the material choice widgets
                const materialWidgetsWithReturnWidgets = materialWidgets.map((widget: TCalculationWidget) => {
                    // Get the return widgets by matching the id of the material choice widget
                    // The default title for a material return widget is "Materiaal.{widget-id}.[Something]"
                    const matchingReturnWidgets = tabData.widgets.filter((returnWidget: TCalculationWidget) => returnWidget.data.type === 'ReturnWidget' && returnWidget.data.title.includes(widget.id));

                    const unit = matchingReturnWidgets.find((returnWidget: TCalculationWidget) => returnWidget.data.title.endsWith('.Aantal'))?.data.unit;

                    return {
                        id: widget.id,
                        label: widget.data.title,
                        machineName: tabData.name,
                        matchingReturnWidgets,
                        unit,
                        tabValues
                    }
                });

                if (materialWidgetsWithReturnWidgets.length === 0) {
                    return false;
                }

                return materialWidgetsWithReturnWidgets;
            })

            // Filter out any tabs that do not have any material widgets
            .filter((tab) => tab !== false)


        // Loop through the material widgets
        for (const calculationTabData of materialWidgetData) {
            if (!calculationTabData) continue;

            // Loop through the material widgets
            for (const materialWidgetData of calculationTabData) {
                // Add the machine name and unit to the row headers
                rowHeaders.push({
                    attributes: {
                        style: {
                            borderRight: 'dashed 1px lightgray',
                        }
                    },
                    data: [
                        `${materialWidgetData.machineName} - ${materialWidgetData.label}`,
                        materialWidgetData.unit,
                    ]
                });

                // Create the row for the material
                const materialRow = [];

                // Get the return widgets for this material
                const quantityWidget = materialWidgetData.matchingReturnWidgets.find(widget => widget.data.title.endsWith('.Aantal'));
                const salePriceWidget = materialWidgetData.matchingReturnWidgets.find(widget => widget.data.title.endsWith('.Verkoopprijs'));
                const total = materialWidgetData.matchingReturnWidgets.find(widget => widget.data.title.endsWith('.Totaalprijs'));

                // Check if material widget has tab values else continue
                if(materialWidgetData?.tabValues === undefined) continue;

                // Loop through the amounts and add the quantity, sale price and total price to the row
                for (const amount of this.data.amounts) {

                    // Get the quantity that the return widget has for the current amount
                    // This is the quantity of the material in the unit that has been set for this widget
                    const quantityValue = Number(materialWidgetData.tabValues[`${amount}`]
                        .find(widget => quantityWidget?.id === widget.id)?.value) || 0;

                    // Get the sale price that the return widget has for the current amount
                    const salePriceWidgetValue = Number(materialWidgetData.tabValues[`${amount}`]
                        .find(widget => salePriceWidget?.id === widget.id)?.value) || 0;

                    // Get the total price that the return widget has for the current amount
                    const totalPriceWidgetValue = Number(materialWidgetData.tabValues[`${amount}`]
                        .find(widget => total?.id === widget.id)?.value) || 0;

                    // Add the quantity, sale price and total price to the row
                    // Add an empty value to separate the results
                    materialRow.push(
                        formatNumberValue(quantityValue, materialWidgetData.unit as string),
                        formatSmallCurrency(salePriceWidgetValue),
                        formatCurrency(totalPriceWidgetValue),
                        ''
                    )
                }

                // Remove the last empty value from the row to prevent an empty cell at the end
                materialRow.pop();
                // Add the row to the table data
                resultTableData.push(materialRow);
            }

            // Add an empty row to the row headers to separate the machines and their materials
            rowHeaders.push({
                attributes: {
                    style: {
                        backgroundColor: '#f6f6f6',
                    }
                },
                data: ['', '']
            });

            // Create an array to fill with empty values to separate the machines
            const emptyRow = Array(this.data.amounts.length).fill(['', '', '', '']).flat();
            emptyRow.pop();

            // Add an empty grey row to separate the machines
            resultTableData.push({
                attributes: {
                    style: {
                        backgroundColor: '#f6f6f6',
                    }
                },
                data: emptyRow
            });
        }

        // Create the headers for the table
        const headers = this.data.amounts.map(amount => ['', String(amount), 'Tarief', 'Totaal']).flat();
        headers.shift();

        const structure: any[] = this.data.amounts.map(amount => ['150px', '100px', '100px', '100px']).flat();
        structure.pop();
        structure.pop();

        const lastItem = {
            minWidth: '100px',
            width: '100%'
        }
        structure.push(lastItem);

        // Remove the last row, because they are empty
        rowHeaders.pop();
        resultTableData.pop();

        return {
            headers: headers,
            rowHeaders: rowHeaders,
            data: resultTableData,
            structure: structure
        }
    }

    public getSimpleThirdPartyTableData() {

        // If there are no third party results, return false
        if (this.data.thirdParty?.length === 0 || !this.data.thirdParty) {
            return false;
        }

        const resultTableData = [];
        let rowHeaders = [];

        if (!isObject(this.data.thirdParty) && !Array.isArray(Object.entries(this.data.thirdParty))) {
            return;
        }

        const thirdParty: Record<string, any>[] = Array.isArray(this.data.thirdParty) ? this.data.thirdParty : Object.values(this.data.thirdParty);

        for (const thirdPartyItem of thirdParty) {
            const thirdPartyRow = [];

            if (!thirdPartyItem) continue;

            for (const amount of this.data.amounts) {
                const thirdPartyItemQuantity = Array.isArray(thirdPartyItem.quantities) ? thirdPartyItem.quantities.find(q => q.quantity === amount) : thirdPartyItem.quantities?.[amount];

                if (!thirdPartyItemQuantity) {
                    thirdPartyRow.push('', '', '', '', '');
                    continue;
                }

                thirdPartyRow.push(
                    thirdPartyItemQuantity.purchaseQuantity ?? thirdPartyItemQuantity.quantity,
                    formatCurrency(thirdPartyItemQuantity.cost),
                    formatNumberValue(thirdPartyItemQuantity.surtax, 'procent'),
                    formatCurrency(thirdPartyItemQuantity.cost * (thirdPartyItemQuantity.surtax / 100 + 1)),
                    ''
                )
            }

            rowHeaders.push([
                thirdPartyItem.description
            ]);

            thirdPartyRow.pop();
            resultTableData.push(thirdPartyRow);
        }

        const headers = this.data.amounts.map(amount => ['', String(amount), 'Kosten', 'Marge', 'Totaal']).flat();
        headers.shift();

        const structure: any[] = this.data.amounts.map(amount => ['100px', '100px', '100px', '100px', '100px']).flat();
        structure.pop();
        structure.pop();

        const lastItem = {
            minWidth: '100px',
            width: '100%'
        }
        structure.push(lastItem);

        rowHeaders = rowHeaders.map((row, index) => ({
            attributes: {
                style: {
                    borderRight: 'dashed 1px lightgray',
                }
            },
            data: row
        }));

        return {
            headers: headers,
            rowHeaders: rowHeaders,
            data: resultTableData,
            structure: structure
        }
    }

    public getSimpleInformationTableData() {

        const rowHeaders = [];
        const resultTableData = [];

        const templateDataMachineTabs = Object.entries(this.data.templateData.data)
            .filter((tab): tab is [`${number}-${number}`, TCalculationTabData] => /^\d+-\d+$/.test(tab[0]));

        // Get all return widget values from the data for each amount
        for (const [tabId, tabData] of templateDataMachineTabs) {

            // Get the information return widgets from the tab
            const informationReturnWidgets = tabData.widgets
                .filter(widget => widget.data.type === 'ReturnWidget' && widget.data.title.startsWith('Informatie.'));

            // Skip the tab if there are no information return widgets
            if (informationReturnWidgets.length === 0) continue;

            // Get the values for the tab
            const tabValues = this.data.values[tabId];

            // If the tab doesn't have any values, skip it
            if (!Array.isArray(tabValues)) {
                continue;
            }

            // Loop through the information return widgets and get the values for each amount
            for (const informationReturnWidget of informationReturnWidgets) {

                const widgetRow = [];

                for (const amount of this.data.amounts) {
                    const value = tabValues[`${amount}`].find((widget: TCalculationValue) => widget.id === informationReturnWidget.id)?.value;

                    widgetRow.push(
                        formatNumberValue(Number(value), informationReturnWidget.data.unit ?? ''),
                    );
                }

                // Add the row to the table data
                resultTableData.push(widgetRow);

                // Add the row headers
                rowHeaders.push({
                    attributes: {
                        style: {
                            borderRight: 'dashed 1px lightgray',
                        }
                    },
                    data: [
                        `${tabData.name} - ${informationReturnWidget.data.title.split('.')[1]}`
                    ]
                });
            }

            // Add an empty row to separate the machines
            rowHeaders.push({
                attributes: {
                    style: {
                        backgroundColor: '#f6f6f6',
                    }
                },
                data: ['']
            });

            const emptyRow = Array(this.data.amounts.length).fill('');

            resultTableData.push({
                attributes: {
                    style: {
                        backgroundColor: '#f6f6f6',
                    }
                },
                data: emptyRow
            });
        }

        // Remove the last empty row
        resultTableData.pop();
        rowHeaders.pop();

        const headers = this.data.amounts.map(amount => String(amount));

        const structure: any[] = this.data.amounts.map(amount => '250px');
        structure.pop();

        const lastItem = {
            minWidth: '250px',
            width: '100%'
        }
        structure.push(lastItem);

        return {
            headers: headers,
            rowHeaders: rowHeaders,
            data: resultTableData,
            structure: structure
        }
    }

    private combineResultsAndMachines(): Array<{
        tabId: string,
        machineName: string,
        result: any,
        constants: any[]
    }> {
        if (!this.data?.rawResult) return [];

        // Loop through the raw results
        return Object.entries(this.data.rawResult)
            .filter(([machineId]) => this.data.optionalMachines[machineId as `${number}-${number}`] !== 'false')
            .map(([tabId, result]) => {
                const tabData = this.data.templateData.data[tabId as `${number}-${number}`];

                return {
                    tabId,
                    machineName: tabData?.name ?? false,
                    result,
                    constants: tabData?.constants ?? [],
                    position: tabData?.position ?? 0,
                }
            })
            .filter(({machineName}) => machineName)
            .sort((a, b) => a.position - b.position);
    }
}
