import User from "App/Strapi/User";
import {ReactElement} from "react";

export default function HasRole({ roles, children, doesNotHaveRole }: { roles: string[], children: ReactElement, doesNotHaveRole?: ReactElement }) {
    const hasPermission = User.hasRole(roles);

    if (hasPermission) {
        return children;
    } else if (doesNotHaveRole) {
        return doesNotHaveRole;
    } else {
        return null;
    }
}
