import Block from "UI/App/Components/Block/Block";
import PersonCompanyListItem from "./PersonCompanyListItem";

export default function PersonCompanyList({ data = [] }) {
	return (
		<Block name='companies' title='Bedrijf'>
			{data.map((company, index) => <PersonCompanyListItem key={index} company={company} />)}
			{data?.length === 0 && <span>Geen Bedrijven gevonden...</span>}
		</Block>
	)
}
