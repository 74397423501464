import {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {FetchContext} from 'App/Strapi/FetchContext';
import {toast} from 'react-toastify';

import Icon from 'UI/App/Components/Icon/Icon';
import Block from "UI/App/Components/Block/Block";
import Grid from 'UI/App/Components/Grid/Grid';
import LineGraph from 'UI/App/Components/Graph/Graph';

export default function Terminal() {

    const { authAxios } = useContext(FetchContext);
    const { terminalId } = useParams();

    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [terminalData, setTerminalData] = useState({
        name: "",
        location: "",
        macAddress: "",
        publicIP: "",

    });

    const [temperatureEntries, setTemperatureEntries] = useState([])
    const [humidityEntries, setHumidityEntries] = useState([])

    useEffect(() => {
        if (terminalId !== undefined && terminalId.toLocaleLowerCase() !== "create") {
            setIsEdit(true);
            authAxios.get(`../terminals/${terminalId}`)
                .then(({ data }) => {
                    setTerminalData(data.data)
                }).catch((exception) => {
                    toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);

                    console.error(exception);
                });

            authAxios.get(`../terminals/logging/${terminalId}/last-seven-days`)
                .then(({ data }) => {
                    let temps = []
                    let humidity = []
                    for (const sensorEntry of data) {
                        humidity.push({ x: new Date(sensorEntry?.terminalStamp), y: sensorEntry.humidity ?? 0 })
                        temps.push({ x: new Date(sensorEntry?.terminalStamp), y: sensorEntry.celsius ?? 0 })
                    }

                    setHumidityEntries(humidity);
                    setTemperatureEntries(temps);
                }).catch((exception) => {
                    toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
                    console.error(exception);
                });
        }
    }, [])

    async function updateOrCreateTerminal(e) {
        e.preventDefault();
        if (isEdit) {
            authAxios.put(`../terminals/${terminalId}`, { data: terminalData }).then(({ data }) => {
                navigate("/admin/terminals")
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            });
        } else {
            authAxios.post(`../terminals`, { data: terminalData }).then(({ data }) => {
                navigate("/admin/terminals")
            }).catch((exception) => {
                console.error(exception);
                toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            });
        }
    }

    return (
        <>
            <Block name={"terminalBlock"} title={<>
                <Link className={'btn btn--transparent'} to={`/admin/terminals`}><Icon name={'arrow-left'} /></Link>
                Terminal  {isEdit ? "Aanpassen" : "Aanmaken"}
            </>}>
                <form onSubmit={(e) => updateOrCreateTerminal(e)}>
                    <div className='input-group'>
                        <label htmlFor='name'>Naam</label>
                        <input
                            type='text'
                            id='name'
                            value={terminalData?.name}
                            onChange={({ target }) => {
                                setTerminalData((data) => ({
                                    ...data,
                                    name: target.value
                                }));
                            }}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='name'>Locatie</label>
                        <input
                            type='text'
                            id='location'
                            value={terminalData?.location}
                            onChange={({ target }) => {
                                setTerminalData((data) => ({
                                    ...data,
                                    location: target.value
                                }));
                            }}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='name'>Mac address</label>
                        <input
                            type='text'
                            id='name'
                            value={terminalData?.macAddress}
                            onChange={({ target }) => {
                                setTerminalData((data) => ({
                                    ...data,
                                    macAddress: target.value
                                }));
                            }}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='name'>Publiek IP</label>
                        <input
                            type='text'
                            id='name'
                            value={terminalData?.publicIP}
                            onChange={({ target }) => {
                                setTerminalData((data) => ({
                                    ...data,
                                    publicIP: target.value
                                }));
                            }}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='name'>Update frequentie</label>
                        <select value={terminalData?.temperatureUpdateRateInMinutes ?? 15} onChange={({ target }) => {
                            setTerminalData((data) => ({
                                ...data,
                                temperatureUpdateRateInMinutes: Number(target.value)
                            }));
                        }}>
                            <option value={15}>15 minuten</option>
                            <option value={30}> 30 minuten</option>
                            <option value={60}>1 uur</option>
                            <option value={120}>2 uur</option>
                            <option value={240}>4 uur</option>
                        </select>
                        <small>De terminal zal deze waarde ophalen wanneer de 'huidige' frequentie behaald is</small>
                    </div>
                    <div className='input-group'>
                        <button type='submit'>
                            {isEdit ? "Aanpassen" : "Aanmaken"} <Icon name='save' />
                        </button>
                    </div>
                </form>
            </Block>
            <Block name={"graphs"}>
                <Grid columns='2'>
                    <LineGraph
                        title="Temperatuur in °C van de laaste 7 dagen"
                        labelSuffix={' °C'}
                        wrapperProps={{ id: "temp", style: { width: "100%" } }}
                        dataset={[{
                            label: "Temperatuur",
                            data: temperatureEntries,
                            yAxisID: 'y'
                        }]}
                    />
                    <LineGraph
                        title="Luchtvochtigheid van de laaste 7 dagen"
                        labelSuffix={'%'}
                        wrapperProps={{ id: "humidity", style: { width: "100%" } }}
                        dataset={[{
                            label: "Luchtvochtigheid",
                            data: humidityEntries,
                            yAxisID: 'y'
                        }]}
                    />
                </Grid>
            </Block>
        </>
    )
}