import Container from 'UI/App/Components/Container/Container';
import Grid from 'UI/App/Components/Grid/Grid';
import Memos from 'UI/App/Partials/Content/Dashboard/Blocks/Memos/Memos';
import Notes from 'UI/App/Partials/Content/Dashboard/Blocks/Notes/Notes';
import './dashboard.scss';
import PowerBI from "UI/App/Partials/Content/Dashboard/Blocks/PowerBI/PowerBI";
import IF from 'UI/App/Components/Conditional/IF';
import Timer from "UI/App/Partials/Content/Dashboard/Blocks/Timer/Timer";
import HasRole from "UI/App/Components/Auth/HasRole";

export default function Dashboard() {
    return (
        <Container>
            <Grid
                columns={{
                    1000: 1,
                    default: 3
                }}
                customColTemplate={{
                    default: '400px 1fr 1fr'
                }}
            >
                <Timer />
                <Notes />
                <Memos />
            </Grid>

            <HasRole roles={['admin', 'management']} >
                <IF condition={localStorage.getItem("isTerminalUser") === "false"}>
                    <PowerBI />
                </IF>
            </HasRole>

        </Container>
    );
}
