import capitalize from 'lodash/capitalize';
import translate from './translate';

export function formatDate(
    date: string | null = null,
    options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    },
    locale = 'nl-NL'
): string {
    if (!date) return new Date().toLocaleTimeString(locale, options);

    return new Date(date).toLocaleTimeString(locale, options);
}

export function formatDateWithoutTime(
    date: string | Date | null = null,
    options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' },
    locale: string = 'nl-NL'
): string {
    if (!date) return new Date().toLocaleDateString(locale, options);

    return new Date(date).toLocaleDateString(locale, options);
}

export function formatTimeWithoutDate(
    datetime: Date | string | null = null,
    options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' },
    locale: string = 'nl-NL'
): string {
    if (!datetime) return new Date().toLocaleTimeString(locale, options);

    return new Date(datetime).toLocaleTimeString(locale, options);
}

export function getDateFromTimeString(
    timeString: string,
    options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' },
    locale: string = 'nl-NL'
) : Date {
    // Get the current date in the correct format for the timeString
    const date = new Date().toLocaleDateString('en-CA')
    const dateTimeString = `${date}T${timeString}`
    return new Date(dateTimeString);
}

export function formatDateForInput(date: Date | string | null = null): string {
    date = new Date(date ?? new Date());
    const dateString = formatDateWithoutTime(date, { year: 'numeric', month: '2-digit', day: '2-digit' }, 'en-CA');
    const timeString = formatTimeWithoutDate(date, { hour: '2-digit', minute: '2-digit' });
    return `${dateString}T${timeString}`;
}

/**
 * Formats a name to correct capitalization and spacing.
 *
 * @export
 * @param {string} [firstName]
 * @param {string} [prefixToSurname]
 * @param {string} [surname]
 * @return {string} Returns the formatted name.
 */
export function formatName(firstName?: string, prefixToSurname?: string, surname?: string): string {
    // capitalize all instance of 'words'
    firstName = (firstName ?? ' ').toLowerCase().replace(/\b[a-z](?=[a-z])/g, function (letter) {
        return letter.toUpperCase();
    });

    // capitalize all instance of 'words'
    surname = (surname ?? ' ').toLowerCase().replace(/\b[a-z](?=[a-z])/g, function (letter) {
        return letter.toUpperCase();
    });

    return (
        ` ${firstName} ` +
        ` ${firstName ? prefixToSurname ?? ' ' : capitalize(prefixToSurname ?? ' ')} ` +
        ` ${surname} `
    )
        .replace(/\s+/g, ' ')
        .trim();
}

/**
 * Formats a letter greeting.
 *
 * @export
 * @param {('nl'|'en')} [language='nl']
 * @param {string} [firstName]
 * @param {string} [prefixToSurname]
 * @param {string} [surname]
 * @param {('man'|'vrouw'|'anders')} [gender='anders']
 * @param {('formeel'|'informeel')} [approach='formeel']
 * @return {string} Returns the proper greeting.
 */
export function formatLetterGreeting(
    language: 'nl' | 'en' = 'nl',
    firstName?: string,
    prefixToSurname?: string,
    surname?: string,
    gender: 'man' | 'vrouw' | 'anders' = 'anders',
    approach: 'formeel' | 'informeel' = 'formeel'
): string {
    const approachText = translate(
        `dear ${approach === 'formeel' ? 'formal' : 'informal'}`,
        language,
        true
    );

    // use "persoon" for `anders`?
    const genderText = translate(
        gender === 'man' ? 'mr' : gender === 'vrouw' ? 'ms' : 'mr/ms',
        language,
        true
    );

    // if no name (e.g. no contact person)
    // `firstName` & `surname` are both required in strapi
    if (!firstName && !surname) {
        return `${approachText} ${genderText},`;
    }

    if (approach === 'informeel') {
        return `${approachText} ${formatName(firstName)},`;
    } else {
        return `${approachText} ${genderText} ${formatName(undefined, prefixToSurname, surname)}`;
    }
}

export function formatTime(hours: number) {
    return `${String(Math.floor(hours)).padStart(2, '0')}:${String(
        Math.ceil((hours % 1) * 60)
    ).padStart(2, '0')}`;
}

export function formatSeconds(seconds: number, showSeconds: boolean = true) {
    return `${String(Math.floor(seconds / 3600)).padStart(2, '0')}:${String(
        Math.floor((seconds % 3600) / 60)
    ).padStart(2, '0')}${(showSeconds ? ':' + String(Math.floor(seconds % 60)).padStart(2, '0') : '')}`;
}

export function formatMinutes(minutes: number) {
    return `${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(
        Math.floor((minutes % 60) / 60)
    ).padStart(2, '0')}`;
}

export function parseTimeToNumber(time: string | number) {
    if (typeof time !== 'number' && /\d+:\d+/.test(time)) {
        const [hours, minutes] = time.split(':');
        return Number(hours) + Number(minutes) / 60;
    } else {
        return Number(String(time).replace(',', '.'));
    }
}

export function getDateString(date?: Date|string) {
    const newDate = date ? new Date(date) : new Date();
    return new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -8);
}

export function formatCurrency(amount: number, currency: string = 'EUR', locale: string = 'nl-NL') {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(
        isNaN(amount) ? 0 : amount
    );
}

export function formatSmallCurrency(amount: number, length: number = 4, currency: string = 'EUR', locale: string = 'nl-NL') {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency, maximumFractionDigits: length }).format(
        isNaN(amount) ? 0 : amount
    );
}

export function formatStringCurrency(input: string, useCurrencySymbol: boolean = true, currency: string|null = null, locale: string|null = null) {
    input = String(input)
    const currencySymbol = input[0];

    // Fix for euro using comma instead of period
    if (currencySymbol === '$' || currency === 'USD' || locale === 'en-US') {
        input = input.replaceAll(',', '');
    } else if (currencySymbol === '€' || currency === 'EUR' || locale === 'nl-NL') {
        input = input.replaceAll('.', '').replace(',', '.');
    } else if (/\d/.test(currencySymbol)) {
        // Fix if there is no currency symbol
        input = ' ' + input;
    }

    const price = Number(input.slice(1).trim());
    currency = currency ?? currencySymbol === "$" ? 'USD' : 'EUR';
    locale = locale ?? currencySymbol === "$" ? 'en-US' : 'nl-NL';

    const formatted = formatCurrency(price, currency, locale);
    return formatted.replace(/([$€])/, useCurrencySymbol ? '$1' : '');
}

export function getNumberFromPrice(price: string) {
    const currencySymbol = price[0];

    // Fix for euro using comma instead of period
    if (currencySymbol === '$') {
        price = price.replace(',', '');
    } else if (currencySymbol === '€') {
        price = price.replace('.', '').replace(',', '.');
    } else if (/\d/.test(currencySymbol)) {
        // Fix if there is no currency symbol
        price = ' ' + price;
    }

    return Number(price.slice(1).trim());
}

export function getCurrencyFromPriceString(price: string) {
    return price[0] === "$" ? 'USD' : 'EUR';
}

export function formatNumber(amount: number, locale: string = 'nl-NL', decimals: number = 2) {
    return new Intl.NumberFormat(locale, { maximumFractionDigits: decimals }).format(
        isNaN(amount) ? 0 : amount
    );
}

export function splitCamelCase(string: string): string {
    return string
        .replaceAll(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^\w/, string.slice(0, 1).toUpperCase());
}

export function tryParse(json: any) {
    try {
        return JSON.parse(json);
    } catch (e) {
        return {};
    }
}

/**
 * Formats a number value
 * @param value
 * @param unit {'euro'|'procent'|'kilogram'|'meter'|'vellen'|'uren'|'minuten'|string}
 * @returns {any|string}
 */
export function formatNumberValue(
    value: number,
    unit: 'euro' | 'procent' | 'kilogram' | 'meter' | 'vellen' | 'uren' | 'minuten' | string
): any | string {
    // The value is of type number so format it
    function formatNumber(value: number, maxDigits = 3) {
        return new Intl.NumberFormat('nl-NL', { maximumFractionDigits: maxDigits }).format(value);
    }

    // Support for units like "euro per kilogram" to be formatted as "€ 1,00 per kilogram"
    if (unit && unit?.toLowerCase()?.includes('euro') && !/^euro$/gi.test(unit.trim())) {
        unit = unit.replace(/euro/gi, '').trim();
        return (formatCurrency(value) + ' ' + unit).trim();
    }

    switch (unit) {
        case 'euro':
            return formatCurrency(value);
        case 'procent':
            return `${formatNumber(value, 2)}%`;
        case 'kilogram':
            return `${formatNumber(value, 2)} kg`;
        case 'meter':
            return `${formatNumber(value, 2)} meter`;
        case 'vellen':
            return `${formatNumber(value, 1)} vellen`;
        case 'uren':
            return `${formatNumber(value, 2)} (${formatTime(value)}) uur`;
        case 'minuten':
            return `${Math.ceil(value)} minuten`;
        default:
            return `${formatNumber(value)} ${unit ?? ''}`;
    }
}

/**
 * convert pixels to millimeter.
 *
 * @export
 * @return {number} px * 0.2645833333
 */
export function pxToMm(px: number): number {
    return px * 0.2645833333;
}

/**
 * convert millimeter to pixels.
 *
 * @export
 * @return {number} mm / 0.2645833333
 */
export function mmToPx(mm: number): number {
    return mm / 0.2645833333;
}

/**
 * convert points to millimeter.
 *
 * @export
 * @return {number} pt * 0.3527777778
 */
export function ptToMm(pt: number): number {
    return pt * 0.3527777778;
}

/**
 * convert millimeter to points.
 *
 * @export
 * @return {number} mm / 0.3527777778
 */
export function mmToPt(mm: number): number {
    return mm / 0.3527777778;
}

/**
 * convert pixels to points.
 *
 * @export
 * @return {number} pt / 1.333
 */
export function pxToPt(pt: number): number {
    return pt / 1.333;
}

/**
 * convert points to pixels.
 *
 * @export
 * @return {number} pt * 1.333
 */
export function ptToPx(pt: number): number {
    return pt * 1.333;
}

/**
 * Strip diacritics from a string.
 * @param str
 */
export function stripDiacritics(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * PHP equivalent of {@link https://www.php.net/manual/en/function.natsort|natsort}: Sort an array using a "natural order" algorithm.
 * "a2b" comes before "a10b" instead of the other way around.
 *
 * Use to get the number needed for array.sort's return.
 *
 * @export
 * @example
 * array.sort((a, b) => natCompare(a, b) (|| natCompare()...));
 * @param {string} x
 * @param {string} y
 * @param {Intl.LocalesArgument} locales
 * @param {Intl.CollatorOptions} options
 * @return {number} An integer between `1` & `-1` for use in array.sort
 */
export function natCompare(
    x: string,
    y: string,
    locales: (string | string[] | undefined) = 'nl-NL',
    options: Intl.CollatorOptions = {
        sensitivity: 'base',
        numeric: true
    }
): number {
    return new Intl.Collator(locales, options).compare(x, y);
}
