import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Icon from 'UI/App/Components/Icon/Icon';
import Block from 'UI/App/Components/Block/Block';
import Container from 'UI/App/Components/Container/Container';
import Select from "react-select";
import capitalize from "lodash/capitalize";
import IF from "UI/App/Components/Conditional/IF";

const usersQuery = stringify({
    filters: {
        confirmed: true
    },
    fields: ['id', 'username', 'salesManager', 'blocked']
});

export default function SetCompanyManagers() {
    const { authAxios } = useContext(FetchContext);

    const navigate = useNavigate();
    const params = useParams();


    const [usersOptions, setUsersOptions] = useState({});
    const [defaultUserOptions, setDefaultUserOptions] = useState([])

    const [currentManagerType, setCurrentManagerType] = useState();

    const [dynamicManagers, setDynamicManagers] = useState();


    // get active managers from the company
    useEffect(() => {


        if (usersOptions.length <= 0) return;

        // get dynamic types
        authAxios.get(`/crm/company/${params?.companyId}/managers`).then(({ data }) => {

            let elements = [];

            for (const type of data) {

                // format current users to select values
                let formatedUsers = []
                // filter to the record of this company only.
                for (const record of type?.managerRecords.filter((el => { return el.company.id == params.companyId }))) {
                    formatedUsers.push({ label: record.user.username, value: record.user.id })
                }

                setDefaultUserOptions(prev => ({
                    ...prev,
                    [type.id]: formatedUsers
                }));

                // format the records so its inline with the data we get back later on from the select
                let formatedRecords = []
                for (const records of type?.managerRecords) {
                    formatedRecords.push({ id: records.id, company: Number(records.company.id), user: records.user.id })
                }
                type.managerRecords = formatedRecords;

                setCurrentManagerType(prev => ({
                    ...prev,
                    [type.id]: type
                }));

            }

        })
    }, [params.companyId, usersOptions]);

    // get all users to fill the input options
    useEffect(() => {
        // get all users that are able to manage
        authAxios.get('/crm/managers/viable-managers').then((data) => {
            let formatedUsers = [];

            for (let user of data.data) {
                formatedUsers.push({ value: user.id, label: user.username })

            }
            setUsersOptions(formatedUsers);
        })
    }, [authAxios]);

    useEffect(() => {

        // gaurd against undefinde or no data
        if (currentManagerType === undefined || Object.values(usersOptions).length <= 0) return;
        setEllements(Object.values(currentManagerType))

    }, [currentManagerType]);

    function setEllements(data) {

        let elements = [];
        for (const type of data) {


            // format the users to be used by a select
            let formatedUsers = [];
            for (const user of type.usersWithAccess) {
                formatedUsers.push({ value: user.id, label: user.username, style: {} })
            }

            // check if we have users that are no longer viable managers
            const noneValidManagers = type.usersWithAccess.filter((id1) => !usersOptions.some((id2) => id1.id === id2.value));

            // set css for users that have the role but should no longer have it, do this to alert the user
            const colourStyles = {
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const noneValidManager = noneValidManagers.some(e => e.id === data.value);
                    return {
                        ...styles,
                        color: noneValidManager ? 'red' : 'default',
                    }
                },
                multiValueLabel: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const noneValidManager = noneValidManagers.some(e => e.id === data.value);
                    return {
                        ...styles,
                        color: noneValidManager ? 'red' : 'default',
                    }
                },
                singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
                    const noneValidManager = noneValidManagers.some(e => e.id === data.value);
                    return {
                        ...styles,
                        color: noneValidManager ? 'red' : 'default',
                    }
                },
            };

            // display a warning if the chosen manager is no longer a viable manager
            let warning = '';

            // if we have mutiple users 
            if (defaultUserOptions[type.id]?.length > 0) {
                for (const chosenUser of defaultUserOptions[type.id]) {

                    // if we have mutiple users that are invalid change the message to be for mutiple
                    if (noneValidManagers.some(e => e.id === chosenUser?.value) && warning === '') {
                        warning = "De gekozen gebuiker voldoet niet aan alle voorwaarden om manager te zijn."
                    } else if (noneValidManagers.some(e => e.id === chosenUser?.value) && warning !== '') {
                        warning = "De gekozen gebuikers voldoen niet aan alle voorwaarden om managers te zijn."
                    }
                }

            } else {// if we have a single users

                // if we have mutiple users that are invalid change the message to be for mutiple
                if (noneValidManagers.some(e => e.id === defaultUserOptions[type.id]?.value) && warning === '') {
                    warning = "De gekozen gebuiker voldoet niet aan alle voorwaarden om manager te zijn."
                } else if (noneValidManagers.some(e => e.id === defaultUserOptions[type.id]?.value) && warning !== '') {
                    warning = "De gekozen gebuikers voldoen niet aan alle voorwaarden om managers te zijn."
                }
            }

            elements.push(
                <div title={type?.description} className='input-group'>
                    <label>{capitalize(type.name)}:</label>
                    <IF condition={warning}>
                        <br />
                        <small style={{ color: 'red' }}>{warning}</small>
                    </IF>
                    <Select
                        options={formatedUsers}
                        value={defaultUserOptions[type.id]}
                        closeMenuOnSelect={type?.allowMultiple === false}
                        styles={colourStyles}
                        isClearable={true}
                        isMulti={type?.allowMultiple === true}
                        onChange={(e) => { handleChange({ name: 'usersWithAcces', typeId: type.id, value: e }) }}
                    />
                </div>
            )


        }
        setDynamicManagers(elements);
    }

    function handleChange(e) {

        setDefaultUserOptions(prev => ({
            ...prev,
            [e.typeId]: e.value
        }));


        let newMangerInfo = currentManagerType[e.typeId];

        // get the records belonging to other companys
        let records = newMangerInfo.managerRecords.filter((el) => Number(el.company) !== Number(params.companyId));

        // if the input is a single
        if (e?.value?.length === undefined) {
            // add the user to the record
            records.push({ user: e?.value?.value, company: params.companyId });
        } else { // if the input is mutiple

            // add all users to the record
            for (const user of e.value) {
                records.push({ user: user.value, company: Number(params.companyId) });
            }
        }

        // set the revord object
        newMangerInfo.managerRecords = records
        if (e.value === null) {
            newMangerInfo.managerRecords = []
        }
        // set the state
        setCurrentManagerType(prev => ({
            ...prev,
            [e.typeId]: newMangerInfo
        }));


    }



    const storeSettings = useCallback(

        (e) => {
            e.preventDefault();

            const toastId = toast.loading('Verantwoordelijke(n) worden aangepast...');

            authAxios.post(`/crm/managers/records`, {
                data: { currentManagerType }
            }).then(() => {
                toast.update(toastId, {
                    render: `De verantwoordelijke(n) zijn aangepast`,
                    type: "success",
                    isLoading: false,
                    autoClose: 2500
                });
                navigate('/crm/companies/' + params.companyId);
            }).catch((exception) => {
                toast.update(toastId, {
                    render: `Kon de verantwoordelijke(n) niet aanpassen`,
                    type: "error",
                    isLoading: false,
                    autoClose: 2500
                });
                process.env.NODE_ENV === 'development' && console.error(exception);
            });

        },
        [currentManagerType, authAxios, navigate, params.companyId]
    );

    return (
        <Container>
            <Block
                name='EditResponsiblePeople'
                title={
                    <>
                        <Link
                            className='btn btn--transparent'
                            to={`/crm/companies/${params.companyId}`}
                        >
                            <Icon name='arrow-left' />
                        </Link>
                        Wijzig het accountmanagement
                    </>
                }
            >
                <form onSubmit={storeSettings}>


                    <div>
                        {dynamicManagers}
                    </div>
                    <button type='submit'>Opslaan</button>
                </form>
            </Block>
        </Container>
    );
}
