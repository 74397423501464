import React, {useCallback, useContext, useEffect, useState} from "react";
import Block from "UI/App/Components/Block/Block";
import {SpinnerCenter, SpinnerOverlay} from "UI/App/Components/Spinner";
import {FetchContext} from "App/Strapi/FetchContext";
import TimerRepository from "UI/App/Partials/Content/Dashboard/Blocks/Timer/TimerRepository";
import {toast} from "react-toastify";

import "./timer.scss";
import Icon from "UI/App/Components/Icon/Icon";
import {formatDate, formatDateWithoutTime, formatTimeWithoutDate, getDateFromTimeString, getDateString} from "App/Util/format";
import Grid from "UI/App/Components/Grid/Grid";
import LiveTimer from "UI/App/Partials/Content/Dashboard/Blocks/Timer/LiveTimer";
import Breaks from "UI/App/Partials/Content/Dashboard/Blocks/Timer/Breaks";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import TimerCustomEndTimePopup from "UI/App/Partials/Content/Dashboard/Blocks/Timer/TimerCustomEndTimePopup";

const Timer = React.memo(function Timer() {

    /**
     * Authenticated axios instance
     */
    const { authAxios } = useContext(FetchContext);

    /**
     * The current running timer
     */
    const [currentTimer, setCurrentTimer] = useState(null);

    /**
     * The schedule for today
     */
    const [schedule, setSchedule] = useState(null);

    /**
     * Whether the timer is loading
     */
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Whether the buttons are disabled
     * They are disabled when there is a pending request
     */
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    /**
     * Notification state
     */
    const [notification, setNotification] = useState({
        message: null,
        type: 'info'
    });

    /**
     * Clockout time
     */
    const [clockOutTime, setClockOutTime] = useState(null);

    /**
     * Notify the user
     * @type {(function(*, *): void)|*}
     */
    const notify = useCallback((message, type) => {
        setNotification({
            message,
            type
        })
    }, [setNotification]);

    /**
     * Get the current running timer
     * @type {function(): Promise<T>}
     */
    const fetchTimer = useCallback(async () => {
        const tr = new TimerRepository(authAxios);
        return await tr.current();
    }, [authAxios]);

    /**
     * Fetch the schedule for today
     * @type {function(): Promise<any>}
     */
    const fetchSchedule = useCallback(async () => {
        const tr = new TimerRepository(authAxios);
        return await tr.getScheduleForToday();
    }, [authAxios]);

    /**
     * Clock in the user
     * @type {function(): Promise<any>}
     */
    const clockIn = useCallback(async () => {
        setButtonsDisabled(true);
        const tr = new TimerRepository(authAxios);
        const timer = await tr.clockIn();

        if (currentTimer?.minutesLate <= 0) {
            notify(`Op tijd! Goed gedaan, werkse vandaag!`, 'success');
        }

        setCurrentTimer(timer.data);
        setButtonsDisabled(false);
    }, [authAxios]);

    /**
     * Clock out the user
     * @type {(function(): Promise<void>)|*}
     */
    const clockOut = useCallback(async (endDate = undefined) => {
        setButtonsDisabled(true);
        const now = new Date();
        const start = currentTimer?.start ? new Date(currentTimer.start) : new Date();

        if (!endDate && now - start > 16 * 60 * 60 * 1000) {
            setClockOutTime(new Date());
            openPopup('timer__custom-end-time');
            return;
        }

        endDate = endDate ? new Date(endDate) : new Date();

        const tr = new TimerRepository(authAxios);
        const timer = await tr.clockOut(endDate);

        closePopup();

        if (timer.data.minutesOvertime > 15) {
            notify(`Bedankt voor je inzet. Er is ${formatOvertime(timer.data.minutesOvertime)} overwerk gedetecteerd.`, 'success');
        } else {
            notify('Je bent uitgeklokt.', 'success');
        }

        setCurrentTimer(null);
        setButtonsDisabled(false);
    }, [authAxios, notify, currentTimer]);

    /**
     * Start a break
     * @type {(function(): Promise<void>)|*}
     */
    const startBreak = useCallback(async () => {
        setButtonsDisabled(true);
        const tr = new TimerRepository(authAxios);
        const timer = await tr.startBreak();
        setCurrentTimer(timer.data);
        setButtonsDisabled(false);
    }, [authAxios]);

    /**
     * End a break
     * @type {(function(): Promise<void>)|*}
     */
    const endBreak = useCallback(async () => {
        setButtonsDisabled(true);
        const tr = new TimerRepository(authAxios);
        const timer = await tr.endBreak();
        setCurrentTimer(timer.data);
        setButtonsDisabled(false);
    }, [authAxios]);

    // Create time formatter
    const timeFormatter = new Intl.DateTimeFormat('nl-NL', {
        hour: 'numeric',
        minute: 'numeric',
    });

    /**
     * Fetch the current running timer on component mount
     */
    useEffect(() => {
        setButtonsDisabled(true);

        // Fetch the current running timer
        fetchTimer()
            // Set the current timer
            .then(({ data }) => {
                // If the timer has no start, clear timer and return
                // This means no timer is running
                if (!data.start) {
                    setCurrentTimer(null);
                    return;
                }

                setCurrentTimer(data);
            })
            // Catch errors
            .catch((error) => {
                console.error(error);
                setCurrentTimer(null);
                toast.error("Er is een fout opgetreden bij het ophalen van de timer.");
            })
            // Finally, set loading to false
            .finally(() => {
                setIsLoading(false);
                setButtonsDisabled(false);
            });

        // Fetch the schedule for today
        fetchSchedule()
            // Set the schedule
            .then((schedule) => {
                const startTime = getDateFromTimeString(schedule.data.startTime);
                const endTime = getDateFromTimeString(schedule.data.endTime);

                // If the schedule has no start or end time, clear schedule and return
                if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
                    setSchedule(null);
                    return;
                }

                schedule.data.startTime = startTime;
                schedule.data.endTime = endTime;

                setSchedule(schedule.data);
            })
            // Catch errors
            .catch((error) => {
                console.error(error);
                toast.error("Er is een fout opgetreden bij het ophalen van het rooster van vandaag.");
            });
    }, [fetchTimer, fetchSchedule]);

    const formatOvertime = (minutes) => {
        if (minutes > 60) {
            if (minutes % 60 === 0) {
                return `${Math.floor(minutes / 60)} uur`;
            }

            return `${Math.floor(minutes / 60)} uur en ${minutes % 60} minuten`;
        } else if (minutes === 1) {
            return '1 minuut';
        } else {
            return `${minutes} minuten`;
        }
    }

    /**
     * If the timer is loading, show a spinner
     */
    if (isLoading) {
        return <SpinnerCenter />;
    }

    /**
     * Check if the user is on a break
     * @type {boolean | undefined}
     */
    const isOnBreak = currentTimer?.breaks?.some((breakI) => breakI.end === null);

    return (
        <Block
            name={"Timer"}
            title={"Timer"}
        >
            {schedule?.startTime && schedule?.endTime &&
                <div className='timer__schedule'>
                    <strong>Rooster vandaag:</strong>
                    <small style={{ color: 'grey', fontWeight: 600 }}>{formatDateWithoutTime(new Date().toISOString(), { weekday: 'long', day: 'numeric', month: 'long' }, 'nl-NL').toUpperCase()}</small>
                    <span>{timeFormatter.format(schedule?.startTime)} - {timeFormatter.format(schedule?.endTime)}</span>
                </div>
            }

            {currentTimer?.minutesLate > 0 &&
                <div className={`timer__alert warning`}>
                    <Icon name={getNotificationIcon('warning')} /><span>Te laat: {formatOvertime(currentTimer?.minutesLate)}. Probeer de volgende keer op tijd te zijn.</span>
                </div>
            }

            {notification.message &&
                <div className={`timer__alert ${notification.type}`}>
                    <Icon name={getNotificationIcon(notification.type)} /><span>{notification.message}</span>
                </div>
            }

            {!currentTimer &&
                <button onClick={clockIn} disabled={buttonsDisabled} className={'btn timer__clock-in'}>
                    Inklokken <Icon name={'play'} />
                </button>
            }

            {currentTimer &&
                <Grid
                    columns={1}
                    className='timer__current-timer'
                >
                    <Grid columns={2} customColTemplate={'100% 50px'} className={'timer__row'}>
                        <strong>INGEKLOKT OM:</strong>
                        <span>{formatTimeWithoutDate(currentTimer?.start, { hour: '2-digit', minute: '2-digit' })}</span>
                    </Grid>

                    <SpinnerOverlay visible={buttonsDisabled}>
                        <Grid columns={2} className={'timer__buttons'}>
                            <button
                                data-title={isOnBreak ? 'Pauze stoppen' : 'Pauze starten'}
                                className={'timer__btn timer__break'}
                                onClick={isOnBreak ? endBreak : startBreak}
                                disabled={buttonsDisabled}
                            >
                                <Icon name={isOnBreak ? 'fas play' : 'fas hourglass'} />
                            </button>

                            <button
                                data-title='Uitklokken'
                                className={'timer__btn timer__clock-out'}
                                onClick={() => clockOut()}
                                disabled={buttonsDisabled}
                            >
                                <Icon name={'fa-solid square'} />
                            </button>

                            {isOnBreak && <span className='timer__paused'>(Gepauzeerd)</span>}
                            <LiveTimer start={new Date(currentTimer?.start)} breaks={currentTimer?.breaks} />
                        </Grid>

                        {currentTimer.breaks?.length > 0 &&
                            <Breaks breaks={currentTimer.breaks} />
                        }
                    </SpinnerOverlay>
                </Grid>
            }

            <TimerCustomEndTimePopup
                alertMessage="De Timer heeft een ongebruikelijke shift gedetecteerd. Corrigeer de timer handmatig naar de juiste uitkloktijd!"
                currentTimer={currentTimer}
                onSubmit={clockOut}
            />
        </Block>
    )
});

export const getNotificationIcon = (type) => {
    switch (type) {
        case 'success':
            return 'check-circle';
        case 'warning':
            return 'triangle-exclamation';
        case 'error':
            return 'exclamation-circle';
        default:
            return 'info-circle';
    }
}

export default Timer;