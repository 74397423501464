import {FetchContext} from 'App/Strapi/FetchContext';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import Popup, {closePopup} from 'UI/App/Components/Popup/Popup';
import Inputs from "UI/App/Components/Form/Inputs";
import {toast} from 'react-toastify';

/**
 * popup chatmelding bewerken
 */
export default function PopupEditCompanyMessage({ getNotes, tagData, company, setCompany, editFormData }) {
	const { authAxios } = useContext(FetchContext);

	// check if on suppliers path
	const location = useLocation();
	const isSupplier = /\/suppliers\//.test(location.pathname);

	const [tagElements, setTagElements] = useState([]);
	const [tagState, setTageState] = useState([]);

	useEffect(() => {
		if (editFormData?.tags !== undefined) {
			let checkedTags = [];
			for (const tag of editFormData.tags) {
				checkedTags[tag.id] = true;
			}
			setTageState(checkedTags);
		}
	}, [editFormData])

	useEffect(() => {
		createTagElements();
	}, [tagData, tagState]);

	function updateTag(e) {
		// store the state of the checkbox on ID of the tag
		let tagArray = tagState;
		tagArray[e.target.value] = e.target.checked;
		setTageState(tagArray);
		createTagElements();
	}

	function createTagElements() {
		// return if no data
		if (tagData === false) {
			return;
		}
		let tagArray = [];

		// create an input for the tag with state
		for (const tag of tagData?.data ?? []) {
			tagArray.push(<li key={tag.id}>
				<input checked={(tagState[tag.id] === undefined) ? false : tagState[tag.id]} type={"checkbox"} name={"tags"} onChange={updateTag} value={tag.id} id={"tag-edit-" + tag.id} />
				<label htmlFor={"tag-edit-" + tag.id} style={{ backgroundColor: tag.backgroundColor, color: tag.color }}>{tag.name}</label>
			</li >)
		}
		setTagElements(tagArray);
	}

	/**
	 * saves the edited message
	 */
	function saveEditedMessage(e) {
		e.preventDefault();

		// stop if data object is empty
		if (isEmpty(editFormData)) {
			return;
		}

		// check if text input has text.
		if (e.target['edit-chat-message-text'].value === "") {
			toast.error("Tekstveld kan niet leeg opgeslagen worden.");
			return;
		}

		let chosenTags = [];
		for (let key of Object.keys(tagState)) {
			// if checked store tag ID in array
			if (tagState[key]) {
				chosenTags.push(key);
			}
		}

		// save the messsage
		authAxios.put('/crm/notes/' + editFormData['edit-chat-message-id'], {
			data: {
				'content': e.target['edit-chat-message-text'].value,
				'company': !isSupplier ? editFormData['edit-chat-company-id'] : undefined,
				'supplier': isSupplier ? editFormData['edit-chat-company-id'] : undefined,
				'user': editFormData['edit-chat-user-id'],
				'tags': chosenTags
			}
		}).then(({ data: { data } }) => {
			if (setCompany !== undefined) {
				const key = editFormData['edit-chat-message-key'];
				const newNotes = company.notes;
				newNotes[key].content = data.content;
				newNotes[key].updatedAt = data.updatedAt;

				setCompany((prev) => ({ ...prev, notes: newNotes }));
			} else if (getNotes !== undefined) {
				getNotes();
			}

			// re-render the company notes
			closePopup();
			toast.success('Informatie bericht bijgewerkt');
		}).catch((exception) => {
			toast.error('Informatie bericht niet bijgewerkt!');

			console.error(exception);
		});
	}

	return (
		<Popup title='Bewerk bericht' popupID='edit-chat-message'>
			<form onSubmit={saveEditedMessage} key={editFormData['edit-chat-message-id']}>
				<ul className='company__message__labels'>
					{tagElements}
				</ul>
				<Inputs key={crypto.randomUUID()} inputs={[
					{
						type: 'multiple',
						name: 'edit-chat-message-hidden',
						subFields: [
							{
								type: 'hidden',
								name: 'edit-chat-message-id',
								value: editFormData['edit-chat-message-id'],
							},
							{
								type: 'hidden',
								name: 'edit-chat-message-key',
								value: editFormData['edit-chat-message-key'],
							},
							{
								type: 'hidden',
								name: 'edit-chat-message-user-id',
								value: editFormData['edit-chat-user-id'],
							},
							{
								type: 'hidden',
								name: 'edit-chat-message-company-id',
								value: editFormData['edit-chat-company-id'],
							},
						],
					},
					// {
					//     type: 'select',
					//     options: tags,
					//     name: 'edit-chat-message-tags',
					// },
					{
						type: 'textarea',
						name: 'edit-chat-message-text',
						placeholder: 'Update bericht',
						defaultValue: editFormData['edit-chat-message-text'],
						class: 'edit-chat-message-text',
						rows: 13,
					},
					{
						type: 'submit',
						name: 'edit-chat-message-submit',
						class: 'btn'
					},
				]} />
			</form>
		</Popup>
	)
}
