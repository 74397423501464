import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'UI/App/Components/Spinner';
import { AuthContext } from 'App/Strapi/AuthProvider';
import { getExpirationTimeFromJWT } from 'App/Strapi/StrapiAuthenticator';
import axios from 'axios';
import {useNavigate, useSearchParams} from 'react-router-dom';

export default function TerminalAuthenticator() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [authenticationState, setAuthenticationState] = useState('Authenticating...');
    const [authFailed, setAuthFailed] = useState(false);

    // get an instance of the auth provider which will be used to set the current auth state after authentication
    const { setAuthInfo, msGraph } = useContext(AuthContext);

    useEffect(() => {
        if (!setAuthInfo || !msGraph) {
            return;
        }

        const token = searchParams.get('jwt');

        const params = searchParams.entries();
        const relevantParams = [];
        // filter out the redirect if we have one
        let redirect = searchParams.get('redirect') ?? undefined;

        // check the rest of the params for things to pass
        for (let [key, value] of params) {
            if (key === 'jwt' || key === 'redirect') {
                continue;
            }
            relevantParams.push(`${key}=${value}`)
        }

        // format the params to url
        let parameters = "";
        if (relevantParams !== undefined && relevantParams?.length > 0) {
            parameters = `?${relevantParams?.join("&")}`
        }

        axios
            .get(process.env.REACT_APP_API_URL + '/users/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                const user = response.data;
                const expiresAt = getExpirationTimeFromJWT(token);

                setAuthInfo({
                    token: token,
                    expiresAt: expiresAt,
                    user: user
                });

                // disable ms graph
                msGraph.disable();

                setAuthenticationState('Authenticated');
                // make it clear this is a terminal user
                localStorage.setItem("isTerminalUser", "true");

                // Redirect to the home page
                // get last know path
                let lastKnowRoute = localStorage.getItem('lastRoute');

                // check if we have anything to work with
                if (lastKnowRoute?.length === undefined || lastKnowRoute.length === 0) {
                    lastKnowRoute = '/';
                }

                // if we have a known redirect we don't wane go to last know route
                if (redirect) {
                    // check if the redirect starts somewhere
                    if (!redirect?.startsWith('/')) {
                        redirect = `/${redirect}`;
                    }

                    navigate(redirect + parameters);
                } else {
                    navigate(lastKnowRoute + parameters);
                }
                // go to last know path


            })
            .catch((error) => {
                console.error(error);
                setAuthenticationState('Inloggen mislukt. Probeer het opnieuw.');
                setAuthFailed(true);
            });
    }, [msGraph, navigate, searchParams, setAuthInfo]);

    return (
        <div
            style={{
                display: 'flex',
                width: '100vw',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <span
                style={{
                    marginBottom: '20px'
                }}
            >
                {authenticationState}
            </span>
            {!authFailed && <Spinner />}
            {authFailed && <button onClick={() => (window.location.href = '/logout')}>Uitloggen</button>}
        </div>
    );
}
