import React, {useContext, useEffect, useState} from "react";
import Grid from "UI/App/Components/Grid/Grid";
import Select from "react-select";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import {formatDate} from "App/Util/format";


export default function CheckOutStorage({chosenStorageSpot, setWarehouse, setChosenStorageSpot}) {
    const { authAxios } = useContext(FetchContext);


    async function checkOut(){
        // Update storage spot
        await authAxios.put(`/storage-spot/${chosenStorageSpot.id}?populate=warehouse.storage`, {
            data: {
                contents: '',
            }
        }).then(({ data }) => {
            toast.success(`Uitgeboekt!`,{
                type: "success",
                    autoClose: 500
            });
            setWarehouse(data.data.warehouse)
            setChosenStorageSpot(null)
            closePopup();
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van de opslag positie`);
        });
    }

    return (
        <>
            <span style={{ display: 'block', margin: '1rem 0 2rem 0', textAlign: 'center' }}>Weet u zeker dat u "{chosenStorageSpot?.contents}" uit vak {chosenStorageSpot?.name} wilt uitboeken?</span>
            <Grid columns={2}>
                <button onClick={closePopup}>
                    Nee
                </button>
                <button onClick={checkOut}>
                    Ja
                </button>
            </Grid>

        </>
    )
}
