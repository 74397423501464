import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {useParams} from "react-router-dom";
import Block from "UI/App/Components/Block/Block";
import IF from "UI/App/Components/Conditional/IF";
import isEmpty from 'lodash/isEmpty';

export default function LogDetail() {
    // get the params from the url
    const params = useParams();
    const [logData, setLogData] = useState([]);
    const { authAxios } = useContext(FetchContext);

    useEffect(() => {
        authAxios.get(`/admin/logs/${params.logId}`, {
            params: {
                populate: "*"
            }
        }).then(({ data: {data} }) => {
            setLogData(data);
        });
    }, [authAxios])

    return (
        <Block name='log__info' title='Informatie'>
            <p><strong>Datum:</strong> {logData?.createdAt}</p>
            <p><strong>Actie:</strong> {logData?.action}</p>
            <IF condition={logData?.targetAction}>
                <p><strong>Doel actie:</strong> {logData?.targetAction}</p>
            </IF>
            <IF condition={logData?.location}>
                <p><strong>Locatie:</strong> {logData?.location}</p>
            </IF>

            <div className="columns">
                <br/>
                <div className="column">
                    <h3>Data</h3>
                    <p style={{ whiteSpace: "break-spaces", fontFamily: 'monospace', backgroundColor: '#f2f2f2', padding: '10px', maxHeight: '400px', overflow: "auto" }}>{JSON.stringify(logData?.payload, null, "    ")}</p>
                </div>
                <IF condition={logData?.parameters && !isEmpty(logData?.parameters)}>
                    <div className="column">
                        <h3>Parameters</h3>
                        <p style={{ whiteSpace: "break-spaces", fontFamily: 'monospace', backgroundColor: '#f2f2f2', padding: '10px', maxHeight: '400px', overflow: "auto" }}>{JSON.stringify(logData?.parameters, null, "    ")}</p>
                    </div>
                </IF>
                <IF condition={logData?.query && !isEmpty(logData?.query)}>
                    <div className="column">
                        <h3>Query</h3>
                        <p style={{ whiteSpace: "break-spaces", fontFamily: 'monospace', backgroundColor: '#f2f2f2', padding: '10px', maxHeight: '400px', overflow: "auto" }}>{JSON.stringify(logData?.query, null, "    ")}</p>
                    </div>
                </IF>
                <br/>
                { logData?.user &&
                    <div className="column">
                        <h3>Gebruiker</h3>
                        <p><strong>id:</strong> {logData?.user?.id}</p>
                        <p><strong>Voornaam:</strong> {logData?.user?.firstName}</p>
                        <p><strong>Achternaam:</strong> {logData?.user?.lastName}</p>
                        <p><strong>Email:</strong> {logData?.user?.email}</p>
                    </div>
                }
                {
                    logData?.data?.attributes?.apiToken &&
                    <div className="column">
                        <h3>API Key</h3>
                        <p><strong>Key:</strong> {logData?.apiToken}</p>
                    </div>
                }
            </div>
        </Block>
    )
}
