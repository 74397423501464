import React from "react";
import WidgetCollection from "../Widgets/Widgets/WidgetCollection";
import {toast} from "react-toastify";

export default class CalculationDataProvider extends React.Component {

    /**
     * Fill individual
     */
    async fillIndividualWidget(widgetData, element, data, values, withAmounts = false,showVisibilityCheckbox = false) {
        /**
         * Get widget value
         */
        let value = null
        const parsedWidgetData = JSON.parse(widgetData)

        if (data[parsedWidgetData.parentId] !== undefined) {
            for (const widgetOfMachine of data[parsedWidgetData.parentId].widgets) {
                if (widgetOfMachine.id === parsedWidgetData.id) {
                    value = widgetOfMachine.value;
                }
            }
        }

        const widgetCollection = new WidgetCollection(null, null, null, parsedWidgetData, false, parsedWidgetData.prefix,showVisibilityCheckbox);

        let widgetValueId = '';

        // If parentID contains extra prefix remove that
        // \-(.*?)\-

        if((parsedWidgetData.parentId.match(/-/g) || []).length === 2){
            widgetValueId = parsedWidgetData.parentId.replace(/\-(.*?)\-/, '-');
        }else{
            widgetValueId = parsedWidgetData.parentId;
        }

        /**
         * If select or material choice
         */
        if (parsedWidgetData.data.type === 'MaterialChoice' || parsedWidgetData.data.type === 'SelectWidget') {
            const data = await widgetCollection.getWidgetData(parsedWidgetData.data.type, element);

            if (!data) {
                return Promise.resolve(false)
            }

            const selectElement = document.getElementById(`${parsedWidgetData.prefix}${parsedWidgetData.id}`);

            /**
             * Empty select element
             */
            selectElement.querySelector('select').innerHTML = '';

            if (selectElement === null) {
                console.error(`[MaterialChoice]: Select with id '${parsedWidgetData.prefix}${parsedWidgetData.id}' not found!`);
                toast.error(`Something went wrong trying to set material data!`);
            }

            let optGroupedValues = [];
            Object.entries(data).forEach(([key, value]) => {

                if (value.length !== undefined) {
                    optGroupedValues.push(`<optgroup label="${key}">`);
                    if (parsedWidgetData.data.type === 'MaterialChoice') {

                        for (const optionData of value) {
                            optGroupedValues.push(`<option value="${optionData.value}" data-material='${JSON.stringify(optionData.dataMaterial)}'>${optionData.title}${optionData.dataMaterial.internalSKU ? ` (${optionData.dataMaterial.internalSKU})` : ''}</option>`);
                        }

                    } else {
                        if(Array.isArray(value)){
                            for (const optionData of value) {
                                optGroupedValues.push(`<option value="${optionData.value}">${optionData.title}</option>`);
                            }
                        } else {
                            optGroupedValues.push(`<option value="${value.value}">${value.title}</option>`);
                        }
                    }
                    optGroupedValues.push(`</optgroup>`);
                } else {
                    optGroupedValues.push(`<option value="${value.value}" >${value.title}</option>`);
                }
            })

            selectElement.querySelector('select').innerHTML = `<option disabled selected value="">Kies materiaal...</option>` + optGroupedValues.join('');
            selectElement.querySelector('select').removeAttribute('data-loading');

            /**
             * If machine has values
             */
            let chosenValue = null;
            if (values !== undefined) {
                if (values[widgetValueId] !== undefined) {
                    if (!withAmounts) {
                        for (const widget of values[widgetValueId][Object.keys(values[widgetValueId])[0]]) {
                            if (widget.id === parsedWidgetData.id) {
                                chosenValue = widget.value
                            }
                        }
                    } else {
                        for (const widget of values[widgetValueId]) {
                            if (widget.id === parsedWidgetData.id) {
                                chosenValue = widget.value
                            }
                        }
                    }

                    if (chosenValue !== null) {
                        for (const option of document.getElementById(element).querySelector('select').options) {
                            if (parsedWidgetData.data.type === 'SelectWidget') {
                                document.getElementById(element).querySelector('.selectWidget').value = chosenValue
                            } else {
                                if (!withAmounts) {
                                    if (JSON.parse(chosenValue)?.data?.value?.result?.id !== undefined) {
                                        if (JSON.parse(chosenValue)?.data?.value?.result?.id == option.value) {
                                            option.setAttribute('selected', true);
                                        }
                                    }
                                } else {
                                    if (chosenValue !== undefined) {
                                        if (chosenValue == option.value) {
                                            option.setAttribute('selected', true);
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }

        } else if (parsedWidgetData.data.type === 'Input') {
            if (values !== undefined) {
                if (values[widgetValueId] !== undefined) {
                    if (!withAmounts) {
                        for (const widget of values[widgetValueId][Object.keys(values[widgetValueId])[0]]) {
                            if (widget.id === parsedWidgetData.id) {
                                document.getElementById(element).querySelector(".inputWidgetInput").value = widget.value
                                break;
                            }
                        }
                    } else {
                        for (const widget of values[widgetValueId]) {
                            if (widget.id === parsedWidgetData.id) {
                                document.getElementById(element).querySelector(".inputWidgetInput").value = widget.value
                                break;
                            }
                        }
                    }
                }
            }
        } else if (parsedWidgetData.data.type === 'HourWidget') {
            if (values !== undefined) {
                if (values[widgetValueId] !== undefined) {
                    for (const widget of values[widgetValueId]) {
                        if (widget.id === parsedWidgetData.id) {
                            document.getElementById(element).querySelector("input").value = widget.value.input
                            for (const option of document.getElementById(element).querySelector("select").options) {
                                if (option.value === widget.value.select) {
                                    option.setAttribute('selected', true)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
