import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import { FetchContext } from 'App/Strapi/FetchContext';
import Icon from 'UI/App/Components/Icon/Icon';
import Table from 'UI/App/Components/Table/Table';
import Container from "UI/App/Components/Container/Container";
import useWindowDimensions from "App/Util/useWindowDimensions";
import { toast } from "react-toastify";
import { stringify } from 'qs';

export default function ManagersIndex() {
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const { authAxios } = useContext(FetchContext);

    let [forceUpdate, setForceUpdate] = useState({});

    // create a state for the table data
    const [tableData, setTableData] = useState([]);

    // create a state for the table columns (sort order and column field)
    const [tableColumns, setTableColumns] = useState({
        sort: {
            column: 'name',
            direction: 'asc'
        },

        fields: [
            { name: 'Naam', field: 'name', sortable: true },
            { name: 'Bedrijven', field: '', sortable: false },
            { name: 'Gebruikers', field: '', sortable: false },
            { name: 'Delete', fiekd: '', sortable: false }
        ]
    });
    const handleCreate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/admin/managers/create`, { state: {} })
    }

    const handleDelete = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        authAxios.delete(`/crm/managers/${id}`).then(({ data }) => {
            setForceUpdate((prevState, props) => ({
                counter: prevState.counter + 1
            }))
        })
    }
    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded,
        setFilterValues
    } = usePagination({
        storageKey: 'crm-managers',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
            ]
        },
        htmlElements: [

            <span className='pagination__filter addItem' key='link--add'>
                <Link onClick={handleCreate} name="createManager" className='btn btn--add btn--icon-right'>
                    Toevoegen
                    <Icon name='plus' />
                </Link>
            </span>,

        ],
        buttonCollapseBreakpoint: 1000,
        resultsPerPageBreakpoint: 1960,
        paginationBreakpoint: 1270,
        resultCountBreakpoint: 1850
    });

    useEffect(() => {
        if (!paginationStateLoaded) return;

        // build the filter parameters for this query
        const query = buildSearchParams(resultsPerPage, currentPage, tableColumns);

        // get the companies using the built params
        authAxios
            .get(`/crm/managers?${query}&${filterQuery}`)
            .then(({ data }) => {
                const tableData = [];


                for (const managerEntry of data.data) {

                    tableData.push({
                        attributes: {
                            onClick: (e) => {
                                navigate(`/admin/managers/edit/${managerEntry.id}`, { state: { id: managerEntry.id } })

                            }
                        },
                        data: [
                            managerEntry.name,
                            [...new Set(managerEntry.managerRecords.map(entry => entry.company.name))].length,//managerEntry.managerRecords.map(entry => entry.company.name).length,
                            managerEntry.managerRecords.length,
                            <Link data-title={'Typen verwijderen'} value={managerEntry.id} className={'btn btn--transparent'} onClick={(e) => { handleDelete(e, managerEntry.id) }} >
                                <Icon name={'trash-can'} />
                            </Link >
                        ],
                    })
                }
                setTableData(tableData);

                // Set pagination
                const paginationData = data.meta.pagination;
                setTotalPages(paginationData.pageCount);
                setTotalResults(paginationData.total);
            }).catch((exception) => {
                toast.error(`Er ging iets fout met het ophalen van de managers`);
                console.error(exception);
            });
    }, [
        currentPage,
        resultsPerPage,
        authAxios,
        setTotalPages,
        setTotalResults,
        filterQuery,
        tableColumns,
        paginationStateLoaded,
        forceUpdate
    ]);


    return (
        <div>
            {filtering}
            <Container>
                <Table
                    headers={[
                        'Naam',
                        'Bedrijven',
                        'Gebruikers',
                        ''
                    ]}
                    structure={{
                        default: ['60%', '20%', '20%', '20%']
                    }}
                    data={tableData}
                    tableColumns={tableColumns}
                    setTableColumns={setTableColumns}
                    columnOverflowExceptions={[2, 3, 4]}
                    hover
                    border='row'
                    overflowText={true}
                />
            </Container>
            {paging}
        </div>
    );
}
function buildSearchParams(resultsPerPage, currentPage, tableColumns) {

    let queryParams = {
        pagination: {
            page: currentPage,
            pageSize: resultsPerPage
        },
        populate: {
            managerRecords: {
                populate: {
                    company: true
                }
            }
        },
        sort: `${tableColumns.sort.column}:${tableColumns.sort.direction} `
    };

    const query = stringify(queryParams, {
        encodeValuesOnly: true // prettify URL
    });

    return query;
}
