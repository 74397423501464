import { FetchContext } from "App/Strapi/FetchContext";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Inputs, { getFormData } from "UI/App/Components/Form/Inputs";
import { getCountriesList } from "../../../../../../App/Util/countries";
import Block from "../../../../Components/Block/Block";
import Icon from "../../../../Components/Icon/Icon";
import strapiToast from "App/Util/errorHandle";

import "./Company/company.scss";
import Container from "UI/App/Components/Container/Container";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function CreateCompany() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();

    // check if on suppliers path
    const location = useLocation();
    const [isSupplier, setIsSupplier] = useState(location.pathname.includes('leverancier'));

    const { width } = useWindowDimensions();

    const [types, setTypes] = useState([]);
    const [branches, setBranches] = useState([]);
    const [tags, setTags] = useState([]);
    // get types and branches from crm config
    const [inputs, setInputs] = useState([]);
    const [countryNames, setCountryNames] = useState([]);
    const [countryLandCodes, setCountryLandCodes] = useState([]);
    const [searchParams] = useSearchParams();
    const params = useParams();

    useEffect(() => {
        getCountriesList().then((result) => {
            setCountryNames(result.countries);
            setCountryLandCodes(result.countryPhoneCodes);
        });
    }, [])

    useEffect(() => {
        setInputData(types, branches, tags);
    }, [types, branches, tags, width])


    useEffect(() => {
        if (countryNames?.length === 0 || countryLandCodes?.length === 0) {
            return;
        }


        authAxios.get(`/config/crm`, {
            params: {
                populate: "*"
            }
        }).then(({ data: { data } }) => {
            const _types = [];
            const _branches = [];
            const _tags = [];

            // suppliers dont have types or branches
            if (!isSupplier) {
                for (const type of data.companyTypes.split('\n')) {
                    _types.push({ value: type, text: type });
                }

                for (const branche of data.companyBranches.split('\n')) {
                    _branches.push({ value: branche, text: branche });
                }
            }

            for (const tagGroup of data?.companyTags ?? []) {
                if (!tagGroup.groupName || !tagGroup.tags) {
                    continue;
                }

                const tagOptions = [];
                for (const tag of tagGroup?.tags.split('\n') ?? []) {
                    tagOptions.push({ value: tag, text: tag });
                }

                _tags.push({
                    type: 'checkbox',
                    name: `tags[${tagGroup.groupName}]`,
                    label: tagGroup.groupName,
                    direction: 'row',
                    // component: 'data.tag-group',
                    options: [
                        ...tagOptions
                    ]
                });
            }

            setTypes(_types)
            setBranches(_branches)
            setTags(_tags)

        }).catch((exception) => {
            console.error(exception);
        });
    }, [authAxios, isSupplier, countryNames]);

    function setInputData(types, branches, tags) {


        // prepare companytype on url params
        let formattedParam = undefined;
        if (params.type !== undefined) {

            let param = params?.type
            formattedParam = param.charAt(0).toUpperCase() + param.slice(1);
        }

        let inputs = [];

        inputs.push({
            type: 'multiple',
            name: 'CompanyData',
            label: 'KVK / BTW',
            required: false,
            subFields: [
                {
                    type: 'text',
                    name: 'kvk',
                    placeholder: 'KVK...',
                    required: false,
                    style: {
                        width: width > 400 ? '50%' : '100%'
                    },
                },
                {
                    type: 'text',
                    name: 'BTW',
                    placeholder: 'BTW...',
                    required: false,
                    style: {
                        width: width > 400 ? '50%' : '100%'
                    },
                }
            ]
        });

        // inputs.push({
        //     type: 'text',
        //     name: 'kvk',
        //     label: 'Kvk',
        //     placeholder: 'Kvk...',
        //     required: false,
        // });

        inputs.push(
            {
                type: 'text',
                name: 'name',
                label: 'Bedrijfsnaam',
                placeholder: 'Bedrijfsnaam...',
                required: true,
                // Set the default value to the search param companyName if it exists
                defaultValue: searchParams.get('companyName') ?? '',
            },
            !isSupplier ? {
                type: 'select',
                name: 'type',
                label: 'Type bedrijf',
                options: types,
                value: formattedParam,
                required: true,
            } : {},
            !isSupplier ? {
                type: 'select',
                name: 'industry',
                label: 'Branche',
                options: branches,
                required: true,
            } : {},
            {
                type: 'multiple',
                name: 'address',
                label: 'Adres',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'address[addressLine1]',
                        placeholder: 'Adres regel 1...',
                        required: true,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine2]',
                        placeholder: 'Adres regel 2...',
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine3]',
                        placeholder: 'Adres regel 3...',
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[postalCode]',
                        placeholder: 'Postcode...',
                        required: true,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[city]',
                        placeholder: 'Stad...',
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[province]',
                        placeholder: 'Provincie...',
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'select',
                        name: 'address[country]',
                        placeholder: 'Land...',
                        options: countryNames,
                        value: 'Netherlands',
                        required: true,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'email',
                name: 'generalEmail',
                label: 'E-mailadres',
                placeholder: 'E-mailadres...',
                required: true,
            },
            {
                type: 'email',
                name: 'invoiceEmail',
                label: 'Facturatie e-mailadres',
                placeholder: 'Facturatie e-mailadres...',
            },
            {
                type: 'multiple',
                name: 'telephone',
                label: 'Telefoonnummer',
                subFields: [
                    {
                        type: 'select',
                        name: 'telephone[dialingCode]',
                        class: "dialingCode",
                        placeholder: 'Landcode...',
                        options: countryLandCodes,
                        value: '31'
                    },
                    {
                        type: 'tel',
                        name: 'telephone[telephoneNumber]',
                        placeholder: 'Telefoonnummer...',
                        class: "telephoneNumber",

                    }
                ]
            },
            {
                type: 'text',
                name: 'website',
                label: 'Website',
                placeholder: 'https://website.com',
            },
            {
                type: 'file',
                name: 'logo',
                label: 'logo',
                accept: 'image/*',
            },
            ...tags
        );

        console.log((location.state.owner === "hjmg") ? true : false)
        inputs.push({
            type: 'multiple',
            name: 'managingCompany[HJMG]',
            label: 'Exact nummers:',
            required: false,
            subFields: [
                {
                    type: 'checkbox',
                    name: `managingCompany[HJMG]`,
                    style: {
                        width: '20px'
                    },
                    options: [
                        {
                            value: 'true',
                            text: 'HJMG',
                            checked: (location.state.owner === "hjmg") ? true : false,
                        }
                    ],
                },
                {
                    type: 'text',
                    name: 'managingCompany[exactHJMG]',
                    placeholder: 'Exact nummer...',
                    required: false,
                    style: {
                        width: '100%',
                    },
                },
                {
                    type: 'checkbox',
                    name: `managingCompany[Pretore]`,
                    style: {
                        width: '20px',
                    },
                    options: [
                        {
                            value: 'true',
                            text: 'Pretore',
                            checked: (location.state.owner === "pretore") ? true : false,
                        }
                    ]
                },
                {
                    type: 'text',
                    name: 'managingCompany[exactPretore]',
                    placeholder: 'Exact nummer...',
                    required: false,
                    style: {
                        width: '100%',
                    },
                },
            ]
        },
        );

        inputs.push({
            type: 'submit',
            name: 'submit',
            class: 'btn'
        },)

        setInputs(inputs);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        let data = await getFormData(e, authAxios);

        const resetToNull = ['kvk', 'invoiceEmail'];

        for (const key of resetToNull) {
            if (data[key] === '') {
                data[key] = null;
            }
        }

        if (data.telephone.dialingCode === '' || data.telephone.telephoneNumber === '') {
            data.telephone = null;
        }

        data.managingCompany = {
            HJMG: data?.managingCompany?.HJMG === 'true',
            exactHJMG: data?.managingCompany?.exactHJMG,
            Pretore: data?.managingCompany?.Pretore === 'true',
            exactPretore: data?.managingCompany?.exactPretore,
        }

        if (!/[a-zA-Z0-9]/.test(data.name)) {
            toast.error('Bedrijfsnaam moet minimaal 1 letter of cijfer bevatten');
            return;
        }

        const valueCheck = document.getElementById('logo').value.split('.');

        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];

        if (!allowedExtensions.includes(document.getElementById('logo').value.split('.')[valueCheck.length - 1])) {
            if (document.getElementById('logo').value !== '') {
                toast.error('Er is geen afbeelding toegevoegd bij bedrijf logo');
            }
            // return;
        }

        authAxios.post(!isSupplier ? '/crm/companies' : '/crm/suppliers', {
            data
        }).then(async ({ data }) => {
            toast.success('Bedrijf aangemaakt');

            // If checklist id set, update the checklist with the company id
            if (searchParams.get('checklistId') !== null) {
                const checklistId = parseInt(searchParams.get('checklistId'));
                // Update the checklist with the company id
                await authAxios.put('/calculations/checklists/' + checklistId, {
                    data: {
                        company: data.data.id
                    }
                });
            }

            navigate((!isSupplier ? '/crm/companies/' : '/crm/suppliers/') + data.data.id);

        }).catch((e) => {
            // mag van brian
            let companyNameNotUniq = false;
            if (e.response?.data?.error?.details?.errors !== undefined) {
                for (let error of e.response?.data?.error?.details?.errors) {
                    if (error.path.includes("companyName") && error.message === "This attribute must be unique") {
                        companyNameNotUniq = true;
                    }

                }
            }
            if (companyNameNotUniq) {
                toast.error('Het ziet er naar uit dat er al een bedrijf met deze naam bestaat, dit bedrijf kan ook in het archief staan',
                    { autoClose: 10000 }
                );
            } else {
                strapiToast(e);
            }


            console.error(e);
        });
    }

    return (
        <Container>
            <Block name={'CreateCompany'} title={<>
                <Link className={'btn btn--transparent'} to={`/crm`}><Icon name={'arrow-left'} /></Link>
                Bedrijf toevoegen
            </>}>
                <form onSubmit={handleSubmit}>
                    {/*<KVKConnection />*/}
                    <Inputs key={crypto.randomUUID()} inputs={inputs} />
                </form>
            </Block>
        </Container>
    );
}
