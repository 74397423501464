export default function VestigingDetailItem({title, display = true, content}) {
	// if the content (lines) is empty, return nothing
	if (!(content?.length ?? 0) || !display) {
		return (
			null
		)
	}

	return (
		<div className='company__item' key={title}>
			<strong className='company-details__title' key={title}>{title}</strong>
			{
				content?.map((line, index) => {
					return line ? <div key={index} className='company-details__line'>{line}</div> : null;
				})
			}
		</div>
	)
}