import {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {FetchContext} from "App/Strapi/FetchContext";
import {toast} from "react-toastify";
import Inputs, {getFormData} from "UI/App/Components/Form/Inputs";
import {getCountriesList, updateOldCountryName} from "../../../../../../App/Util/countries";
import Block from "../../../../Components/Block/Block";
import strapiToast from "App/Util/errorHandle";
import QueryString from "qs";
import {sanitizeData} from "App/Util/transform";
import Container from "UI/App/Components/Container/Container";
import Icon from "UI/App/Components/Icon/Icon";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function EditCompany() {
    // create a new fetch context for fetching the company
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();

    // check if on suppliers path
    const location = useLocation();
    const isSupplier = /\/suppliers\//.test(location.pathname);

    const { width } = useWindowDimensions();

    const [types, setTypes] = useState([]);
    const [branches, setBranches] = useState([]);
    const [tags, setTags] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [countryNames, setCountryNames] = useState([]);
    const [countryLandCodes, setCountryLandCodes] = useState([]);
    const [inputCollection,setInputCollection] = useState(null);

    // get the params from the url
    const params = useParams();

    useEffect(() => {
        getCountriesList().then((result) => {
            setCountryNames(result.countries);
            setCountryLandCodes(result.countryPhoneCodes);
        });
    }, [])

    useEffect(() => {
        setInputData(companyData, types, branches, tags);
    }, [companyData, types, branches, tags, width])

    useEffect(() => {
        if (countryNames?.length === 0 || countryLandCodes?.length === 0) {
            return;
        }
        // create query string out of object
        const queryParams = QueryString.stringify({
            populate: {
                address: true,
                telephone: true,
                managingCompany: true,
                branches: {
                    populate: {
                        address: true,
                    },
                },
                logo: true
            }
        })

        authAxios.get(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}?` + queryParams)
            .then(({data: {data: companyData}}) => {
                companyData = sanitizeData(companyData);

                const _types = [];
                const _branches = [];
                const _tags = [];

                authAxios.get(`/config/crm`, {
                    params: {
                        populate: "*"
                    }
                }).then(async ({data: {data}}) => {
                    // suppliers dont have types or branches
                    if (!isSupplier) {
                        for (const type of data.companyTypes.split('\n')) {
                            _types.push({value: type, text: type});
                        }

                        for (const branche of data.companyBranches.split('\n')) {
                            _branches.push({value: branche, text: branche});
                        }
                    }

                    for (const tagGroup of data?.companyTags ?? []) {
                        if (!tagGroup.groupName || !tagGroup.tags) {
                            continue;
                        }

                        const tagOptions = [];
                        for (const tag of tagGroup?.tags.split('\n') ?? []) {
                            tagOptions.push({value: tag, text: tag, checked: (companyData?.tags?.[tagGroup?.groupName] ?? []).includes(tag)});
                        }

                        _tags.push({
                            type: 'checkbox',
                            name: `tags[${tagGroup.groupName}]`,
                            label: tagGroup.groupName,
                            direction: 'row',
                            options: [
                                ...tagOptions
                            ]
                        });
                    }

                    if (companyData.logo?.id !== undefined) {
                        await authAxios.get("../download/files/" + companyData.logo?.id,
                            {
                                responseType: 'arraybuffer',
                            })
                            .then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data], companyData.logo?.ext === '.svg' ? {type: 'image/svg+xml'} : undefined));
                                companyData.logo = url;
                            })
                            .catch((error) => {
                                companyData.logo = undefined;
                                toast.error("Het logo kon niet worden weergegeven");
                                console.log(error);
                            });
                    } else {
                        companyData.logo = undefined;
                    }

                    setCompanyData(companyData);
                    setTypes(_types);
                    setBranches(_branches);
                    setTags(_tags);

                    // setKVK(companyData.kvk);
                }).catch((exception) => {
                    process.env.NODE_ENV === 'development' && console.error(exception);
                });

            }).catch((exception) => {
            toast.error('Kon bedrijf informatie niet ophalen');

            // navigate((!isSupplier ? '/crm/' : '/crm/suppliers/'));

            process.env.NODE_ENV === 'development' && console.error(exception);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryNames, countryLandCodes]);

    function setInputData(data, types = [], branches = [], tags = []) {
        let inputs = [];

        // if(isSupplier){
        inputs.push({
            type: 'multiple',
            name: 'CompanyData',
            label: 'KVK / BTW',
            required: false,
            subFields: [
                {
                    type: 'text',
                    name: 'kvk',
                    placeholder: 'KVK...',
                    required: false,
                    style: {
                        width: width > 400 ? '50%' : '100%'
                    },
                    defaultValue: (data.kvk !== undefined ? data.kvk : ''),
                },
                {
                    type: 'text',
                    name: 'BTW',
                    placeholder: 'BTW...',
                    required: false,
                    defaultValue: data.BTW,
                    style: {
                        width: width > 400 ? '50%' : '100%'
                    },
                }
            ]
        });
        // }else{
        if (!data.managingCompany) {
            data.managingCompany = {
                "HJMG": false,
                "Pretore": false,
                "exactHJMG": '',
                "exactPretore": ''
            }
        }

        inputs.push(
            {
                type: 'text',
                name: 'name',
                label: 'Bedrijfsnaam',
                placeholder: 'Bedrijfsnaam...',
                required: true,
                defaultValue: data.name,
            },
            !isSupplier ? {
                type: 'select',
                name: 'type',
                label: 'Type bedrijf',
                options: types,
                // Use find to get the right value. This is to avoid casing issues since the original values come from an import script
                value: types.find((type) => type.value.toLowerCase() === data?.type?.toLowerCase())?.value,
                required: true,
            } : {},
            !isSupplier ? {
                type: 'select',
                name: 'industry',
                label: 'Branche',
                options: branches,
                // Use find to get the right value. This is to avoid casing issues since the original values come from an import script
                value: branches.find((branche) => branche.value.toLowerCase() === data?.industry?.toLowerCase())?.value,
                required: true,
            } : {},
            {
                type: 'multiple',
                name: 'address',
                label: 'Adres',
                required: true,
                subFields: [
                    {
                        type: 'text',
                        name: 'address[addressLine1]',
                        placeholder: 'Adres regel 1...',
                        required: true,
                        defaultValue: data?.address?.addressLine1,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine2]',
                        placeholder: 'Adres regel 2...',
                        defaultValue: data?.address?.addressLine2,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[addressLine3]',
                        placeholder: 'Adres regel 3...',
                        defaultValue: data?.address?.addressLine3,
                        style: {
                            width: '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[postalCode]',
                        placeholder: 'Postcode...',
                        required: true,
                        defaultValue: data?.address?.postalCode,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[city]',
                        placeholder: 'Stad...',
                        required: true,
                        defaultValue: data?.address?.city,
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                    {
                        type: 'text',
                        name: 'address[province]',
                        placeholder: 'Provincie...',
                        defaultValue: data?.address?.province,
                        style: {
                            width: width > 500 ? '30%' : '100%'
                        },
                    },
                    {
                        type: 'select',
                        name: 'address[country]',
                        placeholder: 'Land...',
                        options: countryNames,
                        required: true,
                        value: updateOldCountryName(data?.address?.country),
                        style: {
                            width: width > 500 ? '70%' : '100%'
                        },
                    },
                ]
            },
            {
                type: 'email',
                name: 'generalEmail',
                label: 'E-mailadres',
                placeholder: 'E-mailadres...',
                required: true,
                defaultValue: data?.generalEmail,
            },
            {
                type: 'email',
                name: 'invoiceEmail',
                label: 'Facturatie e-mailadres',
                placeholder: 'Facturatie e-mailadres...',
                defaultValue: data?.invoiceEmail,
            },
            {
                type: 'multiple',
                name: 'telephone',
                label: 'Telefoonnummer',
                subFields: [
                    {
                        type: 'select',
                        name: 'telephone[dialingCode]',
                        placeholder: 'Landcode...',
                        min: 1,
                        max: 999,
                        value: data?.telephone?.dialingCode,
                        options: countryLandCodes,
                        style: {
                            width: width > 600 ? '20%' : '100%'
                        },
                    },
                    {
                        type: 'tel',
                        name: 'telephone[telephoneNumber]',
                        placeholder: 'Telefoonnummer...',
                        min: 0,
                        minLength: 5,
                        defaultValue: data?.telephone?.telephoneNumber,
                        style: {
                            width: width > 600 ? '80%' : '100%'
                        },
                    }
                ]
            },
            {
                type: 'text',
                name: 'website',
                label: 'Website',
                placeholder: 'https://website.com',
                defaultValue: data?.website,
            },
            {
                type: 'file',
                name: 'logo',
                label: 'Logo',
                accept: 'image/*',
                style: {marginTop: 10},
                alt: data?.logo?.data?.attributes?.name,
                value: data?.logo,
            },
            ...tags
        )

        inputs.push({
                type: 'multiple',
                name: 'managingCompany[HJMG]',
                label: 'Exact nummers:',
                required: false,
                subFields: [
                    {
                        type: 'checkbox',
                        name: `managingCompany[HJMG]`,
                        style: {
                            width: '20px'
                        },
                        options: [
                            {
                                value: 'true',
                                text: 'HJMG',
                                checked: data?.managingCompany?.HJMG
                            }
                        ]
                    },
                    {
                        type: 'text',
                        name: 'managingCompany[exactHJMG]',
                        placeholder: 'Exact nummer...',
                        required: false,
                        defaultValue: data?.managingCompany?.exactHJMG,
                        style: {
                            width: '100%',
                        },
                    },
                    {
                        type: 'checkbox',
                        name: `managingCompany[Pretore]`,
                        style: {
                            width: '20px'
                        },
                        options: [
                            {
                                value: 'true',
                                text: 'Pretore',
                                checked: data?.managingCompany?.Pretore,
                            }
                        ]
                    },
                    {
                        type: 'text',
                        name: 'managingCompany[exactPretore]',
                        placeholder: 'Exact nummer...',
                        required: false,
                        defaultValue: data?.managingCompany?.exactPretore,
                        style: {
                            width: '100%',
                        },
                    },
                ]
            })

        inputs.push({
                type: 'submit',
                name: 'submit',
                class: 'btn'
            },
        );

        setInputs(inputs);
    }

    async function handleSubmit(e) {
        e.preventDefault();

        let data = await getFormData(e, authAxios);

        const resetToNull = ['kvk', 'invoiceEmail'];

        for (const key of resetToNull) {
            if (data[key] === '') {
                data[key] = null;
            }
        }

        if (data.telephone.dialingCode === '' || data.telephone.telephoneNumber === '') {
            data.telephone = null;
        }

        data.managingCompany = {
            HJMG: data?.managingCompany?.HJMG === 'true',
            exactHJMG: data?.managingCompany?.exactHJMG,
            Pretore: data?.managingCompany?.Pretore === 'true',
            exactPretore: data?.managingCompany?.exactPretore,
        }

        const valueCheck = document.getElementById('logo').value.split('.');
        if (!/[a-zA-Z0-9]/.test(data.name)) {
            toast.error('Bedrijfsnaam bevat geen leestekens');
            return;
        }

        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];

        if (!allowedExtensions.includes(document.getElementById('logo').value.split('.')[valueCheck.length - 1])) {
            if (document.getElementById('logo').value !== '') {
                toast.error('Er is geen afbeelding toegevoegd bij bedrijf logo');
            }
            // return;
        }
        // clear kvk if empty
        data.kvk = data.kvk === '' ? null : data.kvk;
        // clear invoice email if empty
        data.invoiceEmail = data.invoiceEmail === '' ? null : data.invoiceEmail;

        authAxios.put((!isSupplier ? '/crm/companies/' : '/crm/suppliers/') + params.companyId, {
            data
        }).then(({data}) => {
            console.log(data);
            toast.success('Bedrijf bijgewerkt');
            navigate((!isSupplier ? '/crm/companies/' : '/crm/suppliers/') + data.data.id);
        }).catch((e) => {

            strapiToast(e);

            console.error(e);
        });
    }

    return (
        <Container>
            <Block name={"EditCompany"} title={<>
                <Link className={'btn btn--transparent'} to={`/crm/${!isSupplier ? 'companies' : 'suppliers'}/${params.companyId}`}><Icon name={'arrow-left'}/></Link>
                Bedrijf aanpassen
            </>}>
                <form onSubmit={handleSubmit}>
                    {/*<KVKConnection kvk={kvk} companyId={params.companyId} companyType={!isSupplier ? 'companies' : 'suppliers'}/>*/}
                    <Inputs inputs={inputs} key={crypto.randomUUID()}/>
                </form>
            </Block>
        </Container>
    );
}
