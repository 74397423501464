import { useEffect, useState } from 'react';
import './Boolean.scss';

type TBooleanControlled = {
    id?: string;
    style?: { [key: string]: any };
    className?: string;
    value?: boolean | null;
    setValue?: (value: boolean | string | number | null | undefined) => void;
    leftLabel?: string;
    leftIsRed?: boolean;
    leftDisabled?: boolean;
    leftValue?: boolean | string | number;
    rightLabel?: string;
    rightIsRed?: boolean;
    rightDisabled?: boolean;
    rightValue?: boolean | string | number;
    nullable?: boolean;
    nullDisabled?: boolean;
};

export function BooleanControlled({
    id = undefined,
    style = {},
    className = '',
    value = undefined,
    setValue = () => {},
    leftLabel = 'False',
    leftIsRed = false,
    leftDisabled = false,
    leftValue = false,
    rightLabel = 'True',
    rightIsRed = false,
    rightDisabled = false,
    rightValue = true,
    nullable = false,
    nullDisabled = false
}: TBooleanControlled) {
    // Execute the supplied setter callback on component first mount.
    // Sets the value to what is in the `value` attr on the first render of the component.
    // This is usually the value that you put after the null coalescing before a state would be initialized.
    // So on load you have your desired default value set in the state it needs to be in.
    useEffect(
        () => setValue(value),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div {...{ id, style }} className={`boolean ${className}`}>
            <button
                type='button' // to not submit forms
                className={`boolean__value ${leftIsRed && 'boolean__value--red'}`}
                data-value={leftValue}
                data-selected={value === leftValue}
                disabled={leftDisabled}
                onClick={(e) => {
                    e.stopPropagation();

                    // stop if button is disabled,
                    // the clicked element is not a button (ts),
                    if (leftDisabled || !(e.target instanceof HTMLButtonElement)) return;

                    let returnValue = leftValue;

                    // transform stringBoolean to boolean
                    if (typeof returnValue === 'string') {
                        if (returnValue.toLowerCase() === 'true') {
                            returnValue = true;
                        } else if (returnValue.toLowerCase() === 'false') {
                            returnValue = false;
                        }
                    }

                    // stop if returnValue is the same as the already set value
                    if (returnValue === value) return;

                    // execute the supplied setter callback
                    setValue(returnValue);
                }}
            >
                {leftLabel}
            </button>
            <button
                type='button' // to not submit forms
                className={`boolean__value ${rightIsRed && 'boolean__value--red'}`}
                data-value={rightValue}
                data-selected={value === rightValue}
                disabled={rightDisabled}
                onClick={(e) => {
                    e.stopPropagation();

                    // stop if button is disabled,
                    // the clicked element is not a button (ts),
                    if (rightDisabled || !(e.target instanceof HTMLButtonElement)) return;

                    let returnValue = rightValue;

                    // transform stringBoolean to boolean
                    if (typeof returnValue === 'string') {
                        if (returnValue.toLowerCase() === 'true') {
                            returnValue = true;
                        } else if (returnValue.toLowerCase() === 'false') {
                            returnValue = false;
                        }
                    }

                    // stop if returnValue is the same as the already set value
                    if (returnValue === value) return;

                    // execute the supplied setter callback
                    setValue(returnValue);
                }}
            >
                {rightLabel}
            </button>

            {nullable && (
                <button
                    type='button'
                    className='boolean__unset'
                    data-value='null'
                    data-selected={value === null}
                    disabled={nullDisabled}
                    onClick={(e) => {
                        e.stopPropagation();

                        // stop if the clicked element is not a button (ts)
                        if (!(e.target instanceof HTMLButtonElement)) return;

                        // stop if returnValue is the same as the already set value
                        if (null === value) return;

                        // execute the supplied setter callback
                        setValue(null);
                    }}
                >
                    &times;
                </button>
            )}
        </div>
    );
}

type TBooleanInput = {
    style?: { [key: string]: any };
    displayFalse?: string;
    displayTrue?: string;
    value?: '' | boolean | null;
    nullable?: boolean;
    field: {
        name: string;
        [key: string]: any;
    };
    falseIsRed?: boolean;
    setValue?: (fieldName?: string, value?: boolean | null) => void | {};
};

/**
 * ! better to use `<BooleanControlled>`. It works more reliably and has a few more features
 */
export default function Boolean({
    field,
    style = {},
    valueStyle = {},
    displayTrue = 'True',
    displayFalse = 'False',
    falseIsRed = false,
    nullable = false,
    value = '',
    setValue = (fieldName, value) => {}
}: TBooleanInput) {
    // to-do: changed to better controlled version

    const [prevValue, setPrevValue] = useState(value);
    const [booleanValue, setBooleanValue] = useState(value);

    if (!nullable && booleanValue !== true && booleanValue !== false) {
        setBooleanValue(false);
        setPrevValue(false);
    }

    useEffect(() => {
        if (value === booleanValue) return;

        setBooleanValue(value);
    }, [value]);

    useEffect(
        () => {
            if (prevValue === booleanValue) return;

            setPrevValue(booleanValue);
            setValue(field.name, typeof booleanValue !== 'boolean' ? null : booleanValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [booleanValue, field.name, setValue]
    );

    return (
        <span style={{ width: '100%' }}>
            <span className='boolean' style={style}>
                <span
                    className={`boolean__value ${falseIsRed && 'boolean__value--red'}`}
                    style={valueStyle}
                    data-value={false}
                    data-selected={booleanValue === false}
                    onClick={(e) => {
                        e.stopPropagation();
                        setBooleanValue(false);
                    }}
                >
                    {displayFalse ?? 'False'}
                </span>
                <span
                    className='boolean__value'
                    style={valueStyle}
                    data-value={true}
                    data-selected={booleanValue === true}
                    onClick={(e) => {
                        e.stopPropagation();
                        setBooleanValue(true);
                    }}
                >
                    {displayTrue ?? 'True'}
                </span>
                {nullable && (
                    <span className='boolean__unset' onClick={() => setBooleanValue('')}>
                        &times;
                    </span>
                )}
                {/* @ts-ignore -> the value isn't assignable but will get parsed correctly anyway */}
                <input {...field} type='hidden' value={booleanValue} />
            </span>
        </span>
    );
}
