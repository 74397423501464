import './Title.scss';

export default function Title({ children, type = 'h2', className = '' }) {
	switch (type) {
		case 'h1':
			return (<h1 className={`heading ${className}`}>{children}</h1>);
		case 'h2':
			return (<h2 className={`heading ${className}`}>{children}</h2>);
		case 'h3':
			return (<h3 className={`heading ${className}`}>{children}</h3>);
		case 'h4':
			return (<h4 className={`heading ${className}`}>{children}</h4>);
		case 'h5':
			return (<h5 className={`heading ${className}`}>{children}</h5>);
		default:
			return (<h2 className={`heading ${className}`}>{children}</h2>);
	}
}