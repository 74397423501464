import {FetchContext} from "App/Strapi/FetchContext";
import {useContext} from "react";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Icon from "UI/App/Components/Icon/Icon";
import {toast} from "react-toastify";
import qs from "qs";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function ExportCompanies({ exportContentType = "companies" }) {
	const { authAxios } = useContext(FetchContext);
	const { width } = useWindowDimensions();

	/**
	 * Handles exporting all companies to csv.
	 * gets all company data
	 * changes company data to right object format.
	 * yes they all need async/awaits...
	*/
	async function exportCompanies(exportContentType, target) {
		// disable the button to prevent spamming while this function is running
		target.disabled = true;

		// initialize empty csv data array
		let csvData = [];

		const query = qs.stringify({
			populate: {
				address: true,
				logo: true,
				telephone: true,
				contactPersons: {
					populate: {
						address: true,
						telephone: true,
						personalCellPhone: true,
						mobilePhone: true,
						notes: {
							populate: {
								content: true,
								createdAt: true,
								user: {
									fields: [
										"username"
									]
								}
							},
							sort: 'createdAt:desc'
						}
					}

				},
				notes: {
					populate: {
						content: true,
						createdAt: true,
						user: {
							fields: [
								"username"
							]
						}
					},
					sort: 'createdAt:desc'
				}
			},
			sort: 'name:asc'
		})

		await authAxios.get(`/crm/${exportContentType}/all?${query}`).then(async ({ data }) => {
			if (!data || (data?.length ?? 0) === 0) {
				toast.info(`Er zijn geen ${exportContentType === "companies" ? "bedrijven" : "leveranciers"} om te exporteren.`);

				// enable the button again
				target.disabled = false;

				// stop the function
				return;
			}
            
			// loop through each company
			for (const company of data) {
				let companyDataRows = [];
				// if company has no contact people, use array with 1 empty object to still add a row for the company.
				const companyContactPeople = (company?.contactPersons?.length ?? 0) !== 0 ? company?.contactPersons : [{}];
				let companyNotes = ""
				company?.notes?.map((entry) => { companyNotes += `${entry.content}\r\n -${entry?.user?.username}\r\n ${new Date(entry.createdAt).toLocaleString()} \r\n\r\n` });
				// loop through each contactperson per company (or the empty object if no contact people)
				for (const contactPerson of companyContactPeople ?? [{}]) {
					let contactPersonNotes = ""
					contactPerson?.notes?.map((entry) => { contactPersonNotes += `${entry.content}\r\n -${entry?.user?.username}\r\n ${new Date(entry.createdAt).toLocaleString()}  \r\n\r\n` });
					// push row per person
					const newRow = {
						[exportContentType === "companies" ? "Bedrijf ID" : "Leverancier ID"]: company?.id ?? '',
						'Bedrijfsnaam': company?.name ?? '',
						'Type bedrijf': company?.type ?? '',
						'Branch': company?.branch ?? '',
						'KVK nummer': company?.kvk ?? '',
						'Adres regel 1': company?.address?.addressLine1 ?? '',
						'Adres regel 2': company?.address?.addressLine2 ?? '',
						'Adres regel 3': company?.address?.addressLine3 ?? '',
						'Postcode': company?.address?.postalCode ?? '',
						'Stad': company?.address?.city ?? '',
						'Provincie': company?.address?.province ?? '',
						'Land': company?.address?.country ?? '',
						'Algemeen e-mailadres': company?.generalEmail ?? '',
						'Facturatie e-mailadres': company?.invoiceEmail ?? '',
						'Telefoonnummer': `${company?.telephone?.dialingCode ? '+' + company?.telephone?.dialingCode + ' ' : ''}${company?.telephone?.telephoneNumber ?? ''}`,
						'Website': company?.website ?? '',
						'Logo': company?.logo?.name ?? '',
						'Gearchiveerd': company?.archived ? 'ja' : 'nee',
						'Contactpersoon ID': contactPerson?.id ?? '',
						'Contactpersoon benadering': contactPerson?.approach ?? '',
						'Contactpersoon geslacht': contactPerson?.gender ?? '',
						'Contactpersoon voorletter': contactPerson?.initials ?? '',
						'Contactpersoon titel': contactPerson?.title ?? '',
						'Contactpersoon voornaam': contactPerson?.firstName ?? '',
						'Contactpersoon tussenvoegsel': contactPerson?.prefixToSurname ?? '',
						'Contactpersoon achternaam': contactPerson?.surname ?? '',
						'Contactpersoon achter titel': contactPerson?.titleAfter ?? '',
						'Contactpersoon functie': contactPerson?.function ?? '',
						'Contactpersoon adres regel 1': contactPerson?.address?.addressLine1 ?? '',
						'Contactpersoon adres regel 2': contactPerson?.address?.addressLine2 ?? '',
						'Contactpersoon adres regel 3': contactPerson?.address?.addressLine3 ?? '',
						'Contactpersoon postcode': contactPerson?.address?.postalCode ?? '',
						'Contactpersoon stad': contactPerson?.address?.city ?? '',
						'Contactpersoon provincie': contactPerson?.address?.province ?? '',
						'Contactpersoon land': contactPerson?.address?.country ?? '',
						'Contactpersoon e-mailadres': contactPerson?.address?.country ?? '',
						'Contactpersoon privé e-mailadres': contactPerson?.address?.country ?? '',
						'Contactpersoon telefoonnummer': `${contactPerson?.telephone?.dialingCode ? '+' + contactPerson?.telephone?.dialingCode + ' ' : ''}${contactPerson?.telephone?.telephoneNumber ?? ''}`,
						'Contactpersoon privé nummer': `${contactPerson?.personalCellPhone?.dialingCode ? '+' + contactPerson?.personalCellPhone?.dialingCode + ' ' : ''}${contactPerson?.personalCellPhone?.telephoneNumber ?? ''}`,
						'Contactpersoon mobiel nummer': `${contactPerson?.mobilePhone?.dialingCode ? '+' + contactPerson?.mobilePhone?.dialingCode + ' ' : ''}${contactPerson?.mobilePhone?.telephoneNumber ?? ''}`,
						'Notities': companyNotes,
						'Contactpersson Notities': contactPersonNotes,
					};

					// delete some fields for suppliers that dont exist
					if (exportContentType === "suppliers") {
						delete newRow['Type bedrijf'];
						delete newRow['Branch'];
					}

					companyDataRows.push(newRow);
				}

				// push companyDataRows to csvData
				csvData.push(...companyDataRows);
			}

			// available options at: https://github.com/alexcaza/export-to-csv#api
            const csvFileOptions = mkConfig({
                filename: `crm_${exportContentType === "companies" ? "bedrijven" : "leveranciers"}_export_${new Date().toLocaleDateString("nl", { year: "numeric", month: "2-digit", day: "2-digit" })}`, // date in DD-MM-YYY
                fieldSeparator: ';',
                decimalSeparator: 'locale',
                useKeysAsHeaders: true,
                showColumnHeaders: true,
                useBom: true,
            });
            
            const csv = generateCsv(csvFileOptions)(csvData);

			// download csv
            download(csvFileOptions)(csv);
		}).catch((error) => {
			toast.error(`${exportContentType === "companies" ? "Bedrijven" : "Leveranciers"} kon niet geÃ«xporteerd worden`);

			process.env.NODE_ENV === 'development' && console.error(error);
		});

		// enable the button again
		target.disabled = false;
	}

	if(width > 1200){
		return (
			<button onClick={({ target }) => exportCompanies(exportContentType, target)} className='btn--black btn btn--icon-right'>
				Exporteren <Icon name='arrow-down' />
			</button>
		)
	}else{
		return (
			<button onClick={({ target }) => exportCompanies(exportContentType, target)} className='btn--black btn btn--icon-right'>
				Exporteren <Icon name='arrow-down' />
			</button>
		)
	}

}
