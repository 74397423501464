import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import Block from 'UI/App/Components/Block/Block';
import Grid from 'UI/App/Components/Grid/Grid';
import Icon from 'UI/App/Components/Icon/Icon';
import { BooleanControlled } from 'UI/App/Components/Form/Boolean';
import { Timelist } from 'UI/App/Components/Form/Datalist';
import Fieldset from 'UI/App/Components/Form/Fieldset';
import Button from 'UI/App/Components/Button/Button';
import Table from 'UI/App/Components/Table/Table';
import Popup, { closePopup, openPopup } from 'UI/App/Components/Popup/Popup';
import HasRole from 'UI/App/Components/Auth/HasRole';
import _User from "App/Strapi/User";
import IF from 'UI/App/Components/Conditional/IF';
import ConfirmationPopup from 'UI/App/Components/Popup/Popups/Util/ConfirmationPopup';

const userQuery = stringify({
    fields: ['username', 'RFIDToken'],
    populate: {
        role: {
            fields: ['id', 'name']
        },
        personal: true,
        business: true,
        contract: true,
        basePlannings: {
            populate: 'weekdays'
        },
        planningMutations: {
            fields: ["*"],
            populate: {
                weekdays: true
            }
        },
        canOperateMachines: {
            fields: ['id', 'name']
        }
    }
});

const machinesQuery = stringify({
    fields: ['id', 'name'],
    sort: ['name:ASC'],
    noCalculate: true
});

export default function User() {
    const { authAxios } = useContext(FetchContext);
    const { userId } = useParams();

    const [roleOptions, setRoleOptions] = useState([]);
    const [machineOptions, setMachineOptions] = useState([]);

    const [role, setRole] = useState(null);
    const [operatorForMachines, setOperatorForMachines] = useState(null);
    const [blockTitle, setBlockTitle] = useState(null);
    const [personal, setPersonal] = useState(null);
    const [RFID, setRFID] = useState({});
    const [business, setBusiness] = useState(null);
    const [contract, setContract] = useState(null);
    const [basePlannings, setBasePlannings] = useState(null);
    const [planningMutations, setPlanningMutations] = useState(null);
    const [mutationTable, setMutationTable] = useState(null)
    const [mutationView, setMutationView] = useState(false);
    const [currentMutatedPlanning, setCurrentMutatedPlanning] = useState(null);
    const [editMutatedPlanning, setEditMutatedPlanning] = useState(null);
    const [popupInformation, setPopupInformation] = useState(null)
    const [basePlanningData, setBasePlanningData] = useState({
        isNew: true,
        startDate: null,
        endDate: null,
        days: {
            monday: {
                label: 'maandag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            tuesday: {
                label: 'dinsdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            wednesday: {
                label: 'woensdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            thursday: {
                label: 'donderdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            friday: {
                label: 'vrijdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            saturday: {
                label: 'zaterdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            }
        }
    });
    const [mutatedPlanningData, setMutatedPlanningData] = useState({
        name: "Verlof periode",
        startDate: null,
        endDate: null,
        days: {
            monday: {
                label: 'maandag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            tuesday: {
                label: 'dinsdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            wednesday: {
                label: 'woensdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            thursday: {
                label: 'donderdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            friday: {
                label: 'vrijdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            },
            saturday: {
                label: 'zaterdag',
                active: false,
                startTime: '08:00',
                endTime: '16:30'
            }
        }
    });

    const getRoleOptions = useCallback(() => {
        // get roles (can't add fields query)
        authAxios
            .get(`../users-permissions/roles`)
            .then(({ data }) => {
                if (!Array.isArray(data?.roles) || isEmpty(data?.roles)) throw new TypeError('No roles');

                // set roles options
                setRoleOptions(() => {
                    let _roles = [];
                    for (const role of data?.roles) {
                        if (role.name.toLowerCase() === 'public') continue;

                        _roles.push({
                            value: role.id,
                            label: role.name
                        });
                    }

                    return _roles;
                });
            })
            .catch((error) => {
                toast.error('Rollen kon niet worden opgehaald');
                console.error(error);
            });
    }, [authAxios]);

    const getMachineOptions = useCallback(() => {
        // get machines
        authAxios
            .get(`/calculations/resources/machines/all?${machinesQuery}`)
            .then(({ data }) => {
                if (!Array.isArray(data) || isEmpty(data)) throw new TypeError('No machines');

                // set machine options
                setMachineOptions(data.map((machine) => ({ value: machine.id, label: machine.name })));
            })
            .catch((error) => {
                toast.error('Rollen kon niet worden opgehaald');
                console.error(error);
            });
    }, [authAxios]);

    const getUserData = useCallback(() => {
        // get user
        authAxios
            .get(`../users/${userId}?${userQuery}`)
            .then(({ data }) => {
                setCurrentMutatedPlanning(undefined)
                // set states
                setRole({ value: data.role.id, label: data.role.name });
                setOperatorForMachines(
                    (data?.canOperateMachines ?? []).map((machine) => ({
                        value: machine.id,
                        label: machine.name
                    }))
                );
                setBlockTitle(data?.username);
                setPersonal(data?.personal ?? {});
                setRFID({
                    token: data?.RFIDToken ?? "",
                    error: undefined,
                });
                setBusiness(data?.business ?? {});
                setContract(data?.contract ?? {});
                setBasePlannings(data?.basePlannings ?? []);
                setPlanningMutations(data?.planningMutations ?? []);

                let relevantMutations = data?.planningMutations?.map((el) => {
                    let end = new Date(el.endDate).getTime()
                    let start = new Date(el.startDate).getTime()
                    let now = new Date().getTime() - 86400000

                    // check if entry is a relevant mutation
                    if (start >= now || now <= end) {
                        if (start <= now && end >= now) {
                            setCurrentMutatedPlanning(el)
                            setMutationView(true)
                        }
                        // return formated table data
                        return {
                            id: el.id,
                            attributes: {
                                onClick: () => {
                                    setCurrentMutatedPlanning(data?.planningMutations?.find((entry) => entry.id === el.id))
                                    setMutationView(true)
                                },
                            },
                            data: [
                                el.name ?? "Onbekend",
                                el.startDate,
                                el.endDate,
                                <Button className={'btn btn--transparent'} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openPopup("deleteMutatedSchedule")
                                    setPopupInformation(el.id)
                                }}><Icon name="trash-can" /></Button>
                            ]
                        }
                    }
                }).filter(Boolean)


                setMutationTable(relevantMutations);
            })
            .catch((error) => {
                toast.error('Gebruiker kon niet worden opgehaald');
                console.error(error);
            });
    }, [authAxios, userId]);

    useEffect(() => {
        getRoleOptions();
    }, [getRoleOptions]);

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    useEffect(() => {
        getMachineOptions();
    }, [getMachineOptions]);

    useEffect(() => {
        if (basePlannings !== null) {
            if (Array.isArray(basePlannings) && basePlannings !== undefined && basePlannings.length > 0) {
                const latestPlanning = basePlannings?.[basePlannings.length - 1];

                setBasePlanningData((basePlanning) => {
                    basePlanning.isNew = false;
                    basePlanning.startDate = latestPlanning.startDate;
                    basePlanning.endDate = latestPlanning.endDate;

                    for (const { day, startTime, endTime } of latestPlanning.weekdays) {
                        basePlanning.days[day].active = true;
                        basePlanning.days[day].startTime = startTime.replace(/^(\d{2}:\d{2}).*/, '$1');
                        basePlanning.days[day].endTime = endTime.replace(/^(\d{2}:\d{2}).*/, '$1');
                    }

                    return { ...basePlanning };
                });
            } else {
                setBasePlanningData((basePlanning) => {
                    basePlanning.startDate = new Date().toLocaleDateString('fr-ca');

                    return { ...basePlanning };
                });
            }
        }
    }, [basePlannings]);

    const updateUserPart = useCallback(
        (e, part, data) => {
            e.preventDefault();

            const postData = part !== undefined ? { [part]: data } : data;

            authAxios
                .put(`../users/${userId}`, postData)
                .then(() => {
                    toast.success('Gebruiker bijgewerkt.');
                })
                .catch((error) => {
                    toast.error('Er ging iets mis met het bijwerken van de gebruiker');

                    const rfidError = error.response.data.error.details.errors.find((err) => err.path[0] === "RFIDToken");
                    setRFID({ token: "", error: rfidError.name === 'ValidationError' && "Opgegeven RFID is all ingebruik." })
                    console.error(error);
                });
        },
        [authAxios, userId]
    );

    const updateUserBasePlanning = useCallback(
        (e) => {
            e.preventDefault();

            const newBasePlanning = {
                startDate: basePlanningData.startDate,
                endDate: basePlanningData.endDate,
                weekdays: Object.entries(basePlanningData.days)
                    .filter(([_, day]) => day.active)
                    .map(([key, day]) => {
                        return {
                            day: key,
                            startTime: `${day.startTime}:00.000`,
                            endTime: `${day.endTime}:00.000`
                        };
                    })
            };

            let newBasePlannings = basePlannings;
            if (basePlanningData.isNew) {
                newBasePlannings.push(newBasePlanning);
            } else {
                try {
                    newBasePlannings = newBasePlannings.with(-1, newBasePlanning);
                } catch (error) {
                    if (error instanceof RangeError) {
                        newBasePlannings.push(newBasePlanning);
                    }
                }
            }

            setBasePlannings(newBasePlannings);

            updateUserPart(e, 'basePlannings', newBasePlannings);
        },
        [
            basePlanningData.days,
            basePlanningData.endDate,
            basePlanningData.isNew,
            basePlanningData.startDate,
            basePlannings,
            updateUserPart
        ]
    );
    const updateMutatedBasePlanning = useCallback((e) => {
        e.preventDefault();

        // edit object is diffrent from create
        if (e?.target?.id === "edit") {

            let putData = {
                ...editMutatedPlanning,
                weekdays: Object.entries(editMutatedPlanning.weekdays).map(([key, value]) => {
                    return {
                        day: value.day,
                        present: value?.present ?? false,
                        startTime: `${value.startTime}`,
                        endTime: `${value.endTime}`,
                    }
                })
            }

            authAxios.put(`/employees/planning/${editMutatedPlanning.id}`, { data: putData }).then((data) => {
                toast.success('Aangepaste planning bewerkt.');
                getUserData();
                closePopup();
            }).catch((error) => {
                toast.error("Er ging iets mis met het bewerken van de aangepaste planning")
                console.error(error);
            })
        } else {
            const postData = {
                name: mutatedPlanningData?.name ?? "verlof",
                startDate: mutatedPlanningData?.startDate,
                endDate: mutatedPlanningData?.endDate,
                weekdays: Object.entries(mutatedPlanningData?.days).map(([key, value]) => {
                    return {
                        day: key,
                        present: value?.active ?? false,
                        startTime: `${value.startTime}:00.000`,
                        endTime: `${value.endTime}:00.000`,
                    }
                }),
                user: userId
            }
            authAxios.post('/employees/planning', { data: postData }).then((data) => {
                toast.success('Aangepaste planning aangemaakt.');
                getUserData();
                closePopup();
            }).catch((error) => {
                toast.error("Er ging iets mis met het aanmaken van de aangepaste planning")
                console.error(error);
            })
        }
    }, [mutatedPlanningData, editMutatedPlanning, authAxios, userId])

    function deleteMutatedPlanning(id) {

        authAxios.delete(`/employees/planning/${id}`).then((data) => {
            toast.success("Icidenteel rooster verwijderd!")
            getUserData();
        }).catch((error) => {
            toast.error("Er ging iets mis met verwijderen van dit rooster")
            console.error(error);
        })
    }
    return (
        <Grid columns='1'>
            <Block name='inputs' title={blockTitle}>
                <form
                    onSubmit={(e) =>
                        updateUserPart(e, undefined, {
                            role: role.value,
                            canOperateMachines: operatorForMachines.map((machine) => machine.value),
                            RFIDToken: RFID.token
                        })
                    }
                >
                    <div className='input-group'>
                        <label htmlFor='role'>Rol</label>
                        <Select
                            name='role'
                            id='role'
                            isClearable={false}
                            options={roleOptions}
                            value={role}
                            onChange={(role) => {
                                setRole(role);
                            }}
                            required
                        />
                    </div>

                    <div className='input-group'>
                        <label htmlFor='canOperateMachines'>Kan machines bedienen:</label>
                        <Select
                            name='canOperateMachines'
                            id='canOperateMachines'
                            placeholder='Selecteer de machines die deze werknemer kan bedienen'
                            isMulti
                            options={machineOptions}
                            value={operatorForMachines}
                            onChange={(machines) => {
                                setOperatorForMachines(machines);
                            }}
                        />
                    </div>
                    <div className='input-group'>
                        <label htmlFor='rfid'>RFID Token:</label><br />
                        <div style={{ color: "var(--text-error)" }}>{RFID.error}</div>
                        <input
                            type='text'
                            id='rfid'
                            value={RFID.token ?? ''}
                            onChange={({ target }) => {
                                setRFID({ token: target.value, error: undefined });
                            }}
                            required
                        />
                    </div>

                    <div className='input-group'>
                        <button type='submit'>
                            Opslaan <Icon name='save' />
                        </button>
                    </div>
                </form>
            </Block>

            <Grid
                columns={{
                    1000: 1,
                    default: 2
                }}
                customColTemplate='repeat(auto-fit, minmax(400px, 1fr)'
            >
                <Block name='personal' title='Persoonsgegevens'>
                    <form onSubmit={(e) => updateUserPart(e, 'personal', personal)}>
                        <div className='input-group'>
                            <label htmlFor='personalGender'>Geslacht</label>
                            <select
                                id='personalGender'
                                value={personal?.gender ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        gender: target.value
                                    }));
                                }}
                                required
                            >
                                <option value='man'>Man</option>
                                <option value='vrouw'>Vrouw</option>
                                <option value='anders'>Anders</option>
                            </select>
                        </div>

                        <div className='input-group'>
                            <label htmlFor='personalFirstName'>Voornaam</label>
                            <input
                                type='text'
                                id='personalFirstName'
                                value={personal?.firstName ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        firstName: target.value
                                    }));
                                }}
                                required
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='personalLastName'>Achternaam</label>
                            <input
                                type='text'
                                id='personalLastName'
                                value={personal?.lastName ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        lastName: target.value
                                    }));
                                }}
                                required
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='personalPrivateEmailAddress'>Privé emailadres</label>
                            <input
                                type='email'
                                id='personalPrivateEmailAddress'
                                value={personal?.privateEmailAddress ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        privateEmailAddress: target.value
                                    }));
                                }}
                                required
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='PersonalprivatePhoneNumber'>Privé telefoonnummer</label>
                            <input
                                type='tel'
                                id='PersonalprivatePhoneNumber'
                                value={personal?.privatePhoneNumber ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        privatePhoneNumber: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='personalBbsn'>BSN</label>
                            <input
                                type='number'
                                id='personalBbsn'
                                value={personal?.bsnNumber ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        bsnNumber: String(target.value)
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='personalMaritalStatus'>Burgerlijke staat</label>
                            <select
                                id='personalMaritalStatus'
                                value={personal?.maritalStatus ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        maritalStatus: target.value
                                    }));
                                }}
                            >
                                <option value='ongehuwd'>Ongehuwd</option>
                                <option value='gehuwd'>Gehuwd</option>
                                <option value='gescheiden'>Gescheiden</option>
                                <option value='verweduwd'>Verweduwd</option>
                                <option value='geregistreerd partnerschap'>Geregistreerd partnerschap</option>
                            </select>
                        </div>

                        <div className='input-group'>
                            <label htmlFor='personalDateOfBirth'>Geboortedatum</label>
                            <input
                                type='date'
                                id='personalDateOfBirth'
                                value={personal?.birthDate ?? ''}
                                onChange={({ target }) => {
                                    setPersonal((personal) => ({
                                        ...personal,
                                        birthDate: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <button type='submit'>
                                Opslaan <Icon name='save' />
                            </button>
                        </div>
                    </form>
                </Block>

                <Block name='business' title='Zakelijk'>
                    <form onSubmit={(e) => updateUserPart(e, 'business', business)}>
                        <div className='input-group'>
                            <label htmlFor='businessFunction'>Functie</label>
                            <input
                                type='text'
                                id='businessFunction'
                                value={business?.function ?? ''}
                                onChange={({ target }) => {
                                    setBusiness((business) => ({
                                        ...business,
                                        function: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='businessEmail'>Zakelijk E-mail</label>
                            <input
                                type='email'
                                id='businessEmail'
                                value={business?.businessEmail ?? ''}
                                onChange={({ target }) => {
                                    setBusiness((business) => ({
                                        ...business,
                                        businessEmail: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='businessPhoneNumber'>Zakelijk telefoonnummer</label>
                            <input
                                type='tel'
                                id='businessPhoneNumber'
                                value={business?.businessPhoneNumber ?? ''}
                                onChange={({ target }) => {
                                    setBusiness((business) => ({
                                        ...business,
                                        businessPhoneNumber: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='businessInternNumber'>Intern nummer</label>
                            <input
                                type='text'
                                name='businessInternNumber'
                                value={business?.internNumber ?? ''}
                                onChange={({ target }) => {
                                    setBusiness((business) => ({
                                        ...business,
                                        internNumber: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='businessTarget'>Target</label>
                            <input
                                type='text'
                                id='businessTarget'
                                value={business?.target ?? ''}
                                onChange={({ target }) => {
                                    setBusiness((business) => ({
                                        ...business,
                                        target: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <button type='submit'>
                                Opslaan <Icon name='save' />
                            </button>
                        </div>
                    </form>
                </Block>

                <Block name='contract' title='Contract'>
                    <form onSubmit={(e) => updateUserPart(e, 'contract', contract)}>
                        <div className='input-group'>
                            <label htmlFor='contractEmployment'>Dienstberekkening</label>
                            <input
                                type='number'
                                id='contractEmployment'
                                value={contract?.employment ?? ''}
                                onChange={({ target }) => {
                                    setContract((contract) => ({
                                        ...contract,
                                        employment: parseInt(target.value)
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='contractType'>Type contract</label>
                            <select
                                id='contractType'
                                value={contract?.contractType ?? ''}
                                onChange={({ target }) => {
                                    setContract((contract) => ({
                                        ...contract,
                                        contractType: target.value
                                    }));
                                }}
                            >
                                <option>Bepaalde tijd</option>
                                <option>Onbepaalde tijd</option>
                            </select>
                        </div>

                        <div className='input-group'>
                            <label htmlFor='contractStartDate'>Start datum</label>
                            <input
                                type='date'
                                id='contractStartDate'
                                value={contract?.startDate ?? ''}
                                onChange={({ target }) => {
                                    setContract((contract) => ({
                                        ...contract,
                                        startDate: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='contractEndDate'>Eind datum</label>
                            <input
                                type='date'
                                id='contractEndDate'
                                value={contract?.endDate ?? ''}
                                onChange={({ target }) => {
                                    setContract((contract) => ({
                                        ...contract,
                                        endDate: target.value
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='contractCommercialHourlyRate'>Commercieel uurloon</label>
                            <input
                                type='number'
                                id='contractCommercialHourlyRate'
                                value={contract?.commercialHourlyRate ?? ''}
                                onChange={({ target }) => {
                                    setContract((contract) => ({
                                        ...contract,
                                        commercialHourlyRate: Number(target.value)
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <label htmlFor='contractVacationHours'>Vakantie uren</label>
                            <input
                                type='number'
                                id='contractVacationHours'
                                value={contract?.vacationHours ?? ''}
                                onChange={({ target }) => {
                                    setContract((contract) => ({
                                        ...contract,
                                        vacationHours: Number(target.value)
                                    }));
                                }}
                            />
                        </div>

                        <div className='input-group'>
                            <button type='submit'>
                                Opslaan <Icon name='save' />
                            </button>
                        </div>
                    </form>
                </Block>



            </Grid>
            
            <Grid columns={{
                1000: 1,
                default: 2
            }}
                customColTemplate='repeat(auto-fit, minmax(400px, 1fr)'>
                <Block name='rooster' title='Standaard Rooster'
                    headerRightSideChildren={
                        mutationView === true && (
                            <>
                                <Button onClick={() => setMutationView(false)}>Standaard rooster</Button>
                                
                                <Button
                                    style={{ float: 'right' }}
                                    onClick={() => {
                                        setEditMutatedPlanning(currentMutatedPlanning);
                                        openPopup('editIcidentSchedule');
                                    }}
                                >
                                    Rooster aanpassen
                                </Button>
                            </>
                        )
                    }
                >
                    <form onSubmit={(e) => updateUserBasePlanning(e)}>
                        <Grid columns='1' gap='var(--gap)'>
                            {/* add data selection list for an option every 15 min */}
                            {/* <Timelist id='timeRangeList' includeMinutes={[0, 15, 30, 45]} /> */}
                            <IF condition={!mutationView}>
                                {Object.entries(basePlanningData.days).map(([key, day]) => {


                                    return (
                                        <Fieldset
                                            key={key}
                                            label={
                                                <label>
                                                    <HasRole roles={['admin']}>
                                                        <input
                                                            type='checkbox'
                                                            id={key}
                                                            checked={day?.active}
                                                            onChange={({ target }) => {
                                                                setBasePlanningData((basePlanning) => {
                                                                    basePlanning.days[key].active = target.checked;

                                                                    return { ...basePlanning };
                                                                });
                                                            }}
                                                        />
                                                    </HasRole>
                                                    {day.label}
                                                </label>
                                            }
                                        >
                                            <Grid gap='10px' columns='2'>
                                                <div className='input-group'>
                                                    <label htmlFor={`${key}StartTime`} className='required'>
                                                        Start tijd
                                                    </label>
                                                    <input
                                                        type='time'
                                                        id={`${key}StartTime`}
                                                        value={day?.startTime ?? ''}
                                                        step={60 * 15}
                                                        pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                        // list='timeRangeList'
                                                        onChange={({ target }) => {
                                                            setBasePlanningData((basePlanning) => {
                                                                basePlanning.days[key].startTime = target.value;

                                                                return { ...basePlanning };
                                                            });
                                                        }}
                                                        disabled={(!basePlanningData?.days[key]?.active || !_User.hasRole(['admin']))}
                                                        required={basePlanningData?.days[key]?.active}
                                                    />
                                                </div>

                                                <div className='input-group'>
                                                    <label htmlFor={`${key}EndTime`} className='required'>
                                                        Eind tijd
                                                    </label>
                                                    <input
                                                        type='time'
                                                        id={`${key}EndTime`}
                                                        value={day?.endTime ?? ''}
                                                        step={60 * 15}
                                                        pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                        // list='timeRangeList'
                                                        onChange={({ target }) => {
                                                            setBasePlanningData((basePlanning) => {
                                                                basePlanning.days[key].endTime = target.value;

                                                                return { ...basePlanning };
                                                            });
                                                        }}
                                                        disabled={(!basePlanningData?.days[key]?.active || !_User.hasRole(['admin']))}
                                                        required={basePlanningData?.days[key]?.active}
                                                    />
                                                </div>
                                            </Grid>
                                        </Fieldset>
                                    );
                                })}
                            </IF>
                            <IF condition={mutationView}>

                                <div className='input-group'>
                                    <label>Reden voor aangepast rooster: <b style={{ color: "var(--primary)" }}>{currentMutatedPlanning?.name ?? "onbekend"}</b></label>
                                </div>
                                {Object.entries(currentMutatedPlanning?.weekdays ?? []).map(([key, day]) => {

                                    let currentDay = currentMutatedPlanning?.weekdays[key]?.day
                                    return (
                                        <Fieldset
                                            key={key}
                                            label={
                                                <label>

                                                    {mutatedPlanningData.days[currentDay].label}
                                                </label>
                                            }
                                        >
                                            {(currentMutatedPlanning?.weekdays[key].present ?? false) ?
                                                <Grid gap='10px' columns='2'>
                                                    <div className='input-group'>
                                                        <label htmlFor={`${key}StartTime`} >
                                                            Start tijd
                                                        </label>
                                                        <input
                                                            type='time'
                                                            id={`${key}StartTime`}
                                                            value={day?.startTime ?? ''}
                                                            step={60 * 15}
                                                            pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                            // list='timeRangeList'

                                                            disabled={true}
                                                        //required={basePlanningData?.days[key]?.active}
                                                        />
                                                    </div>

                                                    <div className='input-group'>
                                                        <label htmlFor={`${key}EndTime`} >
                                                            Eind tijd
                                                        </label>
                                                        <input
                                                            type='time'
                                                            id={`${key}EndTime`}
                                                            value={day?.endTime ?? ''}
                                                            step={60 * 15}
                                                            pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                            // list='timeRangeList'

                                                            disabled={true}
                                                        //required={basePlanningData?.days[key]?.active}
                                                        />
                                                    </div>
                                                </Grid>
                                                :
                                                <>Verlof</>
                                            }

                                        </Fieldset>
                                    );
                                })}
                            </IF>
                        </Grid>

                        {mutationView === false &&
                        <div className='input-group'>
                            <button type='submit' disabled={mutationView}>
                                Opslaan <Icon name='save' />
                            </button>
                        </div>
                        }
                    </form>
                </Block>
                
                <Block
                    name='incidentSchedule'
                    title='Incidenteel Rooster'
                    headerRightSideChildren={
                        <Button onClick={(e) => { openPopup("addIcidentSchedule") }}>
                            <Icon name="plus" />
                        </Button>
                    }
                >
                    <Table
                        structure={{
                            default: ["24%", "24%", "24%", "15%"]
                        }}
                        headers={[
                            {
                                value: 'Naam',
                                attributes: {
                                    style: {
                                        background: "white",
                                        color: "black",
                                        margin: '0px',
                                    }
                                }
                            },
                            {
                                value: 'Start datum',
                                attributes: {
                                    style: {
                                        background: "white",
                                        color: "black",
                                        margin: '0px',
                                    }
                                }
                            },
                            {
                                value: 'Eind datum',
                                attributes: {
                                    style: {
                                        background: "white",
                                        color: "black",
                                        margin: '0px',
                                    }
                                }
                            },
                            {
                                value: '',
                                attributes: {
                                    style: {
                                        background: "white",
                                        color: "black",
                                        margin: '0px',
                                    }
                                }
                            }
                        ]}
                        data={mutationTable ?? []}
                    />
                </Block>

                <Popup popupID={"addIcidentSchedule"} title='Rooster toevoegen'>
                    <form onSubmit={(e) => updateMutatedBasePlanning(e)}>
                        <div className='input-group'>
                            <label className="required" htmlFor='startDate'>Start datum:</label><br />
                            <input
                                type='date'
                                id='startDate'
                                value={mutatedPlanningData.startDate ?? ''}
                                onChange={({ target }) => {
                                    setMutatedPlanningData(prev => ({
                                        ...prev,
                                        startDate: target.value,
                                    }))
                                }}
                                required
                            />
                        </div>
                        <div className='input-group'>
                            <label className="required" htmlFor='startDate'>Eind datum:</label><br />
                            <input
                                type='date'
                                id='endDate'
                                value={mutatedPlanningData?.endDate ?? ''}
                                onChange={({ target }) => {
                                    setMutatedPlanningData(prev => ({
                                        ...prev,
                                        endDate: target.value,
                                    }))
                                }}
                                required
                            />
                        </div>

                        <div className='input-group'>
                            <label className="required" htmlFor='reason'>Naam:</label><br />
                            <input
                                type='text'
                                id='reason'
                                value={mutatedPlanningData?.name ?? ''}
                                onChange={({ target }) => {
                                    setMutatedPlanningData(prev => ({
                                        ...prev,
                                        name: target.value,
                                    }))
                                }}
                                required
                            />
                        </div>
                        <Block name='rooster' title='Afwijkend Rooster'>
                            <Grid columns='1' gap='var(--gap)'>
                                {/* add data selection list for an option every 15 min */}
                                {/* <Timelist id='timeRangeList' includeMinutes={[0, 15, 30, 45]} /> */}

                                {Object.entries(mutatedPlanningData.days).map(([key, day]) => {
                                    return (
                                        <Fieldset
                                            key={key}
                                            label={
                                                <label>
                                                    <input
                                                        type='checkbox'
                                                        id={`mutation_${key}`}
                                                        checked={day?.active}
                                                        onChange={({ target }) => {
                                                            setMutatedPlanningData((basePlanning) => {
                                                                basePlanning.days[key].active = target.checked;

                                                                return { ...basePlanning };
                                                            });
                                                        }}
                                                    />
                                                    {day.label}
                                                </label>
                                            }
                                        >
                                            {!day?.active ?
                                                <>verlof</>
                                                :
                                                <Grid gap='10px' columns='2'>
                                                    <div className='input-group'>
                                                        <label htmlFor={`${key}StartTime`} className='required'>
                                                            Start tijd
                                                        </label>
                                                        <input
                                                            type='time'
                                                            id={`${key}StartTime`}
                                                            value={day?.startTime ?? ''}
                                                            step={60 * 15}
                                                            pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                            // list='timeRangeList'
                                                            onChange={({ target }) => {
                                                                setMutatedPlanningData((basePlanning) => {
                                                                    basePlanning.days[key].startTime = target.value;
                                                                    basePlanning.days[key].active = day?.active;
                                                                    return { ...basePlanning };
                                                                });
                                                            }}
                                                            onClick={({ target }) => {
                                                                // onFocus has error on requiring user gesture on browser window auto focus
                                                                // e.g. from closing 1 window and your OS auto focusing the browser after

                                                                // open picker on full input click
                                                                if (
                                                                    !target.disabled &&
                                                                    document.activeElement.id === target.id
                                                                ) {
                                                                    target.showPicker();
                                                                }
                                                            }}
                                                            disabled={!mutatedPlanningData?.days[key]?.active}
                                                            required={mutatedPlanningData?.days[key]?.active}
                                                        />
                                                    </div>

                                                    <div className='input-group'>
                                                        <label htmlFor={`${key}EndTime`} className='required'>
                                                            Eind tijd
                                                        </label>
                                                        <input
                                                            type='time'
                                                            id={`${key}EndTime`}
                                                            value={day?.endTime ?? ''}
                                                            step={60 * 15}
                                                            pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                            // list='timeRangeList'
                                                            onChange={({ target }) => {
                                                                setMutatedPlanningData((basePlanning) => {
                                                                    basePlanning.days[key].endTime = target.value;
                                                                    basePlanning.days[key].active = day?.active;
                                                                    return { ...basePlanning };
                                                                });
                                                            }}
                                                            onClick={({ target }) => {
                                                                // onFocus has error on requiring user gesture on browser window auto focus
                                                                // e.g. from closing 1 window and your OS auto focusing the browser after

                                                                // open picker on full input click
                                                                if (
                                                                    !target.disabled &&
                                                                    document.activeElement.id === target.id
                                                                ) {
                                                                    target.showPicker();
                                                                }
                                                            }}
                                                            disabled={!mutatedPlanningData?.days[key]?.active}
                                                            required={mutatedPlanningData?.days[key]?.active}
                                                        />
                                                    </div>
                                                </Grid>
                                            }
                                        </Fieldset>
                                    );
                                })}
                            </Grid>



                        </Block>

                        <div className='input-group'>
                            <button type='submit'>
                                Afwijkend rooster opslaan <Icon name='save' />
                            </button>
                        </div>
                    </form>

                </Popup>

                <Popup popupID={"editIcidentSchedule"} title='Rooster aanpassen'>
                    <form id="edit" onSubmit={(e) => updateMutatedBasePlanning(e)}>
                        <div className='input-group'>
                            <label className="required" htmlFor='startDate'>Start datum:</label><br />
                            <input
                                type='date'
                                id='startDate'
                                value={editMutatedPlanning?.startDate ?? ''}
                                onChange={({ target }) => {
                                    setEditMutatedPlanning(prev => ({
                                        ...prev,
                                        startDate: target.value,
                                    }))
                                }}
                                required
                            />
                        </div>
                        <div className='input-group'>
                            <label className="required" htmlFor='startDate'>Eind datum:</label><br />
                            <input
                                type='date'
                                id='endDate'
                                value={editMutatedPlanning?.endDate ?? ''}
                                onChange={({ target }) => {
                                    setEditMutatedPlanning(prev => ({
                                        ...prev,
                                        endDate: target.value,
                                    }))
                                }}
                                required
                            />
                        </div>

                        <div className='input-group'>
                            <label className="required" htmlFor='reason'>Naam:</label><br />
                            <input
                                type='text'
                                id='reason'
                                value={editMutatedPlanning?.name ?? ''}
                                onChange={({ target }) => {
                                    setEditMutatedPlanning(prev => ({
                                        ...prev,
                                        name: target.value,
                                    }))
                                }}
                                required
                            />
                        </div>
                        <Block name='rooster' title='Afwijkend Rooster'>
                            <Grid columns='1' gap='var(--gap)'>
                                {/* add data selection list for an option every 15 min */}
                                {/* <Timelist id='timeRangeList' includeMinutes={[0, 15, 30, 45]} /> */}

                                {Object.entries(editMutatedPlanning?.weekdays ?? []).map(([key, day]) => {

                                    let editDay = mutatedPlanningData.days[day.day]
                                    return (
                                        <Fieldset
                                            key={key}
                                            label={
                                                <label>
                                                    <input
                                                        type='checkbox'
                                                        id={`mutation_${key}`}
                                                        checked={day?.present}
                                                        onChange={({ target }) => {
                                                            setEditMutatedPlanning((basePlanning) => {
                                                                editMutatedPlanning.weekdays[key].present = target.checked;

                                                                return { ...basePlanning };
                                                            });
                                                        }}
                                                    />
                                                    {editDay.label}
                                                </label>
                                            }
                                        >
                                            {!day?.present ?
                                                <>verlof</>
                                                :
                                                <Grid gap='10px' columns='2'>
                                                    <div className='input-group'>
                                                        <label htmlFor={`${key}StartTime`} className='required'>
                                                            Start tijd
                                                        </label>
                                                        <input
                                                            type='time'
                                                            id={`${key}StartTime`}
                                                            value={day?.startTime ?? ''}
                                                            step={60 * 15}
                                                            pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                            // list='timeRangeList'
                                                            onChange={({ target }) => {
                                                                setEditMutatedPlanning((basePlanning) => {
                                                                    editMutatedPlanning.weekdays[key].startTime = `${target.value}:00.000`;
                                                                    editMutatedPlanning.weekdays[key].active = day?.active;
                                                                    return { ...basePlanning };
                                                                });
                                                            }}
                                                            onClick={({ target }) => {
                                                                // onFocus has error on requiring user gesture on browser window auto focus
                                                                // e.g. from closing 1 window and your OS auto focusing the browser after

                                                                // open picker on full input click
                                                                if (
                                                                    !target.disabled &&
                                                                    document.activeElement.id === target.id
                                                                ) {
                                                                    target.showPicker();
                                                                }
                                                            }}
                                                            disabled={!day?.present}
                                                            required={day?.present}
                                                        />
                                                    </div>

                                                    <div className='input-group'>
                                                        <label htmlFor={`${key}EndTime`} className='required'>
                                                            Eind tijd
                                                        </label>
                                                        <input
                                                            type='time'
                                                            id={`${key}EndTime`}
                                                            value={day?.endTime ?? ''}
                                                            step={60 * 15}
                                                            pattern='[0-9]{2}:[0-9]{2}' // pattern for browser text fallback
                                                            // list='timeRangeList'
                                                            onChange={({ target }) => {
                                                                setEditMutatedPlanning((basePlanning) => {
                                                                    editMutatedPlanning.weekdays[key].endTime = `${target.value}:00.000`;
                                                                    editMutatedPlanning.weekdays[key].active = day?.active;
                                                                    return { ...basePlanning };
                                                                });
                                                            }}
                                                            onClick={({ target }) => {
                                                                // onFocus has error on requiring user gesture on browser window auto focus
                                                                // e.g. from closing 1 window and your OS auto focusing the browser after

                                                                // open picker on full input click
                                                                if (
                                                                    !target.disabled &&
                                                                    document.activeElement.id === target.id
                                                                ) {
                                                                    target.showPicker();
                                                                }
                                                            }}
                                                            disabled={!day?.present}
                                                            required={day?.present}
                                                        />
                                                    </div>
                                                </Grid>
                                            }
                                        </Fieldset>
                                    );
                                })}
                            </Grid>



                        </Block>

                        <div className='input-group'>
                            <button type='submit'>
                                Afwijkend rooster aanpassen <Icon name='save' />
                            </button>
                        </div>
                    </form>
                </Popup>
                
                <ConfirmationPopup
                    popupID={"deleteMutatedSchedule"}
                    title={"rooster verwijderen"}
                    description={"Weet je zeeker dat je dit rooster wil verwijderen?"}
                    functionOnConfirm={() => deleteMutatedPlanning(popupInformation)}
                />
            </Grid>
        </Grid>
    );
}
