import {useContext, useEffect, useRef, useState} from "react";
import {FetchContext} from "../../../../../../../../../../App/Strapi/FetchContext";
import Block from "../../../../../../../../Components/Block/Block";
import Icon from "../../../../../../../../Components/Icon/Icon";
import Button from "../../../../../../../../Components/Button/Button";
import CompanyPersonInfoMessage from "../../../Blocks/CompanyMessages/CompanySingleMessage";
import PopupAddOrEditMemo from "../../../../../../../../Components/Popup/Popups/AddOrEditMemo";
import PopupEditCompanyMessage from "../../../../../../../../Components/Popup/Popups/EditCompanyMessage";
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../../../../../../../../../../App/Strapi/AuthProvider";
import Popup from "../../../../../../../../Components/Popup/Popup";
import './companyPerson.scss';
import qs from "qs";
import PDF from "../../../../../../../../../../App/Util/print";
import {ConfigContext} from "../../../../../../../../../../App/Strapi/ConfigContext";
import useWindowDimensions from "App/Util/useWindowDimensions";

export default function CompanyPersonInfo({ personId, person }) {
    const [memoFormData, setMemoFormData] = useState({});
	const [editFormData, setEditFormData] = useState({});
	const [notes, setNotes] = useState([]);
	const { authAxios } = useContext(FetchContext);
	const { authState } = useContext(AuthContext);
	const [file, setFile] = useState(null);
	const [imgUrl, setImgUrl] = useState("");
	const [tagElements, setTagElements] = useState([]);
	const [tagData, setTagData] = useState(false);
	const [tagState, setTageState] = useState([]);
	const [messageText, setMessageText] = useState("");
	const [collapseTags, setCollapseTags] = useState(true);
	const { appConfig } = useContext(ConfigContext);

	const { width } = useWindowDimensions();

	const location = useLocation();
	const isSupplier = /\/suppliers\//.test(location.pathname);

	// const handleFileChange = event => {
	//     setFile(event.target.files[0]);
	// };

	// check if on suppliers path
	/**When a new note is added the pages informatie tab is expanded with the new note */
	useEffect(() => {
		getTags();
		getNotes();
	}, []);

	useEffect(() => {
		createTagElements();
	}, [tagData, tagState]);

	const getNotes = () => {
		const params = qs.stringify({
			filters: {
				contactPerson: personId,
			},
			populate: [
				'attachment',
				'tags',
				'user',
			],
			sort: 'createdAt:DESC',
		}, { encode: false });

		authAxios.get(`/crm/notes?populate=user&${params}`).then(({data: {data}}) => {
			setNotes(data);
		});
	}

	function updateText(e) {
		setMessageText(e.target.value);
	}

	function getTags() {
		authAxios.get('/crm/notes/tags',).then(({ data }) => {
			setTagData(data);
		}).catch((exception) => {
			toast.error("Kon tags niet ophalen");
			console.log(exception);
		});
	}

	function updateTag(e) {
		console.log("tag updated");
		// store the state of the checkbox on ID of the tag
		let tagArray = tagState;
		tagArray[e.target.value] = e.target.checked;
		setTageState(tagArray);
		createTagElements();
	}

	function createTagElements() {
		// return if no data
		if (tagData === false) {
			return;
		}
		let tagArray = [];

		// create an input for the tag with state
		for (const tag of tagData.data) {

			tagArray.push(<li key={tag.id}><input checked={(tagState[tag.id] === undefined) ? false : tagState[tag.id]} type={"checkbox"} name={"tags"} onChange={updateTag} value={tag.id} id={"tag-" + tag.id} /><label htmlFor={"tag-" + tag.id} style={{ backgroundColor: tag.backgroundColor, color: tag.color }}>{tag.name}</label></li >)
		}
		setTagElements(tagArray);
	}

	// Fileupload popup
	const ref = useRef()
	const handleClick = (e) => {
		ref.current.click()
	}

	const handleFileChange = event => {
		setFile(event.target.files[0]);
	};

	const handleSubmit = async event => {
		uploadFile();
		event.preventDefault();
	};

	function createMessage(text = '') {
		// stop if to text value
		if (messageText === "") {
			return;
		}

		let chosenTags = [];
		for (let key of Object.keys(tagState)) {
			// if checked store tag ID in array
			if (tagState[key]) {
				chosenTags.push(key);
			}
		}
		setTageState([]);

		// post the messsage
		authAxios.post('/crm/notes', {
			data: {
				'content': text,
				'company': !isSupplier ? person.company.id : undefined,
				'supplier': isSupplier ? person.supplier.id : undefined,
				'user': authState?.user?.id,
				'contactPerson': personId,
				'tags': chosenTags
			},
		}).then(({ data: { data } }) => {
			toast.success('Bericht toegevoegd');

			// empty the textarea
			document.getElementById('newCompanyMessage').value = null

			// add new message to company & re-render the company notes
			getNotes()
		}).catch((exception) => {
			toast.error('Er is iets mis gegaan bij het aanmaken van het bericht!', {
				autoClose: 2500,
			});
		});
	}
	function uploadFile() {
		if (!file) {
			return;
		}

		try {
			let formData = new FormData();
			formData.append("files", file);

			const pendingUpload = toast.loading("Bestand uploaden...");

			authAxios.post("../upload", formData)
				.then(({data}) => {

					toast.update(pendingUpload, {
						render: "Bericht aanmaken...",
						type: "success",
						isLoading: true,
					});

					const fileId = data[0].id;
					authAxios.post('/crm/notes', {
						'data': {
							'content': file.name,
							'company': !isSupplier ? person.company.id : undefined,
							'supplier': isSupplier ? person.company.id : undefined,
							'user': authState?.user?.id,
							'attachment': fileId,
							'contactPerson': personId
						},
					}).then(({ data: { data } }) => {
						toast.update(pendingUpload, {
							render: "Bestand geupload.",
							type: "success",
							isLoading: false,
							autoClose: 2500
						});
						// empty the file area
						document.getElementById('fileUpload').value = null;

						//empty setfile const to free up space for new uploads
						setFile(null);
						getNotes();

					}).catch((exception) => {
						toast.update(pendingUpload, {
							render: "Er is een probleem opgetreden bij het aanmaken van het bericht!",
							type: "error",
							isLoading: false,
							autoClose: 4000
						});
					});
				})
				.catch((error) => {
					if (error?.response?.status === 413) {
						toast.update(pendingUpload, {
							render: "Bestand is te groot om te uploaden. (Max 32MB)",
							type: "error",
							isLoading: false,
							autoClose: 4000
						});
					}else{
						toast.update(pendingUpload, {
							render: "Er is een probleem opgetreden bij het uploaden van de bijlage!",
							type: "error",
							isLoading: false,
							autoClose: 4000
						});
					}
				})
		} catch (error) {
			console.log(error);
		}
	}

	/**
	 * Creates a temporary pdf with the company's info messages for printing
	 */
	async function createMessagesPDF() {
		// abort if there are no messages
		if ((notes ?? 0) === 0) {
			toast.info('Er zijn geen berichten om te printen');
			return;
		}

		const pdf = new PDF(`${person?.company?.name ?? ''}\nachtergrond informatie`, appConfig?.logo);

		// add table for each message
		for (const message of notes ?? []) {
			const username = message?.user?.username ?? "Onbekende gebruiker";
			const datePosted = new Date(message?.createdAt)
				.toLocaleString('nl-NL',
					{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
				);

			const tags = [];
			for (const tag of message?.tags ?? []) {

				const tagColor = tag.color.startsWith('rgb') ? tag.color.match(/\d{1,3}/g).map((x) => parseInt(x)) : tag.color;
				tags.push({
					content: tag.name,
					styles: {
						textColor: tagColor,
						overflow: 'ellipsize',
						// overflow: 'visible',
						lineWidth: { bottom: 0, top: 0 },
						fontSize: 9,
					},
				});
			}

			const body = tags.length > 0 ? [
				[
					{
						content: '', // empty string otherwise `[object] [object]` is shown.
						colSpan: 2,
						styles: { lineWidth: { bottom: 0, top: 0 } }
					}
				],
				[
					{
						content: message?.content,
						colSpan: 2,
						styles: { lineWidth: { bottom: 0, top: 0 } }
					}
				]
			] : [
				[
					{
						content: message?.content,
						colSpan: 2,
						styles: { lineWidth: { bottom: 0, top: 0 } }
					}
				]
			];

			const didDrawCell = tags.length > 0 ? (data) => {
				if (data.row.index === 0 && data.row.section === 'body') {
					pdf.autoTable({
						startY: data.cell.y,
						tableWidth: 'wrap',
						// tableWidth: pdf.getPageWidth() - 28 - 3,
						body: [tags],
					})
				}
			} : undefined;


			// add table for current message
			await pdf.addTable([
				[
					{ content: username },
					{
						content: datePosted + ' ',
						styles: { fontStyle: 'italic', fontSize: 9, halign: 'right' }
					}
				]
			], body, { didDrawCell })
		}

		pdf.openPDF();
	}

	const companyOrSupplierData = person?.company ?? person?.supplier ?? {};

	return (
		<>
			<Block name='chat' title='Informatie'  headerRightSideChildren={<button onClick={() => createMessagesPDF()} className='btn--round btn--black'><Icon name='print' /></button>}>
				<div className='company__chat'>
					{/* usestate notes */}
					{notes.map((note, index) => {
                        return (
                            <CompanyPersonInfoMessage
                                getNotes={getNotes}
                                key={`message-${index}`}
                                companyId={companyOrSupplierData?.id}
                                setMemoFormData={setMemoFormData}
                                setEditFormData={setEditFormData}
                                setImgUrl={setImgUrl}
                                setCollapseTags={setCollapseTags}
                                collapseTags={collapseTags}
                                messageKey={index}
                                message={note}
                            />
                        );
                    })}
					{notes?.length === 0 && <span style={{color: 'gray', fontStyle: 'italic', padding: '15px'}}>Er zijn nog geen berichten...</span>}
				</div>

				<div className='company__add'>
					<ul className='company__message__labels'>
						{tagElements}
					</ul>
					<textarea onChange={updateText} id='newCompanyMessage' placeholder='Informatie toevoegen' rows='4'></textarea>
					<Button className="company__addbutton" onClick={() => createMessage(document.getElementById('newCompanyMessage').value)}>{width > 350 && 'Toevoegen'}<Icon name={'plus'} /></Button>
					<div className='company__message__file-upload'>
						<Button onClick={handleClick} className="btn btn--black button--popup" htmlFor="#fileUpload">
							<Icon name="paperclip" />
						</Button>
						<input id='fileUpload' ref={ref} onInput={handleFileChange} onChange={handleSubmit} type="file" hidden/>
					</div>
					{width > 600 && <span>32 MB maximale bestandsgrootte</span>}
				</div>

				{/* add popups */}
				<PopupAddOrEditMemo changedFormValues={memoFormData} />
				<PopupEditCompanyMessage getNotes={getNotes} tagData={tagData} company={companyOrSupplierData.data} editFormData={editFormData} />
				<Popup title='Bijlage' popupID={'inspect-image'}>
					<img src={imgUrl} alt="" />
				</Popup>
			</Block>
		</>
	)


}
