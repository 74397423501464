import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FetchContext } from "App/Strapi/FetchContext";
import { toast } from "react-toastify";
import Container from "UI/App/Components/Container/Container";
import PageHeader from "UI/App/Components/PageHeader/PageHeader";
import Block from "UI/App/Components/Block/Block";
import Icon from "UI/App/Components/Icon/Icon";

export default function XmlEditJobTicket(ticketXlm) {
    const navigate = useNavigate();
    const { orderId, ticketId } = useParams();
    const { authAxios } = useContext(FetchContext);

    const [numberdElem, setNumberdElem] = useState(undefined);
    const [wrapperdElem, setWrapperdElem] = useState(undefined);
    const [overlayEnabeld, setOverlayEnabeld] = useState(true);
    const [textValue, setTextValue] = useState("");
    const [ticketLoaded, setTicketLoaded] = useState(false);
    const [overlaytextValue, setOverlayTextValue] = useState("");
    const [debounced, setDebounced] = useState("");
    const [knowError, setKnowError] = useState({
        isError: false,
        line: undefined,
        column: undefined,
        reason: undefined,
        altReason: undefined,
    });


    useEffect(() => {
        findJobTicket(ticketId)
        appendLineNumbering("xmlEditor")
    }, [ticketId])

    useEffect(() => {
        if (numberdElem === undefined || wrapperdElem === undefined) return
        updateLineNumbering();

        // debounce the error verify, we dont need this to happen every update
        const timeoutId = setTimeout(() => {
            setDebounced(textValue);
        }, 1500);
        return () => clearTimeout(timeoutId);
    }, [textValue])

    useEffect(() => {
        verifyXml()
    }, [debounced])

    useEffect(() => {
        if (numberdElem === undefined || wrapperdElem === undefined) return
        updateLineNumbering();
    }, [knowError, overlayEnabeld])

    async function findJobTicket(id) {
        if (id === undefined) {
            return console.error("No id given");
        }

        authAxios.get(`/workflow/job-tickets/${id}`).then((data) => {
            let untabedXml = data.data.xml.replace(/\t/, "    ");
            setTextValue(untabedXml)
            setTicketLoaded(true)
        }).catch((error) => {
            console.error(error);
            toast.error('Er is iets misgegaan bij het ophalen van de job ticket.')
        });
    }

    function appendLineNumbering(id, wrapperId = undefined) {
        // Get reference to desired <textarea>
        const ta = document.getElementById(id);

        // If getting reference to element fails, warn and leave
        if (ta == null) {
            console.warn("Cant find textarea with id '" + id + "'");
            return;
        }

        // If textarea already has TLN active, warn and leave
        if (ta.className.indexOf("tln-active") != -1) {
            console.warn("textarea with id '" + id + "' is already being numbered");
            return;
        }

        // Otherwise, were safe to add the class name and clear inline styles
        ta.classList.add("tln-active");
        ta.style = {};

        // Create line numbers wrapper, insert it before textarea
        const elem = document.createElement("div");

        elem.className = "tln-wrapper";
        if (wrapperId !== undefined) {
            const taWrapper = document.getElementById(wrapperId);
            taWrapper.parentNode.insertBefore(elem, taWrapper);
        } else {
            ta.parentNode.insertBefore(elem, ta);
        }

        setNumberdElem(ta);
        setWrapperdElem(elem)
        updateLineNumbering(elem, ta)
    }

    function updateLineNumbering(elem = wrapperdElem, textArea = numberdElem) {
        // wy overley? this way if styling go's kaput xml can still be edited
        if (overlayEnabeld) updateOverlay(textArea)

        // Let's check if there are more or less lines than before
        const line_count = textArea.value.split("\n").length;
        const child_count = elem.children.length;
        let difference = line_count - child_count;

        // If there is any positive difference, we need to add more line numbers
        if (difference > 0) {
            // Create a fragment to work with so we only have to update DOM once
            const frag = document.createDocumentFragment();
            // For each new line we need to add,
            while (difference > 0) {
                // Create a <span>, add TLN class name, append to fragment and
                // update difference
                const line_number = document.createElement("span");
                line_number.className = "tln-line";
                frag.appendChild(line_number);
                difference--;
            }
            // Append fragment (with <span> children) to our wrapper element
            elem.appendChild(frag);
        }

        // If, however, there's negative difference, we need to remove line numbers
        while (difference < 0) {
            // Simple stuff, remove last child and update difference
            elem.removeChild(elem.lastChild);
            difference++;
        }
    }

    function updateOverlay() {
        const overlay = document.getElementById("xmlEditorStylingOverlay");
        let formatedText = ""
        let currentLine = 1;

        for (let line of textValue.split("\n")) {

            // this abomination has been made by yours truly; Ardin
            let escapedLine = line.replace(/&/g, "&amp;")
                // escape 'html' characters
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#39;")
                .replace(/[\s]\B/g, "&nbsp")
                // styling to make things easier to read
                .replace(/((&lt;\?[a-z-0-9]+(&gt;)?)|(&lt;\/[a-z-0-9]+(&gt;)?)|(&lt;[a-z-0-9]+(&gt;)?))/g, function (match) {
                    return `<span class="comp" >${match}</span>`
                })
                .replace(/((\?&gt;)|(&gt;))/g, function (match) {
                    return `<span class="comp" >${match}</span>`
                })
                .replace(/((?!&quot;)[a-zA-Z0-9.-]+(?=&quot;))/g, function (match) {
                    return `<span class="var" >${match}</span>`
                })
                .replace(/(([a-zA-Z]+)=(&quot;)|(&quot;))/g, function (match) {
                    return `<span class="varName" >${match}</span>`
                })
                .replace(/((?![A-Za-z0-9.-]+)=(?=&quot;))/g, function (match) {
                    return `<span class="comp" >${match}</span>`
                })
            if (knowError?.isError && knowError.line === currentLine) {
                formatedText += `<span class="error" >${escapedLine}</span><br>`
            } else {
                formatedText += `${escapedLine}<br>`
            }
            currentLine++
        }

        overlay.innerHTML = formatedText;
    }

    function verifyXml() {
        // if we have no data to verify, dont.
        if (!ticketLoaded === true) return;

        // if we have no data set, notify
        if (textValue === undefined || textValue === "") {
            setKnowError({
                isError: true,
                line: undefined,
                column: undefined,
                reason: "",
                altReason: "XML is empty"
            })
            return
        } 

        const domParser = new DOMParser();
        let dom = domParser.parseFromString(textValue, 'text/xml');

        if (dom.getElementsByTagName('parsererror').length > 0) {
            const errorMessage = (new XMLSerializer()).serializeToString(dom)
            const filterdMessage = RegExp(/((error on line)([A-Za-z0-9 ?:]+))/, 'g').exec(errorMessage)[0]
            const line = RegExp(/[0-9]+/, 'g').exec(filterdMessage)[0]
            const column = RegExp(/([0-9]+(?=:))/, 'g').exec(filterdMessage)[0]

            setKnowError({
                isError: true,
                line: Number(line),
                column: Number(column),
                reason: filterdMessage.split(":")[1],
                altReason: undefined
            })
        } else {
            setKnowError({
                isError: false,
                line: undefined,
                column: undefined,
                reason: "",
                altReason: undefined
            })
        }
    }

    function saveXml(id) {
        if (id === undefined) {
            return console.error("No id given");
        }

        authAxios.put(`/workflow/job-tickets/${id}`, {
            data: {
                xml: textValue
            }
        }).then((data) => {
            toast.success("Job Ticket opgeslagen")
            navigate(`/orders/${orderId}`)
        }).catch((error) => {
            console.error(error);
            toast.error('Er is iets misgegaan met het opslaan van de job ticket.')
        });
    }

    return (
        <Container>
            <PageHeader title={'Job ticket aanpassen'}>
                <button data-title={"Overlay"} onClick={(e) => { setOverlayEnabeld(!overlayEnabeld) }} className={'btn btn--black btn--round'}><Icon name={'wand-magic-sparkles'} /></button>
                <button data-title={"Opslaan"} disabled={(overlayEnabeld && knowError?.isError === true)} onClick={(e) => { saveXml(ticketId) }} className={'btn btn--black btn--round'}><Icon name={'save'} /></button>
            </PageHeader>
            <Block name={'Job ticket XML editor'} title={'Job ticket XML editor'}>
                {(overlayEnabeld && knowError?.isError === true) && <div className='progress__notice'>{(knowError?.altReason ?? `Er zit een fout in de XML op regel ${knowError.line}! (${knowError?.reason?.trim()})`)}</div>}
                <div className="input-group" style={{
                    width: '100%',
                    height: '800px'
                }}>
                    <div id={"xmlWrapper"} className="container_row" style={{
                        width: '100%',
                        height: '800px'
                    }}>
                        <textarea
                            onScroll={(e) => { wrapperdElem.scrollTop = numberdElem.scrollTop }}
                            onChange={(e) => { setTextValue(e.target.value) }}
                            onKeyDown={(e) => {
                                if (e.key == 'Tab') {
                                    e.preventDefault();

                                    let start = e.target.selectionStart;
                                    let end = e.target.selectionEnd;

                                    //set textarea value to: text before tab + "tab" + text tab caret
                                    let tabified = e.target.value.substring(0, start) + "  " + e.target.value.substring(end);
                                    setTextValue(tabified);
                                }
                            }}
                            on
                            id={"xmlEditor"}
                            value={textValue}
                            className="xmlLayer"
                        />

                        {overlayEnabeld && <div id={"xmlEditorStylingOverlay"} class={"xmlOverlay"} contentEditable={false} >{overlaytextValue}</div>}
                    </div>

                </div>

            </Block>
        </Container>
    )

}