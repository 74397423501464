import {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {isEmpty} from 'lodash';
import {stringify} from 'qs';
import {getOptionsFromConfig} from 'App/Strapi/ConfigContext';
import {FetchContext} from 'App/Strapi/FetchContext';
import {sanitizeString} from 'App/Util/transform';
import Icon from 'UI/App/Components/Icon/Icon';
import {usePagination} from 'UI/App/Components/Pagination/Pagination';
import Table from 'UI/App/Components/Table/Table';
import ExportCompanies from 'UI/App/Partials/Content/CRM/ExportCompanies';
import Container from "UI/App/Components/Container/Container";
import IF from "UI/App/Components/Conditional/IF";
import useWindowDimensions from "App/Util/useWindowDimensions";
import { getCountriesList } from "App/Util/countries"
import './CRM.scss';

export default function CRM({archived = false}) {
    const location = useLocation();
    const {width} = useWindowDimensions();
    const {authAxios} = useContext(FetchContext);
    // create a state for the table data
    const [tableData, setTableData] = useState([]);
    // create a state for the isSupplier data
    const [isSupplier, setIsSupplier] = useState(/\/suppliers/.test(location.pathname));
    // create a state for the type filter options
    const [typeFilterOptions, setTypeFilterOptions] = useState([]);
    // create a state for the table columns (sort order and column field)
    const [tableColumns, setTableColumns] = useState({
        sort: {
            column: 'name',
            direction: 'asc'
        },

        fields: [
            {name: 'beheerder', field: 'managingCompany', sortable: false,},
            {name: 'Bedrijf', field: 'name', sortable: true},
            {name: 'Contactpersoon', field: 'contactperson', sortable: false},
            {name: 'Telefoonnummer', field: 'telephone.dialingCode', sortable: true},
            {name: 'Type', field: 'type', sortable: true}
        ]
    });

    const [countryNames, setCountryNames] = useState([]);
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {

        getCountriesList().then((result) => {
            // set the 'default' value to something the filter will recognize
            result.countries[0].value = "*"

            // map to the right format
            setCountryNames(result.countries.map((entry) => {
                return { value: entry.value, name: entry.text }
            }));
        });
    }, [])

    // if not on archived page, check if the current path and setIsSupplier
    useEffect(() => {
        if (!archived) {
            const isSupplier = /\/suppliers/.test(location.pathname);
            setIsSupplier(isSupplier);
        }
    }, [location.pathname]);

    // get the filter types for the type filter
    useEffect(() => {
        const setTypeFilterOptionsInterceptor = (typeFilterOptions) => {
            setTypeFilterOptions([
                {
                    name: 'Alle',
                    value: '*',
                    text: 'Alle'
                },
                ...typeFilterOptions,
                { // since company types shouldn't be able to be created as a supplier we have to 'manually' add this type filter here.
                    name: 'Leverancier',
                    value: 'leverancier',
                    text: 'Leverancier'
                },
            ]);
        };
        void getOptionsFromConfig(authAxios, () => {
        }, setTypeFilterOptionsInterceptor);
    }, [authAxios]);

    const handleCreate = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target.name === "HJMG") {
            navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/create${(filterValues.type !== 'Leverancier' && params?.type !== undefined) ? `/${params?.type}` : ''}`, {state: {owner: "hjmg"}})
        } else {
            navigate(`/crm/${!isSupplier ? 'companies' : 'suppliers'}/create${(filterValues.type !== 'Leverancier' && params?.type !== undefined) ? `/${params?.type}` : ''}`, {state: {owner: "pretore"}})
        }


    }
    // Enable pagination
    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterValues,
        filterQuery,
        paginationStateLoaded,
        setFilterValues
    } = usePagination({
        storageKey: archived ? 'crm-archive' : 'crm-companies',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'name',
                '[contactPersons][firstName]',
                '[contactPersons][prefixToSurname]',
                '[contactPersons][surname]',
                'type'
            ]
        },
        filters: [
            {
                name: 'type',
                type: 'select',
                options: typeFilterOptions,
                defaultValue: '*',
            },
            {
                name: 'startsWith',
                type: 'select',
                options: [
                    // all values
                    {name: 'A-z', value: '*'},
                    // number range
                    {name: '0-9', value: '0-9'},
                    // special characters
                    {name: '#', value: '#'},
                    // generate the alphabet
                    ...Array.from(Array(26))
                        .map((e, i) => String.fromCharCode(i + 65))
                        .map((letter) => ({name: letter, value: letter.toLowerCase()}))
                ],
                strapiFilter: '$startsWith',
                strapiFilterFields: ['name'],
            },
            {
                name: 'managingCompany',
                type: 'select',
                options: [
                    {name: "Alle bedrijven", value: '*'},
                    {name: "HJMG", value: "HJMG"},
                    {name: "Pretore", value: "Pretore"},
                ],
                defaultValue: '*',
                callbackFunction: callbackFilter,
            },
            {
                name: 'Land',
                type: 'select',
                options: countryNames,
                strapiFilter: '$contains',
                strapiFilterFields: ['[address][country]'],
                defaultValue: "*",
                callbackFunction: (e) => {
                    // check if we have the default value, return without a filter query if we do
                    if (e.currentValue === "*") return;

                    // 'format' a query based on the field
                    return {
                        address: {
                            country: {
                                $contains: e.currentValue
                            }
                        }
                    }
                },
            }
        ],
        htmlElements: [
            <ExportCompanies exportContentType={!isSupplier ? 'companies' : 'suppliers'}/>,
            // <Link
            //     onClick={handleCreate}
            //     className='btn btn--add btn--icon-right'
            // >
            //     Toevoegen <Icon name='plus' />
            // </Link>,
            <span className='pagination__filter addItem' key='link--add'>
                <Link onClick={handleCreate} name="HJMG" className='btn btn--add btn--icon-right btn--hjmg'>
                    Toevoegen {width > 1650 ? '(HJMG)' : ''}
                    <Icon name='plus'/>
                </Link>
            </span>,
            <span className='pagination__filter addItem' key='link--add'>
                <Link onClick={handleCreate} name="PRETORE" className='btn btn--add btn--icon-right btn--pretore'>
                    Toevoegen {width > 1650 ? '(Pretore)' : ''}
                    <Icon name='plus'/>
                </Link>
            </span>
        ],
        buttonCollapseBreakpoint: 1820,
        resultsPerPageBreakpoint: 1960,
        paginationBreakpoint: 1270,
        resultCountBreakpoint: 1850
    });

    function callbackFilter(e) {
        // guard against no value or default value, if we have a callbackFilterValues we use that instead
        if (e?.currentValue === undefined || e?.currentValue === "*") return;
        // acquire the 'newest' value
        let value = e.currentValue;

        // create the custom filter object
        return {
            [e.name]: {
                [value]: {
                    '$eq': true
                }
            }
        }
    }

    useEffect(() => {
        if (params?.type === filterValues?.type) return;

        let relationType = params.type !== undefined ? params.type.replace(/s$/, '') : '*';

        // check if were customers
        if (location?.pathname?.endsWith("customers")) {
            relationType = "klant"
        }
        // check if were suppliers
        if (location?.pathname?.endsWith("suppliers")) {
            relationType = "leverancier"
        }

        setFilterValues((prevValue) => ({
            ...prevValue,
            ...{type: relationType}
        }));


    }, [params?.type]);

    useEffect(() => {

        if (filterValues?.type === 'Leverancier') {
            setIsSupplier(true)
        } else {
            setIsSupplier(false)
        }
        // set the type to the filter type.
        if (filterValues.type !== '*') {
            params.type = filterValues?.type
        }

    }, [filterValues])

    // get the table data using the useEffect hook
    useEffect(() => {
        if (!paginationStateLoaded) return;

        // build the filter parameters for this query
        const query = buildCRMSearchParams(resultsPerPage, currentPage, archived, tableColumns);

        // get the companies using the built params
        authAxios
            .get(`/crm?${query}&${filterQuery}`)
            .then(({data}) => {
                // Set data
                const tableData = [];

                // loop through all the companies
                for (const company of data.data) {
                    tableData.push({
                        attributes: {
                            onClick: (e) => {
                                const url = `/crm/${company.type !== 'Leverancier' ? 'companies' : 'suppliers'}/${company.id
                                }`;
                                if (e?.ctrlKey || e?.metaKey) {
                                    window.open(url, '_blank');
                                } else if (e.shiftKey) {
                                    window.open(url);
                                } else {
                                    navigate(url);
                                }
                            }
                        },
                        data: [
                            <div className='owner-indicator'>
                                <IF condition={company.managingCompany?.HJMG}>
                                    <span className='circle circle-hjmg'></span>
                                </IF>
                                <IF condition={company.managingCompany?.Pretore}>
                                    <span className='circle circle-pretore'></span>
                                </IF>
                            </div>,
                            sanitizeString(company.name),
                            company.contactPersons?.length > 0
                                ? {
                                    value: <span title={`${company.contactPersons[0].firstName} ${company?.contactPersons[0]?.prefixToSurname ?? ''} ${company.contactPersons[0].surname}`} data-company-id={company.id} className={`table__contactperson__content`}>{company.contactPersons[0].firstName} {company.contactPersons[0].prefixToSurname} {company.contactPersons[0].surname} <Icon className='table__angle__down__icon' name="angle-down"/></span>,
                                    attributes: {
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            e.currentTarget.querySelector('svg').style['transform'] = e.currentTarget.querySelector('svg').style['transform'] === 'scaleY(-1)' ? 'scaleY(1)' : 'scaleY(-1)';
                                            document
                                                .querySelectorAll(
                                                    `tr[data-company-id='${company.id}']`
                                                )
                                                .forEach((row) => {
                                                    row.toggleAttribute('hidden');
                                                });
                                        },
                                        style: {
                                            color: 'var(--primary)',
                                            userSelect: 'none'
                                        }
                                    }
                                }
                                : null,
                            company.telephone?.dialingCode && company.telephone?.telephoneNumber
                                ? <span data-company-id={company.id}>+{company.telephone?.dialingCode} {company.telephone?.telephoneNumber}</span>
                                : null,
                            company?.type?.charAt(0)?.toUpperCase() + company?.type?.slice(1)?.toLowerCase()
                        ]
                    });

                    // loop through the contact people
                    for (const contactPerson of company.contactPersons) {
                        let hidden = true;
                        for (const searchWord of (filterValues?.search ?? '')
                            .toLowerCase()
                            .split(' ')) {
                            if (searchWord === '') {
                                continue;
                            }

                            // check if the contactPerson's name includes one of the words
                            if (
                                contactPerson.firstName.toLowerCase().includes(searchWord) ||
                                contactPerson.prefixToSurname?.toLowerCase().includes(searchWord) ||
                                contactPerson.surname.toLowerCase().includes(searchWord)
                            ) {
                                // set hidden to false to show the contactPerson
                                hidden = false;
                            }
                        }

                        // add a row for each contactperson
                        tableData.push({
                            attributes: {
                                onClick: () => {
                                    navigate(
                                        `/crm/${company.type !== 'Leverancier' ? 'companies' : 'suppliers'}/${company.id
                                        }/contactpersons/${contactPerson.id}`
                                    );
                                },
                                className: 'sub-row',
                                hidden: hidden,
                                'data-company-id': company.id
                            },
                            data: [
                                <span></span>,
                                !isEmpty(company.contactPersons) ? (
                                    <span>
                                        {`${contactPerson.firstName}${contactPerson.prefixToSurname
                                            ? ' ' + contactPerson.prefixToSurname + ' '
                                            : ' '
                                        }${contactPerson.surname}`}
                                        <IF condition={!/^\W+$/.test(contactPerson.function)}>
                                            <span>, </span>
                                            <small><b>({contactPerson.function})</b></small>
                                        </IF>
                                    </span>
                                ) : null,
                                contactPerson.email,
                                contactPerson.telephone
                                    ? `+${contactPerson.telephone?.dialingCode} ${contactPerson.telephone?.telephoneNumber}`
                                    : null,
                                null
                            ]
                        });
                    }
                }

                setTableData(tableData);

                // Set pagination
                const paginationData = data.meta.pagination;
                setTotalPages(paginationData.pageCount);
                setTotalResults(paginationData.total);
            })
            .catch((exception) => {
                toast.error(
                    `Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''
                    } `
                );

                console.error(exception);
            });
    }, [currentPage, resultsPerPage, authAxios, setTotalPages, setTotalResults, filterQuery, archived, tableColumns, paginationStateLoaded, isSupplier]);

    return (
        <div>
            {filtering}
            <Container>
                <Table
                    headers={[
                        '',
                        'Bedrijf',
                        'Contactpersoon',
                        'Telefoonnummer',
                        'Type'
                    ]}
                    structure={{
                        600: ['60px', '100%', 0, 0, 0],
                        700: ['60px', '250px', '170px', 0, 0],
                        800: ['60px', '250px', '170px', 0, '100px'],
                        950: ['60px', '300px', '200px', 0, '150px'],
                        1300: ['60px', '300px', '150px', '200px', '150px'],
                        1600: ['60px', '40%', '30%', '30%', '150px'],
                        default: ['60px', '60%', '20%', '20%', '150px']
                    }}
                    columnTranslationExceptions={[1, 2]}
                    data={tableData}
                    tableColumns={tableColumns}
                    setTableColumns={setTableColumns}
                    hover
                    border='row'
                    overflowText={true}
                />
            </Container>
            {paging}
        </div>
    );
}

function buildCRMSearchParams(resultsPerPage, currentPage, archived, tableColumns) {
    let queryParams = {
        pagination: {
            page: currentPage,
            pageSize: resultsPerPage
        },
        populate: {
            telephone: 'telephone',
            managingCompany: true,
            contactPersons: {
                populate: {
                    telephone: true
                }
            }
        },
        'filters[$and][][archived][$eq]': archived,
        sort: `${tableColumns.sort.column}:${tableColumns.sort.direction} `
    };

    return stringify(queryParams, {
        encodeValuesOnly: true // prettify URL
    });
}
