import Icon from "UI/App/Components/Icon/Icon";
import { useEffect } from "react";

export default function MonthHeader({selectedDateMonthly, setSelectedDateMonthly, formatOptions, startDate}) {

    const MONTHS = {
        'januari': 'January',
        'februari': 'February',
        'maart': 'March',
        'april': 'April',
        'mei': 'May',
        'juni': 'June',
        'juli': 'July',
        'augustus': 'August',
        'september': 'September',
        'oktober': 'October',
        'november': 'November',
        'december': 'December',
    };

    useEffect(() => {
        if(startDate !== null) {
            setSelectedDateMonthly(new Intl.DateTimeFormat('nl', formatOptions).format(new Date(startDate)));
        }
    }, []);

    function onMonthChange(type) {

        const [month, year] = selectedDateMonthly.split(' ');
        let parsedDate = new Date(Date.parse(`${MONTHS[month]} 1, ${year}`));
        
        if(type === 'PREV') {
            parsedDate.setMonth(parsedDate.getMonth() - 1);
        } else {
            parsedDate.setMonth(parsedDate.getMonth() + 1);
        }
        setSelectedDateMonthly(new Intl.DateTimeFormat('nl', formatOptions).format(new Date(parsedDate)));
    }

    return (
        <>
            <div onClick={() => onMonthChange('PREV')}>
                <Icon 
                    name="chevron-left" 
                    className='planning__header__date__content__icon__button'
                />
            </div>
            <span className='planning__header__date__content__text'>
                {selectedDateMonthly}
            </span>
            <div onClick={() => onMonthChange('NEXT')}>
                <Icon 
                    name="chevron-right" 
                    className='planning__header__date__content__icon__button'
                />
            </div>
        </>
    )
}