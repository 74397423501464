import Grid from 'UI/App/Components/Grid/Grid';
import { useCallback, useContext, useEffect, useState } from 'react';
import Icon from 'UI/App/Components/Icon/Icon';
import { formatCurrency } from 'App/Util/format';

export default function FancyInput({
    label,
    description,
    type,
    id,
    labelProps = {},
    inputProps = {},
    iconProps = {},
    customInput,
    icon = "question",
    altIcon = "exclamation",
    icon_SwitchAltOnClick = false,
    icon_FunctionOnClick = undefined,
    invertIcon = false,
    size = 38,
    icon_size = 25,
    value,
    onChange,
    onBlur,
    required,
    useAccent = false,
    hideIcon = false,
    ...props
}) {
    const inputUuid = id ?? crypto.randomUUID()
    const [iconToggle, setIconToggle] = useState(false);
    const [effectiveIcon, setEffectiveIcon] = useState("")

    // check/set the icon
    useEffect(() => {
        if (!iconToggle) {
            setEffectiveIcon(icon)
        }
        if (iconToggle) {
            if (altIcon !== undefined && icon_SwitchAltOnClick) {
                setEffectiveIcon(altIcon)
            } else {
                setEffectiveIcon(icon)
            }
        }
    }, [icon, altIcon, iconToggle])

    if (typeof inputProps !== "object") {
        console.error("inputProps must be typeof object")
        return
    }
    if (typeof iconProps !== "object") {
        console.error("iconProps must be typeof object")
        return
    }
    if (typeof labelProps !== "object") {
        console.error("labelProps must be typeof object")
        return
    }

    switch (type?.toLowerCase()) {
        case 'percentage':
            // set 'required' props if not set
            if (inputProps?.step === undefined) {
                inputProps.step = 1
            }
            if (inputProps?.lang === undefined) {
                inputProps.lang = "nl"
            }
            if (inputProps?.max === undefined) {
                inputProps.max = 100
            }
            if (inputProps?.min === undefined) {
                inputProps.min = 0
            }
            return (
                <div id={`wrapper-${inputUuid}`} className='input-group1' >
                    <label className={required && "required"}{...labelProps} htmlFor={inputUuid}> {label} </label>
                    <InputIcon
                        input={customInput ?? <input
                            className={`${!hideIcon && `fancy ${invertIcon ? "left" : "right"}`} `}
                            value={
                                value
                            }

                            onChange={(e) => onChange && onChange(e)}
                            onBlur={(e) => { onBlur && onBlur(e) }}
                            type={"number"}
                            id={inputUuid}

                            required={required}
                            {...inputProps}
                        />}
                        icon={"fa-regular percent"}
                        iconProps={iconProps}
                        size={size ?? undefined}
                        iconSize={icon_size ?? undefined}
                        useAccent={useAccent}
                        forceLeft={invertIcon}
                        forceRight={!invertIcon}
                        iconToggle={iconToggle}
                        setIconToggle={setIconToggle}
                        iconClick={icon_FunctionOnClick}
                        forceNone={hideIcon}
                    />
                    <small>{description}</small>
                </div>
            )
        case 'currency-euro': // if this is getting used by a user its recommend to set the blur.
            return (
                <div id={`wrapper-${inputUuid}`} className='input-group2' >
                    <label className={required && "required"}{...labelProps} htmlFor={inputUuid}> {label} </label>
                    <InputIcon
                        input={customInput ?? <input
                            className={`${!hideIcon && `fancy ${!invertIcon ? "left" : "right"}`} `}
                            value={
                                onBlur ? value : formatCurrency(Number(value?.replace(/[^0-9,-]+/g, "")?.replace(",", ".")))?.replace("€", "")
                            }

                            onChange={(e) => onChange && onChange(e)}
                            onBlur={(e) => { onBlur && onBlur(e, formatCurrency(Number(e.target.value?.replace(/[^0-9,-]+/g, "")?.replace(",", ".")))?.replace("€", "")) }}
                            type={"text"}
                            id={inputUuid}

                            required={required}
                            {...inputProps}
                        />}
                        icon={"fa-regular euro-sign"}
                        iconProps={iconProps}
                        size={size ?? undefined}
                        iconSize={icon_size ?? undefined}
                        forceLeft={!invertIcon}
                        forceRight={invertIcon}
                        iconToggle={iconToggle}
                        setIconToggle={setIconToggle}
                        iconClick={icon_FunctionOnClick}
                        useAccent={useAccent}
                        forceNone={hideIcon}
                    />
                    <small>{description}</small>
                </div>
            )
        case 'password':
            return (
                <div id={`wrapper-${inputUuid}`} className='input-group3' >
                    <label className={required && "required"}{...labelProps} htmlFor={inputUuid}> {label} </label>
                    <InputIcon
                        input={customInput ?? <input
                            className={`${!hideIcon && `fancy ${invertIcon ? "left" : "right"}`} `}
                            value={value}
                            onChange={(e) => onChange && onChange(e)}
                            type={iconToggle ? "text" : "password"}
                            id={inputUuid}
                            required={required}
                            {...inputProps}
                        />}
                        icon={iconToggle ? "eye-slash" : "eye"}
                        iconProps={iconProps}
                        size={size ?? undefined}
                        iconSize={icon_size ?? undefined}
                        forceLeft={invertIcon}
                        forceRight={!invertIcon}
                        iconToggle={iconToggle}
                        setIconToggle={setIconToggle}
                        iconClick={icon_FunctionOnClick}
                        useAccent={useAccent}
                        forceNone={hideIcon}
                    />
                    <small>{description}</small>
                </div>
            )
        default:
            return (
                <div id={`wrapper-${inputUuid}`} className='input-group' >
                    <label {...labelProps} className={required && "required"} htmlFor={inputUuid}> {label} </label>
                    <InputIcon
                        input={customInput ?? <input
                            className={`${!hideIcon && `fancy ${invertIcon ? "left" : "right"}`} `}
                            value={value}
                            onChange={(e) => onChange && onChange(e)}
                            id={inputUuid}
                            required={required}
                            {...inputProps}
                        />}
                        icon={effectiveIcon}
                        iconProps={iconProps}
                        size={size ?? undefined}
                        iconSize={icon_size ?? undefined}
                        forceLeft={invertIcon}
                        forceRight={!invertIcon}
                        iconToggle={iconToggle}
                        setIconToggle={setIconToggle}
                        iconClick={icon_FunctionOnClick}
                        useAccent={useAccent}
                        forceNone={hideIcon}
                    />
                    <small>{description}</small>
                </div>
            )
    }

}
function InputIcon({
    input,
    iconToggle,
    setIconToggle,
    iconProps,
    icon = "",
    iconClick,
    forceLeft = false,
    forceRight = false,
    forceNone = false,
    useAccent = false,
    size = 25,
    iconSize = 25
}) {
    if (typeof iconProps !== "object") {
        iconProps = {}
    }

    let styleProps = {};
    if (iconProps?.style !== undefined) {
        styleProps = iconProps.style
        delete iconProps.style
    }
    if (forceNone) {
        return (
            <Grid columns={1} >
                {input}
            </Grid>
        )

    }
    if (forceLeft) {
        return (
            <Grid columns={2} customColTemplate={"36px 1fr"} gap={"0px"}>
                <Icon
                    onClick={(e) => { (iconClick && iconClick(e)); setIconToggle(!iconToggle) }}
                    style={{
                        backgroundColor: "var(--medium-gray)",
                        height: `${size}px`,
                        width: `${size}px`,
                        fontSize: `${iconSize}px`,
                        padding: `5px`,
                        margin: `5px 0px`,
                        borderRadius: "var(--rounded-corners-s) 0px 0px var(--rounded-corners-s)",
                        borderStyle: "solid none solid solid",
                        borderColor: "var(--border-color)",
                        color: useAccent && "var(--primary)",
                        ...(styleProps)
                    }}
                    forceAwesome={true}
                    name={icon}
                    {...iconProps}
                />
                {input}
            </Grid>
        )

    } else if (forceRight) {
        return (
            <Grid columns={2} customColTemplate={"1fr 36px"} gap={"0px"}>
                {input}
                <Icon
                    onClick={(e) => { (iconClick && iconClick(e)); setIconToggle(!iconToggle) }}
                    style={{
                        backgroundColor: "var(--medium-gray)",
                        height: `${size}px`,
                        width: `${size}px`,
                        fontSize: `${iconSize}px`,
                        padding: `5px 8px`,
                        margin: `5px 0px`,
                        borderRadius: "0px var(--rounded-corners-s) var(--rounded-corners-s) 0px",
                        borderStyle: "solid solid solid none",
                        borderColor: "var(--border-color)",
                        color: useAccent && "var(--primary)",
                        ...(styleProps)
                    }}
                    forceAwesome={true}

                    name={icon}
                    {...iconProps}
                />
            </Grid>
        )

    }
    else {
        return (
            <Grid columns={1} customColTemplate={"1fr 36px"} gap={"0px"}>
                {input}
            </Grid>
        )
    }

}