import {pxToMm} from 'App/Util/format';

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 */
export function getVisibleTextWidth(
    text: string,
    font: string = '12px Arial',
    raw: boolean
): number {
    /**
     * Check if word needs specific amount of tabs
     * @type {{Print: string, Extent: string, Editie: string}}
     */
    const specificWords = {
        "Pagina's": 4,
        Print: 3
    };

    if (text in specificWords) {
        // @ts-ignore
        return specificWords[text];
    }

    // @ts-ignore
    let canvas =
        // @ts-ignore
        getVisibleTextWidth.canvas ||
        // @ts-ignore
        (getVisibleTextWidth.canvas = document.createElement('canvas'));
    // else, create new canvas
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);

    return raw ? metrics.width : Math.round(metrics.width / 10);
}

export function getVisibleTextWidthInMM(text: string, font: string = '12px Arial'): number {
    return pxToMm(getVisibleTextWidth(text, font, true));
}

export function getRequiredTabs(
    text: string,
    font = '12px Arial',
    numberOfTabs: number,
    log = false,
    transform?: (content: string, fillableLength: number, uselessWidth: number) => string
) {
    const singleTabWidth = getVisibleTextWidth('    ', font, true);
    const totalTabWidth = getVisibleTextWidth('    '.repeat(numberOfTabs), font, true);

    const textWidth = getVisibleTextWidth(text, font, true);
    const fillableLength = totalTabWidth - textWidth;
    const uselessWidth = textWidth % singleTabWidth;

    log && console.log(text, fillableLength, uselessWidth);

    let content = '';

    try {
        content += '\t'.repeat(Math.round(fillableLength / singleTabWidth));
    } catch (e) {
        console.warn(
            `The fillable length (${fillableLength}) is too small to fit a tab of width ${singleTabWidth}.`
        );
    }

    if (transform) {
        content = transform(content, fillableLength, uselessWidth);
    }

    return content;
}

export function getNumLines(text: any, font: string = '12px Arial', maxWidth: number): number {
    
    if (typeof text !== 'string') {
        return 0;
    }

    const lines = text.split('\n');

    const numLinesArray = lines.map((line) => {
        const lineWidth = getVisibleTextWidth(line, font, true);
        const numWrappedLines = Math.ceil(lineWidth / maxWidth);
        return numWrappedLines === 0 ? 1 : numWrappedLines;
    });

    return numLinesArray.reduce((a, b) => a + b, 0);
}

export function limitLines(
    text: string,
    font: string = '12px Arial',
    maxWidth: number,
    maxLines: number
): string {
    const lines = text.split('\n');

    const returnLines: { line: string; numLines: number }[] = [];

    for (const line of lines) {
        const lineWidth = getVisibleTextWidth(line, font, true);
        const numWrappedLines = Math.ceil(lineWidth / maxWidth);
        const numLinesForLine = numWrappedLines === 0 ? 1 : numWrappedLines;

        if (returnLines.reduce((a, b) => a + b.numLines, 0) + numLinesForLine > maxLines) {
            break;
        } else {
            returnLines.push({ line, numLines: numLinesForLine });
        }
    }

    return returnLines.map((line) => line.line).join('\n');
}
